export const path = 'app'
export const PREFIX = 'nl'
export const gigyaUrl = 'gigya.com'
export const allActivated = 'all-activated'
export const defaultQty = 1
export const keyCodes = {
    escKeyCode: 27,
}
export const keyboardKeys = {
    escKeyIEEdge: 'Esc',
    escKey: 'Escape',
}
export const maxQty = 99
export const autoService = 'AUTO'
export const pageTypes = {
    searchPage: 'search',
    checkout: 'checkout',
    storeLocator: 'storeLocator',
    storeDetails: 'storeDetails',
    pdpPage: 'product',
    productPage: 'product',
    store: 'store',
    brandPage: 'brandlisting',
    brandCategoryPage: 'brandcategorylisting',
    plp: 'categorylevelN',
    categoryPages: ['categorylevel1', 'categorylevel2', 'categorylevelN'], // dlp, clp, cplp
    cart: 'cart',
    wishlist: 'wishlist',
    eventLanding: 'eventlanding',
    eventListing: 'eventlisting',
    promoListing: 'promolisting',
    orderDetails: 'order-details',
    orderConfirmation: 'purchase',
    account: 'account',
    packageLanding: 'packagelanding',
    digitalFlyer: 'other', // This type will be replace actual data page type for digital flyer
    homePage: 'home',
    aoaPage: 'autoServiceAppointment',
    dynamicPages: ['categorylevel1', 'categorylevel2', 'categorylevelN', 'product', 'store'],
    brandLandingPage: 'brandlanding',
    brandListingPage: 'brandlisting',
    noSearchPage: 'nosearch',
    emailVerificationPage: 'emailVerification',
}

export const categoryURLIdentifier = 'cat'
export const vehicleParametersRegExp = /\/veh\/(?:ct|vc)\//
export const vehicleSuffix = '/veh/'
export const categorySuffixByLang = {
    en: 'ct',
    fr: 'vc',
    'x-default': 'ct',
}

export const BREAKPOINTS = {
    mobileWidth: 440,
    mobileMaxWidth: 767, // anything above 767 will be treated as tablet
    tabletMaxWidth: 1279,
    desktopMinWidth: 1280,
    desktopLgWidth: 2560,
    mobileLandscape: 667,
    tabletStandardWidth: 1024,
}
export const MODAL_OPEN_CLASS = 'modal-open'
export const SIDEBAR_MODAL_OPEN_CLASS = 'sidebar-modal-open'

export const previousElementName = 'data-previous-focused-ele' // This name is the element which opened the modal

export const CLONED_CART_ID_PARAM = 'clonedCart' // The parameter name to get cloned cart id from query string, is in use in cart sharing flow
export const CLONED_STS_CART_ID_PARAM = 'clonedStsCart' // The parameter name to get cloned cart id from query string, is in use in cart sharing flow
export const CLONED_ISE_CART_ID_PARAM = 'clonedISECart' // The parameter name to get cloned cart id from query string, is in use in In Store Ecommerce cart sharing flow
export const STORE_INITIATED_CART_KEY = 'CTC.StoreInitiatedCart' // The key for store initiated cart to write/remove it to/from session storage
export const SAVE_THE_SALE_CART_KEY = 'CTC.SaveTheSaleCart' // The key for store initiated cart to write/remove it to/from session storage
export const STORE_INITIATED_CART_TEMPLATE = 'CTC.StoreInitiatedCartTemplate'
export const SAVE_THE_SALE_CART_TEMPLATE = 'CTC.SaveTheSaleCartTemplate'
export const GET_CARD_ID_MESSAGE = 'getCartId'
export const CART_CLEAN_UP_MESSAGE = 'sessionCartCleanUp'
export const CART_CLEAN_UP_START = 'sessionCartCleanUpStart'
export const CART_CLEAN_UP_END = 'sessionCartCleanUpEnd'
export const CART_CLEAN_UP_FAIL = 'sessionCartCleanUpFail'
export const INIT_WARN_POPUP = 'initWarnPopup'
export const REF_URL_KEY = 'refUrl'
export const IS_FIRST_PAGE_VISIT = 'isFirstPageVisit'

export enum Timesuffix {
    AM = 'AM',
    PM = 'PM',
}

export const availableParamsForPLP = ['sort', 'saleItem', 'storeId', 'facet']
export const digitPrecision = 2
export const seoDescriptionLimit = 220

export const dynamicComponentName = 'ReferenceComponent'
export const userState = {
    loyaltyCard: 'loyaltyCard',
    noLoyaltyCard: 'noLoyaltyCard',
    guest: 'guest',
    triangleCreditCard: 'triangleCreditCard',
}

export const ErrorTypes = {
    genericError: 'genericError',
    validationError: 'validationerror',
    HybrisErrorTypes: {
        UnavailableExpressEntry: 'cart.hasUnavailableExpressEntry',
        MixedHomeDeliveryModes: 'cart.hasMixedHomeDeliveryModes',
        CartError: 'CartError',
        ExpiredSharedCartLinkError: 'UnknownIdentifierError',
        AlreadyUsedSharedCartError: 'CTCInvalidSharedCartError',
        TriangleSelectStatusChangedError: 'validation.triangleSelectStatus.inconsistent',
    },
}

export const deliveryOptions = {
    STANDARD: 'STANDARD (STAN)',
    IN_HOME_DELIVERY: 'IN_HOME_DELIVERY (IHDE)',
    IN_HOME_DELIVERY_AND_UNPACK: 'IN_HOME_DELIVERY_UNPACK (IHDU)',
}

export const priceCategory = {
    THRESHOLD1: 'THRESHOLD1',
    THRESHOLD2: 'THRESHOLD2',
    THRESHOLD3: 'THRESHOLD3',
}

export const paymentTypes = {
    PAYPAL_EXPRESS: 'PAYPAL_EXPRESS',
    PAYPAL_REGULAR: 'PAYPAL_REGULAR',
}
