import { PREFIX } from '../../config'

export enum toastTypeConst {
    postalCode = 'postalCode',
    pickupInformation = 'pickupInformation',
    general = 'general',
    successFees = 'successFees',
    changeShippingFee = 'changeShippingFee',
    CTMoneyError = 'CTMoneyError',
    CTMoneySuccess = 'CTMoneySuccess',
    deliveryModeChanged = 'deliveryModeChanged',
    contact = 'contactError',
    ToggleOffByUser = 'toggleOffByUser',
    shippingFailure = 'shippingFailure',
    deliveryToPOBoxesFailure = 'deliveryToPOBoxesFailure',
    shippingFeeApplied = 'shippingFeeApplied',
}

// To-Do: temporary fix as CDS does not return store data and init payment is not getting called
// to be removed when CDS returns store data in cart response
export const storeId = 7
export enum drawerFillStatus {
    contactInfoDrawer = 'contact',
    shippingInfoDrawer = 'shipping',
    pickupInfoDrawer = 'pickup',
    paymentInfoDrawer = 'payment',
}
export const trueString = 'true'
export const inactiveClassName = 'inactive-bar'
export const gcBarClassName = 'giftcard-bar'
export const gcBarClassNameInactive = `${gcBarClassName} ${PREFIX}-${gcBarClassName}--inactive`

export const successStatus = 200
export const checkoutContactUrl = 'updateContactCheckout'
export const checkoutDeliveryUrl = 'updateDeliveryCheckout'
export const checkoutPickupUrl = 'updatePickupCheckout'
export const checkoutPaymentUrl = 'updatePaymentCheckout'
export const PlaceOrderErrorCode = {
    hystrixError: 'error00271',
    inSufficientInventoryError: 'error03054',
    cartEmptyError: 'error03037',
    cardMessagePathError: 'error03000',
}
export const payPalShippingPostalCodeRestrictedQuery = 'payPalShippingPostalCodeRestricted'
export const payPalCannotDeliverPOBoxQuery = 'payPalCannotDeliverPOBox'

export const RedeemCTMoneyURL = 'redeemCTMoney'

export const NO_ERROR = 'No Error'
export const ProfileURL = 'profile'
export const PaymentDetailsURL = 'paymentDetails'

// attribute name to access the old car banner id from AEM for Gigya
export const gigyaBannerId = 'gigyaBannerId'

/**
 * Enum to differentiate various toast options
 */
export enum ToastVariant {
    WARNING = 'WARNING',
    SUCCESS = 'SUCCESS',
    INFORMATION = 'INFORMATION',
}
