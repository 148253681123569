import React from 'react'
import { useSelector } from 'react-redux'
import { Icon } from '@nl/lib'
import { PREFIX } from '../../config'
import checkNestedProps from '../../utils/checkNestedProps'
import PropTypes from 'prop-types'

import { AutoServiceButtonProps } from './AutoServiceButton.type'
import { commonContentSelector } from '../../redux/selectors/commonContent.selectors'

const AutoServiceButton: React.FC<AutoServiceButtonProps> = ({ ...props }): JSX.Element => {
    const { storeNumberForFrame } = props

    const { commonContentAvailable } = useSelector(commonContentSelector)

    const autoServiceButtonLabel = checkNestedProps(
        commonContentAvailable,
        'autoAppointment',
        'autoServiceButtonLabel',
    ) as string

    const autoAppointmentPagePath = checkNestedProps(
        commonContentAvailable,
        'autoAppointment',
        'autoAppointmentPagePath',
    ) as string

    const onButtonClick = (): void => {
        const redirectPath = storeNumberForFrame
            ? autoAppointmentPagePath + `?store=${storeNumberForFrame}`
            : autoAppointmentPagePath
        window.open(redirectPath, '_self', 'noopener')?.focus()
    }

    return (
        <button
            className={`${PREFIX}-need-auto-service-button`}
            data-testid="auto-appointment-button"
            onClick={onButtonClick}
            aria-label={autoServiceButtonLabel}>
            <Icon type={props.icon} size={props.iconSize} />
            {autoServiceButtonLabel}
        </button>
    )
}

AutoServiceButton.defaultProps = {
    icon: 'ct-wrench-in-green',
    iconSize: 'md',
}

AutoServiceButton.propTypes = {
    storeNumberForFrame: PropTypes.string,
}

export default AutoServiceButton
