import { Dispatch } from 'redux'

import { SET_PENCIL_BANNER_PROPS, SET_SECONDARY_NAV_PROPS } from '../types/headerProps/headerProps.actionTypes.constant'
import { setPencilBannerProps, setSecondaryNavProps } from '../actionCreators/headerProps.actionCreators'
import { AccountPencilBannerProps } from '../models/accountMenu.interface'
import { SecondaryNavigationWrapperProps } from '../../components/SecondaryNavigation/SecondaryNavigationWrapper.type'

export const addPencilBanner =
    (requestPayload: AccountPencilBannerProps) =>
    (dispatch: Dispatch<{ type: typeof SET_PENCIL_BANNER_PROPS; payload: AccountPencilBannerProps }>): void => {
        dispatch(setPencilBannerProps(requestPayload))
    }

export const addSecondaryNavigation =
    (requestPayload: SecondaryNavigationWrapperProps) =>
    (dispatch: Dispatch<{ type: typeof SET_SECONDARY_NAV_PROPS; payload: SecondaryNavigationWrapperProps }>): void => {
        dispatch(setSecondaryNavProps(requestPayload))
    }
