import { FulfillmentOptionAvailable, YesNoNotApplicable } from '../../analytics/providers/provider.type'
import { GlobalAnalyticsVehicle } from '../../components/Accounts/OrderHistory/GlobalPageLoadAnalytics.type'
import { HttpRequestState } from '../../httpClient/client.type'
import { CheckoutContactInfo, CheckoutShippingInfo, BillingAddress } from './checkout.interface'
import { iPrice } from './productData.interface'
import { StoreWithAvailability } from './storeDetails.interface'
import { productWheelTypes, FitmentTypeEnum } from '../../globalConstants/global.constant'
import { Consignment, EntryGroups, PointOfService } from './orderConfirmation.interface'
import { ItemInlineErrorType } from '../../components/ShoppingCart/ShoppingCart.type'
import { PromoMessageType, ProductSpecifications, TriangleSelectBenefitsType } from './product.interface'
import { SaleCut } from '../../components/BuyBox/BuyBox.type'

export interface Price {
    value?: number | null
    maxPrice?: number | null
    minPrice?: number | null
}

export interface PriceMessage {
    label?: string | null
    tooltip?: string | null
}

export interface OrderEntryDTO {
    product: {
        code: string
    }
    quantity: number
    deliveryPointOfService?: {
        name: string
    }
    deliveryMode: {
        code: string
    }
    isPackagedItem?: boolean
    packageId?: string
    vehicleInformation?: VehicleInformation
    cartEntryReference?: string
    isGrouped?: boolean
}

export interface AddProductToCartGuestRequestDTO {
    guid: string
    saveForLaterId?: string
    clickedSFL?: boolean
    fields?: string
    entries: {
        orderEntries: OrderEntryDTO[]
    }
    isPackagedItem?: boolean
    packageId?: string
    vehicleInformation?: VehicleInformation
    isGrouped?: boolean
    entryNumber?: number[]
}

export interface AddProductToSFLGuestRequestDTO {
    productName: string
    isBulk: boolean
    productData: AddProductToCartGuestRequestDTO
}

export interface CartModificationsDTO {
    entry: CartOrderEntries
    quantityAdded: number
    errorCode: string
    errorMessage: string
    numberOfItems: number
}

export interface ServiceDTO {
    serviceType?: string
    maxQuantity?: number
    name: string
    price: number
    shortDescription?: string
    sku: string
    url: string
    entryType?: string
    fulfillment?: CartItemFulFillmentType
    code?: string
}

export enum ServiceType {
    ADD_ON = 'ADD',
    SERVICE = 'SER',
}

export interface AddProductToCartGuestResponseDTO {
    cartModifications: CartModificationsDTO[]
    serviceList: ServiceDTO[]
    guid: string
    numberOfItems: number
}

export interface DeliveryOptionsCost {
    code: STHDeliveryModes.STANDARD | STHDeliveryModes.EXPRESS
    eligible: boolean
    shippingFee: DeliveryCostType
}

export interface DeliveryCosts {
    deliveryCosts: DeliveryOptionsCost[]
}

export interface CartResponseErrorDTO {
    'x-request-id'?: string
    statusCode?: number
    status?: number
    error: string
    message: string
    errCode?: string
    errorCode?: string
    data?: {
        errors: [
            {
                message: string
                type: string
            },
        ]
        statusCode: number
        message: string
        inventory?: number
        maxSFLCount: string
        errorMessage: string
        errorCode: string
        data: string
        errCode: string
        errOrigin: string
        error?: string
        cartItemLimit?: number
    }
    response?: {
        data?: {
            data?: {
                errors: [
                    {
                        message: string
                    },
                ]
            }
        }
    }
    errors: string[]
    cartId?: string
    correlationId?: string
    errOrigin?: string
    isCdsError?: boolean
    requestId?: string
    stack?: string
}

export interface OrderEntries {
    configurationInfos: []
    deliveryPointOfService: {
        name: string
    }
    entryNumber: number
    quantity: number
    statusSummaryList: []
}

export enum DeliveryStatusBopis {
    READY_FOR_PICK_UP = 'READY_FOR_PICKUP',
    IN_PROGRESS = 'IN_PROGRESS',
    ORDER_RECEIVED = 'ORDER_RECEIVED',
    COMPLETED = 'COMPLETED',
    CANCELLATION_PENDING = 'CANCELLATION_PENDING',
    CANCELLED = 'CANCELLED',
    UNCONSIGNED = 'UNCONSIGNED',
    RETURNED = 'RETURNED',
}

export enum DeliveryStatusSTH {
    ORDER_RECEIVED = 'ORDER_RECEIVED',
    SHIPPED = 'SHIPPED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    CANCELLATION_PENDING = 'CANCELLATION_PENDING',
    CANCELLED = 'CANCELLED',
    RETURN_REQUESTED = 'RETURN_REQUESTED',
    UNCONSIGNED = 'UNCONSIGNED',
    RETURNED = 'RETURNED',
    EN_COURS = 'EN COURS',
}

export enum eEntryType {
    PRODUCT = 'PRODUCT (PRO)',
    SERVICE_ADDON = 'SERVICE_ADDON (SER_ADD)',
    SERVICE_STANDALONE = 'STANDALONE (SER_STA)',
}

export enum ProductSourceTypes {
    INSTORE = 'INSTORE',
    DC = 'DC',
    SHIPFROMINSTORE = 'Ship from store',
    SHIPFROMDC = 'Ship from DC',
}

export enum STHDeliveryModes {
    STH = 'STH',
    STANDARD = 'STANDARD',
    EXPRESS = 'EXPRESS',
}

export enum ExpressRejectReasons {
    TOGGLE_OFF = 'TOGGLED_OFF',
    DISTANCE_EXCEEDED = 'DISTANCE_EXCEEDED',
    WEIGHT_EXCEEDED = 'WEIGHT_EXCEEDED',
    SIZE_EXCEEDED = 'SIZE_EXCEEDED',
    NOT_ELIGIBLE = 'PRODUCT_NOT_ELIGIBLE',
    TOTAL_EXCEEDED = 'TOTAL_EXCEEDED',
}

export interface ProductOption {
    descriptor: string
    display: string
    value: string
}

export interface VehicleInformation {
    baseVehicleId?: string
    engineConfigId?: string
    body?: string
    make: string
    model: string
    options?: string
    type: string
    year: string
    vin?: string
    boltPattern?: string
}

export interface DeliveryCostType {
    currencyIso: string
    formattedValue: string
    maxQuantity: number
    minQuantity: number
    priceType: string
    value: number
}

export interface AvailableDeliveryModesType {
    code: STHDeliveryModes.STH | STHDeliveryModes.EXPRESS
    isApplicable: boolean
    name?: string
    deliveryCost?: DeliveryCostType
    description?: string
    orderIn?: string
    etaEarliest?: string | null
    etaLatest?: string | null
    rejectionReason?:
        | ExpressRejectReasons.TOGGLE_OFF
        | ExpressRejectReasons.DISTANCE_EXCEEDED
        | ExpressRejectReasons.SIZE_EXCEEDED
        | ExpressRejectReasons.WEIGHT_EXCEEDED
        | ExpressRejectReasons.NOT_ELIGIBLE
        | ExpressRejectReasons.TOTAL_EXCEEDED
    shippingFees?: Price
}

export interface CartOrderEntries {
    vehicleInformation: VehicleInformation | null
    entryNumber: number
    quantity: number
    errorCode?: string
    statusSummaryList?: []
    entryType: string
    cartEntryReference?: string
    code: string
    cancellable: boolean
    cancellableQuantity: number
    isFreeStorePickup?: boolean
    availability?: string
    images: ImageDTO[]
    brand: BrandDTO
    name: string
    color?: string
    options?: ProductOption[]
    pickupDateTime?: PickupDateTimeDTO
    deliveryDates?: DeliveryDatesDTO[]
    deliveryAddress?: AddressDTO
    availableInDC?: boolean
    availableDCQuantity?: number
    isShipToHome?: boolean
    isShipHome: boolean
    isEligibleToShipHome?: boolean
    isTireOrWheel: boolean
    maxQuantity: number
    availableQuantity?: number
    fullFillmentDaysAway?: string[]
    curbPickupInfo?: string
    isCurbside?: boolean
    timeTestedPrice?: iPrice
    price: iPrice
    discount?: Price
    originalPrice: Price
    currentPrice: Price
    displayWasLabel: boolean
    totalPrice: Price
    shippingFees?: PriceDTO
    servicesList: ServiceDTO[]
    packagedItem: boolean
    ecoFee?: iPrice
    coreCharge?: iPrice
    ecoMessage?: string
    ecoFeeIncluded?: boolean
    sku: string
    isBulk: boolean
    isServiceProduct?: boolean
    badges?: string[]
    limitedQuantity?: number
    installationEligible?: boolean
    // Analytics key
    fulfillmentOptionAvailable?: FulfillmentOptionAvailable
    corporateStatus?: string
    badge?: string[]
    fulfillmentStatus?: string
    category?: string
    level1?: string
    level2?: string
    level3?: string
    level4?: string
    level5?: string
    // TODO: Need to remove the below key. For now unable to find where it is used so keeping it. The above key has a new interface.
    // fulfillment?: DeliveryMethod
    fitted?: string
    package?: string
    fulfillmentDaysAway?: string
    fee?: string
    someoneElsePickingUp?: boolean
    pickupEmailProvided?: boolean
    pcode?: string
    instantRebateIncluded?: boolean
    iumapp?: boolean
    rebate?: Rebate
    repairOrderRequired: boolean
    ror?: boolean
    variant1?: string
    variant2?: string
    variant3?: string
    vehicle?: {
        staggeredFitment?: boolean
        type?: string
        make?: string
        model?: string
        year?: string
        body?: string
        option?: string
        vin?: string
    }
    prodCategory: string
    division?: string
    lob?: string
    businessCategory?: string
    businessSubcategory?: string
    fineLine?: string
    feeType?: string
    variant?: {
        type: string
        value: string
    }[]
    numberOfReviews?: number
    rating?: number
    shipFrom?: string
    fulfillment: CartItemFulFillmentType
    productStatus: ProductStatus
    errorResponse?: {
        errCode: string
    }
    sellable: boolean
    isBopisOnly: boolean
    isSellableStarted?: boolean
    isSellableFinished?: boolean
    isRorProduct?: boolean
    productWheelType?: productWheelTypes
    fitmentTypeCode?: FitmentTypeEnum
    feeMessages?: FeeMessage[]
    maxPurchaseQty?: number
    staggeredGroup?: string
    merchCategories?: string[]
    hasStoreAutoServiceCenter?: boolean
    isPackagedItem?: boolean
    packageId?: string
    priceMessage?: PriceMessage[]
    url?: string
    selected?: boolean
    itemAvailabilityInfo?: ItemInlineErrorType
    status?: string
    baseProduct?: string
    Rating?: number
    // TODO: Need to revisit this once api response format finalized, for now adding this attribut to check whether product is balloon product or not.
    balloonData?: BalloonData
    bulkDeliveryOptions?: BulkDeliveryOptionDTO[]
    specifications?: ProductSpecifications[]
    isInWishList?: boolean
    saleCut?: SaleCut
    estimatedDeliveryDate?: {
        startDate: string
        endDate: string
    }
    eddMax?: string
    eddMin?: string
    triangleSelectBenefits?: TriangleSelectBenefitsType
    isReusableBag?: boolean
}

export interface CartOrderEntriesWithGroupingVal extends CartOrderEntries {
    trackingID: string
    maxETA: string
    minETA: string
}

export interface BalloonData {
    balloonType: string
    heliumIncluded: boolean
    isInflated: boolean
}
export interface CartOrderVehicleInfo {
    body?: string
    make?: string
    model?: string
    options?: string
    year?: string
}

export interface CartOrderEntriesWithFilterVal extends CartOrderEntries {
    filterVal: string
}

export interface FeeMessage {
    feeDisclaimerTitle?: string
    feeDisclaimerMessage?: string
    feeTitle?: string
    type?: string
    value?: number
}

export interface StaggeredFitmentProduct extends CartOrderEntries {
    isFront?: boolean
}

export interface StockItemAvailability {
    dcQuantity: number
    storeQuantity: number
}

export enum CartOrderDeliveryModes {
    BOPIS = 'BOPIS',
    STH = 'STH',
    CURBSIDE = 'CURBSIDE',
    EXPRESS = 'EXPRESS',
}

export interface CartItemFulFillmentType {
    orderableFromDC: boolean
    isEligibleToShipHome: boolean
    isEligibleToPickupFromStore: boolean
    isEligibleForExpressDelivery: boolean
    isStorePickup: boolean
    deliveryMode: CartOrderDeliveryModes
    sourceTypes: CartItemFulfillmentSource[]
    stockItemAvailability: StockItemAvailability
    availability?: {
        Corporate: {
            Quantity: number
            MinOrderQty: number
            MaxETA: string
            MinETA: string
            orderableFromDC: boolean
        }
        quantity: number
        storeShelfLocation: string
    }
}

export interface CartItemFulfillmentSource {
    quantity: number
    productSourceType: string // INSTORE or DC
    etaEarliest: string
    etaLatest: string
    NextOrderCutOffDate: string
    MinOrderQty: number
    storeShelfLocation: string
}

export interface SingleCartItemDetailsType {
    enabled: boolean
    source: StoreDataDTO | null
    availability: ItemAvailability
    etaEarliest: string
}
export interface StorePickUp extends SingleCartItemDetailsType {
    etaTime: string
}

export interface ShipToHome extends SingleCartItemDetailsType {
    etaLatest: string
}

// TODO: Need to revisit the below enum
export enum ProductStatus {
    ACTIVE = 'ACTIVE(ACT)',
    FULLY_DISCONTINUED = 'FULLY_DISCONTINUED(FD)',
    DISCONTINUED_WHEN_OUT = 'DISCONTINUED_WHEN_OUT(DW)',
    SEASONALLY_DISCONTINUED = 'SEASONALLY_DISCONTINUED(SD)',
    TEMPORARY_DISCONTINUED = 'TEMPORARY_DISCONTINUED(TD)',
}

export interface ItemAvailability {
    Corporate: {
        Quantity: number
        MinOrderQty: number
        MinETA: string
        MaxETA: string
        NextOrderCutOffDate: string
    }
    quantity: number
    storeShelfLocation: string | null
}

export interface SiteWidePromotionalCode {
    code: string
    title: string
    description: string
    startDate: string
    endDate: string
}

export interface PromotionalCode {
    code: string
    startDate: string
    endDate: string
    promotionType: PromotionType
    promoMessage: PromoMessageType
}

export enum PromotionType {
    PRICE_BASED = 'PRICE_BASED',
    FREE_SHIPPING = 'FREE_SHIPPING',
}

export interface AppliedVoucher {
    code: string
    applied: boolean | null
    redeemingErrorCode: string
}

export interface CartItemsData {
    availableDeliveryModes: AvailableDeliveryModesType[]
    status?: string
    cancellable: boolean
    cancellableQuantity: number
    cartId: string
    code: string
    consignments: Consignment[]
    guid?: string
    created?: string
    statusDisplay?: string
    orderEntries: CartOrderEntries[]
    entries?: CartOrderEntries[]
    errorCode?: string
    errorMessage: string
    successFlag: boolean
    orderThreshold: number
    handlingFee: number
    numberOfItems: number
    totalItems?: number
    storeEligibility: boolean
    store: StoreWithAvailability
    updatedCart: CartItemsData
    cartSummary: CartSummaryDTO
    orderSummary?: CartSummaryDTO
    isBulk: boolean
    bulkDeliveryOptions: BulkDeliveryOptionDTO[]
    bulkDeliveryOption: BulkDeliveryOptionDTO
    deliveryAddress: DeliveryAddressDTO
    deliveryPointOfService?: PointOfService
    deliveryMode: string
    serviceList: ServiceDTO[] // For storing the services added on a particular item. TODO: Need to remove this.
    bopis?: CartOrderEntries[]
    sth?: CartOrderEntries[]
    contactInfo?: CheckoutContactInfo
    shippingInfo?: CheckoutShippingInfo
    orderPickupInPerson?: PickupSomeoneElse
    pickupLocation?: string
    pickupMethod?: string
    paymentInfo: CartPaymentResponse
    entryGroups: EntryGroups[]
    productWheelType?: string
    vin?: string
    requestCallback?: boolean
    customerCallbackRequested?: boolean
    saveForLaterId?: string
    flatRateActive?: boolean
    appliedPromotions?: PromotionalCode[]
    appliedVouchersData?: AppliedVoucher[]
    maxPromoCodeCount?: number
    successMessage?: string
    returnRequestedData?: Array<ReturnEntry>
    unconsignedEntries?: Array<Record<string, unknown>>
    isConsignmentPacked?: boolean
    potentialCTMoney?: number
    dropOffInstructions?: {
        signatureRequired?: boolean
        specialInstructions?: string
    }
    isEligibleForReusableBags?: boolean
    reusableBagOptIn?: boolean
    reusableBagInfo?: {
        name: string
        price: number
        images: ImageDTO[]
    }
    isModificationEnabled?: {
        contactEmail?: boolean
        contactPhone?: boolean
        deliveryAddress?: boolean
        pickupContact?: boolean
        pickupMethod?: boolean
    }
    deliveryCostStatus: DeliveryCostStatus
}

export interface MiniCartData {
    cartId: string
    numberOfItems: number
    updatedCart: {
        numberOfItems: number
    }
    orderEntries: MiniCartOrderEntries[]
    deliveryAddress: MiniCartDeliveryAddress
}

export interface MiniCartOrderEntries {
    entryType: string
    cartEntryReference: string
    fulfillment: {
        deliveryMode: string
    }
    vehicleInformation: MiniCartVehicleInformation
    code: string
    isBulk: boolean
}

export interface MiniCartVehicleInformation {
    year: string
    make: string
    model: string
    body: string
    options: string
}

export interface MiniCartDeliveryAddress {
    address: {
        postalCode: string
    }
}

export interface VersionedMiniCartData extends MiniCartData {
    version: string
}

interface ReturnEntry {
    returnEntries: Array<Record<string, unknown>>
    entries: Array<Record<string, unknown>>
    [key: string]: unknown
}

export interface UpdateDeliveryRequest {
    bannerid?: string
    cartId?: string
    entryNumber?: number
    userID?: string
    guid?: string
    deliveryMethodId?: string
    bulkDeliveryOptionType?: string
    postalCode?: postalCodeRequest
    storeId: number
}

interface postalCodeRequest {
    storeZoneId?: string
    postalCode?: string
}

export interface PickupSomeoneElse {
    emailAddress: string
    firstName: string
    lastName: string
}

export interface BulkDeliveryOptionDTO {
    deliveryOptionType: DeliveryOptionType
    wgPrice: iPrice
    isSelected?: boolean
}

export interface CartSummaryDTO {
    subTotalAmt: iPrice
    shippingFees: iPrice
    taxLines: TaxLineDTO[]
    totalTaxAmt: iPrice
    totalAmt: iPrice
    totalWithTaxesAmt: iPrice
    totalSavingAmt: iPrice
    totalRebateAmt: iPrice
    orderSummary?: CartSummaryDTO
    totalItems?: number
    totalDiscount?: iPrice
}

export interface TaxLineDTO {
    taxName: TaxName
    taxValue: iPrice
}

export enum DeliveryOptionType {
    STANDARD = 'STANDARD (STAN)',
    IN_HOME_DELIVERY = 'IN_HOME_DELIVERY (IHDE)',
    IN_HOME_DELIVERY_UNPACK = 'IN_HOME_DELIVERY_UNPACK (IHDU)',
}

export enum TaxName {
    GST = 'GST',
    HST = 'HST',
    QST = 'QST',
    PST = 'PST',
}

export interface PickupDateTimeDTO {
    etaTime: string
    etaDate: string
    quantity?: number
}

export interface DeliveryDatesDTO {
    etaEarliest: string
    etaLatest: string
    quantity: number
}

export interface StoreDataDTO {
    storeId: string
    name: string
    address: AddressDTO
    storeIntersection: string
    url: string
    openTime: string
    closeTime: string
    latitude: number
    longitude: number
    isCurbside: boolean
    curbSideFee: number
    curbPickupInfo: string
}

export interface ProductAvailabityDTO {
    name: string
    url: string
}

export interface AddressDTO {
    streetNumber: string
    streetName: string
    address1: string
    address2: string
    city: string
    province: string
    postalCode: string
}

export interface ImageDTO {
    altText: string
    mediaType: string
    isListingThumbnailImage: string
    url: string
}

export interface BrandDTO {
    label: string
    url: string
}

export interface PriceDTO {
    formattedValue: string | null
    value: number | null
    each: boolean
}

export interface DeliveryAddressDTO {
    name: string
    firstName?: string
    lastName?: string
    address: AddressDTO
    url?: string
}
export interface FilteredCartData {
    // TODO: state hierarchy need to be improved
    cart: CartItemsData
    fulfillmentCost: string | undefined
    financingEligible: boolean | undefined
    financingShown: boolean | undefined
    financingSelected: boolean | undefined
    sflAddAndMoveCart?: sflAddAndMoveCart
    installationAvailable: YesNoNotApplicable
    installationHelp: YesNoNotApplicable
    vinEntered: YesNoNotApplicable
    bopis: CartOrderEntries[]
    sth: CartOrderEntries[]
    services: CartOrderEntries[]
    storeData: StoreWithAvailability
    deliveryAddress: DeliveryAddressDTO
    selectedServiceList: CartOrderEntries[]
    successFlag?: boolean
    xhrInfo?: {
        getCartInfo?: HttpRequestState
    }
    bopisGrouped: Record<string, unknown>
    sthGrouped: Record<string, unknown>
    servicesGrouped: Record<string, unknown>
    isPostalCodePartialSuccess?: boolean
    freeShippingProgressData?: FreeShippingProgress
    freeShippingProgressModal?: FreeShippingProgressModal
    order?: CartItemsData
    isUpdateQuantity?: boolean
    availableDeliveryModes: AvailableDeliveryModesType[]
}

export interface RedeemCTMoneyResponse {
    isSuccess: boolean
    error?: Record<string, unknown>
}

export interface CartModificationDTO {
    updatedCart?: CartItemsData
    errorCode?: string
    errorMessage?: string
    successFlag: boolean
    successMessage?: string
    deliveryMethod?: DeliveryMethodIdDTO
    // TODO: need to check
    storeData?: StoreDataDTO
    deliveryAddress?: CheckoutShippingInfo
    saveForLaterId?: string
}
export interface AddServiceToCart {
    success: boolean
    showErrorToast: boolean
}

export interface AddServiceSuccessToCart {
    success: boolean
    showErrorToast: boolean
    selectedService: ServiceDTO
}

export interface DeliveryMethodIdDTO {
    deliveryMethodId: string
    deliveryMethod: DeliveryMethodDTO
}

export enum DeliveryMethodDTO {
    FREE_STORE_PICKUP = 'FREE_STORE_PICKUP',
    SHIP_TO_HOME = 'SHIP_TO_HOME',
}

export interface CartAnalytics {
    availability?: string
    corporateStatus?: string
    badge: string[] | undefined
    brand: string | undefined
    fulfillmentStatus?: string
    category: string | undefined
    level1: string | undefined
    level2: string | undefined
    level3: string | undefined
    level4: string | undefined
    level5: string | undefined
    fulfillment?: string
    fitted: boolean | undefined
    package: string | undefined
    fullfillmentDaysAway?: string
    fee: string | undefined
    financingEligible?: string
    financingShown?: string
    installationAvailable?: string
    inventory?: string
    list?: string
    name: string
    pcode?: string
    price: string | null | undefined
    rebate: string | undefined
    quantity: number | undefined
    rating: string | undefined
    ror?: boolean
    numberOfReviews: string | undefined
    shipFrom: string | undefined
    sku: string | number
    variant1: string | undefined
    variant2: string | undefined
    variant3: string | undefined
    vehicle: GlobalAnalyticsVehicle | undefined
    fulfillmentOptionAvailable?: string
    bulk: boolean | undefined
    guaranteedFitment?: string
    pdpType?: string
}

export interface VehicleAnalytics {
    type: string | undefined
    make: string | undefined
    model: string | undefined
    year: string | undefined
    body: string | undefined
    option: string | undefined
}

export interface RemoveCartItem {
    errorFlag: boolean
    errorMessage: string
}

/**
 * interface to get the list of postal code
 * TO-DO: to be removed when actual api integration is done.
 */
export interface PostalCodeData {
    invalidPC: string[]
    validPC: string[]
    outOfRangePC: string[]
}

export interface Rebate {
    iumapp?: boolean
    value?: number
    percentage?: boolean
    message?: string
    tooltipMessage?: string
    greyCopyMsg?: string
    pdfLink?: string
    priceAfterRebate?: number
}

export interface SwitchFulfillmentType {
    mode: string
    entryNumber: number
}

export interface BasicVehicleData {
    sku: string
    vehicleInformation: VehicleInformation
}

export interface FeeMessage {
    feeDisclaimerTitle?: string
    feeDisclaimerMessage?: string
    feeTitle?: string
    type?: string
    value?: number
}

export interface CartPaymentResponse {
    transactionId: string
    saveToMyProfile: boolean
    maxAllowableCTMoneyToRedeem: iPrice
    newCreditCard: boolean
    maskedCardNumber: string
    loyaltyCardNumber: string
    cardType: {
        code: string
        name: string
    }
    expiryMonth: string
    expiryYear: string
    saved: boolean
    isDigitalCard: boolean
    redeemCTMoney: boolean
    redeemCTAmount: iPrice
    eligibleForFinancing: boolean
    financingPlanId: number
    financingSelected?: string
    billingAddress: BillingAddress
    isLoyaltyRedemptionEnabled?: boolean
    giftCards?: GiftCard[]
    payPalInfo?: PayPalInfo
    remainingTotalCost?: RemainingTotalCost
    totalGiftCardAmount?: TotalGiftCardAmount
    tmcCtMoneyBalance?: number
    isPostPurchaseCTMRedemption?: boolean
}

export interface RemainingTotalCost {
    value?: number
}

export interface TotalGiftCardAmount {
    value?: number
}

export interface ETAData {
    etaEarliest: string
    etaLatest: string
}

export interface SortedAutomotiveType {
    sortedAutoList: CartOrderEntries[]
    groupedYMMETA: ETAData | undefined
}

export interface sflAddAndMoveCart {
    addedToSflSuccess?: boolean
    addedToSflFailure?: boolean
    moveToSflSuccess?: boolean
    moveToSflFailure?: boolean
    removeSflFailure?: boolean
    getSflFailure?: boolean
}

export interface ShippingEstimation {
    isFetching: boolean
    result: DeliveryCosts | null
    error: CartResponseErrorDTO | null
}

export interface FreeShippingProgress {
    type: string
    threshold: number
    awayFromFreeShipping: number
    percent: number
}

export interface FreeShippingProgressModal {
    name: string
    disclaimer: string
    shortMessage: string
}

export interface FreeShippingProgressModalDTO {
    appliedShippingPromoMessage: FreeShippingProgressModal
}

export enum FreeShippingWidgetType {
    ELIGIBLE = 'ELIGIBLE',
    ZERO_THRESHOLD = 'ZERO_THRESHOLD',
    NOT_ELIGIBLE = 'NOT_ELIGIBLE',
    PROMO_APPLIED = 'PROMO_APPLIED',
}

export interface GiftCard {
    amount: number
    cardNumber: string
    number: string
}

export interface PayPalInfo {
    amount: number
}

export interface AddedServiceToCart {
    success: boolean
    showErrorToast: boolean
    selectedService?: ServiceDTO
}

export interface XhrInfo {
    getCartInfo?: HttpRequestState
}

/**
 * Enum for differntiating the various delivery cost status
 */
export enum DeliveryCostStatus {
    UNCHANGED = 'UNCHANGED',
    APPLIED = 'APPLIED',
    UPDATED = 'UPDATED',
}
