import { DropdownType, DropdownOptionsState } from '../models/vehicleDropdowns.types'

/**
 * Reset option, value and set status NOT_LOADED
 * @param {DropdownType} drop - dropdown to clear
 */
export const resetDropdown = (drop: DropdownType): void => {
    drop.options = []
    drop.isDisabled = true
    drop.optionsState =
        drop.optionsState === DropdownOptionsState.TO_SKIP
            ? DropdownOptionsState.TO_SKIP
            : DropdownOptionsState.NOT_LOADED
    drop.value = ''
}
