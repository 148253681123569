import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { VariantsProps } from './Variants.type'
import { Variant, VariantType } from '../BuyBox/Buybox.type'
import { getSkuArray } from '../../helpers/getSkuArray.helper'
import Button from '../Button'
import Icon from '../Icon'
import { variantsConstant } from './Variants.constant'
import { isArrayNotEmpty } from '../../utils/isArrayNotEmpty'
import { magicNumber, replaceStrWithDynamicVal, updateUrlHistory } from '../../utils'
import usePrevious from '../../hooks/usePrevious.hook'
import { ProductSkusData } from '@nl/app/src/redux/models/product.interface'
import { areAllParamsValid } from '../../utils/validParams'

const {
    maxHeight,
    variantLabel,
    firstVariantName,
    secondVariantName,
    thirdVariantName,
    hideVariantClass,
    showVariantClass,
    colorCodeParam,
} = variantsConstant

const listItems = (
    variantData: VariantType[],
    setSelectedFirstVariantValue: (variant: string) => void,
    firstVariantStyleItem: string,
    setSelectedFirstVariantIdValue: (id: string) => void,
    setSelectedSecondVariantValue: (variant: string) => void,
    setSelectedSecondVariantIdValue: (id: string) => void,
    setSelectedThirdVariantValue: (variant: string) => void,
    setSelectedThirdVariantIdValue: (id: string) => void,
    variantKey: string,
    setSelectedVariantValue: React.Dispatch<React.SetStateAction<string>>,
    variantValue: string,
    variantId: string,
    isAddToCartClicked: boolean,
    isNoSkuFindStoreClicked: boolean,
    a11yVariantSelected: string,
    a11yVariantUnSelected: string,
    variantRef: number,
    setIsFirstVariantClicked: (isClicked: boolean) => void,
    variantClickHandler: () => void,
    isColourSwatchesActive: boolean,
    variantsProductSkuData: ProductSkusData,
    checkOutOfStock: (item: VariantType, variantKey: string) => boolean,
    enableHidingFirstVariantWhereAllSkusOOS: boolean,
    selectedProductCode: string,
    isAutomotive: boolean,
) => {
    //  Function to set selected variant value
    /**
     * Function to set selected variant value
     * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event
     * @param {VariantType} selectedItem
     */
    const setVariant = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, selectedItem: VariantType): void => {
        event.stopPropagation()
        variantClickHandler()
        if (!firstVariantStyleItem) {
            switch (variantKey) {
                case firstVariantName:
                    setSelectedFirstVariantValue(selectedItem.name)
                    setSelectedFirstVariantIdValue(selectedItem.id)
                    setIsFirstVariantClicked(true)
                    setSelectedColorCodeToUrl(selectedItem.id)
                    break
                case secondVariantName:
                    setSelectedSecondVariantValue(selectedItem.name)
                    setSelectedSecondVariantIdValue(selectedItem.id)
                    setIsFirstVariantClicked(false)
                    break
                default:
                    setSelectedThirdVariantValue(selectedItem.name)
                    setSelectedThirdVariantIdValue(selectedItem.id)
                    setIsFirstVariantClicked(false)
            }
        }
    }

    /**
     * Function to set selected Color to Url
     * @param {string} selectedColor
     */
    const setSelectedColorCodeToUrl = (selectedColor: string): void => {
        const urlSearchParams = new URLSearchParams(window.location.search)

        urlSearchParams.set(colorCodeParam, selectedColor)
        updateUrlHistory('?' + decodeURIComponent(urlSearchParams.toString()))
    }

    /**
     * Function to remove class on removing focus from element
     */
    const onRemoveFocusVariant = (): void => {
        setSelectedVariantValue(variantValue)
    }

    /**
     * Function to add class on focusing on element
     * @param {VariantType} item
     */
    const onFocusVariant = (item: VariantType): void => {
        setSelectedVariantValue(item.name)
    }

    /**
     * Function to set hoved variant value
     * @param {VariantType} item
     */
    const onHoveredVariant = (item: VariantType): void => {
        setSelectedVariantValue(item.name)
    }

    /**
     * Function to get variant show/hide class
     * @param {boolean} isUnavailableVariant
     * @return {string}
     */
    const getVariantClass = (isUnavailableVariant: boolean): string => {
        if (isUnavailableVariant) {
            if (variantRef === magicNumber.ONE && variantKey === firstVariantName) {
                return showVariantClass
            } else {
                return hideVariantClass
            }
        } else {
            return showVariantClass
        }
    }

    /**
     * This function will return true if product is Automotive and selectedProductCode present in item's skuCodes or product is not a Automotive and variantValue is same as item's name
     * @param {VariantType} item
     * @return {boolean}
     */
    const isPillsSelected = (item: VariantType): boolean => {
        return !!isAutomotive ? item?.skuCodes?.includes(selectedProductCode) : item?.id === variantId
    }

    /**
     * Function to get variant assigned class selected/unselected
     * @param {VariantType} item
     * @return {string}
     */

    const getVariantAssignedClass = (item: VariantType): string => {
        const isColorSwathesStylesNeeded = isColourSwatchesActive && !!item.colourSwatchImageUrl
        const colourSwathesClass = isColorSwathesStylesNeeded ? `${PREFIX}-variants__variant--colour-swatches` : ''
        const outOfStockClass =
            variantsProductSkuData?.skus && checkOutOfStock(item, variantKey) ? `${PREFIX}-variants__disabled` : ''
        const defaultClass = `${
            firstVariantStyleItem ? firstVariantStyleItem : `${PREFIX}-variants__variant ${colourSwathesClass}`
        } ${outOfStockClass}`
        const warningClass =
            (isAddToCartClicked || isNoSkuFindStoreClicked) && !variantValue
                ? `${PREFIX}-variants__variant--warning`
                : ''

        return isPillsSelected(item)
            ? `${defaultClass} ${PREFIX}-variants__variant--selected`
            : `${defaultClass} ${PREFIX}-variants__variant--unselected ${warningClass}`
    }

    return (
        isArrayNotEmpty(variantData) &&
        variantData.map((variantObj: VariantType, index: number) => {
            const isVariantSelected = () => variantObj?.id === variantId
            const isColorSwathesStylesNeeded = isColourSwatchesActive && !!variantObj.colourSwatchImageUrl
            const disabledColourSwathes = () => isColorSwathesStylesNeeded && checkOutOfStock(variantObj, variantKey)
            const buttonLabel = isVariantSelected()
                ? replaceStrWithDynamicVal(a11yVariantSelected, variantObj.name)
                : replaceStrWithDynamicVal(a11yVariantUnSelected, variantObj.name)

            const isFirstVariantOOS = () =>
                areAllParamsValid(
                    secondVariantName in variantObj,
                    checkOutOfStock(variantObj, variantKey),
                    enableHidingFirstVariantWhereAllSkusOOS,
                )

            if (isFirstVariantOOS()) {
                if (!isVariantSelected()) {
                    return null
                }
                const firstAvailableVariant = variantData?.find(
                    (variant: VariantType) => !checkOutOfStock(variant, variantKey),
                )
                setSelectedFirstVariantValue(firstAvailableVariant?.name)
                setSelectedFirstVariantIdValue(firstAvailableVariant?.id)
            }

            return (
                <li
                    key={index}
                    className={
                        firstVariantStyleItem
                            ? `${PREFIX}-variants__color-list--list`
                            : `${PREFIX}-variants__variant-item ${getVariantClass(variantObj.isUnavailable)}`
                    }>
                    {disabledColourSwathes() && <div className="colour-swathes--disable" />}
                    <button
                        className={getVariantAssignedClass(variantObj)}
                        aria-label={buttonLabel}
                        data-testid={`${variantLabel}${index}`}
                        onClick={event => setVariant(event, variantObj)}
                        onMouseEnter={() => onHoveredVariant(variantObj)}
                        onMouseLeave={() => {
                            setSelectedVariantValue('')
                        }}
                        onFocus={() => onFocusVariant(variantObj)}
                        onBlur={() => onRemoveFocusVariant()}>
                        {isColorSwathesStylesNeeded && (
                            <img src={variantObj.colourSwatchImageUrl} alt="ColourVariant" />
                        )}
                        {firstVariantStyleItem && (
                            <img
                                className={`${PREFIX}-variants__variant-image`}
                                alt="ColourVariant"
                                src={variantObj.name}
                            />
                        )}
                        {!isColorSwathesStylesNeeded && (
                            <span className={`${PREFIX}-variants__variant-text`}>{variantObj.name}</span>
                        )}
                    </button>
                </li>
            )
        })
    )
}

/**
 * Variant component
 * @param {VariantsProps} props -colourVariants,
 *      selectedFirstVariant,
 *      setSelectedFirstVariantValue,
 *      firstVariantStyle,
 *      firstVariantStyleItem,
 * @return {JSX.Element} returns Variant Component
 */
const Variants: React.FC<VariantsProps> = props => {
    const {
        variantsData,
        selectedFirstVariant,
        setSelectedFirstVariantValue,
        firstVariantStyle,
        firstVariantStyleItem,
        setSelectedFirstVariantIdValue,
        selectedFirstVariantId,
        selectedSecondVariantId,
        selectedThirdVariantId,
        variantOptions,
        path,
        viewMoreLabel,
        viewLessLabel,
        setSelectedSecondVariantValue,
        setSelectedSecondVariantIdValue,
        setSelectedThirdVariantValue,
        setSelectedThirdVariantIdValue,
        setIsVariantSelected,
        variantKey,
        variantDetails,
        selectedSecondVariant,
        selectedThirdVariant,
        isAddToCartClicked,
        isNoSkuFindStoreClicked,
        a11yVariantSelected,
        a11yVariantUnSelected,
        isFirstVariantClicked,
        setIsFirstVariantClicked,
        variantClickHandler,
        isColourSwatchesActive,
        setShowSizeChart,
        showSizeChart,
        isSizeChartAvailable,
        sizeChartButtonTitle,
        isOnlyOneVariant,
        variantsProductSkuData,
        checkOutOfStock,
        enableHidingFirstVariantWhereAllSkusOOS,
        selectedProductCode,
        isAutomotive,
    } = props

    const [variantData, setVariantData] = useState(variantsData?.values)
    const [showViewMore, setShowViewMore] = useState(false)
    const [showViewMoreOrLess, setShowViewMoreOrLess] = useState(true)
    const [selectedVariantValue, setSelectedVariantValue] = useState('')
    const [variantValue, setVariantValue] = useState('')
    const [variantId, setVariantId] = useState('')

    const viewMoreVariantClass = !showViewMore
        ? `${PREFIX}-variants__variant-list--expanded`
        : `${PREFIX}-variants__variant-list--collapsed`
    const variantEle = document.querySelector(`.${PREFIX}-variants__${variantKey}`)
    const selectedElemt = document.querySelector(
        `.${PREFIX}-variants__${variantKey} .${PREFIX}-variants__variant--selected`,
    )
    const variantRef = useRef(0)

    const { firstVariant, secondVariant, thirdVariant } = variantDetails || {}

    const previousSelectedFirstVariantId = usePrevious(selectedFirstVariantId)
    const previousSelectedSecondVariantId = usePrevious(selectedSecondVariantId)
    const previousSelectedThirdVariantId = usePrevious(selectedThirdVariantId)
    const previousShowViewMore = usePrevious(showViewMore)
    const previousIsFirstVariantClicked = usePrevious(isFirstVariantClicked)
    const previousSelectedFirstVariant = usePrevious(selectedFirstVariant)

    // useEffect to set view more/less section state
    useEffect(() => {
        if (variantEle instanceof HTMLElement) {
            setShowViewMoreOrLess(variantEle && variantEle.scrollHeight > maxHeight)
            const variantEleTop = variantEle?.getBoundingClientRect().top || 0
            const selectedElemtTop = selectedElemt?.getBoundingClientRect().top || 0
            selectedElemtTop > maxHeight + variantEleTop ? setShowViewMore(false) : setShowViewMore(true)
        }
    }, [setShowViewMoreOrLess, setShowViewMore, variantEle, variantData, selectedElemt])

    /**
     * Function to get first variant values
     * @return {VariantType} returns first variant values
     */
    const getVariantData = useCallback((): VariantType => {
        return isArrayNotEmpty(variantData) && firstVariant?.values?.find(item => item?.id === selectedFirstVariantId)
    }, [firstVariant, selectedFirstVariantId, variantData])

    const setFilteredFirstData = useCallback(() => {
        const getSelectedFirstVariant: VariantType = getVariantData()
        switch (variantKey) {
            case secondVariantName:
                getSelectedFirstVariant && secondVariant && setVariantData([...getSelectedFirstVariant.secondVariant])
                break
            case thirdVariantName:
                getSelectedFirstVariant && thirdVariant && setVariantData([...getSelectedFirstVariant.thirdVariant])
                break
            default:
        }
    }, [getVariantData, secondVariant, thirdVariant, variantKey])

    /**
     * useEffect used to set the filtered first variant data
     */
    useEffect(() => {
        if (
            (selectedFirstVariant !== previousSelectedFirstVariant ||
                isFirstVariantClicked !== previousIsFirstVariantClicked) &&
            (variantRef.current === magicNumber.ZERO || isFirstVariantClicked)
        ) {
            setFilteredFirstData()
        }
    }, [
        isFirstVariantClicked,
        previousIsFirstVariantClicked,
        previousSelectedFirstVariant,
        selectedFirstVariant,
        setFilteredFirstData,
    ])

    // useEffect to set initial scroll position
    useEffect(() => {
        showViewMore &&
            showViewMore !== previousShowViewMore &&
            variantEle &&
            variantEle.scrollTo &&
            variantEle.scrollTo({
                top: 0,
            })
    }, [showViewMore, previousShowViewMore, variantEle, variantData])

    /**
     * Function to set the selected variant value
     */
    const getSelectedVariantValue = useCallback((): void => {
        switch (variantKey) {
            case firstVariantName:
                setVariantValue(selectedFirstVariant)
                setVariantId(selectedFirstVariantId)
                break
            case secondVariantName:
                setVariantValue(selectedSecondVariant)
                setVariantId(selectedSecondVariantId)
                break
            default:
                setVariantValue(selectedThirdVariant)
                setVariantId(selectedThirdVariantId)
        }
    }, [
        selectedFirstVariant,
        selectedSecondVariant,
        selectedThirdVariant,
        variantKey,
        selectedFirstVariantId,
        selectedSecondVariantId,
        selectedThirdVariantId,
    ])

    const getAvailableVariantOption = useCallback(() => {
        const variantArr = [selectedFirstVariantId]
        selectedSecondVariantId && variantArr.push(selectedSecondVariantId)
        selectedThirdVariantId && variantArr.push(selectedThirdVariantId)
        const getSkuArr = getSkuArray(variantArr, variantOptions)
        if (variantKey === firstVariantName) {
            const getFirstVatiants = variantData.map((firstVariantValue: VariantType) => {
                return {
                    ...firstVariantValue,
                    isUnavailable: !firstVariantValue.skuCodes.some((sku: string) => getSkuArr.includes(sku)),
                }
            })
            getFirstVatiants && setVariantData(getFirstVatiants)
        }
        variantRef.current = magicNumber.ONE
    }, [
        selectedFirstVariantId,
        selectedSecondVariantId,
        selectedThirdVariantId,
        variantData,
        variantKey,
        variantOptions,
    ])

    /**
     * useEffect used to get the available variant option on selected color & size or dynamic variant
     */
    useEffect(() => {
        if (
            variantData &&
            selectedFirstVariantId &&
            (selectedSecondVariantId || selectedThirdVariantId) &&
            (selectedFirstVariantId !== previousSelectedFirstVariantId ||
                selectedSecondVariantId !== previousSelectedSecondVariantId ||
                selectedThirdVariantId !== previousSelectedThirdVariantId)
        ) {
            getAvailableVariantOption()
        }
        getSelectedVariantValue()
    }, [
        getAvailableVariantOption,
        getSelectedVariantValue,
        previousSelectedFirstVariantId,
        previousSelectedSecondVariantId,
        previousSelectedThirdVariantId,
        selectedFirstVariantId,
        selectedSecondVariantId,
        selectedThirdVariantId,
        variantData,
    ])

    /**
     * useEffect used to get the available variant option on selected color & dynamic variant
     */
    useEffect(() => {
        if (
            variantData &&
            selectedFirstVariantId &&
            variantKey === secondVariantName &&
            (selectedFirstVariantId !== previousSelectedFirstVariantId ||
                selectedThirdVariantId !== previousSelectedThirdVariantId)
        ) {
            const variantArr = [selectedFirstVariantId, selectedThirdVariantId]
            const getSkuArr = getSkuArray(variantArr, variantOptions)
            const getSizeVariants = variantData.map((size: Variant) => {
                return {
                    ...size,
                    isUnavailable: !size.skuCodes.some((sku: string) => getSkuArr.includes(sku)),
                }
            })

            const isAvailable = Boolean(getSizeVariants.find((item: Variant) => item.isUnavailable === false))

            if (isArrayNotEmpty(getSizeVariants) && !isAvailable) {
                setIsVariantSelected(true)
                setSelectedSecondVariantIdValue('')
                setSelectedSecondVariantValue('')
            } else {
                setIsVariantSelected(false)
            }
            getSizeVariants && setVariantData(getSizeVariants)
        }
    }, [
        selectedFirstVariantId,
        selectedThirdVariantId,
        previousSelectedFirstVariantId,
        previousSelectedThirdVariantId,
        setIsVariantSelected,
        setSelectedSecondVariantIdValue,
        setSelectedSecondVariantValue,
        variantData,
        variantKey,
        variantOptions,
    ])

    /**
     * useEffect used to get the available variant option on selected color & size variant
     */
    useEffect(() => {
        if (
            variantData &&
            selectedFirstVariantId &&
            selectedSecondVariantId &&
            variantKey === thirdVariantName &&
            (selectedFirstVariantId !== previousSelectedFirstVariantId ||
                selectedSecondVariantId !== previousSelectedSecondVariantId)
        ) {
            const variantArr = [selectedFirstVariantId, selectedSecondVariantId]
            const getSkuArr = getSkuArray(variantArr, variantOptions)
            const getDynamicVatiants = variantData.map((dynamicItem: Variant) => {
                return {
                    ...dynamicItem,
                    isUnavailable: !dynamicItem.skuCodes.some((sku: string) => getSkuArr.includes(sku)),
                }
            })

            const isAvailable = Boolean(getDynamicVatiants.find((item: Variant) => item.isUnavailable === false))

            if (isArrayNotEmpty(getDynamicVatiants) && !isAvailable) {
                setIsVariantSelected(true)
                setSelectedThirdVariantIdValue('')
                setSelectedThirdVariantValue('')
            } else {
                setIsVariantSelected(false)
            }
            getDynamicVatiants && setVariantData(getDynamicVatiants)
        }
    }, [
        selectedFirstVariantId,
        selectedSecondVariantId,
        previousSelectedFirstVariantId,
        previousSelectedSecondVariantId,
        setIsVariantSelected,
        setSelectedThirdVariantIdValue,
        setSelectedThirdVariantValue,
        variantData,
        variantKey,
        variantOptions,
    ])

    /**
     * useEffect to reset the selected variant value on first variant change
     */
    useEffect(() => {
        if (selectedFirstVariantId && !selectedSecondVariantId && !selectedThirdVariantId) {
            setSelectedVariantValue('')
        }
    }, [selectedFirstVariantId, selectedSecondVariantId, selectedThirdVariantId])

    /**
     * Function to get variant label text
     * @return {JSX.Element} returns variant label text
     */
    const getVariantLabel = (): JSX.Element => {
        const isSizeChartButtonVisible = isOnlyOneVariant
            ? variantKey === firstVariantName
            : variantKey === secondVariantName
        const sizeChart =
            isSizeChartButtonVisible && isSizeChartAvailable ? (
                <button onClick={() => setShowSizeChart(!showSizeChart)} className={`${PREFIX}-variants__size-chart`}>
                    {sizeChartButtonTitle}
                </button>
            ) : null
        return (
            !firstVariantStyle && (
                <div className={`${PREFIX}-variants__label`}>
                    <div className={`${PREFIX}-variants__label__position`}>
                        <label className={`${PREFIX}-h4--sm`} id={`variant-type-label-${variantKey}`}>
                            {variantsData?.display}:
                        </label>
                        <p className={`${PREFIX}-body-md ${PREFIX}-variants__label__text`}>
                            {selectedVariantValue ? selectedVariantValue : variantValue}
                        </p>
                    </div>
                    {sizeChart}
                </div>
            )
        )
    }

    /**
     * Function to get variant warning message
     * @param {string} variantName
     * @return {JSX.Element} returns variant warning message
     */
    const showVariantWarningMessage = (variantName: string): JSX.Element => {
        return (
            (isAddToCartClicked || isNoSkuFindStoreClicked) &&
            !variantValue && (
                <div className={`${PREFIX}-variants__warning`}>
                    <Icon type="ct-warning" size="lg"></Icon>
                    {variantName && (
                        <p className={`${PREFIX}-body-sm ${PREFIX}-variants__warning__text`}>
                            {replaceStrWithDynamicVal(props.variantWarningLabel, variantName)}
                        </p>
                    )}
                </div>
            )
        )
    }

    /**
     * useEffect to reset selected second and third options if not available
     */
    useEffect(() => {
        const selectedVariant = variantData?.find(item => item?.id === variantId)
        if (variantKey !== firstVariantName && selectedVariant?.isUnavailable) {
            variantKey === thirdVariantName && setSelectedVariantValue('')
            if (variantDetails?.thirdVariant) {
                setSelectedThirdVariantIdValue('')
                setSelectedThirdVariantValue('')
            } else {
                setSelectedSecondVariantIdValue('')
                setSelectedSecondVariantValue('')
            }
        }
    }, [
        setSelectedSecondVariantIdValue,
        setSelectedSecondVariantValue,
        setSelectedThirdVariantIdValue,
        setSelectedThirdVariantValue,
        variantData,
        variantDetails,
        variantKey,
        variantId,
    ])

    return (
        <div className={`${PREFIX}-col-md-12 ${PREFIX}-col-sm-9 ${PREFIX}-col-xs-6`}>
            {isArrayNotEmpty(variantData) ? (
                <>
                    {getVariantLabel()}
                    {showVariantWarningMessage(variantsData?.display)}
                    <ul
                        aria-labelledby={`variant-type-label-${variantKey}`}
                        className={
                            firstVariantStyle
                                ? firstVariantStyle
                                : `${PREFIX}-variants__variant-list ${viewMoreVariantClass} ${PREFIX}-variants__${variantKey}`
                        }>
                        {listItems(
                            variantData,
                            setSelectedFirstVariantValue,
                            firstVariantStyleItem,
                            setSelectedFirstVariantIdValue,
                            setSelectedSecondVariantValue,
                            setSelectedSecondVariantIdValue,
                            setSelectedThirdVariantValue,
                            setSelectedThirdVariantIdValue,
                            variantKey,
                            setSelectedVariantValue,
                            variantValue,
                            variantId,
                            isAddToCartClicked,
                            isNoSkuFindStoreClicked,
                            a11yVariantSelected,
                            a11yVariantUnSelected,
                            variantRef.current,
                            setIsFirstVariantClicked,
                            variantClickHandler,
                            isColourSwatchesActive,
                            variantsProductSkuData,
                            checkOutOfStock,
                            enableHidingFirstVariantWhereAllSkusOOS,
                            selectedProductCode,
                            isAutomotive,
                        )}
                    </ul>
                    {showViewMoreOrLess && (
                        <div className={`${PREFIX}-variants__view-more`}>
                            <Button type="tertiary" size="mini" onClick={() => setShowViewMore(!showViewMore)}>
                                {showViewMore ? viewMoreLabel : viewLessLabel}{' '}
                                <Icon type={showViewMore ? 'ct-chevron-down' : 'ct-chevron-up'} size="md" path={path} />
                            </Button>
                        </div>
                    )}
                </>
            ) : (
                ''
            )}
        </div>
    )
}
Variants.propTypes = {
    variantsData: PropTypes.any,
    selectedFirstVariant: PropTypes.string,
    setSelectedFirstVariantValue: PropTypes.func,
    firstVariantStyle: PropTypes.string,
    firstVariantStyleItem: PropTypes.string,
    selectedSecondVariant: PropTypes.string,
    selectedThirdVariant: PropTypes.string,
    setSelectedFirstVariantIdValue: PropTypes.func,
    selectedFirstVariantId: PropTypes.string,
    selectedSecondVariantId: PropTypes.string,
    selectedThirdVariantId: PropTypes.string,
    variantOptions: PropTypes.array,
    path: PropTypes.string,
    viewMoreLabel: PropTypes.string,
    viewLessLabel: PropTypes.string,
    setSelectedSecondVariantValue: PropTypes.func,
    setSelectedSecondVariantIdValue: PropTypes.func,
    setSelectedThirdVariantValue: PropTypes.func,
    setSelectedThirdVariantIdValue: PropTypes.func,
    variantKey: PropTypes.string,
    variantDetails: PropTypes.any,
    setIsVariantSelected: PropTypes.func,
    variantWarningLabel: PropTypes.string,
    isAddToCartClicked: PropTypes.bool,
    a11yVariantSelected: PropTypes.string,
    a11yVariantUnSelected: PropTypes.string,
    isFirstVariantClicked: PropTypes.bool,
    setIsFirstVariantClicked: PropTypes.func,
    enableHidingFirstVariantWhereAllSkusOOS: PropTypes.bool,
    isAutomotive: PropTypes.bool,
}
export default Variants
