import React, { useEffect, useState, Validator } from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import ProductInfo from '../ProductInfo'
import VehicleProductInformation from './VehicleProductInformation'
import {
    vehicleRearLabelValue,
    rearTireCode,
    rearTireCurrentPrice,
    setOf4Price,
    frontTireSpecification,
    rearTireSpecification,
    rearTireDisplayWasLabel,
    rearTireOriginalPrice,
    getFeeValue,
    rearPriceMessage,
    rearBadgeList,
    getRebateDetails,
} from './VehicleProduct.helper'
import { BannerType, productWheelTypes } from '../VehicleBanner/VehicleBanner.type'
import { VehicleCardPropTypes } from './VehicleProductCard.type'
import {
    staggeredSkuLength,
    minimumSpecificationCount,
    maximumSpecificationCount,
    iconTypes,
} from './VehicleProduct.constant'
import { DisplayViewType } from '../GridListToggle/buttonConstant.type'
import AutoPartsProductInformation from './AutoPartsProductInformation'
import { FitmentNote } from '../ProductGridView/ProductGrid.types'
import RoadRatingBadge from '../RoadRatingBadge/RoadRatingBadge'
import { replaceStrWithDynamicVal, addDynamicToken } from '../../utils/replaceStrWithDynamicVal'
import { Specification } from '../ProductReusableCard/product.types'
import { ProductSku } from '../VariantsWrapper/VariantsWrapper.type'
import Icon from '../Icon'
import { isArrayNotEmpty, magicNumber, stringKeyCodes } from '../../utils'
import { productCardScrollToTop } from '../../utils/ProductCardScrollToTop/productCardScrollToTop'
import { ProductCardScrollEvent } from '../../utils/ProductCardScrollToTop/productCardScrollToTop.type'
import { getFormattedPriceValue } from '../ProductReusableCard/instanceCheckingUtil'
import { ProductCardType } from '../ProductGridView/ProductGrid.types'
import { getAccessibilityAvailabilityId, getAccessibilityTitleId } from '../../utils/getAccessibilityId'
import { checkValidData } from '../../utils/checkDataLength'

/**
 * ProductCard component
 * @param {VehicleCardPropTypes} props
 * @return {JSX.Element} returns ProductCardVariants,ProductInfo,Price,Badges
 */
const VehicleProductCard: React.FC<VehicleCardPropTypes> = props => {
    const GridView = props.cardType === DisplayViewType.grid.toLowerCase()
    const ratingsAndReview = props.productProps.ratingsAndReview
    const { fitmentCriticalNotesLabel, vehicleSpecificFitLabel } = props.productProps
    const [updatedFitmentNotesList, setFitmentNotes] = useState<null | FitmentNote[]>(null)
    const [selectedSKUData, setSelectedSkuData] = useState<null | ProductSku>()
    const isStaggeredState = props.skus?.length === staggeredSkuLength && props.vehicleInformationPresent
    const returnPolicy = props.returnPolicy
    const isLazyRequire = props.isLazyRequire

    /**
     *return image of product
     * @return {string}
     */
    const productImageRendering = (): string => {
        return props.images && props.images?.[0]?.url
    }

    useEffect(() => {
        if (props.isAutoPartPlp) {
            const sku = props.skus?.[0]
            const fitment = sku?.fitment
            const fitmentData: FitmentNote[] = []
            if (fitment) {
                setSelectedSkuData(sku)
                fitment.fitmentNotes?.forEach(fitmentNote => {
                    fitmentData.push({ sku: fitment.sku, title: fitmentNote?.title, note: fitmentNote?.note })
                    return isArrayNotEmpty(fitmentData) ? fitmentData : null
                })
            }
            setFitmentNotes(fitmentData.length ? fitmentData : null)
        }
    }, [props.isAutoPartPlp, props.skus])

    /**
     * function to return rating of vehicle
     *
     * @return {JSX.Element}
     */
    const ratingsView = (): JSX.Element => {
        // TODO: if cds sends rating as an object i.e. averageRating and number of reviews need to pass the object keys as the props.
        return (
            ratingsAndReview && (
                <div
                    className={`${PREFIX}-reviews__list`}
                    data-bv-show="inline_rating"
                    data-bv-product-id={props.productDataId}
                    data-bv-seo="false"
                    aria-hidden={true}
                />
            )
        )
    }

    /**
     * function to return product rating info
     *
     * @return {JSX.Element}
     */
    const showRating = (): JSX.Element => {
        return (
            props.showRatingSection && (
                <>
                    {ratingsView()}
                    {props.productWheelType?.toUpperCase() === productWheelTypes.Tire && (
                        <RoadRatingBadge
                            roadRatedLabel={props.roadRatedLabel}
                            aggregateValue={props.roadRating?.aggregateValue}
                            isReviewAvailable={props.ratingsCount > 0}
                            ariaHidden={true}
                        />
                    )}
                </>
            )
        )
    }

    /**
     * function return skucode code for Complete Vehicle State  and pcode for partial Vehicle State for non-staggered
     *
     * @return {string}
     */
    const codeValueForVehicle = (): string => {
        return props.vehicleFitType === BannerType.Fit ? props.skus?.[0]?.formattedCode : ''
    }

    /**
     * function return skucode code for Complete Vehicle State  and pcode for partial Vehicle State in Autoparts
     *
     * @return {string}
     */
    const codeValueForAutoParts = (): string => {
        const formattedCode = codeValueForVehicle()
        return isArrayNotEmpty(props.skus) && props.isAutoPartPlp && props.vehicleInformationPresent
            ? !!props.skus?.[0]?.partNumber
                ? `${formattedCode} | ${props.partNumberLabel} ${props.skus?.[0]?.partNumber}`
                : `${formattedCode}`
            : ''
    }
    const code = props.isAutoPartPlp ? codeValueForAutoParts() : null
    const productCodeDisplay = (): JSX.Element => {
        return (
            code && (
                <p className={`${PREFIX}-product__code`} aria-hidden={true}>
                    #{code}
                </p>
            )
        )
    }

    /**
     * function to display fitment details
     * @param { string | number } displayNote
     * @param { string } displayName
     * @return { JSX.Element }
     */
    const displayFitmentDetails = (displayNote: string | number, displayName: string): JSX.Element => {
        return (
            <li key={displayNote}>
                <span className={`${PREFIX}-product-card__fitment-label`}>{displayName}: </span>
                <span className={`${PREFIX}-product-card__fitment-value`}>{displayNote}</span>
            </li>
        )
    }
    /**
     * function to display usage or position details
     * @param {FitmentNote} displayList
     * @param {string} displayName
     * @return { JSX.Element[] }
     */
    const displayUsageOrPositionDetails = (displayList: FitmentNote[], displayName: string): JSX.Element[] => {
        return displayList?.map((usageValue: FitmentNote) => {
            return displayFitmentDetails(usageValue.note, displayName)
        })
    }

    /**
     * function  to show critical Fitment Notes
     * @return {JSX.Element|null}
     */

    const specifications = (): JSX.Element | null => {
        const updatedFitmentNotes = updatedFitmentNotesList?.slice(minimumSpecificationCount, maximumSpecificationCount)
        const fitment = props.skus?.[0]?.fitment
        return (
            props.vehicleFitType === BannerType.Fit &&
            props.isAutoPartPlp &&
            // cover case of empty fitment object
            checkValidData(fitment) && (
                <div className={`${PREFIX}-product-card__specific-information`} aria-hidden={true}>
                    <p className={`${PREFIX}-product-card__critical-fitment`}>{fitmentCriticalNotesLabel}</p>
                    <ul className={`${PREFIX}-product-card__fitment-list`}>
                        {displayUsageOrPositionDetails(fitment?.usage, props.attributeUsageLabel)}
                        {displayUsageOrPositionDetails(fitment?.position, props.attributePositionLabel)}
                        {fitment?.suggestedQty &&
                            Number(fitment?.suggestedQty) > magicNumber.ONE &&
                            displayFitmentDetails(fitment?.suggestedQty, props.suggestedQuantityLabel)}
                        {updatedFitmentNotes?.map((value: FitmentNote) => {
                            return (
                                <li key={value.note}>
                                    {value.title && (
                                        <span className={`${PREFIX}-product-card__fitment-label`}>{value.title}: </span>
                                    )}
                                    <span className={`${PREFIX}-product-card__fitment-value`}>{value.note}</span>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            )
        )
    }

    /**
     * function to display vehicle product list
     *
     * @return {JSX.Element}
     */
    const productVehicleCardData = (): JSX.Element => {
        const feeValue = getFeeValue(
            props.skus,
            props.feeValue,
            isStaggeredState,
            props.vehicleInformationPresent,
            props.tireInformationPresent,
        )
        return props.isAutoPartPlp ? (
            <AutoPartsProductInformation
                {...props}
                isVehicleFullState={props.vehicleInformationPresent}
                selectedSKUData={selectedSKUData}
                a11yStrikeOutPrice={props.a11yStrikeOutPrice}
                a11yStrikeOutPriceRange={props.a11yStrikeOutPriceRange}
                overridePriceHeight={props.overridePriceHeight}
                feeMessages={props.feeMessages}
                accessibilityId={props.accessibilityId}
            />
        ) : (
            <VehicleProductInformation
                vehicleRearLabel={vehicleRearLabelValue(isStaggeredState, props.perTireLabel, props.vehicleRearLabel)}
                rearTireSpecification={rearTireSpecification(
                    props.skus,
                    props.specification,
                    isStaggeredState,
                    props.productCardSpecifications,
                )}
                rearTireCode={rearTireCode(
                    props.skus,
                    codeValueForVehicle(),
                    isStaggeredState,
                    props.vehicleInformationPresent,
                    props.tireInformationPresent,
                )}
                rearTireCurrentPrice={rearTireCurrentPrice(
                    props.skus,
                    props.currentPrice,
                    isStaggeredState,
                    props.vehicleInformationPresent,
                    props.tireInformationPresent,
                )}
                rearTireOriginalPrice={rearTireOriginalPrice(
                    props.skus,
                    props.originalPrice,
                    isStaggeredState,
                    props.vehicleInformationPresent,
                    props.tireInformationPresent,
                )}
                frontTireSpecification={frontTireSpecification(
                    props.skus,
                    isStaggeredState,
                    props.productCardSpecifications,
                    props.specification,
                )}
                totalCurrentPrice={setOf4Price(
                    props.skus,
                    props.totalCurrentPrice,
                    isStaggeredState,
                    props.vehicleInformationPresent,
                    props.tireInformationPresent,
                )}
                frontTireCode={props.skus?.[0]?.formattedCode}
                setOfFourLabel={props.setOfFourLabel}
                skus={props.skus}
                vehicleFrontLabel={props.vehicleFrontLabel}
                language={props.language}
                feeTitle={
                    feeValue
                        ? replaceStrWithDynamicVal(
                              addDynamicToken(props.feeTitle, '$x'),
                              getFormattedPriceValue(props.language, feeValue),
                          )
                        : ''
                }
                vehicleFitType={props.vehicleFitType}
                rebate={getRebateDetails(
                    props.skus,
                    props.rebate,
                    isStaggeredState,
                    props.vehicleInformationPresent,
                    props.tireInformationPresent,
                )}
                rebateIcon={props.rebateIcon}
                eachLabel={props.eachLabel}
                isNonStaggeredGrid={isNonStaggeredGrid()}
                badges={rearBadgeList(
                    props.skus,
                    props.badges,
                    isStaggeredState,
                    props.vehicleInformationPresent,
                    props.tireInformationPresent,
                    props.exclude,
                )}
                productLevelBadges={props.badges}
                badgePriorities={props.badgePriorities}
                hideDisclaimer={props.hideDisclaimer}
                originalPrice={props.originalPrice}
                feeDisclaimerMessage={props.feeDisclaimerMessage}
                nowFromLabel={props.nowFromLabel}
                saveFromLabel={props.saveFromLabel}
                wasFromLabel={props.wasFromLabel}
                fromLabel={props.fromLabel}
                discount={props.discount}
                promotionalPriceLabel={props.promotionalPriceLabel}
                unitPriceLabel={props.unitPriceLabel}
                displayWasLabel={props.displayWasLabel}
                rearTireDisplayWasLabel={rearTireDisplayWasLabel(
                    props.skus,
                    props.displayWasLabel,
                    isStaggeredState,
                    props.vehicleInformationPresent,
                    props.tireInformationPresent,
                )}
                clearancePriceLabel={props.clearancePriceLabel}
                feeDisclaimerType={props.feeDisclaimerType}
                isSRPPage={props.isSRPPage}
                priceMessage={rearPriceMessage(
                    props.skus,
                    props.priceMessage,
                    isStaggeredState,
                    props.vehicleInformationPresent,
                    props.tireInformationPresent,
                )}
                vehicleInformationPresent={props.vehicleInformationPresent}
                feeDisclaimerTitle={props.feeDisclaimerTitle}
                scrollToFooter={props.scrollToFooter}
                tireInformationPresent={props.tireInformationPresent}
                a11yStrikeOutPrice={props.a11yStrikeOutPrice}
                a11yStrikeOutPriceRange={props.a11yStrikeOutPriceRange}
                excludeBadges={props.exclude}
                frontAvailability={
                    hasMultipleSkus(props?.skus?.length) && props?.skus?.[0]
                        ? renderAvailabilityStatement(props?.skus?.[0])
                        : null
                }
                rearAvailability={
                    hasMultipleSkus(props?.skus?.length) && props?.skus?.[magicNumber.ONE]
                        ? renderAvailabilityStatement(props?.skus?.[magicNumber.ONE])
                        : null
                }
                perAvailability={props?.skus?.[0] && renderAvailabilityStatement(props?.skus?.[0])}
                a11yClickToReadFootnote={props.a11yClickToReadFootnote}
                accessibilityId={props.accessibilityId}
                ariaHidden={true}
                a11yTooltipIcon={props.a11yTooltipIcon}
                a11yCloseIconLabel={props.a11yCloseIconLabel}
            />
        )
    }

    /**
     * function to get vehicle fit label
     * @param {Specification[]} specificationsList
     * @return {string}
     */

    const getVehicleFitLabel = (specificationsList: Specification[]): string => {
        const vehicleFitType = specificationsList?.find((specification: Specification) =>
            [vehicleSpecificFitLabel].includes(specification.code),
        )
        return vehicleFitType?.value
    }

    /**
     * function return product information brand, name, rating, badges, variant
     *
     * @return {JSX.Element}
     */
    const productInfoComponent = (): JSX.Element => {
        return (
            <div className={`${PREFIX}-product-card__product-information`}>
                <ProductInfo
                    brand={props.brand}
                    title={props.title}
                    hideDescription={props.hideDescription}
                    titleStyle={`${PREFIX}-product-card__title ${PREFIX}-product--trim`}
                    titleContainerStyle={`${PREFIX}-product-card__title-container`}
                    isFitmentRequired={props.isFitmentRequired}
                    tireCategory={props.tireCategory}
                    isAutoPartPlp={props.isAutoPartPlp}
                    ariaHidden={true}
                    vehicleFitLabel={
                        props.vehicleInformationPresent
                            ? getVehicleFitLabel(props?.skus[0]?.specifications)
                            : getVehicleFitLabel(props?.specification)
                    }
                    accessibilityTitleId={getAccessibilityTitleId(props.accessibilityId)}
                />
                {showRating()}
            </div>
        )
    }

    // returns true is plp is in grid view with non-staggered fit product
    const isNonStaggeredGrid = () => {
        return GridView && props.skus?.length !== staggeredSkuLength
    }

    const getAutoMotiveWrapperClass = (): string => {
        return props.isAutoPartPlp
            ? props.vehicleFitType === BannerType.Fit
                ? `${PREFIX}-product-card__full-vehicle`
                : `${PREFIX}-product-card__partial-vehicle`
            : `${PREFIX}-product-card__automotive-content`
    }

    /**
     * Returns Stock Icon
     * @param {string} iconType
     * @return {JSX.Element}
     */
    const returnStockIcon = (iconType?: string): JSX.Element => {
        return (
            <span className={`${PREFIX}-product-card__availability-icon`}>
                <Icon type={`ct-${iconType ? iconType : iconTypes.checkmark}`} size="sm" />
            </span>
        )
    }

    /**
     * Returns availability statement
     * @param {string} label
     * @param {number} quantity
     * @return {JSX.Element}
     */
    const returnAvailabilityStatement = (label: string, quantity?: number): JSX.Element => {
        return (
            <div
                className={`${PREFIX}-product-card__availability-message`}
                id={props.isAutoPartPlp && getAccessibilityAvailabilityId(props.accessibilityId)}>
                {label === props?.outOfStockLabel ? returnStockIcon(iconTypes.prohibited) : returnStockIcon()}
                {label === props?.inStockAisleLabel ? getAvailabilityLabel(quantity) : label}
            </div>
        )
    }

    /**
     * @param {boolean} sellable
     * @param {boolean} orderable
     * @param {number} quantity
     * @param {number} corpQuantity
     * @param {boolean} isWheelOrTire
     * @return {boolean}
     */
    const showInStockOnlineLabel = (
        sellable: boolean,
        orderable: boolean,
        quantity: number,
        corpQuantity: number,
        isWheelOrTire: boolean,
    ): boolean => {
        if (sellable && orderable && corpQuantity) {
            if (isWheelOrTire) {
                return quantity < magicNumber.FOUR
            } else {
                return quantity === magicNumber.ZERO
            }
        } else {
            return false
        }
    }

    /**
     * @param {boolean} sellable
     * @param {boolean} orderable
     * @param {number} quantity
     * @param {number} corpQuantity
     * @return {boolean}
     */
    const showOutOfStockLabel = (
        sellable: boolean,
        orderable: boolean,
        quantity: number,
        corpQuantity: number,
    ): boolean => {
        return sellable && !orderable && quantity === magicNumber.ZERO && corpQuantity === magicNumber.ZERO
    }

    /**
     * @param {boolean} sellable
     * @param {number} quantity
     * @param {boolean} isWheelOrTire
     * @return {boolean}
     */
    const showInStockAisleLabel = (sellable: boolean, quantity: number, isWheelOrTire: boolean): boolean => {
        return sellable && (isWheelOrTire ? quantity >= magicNumber.FOUR : quantity > magicNumber.ZERO)
    }

    /**
     * Availability statement component
     * @param {ProductSku} skuData
     * @return {JSX.Element} returns availability statement to show
     */
    const renderAvailabilityStatement = (skuData: ProductSku): JSX.Element => {
        // check if availability info not present
        if (!props.showAvailabilityMessage) {
            return null
        }
        const { sellable, orderable, fulfillment } = skuData
        const availability = fulfillment?.availability
        const quantity = availability?.quantity
        const Corporate = availability?.Corporate
        const corpQuantity = Corporate?.Quantity
        // inStockOnlineLabel
        if (showInStockOnlineLabel(sellable, orderable, quantity, corpQuantity, props.isWheelOrTirePDP)) {
            return returnAvailabilityStatement(props?.inStockOnlineLabel)
        }
        // outOfStockLabel
        if (showOutOfStockLabel(sellable, orderable, quantity, corpQuantity)) {
            return returnAvailabilityStatement(props?.outOfStockLabel)
        }
        // inStockAisleLabel
        if (showInStockAisleLabel(sellable, quantity, props.isWheelOrTirePDP)) {
            return returnAvailabilityStatement(props?.inStockAisleLabel, quantity)
        }
        // checkAvailabilityLabel
        return returnAvailabilityStatement(props?.checkAvailabilityLabel)
    }

    const getAvailabilityLabel = (quantityFlag: number) => {
        const inStockAisleLabel = props?.inStockAisleLabel
        const limitedStockMessage = props?.isUrgentLowStockLabel
        const isUrgentLowStock = props?.isUrgentLowStock

        if (isUrgentLowStock) {
            const urgencyMsg = limitedStockMessage
                ? replaceStrWithDynamicVal(limitedStockMessage, quantityFlag?.toString())
                : ''
            return (
                <span>
                    <span className={`${PREFIX}-product-card__urgentLowStock`}>{urgencyMsg}</span>
                </span>
            )
        } else {
            return replaceStrWithDynamicVal(inStockAisleLabel, quantityFlag?.toString() ?? '')
        }
    }

    const hasMultipleSkus = (skuQuantity: number): boolean => {
        const singleSkuNumber = 1
        return skuQuantity > singleSkuNumber
    }

    const fitmentClass = props.isAutoPartPlp ? `${PREFIX}-product-card__price-fitment_grid--auto-parts` : ''

    const modifierClass =
        !isArrayNotEmpty(updatedFitmentNotesList) && props.isAutoPartPlp
            ? `${PREFIX}-product-card__auto-info--no-critical-fitmnet`
            : ''

    type imgAttribute = {
        'data-src'?: string
        src?: string
        className?: string
        'data-component-name': string
    }
    /**
     * @param {string} path
     * @param {string} dataComponentName
     * @returns {imgAttribute}
     */

    const imageAttributes = (path: string, dataComponentName: string) => {
        const attributeObject: imgAttribute = {
            'data-component-name': dataComponentName,
        }
        if (isLazyRequire) {
            attributeObject['data-src'] = path
            attributeObject.className = 'lazyload'
        } else {
            attributeObject.src = path
        }
        return attributeObject
    }

    const createUrlWithPolicy = (source: string, policyType: string) => {
        return policyType
            ? `${source?.toString()?.split('?', magicNumber.ONE).toString()}${returnPolicy(policyType)}`
            : `${source}`
    }

    return (
        <a
            href={props.url}
            onKeyPress={(event: React.KeyboardEvent<HTMLElement>) => {
                if (event.key === stringKeyCodes.enter) {
                    props.productCardClick(event, props.product, props.idx)
                }
            }}
            aria-labelledby={`title__${props.accessibilityId} price__${props.accessibilityId} availability__${props.accessibilityId}`}
            onClick={event => props.productCardClick(event, props.product, props.idx)}
            tabIndex={0}
            onKeyUp={(event: React.KeyboardEvent<HTMLElement>) => {
                if (event.key === stringKeyCodes.tab && props.isScrollingRequired) {
                    productCardScrollToTop(event as ProductCardScrollEvent)
                }
            }}
            data-testid={`product-card-container${props.idx}`}
            className={`${PREFIX}-product-card__content ${getAutoMotiveWrapperClass()}`}>
            <div
                className={`${PREFIX}-product-card__automotive-wrap ${
                    !props.isWheelOrTirePDP ? `${PREFIX}-product-card__automotive-wrap--no-wrap` : ``
                }`}>
                <div className={`${PREFIX}-product-card__auto-primary-details`}>
                    <div className={`${PREFIX}-product-card__auto-primary-details-right`}>
                        <div className={`${PREFIX}-product-card__image-wrap`} aria-hidden={true}>
                            <img
                                aria-hidden={true}
                                alt=""
                                {...imageAttributes(
                                    `${createUrlWithPolicy(productImageRendering(), props.imageDataComponentName)}`,
                                    props.imageDataComponentName,
                                )}
                            />
                        </div>
                        {productInfoComponent()}
                    </div>

                    <div className={`${PREFIX}-product-card__price-fitment_grid ${fitmentClass}`}>
                        <div className={`${PREFIX}-product-card__auto-info ${modifierClass}`}>
                            {productVehicleCardData()}
                            {productCodeDisplay()}
                            {props.isAutoPartPlp && props?.skus?.[0] && renderAvailabilityStatement(props?.skus?.[0])}
                        </div>
                        {props.vehicleFitType === BannerType.Fit && specifications()}
                    </div>
                </div>
            </div>
        </a>
    )
}

VehicleProductCard.defaultProps = {
    showRatingSection: false,
}

VehicleProductCard.propTypes = {
    path: PropTypes.string,
    productProps: PropTypes.any,
    idx: PropTypes.number.isRequired,
    cardType: PropTypes.string.isRequired,
    type: PropTypes.string,
    title: PropTypes.string.isRequired,
    code: PropTypes.string,
    brand: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.exact({
            label: PropTypes.string,
            url: PropTypes.string,
        }),
    ]).isRequired,
    rating: PropTypes.number.isRequired,
    ratingsCount: PropTypes.number.isRequired,
    priceShortMessage: PropTypes.string,
    priceLongMessage: PropTypes.string,
    longDescription: PropTypes.string,
    productCardClick: PropTypes.func,
    redirectAfterProductCardClick: PropTypes.func,
    hideDescription: PropTypes.bool,
    images: PropTypes.array,
    showRatingSection: PropTypes.bool,
    ecoFeeTitleChange: PropTypes.func,
    skus: PropTypes.array,
    currentPrice: PropTypes.any,
    originalPrice: PropTypes.any,
    language: PropTypes.string,
    feeTitle: PropTypes.string,
    rebate: PropTypes.any,
    rebateIcon: PropTypes.string,
    tireCategory: PropTypes.string,
    roadRating: PropTypes.any,
    isFitmentRequired: PropTypes.bool,
    specification: PropTypes.any,
    setOfFourLabel: PropTypes.string,
    perTireLabel: PropTypes.string,
    vehicleFrontLabel: PropTypes.string,
    vehicleRearLabel: PropTypes.string,
    roadRatedLabel: PropTypes.string,
    vehicleFitType: PropTypes.any,
    eachLabel: PropTypes.string,
    badgePriorities: PropTypes.any,
    badges: PropTypes.array,
    hideDisclaimer: PropTypes.bool,
    isAutoPartPlp: PropTypes.bool,
    a11yStrikeOutPrice: PropTypes.string,
    a11yStrikeOutPriceRange: PropTypes.string,
    a11yCloseIconLabel: PropTypes.string,
    a11yTooltipIcon: PropTypes.string,
    fitmentTypeCode: PropTypes.any,
    vehicleList: PropTypes.array,
    options: PropTypes.array,
    partNumber: PropTypes.string,
    fitmentNotes: PropTypes.array,
    productWheelType: PropTypes.string,
    feeDisclaimerMessage: PropTypes.string,
    nowFromLabel: PropTypes.string,
    saveFromLabel: PropTypes.string,
    wasFromLabel: PropTypes.string,
    fromLabel: PropTypes.string,
    promotionalPriceLabel: PropTypes.string,
    unitPriceLabel: PropTypes.string,
    displayWasLabel: PropTypes.bool,
    clearancePriceLabel: PropTypes.string,
    discount: PropTypes.any,
    feeDisclaimerType: PropTypes.string,
    isSRPPage: PropTypes.bool,
    feeValue: PropTypes.number,
    priceMessage: PropTypes.array,
    vehicleInformationPresent: PropTypes.bool,
    feeDisclaimerTitle: PropTypes.string,
    tireInformationPresent: PropTypes.bool,
    scrollToFooter: PropTypes.func,
    isWheelOrTirePDP: PropTypes.bool,
    inStockLabel: PropTypes.string,
    inStockOnlineLabel: PropTypes.string,
    outOfStockLabel: PropTypes.string,
    inStorePurchaseLabel: PropTypes.string,
    inStockAisleLabel: PropTypes.string,
    checkAvailabilityLabel: PropTypes.string,
    checkOtherStoresLabel: PropTypes.string,
    partNumberLabel: PropTypes.string,
    exclude: PropTypes.array,
    overridePriceHeight: PropTypes.bool,
    a11yReviewRating: PropTypes.string,
    url: PropTypes.string,
    feeMessages: PropTypes.array,
    productCardSpecifications: PropTypes.string,
    isUrgentLowStock: PropTypes.bool,
    isUrgentLowStockLabel: PropTypes.string,
    totalCurrentPrice: PropTypes.any,
    imageDataComponentName: PropTypes.string,
    attributePositionLabel: PropTypes.string,
    suggestedQuantityLabel: PropTypes.string,
    attributeUsageLabel: PropTypes.string,
    isScrollingRequired: PropTypes.bool,
    returnPolicy: PropTypes.func,
    isLazyRequire: PropTypes.bool,
    product: PropTypes.object.isRequired as Validator<ProductCardType>,
    productDataId: PropTypes.string,
    a11yClickToReadFootnote: PropTypes.string,
    showAvailabilityMessage: PropTypes.bool,
}
export default VehicleProductCard
