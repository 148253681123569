import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { PREFIX, previousElementName } from '../config'
import { FacetPanelModalProps, FacetRange, FacetTypes } from './FacetPanelModal.type'
import Icon from '../Icon'
import Button from '../Button'
import FlyoutModalComponent from '../FlyoutModalComponent'
import { replaceStrWithDynamicVal, magicNumber } from '../../utils'
import SortFacet from './SortFacet'
import Filters from './Filters'
import { modalAccessibilityHandler } from '../../utils/modalAccessibility'
import { isArrayNotEmpty } from '../../utils/isArrayNotEmpty'
import Accordion from '../Accordion'
import ShowMoreLessButton from './ShowMoreLessButton'

/**
 * FacetPanelModal component
 * @param {FacetPanelModalProps} props
 * @return {JSX.Element} returns FlyoutModalComponent,SortFacet and Filters
 */
const FacetPanelModal: React.FC<FacetPanelModalProps> = props => {
    const {
        isOpen,
        path,
        closeFlyout,
        closeBtnLabel,
        title,
        viewResultsLabel,
        filtersLabel,
        mSortByCTALabel,
        clearAllLabel,
        resultCount,
        sortings = [],
        facets,
        onSortChange,
        selectedFacetType,
        sortLabel,
        refineByLabel,
        categoryLabel,
        showMoreThreshold,
        showMoreLabel,
        showLessLabel,
        selectedLabel,
        onFilterChange,
        clearAllLink,
        currentLocale,
        minPriceErrorMessage,
        maxPriceErrorMessage,
        minPriceLabel,
        maxPriceLabel,
        isClientSideFilter,
        isTriangleOffer,
        enableFilterFacetsEnhancement,
        searchInputPlaceholderText,
        noSearchResults,
        categoryData,
        showCategoryDropdown,
        isCategoryPage,
    } = props

    const componentClassName = `${PREFIX}-facet-modal`

    const enableSortByFacet = enableFilterFacetsEnhancement && selectedFacetType == 'sort'
    const getFacetTitle = (): string => {
        return enableSortByFacet ? sortLabel : title
    }
    const flyoutModalComponentProps = {
        isOpen,
        closeFlyout,
        title: getFacetTitle(),
        isStoreSelectorModalUsed: false,
    }

    const updatedShowMoreLabel = replaceStrWithDynamicVal(showMoreLabel, categoryData?.length - showMoreThreshold)
    const { current: rowsToShow } = useRef(categoryData?.slice(0, showMoreThreshold))

    const [showAllCategories, setShowAllCategories] = useState(false)
    const [listData, setListData] = useState({
        listViewData: rowsToShow,
        icon: 'ct-chevron-down',
        link: updatedShowMoreLabel,
    })

    // Sets related label, icon and list data based on show less / show more
    useEffect(() => {
        if (showAllCategories) {
            setListData({
                listViewData: categoryData,
                icon: 'ct-chevron-up',
                link: showLessLabel,
            })
        } else {
            setListData({
                listViewData: rowsToShow,
                icon: 'ct-chevron-down',
                link: updatedShowMoreLabel,
            })
        }
    }, [showAllCategories, categoryData, showLessLabel, updatedShowMoreLabel, rowsToShow])

    const ctaLabel = replaceStrWithDynamicVal(viewResultsLabel, resultCount)

    // Below code is for handling the accessibility when clicked on close button
    const modalClassName = `${PREFIX}-flyout-modal-overlay`

    const previousElement: HTMLElement = document.querySelector(`button[${previousElementName}`)

    const factetFilterSearchThreshold = magicNumber.FIFTEEN

    const modifiedCloseFlyout = () => {
        modalAccessibilityHandler({ modalOpen: false, modalClassName })
        closeFlyout()
        if (previousElement) {
            previousElement.focus() // Highlight the initiated button
            previousElement.removeAttribute(previousElementName) // Removing it when user close it.
        }
    }

    useEffect(() => {
        modalAccessibilityHandler({ modalOpen: true, modalClassName })
    }, [modalClassName, sortings])

    useEffect(() => {
        if (!isClientSideFilter) {
            scrollIntoView()
        }
    }, [facets, isClientSideFilter])

    /**
     * function to scroll the selected filter to the start of the view
     */

    const scrollIntoView = (): void => {
        const expanded = document.getElementsByClassName('nl-accordion--expanded')[0]
        if (expanded) {
            expanded.scrollIntoView({ block: 'start', behavior: 'smooth' })
        }
    }

    const showClearAllLabel = (): boolean => {
        return (
            clearAllLink &&
            facets &&
            !!facets.filter(facet => (isTriangleOffer ? facet.values.some(item => item.selected) : facet.selected))
                .length
        )
    }
    const renderSortFacet = (): JSX.Element => {
        return (
            <>
                {isArrayNotEmpty(sortings) && (
                    <SortFacet
                        selectedFacetType={selectedFacetType}
                        sortings={sortings}
                        onSortChange={onSortChange}
                        sortLabel={sortLabel}
                        showMoreThreshold={showMoreThreshold}
                        showMoreLabel={showMoreLabel}
                        showLessLabel={showLessLabel}
                        enableFilterFacetsEnhancement={enableFilterFacetsEnhancement}
                    />
                )}
            </>
        )
    }

    const renderTitleFacet = (): JSX.Element => {
        return <h2 className={`${componentClassName}__title`}>{getFacetTitle()}</h2>
    }

    const renderFilterFacet = (): JSX.Element => {
        return (
            <>
                <div
                    className={`${componentClassName}__filters-heading ${isSortAvailable} ${
                        enableFilterFacetsEnhancement ? 'featured-heading' : ''
                    }`}>
                    <h3 className={`${componentClassName}__filters-title`}>{filtersLabel}</h3>
                    {showClearAllLabel() && (
                        <Button type="tertiary" onClick={clearAllClickHandler}>
                            {clearAllLabel}
                        </Button>
                    )}
                </div>
                <ul className={`${PREFIX}-filter-section`}>
                    {facets.map(facet => {
                        return (
                            <Filters
                                key={facet.label}
                                facet={facet}
                                onFilterChange={onFilterChange}
                                customPriceFacet={
                                    facets.find(oneFacet => oneFacet.type === FacetTypes.CUSTOM_PRICE) as FacetRange
                                }
                                isTriangleOffer={isTriangleOffer}
                                {...filtersLabelsProps}
                                showSearch={
                                    facet.type !== FacetTypes.RANGE &&
                                    facet?.values?.length > factetFilterSearchThreshold
                                        ? true
                                        : false
                                }
                                enableFilterFacetsEnhancement={enableFilterFacetsEnhancement}
                                searchInputPlaceholderText={searchInputPlaceholderText}
                                noSearchResults={noSearchResults}
                                isCategoryPage={isCategoryPage}
                            />
                        )
                    })}
                </ul>
            </>
        )
    }

    // required for the feature toggle
    const getCategoryFilter = () => facets?.find(it => it.type === FacetTypes.BREADCRUMB)

    // required for the feature toggle
    const filtersLabelsProps = {
        selectedFacetType,
        showMoreThreshold,
        showMoreLabel,
        showLessLabel,
        selectedLabel,
        currentLocale,
        minPriceErrorMessage,
        maxPriceErrorMessage,
        minPriceLabel,
        maxPriceLabel,
    }

    /**
     * function to clear all filter
     */
    const clearAllClickHandler = (): void => {
        onFilterChange(clearAllLink, clearAllLabel, '', false, '', '', false)
    }

    const renderCategoryDropDown = (): JSX.Element => {
        return (
            showCategoryDropdown === true &&
            isCategoryPage && (
                <>
                    <div className={`${componentClassName}__filters-heading`}>
                        <h3 className={`${componentClassName}__filters-title`}>{refineByLabel}</h3>
                    </div>
                    <ul>
                        <li>
                            <Accordion title={categoryLabel}>
                                <ul>
                                    {listData.listViewData.map(val => {
                                        const labelComponent = (
                                            <a
                                                data-testid="categories-facet"
                                                href={val.url}
                                                tabIndex={0}
                                                className={`${PREFIX}-filter-section__breadcrumb-item`}>
                                                {val.label}
                                            </a>
                                        )

                                        return (
                                            <li
                                                className={`${PREFIX}-filter-section__multiselect-item`}
                                                key={val.label}>
                                                {labelComponent}
                                            </li>
                                        )
                                    })}
                                    <ShowMoreLessButton
                                        totalResults={categoryData as unknown as Record<string, unknown>[]}
                                        setShowAllRows={setShowAllCategories}
                                        showAllRows={showAllCategories}
                                        listData={listData}
                                        showMoreThreshold={showMoreThreshold}
                                    />
                                </ul>
                            </Accordion>
                        </li>
                    </ul>
                </>
            )
        )
    }
    const isSortAvailable = !isArrayNotEmpty(sortings) ? `${componentClassName}__filters-border` : ''
    return (
        <FlyoutModalComponent {...flyoutModalComponentProps}>
            <div className={`${componentClassName}__container`}>
                <div className={`${componentClassName}__header`}>
                    {!isTriangleOffer && renderTitleFacet()}
                    <button
                        className={`${componentClassName}__close-btn`}
                        onClick={modifiedCloseFlyout}
                        aria-label={closeBtnLabel}
                        title={closeBtnLabel}>
                        <Icon type="ct-close" size="lg" path={path} />
                    </button>
                </div>
                <div className={`${componentClassName}__body`}>
                    {enableFilterFacetsEnhancement && getCategoryFilter() && selectedFacetType != 'sort'
                        ? renderCategoryDropDown()
                        : renderSortFacet()}
                    {!enableSortByFacet && isArrayNotEmpty(facets) ? renderFilterFacet() : null}
                </div>
                <div className={`${componentClassName}__footer`}>
                    {enableSortByFacet ? (
                        <Button id="add-to-cart" type="primary" size="large" onClick={modifiedCloseFlyout}>
                            {mSortByCTALabel}
                        </Button>
                    ) : (
                        <Button size="large" onClick={modifiedCloseFlyout}>
                            {ctaLabel}
                        </Button>
                    )}
                </div>
            </div>
        </FlyoutModalComponent>
    )
}

FacetPanelModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeFlyout: PropTypes.func.isRequired,
    closeBtnLabel: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    viewResultsLabel: PropTypes.string.isRequired,
    filtersLabel: PropTypes.string.isRequired,
    clearAllLabel: PropTypes.string.isRequired,
    sortLabel: PropTypes.string,
    refineByLabel: PropTypes.string,
    categoryLabel: PropTypes.string,
    showMoreThreshold: PropTypes.number,
    showMoreLabel: PropTypes.string,
    showLessLabel: PropTypes.string,
    resultCount: PropTypes.number,
    sortings: PropTypes.array,
    facets: PropTypes.array,
    path: PropTypes.string,
    onSortChange: PropTypes.func,
    selectedFacetType: PropTypes.string,
    selectedLabel: PropTypes.string,
    onFilterChange: PropTypes.func,
    clearAllLink: PropTypes.string,
    currentLocale: PropTypes.string,
    minPriceErrorMessage: PropTypes.string,
    maxPriceErrorMessage: PropTypes.string,
    minPriceLabel: PropTypes.string,
    maxPriceLabel: PropTypes.string,
    isClientSideFilter: PropTypes.bool,
    enableFilterFacetsEnhancement: PropTypes.bool,
}

export default FacetPanelModal
