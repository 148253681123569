import React, { useState, useCallback, useEffect, useLayoutEffect, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
    Button,
    Icon,
    magicNumber,
    OfferCard,
    OfferFlyout,
    useGlobalResizeEvent,
    getButtonStatus,
    offerComponentLocation,
    getAkamaiPolicy,
    Separator,
    SeparatorStyle,
    SeparatorSize,
    BrandingColors,
    NotificationBadge,
} from '@nl/lib'

import { PREFIX, previousElementName } from '../../config'
import { Offers } from '../../redux/models/offers.interface'
import checkNestedProps from '../../utils/checkNestedProps'
import { getFormattedPriceWithLocale } from '../../utils/formatPrice'
import { clearOffersActivateAction, clearOfferActivationErrorAction } from '../../redux/actionCreators'
import { useIntersect, useMobileLayoutState } from '../../hooks/layout.hook'

import { offerConstants } from '../Accounts/Rewards/Offers/OffersCard.constants'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { LoyaltyOffersProps } from './LoyaltyOffers.type'
import {
    defineContainerClasses,
    definePanelClasses,
    useMouseWheelScrollHandler,
    useDragScrollHandler,
    calculateComponentPosition,
    calculateHighestElementTopPosition,
    markPanelWasExpanded,
    isFooterVisible,
    STICK_TO_FOOTER,
    STICK_TO_BOTTOM,
    EXPANDED,
    TRIANGLE_SELECT,
    getPageClickCount,
    incrementClickCount,
    initializeClickCount,
} from './LoyaltyOffers.helper'
import { gigyaLoadFunctions } from '../GigyaScreen/GigyaScreen'
import { navigationConst } from '../PrimaryNavigation/PrimaryNavigation.constant'
import { setOgImageTag } from '../../redux/actionCreators/ogImageTag.actionCreators'
import { commonContentAvailableSelector, commonContentSelector } from '../../redux/selectors/commonContent.selectors'
import { userProfileDataSelector, isAuthFlowExecutedSelector } from '../../redux/selectors/userProfile.selectors'
import { tokenStateSelector } from '../../redux/selectors/tokenState.selectors'
import { AkamaiImagePolicies } from '../../akamaiPolicy/akamaiPolicy.service'
import { activateOffer, checkOfferStatus, getFilteredOffersBanner, showBadge } from '../../helpers/offers.helper'
import {
    offersActivatedDataSelector,
    offersActivatedErrorSelector,
    offersDataSelector,
    offersSelector,
} from '../../redux/selectors/offers.selectors'
import { updateFilteredOffers } from '../../redux/actions/offers.action'

const CLICK_EVENT = 'click'
const TRIANGLE_SELECT_ACTIVE = 'A'

const LoyaltyOffers: React.FC<LoyaltyOffersProps> = ({ ...props }) => {
    const {
        activationErrorMessage,
        offersToDisplayOnDesktop,
        offersToDisplayOnMobile,
        toggleBtnSignInText,
        toggleBtnText,
        notSignedInInfoMsg,
        signInAdviceMsg,
        signUpBtnTextDesktop,
        signUpBtnTextMobile,
        offersHeaderTitle,
        offersHeaderSubtitle,
        viewAllOffersBtnText,
        offerEndsLabel,
        offerEndsTodayLabel,
        offerImageAltText,
        activateOfferBtnText,
        activatedOfferBtnText,
        redeemedBtnText,
        bannerImageList,
        offerEndsDateLabel,
        loadOffers,
        onPanelDismiss,
        trilogoImage,
        trilogoImageAltText,
        viewOffersBtnText,
        viewOffersA11yLabel,
        dismissBtnText,
        dismissA11yLabel,
        ctMoneyRichtext,
        attemptsToDisplayWithoutInteraction,
        offersPerTileGroupDesktop,
        offersPerTileGroupTablet,
        offerDetailsLabel,
        offerBannerList,
        enableLoyaltyOffersPanelV3,
    } = props

    const [isMobileLayout] = useMobileLayoutState() as [boolean]
    useGlobalResizeEvent(() => calculateComponentPosition(isMobileLayout))

    const overlayRef = useRef<HTMLDivElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const offersContainerRef = useRef<HTMLDivElement>(null)
    const scrollableWrapperRef = useRef<HTMLDivElement>(null)
    const togglerRef = useRef<HTMLDivElement>(null)
    const isClickForDraggingRef = useRef<boolean>(false)

    const userProfileData = useSelector(userProfileDataSelector)
    const isAuthFlowExecuted = useSelector(isAuthFlowExecutedSelector)
    const isLoggedIn = checkDataLength(userProfileData)
    const hasLoyaltyAccount = checkDataLength(userProfileData?.loyalty)
    const isTriangleSelectUser = userProfileData?.loyalty?.tsSubscriptionStatus === TRIANGLE_SELECT_ACTIVE
    const isDisplayOffersList = checkDataLength(userProfileData?.loyalty)

    const { commonContentAvailable } = useSelector(commonContentSelector)
    const { loyalty } = useSelector(commonContentAvailableSelector)
    const { fallbackOfferImageUrl, moreDetailsLabel } = loyalty || ({} as typeof commonContentAvailable.loyalty)
    const { accountDashboard } = useSelector(commonContentAvailableSelector)
    const { offersBadgeCountThreshold, a11yAlertBadgeValue } =
        accountDashboard || ({} as typeof commonContentAvailable.accountDashboard)
    const colors = [BrandingColors.TEAL, BrandingColors.BURGUNDY, BrandingColors.RED]
    const [isAccordionOpen, setIsAccordionOpen] = useState(false)
    const [isActivateOfferClick, setIsActivatedOfferClick] = useState(false)

    const labels = {
        activatedLabel: activatedOfferBtnText,
        activateLabel: activateOfferBtnText,
        redeemedLabel: redeemedBtnText,
    }

    const { filteredOffersData } = useSelector(offersSelector)
    const { filteredOffers } = filteredOffersData || {}
    const offersData = useSelector(offersDataSelector)
    const { unActivatedOffersCount } = offersData || {}
    const offersActivatedData = useSelector(offersActivatedDataSelector)
    const offersActivatedError = useSelector(offersActivatedErrorSelector)
    const offersCountToDisplay = isMobileLayout ? offersToDisplayOnMobile : offersToDisplayOnDesktop

    const tokenState = useSelector(tokenStateSelector)
    const [totalOffers, setTotalOffers] = useState(0)
    const [selectedOffer, setSelectedOffer] = useState<Offers | null>(null)
    const [isOpenViewDetailsModal, setViewDetailsModalOpen] = useState(false)
    const [isShowErrorMessage, setIsShowErrorMessage] = useState(false)
    const [isCollapsed, setIsCollapsed] = useState(true)
    const [isAuthFlowTriggeredFromHere, setIsAuthFlowTriggeredFromHere] = useState(false)
    const [reloadOffersRequired, setReloadOffersRequired] = useState(false)

    const stylePrefix = `${PREFIX}-loyalty-offers`
    const triangleSelectStyle = isTriangleSelectUser ? TRIANGLE_SELECT : ''

    const [isDrawerReduced, setIsDrawerReduced] = useState(false)
    const [currentPageClickCount, setCurrentPageClickCount] = useState(getPageClickCount)

    const dispatch = useDispatch()
    const columns = {
        md: offersPerTileGroupDesktop
            ? magicNumber.TWELVE / offersPerTileGroupDesktop
            : magicNumber.TWELVE / magicNumber.THREE,
        sm: offersPerTileGroupTablet
            ? magicNumber.TWELVE / offersPerTileGroupTablet
            : magicNumber.TWELVE / magicNumber.TWO,
        xs: magicNumber.THREE,
    }

    /**
     * useEffect used to update accordion status
     */
    useEffect(() => {
        selectedOffer && !!checkOfferStatus(selectedOffer, offerConstants.activate)
            ? setIsAccordionOpen(true)
            : !isActivateOfferClick && setIsAccordionOpen(false)
    }, [labels, selectedOffer, isActivateOfferClick])

    // useEffect to update filtered offers data redux state
    useEffect(() => {
        const offersToDisplay = offersData?.offers?.slice(0, offersCountToDisplay) || []
        const filteredData = getFilteredOffersBanner(offersToDisplay, offerBannerList)
        dispatch(updateFilteredOffers(filteredData))
    }, [dispatch, offerBannerList, offersCountToDisplay, offersData])

    useEffect(() => {
        const bannerImage = props.bannerImageList[0].bannerImagePath
        dispatch(setOgImageTag({ component: 'LoyaltyOffers', imageLink: bannerImage }))
    }, [props.bannerImageList, dispatch])

    useEffect(() => {
        if (!isCollapsed) {
            markPanelWasExpanded()
        }
    }, [isCollapsed])

    useEffect(() => {
        setTotalOffers((offersData?.offers || []).filter(offer => offer.offerStatus === 'ACTIVATE').length)
    }, [offersData])

    useLayoutEffect(() => {
        // To prevent page scrolling while offers list scrolling
        document.body.style.overflow = isCollapsed ? '' : 'hidden'

        return () => {
            document.body.style.overflow = ''
        }
    }, [isCollapsed, stylePrefix, overlayRef])

    const options = useMemo(() => {
        const highestStickyElementMargin = calculateHighestElementTopPosition(isMobileLayout)
        const distanceFromHighestStickyElemTopToPageBottom = Math.ceil(window.innerHeight - highestStickyElementMargin)

        return {
            root: null,
            rootMargin: `-${distanceFromHighestStickyElemTopToPageBottom}px`,
            threshold: 0.0,
        }
    }, [isMobileLayout])

    const setIntersectionObservable = useIntersect((): void => {
        if (isFooterVisible(isMobileLayout)) {
            containerRef.current?.classList.add(STICK_TO_FOOTER)
            containerRef.current?.classList.remove(STICK_TO_BOTTOM)
        } else {
            containerRef.current?.classList.add(STICK_TO_BOTTOM)
            containerRef.current?.classList.remove(STICK_TO_FOOTER)
        }
        calculateComponentPosition(isMobileLayout)
    }, options)

    const footerCompEL = document.querySelector(navigationConst.footerLinks) as HTMLElement

    useLayoutEffect(() => {
        setIntersectionObservable(footerCompEL)
    }, [setIntersectionObservable, footerCompEL])

    useLayoutEffect(() => {
        calculateComponentPosition(isMobileLayout)

        const handleScroll = () => {
            calculateComponentPosition(isMobileLayout)
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [isMobileLayout])

    const handleMouseWheelScrolling = useMouseWheelScrollHandler()
    const handleDragScrolling = useDragScrollHandler(isClickForDraggingRef)

    const layoutChangeHandler = useCallback(() => {
        if (!isDisplayOffersList) {
            return
        }

        const displayFlexStyle = `${PREFIX}_display-flex`
        const displayBlockStyle = `${PREFIX}_display-block`
        const justifyFlexStartStyle = `${PREFIX}_justify-flex-start`
        const justifyCenterStyle = `${PREFIX}_justify-center`

        const hasScroll = offersContainerRef.current?.scrollWidth > window.innerWidth

        if (hasScroll) {
            handleMouseWheelScrolling()
            handleDragScrolling()
        }

        scrollableWrapperRef.current?.classList.remove(hasScroll ? displayBlockStyle : displayFlexStyle)
        scrollableWrapperRef.current?.classList.add(hasScroll ? displayFlexStyle : displayBlockStyle)
        offersContainerRef.current?.classList.remove(hasScroll ? justifyCenterStyle : justifyFlexStartStyle)
        offersContainerRef.current?.classList.add(hasScroll ? justifyFlexStartStyle : justifyCenterStyle)
    }, [isDisplayOffersList, handleMouseWheelScrolling, handleDragScrolling, offersContainerRef, scrollableWrapperRef])

    useLayoutEffect(() => {
        window.visualViewport.addEventListener('resize', layoutChangeHandler)
        return () => {
            window.visualViewport.removeEventListener('resize', layoutChangeHandler)
        }
    }, [layoutChangeHandler])

    useLayoutEffect(layoutChangeHandler, [layoutChangeHandler, isMobileLayout])

    useEffect(() => {
        const onOverlayClick = (event: MouseEvent): void => {
            if (event.target === overlayRef.current || event.target === containerRef.current) {
                setIsCollapsed(true)
                if (reloadOffersRequired) {
                    loadOffers()
                    setReloadOffersRequired(false)
                }
            }
        }

        overlayRef.current?.addEventListener(CLICK_EVENT, onOverlayClick)
        const currentContainerRef = containerRef.current
        currentContainerRef?.addEventListener(CLICK_EVENT, onOverlayClick)

        return () => {
            currentContainerRef?.removeEventListener(CLICK_EVENT, onOverlayClick)
        }
    }, [overlayRef, containerRef, isCollapsed, loadOffers, reloadOffersRequired])

    useEffect(() => {
        const onToggleClick = (): void => {
            setIsCollapsed(!isCollapsed)
        }

        const currentTogglerRef = togglerRef.current
        currentTogglerRef?.addEventListener(CLICK_EVENT, onToggleClick)

        return () => {
            currentTogglerRef?.removeEventListener(CLICK_EVENT, onToggleClick)
        }
    }, [isCollapsed, togglerRef])

    const onViewAllOffersClick = () => {
        const weeklyOffersLink = checkNestedProps(commonContentAvailable, 'globalLinks', 'weeklyOffersLink')
        window.location.href = (weeklyOffersLink as string) || '/'
    }

    const onSignUpClick = () => {
        const rewardsPageLink = checkNestedProps(commonContentAvailable, 'globalLinks', 'registrationPageLink')
        window.location.href = (rewardsPageLink as string) || '/'
    }

    const onSignInClick = () => {
        gigyaLoadFunctions('ODP-LoginSignIn', undefined, '', false, false, '')
        window.gigya.showSignInModal = true
    }

    useEffect(() => {
        if (tokenState === 'SUCCESS') {
            setIsAuthFlowTriggeredFromHere(true)
        }
    }, [tokenState])

    useEffect(() => {
        if (isAuthFlowTriggeredFromHere && isLoggedIn && isAuthFlowExecuted && hasLoyaltyAccount) {
            setIsCollapsed(true)
        }
    }, [isAuthFlowTriggeredFromHere, isLoggedIn, isAuthFlowExecuted, hasLoyaltyAccount, loadOffers])

    useEffect(() => {
        if (checkDataLength(offersActivatedData)) {
            const activatedOfferCode = offersActivatedData?.offers[0].offerCode
            const activationStatus = offersActivatedData?.offers[0].activated
            if (activationStatus === offerConstants.yes) {
                const updatedOffersToDisplay = filteredOffers?.map(offer => {
                    if (offer.offerCode === activatedOfferCode) {
                        offer.offerStatus = offerConstants.activated
                    }
                    return offer
                })
                dispatch(updateFilteredOffers(updatedOffersToDisplay))
                setIsAccordionOpen(true)
                setIsActivatedOfferClick(true)
                dispatch(clearOffersActivateAction())
                setReloadOffersRequired(true)
            }
        }
    }, [offersActivatedData, dispatch, filteredOffers])

    useEffect(() => {
        if (checkDataLength(offersActivatedError)) {
            setIsShowErrorMessage(true)
        } else {
            setIsShowErrorMessage(false)
        }
    }, [offersActivatedError])

    const handlePageClick = () => {
        incrementClickCount()
        setCurrentPageClickCount(getPageClickCount)
    }

    useEffect(() => {
        if (currentPageClickCount >= Number(attemptsToDisplayWithoutInteraction)) {
            setIsDrawerReduced(true)
        } else setIsDrawerReduced(false)
    }, [currentPageClickCount, attemptsToDisplayWithoutInteraction])

    useEffect(() => {
        document.body.addEventListener('click', handlePageClick)
        return () => {
            document.body.removeEventListener('click', handlePageClick)
        }
    }, [])

    const openOfferModal = (offerInModal: Offers): void => {
        if (!isClickForDraggingRef.current) {
            setSelectedOffer(offerInModal)
            setViewDetailsModalOpen(true)
            setIsCollapsed(true)
        }
    }
    const setViewDetailsModal = (isCloseClicked: boolean): void => {
        setViewDetailsModalOpen(isCloseClicked)
        if (reloadOffersRequired) {
            loadOffers()
            setReloadOffersRequired(false)
        }
    }

    const onCloseClick = () => {
        setIsCollapsed(true)
        if (reloadOffersRequired) {
            loadOffers()
            setReloadOffersRequired(false)
        }
    }

    const onErrorPopupClose = () => {
        setIsShowErrorMessage(false)
        dispatch(clearOfferActivationErrorAction())
    }

    /**
     * To open the Flyout with offer cards
     * @param {React.MouseEvent<Element, MouseEvent>} event - mouse event on click
     */
    const onClickViewOffers = (event: React.MouseEvent<Element, MouseEvent>): void => {
        const viewOffersCTAEle = event.target as HTMLElement
        viewOffersCTAEle.setAttribute(previousElementName, 'true')
        setIsCollapsed(false)
        initializeClickCount()
        setCurrentPageClickCount(getPageClickCount())
    }

    /**
     * render Notification Badge
     * @return {JSX.Element}
     */
    const renderBadge = (): JSX.Element => {
        return (
            <NotificationBadge
                value={unActivatedOffersCount}
                a11yValue={a11yAlertBadgeValue}
                notificationClass={`${PREFIX}-drawer__notification`}
                type="error"
                size="lg"
                radius="circle"
            />
        )
    }

    /**
     * Renders V3 version for Offer Drawer
     * @return {JSX.Element}
     */
    const renderToggleButtonV3 = (): JSX.Element => {
        const showNotificationBadge = showBadge(unActivatedOffersCount, offersBadgeCountThreshold)
        const notificationArialLabel = showNotificationBadge
            ? `${a11yAlertBadgeValue ? a11yAlertBadgeValue : ''} ${Number(unActivatedOffersCount)}`
            : ''
        return (
            <div className={`${stylePrefix}__toggle-btn-wrapper--v3`}>
                <Separator colors={colors} style={SeparatorStyle.HORIZONTAL} size={SeparatorSize.FULL_WIDTH} />
                <div
                    data-testid="toggle-button"
                    className={`${stylePrefix}__toggle-btn-wrapper__center ${triangleSelectStyle}`}>
                    <div className={`${stylePrefix}__mobile-container-top`}>
                        <div className={`${stylePrefix}__toggle-btn__labels ${triangleSelectStyle}`}>
                            <div className={`${stylePrefix}__toggle-btn__ct-money`}>
                                <div dangerouslySetInnerHTML={{ __html: ctMoneyRichtext }} />
                                <span>{getFormattedPriceWithLocale(Number(userProfileData?.balance))}</span>
                            </div>
                        </div>
                        <button
                            className={`${PREFIX}-modal__close`}
                            onClick={onPanelDismiss}
                            data-testid="dismiss-loyalty-offers-panel">
                            <Icon type="ct-close" size="lg" />
                        </button>
                    </div>
                    <div className={`${stylePrefix}__view-offers-button`}>
                        <Button
                            id="view-offers"
                            type="primary"
                            size="small"
                            onClick={(event: React.MouseEvent<Element, MouseEvent>) => {
                                onClickViewOffers(event)
                            }}
                            label={viewOffersBtnText}
                            ariaLabel={`${viewOffersA11yLabel} ${notificationArialLabel}`}>
                            {showNotificationBadge && renderBadge()}
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    const renderToggleButton = (): JSX.Element => {
        return (
            <div className={`${stylePrefix}_toggle-btn-wrapper ${isDrawerReduced ? 'reduced' : ''}`}>
                <Separator colors={colors} style={SeparatorStyle.HORIZONTAL} size={SeparatorSize.FULL_WIDTH} />
                <div
                    data-testid="toggle-button"
                    className={`${stylePrefix}_toggle-btn-wrapper_center ${triangleSelectStyle}`}>
                    {!isDrawerReduced && (
                        <div className={`${stylePrefix}_mobile-container-top`}>
                            <div className={`${stylePrefix}_toggle-btn_icon-wrapper`}>
                                <div className={`${stylePrefix}_toggle-btn_icon-container`}>
                                    <img src={trilogoImage} alt={trilogoImageAltText} />
                                </div>
                            </div>
                            <div className={`${stylePrefix}_toggle-btn_labels ${triangleSelectStyle}`}>
                                {isLoggedIn ? (
                                    <>
                                        <div className={`${stylePrefix}_toggle-btn_ct-money`}>
                                            <div dangerouslySetInnerHTML={{ __html: ctMoneyRichtext }} />
                                            <span>{getFormattedPriceWithLocale(Number(userProfileData?.balance))}</span>
                                        </div>
                                        <div className={`${stylePrefix}_toggle-btn_title`}>
                                            {(toggleBtnText || 'You have [0] offers to Activate').replace(
                                                '[0]',
                                                String(totalOffers),
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    <div className={`${stylePrefix}_toggle-btn_title`}>{toggleBtnSignInText}</div>
                                )}
                            </div>
                        </div>
                    )}
                    <div className={`${stylePrefix}_mobile-container-bottom`}>
                        <div className={`${stylePrefix}_view-offers-button`}>
                            <Button
                                id="view-offers"
                                type="primary_red"
                                size="mini"
                                onClick={(event: React.MouseEvent<Element, MouseEvent>) => {
                                    onClickViewOffers(event)
                                }}
                                label={viewOffersBtnText}
                                ariaLabel={viewOffersA11yLabel}
                            />
                        </div>
                        <div className={`${stylePrefix}_dismiss-button`}>
                            <Button
                                id="dismiss"
                                type="secondary"
                                size="mini"
                                onClick={onPanelDismiss}
                                label={dismissBtnText}
                                ariaLabel={dismissA11yLabel}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderErrorMessage = (): JSX.Element => {
        return (
            <div className={`${stylePrefix}_error-message`}>
                <div
                    className={`${stylePrefix}_error-message__detail`}
                    dangerouslySetInnerHTML={{ __html: activationErrorMessage }}
                />
                <div>
                    <button
                        className={`${stylePrefix}_error-message__close-button`}
                        onClick={onErrorPopupClose}
                        data-testid="dismiss-loyalty-offers-error-message">
                        <Icon type="ct-close" size="lg" />
                    </button>
                </div>
            </div>
        )
    }

    const renderHeader = (): JSX.Element => {
        return (
            <div className={`${stylePrefix}_header ${triangleSelectStyle}`}>
                <div>
                    <button
                        className={`${PREFIX}-modal__close`}
                        onClick={onCloseClick}
                        data-testid="dismiss-loyalty-offers-panel">
                        <Icon type="ct-close" size="lg" />
                    </button>
                </div>
                <div className={`${stylePrefix}_header-icon-and-text-wrapper`}>
                    {isTriangleSelectUser && (
                        <div>
                            <Icon type="ct-triangle-select" size="lg" />
                        </div>
                    )}
                    <div className={`${stylePrefix}_header-text-wrapper`}>
                        {(!isTriangleSelectUser || !isMobileLayout) && (
                            <div className={`${stylePrefix}_greeting`}>{offersHeaderTitle}</div>
                        )}
                        <div className={`${stylePrefix}_arrive`}>{offersHeaderSubtitle}</div>
                    </div>
                </div>
                {isShowErrorMessage && renderErrorMessage()}
            </div>
        )
    }

    const renderForNotLoggedInUser = (): JSX.Element => {
        const signInLabel = checkNestedProps(commonContentAvailable, 'accountDashboard', 'signInLabel')

        return (
            <div className={`${stylePrefix}_sign-in-up-container`}>
                <button
                    className={`${PREFIX}-modal__close`}
                    onClick={onPanelDismiss}
                    data-testid="dismiss-loyalty-offers-panel">
                    <Icon type="ct-close" size="lg" />
                </button>
                <div className={`${stylePrefix}_not-signed-in-info-msg`}>{notSignedInInfoMsg}</div>
                <div className={`${stylePrefix}_sign-in-advice-msg`}>{signInAdviceMsg}</div>
                <div className={`${stylePrefix}_sign-in-up-buttons`}>
                    <Button
                        type="primary"
                        size="small"
                        buttonType="submit"
                        quantumMetricAttribute={{ type: 'allow', value: 'true' }}
                        onClick={onSignUpClick}>
                        {isMobileLayout ? signUpBtnTextMobile : signUpBtnTextDesktop}
                    </Button>
                    <Button
                        type="secondary"
                        size="small"
                        quantumMetricAttribute={{ type: 'allow', value: 'true' }}
                        onClick={onSignInClick}>
                        {signInLabel}
                    </Button>
                </div>
            </div>
        )
    }

    const renderOfferCard = (): JSX.Element => {
        return (
            <div className={`${stylePrefix}_offer-container`}>
                {filteredOffers?.map((offer: Offers, index: number) => (
                    <OfferCard
                        key={`offer_${index}`}
                        bannerImageList={bannerImageList}
                        offerEndsLabel={offerEndsLabel}
                        offerEndsTodayLabel={offerEndsTodayLabel}
                        offerEndsDateLabel={offerEndsDateLabel}
                        offerImageAltText={offerImageAltText}
                        rewardsPerRowTablet={magicNumber.THREE}
                        offer={offer}
                        openModal={openOfferModal}
                        activateOffer={activateOffer}
                        classNameBanner={`${PREFIX}-offers-card__banner-icon-loyalty`}
                        classNameDates={`${PREFIX}-offers-card_loyalty-dates`}
                        classNameSubtitle={`${PREFIX}-offers-card_loyalty-subtitle`}
                        classNameAlign={`${PREFIX}-offers-card_loyalty-align`}
                        status={getButtonStatus(offer?.offerStatus, offerConstants, labels)}
                        columns={columns}
                        updateOfferState={setSelectedOffer}
                        component={offerComponentLocation.loyaltyOffer}
                        akamaiPolicy={getAkamaiPolicy(AkamaiImagePolicies.returnPolicy, fallbackOfferImageUrl)}
                    />
                ))}
            </div>
        )
    }

    const renderComponentContainer = (): JSX.Element => {
        const containerClasses = defineContainerClasses(isCollapsed, isLoggedIn, isFooterVisible(isMobileLayout))
        const hiddenContainerClasses = definePanelClasses(isCollapsed, isTriangleSelectUser)

        return (
            <div ref={containerRef} className={containerClasses}>
                {enableLoyaltyOffersPanelV3 ? renderToggleButtonV3() : renderToggleButton()}
                <div className={hiddenContainerClasses}>
                    {isLoggedIn ? (
                        <>
                            {renderHeader()}
                            {renderOfferCard()}
                            <div className={`${stylePrefix}_view-all-offers-button`}>
                                <Button
                                    id="view-all-offers"
                                    type="secondary"
                                    size="large"
                                    onClick={onViewAllOffersClick}
                                    label={viewAllOffersBtnText}></Button>
                            </div>
                        </>
                    ) : (
                        renderForNotLoggedInUser()
                    )}
                </div>
            </div>
        )
    }

    /**
     * function to render OfferFlyout
     * @return {JSX.Element}
     */
    const renderOfferDetailsModal = (): JSX.Element => {
        return (
            <>
                {checkDataLength(selectedOffer) && isOpenViewDetailsModal && (
                    <div className={`${PREFIX}__offers_details`}>
                        <OfferFlyout
                            {...props}
                            isOpen={isOpenViewDetailsModal}
                            setIsFlyoutOpen={setViewDetailsModal}
                            setIsActivatedOfferClick={setIsActivatedOfferClick}
                            isAccordionOpen={isAccordionOpen}
                            offer={selectedOffer || ({} as Offers)}
                            activateOffer={activateOffer}
                            status={getButtonStatus(selectedOffer?.offerStatus, offerConstants, labels)}
                            columns={columns}
                            modalLabel={offerDetailsLabel}
                            updateOfferState={setSelectedOffer}
                            component={offerComponentLocation.loyaltyOffer}
                            akamaiPolicy={getAkamaiPolicy(AkamaiImagePolicies.returnPolicy, fallbackOfferImageUrl)}
                            moreDetailsLabel={moreDetailsLabel}
                        />
                    </div>
                )}
            </>
        )
    }

    return (
        <>
            {!isCollapsed && <div ref={overlayRef} className={`${stylePrefix}_overlay ${EXPANDED}`} />}
            {renderComponentContainer()}
            {renderOfferDetailsModal()}
        </>
    )
}

LoyaltyOffers.propTypes = {
    offersToDisplayOnDesktop: PropTypes.number.isRequired,
    offersToDisplayOnMobile: PropTypes.number.isRequired,
    toggleBtnSignInText: PropTypes.string.isRequired,
    toggleBtnText: PropTypes.string.isRequired,
    notSignedInInfoMsg: PropTypes.string.isRequired,
    signInAdviceMsg: PropTypes.string.isRequired,
    signUpBtnTextDesktop: PropTypes.string.isRequired,
    signUpBtnTextMobile: PropTypes.string.isRequired,
    offersHeaderTitle: PropTypes.string.isRequired,
    offersHeaderSubtitle: PropTypes.string.isRequired,
    viewAllOffersBtnText: PropTypes.string.isRequired,
    offerEndsLabel: PropTypes.string.isRequired,
    offerEndsTodayLabel: PropTypes.string.isRequired,
    offerImageAltText: PropTypes.string,
    activateOfferBtnText: PropTypes.string.isRequired,
    activatedOfferBtnText: PropTypes.string.isRequired,
    bannerImageList: PropTypes.array.isRequired,
    offerDetailsLabel: PropTypes.string.isRequired,
    offerTermsLabel: PropTypes.string.isRequired,
    offerExpiresLabel: PropTypes.string.isRequired,
    loadOffers: PropTypes.func.isRequired,
    onPanelDismiss: PropTypes.func.isRequired,
}

export default LoyaltyOffers
