import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Carousel, CategoryCard, isArrayNotEmpty, isArrayEmpty, SkeletonComponent } from '@nl/lib'
import { PREFIX } from '../../config'
import { RootState } from '../../redux/reducers'
import { CertonaProductType } from '../../certona/certona.type'
import { CertonaInitialization } from '../../certona/certona.service'
import {
    desktopThreshold,
    linkTargetConst,
    mScrollToShow,
    mSlidesToShow,
    titleAlignConst,
} from './CategoryCarousel.constant'
import { CategoryCarouselProps } from './CategoryCarousel.type'
import { usePageAnalytics } from '../../analytics/hooks/usePageAnalytics'
import { analyticsInteraction } from '../../redux/actions'
import { analyticsAttributes } from '../../globalConstants/analyticsParams.constant'
import { AkamaiImagePolicies } from '../../akamaiPolicy/akamaiPolicy.service'
import { isVehicleBannerRequired, isWheelOrTirePDP } from '../Vehicles/Vehicle.helper'
import getPageType from '../../utils/getPageType'
import { magicNumber } from '@nl/lib/src/utils/magicNumber'
import { lazyLoadImages } from '../../helpers/lazyLoadImage.helper'
import { productDataSelector } from '../../redux/selectors/product.selectors'
import {
    isFitmentRequiredSelector,
    productWheelTypeSelector,
    categoryIdDataSelector,
} from '../../redux/selectors/categoryIdData.selectors'
import BaseService from '../../services/base.service'
import { getEnvironment } from '../../environments'
import { automotivePackageFlowSelector } from '../../redux/selectors/automotivePackage.selector'
import { initialDropDownsFetchedSelector } from '../../redux/selectors/vehiclesData.selectors'

const CategoryCarousel: React.FC<CategoryCarouselProps> = ({
    title,
    schemaIdCatLevel2,
    schemaIdCatLevel3,
    schemaIdCatLevel4,
    linkTarget,
    titleAlignment,
    maximumNumberOfRecommendationsCC,
    minimumNumberOfRecommendationsCC,
}): JSX.Element => {
    const recommendationData = useSelector((state: RootState) => state.certona)
    const [recommendationItems, setRecommendationItems] = useState([] as CertonaProductType[])
    const [certonaTitle, setCertonaTitle] = useState('')
    const [showSkeleton, setShowSkeleton] = useState(true)
    const [schemaId, setSchemaId] = useState('')
    const {
        event: { filter },
        eventParameters: {
            action: { shopByCategory },
        },
    } = analyticsAttributes

    const isPackageFlow = useSelector(automotivePackageFlowSelector)
    const productWheelType = useSelector(productWheelTypeSelector)
    const isFitmentRequired = useSelector(isFitmentRequiredSelector)
    const vehicleApiStatus = useSelector(initialDropDownsFetchedSelector)
    const productData = useSelector(productDataSelector)
    const categoryIdData = useSelector(categoryIdDataSelector)
    const isTireOrWheelPLP = isWheelOrTirePDP(productWheelType)
    const pageType = getPageType()
    const config = getEnvironment()

    const schemaIdByCatLevel = {
        2: { schemaId: schemaIdCatLevel2 },
        3: { schemaId: schemaIdCatLevel3 },
        4: { schemaId: schemaIdCatLevel4 },
    }

    /**
     * Function to add padding top for category carousel if, vehicle banner is present
     * @return {string}
     */
    const modifierClass = (): string => {
        return isVehicleBannerRequired(isFitmentRequired, pageType, vehicleApiStatus, productData)
            ? `${PREFIX}-dynamic-carousel__category--addPadding`
            : ''
    }

    const dispatch = useDispatch()

    usePageAnalytics()

    useEffect(() => {
        const catLevel = categoryIdData?.breadcrumbList.length
        const authoredSchemaIdByCatLevel =
            schemaIdByCatLevel[`${catLevel}` as keyof typeof schemaIdByCatLevel]?.schemaId || ''

        setSchemaId(authoredSchemaIdByCatLevel)
    }, [categoryIdData, schemaIdByCatLevel])

    useEffect(() => {
        const schemes = recommendationData?.resonance?.schemes
        if (schemes) {
            if (isArrayEmpty(schemes)) {
                setShowSkeleton(false)
            } else {
                const { getItems, getTitle } = CertonaInitialization.extractSchemeDetails(schemes, schemaId)
                if (isArrayNotEmpty(getItems)) {
                    // TODO: Remove the below code when it is ready for production.
                    if (BaseService.isMock()) {
                        setRecommendationItems([...getItems, ...getItems])
                    } else {
                        setRecommendationItems(getItems)
                    }
                }
                setShowSkeleton(false)
                lazyLoadImages()
                setCertonaTitle(getTitle)
            }
        }
    }, [recommendationData, schemaId])

    useEffect(() => {
        lazyLoadImages()
    }, [showSkeleton])

    const cardClicked = (label: string | undefined) => {
        dispatch(analyticsInteraction(label as string, '', filter, shopByCategory))
    }
    const categoryCarousel = recommendationItems.length >= magicNumber.SIX ? `${PREFIX}-category-carousel` : ''
    const categorySliderCarousel =
        recommendationItems.length > magicNumber.SIX ? `${PREFIX}-category-slider-carousel` : ''

    /**
     * @return {JSX.Element[]}
     */
    const categoryCards = (): JSX.Element[] => {
        return recommendationItems
            .slice(0, maximumNumberOfRecommendationsCC)
            .map(({ url, label, image }: CertonaProductType, index: number) => (
                <CategoryCard
                    href={url}
                    imagePolicies={AkamaiImagePolicies.initIndPolicy}
                    key={index}
                    linkTarget={linkTarget}
                    cardClicked={cardClicked}
                    linkClass={`${PREFIX}-dynamic-carousel__category--link`}
                    imageClass={`${PREFIX}-dynamic-carousel__category--image`}
                    labelClass={`${PREFIX}-dynamic-carousel__category--label`}
                    image={image ? image : config.defaultProductImage}
                    label={label}
                />
            ))
    }
    const mobileCarouselSettings = {
        initialSlide: 0,
        centerMode: false,
        arrows: false,
        slidesToShow: mSlidesToShow,
        slidesToScroll: mScrollToShow,
    }

    // function is used to check whether we are in package flow and tiresOrWheels PLP
    const hideRecommendationInPackage = (): boolean => {
        return isTireOrWheelPLP && isPackageFlow
    }

    return (
        <>
            {isArrayNotEmpty(recommendationItems) &&
            recommendationItems.length >= minimumNumberOfRecommendationsCC &&
            !showSkeleton &&
            !hideRecommendationInPackage() ? (
                <div className={`${PREFIX}-container ${PREFIX}-dynamic-carousel__category ${modifierClass()}`}>
                    <div className={`${PREFIX}-col-md-12 ${PREFIX}-col-sm-12 ${PREFIX}-col-xs-6`}>
                        <h2
                            className={titleAlignment === titleAlignConst ? `${PREFIX}-category-title` : ''}
                            data-testid="recommendations-title">
                            {title || certonaTitle}
                        </h2>
                        <Carousel
                            carouselList={categoryCards()}
                            carouselClassName={`${categoryCarousel} ${categorySliderCarousel}`}
                            desktopThreshold={desktopThreshold}
                            mobileCarouselSettings={mobileCarouselSettings}
                        />
                    </div>
                </div>
            ) : (
                showSkeleton && <SkeletonComponent skeletonClass={`${PREFIX}-dynamic-carousel__category--skeleton`} />
            )}
        </>
    )
}

CategoryCarousel.defaultProps = {
    titleAlignment: titleAlignConst,
    linkTarget: linkTargetConst,
}

export default CategoryCarousel
