import React from 'react'
import PropTypes from 'prop-types'

import { PREFIX, autoService } from '../../../config'
import { Accordion, isArrayNotEmpty } from '@nl/lib'
import { StoreHoursInfoProps } from './StoreHoursInfo.type'
import StoreHoursList from './StoreHoursList/StoreHoursList'
import { StoreService, StoreServiceWithStoreTimezone } from '../../../redux/models/storeDetails.interface'
import { openAccordionAnalytics } from '../../../analytics/components/storeDetailsAnalytics'
import AutoServiceButton from '../../AutoServiceButton'
import { AccordionList } from '../StoreDetailsComp.type'

/**
 * Generate StoreHoursInfo Component To Be Used In Store Details
 * @param {props} props
 * @return {JSX.Element} returns StoreHoursInfo component
 */
const StoreHoursInfo: React.FC<StoreHoursInfoProps> = ({ ...props }): JSX.Element => {
    const { storeDetails, storeHoursListContent, storeId, separator } = props
    const { expandLabel, collapseLabel, accordionItems } = storeHoursListContent
    const autoServiceButton = `${PREFIX}-auto-service-button`
    const { storeServices } = storeDetails

    /**
     * Analytics Accordion Click
     * @param {string} accordionTitle
     */
    const accordionClick = (accordionTitle: string): void => {
        openAccordionAnalytics(accordionTitle)
    }

    /**
     * function to arrange service list as per AEM accordion sequence and item label from aem props
     * @return  {StoreServiceWithStoreTimezone[]} updatedStoreServiceList
     */

    const getServiceListOrderByAEM = (): StoreServiceWithStoreTimezone[] => {
        const updatedStoreServiceList: StoreServiceWithStoreTimezone[] = []
        let updatedStoreServiceObj: StoreServiceWithStoreTimezone = {}
        accordionItems?.forEach((accordionItemsValue: AccordionList) => {
            if (accordionItemsValue && isArrayNotEmpty(storeServices)) {
                const storeServiceObj = storeServices?.find(
                    (storeServiceItem: StoreService) => storeServiceItem.id.toString() === accordionItemsValue.labelID,
                )
                updatedStoreServiceObj = {
                    ...(storeServiceObj as StoreService),
                    name: accordionItemsValue.itemLabel,
                    storeTimezone: storeDetails.storeTimezone,
                }
                storeServiceObj && updatedStoreServiceList.push(updatedStoreServiceObj)
            }
        })
        return updatedStoreServiceList
    }

    /**
     * Accordion Items Array From Service
     * @return {JSX.Element[]} returns Accordion Items From Service
     */
    const storeHoursInfoHTML = (): JSX.Element[] => {
        return getServiceListOrderByAEM().map((storeService: StoreServiceWithStoreTimezone, index: number) => (
            <Accordion
                key={index}
                title={storeService.name}
                expandLabel={expandLabel}
                collapseLabel={collapseLabel}
                collapseControl={(isOpen: boolean) => isOpen && accordionClick(storeService.name)}>
                <StoreHoursList
                    storeService={storeService}
                    storeHoursListContent={storeHoursListContent}
                    separator={separator}
                />
                {storeService?.weekDayOpeningList?.find(item => item.storeArea === autoService) && (
                    <div className={autoServiceButton}>
                        <AutoServiceButton storeNumberForFrame={storeId} />
                    </div>
                )}
            </Accordion>
        ))
    }

    return <>{Object.keys(storeDetails).length > 0 && storeHoursInfoHTML()}</>
}

StoreHoursInfo.propTypes = {
    storeDetails: PropTypes.any,
    storeHoursListContent: PropTypes.any,
    storeId: PropTypes.string,
}

export default StoreHoursInfo
