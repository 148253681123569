import { createAction } from 'typesafe-actions'
import { WeatherTechVehicle } from '../models/weatherTech.interface'
import {
    SET_IS_WEATHER_TECH_FLOW,
    SET_WEATHER_TECH_VEHICLE,
    SET_WEATHER_TECH_VEHICLE_VENDOR_RESULTS,
} from '../types/weatherTech/weatherTech.actionTypes.constant'

export const setWeatherTechVehicle = createAction(
    SET_WEATHER_TECH_VEHICLE,
    (payload: WeatherTechVehicle | null) => payload,
)<WeatherTechVehicle>()

export const setWeatherTechVehicleVendorResults = createAction(
    SET_WEATHER_TECH_VEHICLE_VENDOR_RESULTS,
    (payload: string[]) => payload,
)<string[]>()

export const setIsWeatherTechFlow = createAction(SET_IS_WEATHER_TECH_FLOW, (payload: boolean) => payload)<boolean>()
