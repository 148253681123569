import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../Icon'
import { PREFIX } from '../config'
import { featureProps } from './Features.type'
import { isArrayNotEmpty } from '../../utils/isArrayNotEmpty'

const renderItem = (name: string, item: unknown, icon: string, path: string): JSX.Element[] => {
    return Object.keys(item).map((key, index) => {
        return (
            <li key={`${name}-${index}`}>
                <div className={`${PREFIX}-features__icon`}>
                    <Icon type={icon} size="md" path={path} />
                </div>
                <p className={`${PREFIX}-features__information`}>{item[key]}</p>
            </li>
        )
    })
}

/**
 * Features component
 *
 * @param {featureProps} props - title, icon, path, featureList
 * @return {JSX.Element} returns Features component
 */

const Features: React.FC<featureProps> = ({ ...props }) => {
    const { icon, path, featureList, title, isShowTitle } = props

    /**
     * function to check whether the device is tablet/mobile/desktop
     */

    return (
        <div id="features" className={`${PREFIX}-features`}>
            {isShowTitle && <h3 className={`${PREFIX}-features__header`}>{title}</h3>}
            <div className={`${PREFIX}-row`}>
                {isArrayNotEmpty(featureList) && (
                    <ul data-testid="features" className={`${PREFIX}-features__list`}>
                        {featureList.map(item => {
                            return renderItem('featureList', item, icon, path)
                        })}
                    </ul>
                )}
            </div>
        </div>
    )
}

Features.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    path: PropTypes.string,
    featureList: PropTypes.any,
}
export default Features
