import { phoneNumberFormat, phoneNumberPattern } from '../globalConstants/global.constant'

/**
 * Returns formatted phone number in pattern provided
 * @param {string} phoneNumber
 * @return {string}
 */

export const getFormattedPhoneNumber = (phoneNumber: string): string => {
    return phoneNumber?.replace(phoneNumberFormat, phoneNumberPattern)
}

/** This function is to formate phone number for accessibility.
 * This is done because in android talkback phone no. is announced as two billion five hundred and six million three hundred and fifty seven thousand one hundred and seventy eight.
 * 2506357178 will be formated to 2 5 0 6 3 5 7 1 7 8
 * @param {string} phoneNumber - contact number
 * @return {string}
 */
export const formatedPhoneNumberAccessibility = (phoneNumber: string): string => {
    return phoneNumber?.split('').join(' ')
}
