import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { PREFIX, BREAKPOINTS } from '../../config'
import { SkeletonComponent } from '@nl/lib'
import TriangleRewards from '../OrderSummary/TriangleRewards'
import { WishlistSummaryProps } from './WishListSummary.type'
import WishlistBenefits from './WishlistBenefits'
import appCacheService from '../../utils/appCacheService'
import { wishlistDataSelector, wishlistLoadSuccessSelector } from '../../redux/selectors/wishlist.selectors'

/**
 * WishListSummary component
 * @param {WishlistSummaryProps} props
 * @return {JSX.Element} returns TriangleRewards and WishListBenefits components
 */
const WishListSummary = (props: WishlistSummaryProps): JSX.Element => {
    const wishlistData = useSelector(wishlistDataSelector)
    const wishlistLoadSuccess = useSelector(wishlistLoadSuccessSelector)

    const shouldRenderSkeleton = (): boolean => {
        return (
            window.innerWidth < BREAKPOINTS.tabletMaxWidth &&
            !wishlistLoadSuccess &&
            !!appCacheService.wishlistGuid.get()
        )
    }

    const renderWishListSummary = (): JSX.Element => {
        const triangleRewardsProps = {
            memberMsgUnauthRichtext: props.memberMsgUnAuthRichText,
            earningMsgNoLinkedRichText: props.earningMsgEmptyNoLinkedRichText,
            memberMsgAuthRichText: props.memberMsgAuthRichText,
            earningMsgAuthRichText: props.earningMsgEmptyLinkedRichText,
        }
        return (
            <>
                <div className={`${PREFIX}-wishlistsummary__header`}>{props.wTitle}</div>
                <TriangleRewards {...triangleRewardsProps} tRewardsTitle={props.tRewardsTitle} />
                <WishlistBenefits
                    wbTitle={props.wbTitle}
                    wbDescription={props.wbDescription}
                    wbTriangleIDCTA={props.wbTriangleIDCTA}
                    wbSIgnInText={props.wbSIgnInText}
                    wishlistData={wishlistData}
                />
            </>
        )
    }

    const renderSkeleton = (): JSX.Element => {
        return (
            <>
                <SkeletonComponent skeletonClass={`${PREFIX}-wishlistsummary-title`} />
                <SkeletonComponent skeletonClass={`${PREFIX}-triangle-rewards__skeleton`} />
                <SkeletonComponent skeletonClass={`${PREFIX}-wishlistsummary-benefits__skeleton`} />
            </>
        )
    }

    return (
        <div className={`${PREFIX}-wishlistsummary`} data-testid="wishlist-summary">
            {shouldRenderSkeleton() ? renderSkeleton() : renderWishListSummary()}
        </div>
    )
}

WishListSummary.propTypes = {
    title: PropTypes.string,
    wTitle: PropTypes.string,
    wbTitle: PropTypes.string,
    wbDescription: PropTypes.string,
    wbTriangleIDCTA: PropTypes.string,
    wbSIgnInText: PropTypes.string,
    memberMsgUnAuthRichText: PropTypes.string,
    memberMsgAuthRichText: PropTypes.string,
    earningMsgEmptyNoLinkedRichText: PropTypes.string,
    earningMsgEmptyLinkedRichText: PropTypes.string,
    wishlistData: PropTypes.string,
    tRewardsTitle: PropTypes.string,
}

export default WishListSummary
