import { AxiosPromise } from 'axios'

import BaseService from '../base.service'
import { getEnvironment } from '../../environments'
import { getHttpClient } from '../../httpClient'
import {
    getParamValue,
    updateParam,
    queryParameters,
    Vehicle,
    isArrayNotEmpty,
    getCookieValue,
    getSAPIRequestQueryParam,
} from '@nl/lib'
import { MagicNumber } from '../../analytics/analytics.type'
import getCategoriesFromURL from '../../utils/PLP/getCategoriesFromURL'
import getPageType from '../../utils/getPageType'
import { pageTypes, REF_URL_KEY } from '../../config'
import { searchPageTypes } from '../service.constants'
import { checkDataLength } from '../../components/Accounts/Addresses/checkDataLength'
import { categoryLevelPrefix, FacetList } from '../../components/Vehicles/Vehicles.constant'
import { HttpReqHeaders } from '../../redux/utils/httpClient.type'
import sessionStorageService from '../../utils/sessionStorageService'
import { WeatherTechVehicle } from '../../redux/models/weatherTech.interface'

const environment = getEnvironment()
const httpClient = getHttpClient()
const pageType = getPageType()

/**
 * Search service
 */
class PlpService extends BaseService {
    /**
     * Function to fetch Search Results.
     * @param {string} requestPayload
     * @param {string} store
     * @param {boolean | undefined} isFitmentRequired
     * @param {number} productToFetch
     * @param {number} pageNumber
     * @param {string} pathwayId
     * @param {string[]} breadcrumbList
     * @param {Vehicle} defaultVehicle
     * @param {string} categoryId
     * @param {boolean} isTirePLPData
     * @param {string} categoryURL
     * @param {string} currentPackageId
     * @param {string} isCompleteVehicleState
     * @param {string[]} searchSuppressFacets
     * @param {string} searchWidgetType
     * @param {string} searchCategoryLevel
     * @param {boolean | undefined} searchPassQParameter
     * @param {string} searchQParameter
     * @param {string} searchExperience
     * @param {string} tireOrWheelSpecification
     * @param {boolean} enableXRingFence
     * @return {AxiosPromise}
     */
    static getProductData(
        requestPayload: string,
        store: string,
        isFitmentRequired: boolean | undefined,
        productToFetch?: number,
        pageNumber: number = MagicNumber.ONE,
        pathwayId?: string,
        breadcrumbList?: string[],
        defaultVehicle?: Vehicle,
        categoryId?: string,
        isTirePLPData?: boolean,
        categoryURL?: string,
        currentPackageId?: string,
        isCompleteVehicleState?: boolean,
        searchSuppressFacets?: string[],
        searchWidgetType?: string,
        searchCategoryLevel?: string,
        searchPassQParameter?: boolean | undefined,
        searchQParameter?: string,
        searchExperience?: string,
        tireOrWheelSpecification?: string,
        enableXRingFence?: boolean,
    ): AxiosPromise<unknown> {
        const brUID = getCookieValue('_br_uid_2')
        const brUIDheader = brUID ? { 'X-BR-UID': brUID } : undefined
        const refURL = sessionStorageService.getItem(REF_URL_KEY)
        const referrer = refURL && { 'X-BR-REF-URL': refURL }
        const xBrRef = { 'X-BR-REF': window.location.href }
        const headers: HttpReqHeaders = {
            'x-ringfence': !!enableXRingFence,
            ...brUIDheader,
            ...referrer,
            ...xBrRef,
        }
        return httpClient.apiGet(
            this.getPlpUrl(
                requestPayload,
                store,
                isFitmentRequired,
                productToFetch,
                pageNumber,
                categoryId,
                isTirePLPData,
                pathwayId,
                breadcrumbList,
                defaultVehicle,
                categoryURL,
                currentPackageId,
                isCompleteVehicleState,
                searchSuppressFacets,
                searchWidgetType,
                searchCategoryLevel,
                searchPassQParameter,
                searchQParameter,
                searchExperience,
                tireOrWheelSpecification,
            ),
            {},
            headers,
        )
    }

    /**
     * Function to fetch Weathertech Products.
     * @param {string} requestPayload
     * @param {WeatherTechVehicle} weatherTechVehicle
     * @param {string} store
     * @param {number} productToFetch
     * @param {number} pageNumber
     * @param {string} pathwayId
     * @param {string} categoryId
     * @param {string[]} searchSuppressFacets
     * @param {string} searchWidgetType
     * @param {string} searchCategoryLevel
     * @param {string} searchExperience
     * @param {boolean} enableXRingFence
     * @return {AxiosPromise}
     */
    static getWeatherTechProductData(
        requestPayload: string,
        weatherTechVehicle: WeatherTechVehicle,
        store: string,
        productToFetch?: number,
        pageNumber: number = MagicNumber.ONE,
        pathwayId?: string,
        categoryId?: string,
        searchSuppressFacets?: string[],
        searchWidgetType?: string,
        searchCategoryLevel?: string,
        searchExperience?: string,
        enableXRingFence?: boolean,
    ): AxiosPromise<unknown> {
        const brUID = getCookieValue('_br_uid_2')
        const brUIDheader = brUID ? { 'X-BR-UID': brUID } : undefined
        const refURL = sessionStorageService.getItem(REF_URL_KEY)
        const referrer = refURL && { 'X-BR-REF-URL': refURL }
        const xBrRef = { 'X-BR-REF': window.location.href }
        const headers: HttpReqHeaders = {
            'x-ringfence': !!enableXRingFence,
            ...brUIDheader,
            ...referrer,
            ...xBrRef,
        }
        const vendorResults = weatherTechVehicle.vendorResults

        return httpClient.apiPost(
            this.getPlpUrl(
                requestPayload,
                store,
                undefined,
                productToFetch,
                pageNumber,
                categoryId,
                undefined,
                pathwayId,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                searchSuppressFacets,
                searchWidgetType,
                searchCategoryLevel,
                undefined,
                undefined,
                searchExperience,
                undefined,
                true,
                weatherTechVehicle,
            ),
            checkDataLength(vendorResults) ? { vendorResults } : {},
            headers,
        )
    }

    /**
     * Function to check if the page belongs to cat or not.
     * @return {boolean}
     */
    static isCategoryPage = (): boolean => pageTypes.categoryPages.includes(pageType)

    /**
     * Function to check if the page is packagelanding page.
     * @return {boolean}
     */
    static isPackageLandingPage = (): boolean => pageTypes.packageLanding === pageType

    /**
     * Function to get category path
     * @param {string} path
     * @return {string | undefined}
     */
    static getCategoryPath = (path: string): string | undefined => {
        return path?.slice(0, path?.lastIndexOf('-'))
    }

    /**
     * Function to get category Id
     * @param {string | undefined} categoryId
     * @param {string} path
     * @return {string}
     */
    static getCategoryId = (categoryId: string | undefined, path: string): string => {
        if (PlpService.isCategoryPage()) {
            /**
             * Example: a/b-c/c-id - return value of extractCategoryPath
             * below will extract a/b-c/c
             * then will replace '/' with '::'
             */
            return path?.slice(path?.lastIndexOf('-') + MagicNumber.ONE)
        } else if (PlpService.isPackageLandingPage() && categoryId) {
            return categoryId
        } else {
            return ''
        }
    }

    /**
     * function to append page number
     * @param {string} url
     * @param {string} page
     * @param {string} pageNumber
     * @return {string}
     */
    static appendPageNumber = (url: string, page: string, pageNumber: string): string => {
        const pageNumberFromRequest = getParamValue(url, queryParameters.page, queryParameters.divider)
        return page && !pageNumberFromRequest
            ? `${url}`
            : `${updateParam(url, queryParameters.page, queryParameters.divider, `${pageNumber}`)}`
    }

    /**
     * function to get tire/wheel filter option query string
     * @param {string[]} vehicleQueryString
     * @param {number} vehicleQueryStringIndex
     * @param {Vehicle} defaultVehicle
     * @return {string[]}
     */
    static getAutomotiveSearchQueryString = (
        vehicleQueryString: string[],
        vehicleQueryStringIndex: number,
        defaultVehicle: Vehicle,
    ): string[] => {
        if (defaultVehicle && checkDataLength(defaultVehicle) && defaultVehicle?.autoAttributes) {
            const knownVehicle = Object.keys(defaultVehicle?.autoAttributes)
            knownVehicle?.forEach((attributeName: string) => {
                vehicleQueryString.push(
                    `x${vehicleQueryStringIndex}=auto.${attributeName};q${vehicleQueryStringIndex}=${encodeURIComponent(
                        defaultVehicle.autoAttributes[attributeName],
                    )}`,
                )
                vehicleQueryStringIndex++
            })
        } else if (
            defaultVehicle &&
            defaultVehicle.selectedSectionWidth &&
            defaultVehicle.selectedAspectRatio &&
            defaultVehicle.selectedDiameterValue
        ) {
            vehicleQueryString.push(
                `q=${String(defaultVehicle.selectedSectionWidth)}/${String(
                    defaultVehicle.selectedAspectRatio,
                )}R${String(defaultVehicle.selectedDiameterValue)}`,
            )
        } else {
            // this is added for OCCP-22619 without sending vehicletype products are not returned from api
            vehicleQueryString.push(`&x${vehicleQueryStringIndex}=auto.vehicleType;q${vehicleQueryStringIndex}=*`)
        }

        return vehicleQueryString
    }

    /**
     * function to get vehicle information
     * @param {Vehicle} defaultVehicle
     * @param {number} indexPosition
     * @param {string[]} breadcrumbList
     * @param {string} categoryLevel
     * @param {string} id
     * @param {string} currentPackageId
     * @param {boolean | undefined} isFitmentRequired
     * @return {string}
     */
    static getVehicleInformation = (
        defaultVehicle: Vehicle,
        indexPosition: number,
        breadcrumbList: string[],
        categoryLevel: string,
        id: string,
        currentPackageId?: string,
        isFitmentRequired?: boolean,
    ): string => {
        let vehicleQueryString: string[] = []
        let vehicleQueryStringIndex = indexPosition

        if (!!isFitmentRequired) {
            vehicleQueryString = PlpService.getAutomotiveSearchQueryString(
                vehicleQueryString,
                vehicleQueryStringIndex,
                defaultVehicle,
            )
            vehicleQueryStringIndex = vehicleQueryString.length + MagicNumber.ONE
            if (!currentPackageId && vehicleQueryString.length > 0) {
                vehicleQueryString.push(`x${vehicleQueryStringIndex}=auto.application;q${vehicleQueryStringIndex}=both`)
                vehicleQueryStringIndex++
            }
        }

        if (isArrayNotEmpty(breadcrumbList) && !!isFitmentRequired) {
            breadcrumbList.forEach((category: string, index: number) => {
                vehicleQueryString.push(
                    `x${vehicleQueryStringIndex}=c.${categoryLevelPrefix}-${
                        index + MagicNumber.ONE
                    };q${vehicleQueryStringIndex}=${encodeURIComponent(category)}`,
                )
                vehicleQueryStringIndex++
            })
        } else {
            vehicleQueryString.push(
                `x${vehicleQueryStringIndex}=ast-id-level-${
                    categoryLevel?.split('/')?.length
                }&q${vehicleQueryStringIndex}=${id}`,
            )
            vehicleQueryStringIndex++
        }
        return `&${vehicleQueryString?.join(';')}`
    }

    /**
     * function to get category level
     * @param {string} path
     * @param {string} categoryURL
     * @return {string}
     */
    static getCategoryLevel = (path: string, categoryURL: string): string => {
        return PlpService.isCategoryPage()
            ? PlpService.getCategoryPath(path)
            : getCategoriesFromURL([]).extractCategoryPath(categoryURL)
    }

    /**
     * function to create a request payload for products call
     * @param {boolean} isWeatherTech
     * @param {WeatherTechVehicle} weatherTechVehicle
     * @return {string}
     */
    static createWeatherTechProductRequestPayload = (
        isWeatherTech: boolean,
        weatherTechVehicle: WeatherTechVehicle,
    ): string => {
        return isWeatherTech
            ? `/${weatherTechVehicle.year}/${weatherTechVehicle.vehicleId}/${weatherTechVehicle.groupId}/${weatherTechVehicle.choiceIds}/`
            : ''
    }

    /**
     * function to append SEARCH params to the url
     * @param {string} plpCDSUrl
     * @param {string} pathwayId
     * @param {string[]} searchSuppressFacets
     * @param {string} searchWidgetType
     * @param {boolean | undefined} searchPassQParameter
     * @param {string} searchQParameter
     * @return {string}
     */
    static createUrlWithSearchParams(
        plpCDSUrl: string,
        pathwayId?: string,
        searchSuppressFacets?: string[],
        searchWidgetType?: string,
        searchPassQParameter?: boolean | undefined,
        searchQParameter?: string,
    ): string {
        // if it is necessary to hide selected facets
        if (searchSuppressFacets?.length) {
            let hideFacets = ''
            searchSuppressFacets?.map((facet, i) => {
                hideFacets += facet.searchSuppressFacet
                if (i !== searchSuppressFacets.length - MagicNumber.ONE) {
                    hideFacets += '|'
                }
            })
            plpCDSUrl = `${plpCDSUrl}&hideFacets=${hideFacets}`
        }

        // if pageType corresponds to bcplp/promo/event then append the WidgetId
        const enableSearchPageParameter = () => {
            return (
                pageType === pageTypes.brandCategoryPage ||
                pageType === pageTypes.promoListing ||
                pageType === pageTypes.eventListing
            )
        }

        const urlHasQueryParam = (url: string) => {
            return url.includes(';q=') || url.includes('&q=') || url.includes('?q=')
        }

        if (!!pathwayId && enableSearchPageParameter()) {
            plpCDSUrl = `${plpCDSUrl}&widgetId=${pathwayId}`
        }
        // if searchWidgetType exists append widget type to the url
        if (searchWidgetType) {
            plpCDSUrl = `${plpCDSUrl}&widgetType=${searchWidgetType}`
        }

        // if true append q parameter to the url. Applicable for global promotion and event pages
        if (enableSearchPageParameter() && !urlHasQueryParam(plpCDSUrl)) {
            if (searchPassQParameter && searchQParameter) {
                plpCDSUrl = `${plpCDSUrl}&q=${searchQParameter}`
            } else {
                plpCDSUrl = `${plpCDSUrl}&q=.`
            }
        }

        return plpCDSUrl
    }

    /**
     * function to append params to the url
     * @param {string} plpCDSUrl
     * @param {string} pathwayId
     * @param {number} productToFetch
     * @param {string | undefined} minPrice
     * @param {string | undefined} maxPrice
     * @param {boolean | undefined} isFitmentRequired
     * @param {boolean} searchParamExists
     * @param {string} requestPayload
     * @param {boolean} isCompleteVehicleState
     * @param {string[]} searchSuppressFacets
     * @param {string} searchWidgetType
     * @param {boolean | undefined} searchPassQParameter
     * @param {string} searchQParameter
     * @param {string} tireOrWheelSize
     * @return {string}
     */
    static createUrlWithParams(
        plpCDSUrl: string,
        pathwayId?: string,
        productToFetch?: number,
        minPrice?: string,
        maxPrice?: string,
        isFitmentRequired?: boolean | undefined,
        searchParamExists?: boolean,
        requestPayload?: string,
        isCompleteVehicleState?: boolean,
        searchSuppressFacets?: string[],
        searchWidgetType?: string,
        searchPassQParameter?: boolean | undefined,
        searchQParameter?: string,
        tireOrWheelSize?: string,
    ): string {
        // if product fetch count is given, then append count to the url
        if (productToFetch) {
            plpCDSUrl = `${plpCDSUrl};count=${productToFetch}`
        }

        if (minPrice) {
            plpCDSUrl = `${plpCDSUrl}&priceLowerBound=${minPrice}`
        }
        if (maxPrice) {
            plpCDSUrl = `${plpCDSUrl}&priceUpperBound=${maxPrice}`
        }

        if (tireOrWheelSize) {
            plpCDSUrl = `${plpCDSUrl}&q=${tireOrWheelSize}`
        }

        // if automotive , use this flag to update CDS about automotive flag
        if (isFitmentRequired) {
            const hideSKUFacets = isCompleteVehicleState ? '' : `|${FacetList.partFacet}|${FacetList.positionFacet}`
            plpCDSUrl = `${plpCDSUrl}&hideFacets=${FacetList.deliveryPickupOptions}${hideSKUFacets}&automotive=${String(
                isFitmentRequired,
            )}`
        }
        // if any one of the search param exists, use the url from api
        if (searchParamExists) {
            plpCDSUrl = requestPayload
            const count = getParamValue(plpCDSUrl, queryParameters.count, queryParameters.divider)
            // adding a count parameter as a innitial count while calling the api
            if (!count) {
                plpCDSUrl = plpCDSUrl + `;${queryParameters.count}=${String(productToFetch)}`
            } else {
                const page = getParamValue(plpCDSUrl, queryParameters.page, queryParameters.divider)
                if (page) {
                    plpCDSUrl = updateParam(plpCDSUrl, queryParameters.count, queryParameters.divider, productToFetch)
                }
            }
        }
        // Append the Search params to the url
        plpCDSUrl = `${this.createUrlWithSearchParams(
            plpCDSUrl,
            pathwayId,
            searchSuppressFacets,
            searchWidgetType,
            searchPassQParameter,
            searchQParameter,
        )}`

        return plpCDSUrl
    }

    /**
     * function to get CDS PLP Url
     * @param {string} requestPayload
     * @param {string} store
     * @param {boolean | undefined} isFitmentRequired
     * @param {number} productToFetch
     * @param {number} pageNumber
     * @param {string | undefined} categoryId
     * @param {string} pathwayId
     * @param {string} categoryURL
     * @param {string[]} breadcrumbList
     * @param {Vehicle} defaultVehicle
     * @param {string} currentPackageId
     * @param {boolean} isCompleteVehicleState
     * @param {string[]} searchSuppressFacets
     * @param {string} searchWidgetType
     * @param {string} searchCategoryLevel
     * @param {boolean | undefined} searchPassQParameter
     * @param {string} searchQParameter
     * @param {string} searchExperience
     * @param {string} tireOrWheelSize
     * @return {string}
     */
    /* eslint-disable require-jsdoc */
    /* eslint-disable complexity */
    static getCDSPlpUrl(
        requestPayload: string,
        store: string,
        isFitmentRequired: boolean | undefined,
        productToFetch?: number,
        pageNumber: number = MagicNumber.ONE,
        categoryId?: string,
        pathwayId?: string,
        categoryURL?: string,
        breadcrumbList?: string[],
        defaultVehicle?: Vehicle,
        currentPackageId?: string,
        isCompleteVehicleState?: boolean,
        searchSuppressFacets?: string[],
        searchWidgetType?: string,
        searchCategoryLevel?: string,
        searchPassQParameter?: boolean | undefined,
        searchQParameter?: string,
        searchExperience?: string,
        tireOrWheelSize?: string,
        isWeatherTech?: boolean,
        weatherTechVehicle?: WeatherTechVehicle,
    ): string {
        const {
            API_ENDPOINTS: { getSearchResults, apimEndPointWeatherTechProducts },
        } = environment
        const url = isWeatherTech ? apimEndPointWeatherTechProducts : getSearchResults
        const locale = PlpService.language
        const productItem = getParamValue(requestPayload, queryParameters.searchQuery, queryParameters.divider)
        const rqParam = getParamValue(requestPayload, queryParameters.searchRequestQuery, queryParameters.divider)
        const minPrice = getParamValue(requestPayload, queryParameters.priceLowerBound, queryParameters.divider)
        const maxPrice = getParamValue(requestPayload, queryParameters.priceUpperBound, queryParameters.divider)
        const storeParam = getParamValue(requestPayload, queryParameters.store, queryParameters.divider)
        const searchParamExists = requestPayload?.split(queryParameters.divider)?.length > MagicNumber.TWO

        let plpCDSUrl = `?${queryParameters.store}=${store}${queryParameters.plpCDSDivider}${queryParameters.language}=${locale}`

        if (Boolean(getSAPIRequestQueryParam())) {
            plpCDSUrl = `${plpCDSUrl}${queryParameters.plpCDSDivider}rq=${rqParam as string}`
        }

        const path = getCategoriesFromURL([]).extractCategoryPath()
        const categoryLevel = searchCategoryLevel ? searchCategoryLevel : this.getCategoryLevel(path, categoryURL)
        const id = this.getCategoryId(categoryId, path)

        const indexPosition = searchParamExists ? MagicNumber.HUNDRED : MagicNumber.ONE
        const vehicleQueryString = PlpService.getVehicleInformation(
            defaultVehicle,
            indexPosition,
            breadcrumbList,
            categoryLevel,
            id,
            currentPackageId,
            isFitmentRequired,
        )

        const pageTypeValue = searchPageTypes[`${pageType}` as keyof typeof searchPageTypes]?.x1
        // if product item is given in url, then append product to the url
        if (productItem && !id) {
            plpCDSUrl = `${plpCDSUrl}&q=${productItem}`
        } else if (
            (pageType === pageTypes.brandCategoryPage || pageType === pageTypes.promoListing) &&
            !!searchCategoryLevel &&
            !!categoryId
        ) {
            plpCDSUrl = `${plpCDSUrl}&x${indexPosition}=${searchCategoryLevel}&q${indexPosition}=${categoryId}`
        } else if (
            !!pathwayId &&
            !!pageTypeValue &&
            pageType !== pageTypes.promoListing &&
            pageType !== pageTypes.eventListing
        ) {
            const showExtraParam = pageType === pageTypes.brandPage ? `&q=${pathwayId}` : ``
            plpCDSUrl = `${plpCDSUrl}&x${indexPosition}=${pageTypeValue}&q${indexPosition}=${pathwayId}${showExtraParam}`
        } else if (pageType !== pageTypes.promoListing && pageType !== pageTypes.eventListing) {
            plpCDSUrl = `${plpCDSUrl}${vehicleQueryString}`
        }

        if (!!searchExperience) {
            plpCDSUrl = `${plpCDSUrl}&experience=${searchExperience}`
        }

        if (searchParamExists && !storeParam) {
            requestPayload = `${requestPayload};store=${store}`
        }

        plpCDSUrl = `${this.createUrlWithParams(
            plpCDSUrl,
            pathwayId,
            productToFetch,
            minPrice,
            maxPrice,
            isFitmentRequired,
            searchParamExists,
            requestPayload,
            isCompleteVehicleState,
            searchSuppressFacets,
            searchWidgetType,
            searchPassQParameter,
            searchQParameter,
            tireOrWheelSize,
        )}`
        let lang = getParamValue(plpCDSUrl, queryParameters.language, queryParameters.plpCDSDivider)
        if (lang?.includes(queryParameters.divider)) {
            lang = lang.split(queryParameters.divider)[MagicNumber.ZERO]
        }
        if (lang !== locale) {
            plpCDSUrl = updateParam(plpCDSUrl, queryParameters.language, queryParameters.plpCDSDivider, locale)
        }
        const page = getParamValue(plpCDSUrl, queryParameters.page, queryParameters.divider)
        plpCDSUrl = `${PlpService.createWeatherTechProductRequestPayload(
            isWeatherTech,
            weatherTechVehicle,
        )}${plpCDSUrl}`
        return this.appendPageNumber(`${url}${plpCDSUrl}`, page, pageNumber)
    }

    /**
     * Function used to construct the query params for the search url
     * @param {string} requestPayload
     * @param {string} store
     * @param {boolean | undefined} isFitmentRequired
     * @param {number} productToFetch
     * @param {number} pageNumber
     * @param {string} categoryId
     * @param {boolean} isTirePLPData
     * @param {string} pathwayId
     * @param {string[]} breadcrumbList
     * @param {Vehicle} defaultVehicle
     * @param {string} categoryURL
     * @param {string} currentPackageId
     * @param {string} isCompleteVehicleState
     * @param {string[]} searchSuppressFacets
     * @param {string} searchWidgetType
     * @param {string} searchCategoryLevel
     * @param {boolean | undefined} searchPassQParameter
     * @param {string} searchQParameter
     * @param {string} searchExperience
     * @param {string} tireOrWheelSize
     * @param {boolean} isWeatherTech
     * @param {WeatherTechVehicle} weatherTechVehicle
     * @return {string} plp url
     */
    static getPlpUrl(
        requestPayload: string,
        store: string,
        isFitmentRequired: boolean | undefined,
        productToFetch?: number,
        pageNumber: number = MagicNumber.ONE,
        categoryId?: string,
        isTirePLPData?: boolean,
        pathwayId?: string,
        breadcrumbList?: string[],
        defaultVehicle?: Vehicle,
        categoryURL?: string,
        currentPackageId?: string,
        isCompleteVehicleState?: boolean,
        searchSuppressFacets?: string[],
        searchWidgetType?: string,
        searchCategoryLevel?: string,
        searchPassQParameter?: boolean | undefined,
        searchQParameter?: string,
        searchExperience?: string,
        tireOrWheelSize?: string,
        isWeatherTech?: boolean,
        weatherTechVehicle?: WeatherTechVehicle,
    ): string {
        const { API_BASE_URL } = environment
        return `${API_BASE_URL}${this.getCDSPlpUrl(
            requestPayload,
            store,
            isFitmentRequired,
            productToFetch,
            pageNumber,
            categoryId,
            pathwayId,
            categoryURL,
            breadcrumbList,
            defaultVehicle,
            currentPackageId,
            isCompleteVehicleState,
            searchSuppressFacets,
            searchWidgetType,
            searchCategoryLevel,
            searchPassQParameter,
            searchQParameter,
            searchExperience,
            tireOrWheelSize,
            isWeatherTech,
            weatherTechVehicle,
        )}`
    }
}

export { PlpService }
export default PlpService
