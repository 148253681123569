import {
    AddProductToCartGuestRequestDTO,
    CartOrderEntries,
    OrderEntryDTO,
    StaggeredFitmentProduct,
    FilteredCartData,
    MiniCartData,
    eEntryType,
    VehicleInformation,
    ServiceDTO,
    CartItemFulFillmentType,
} from '../../redux/models/cart.interface'
import { EntryGroups, EntryGroup } from '../../redux/models/orderConfirmation.interface'
import { saveForLaterParam, staggeredGroup } from './ShoppingCart.constants'
import appCacheService from '../../utils/appCacheService'
import { CartErrorService } from './CartError.service'
import { FulfillmentMethods, ItemInlineErrorType, SelectedDeliveryMode } from './ShoppingCart.type'
import { MagicNumber } from '../../analytics/analytics.type'
import { pageTypes } from '../../config'
import getPageType from '../../utils/getPageType'
import { isShoppingCartPage } from '../../utils/checkPageType'
import { isArrayNotEmpty } from '@nl/lib'
import { AddToWishListPayload } from '../../redux/models/wishlist.interface'
import { HardwareList } from '../../redux/models/extraHardware.interface'
import { extraHardwareService } from '../../services/extraHardwareService'
import { balloonAddonsService } from '../../services/balloonAddonsService'
import { ProductSku, ProductSkusData } from '../../redux/models/product.interface'
import { areAllParamsValid } from '../../utils/getFilteredCartItems'

/**
 * used to generate vehicle information based on input parameters
 * @param {string} year
 * @param {string} make
 * @param {string} model
 * @param {string} body
 * @param {string} options
 * @return {string}
 */
export const createVehicleInfo = ({
    year,
    make,
    model,
    body,
    options,
}: {
    year: string
    make: string
    model: string
    body: string
    options: string
}): string => {
    return `${year}${make}${model}${body}${options}`.toLowerCase()
}

/**
 * NOTE - We are defining a new function as we do not want to maintain body and options as null/undefined in vehicle info string
 * used to generate vehicle information based on input parameters
 * @param {string} year
 * @param {string} make
 * @param {string} model
 * @param {string} body
 * @param {string} options
 * @return {string}
 */
export const createWishListVehicleInfo = ({
    year,
    make,
    model,
    body,
    options,
}: {
    year: string
    make: string
    model: string
    body: string
    options: string
}): string => {
    return `${year}${make}${model}${body ? body : ''}${options ? options : ''}`.toLowerCase()
}

/**
 * NOTE - We are defining a new function as there is difference between namings of option property in vehicle info with respect to default vehicle and wishlist vehicle
 * used to generate vehicle information based on input parameters
 * @param {string} year
 * @param {string} make
 * @param {string} model
 * @param {string} body
 * @param {string} option
 * @return {string}
 */
export const createDefaultVehicleInfo = ({
    year,
    make,
    model,
    body,
    option,
}: {
    year: string
    make: string
    model: string
    body: string
    option: string
}): string => {
    return `${year}${make}${model}${body ? body : ''}${option ? option : ''}`.toLowerCase()
}
/**
 * function to get group and priority value from product staggeredGroup field
 * @param {CartOrderEntries} product
 * @return {string | undefined} group number
 */
const getValues = (product: CartOrderEntries) => {
    return product.staggeredGroup?.split('-') || []
}

/**
 * function is used to group the product based vehicle info
 * @param {CartOrderEntries} products
 * @param {Record<string, unknown>} automotiveProducts
 * @return {CartOrderEntries[]}
 */
export const groupVehicle = (
    products: CartOrderEntries,
    automotiveProducts: Record<string, unknown[]>,
): Record<string, unknown> => {
    const vehicleInfo = products.vehicleInformation ? createVehicleInfo(products.vehicleInformation) : null
    if (vehicleInfo) {
        automotiveProducts[vehicleInfo] = automotiveProducts[vehicleInfo] || []
        automotiveProducts[vehicleInfo].push(products)
    }
    return automotiveProducts
}

/**
 * function to sort products by staggeredGroup
 * @param {CartOrderEntries[]} products
 * @param {EntryGroups[]} entryGroups
 * @return {StaggeredFitmentProduct[]}
 */
export const sortStaggeredFitment = (
    products: CartOrderEntries[],
    entryGroups: EntryGroups[],
): StaggeredFitmentProduct[] => {
    const sortedByStaggeredGroup = [...products]
    sortedByStaggeredGroup.sort((a: CartOrderEntries, b: CartOrderEntries) => {
        const [groupA, priorityA] = getValues(a)
        const [groupB, priorityB] = getValues(b)

        const sortByGroup = parseInt(groupA) - parseInt(groupB)
        const sortByPriority = parseInt(priorityA) - parseInt(priorityB)

        return sortByGroup === 0 ? sortByPriority : sortByGroup
    })

    return sortedByStaggeredGroup.reduce((sortedProducts: CartOrderEntries[], product: CartOrderEntries) => {
        const [groupNumber, priority] = getValues(product)
        const entryGroup = entryGroups?.find((group: EntryGroups) => group.groupNumber.toString() === groupNumber)
        const productMatches = entryGroup?.entryGroup?.some(
            (entry: EntryGroup) => entry.priority.toString() === priority,
        )
        const isFront = priority === staggeredGroup.priorityZero

        return productMatches ? [...sortedProducts, { ...product, isFront }] : sortedProducts
    }, [])
}

/**
 * function to sort products - products with staggeredGroup on top
 * @param {CartOrderEntries[]} products
 * @param {EntryGroups[]} entryGroups
 * @return {StaggeredFitmentProduct[]}
 */
export const sortProductsByStaggeredGroup = (
    products: StaggeredFitmentProduct[],
    entryGroups: EntryGroups[] | undefined,
): StaggeredFitmentProduct[] => {
    const productsWithStaggeredFitment = products.filter(product => {
        const [groupNumber, priority] = getValues(product)
        return !!(groupNumber && priority)
    })
    const productsWithoutStaggeredFitment = products.filter(product => {
        const [groupNumber, priority] = getValues(product)
        return !(groupNumber && priority)
    })

    const sortedProductsWithStaggeredFitment = sortStaggeredFitment(productsWithStaggeredFitment, entryGroups)
    return [...sortedProductsWithStaggeredFitment, ...productsWithoutStaggeredFitment]
}

/**
 * Payload for the add item to cart.
 * @param {string} cartGUID - cart guid - anonymous/ authenticated user.
 * @param {CartOrderEntries} services - the add ons which needs to be added to the cart.
 * @param {CartOrderEntries} productData
 * @param {boolean} isSFL
 * @param {string} sflGUID
 * @return {AddProductToCartGuestRequestDTO}
 *
 */
// TODO: this code should be part of service
export const cartDataRequestPayload = (
    cartGUID: string,
    services: CartOrderEntries[] | ServiceDTO[],
    productData?: CartOrderEntries,
    isSFL = false,
    sflGUID?: string,
): AddProductToCartGuestRequestDTO => {
    const entryNumberArray: number[] = []
    entryNumberArray.push(Number(productData?.entryNumber))

    const vehicleInformation = productData?.vehicleInformation
        ? createVehiclePayload(productData.vehicleInformation)
        : undefined
    const cartData: AddProductToCartGuestRequestDTO = {
        guid: cartGUID,
        saveForLaterId: sflGUID,
        entries: {
            orderEntries: [],
        },
        entryNumber: entryNumberArray,
    }

    if (isSFL) {
        const productEntry: OrderEntryDTO = {
            ...constructOrderEntries(productData),
            ...{ vehicleInformation: vehicleInformation },
        }
        cartData.entries.orderEntries.push(productEntry)
    }
    Array.isArray(services) &&
        services.forEach(service => {
            const entry: OrderEntryDTO = {
                ...constructOrderEntries(service, productData),
                cartEntryReference: productData?.code || '',
            }
            cartData.entries.orderEntries.push(entry)
        })

    return cartData
}

/**
 * Convert values to Order Entries
 * @param {CartOrderEntries} productData
 * @param {CartOrderEntries} parentProductData
 * @return {OrderEntryDTO}
 *
 */
const constructOrderEntries = (
    productData: CartOrderEntries | ServiceDTO,
    parentProductData?: CartOrderEntries,
): OrderEntryDTO => {
    const deliveryModeCode: string =
        productData?.entryType === eEntryType.SERVICE_STANDALONE
            ? FulfillmentMethods.BOPIS
            : productData?.fulfillment?.deliveryMode || parentProductData?.fulfillment.deliveryMode
    return {
        deliveryMode: {
            code: deliveryModeCode,
        },
        quantity: Number(productData?.quantity || parentProductData?.quantity),
        deliveryPointOfService: {
            name: appCacheService.preferredStoreId.get(),
        },
        product: {
            code: productData?.code || productData?.sku,
        },
    }
}

/**
 * This function is used to check the sellable property and based on that return error response
 * @param {boolean} isSelleble
 * @param {string} error
 * @param {string} type
 * @return {ItemInlineErrorType}
 */
export const isProductSellable = (
    isSelleble: boolean,
    error: string,
    type: string,
): ItemInlineErrorType | undefined => {
    if (!isSelleble) {
        /**
         * Frame the error response.
         * showInlineError - true, if the item has error.
         * errorMessage - toast error message.
         * grayOutImage - if true, will apply opacity of 0.4 on the image.
         * optionsToBeDisplayed - items to be displayed when the item as errors.
         * fulfillmentDeliverError -if deliver mode  not available during checkout but initially was available
         * hardStopError - if true, user should not be allowed to proceed to checkout.
         */
        return CartErrorService.constructErrorResponse(true, error, true, [type], '', true)
    }
}

/**
 * function to return array of entry number for removing multiple items
 * @param {CartOrderEntries} productData
 * @param {EntryGroups[]}cartEntryGroups
 * @param {CartOrderEntries[]}bopis
 * @param {CartOrderEntries[]}cartSelectedServiceList
 * @param {unknown[]}entryNumber
 * @param {boolean} removeFromCart
 */
export const removeAllItemsEntryNumber = (
    productData: CartOrderEntries,
    cartEntryGroups: EntryGroups[],
    bopis: CartOrderEntries[],
    cartSelectedServiceList: CartOrderEntries[],
    entryNumber: unknown[],
    removeFromCart = true,
): void => {
    const packageId = productData.packageId
    const staggeredId = productData.staggeredGroup?.split('-')[0]
    if (packageId) {
        entryNumber.push(
            ...bopis
                ?.filter(item => item.packageId === packageId)
                .map(item => (removeFromCart ? item.entryNumber : item.code)),
        )
    } else if (staggeredId) {
        const entryGroups = cartEntryGroups
            .find(item => item.groupNumber === Number(staggeredId))
            ?.entryGroup.map(item => item.label.split('.')[MagicNumber.ONE])
        entryGroups?.forEach(searchVal => {
            entryNumber.push(
                removeFromCart
                    ? bopis.find(item => item.code === searchVal)?.entryNumber
                    : bopis.find(item => item.code === searchVal)?.code,
            )
        })
    } else {
        entryNumber.push(removeFromCart ? productData.entryNumber : productData.code)
        cartSelectedServiceList &&
            entryNumber.push(
                ...cartSelectedServiceList
                    .filter(
                        item =>
                            item.cartEntryReference === productData.code &&
                            item.fulfillment?.deliveryMode?.toUpperCase() ===
                                productData.fulfillment?.deliveryMode?.toUpperCase(),
                    )
                    .map(item => (removeFromCart ? item.entryNumber : item.code)),
            )
    }
}

/**
 * function to check if current page is of cart page type
 * @return {boolean}
 */
export const isCartPage = (): boolean => {
    return getPageType() === pageTypes.cart
}

/**
 * function to get orderEntries of singleProduct

 * @param {MiniCartData | FilteredCartData} cartItems
 * @return {string[]|undefined}
 */
export const fetchSingleProductSku = (cartItems: FilteredCartData | MiniCartData): string | undefined => {
    return isShoppingCartPage()
        ? (cartItems as FilteredCartData)?.cart?.orderEntries[0]?.code
        : (cartItems as MiniCartData)?.orderEntries[0]?.code
}

/**
 * function to navigate to any link selected
 * @param {string} url url of the product
 * @param {string} anchor when url has an anchor
 */
export const navigateToLink = (url: string, anchor?: string): void => {
    const anchorTag = `#${String(anchor)}`
    const isAnchorInURL = url.includes(anchorTag)
    const showAnchorTag = anchor || !isAnchorInURL ? anchorTag : ''
    const modifiedURl = `${url}${showAnchorTag}`
    window.location.href = modifiedURl
}

/**
 * function to scroll to top
 */
export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    })
}

/**
 * function to extract cart items SKUs
 * @param {FilteredCartData} cartItems
 * @return {string[]|undefined}
 */
export const extractCartSkus = (cartItems: FilteredCartData): string[] | undefined => {
    return cartItems?.cart?.orderEntries?.map((entry: CartOrderEntries) => entry.code)
}

export const isServiceAddonAdded = (cartItemsData: FilteredCartData): boolean => {
    const isAnyAddOnService = cartItemsData.selectedServiceList?.filter(
        itemData => itemData.entryType === eEntryType.SERVICE_ADDON,
    )
    return isArrayNotEmpty(isAnyAddOnService) || isArrayNotEmpty(cartItemsData.services)
}

/**
 * function to Create VehicleInformation Payload
 * @param {VehicleInformation} vehicle
 * @return {VehicleInformation}
 */
export const createVehiclePayload = (vehicle: VehicleInformation): VehicleInformation => {
    const engineConfigId = vehicle?.engineConfigId?.toString()
    let vehiclePayload = {
        baseVehicleId: vehicle?.baseVehicleId,
        type: vehicle?.type,
        make: vehicle?.make,
        model: vehicle?.model,
        body: vehicle?.body || undefined,
        options: vehicle?.options || undefined,
        year: vehicle?.year,
    }
    if (engineConfigId) {
        vehiclePayload = { ...vehiclePayload, engineConfigId }
    }
    return vehiclePayload
}

/**
 * Returns Wishlist Payload without vehicle information
 * @param {string[]} productCodeArray
 * @return {AddToWishListPayload}
 */
export const wishlistEntriesWithoutVehicleInfo = (productCodeArray: string[]): AddToWishListPayload => {
    return {
        wishListEntries: productCodeArray.map(code => {
            return {
                product: {
                    code,
                },
            }
        }),
    }
}

/**
 * Returns AddToWishListPayload
 * @param {string[]} productCodeArray
 * @param {VehicleInformation | null} vehicleContext
 * @param {boolean} enableVehicleInformationOnWishlist
 * @return {AddToWishListPayload}
 */
export const returnAddToWishListPayload = (
    productCodeArray: string[],
    vehicleContext: VehicleInformation | null,
    enableVehicleInformationOnWishlist: boolean,
): AddToWishListPayload => {
    if (enableVehicleInformationOnWishlist) {
        if (vehicleContext) {
            const vehicleInformation: VehicleInformation = {
                baseVehicleId: vehicleContext.baseVehicleId,
                body: vehicleContext.body,
                model: vehicleContext.model,
                options: vehicleContext.options,
                type: vehicleContext.type,
                year: vehicleContext.year,
                make: vehicleContext.make,
            }
            if (vehicleContext.engineConfigId) {
                vehicleInformation.engineConfigId = vehicleContext.engineConfigId.toString()
            }
            if (vehicleContext.boltPattern) {
                vehicleInformation.boltPattern = vehicleContext.boltPattern
            }
            return {
                wishListEntries: productCodeArray.map(code => {
                    return {
                        product: {
                            code,
                        },
                        vehicleInformation,
                    }
                }),
            }
        } else {
            return wishlistEntriesWithoutVehicleInfo(productCodeArray)
        }
    } else {
        return wishlistEntriesWithoutVehicleInfo(productCodeArray)
    }
}

/**
 * Function to get extra hardware addons
 * @param {HardwareList[]} addonsList
 * @return {HardwareList[]}
 */
export const getExtraHardwareAddonsSku = (addonsList: HardwareList[]): HardwareList[] => {
    return extraHardwareService.getProductSkus(addonsList)
}

/**
 * Function to get balloon addons addons
 * @param {ProductSkusData} productSkuData
 * @param {HardwareList} addons
 * @return {ProductSku | undefined}
 */
export const getProductSkuData = (productSkuData: ProductSkusData, addons: HardwareList): ProductSku | undefined => {
    return productSkuData.skus.find((skuData: ProductSku) => skuData.code === addons.sku)
}

/**
 * Function to get balloon addons addons
 * @param {HardwareList[]} addonsList
 * @return {HardwareList[]}
 */
export const getBalloonAddonsSku = (addonsList: HardwareList[]): HardwareList[] => {
    return balloonAddonsService.getProductSkus(addonsList)
}

/**
 * Function to get balloon addons sku list
 * @param {HardwareList[]} addonsList
 * @param {boolean} isHardwareAddon
 * @return {Record<string, string>[]}
 */
export const getAddonsSkuList = (addonsList: HardwareList[], isHardwareAddon: boolean): Record<string, string>[] => {
    const filteredAddonsList = isHardwareAddon ? getExtraHardwareAddonsSku(addonsList) : getBalloonAddonsSku(addonsList)
    return filteredAddonsList.map((addons: HardwareList) => {
        return { code: addons.sku }
    })
}

export const hasCartItemsBecomeUnavailableForSTH = (
    previousOrderEntries: CartOrderEntries[] = [],
    currentCartItems: CartOrderEntries[] = [],
): boolean => {
    return currentCartItems?.some(currentItem => {
        const previousItem = previousOrderEntries?.find(itemData => itemData.code === currentItem.code)
        const isPreviousItemEligibleForSTH = previousItem?.fulfillment?.isEligibleToShipHome
        return isPreviousItemEligibleForSTH && !currentItem?.fulfillment?.isEligibleToShipHome
    })
}

export const hasCartItemsBecomeUnavailableForBOPIS = (
    previousOrderEntries: CartOrderEntries[] = [],
    currentCartItems: CartOrderEntries[] = [],
): boolean => {
    return currentCartItems?.some(currentItem => {
        const previousItem = previousOrderEntries?.find(itemData => itemData.code === currentItem.code)
        const isPreviousItemEligibleForBOPIS = previousItem?.fulfillment?.isEligibleToPickupFromStore
        return isPreviousItemEligibleForBOPIS && !currentItem?.fulfillment?.isEligibleToPickupFromStore
    })
}

export const hasCartItemsBecomeUnavailableByDCQty = (
    previousOrderEntries: CartOrderEntries[] = [],
    currentCartItems: CartOrderEntries[] = [],
): boolean => {
    return currentCartItems?.some(currentItem => {
        const previousItem = previousOrderEntries?.find(itemData => itemData.code === currentItem.code)
        const previousDCQuantity = previousItem?.fulfillment?.stockItemAvailability?.dcQuantity
        return previousDCQuantity && !currentItem?.fulfillment?.stockItemAvailability?.dcQuantity
    })
}

export const hasCartItemsBecomeUnavailableByStoreQty = (
    previousOrderEntries: CartOrderEntries[] = [],
    currentCartItems: CartOrderEntries[] = [],
): boolean => {
    return currentCartItems?.some(currentItem => {
        const previousItem = previousOrderEntries?.find(itemData => itemData.code === currentItem.code)
        const previousStoreQuantity = previousItem?.fulfillment?.stockItemAvailability?.storeQuantity
        return previousStoreQuantity && !currentItem?.fulfillment?.stockItemAvailability?.storeQuantity
    })
}

export const hasCartItemsBecomeUnavailable = (
    previousOrderEntries: CartOrderEntries[] = [],
    currentCartItems: CartOrderEntries[] = [],
): boolean => {
    return (
        hasCartItemsBecomeUnavailableForSTH(previousOrderEntries, currentCartItems) ||
        hasCartItemsBecomeUnavailableForBOPIS(previousOrderEntries, currentCartItems) ||
        hasCartItemsBecomeUnavailableByDCQty(previousOrderEntries, currentCartItems) ||
        hasCartItemsBecomeUnavailableByStoreQty(previousOrderEntries, currentCartItems)
    )
}

/**
 * function for checking ship to home error that depends on delivery mode
 * @return {string | undefined} group number
 * @param {SelectedDeliveryMode} selectedDeliveryMode
 * @param {FulfillmentMethods} itemType
 * @param {CartItemFulFillmentType} fulfillment
 */
export const checkShipToHomeError = (
    selectedDeliveryMode: SelectedDeliveryMode,
    itemType: FulfillmentMethods,
    fulfillment: CartItemFulFillmentType,
): boolean => {
    return Boolean(
        selectedDeliveryMode?.isExpress
            ? areAllParamsValid(
                  itemType === FulfillmentMethods.STH,
                  !fulfillment?.isEligibleToShipHome,
                  !fulfillment?.isEligibleForExpressDelivery,
              )
            : areAllParamsValid(itemType === FulfillmentMethods.STH, !fulfillment?.isEligibleToShipHome),
    )
}

/**
 * Function to check that page is returning from login redirect
 * @return {boolean}
 */
export const addToCartisRedirected = (): boolean => {
    const redirectURLParams = new URLSearchParams(window.location.search)
    const moveToSFL = redirectURLParams.get(saveForLaterParam)
    if (moveToSFL === 'true' && appCacheService.saveForLater.get()) {
        redirectURLParams.delete(saveForLaterParam) // Delete the moveToSFL parameter.
        window.history.replaceState(null, 'null', window?.ODP?.globalLinks?.cartPageLink)
        return true
    } else {
        return false
    }
}
