import { Dispatch } from 'redux'

import { VEHICLE_TIRESIZE_FORM_RENDERED } from '../types/automotiveBuyBox/automotiveBuyBoxSelector.actionTypes.constants'
import { vehicleTireSizeFormRenderedAction } from '../actionCreators/automotiveBuyBox.actionCreators'

export const vehicleTireSizeFormRendered =
    (requestPayload: boolean) =>
    (dispatch: Dispatch<{ type: typeof VEHICLE_TIRESIZE_FORM_RENDERED; payload: boolean }>): void => {
        dispatch(vehicleTireSizeFormRenderedAction(requestPayload))
    }
