import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
    SearchBar,
    SearchAnalytics,
    searchConstants,
    promiseQueue,
    SearchBarProps,
    Suggestion,
    CacheData,
} from '@nl/lib'
import { getEnvironment } from '../../../environments'
import { commonContentSelector } from '../../../redux/selectors/commonContent.selectors'
import appCacheService from '../../../utils/appCacheService'
import { MagicNumber } from '../../../analytics/analytics.type'
import { BREAKPOINTS } from '../../../config'
import { RootState } from '../../../redux/reducers'
import { fetchSuggestions, setSuggestions, setSuggestionsErr } from '../../../redux/actions'
import { searchAnalytics } from '../../../analytics/components/searchAnalytics'
import { selectedPreferredStoreIdSelector } from '../../../redux/selectors/storeDetails.selectors'

const SearchBarWrapper: React.FC<SearchBarProps> = props => {
    const {
        maxNumCats,
        // TODO: limit number of products shown to 3 on mobile
        // mostPopularProductsCountDesktop,
        // mostPopularProductsCountMobile,
        searchHistoryCountDesktop,
        searchHistoryCountMobile,
        delay,
    } = props

    const { suggest } = useSelector((state: RootState) => state.suggest)

    const MAX_HISTORY_LENGTH_DESKTOP = searchHistoryCountDesktop
    const MAX_HISTORY_LENGTH_MOBILE = searchHistoryCountMobile

    const environment = getEnvironment()
    const urlParams = new URLSearchParams(window.location.search.replace(/;/g, '&'))
    const queryString = urlParams.get('q')
    const searchingString = !queryString || queryString === 'undefined' ? '' : queryString
    const { commonContentAvailable } = useSelector(commonContentSelector)
    const disableStoreNumberInStandardSearchCall =
        !!commonContentAvailable?.featureFlag?.disableStoreNumberInStandardSearchCall
    const selectedPreferredStoreId = useSelector(selectedPreferredStoreIdSelector)

    const { mobileMaxWidth } = BREAKPOINTS
    const isMobile = window.innerWidth <= mobileMaxWidth

    const dispatch = useDispatch()

    /**
     * Function that prepares and controls caching a data
     * @param data
     * @return modified data
     */
    const setCache = useCallback(
        (data: CacheData) => {
            const cacheControl = data.headers['cache-control'] as string
            if (!data.creationTime && data.data.topHit[0]?.label && cacheControl) {
                const searchTerm = data.data.topHit[0]?.label
                const RGres = cacheControl.match(/(max-age=)(\d+)/g)
                const RGTTL = RGres?.length && RGres?.[0]?.split('=')?.[MagicNumber.ONE]
                const timeToLive = RGTTL && !isNaN(Number(RGTTL)) && Number(RGTTL) * MagicNumber.ONETHOUSAND
                timeToLive &&
                    appCacheService.suggestionCacheService.set(
                        `${searchTerm}_${selectedPreferredStoreId}_${environment.language}`,
                        { ...data, creationTime: new Date().toISOString(), timeToLive },
                    )
            }

            delete data.creationTime
            delete data.timeToLive

            return data as unknown
        },
        [environment.language, selectedPreferredStoreId],
    )

    /**
     * invoke suggest api for the searched term and return suggestions and products
     * @param {string} searchTerm - searched term
     */
    const getSuggestions = useCallback(
        (searchTerm: string) => {
            return fetchSuggestions(searchTerm, selectedPreferredStoreId)
        },
        [selectedPreferredStoreId],
    )

    /**
     * invoke suggest api for the searched term and return suggestions and products
     * without providing store id
     * @param {string} searchTerm - searched term
     */
    const getSuggestionsWithoutStoreId = useCallback((searchTerm: string) => {
        return fetchSuggestions(searchTerm)
    }, [])

    /**
     * Update store with successfully fetched suggestions
     * @param {Object} data
     */
    const handleSuggestionGet = useCallback(
        data => {
            data = setCache(data)

            dispatch(setSuggestions(data))
        },
        [dispatch, setCache],
    )

    /**
     * update store with unsuccessful fetch
     * @param {Object} error
     */
    const handleSuggestionErr = useCallback(error => dispatch(setSuggestionsErr(error)), [dispatch])

    /**
     * create a promise queue
     * @param {string} searchTerm - search term
     */
    const pushQueue = useCallback(
        (searchTerm: string) => {
            const cachedData = appCacheService.suggestionCacheService.get(
                `${searchTerm || '*'}_${selectedPreferredStoreId}_${environment.language}`,
            )
            const isCacheExpired =
                !cachedData?.timeToLive ||
                new Date().getTime() - new Date(cachedData?.creationTime || 0).getTime() >= cachedData.timeToLive

            if (cachedData && !isCacheExpired) {
                handleSuggestionGet(cachedData)
            } else {
                if (cachedData) {
                    appCacheService.suggestionCacheService.deleteByKey(
                        `${searchTerm || '*'}_${selectedPreferredStoreId}_${environment.language}`,
                    )
                }
                promiseQueue()(
                    disableStoreNumberInStandardSearchCall
                        ? getSuggestionsWithoutStoreId(searchTerm)
                        : getSuggestions(searchTerm),
                    handleSuggestionGet,
                    handleSuggestionErr,
                )
            }
        },
        [
            handleSuggestionErr,
            handleSuggestionGet,
            getSuggestions,
            getSuggestionsWithoutStoreId,
            environment,
            selectedPreferredStoreId,
            disableStoreNumberInStandardSearchCall,
        ],
    )

    /**
     * add searched item into search history at the beginning of the list. if max number of history is reached, the least recent item is removed.
     * @param {string} searchValue - the most recently searched term
     * @param {Suggestion} data - additional data to save
     * @return {Suggestion[]}
     */
    const addSearchHistory = (searchValue: string, data?: Suggestion) => {
        const tempHistoryList: { label: string }[] = getSearchHistoryList()

        if (searchValue) {
            const newHistory = { label: searchValue, type: searchConstants.SUGGESTION_TYPE_HISTORY, data }
            const maxHistoryNumber = isMobile ? MAX_HISTORY_LENGTH_MOBILE : MAX_HISTORY_LENGTH_DESKTOP

            const historyIndex = tempHistoryList.findIndex(h => h.label === searchValue)
            if (historyIndex !== MagicNumber.MINUS_ONE) {
                tempHistoryList.splice(historyIndex, MagicNumber.ONE)
            }

            if (tempHistoryList.length === maxHistoryNumber) {
                tempHistoryList.splice(MAX_HISTORY_LENGTH_DESKTOP - MagicNumber.ONE, MagicNumber.ONE)
            }

            tempHistoryList.unshift(newHistory)

            // return tempHistoryList;
            appCacheService.searchedList.set(JSON.stringify(tempHistoryList))
        }

        return tempHistoryList
    }

    /**
     * return current search history list
     * @return {SearchHistory[]}
     */
    const getSearchHistoryList = () => {
        const searchHistoryListString = appCacheService.searchedList.get()

        if (searchHistoryListString) {
            try {
                const historyArr = JSON.parse(searchHistoryListString) as { label: string; type: string }[]
                return historyArr.filter(it => it?.label)
            } catch (e) {
                return []
            }
        } else {
            return []
        }
    }

    // clear search history list
    const clearSearchHistoryList = () => {
        appCacheService.searchedList.set('')
    }

    /**
     * truncate suggestion list to maxNumCats
     * @param { Suggestion [] } suggestionList
     * @return { Suggestion [] }
     */
    const truncateSuggestionList = (suggestionList: Suggestion[]) => {
        if (suggestionList.length > maxNumCats) {
            suggestionList.splice(maxNumCats - MagicNumber.ONE, suggestionList.length - maxNumCats)
        }

        return suggestionList as unknown[]
    }

    /**
     * push analytics data. this function needs to be passed in from app module so that lib module can invoke it
     * @param { string } searchValue
     * @param { string } searchType
     * @param { string } suggestionType
     * @param { Suggestion } selectedSuggestion
     * @param { number } selectedProductIndex
     * @param { number } suggestionProductImpressions
     * @param { string } suggestionEngagementType
     * @param { boolean } isTypeaheadUsed
     */
    // eslint-disable-next-line complexity
    const pushAnalyticsData = (
        searchValue: string,
        searchType: string,
        suggestionType: string,
        selectedSuggestion: Suggestion,
        selectedProductIndex: number,
        suggestionProductImpressions: number,
        suggestionEngagementType: string,
        isTypeaheadUsed: boolean,
    ) => {
        const searchAnalyticsData = {} as SearchAnalytics
        let suggestionTypeKey: string

        searchAnalyticsData.event = searchConstants.ANALYTICS_EVENT
        searchAnalyticsData.userInput = searchValue
        searchAnalyticsData.engine = searchConstants.BLOOMREACH
        searchAnalyticsData.type = searchType
        searchAnalyticsData.suggestionProductImpressions = suggestionProductImpressions
        searchAnalyticsData.suggestionEngagementType = suggestionEngagementType

        if (suggestionType === searchConstants.SUGGESTION_TYPE_KEYWORD) {
            // keyword is selected
            suggestionTypeKey = searchConstants.SUGGESTION_TYPE_UJ_KEYWORD
            searchAnalyticsData.suggestionValue = selectedSuggestion.label
            searchAnalyticsData.redirect = selectedSuggestion.isRedirect
            searchAnalyticsData.suggestionType = searchConstants.SUGGESTION_TYPE_KEYWORD
        } else if (suggestionType === searchConstants.SUGGESTION_TYPE_CATEGORY) {
            // category is selected
            suggestionTypeKey = searchConstants.SUGGESTION_TYPE_UJ_CATEGORY
            searchAnalyticsData.suggestionValue = selectedSuggestion.label
            searchAnalyticsData.redirect = selectedSuggestion.isRedirect
            searchAnalyticsData.suggestionType = searchConstants.SUGGESTION_TYPE_CATEGORY
        } else if (suggestionType === searchConstants.SUGGESTION_TYPE_HISTORY) {
            // history is clicked
            suggestionTypeKey = searchConstants.SUGGESTION_TYPE_UJ_HISTORY
            searchAnalyticsData.suggestionValue = selectedSuggestion.label
            searchAnalyticsData.suggestionType = searchConstants.SUGGESTION_TYPE_HISTORY
        }

        searchAnalyticsData.userJourney = `${searchValue ? `userInput=${searchValue}` : ''}${
            searchValue && selectedSuggestion ? ' | ' : ''
        }${selectedSuggestion && selectedSuggestion.label ? `${suggestionTypeKey}=${selectedSuggestion.label}` : ''}`

        appCacheService.completeSearchEventGA.set({
            userInput: searchValue,
            redirect: `${String(selectedSuggestion?.isRedirect || false)}`,
            searchType: searchType || null,
            suggestionType: searchAnalyticsData?.suggestionType || null,
            suggestionValue: selectedSuggestion?.label || null,
            isTypeaheadUsed: isTypeaheadUsed ? `${String(isTypeaheadUsed)}` : null,
        })
        searchAnalytics(searchAnalyticsData)
    }

    return (
        <SearchBar
            isCodeLookup={suggest.isCodeLookup}
            suggestedKeywordList={truncateSuggestionList(suggest.suggestions)}
            suggestedCategoryList={truncateSuggestionList(suggest.categories)}
            getSearchHistoryList={getSearchHistoryList}
            addSearchHistory={addSearchHistory}
            clearSearchHistoryList={clearSearchHistoryList}
            getSuggestions={disableStoreNumberInStandardSearchCall ? getSuggestionsWithoutStoreId : getSuggestions}
            handleSuggestionGet={handleSuggestionGet}
            handleSuggestionErr={handleSuggestionErr}
            pushAnalyticsData={pushAnalyticsData}
            delay={delay}
            searchingString={searchingString}
            pushQueue={pushQueue}
            topHit={suggest.topHit}
            {...props}
        />
    )
}

SearchBarWrapper.propTypes = {
    searchBoxPlaceholder: PropTypes.string.isRequired,
    searchIconSize: PropTypes.string,
    path: PropTypes.string,
    closeIconSize: PropTypes.string,
    showCloseButton: PropTypes.bool,
    closeModal: PropTypes.func,
    a11ySearchIconLabel: PropTypes.string,
    allyCloseIconLabel: PropTypes.string,
    a11yUpdateQueryIconLabel: PropTypes.string,
    id: PropTypes.string,
    minChars: PropTypes.number.isRequired,
    delay: PropTypes.string,
    count: PropTypes.string,
    minCatLevel: PropTypes.string,
    maxNumCats: PropTypes.number,
    ctrPriceUrl: PropTypes.string,
    searchLabel: PropTypes.string.isRequired,
    categoriesLabel: PropTypes.string.isRequired,
    seeMoreResultsLabel: PropTypes.string,
    searchHistoryTitle: PropTypes.string.isRequired,
    searchHistoryCountDesktop: PropTypes.number.isRequired,
    searchHistoryCountMobile: PropTypes.number.isRequired,
    searchHistoryClearLabel: PropTypes.string,
    searchCallBack: PropTypes.func.isRequired,
    pushAnalyticsData: PropTypes.func.isRequired,
}

export default SearchBarWrapper
