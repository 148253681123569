import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { PREFIX } from '../../../config'
import { RootState } from '../../../redux/reducers'
import { ProgressBannerType } from './ProgressBanner.type'
import { checkDataLength } from '../../Accounts/Addresses/checkDataLength'
import { Button, Icon, extractKeyValues, isArrayNotEmpty } from '@nl/lib'
import { productWheelTypes, AutoPackageKeys } from '../../../globalConstants'
import sessionStorageService from '../../../utils/sessionStorageService'
import { MagicNumber } from '../../../analytics/analytics.type'
import { getEnvironment } from '../../../environments'
import { AutoPackages, TireWheelDetails, PackageLandingInitialState } from '../PackageLanding/PackageLanding.type'
import { ProductResponseData } from '../../../redux/models/product.interface'
import { isOnlyTireData, isOnlyWheelData, updatePackageFlowState } from './ProgressBanner.helper'
import { isStaggeredFitmentVehicle } from '../PackageLanding/PackageLanding.helper'
import { isTirePDP, isWheelOrTirePDP, isWheelPDP } from '../../Vehicles/Vehicle.helper'
import { plpURLRegExp } from '../../Breadcrumbs/Breadcrumb.constant'
import { unfinishedPackage } from '../PackageLanding/PackageLanding.constant'
import { commonContentSelector } from '../../../redux/selectors/commonContent.selectors'
import { vehicleListSelector } from '../../../redux/selectors/userProfile.selectors'
import { productDataSelector } from '../../../redux/selectors/product.selectors'
import { productWheelTypeSelector } from '../../../redux/selectors/categoryIdData.selectors'
import { productCardDataSelector } from '../../../redux/selectors/productData.selectors'
import { automotivePackageSelector } from '../../../redux/selectors/automotivePackage.selector'

/**
 * function to check If tire or wheel Page
 * @param {ProductResponseData} productData
 * @param {string} productWheelType
 * @return {boolean}
 */
const checkIfTireOrWheelPDPorPLP = (productData: ProductResponseData, productWheelType?: string): boolean => {
    return isWheelOrTirePDP(productData?.productWheelType) || isWheelOrTirePDP(productWheelType)
}

/**
 * Generate ProgressBanner Component
 * @param {props} props
 * @return {JSX.Element} returns ProgressBanner component
 */
const ProgressBanner: React.FC<ProgressBannerType> = ({ ...props }): JSX.Element => {
    const progressBanner = `${PREFIX}-progress-banner`
    const { autoPackages, isPackageFlow, currentPackageId } = useSelector(automotivePackageSelector)
    const productCardData = useSelector(productCardDataSelector)
    const config = getEnvironment()
    const productData = useSelector(productDataSelector)
    const productWheelType = useSelector(productWheelTypeSelector)
    const [lastAutoPackageItem, setLastAutoPackageItem] = useState({} as AutoPackages)
    const [currentSelectedContainer, setCurrentSelectedContainer] = useState('')
    const [nextSelectedContainer, setNextSelectedContainer] = useState('')
    const [selectedItem, setSelectedItem] = useState('')
    const [selectedContainer, setSelectedContainer] = useState('')
    const miniCartItemsData = useSelector((state: RootState) => state.cart.miniCartItemsData)
    const isChangeFlowInitiated = lastAutoPackageItem?.changeSelectedLink
    const tireData = lastAutoPackageItem?.vehicleInfo?.tireData
    const wheelData = lastAutoPackageItem?.vehicleInfo?.wheelData

    const { commonContentAvailable } = useSelector(commonContentSelector)
    const [
        [qtyAbbrLabel],
        [continueToCartLabel],
        [packagesTiresPath, packagesWheelsPath],
        [cartPageLink],
        [changeLabel],
    ] = useMemo(
        () =>
            extractKeyValues(commonContentAvailable, [
                {
                    product: ['qtyAbbrLabel'],
                },
                {
                    cart: ['continueToCartLabel'],
                },
                {
                    packages: ['packagesTiresPath', 'packagesWheelsPath'],
                },
                {
                    globalLinks: ['cartPageLink'],
                },
                {
                    general: ['changeLabel'],
                },
            ]) as unknown[][],
        [commonContentAvailable],
    ) as string[][]

    const list = useSelector(vehicleListSelector)
    const packageData = (
        checkDataLength(sessionStorageService.getItem(config.SESSION_STORAGE_KEYS.packageFlow))
            ? JSON.parse(sessionStorageService.getItem(config.SESSION_STORAGE_KEYS.packageFlow))
            : ''
    ) as PackageLandingInitialState

    const currentAutoPackage = autoPackages.find(item => item.packageId === currentPackageId)

    useEffect(() => {
        if (checkDataLength(autoPackages)) {
            if (currentPackageId) {
                setLastAutoPackageItem(currentAutoPackage)
            } else {
                setLastAutoPackageItem(autoPackages[autoPackages.length - MagicNumber.ONE])
            }
            lastAutoPackageItem?.currentSelectedContainer &&
                setCurrentSelectedContainer(lastAutoPackageItem?.currentSelectedContainer)
            lastAutoPackageItem?.nextSelectedContainer &&
                setNextSelectedContainer(lastAutoPackageItem?.nextSelectedContainer)
            setSelectedItem(currentSelectedContainer)
            setSelectedContainer(lastAutoPackageItem?.nextSelectedContainer || currentSelectedContainer)
        }
    }, [
        autoPackages,
        lastAutoPackageItem,
        currentSelectedContainer,
        nextSelectedContainer,
        selectedContainer,
        currentPackageId,
        currentAutoPackage,
    ])

    /**
     * function to check if tire or wheel PDP or PLP
     * @param {string} tireOrWheelType
     * @return {boolean}
     */
    const isTireOrWheelPage = useCallback(
        (tireWheelType: string, wheelTireType: string): boolean => {
            return (
                (isTirePDP(productData?.productWheelType || productWheelType) &&
                    currentAutoPackage?.currentSelectedContainer === tireWheelType) ||
                (isWheelPDP(productData?.productWheelType || productWheelType) &&
                    currentAutoPackage?.currentSelectedContainer === wheelTireType)
            )
        },
        [currentAutoPackage, productData, productWheelType],
    )

    /**
     * function to check if in packageflow and not navigate from PLP
     */
    const checkIfAutoPackage = useCallback(() => {
        return (
            !!isPackageFlow &&
            isArrayNotEmpty(autoPackages) &&
            counterRef.current === 0 &&
            !plpURLRegExp.exec(window.location.href) &&
            checkDataLength(miniCartItemsData)
        )
    }, [autoPackages, isPackageFlow, miniCartItemsData])

    const counterRef = useRef(0)

    useEffect(() => {
        const iscompletePackageClicked: boolean = window.location.href.includes(unfinishedPackage)
        if (checkIfAutoPackage() && !iscompletePackageClicked) {
            const currentPackage = packageData?.autoPackages?.find(
                (item: AutoPackages) => item.packageId === packageData?.currentPackageId,
            )
            if (isTireOrWheelPage(productWheelTypes.Tire, productWheelTypes.Wheel)) {
                updatePackageFlowState(
                    autoPackages,
                    currentPackage?.nextSelectedContainer || '',
                    packageData,
                    AutoPackageKeys.changeSelectedLink,
                    true,
                    true,
                    true,
                )
                counterRef.current = MagicNumber.ONE
            } else if (isTireOrWheelPage(productWheelTypes.Wheel, productWheelTypes.Tire)) {
                if (currentPackage?.currentSelectedContainer === productWheelTypes.Tire) {
                    updatePackageFlowState(
                        autoPackages,
                        currentPackage?.nextSelectedContainer || '',
                        packageData,
                        AutoPackageKeys.nextSelectedContainer,
                        productWheelTypes.Wheel,
                        true,
                        false,
                    )
                    setSelectedContainer(productWheelTypes.Wheel)
                } else {
                    updatePackageFlowState(
                        autoPackages,
                        currentPackage?.nextSelectedContainer || '',
                        packageData,
                        AutoPackageKeys.nextSelectedContainer,
                        productWheelTypes.Tire,
                        true,
                        false,
                    )
                    setSelectedContainer(productWheelTypes.Tire)
                }
                counterRef.current = MagicNumber.ONE
            }
        }
    }, [isPackageFlow, packageData, productData, autoPackages, productWheelType, isTireOrWheelPage, checkIfAutoPackage])

    /**
     * Choose a wheel/tire button click handle
     * @param {React.MouseEvent} event
     * @return {void}
     */
    const chooseCTAHandler = (event: React.MouseEvent): void => {
        if (selectedContainer === productWheelTypes.Tire) {
            updatePackageFlowState(
                autoPackages,
                nextSelectedContainer,
                packageData,
                AutoPackageKeys.nextSelectedContainer,
                productWheelTypes.Wheel,
                false,
                false,
            )
            setSelectedContainer(productWheelTypes.Wheel)
            window.location.href = encodeURI(packagesWheelsPath)
        } else {
            updatePackageFlowState(
                autoPackages,
                nextSelectedContainer,
                packageData,
                AutoPackageKeys.nextSelectedContainer,
                productWheelTypes.Tire,
                false,
                false,
            )
            setSelectedContainer(productWheelTypes.Tire)
            window.location.href = encodeURI(packagesTiresPath)
        }
        event.stopPropagation()
    }

    /**
     * Selected wheel/tire container click handler
     * @param {string} selectedContainerType
     * @return {void}
     */
    const selectedContainerHandler = (selectedContainerType: string): void => {
        if (!isOnlyTireData(tireData, wheelData) && !isOnlyWheelData(tireData, wheelData)) {
            if (selectedItem === productWheelTypes.Tire && selectedContainerType !== productWheelTypes.Tire) {
                updatePackageFlowState(
                    autoPackages,
                    nextSelectedContainer,
                    packageData,
                    AutoPackageKeys.currentSelectedContainer,
                    productWheelTypes.Wheel,
                    false,
                )
                setSelectedItem(productWheelTypes.Wheel)
                setSelectedContainer(productWheelTypes.Wheel)
                window.location.href = encodeURI(packagesWheelsPath)
            } else if (selectedContainerType !== productWheelTypes.Wheel && selectedItem === productWheelTypes.Wheel) {
                updatePackageFlowState(
                    autoPackages,
                    nextSelectedContainer,
                    packageData,
                    AutoPackageKeys.currentSelectedContainer,
                    productWheelTypes.Tire,
                    false,
                )
                setSelectedItem(productWheelTypes.Tire)
                setSelectedContainer(productWheelTypes.Tire)
                window.location.href = encodeURI(packagesTiresPath)
            }
        }
    }

    /**
     * Change wheel/tire hyperlink click handler
     * @param {React.MouseEvent} event
     * @return {void}
     */
    const changeSelectedTireOrWheel = (event: React.MouseEvent): void => {
        updatePackageFlowState(
            autoPackages,
            nextSelectedContainer,
            packageData,
            AutoPackageKeys.changeSelectedLink,
            true,
            false,
            true,
        )
        window.location.href = encodeURI(
            selectedItem === productWheelTypes.Tire ? packagesTiresPath : packagesWheelsPath,
        )
        event.stopPropagation()
    }

    /**
     * Selected label item on the basis of selectedItem and containerNumber
     * @param {number} containerNumber
     * @return {string}
     */
    const selectedLabel = (containerNumber: number): string => {
        if (
            isChangeFlowInitiated ||
            (currentAutoPackage?.cartFlyoutChangeSelectedLink &&
                currentAutoPackage?.nextSelectedContainer &&
                containerNumber === MagicNumber.TWO)
        ) {
            return props.currentlyEditingLabel
        } else {
            if (selectedItem === productWheelTypes.Tire) {
                return containerNumber === MagicNumber.ONE ? props.tireSelectedLabel : props.wheelSelectedLabel
            }
            return containerNumber === MagicNumber.ONE ? props.wheelSelectedLabel : props.tireSelectedLabel
        }
    }

    /**
     * Tire/Wheel container with tireWheelData
     * @param {TireWheelDetails | undefined} tireWheelData
     * @param {number} containerNumber
     * @return {JSX.Element}
     */
    const renderContainerWithData = (
        tireWheelData: TireWheelDetails | undefined,
        containerNumber: number,
    ): JSX.Element => {
        const desc = tireWheelData?.desc
        const price = tireWheelData?.price
        const brand = tireWheelData?.brand
        const qty = tireWheelData?.qty
        return (
            <>
                <div className={`${progressBanner}__desktop`}>
                    <div className={`${progressBanner}__selected`}>
                        <span className={`${progressBanner}__selected__label`}>{selectedLabel(containerNumber)}</span>
                        {!(checkDataLength(tireData) && checkDataLength(wheelData)) && !isChangeFlowInitiated && (
                            <Button
                                type="tertiary"
                                size="mini"
                                onClick={(event: React.MouseEvent) => changeSelectedTireOrWheel(event)}>
                                {changeLabel}
                            </Button>
                        )}
                    </div>
                    <div className={`${progressBanner}__details`}>
                        <span className={`${progressBanner}__details__brand`}>
                            <span className={`${progressBanner}__details__label`}>{brand}</span>
                            <span className={`${progressBanner}__details__desc`}>{desc}</span>
                        </span>
                        <span>
                            <div>
                                {qtyAbbrLabel} : {qty}
                            </div>
                            <div className={`${progressBanner}__details__price`}>${price}</div>
                        </span>
                    </div>
                </div>
                <div className={`${progressBanner}__mobile`}>
                    <div className={`${progressBanner}__mobile__selected`}>{selectedLabel(containerNumber)}</div>
                    <div>{brand}</div>
                    <div className={`${progressBanner}__mobile__desc`}>{desc}</div>
                    <div className={`${progressBanner}__mobile__price`}>${price}</div>
                    {!(checkDataLength(tireData) && checkDataLength(wheelData)) && !isChangeFlowInitiated && (
                        <Button
                            id="change-button-id"
                            type="tertiary"
                            size="mini"
                            onClick={(event: React.MouseEvent) => changeSelectedTireOrWheel(event)}>
                            {changeLabel}
                        </Button>
                    )}
                </div>
            </>
        )
    }

    /**
     * Check next selected value for Tire/Wheel container
     * @param {TireWheelDetails | undefined} tireWheelData
     * @param {number} containerNumber
     * @return {JSX.Element}
     */
    const checkNextSelectedValue = (tireWheelData?: TireWheelDetails | undefined): boolean => {
        return checkDataLength(tireWheelData) && !nextSelectedContainer && !isChangeFlowInitiated
    }

    /**
     * Choose a Tire/Wheel label for container
     * @param {number} containerNumber
     * @param {TireWheelDetails | undefined} tireWheelData
     * @return {string}
     */
    const chooseContainerLabel = (containerNumber: number, tireWheelData?: TireWheelDetails | undefined): string => {
        if (selectedItem === productWheelTypes.Tire) {
            if (containerNumber === MagicNumber.ONE) {
                return checkNextSelectedValue(tireWheelData) ? props.nowChooseTireLabel : props.chooseTireLabel
            }
            return checkNextSelectedValue(tireWheelData) ? props.nowChooseWheelLabel : props.chooseWheelLabel
        } else {
            if (containerNumber === MagicNumber.ONE) {
                return checkNextSelectedValue(tireWheelData) ? props.nowChooseWheelLabel : props.chooseWheelLabel
            }
            return checkNextSelectedValue(tireWheelData) ? props.nowChooseTireLabel : props.chooseTireLabel
        }
    }

    /**
     * Tire/Wheel container label
     * @param {number} containerNumber
     * @return {string}
     */
    const selectedContainerLabel = (containerNumber: number): string => {
        if (selectedItem === productWheelTypes.Tire) {
            if (containerNumber === MagicNumber.ONE) {
                return props.startWithTireLabel
            }
            return props.selectWheelToCompleteLabel
        } else {
            if (containerNumber === MagicNumber.ONE) {
                return props.startWithWheelLabel
            }
            return props.selectTireToCompleteLabel
        }
    }

    /**
     * Tire/Wheel container without tireWheelData
     * @param {number} containerNumber
     * @param {TireWheelDetails | undefined} tireWheelData
     * @return {JSX.Element}
     */
    const renderContainerWithoutData = (
        containerNumber: number,
        tireWheelData?: TireWheelDetails | undefined,
    ): JSX.Element => {
        return (
            <div
                className={`${progressBanner}__title ${progressBanner}__now${
                    checkDataLength(tireWheelData) ? '--container' : ''
                }`}>
                <div
                    className={`${progressBanner}__now__block${
                        checkDataLength(tireWheelData) && !nextSelectedContainer && !isChangeFlowInitiated
                            ? '--hide'
                            : '--show'
                    }`}>
                    <div className={`${progressBanner}__choose-label`}>
                        {chooseContainerLabel(containerNumber, tireWheelData)}
                    </div>
                    <div className={`${progressBanner}__select-label`}>{selectedContainerLabel(containerNumber)}</div>
                </div>
                {checkDataLength(tireWheelData) && !nextSelectedContainer && !isChangeFlowInitiated && (
                    <Button
                        type="primary"
                        size="mini"
                        id="choose-cta-handler"
                        onClick={(event: React.MouseEvent) => chooseCTAHandler(event)}>
                        {selectedContainer === productWheelTypes.Tire ? props.chooseWheelCTA : props.chooseTireCTA}
                    </Button>
                )}
            </div>
        )
    }

    /**
     * Tire/Wheel container CSS
     * @param {string} containerType
     * @return {string}
     */
    const selectContainerCSS = (containerType: string): string => {
        if (!(checkDataLength(tireData) && checkDataLength(wheelData))) {
            return selectedContainer === containerType ? '--selected' : ''
        }
        return ''
    }

    /**
     * Choose a Tire/wheel for mobile css
     * @return {string}
     */
    const chooseCTAMobileCSS = (): string => {
        return isOnlyTireData(tireData, wheelData) || isOnlyWheelData(tireData, wheelData) ? '--display' : ''
    }

    /**
     * function to get class for mobile resolution
     * @return {string}
     */
    const getClassNameMobileForWheelContainer = (): string => {
        return `${progressBanner}__icon${
            isOnlyTireData(tireData, wheelData) && !nextSelectedContainer && !isChangeFlowInitiated ? '--display' : ''
        }`
    }

    /**
     * Wheel Container On Page Load
     * @return {JSX.Element}
     */
    const renderWheelContainer = (): JSX.Element => {
        return (
            <div
                className={`${progressBanner}__container ${progressBanner}__container${
                    selectedItem === productWheelTypes.Wheel ? '--first' : '--second'
                } ${progressBanner}__container${selectContainerCSS(productWheelTypes.Wheel)}
                ${progressBanner}__container${chooseCTAMobileCSS()}
                `}
                onClick={() => {
                    selectedContainerHandler(productWheelTypes.Wheel)
                }}
                onKeyPress={() => false}
                tabIndex={0}
                role="button">
                <div className={`${progressBanner}__icon ${getClassNameMobileForWheelContainer()}`}>
                    {checkDataLength(wheelData) ? (
                        <img
                            alt={wheelData.imageAltText}
                            src={wheelData.imageUrl}
                            className={`${progressBanner}__icon__image`}
                        />
                    ) : (
                        <Icon size="lg" type="ct-circle-wheel" />
                    )}
                </div>
                {!checkDataLength(wheelData) &&
                    (selectedItem === productWheelTypes.Wheel
                        ? renderContainerWithoutData(MagicNumber.ONE, tireData)
                        : renderContainerWithoutData(MagicNumber.TWO, tireData))}
                {checkDataLength(wheelData) &&
                    (selectedItem === productWheelTypes.Wheel
                        ? renderContainerWithData(wheelData, MagicNumber.ONE)
                        : renderContainerWithData(wheelData, MagicNumber.TWO))}
            </div>
        )
    }

    /**
     * function to get class for mobile resolution
     * @return {string}
     */
    const getClassNameMobile = (): string => {
        return `${progressBanner}__icon${
            isOnlyWheelData(tireData, wheelData) && !nextSelectedContainer && !isChangeFlowInitiated ? '--display' : ''
        }`
    }

    /**
     * Tire Container On Page Load
     * @return {JSX.Element}
     */
    const renderTireContainer = (): JSX.Element => {
        return (
            <div
                className={`${progressBanner}__container ${progressBanner}__container${
                    selectedItem === productWheelTypes.Tire ? '--first' : '--second'
                } ${progressBanner}__container${selectContainerCSS(productWheelTypes.Tire)}
                ${progressBanner}__container${chooseCTAMobileCSS()}`}
                onClick={() => {
                    selectedContainerHandler(productWheelTypes.Tire)
                }}
                onKeyPress={() => false}
                tabIndex={0}
                role="button">
                <div className={getClassNameMobile()}>
                    {checkDataLength(tireData) ? (
                        <img
                            alt={tireData.imageAltText}
                            src={tireData.imageUrl}
                            className={`${progressBanner}__icon__image`}
                        />
                    ) : (
                        <Icon size="lg" type="ct-tire-circle" />
                    )}
                </div>
                {!checkDataLength(tireData) &&
                    (selectedItem === productWheelTypes.Tire
                        ? renderContainerWithoutData(MagicNumber.ONE, wheelData)
                        : renderContainerWithoutData(MagicNumber.TWO, wheelData))}
                {checkDataLength(tireData) &&
                    (selectedItem === productWheelTypes.Tire
                        ? renderContainerWithData(tireData, MagicNumber.ONE)
                        : renderContainerWithData(tireData, MagicNumber.TWO))}
            </div>
        )
    }

    /**
     * When both tire and wheel data , continue to cart will get redirected to shopping cart url
     */
    const continueToCartCTAHandler = (): void => {
        sessionStorageService.removeItem(getEnvironment().SESSION_STORAGE_KEYS.packageFlow)
        window.location.href = encodeURI(cartPageLink)
    }

    return (
        <>
            {checkIfTireOrWheelPDPorPLP(productData, productWheelType) &&
                isPackageFlow &&
                checkDataLength(list) &&
                !isStaggeredFitmentVehicle(productCardData) && (
                    <div className={`${progressBanner} ${PREFIX}-full-width-container`}>
                        <div className={`${progressBanner}__block`}>
                            {renderTireContainer()}
                            {renderWheelContainer()}
                        </div>
                        {checkDataLength(tireData) &&
                            checkDataLength(wheelData) &&
                            !currentAutoPackage?.cartFlyoutChangeSelectedLink && (
                                <Button id="tire-wheel" type="secondary" size="mini" onClick={continueToCartCTAHandler}>
                                    {continueToCartLabel}
                                </Button>
                            )}
                    </div>
                )}
        </>
    )
}

ProgressBanner.propTypes = {
    chooseTireLabel: PropTypes.string.isRequired,
    nowChooseTireLabel: PropTypes.string.isRequired,
    startWithTireLabel: PropTypes.string.isRequired,
    selectTireToCompleteLabel: PropTypes.string.isRequired,
    tireSelectedLabel: PropTypes.string.isRequired,
    chooseTireCTA: PropTypes.string.isRequired,
    chooseWheelLabel: PropTypes.string.isRequired,
    nowChooseWheelLabel: PropTypes.string.isRequired,
    startWithWheelLabel: PropTypes.string.isRequired,
    selectWheelToCompleteLabel: PropTypes.string.isRequired,
    wheelSelectedLabel: PropTypes.string.isRequired,
    chooseWheelCTA: PropTypes.string.isRequired,
    currentlyEditingLabel: PropTypes.string.isRequired,
}

export default ProgressBanner
