import { Price, PriceMessage } from '../Price/Price.types'
import { Specification } from '../ProductReusableCard/product.types'
import { ProductSku } from '../VariantsWrapper/VariantsWrapper.type'
import { minimumVariantsLength, setOfFourCount, setOfTwoCount } from './VehicleProduct.constant'
import { isArrayNotEmpty } from '../../utils/isArrayNotEmpty'
import { magicNumber } from '../../utils/magicNumber'
import { RebateData } from '../Rebate/Rebate.type'
import { combineBadges } from '../../utils/badgeUtil'
import { filterSpecification } from '../ProductCartItem/ProductCartItem.helper'

/**
 * function return specification list with label and its value
 * @param {Specification[]} specifications
 * @return {string}
 */
export const getSpecifications = (specifications: Specification[]): string => {
    let listSpecifications = ''
    if (specifications?.length) {
        specifications.forEach(specification => {
            listSpecifications += `<span><b>${specification?.label}</b>: ${specification?.value}</span>`
        })
    }

    return listSpecifications
}

/**
 * function to check minPrice or value
 * @param {Price} priceValue
 * @param {number} quantity
 * @return {number}
 */
const checkMinPriceAndValue = (priceValue: Price, quantity?: number): number | null => {
    let updatePriceStructure: number | null = null
    const initialPriceVal = {
        value: 0,
        minPrice: 0,
        maxPrice: 0,
    }
    const { value, minPrice, maxPrice } = !!priceValue ? priceValue : initialPriceVal
    if (value) {
        updatePriceStructure = value * (quantity ? quantity : setOfTwoCount)
    } else if (minPrice) {
        updatePriceStructure = minPrice * (quantity ? quantity : setOfTwoCount)
    } else if (maxPrice) {
        updatePriceStructure = maxPrice * (quantity ? quantity : setOfTwoCount)
    } else {
        updatePriceStructure = null
    }
    return updatePriceStructure
}

/**
 * function to check set of 4 price for staggered condition
 * @param {ProductSku[]} skus
 * @param {boolean} isStaggeredState
 * @param {number} frontQuantity
 * @param {number} rearQuantity
 * @return {number}
 */
export const getSetOfFourPriceForStaggered = (
    skus: ProductSku[],
    isStaggeredState?: boolean,
    frontQuantity?: number,
    rearQuantity?: number,
): number => {
    if (isStaggeredState) {
        const frontTirePrice = isArrayNotEmpty(skus) && checkMinPriceAndValue(skus[0].currentPrice, frontQuantity)
        const rearTirePrice =
            isArrayNotEmpty(skus) && checkMinPriceAndValue(skus[magicNumber.ONE].currentPrice, rearQuantity)
        return frontTirePrice + rearTirePrice
    } else {
        return 0
    }
}

/**
 * function to check set of 4 price for non-staggered condition
 * @param {ProductSku} skus
 * @param {boolean} isStaggeredState
 * @return {number}
 */
const getSetOfFourPriceForNonStaggered = (skus: ProductSku[], isStaggeredState?: boolean): number => {
    if (!isStaggeredState) {
        return isArrayNotEmpty(skus) && checkMinPriceAndValue(skus[0].currentPrice) * setOfTwoCount
    } else {
        return 0
    }
}

/**
 * function return setOfFour price value
 * @param {ProductSku[]} skus
 * @param {Price} currentPrice
 * @param {boolean} isStaggeredState
 * @param {boolean} vehicleInformationPresent
 * @param {boolean} tireInformationPresent
 * @return {Price}
 */
export const totalCurrentPrice = (
    skus: ProductSku[],
    currentPrice: Price,
    isStaggeredState?: boolean,
    vehicleInformationPresent?: boolean,
    tireInformationPresent?: boolean,
): Price => {
    return isStaggeredState
        ? {
              minPrice: getSetOfFourPriceForStaggered(skus, isStaggeredState),
              maxPrice: getSetOfFourPriceForStaggered(skus, isStaggeredState),
              value: getSetOfFourPriceForStaggered(skus, isStaggeredState),
          }
        : checkVehicleOrTirePresent(vehicleInformationPresent, tireInformationPresent)
        ? {
              minPrice: getSetOfFourPriceForNonStaggered(skus, isStaggeredState),
              maxPrice: getSetOfFourPriceForNonStaggered(skus, isStaggeredState),
              value: getSetOfFourPriceForNonStaggered(skus, isStaggeredState),
          }
        : {
              minPrice: currentPrice?.minPrice * setOfFourCount,
              maxPrice: currentPrice?.maxPrice * setOfFourCount,
              value: currentPrice?.value * setOfFourCount,
          }
}

const vehicleContextTotalPrice = (skus: ProductSku[]): Price => {
    return isArrayNotEmpty(skus) && skus[0].totalCurrentPrice
}

/**
 * Returns set of 4 price value for PLP page
 *
 * @param {ProductSku[]} skus
 * @param {Price} totalSetOf4Price set of 4 price (without vehicle context)
 * @param {boolean} isStaggeredState
 * @param {boolean} vehicleInformationPresent
 * @param {boolean} tireInformationPresent
 * @return {Price}
 */
export const setOf4Price = (
    skus: ProductSku[],
    totalSetOf4Price: Price,
    isStaggeredState?: boolean,
    vehicleInformationPresent?: boolean,
    tireInformationPresent?: boolean,
): Price => {
    return isStaggeredState
        ? {
              minPrice: getSetOfFourPriceForStaggered(skus, isStaggeredState),
              maxPrice: getSetOfFourPriceForStaggered(skus, isStaggeredState),
              value: getSetOfFourPriceForStaggered(skus, isStaggeredState),
          }
        : checkVehicleOrTirePresent(vehicleInformationPresent, tireInformationPresent)
        ? vehicleContextTotalPrice(skus)
        : totalSetOf4Price
}

/**
 * function return per tire  or rear label
 * @param {boolean} staggeredState
 * @param {string} perTireLabel
 * @param {string} vehicleRearLabel
 * @return {string}
 */
export const vehicleRearLabelValue = (
    staggeredState: boolean,
    perTireLabel: string,
    vehicleRearLabel: string,
): string => {
    return staggeredState ? vehicleRearLabel : perTireLabel
}

/**
 * function return specificiton label and its value for rear tire
 * @param {ProductSku[]} skus
 * @param {Specification[]} specification
 * @param {boolean} staggeredState
 * @param {string} productCardSpecifications
 * @return {string}
 */
export const rearTireSpecification = (
    skus: ProductSku[],
    specification?: Specification[],
    staggeredState?: boolean,
    productCardSpecifications?: string,
): string => {
    const specifications = filterSpecification(
        staggeredState ? skus?.[minimumVariantsLength]?.specifications : specification,
        productCardSpecifications,
    )
    return getSpecifications(specifications)
}

/**
 * function return pcode or sku code based on staggered condition
 * @param {ProductSku[]} skus
 * @param {string} code
 * @param {boolean} staggeredState
 * @param {boolean} vehicleInformationPresent
 * @param {boolean} tireInformationPresent
 * @return {string}
 */
export const rearTireCode = (
    skus: ProductSku[],
    code: string,
    staggeredState?: boolean,
    vehicleInformationPresent?: boolean,
    tireInformationPresent?: boolean,
): string => {
    return staggeredState
        ? isArrayNotEmpty(skus) && skus?.[minimumVariantsLength].formattedCode
        : checkVehicleOrTirePresent(vehicleInformationPresent, tireInformationPresent)
        ? isArrayNotEmpty(skus) && skus[0].formattedCode
        : code
}

/**
 * function return rear tire price based on staggered condition
 * @param {ProductSku[]} skus
 * @param {Price} currentPrice
 * @param {boolean} staggeredState
 * @param {boolean} vehicleInformationPresent
 * @param {boolean} tireInformationPresent
 * @return {Price}
 */
export const rearTireCurrentPrice = (
    skus: ProductSku[],
    currentPrice: Price,
    staggeredState?: boolean,
    vehicleInformationPresent?: boolean,
    tireInformationPresent?: boolean,
): Price => {
    return staggeredState
        ? isArrayNotEmpty(skus) && skus?.[minimumVariantsLength].currentPrice
        : checkVehicleOrTirePresent(vehicleInformationPresent, tireInformationPresent)
        ? isArrayNotEmpty(skus) && skus[0].currentPrice
        : currentPrice
}

/**
 * function return specificiton label and its value for front tire
 * @param {ProductSku[]} skus
 * @param {boolean} staggeredState
 * @param {string} productCardSpecifications
 * @return {string}
 * @param {Specification[]} productSpecifications
 */
export const frontTireSpecification = (
    skus: ProductSku[],
    staggeredState?: boolean,
    productCardSpecifications?: string,
    productSpecifications?: Specification[],
): string => {
    let specificationValueFront = []
    specificationValueFront = skus?.[magicNumber.ZERO]?.specifications
    const skuSpecificationForFront = specificationValueFront?.slice()
    if (isArrayNotEmpty(productSpecifications)) {
        skuSpecificationForFront.push(
            ...productSpecifications.filter(
                fromSpec =>
                    !skuSpecificationForFront.find(
                        (finaSpec: Record<string, unknown>) => finaSpec.code === fromSpec.code,
                    ),
            ),
        )
    }
    const specifications = filterSpecification(skuSpecificationForFront, productCardSpecifications)
    return staggeredState && getSpecifications(specifications)
}

/**
 * function return rear tire display label based on staggered condition
 * @param {ProductSku[]} skus
 * @param {boolean} displayWasLabel
 * @param {boolean} staggeredState
 * @param {boolean} vehicleInformationPresent
 * @param {boolean} tireInformationPresent
 * @return {Price}
 */
export const rearTireDisplayWasLabel = (
    skus: ProductSku[],
    displayWasLabel: boolean,
    staggeredState?: boolean,
    vehicleInformationPresent?: boolean,
    tireInformationPresent?: boolean,
): boolean => {
    return staggeredState
        ? isArrayNotEmpty(skus) && skus?.[minimumVariantsLength].displayWasLabel
        : checkVehicleOrTirePresent(vehicleInformationPresent, tireInformationPresent)
        ? isArrayNotEmpty(skus) && skus[0].displayWasLabel
        : displayWasLabel
}

/**
 * function return rear tire price based on staggered condition
 * @param {ProductSku[]} skus
 * @param {Price} originalPrice
 * @param {boolean} staggeredState
 * @param {boolean} vehicleInformationPresent
 * @param {boolean} tireInformationPresent
 * @return {Price}
 */
export const rearTireOriginalPrice = (
    skus: ProductSku[],
    originalPrice: Price,
    staggeredState?: boolean,
    vehicleInformationPresent?: boolean,
    tireInformationPresent?: boolean,
): Price => {
    return staggeredState
        ? isArrayNotEmpty(skus) && skus?.[minimumVariantsLength].originalPrice
        : checkVehicleOrTirePresent(vehicleInformationPresent, tireInformationPresent)
        ? isArrayNotEmpty(skus) && skus[0].originalPrice
        : originalPrice
}

/**
 * function to get TRF price for staggered condition on sku[0] and sku[1]
 * @param {ProductSku[]} skus
 * @param {number} position
 * @return {number}
 */
const getSKUFeeValue = (skus: ProductSku[], position: number): number => {
    return isArrayNotEmpty(skus) && skus[position]?.feeValue ? skus[position]?.feeValue * setOfTwoCount : 0
}

/**
 * function return TRF price for staggered condition
 * @param {ProductSku[]} skus
 * @param {boolean} staggeredState
 * @return {number}
 */

const staggeredFeeValue = (skus: ProductSku[], isStaggeredState?: boolean): number => {
    if (isStaggeredState && isArrayNotEmpty(skus)) {
        const frontFeeValue = getSKUFeeValue(skus, 0)
        const rearFeeValue = getSKUFeeValue(skus, magicNumber.ONE)
        return frontFeeValue + rearFeeValue
    } else {
        return 0
    }
}

// function to check vehicleInformationPresent and tireInformationPresent present or not
const checkVehicleOrTirePresent = (vehicleInformationPresent?: boolean, tireInformationPresent?: boolean): boolean => {
    return vehicleInformationPresent || tireInformationPresent
}

/**
 * function return TRF price based on staggered condition
 * @param {ProductSku[]} skus
 * @param {number} value
 * @param {boolean} staggeredState
 * @param {boolean} vehicleInformationPresent
 * @param {boolean} tireInformationPresent
 * @return {number}
 */
export const getFeeValue = (
    skus: ProductSku[],
    value?: number,
    staggeredState?: boolean,
    vehicleInformationPresent?: boolean,
    tireInformationPresent?: boolean,
): number => {
    return staggeredState
        ? staggeredFeeValue(skus, staggeredState)
        : checkVehicleOrTirePresent(vehicleInformationPresent, tireInformationPresent)
        ? getSKUFeeValue(skus, 0) * setOfTwoCount
        : value * setOfFourCount
}

/**
 * function return rear tire priceMessage based on staggered condition
 * @param {ProductSku[]} skus
 * @param {PriceMessage} priceMessage
 * @param {boolean} staggeredState
 * @param {boolean} vehicleInformationPresent
 * @param {boolean} tireInformationPresent
 * @return {PriceMessage[]}
 */
export const rearPriceMessage = (
    skus: ProductSku[],
    priceMessage: PriceMessage[],
    staggeredState?: boolean,
    vehicleInformationPresent?: boolean,
    tireInformationPresent?: boolean,
): PriceMessage[] => {
    return staggeredState
        ? isArrayNotEmpty(skus) && skus?.[minimumVariantsLength].priceMessage
        : checkVehicleOrTirePresent(vehicleInformationPresent, tireInformationPresent)
        ? isArrayNotEmpty(skus) && skus[0].priceMessage
        : priceMessage
}

/**
 * function return rear tire badges based on staggered condition
 * @param {ProductSku[]} skus
 * @param {string[]} badges
 * @param {boolean} staggeredState
 * @param {boolean} vehicleInformationPresent
 * @param {boolean} tireInformationPresent
 * @param {string[]} exclude
 * @return {string[]}
 */
export const rearBadgeList = (
    skus: ProductSku[],
    badges: string[],
    staggeredState?: boolean,
    vehicleInformationPresent?: boolean,
    tireInformationPresent?: boolean,
    exclude?: string[],
): string[] => {
    return staggeredState
        ? isArrayNotEmpty(skus) && combineBadges(skus[minimumVariantsLength].badges, badges, exclude)
        : checkVehicleOrTirePresent(vehicleInformationPresent, tireInformationPresent)
        ? isArrayNotEmpty(skus) && combineBadges(skus[0].badges, badges, exclude)
        : badges
}

/**
 * function to get rebate details
 * @param { ProductSku[] } skus
 * @param { RebateData } rebate
 * @param {boolean} staggeredState
 * @param { boolean } vehicleInformationPresent
 * @param { boolean } tireInformationPresent
 * @return { RebateData | null }
 */

export const getRebateDetails = (
    skus: ProductSku[],
    rebate: RebateData,
    staggeredState?: boolean,
    vehicleInformationPresent?: boolean,
    tireInformationPresent?: boolean,
): RebateData | null => {
    return !staggeredState
        ? checkVehicleOrTirePresent(vehicleInformationPresent, tireInformationPresent)
            ? isArrayNotEmpty(skus) && skus[0]?.rebate
            : rebate
        : rebate
}
