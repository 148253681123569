import React, { useRef, useEffect, useCallback } from 'react'

import { keyCodes } from '../../utils/keyCodes'
import { PREFIX, previousElementName } from '../config'
import { useClickOutsideClose } from '../Tooltip/useClickOutside'
import { FlyoutModalComponentProps } from './FlyoutModalComponent.type'
import { modalAccessibilityHandler } from '../../utils/modalAccessibility'
import { disableFocusLock, enableFocusLock } from '../../utils/focusLock'

/**
 * Flyout Modal
 * @param {FlyoutModalComponentProps} param
 * @return {JSX.Element} JSX Element
 */
export const FlyoutModalComponent: React.FC = ({
    children,
    closeFlyout,
    isOpen,
    modalTriggeredElement,
    disableCloseByEscOrOutsideClick,
    title,
    isStoreSelectorModalUsed,
}: FlyoutModalComponentProps): JSX.Element => {
    const flyOutContent = useRef<HTMLDivElement>(null)

    const modalClassName = `${PREFIX}-flyout-modal-overlay`

    const undoAccessibilityProps = { modalOpen: false, modalClassName }

    const previousElement: HTMLElement =
        document.querySelector(`button[${previousElementName}]`) || modalTriggeredElement
    const focusLockEventRef = useRef<EventListener>()

    const closeHandler = useCallback(() => {
        modalAccessibilityHandler(undoAccessibilityProps)
        closeFlyout() // Closing Modal UI
        // checking if it exists or not to avoid focus null error when the clicked element goes off from the ui due to data change.
        if (previousElement) {
            previousElement.focus() // Highlight the initiated button
            previousElement.removeAttribute(previousElementName) // Removing it when user close it.
        }
    }, [closeFlyout, previousElement, undoAccessibilityProps])

    useEffect(() => {
        if (isOpen) {
            document.body.style.position = 'relative'
        }
        return () => {
            document.body.style.position = 'unset'
        }
    }, [isOpen])

    // Execute the below when user press escape key.
    const closeModalEsc = useCallback(
        (e: KeyboardEvent): void => {
            if (e.keyCode === keyCodes.esc && !disableCloseByEscOrOutsideClick) {
                closeHandler()
            }
        },
        [closeHandler, disableCloseByEscOrOutsideClick],
    )

    useEffect(() => {
        if (isOpen) {
            flyOutContent.current.addEventListener('keydown', closeModalEsc)
            if (!isStoreSelectorModalUsed) {
                focusLockEventRef.current = enableFocusLock(flyOutContent)
            }
        } else {
            flyOutContent.current.removeEventListener('keydown', closeModalEsc)
            !isStoreSelectorModalUsed && disableFocusLock(focusLockEventRef.current)
        }
    }, [isOpen, closeModalEsc, isStoreSelectorModalUsed])

    useClickOutsideClose(flyOutContent, closeHandler, isOpen, !disableCloseByEscOrOutsideClick)

    return (
        <div className={`${PREFIX}-overlay ${modalClassName}`}>
            <div
                className={`${PREFIX}-flyout-modal-component`}
                ref={flyOutContent}
                role="dialog"
                aria-modal="true"
                aria-label={title}>
                {children}
            </div>
        </div>
    )
}

FlyoutModalComponent.defaultProps = {
    disableCloseByEscOrOutsideClick: false,
}
