export const isUrl = (url: string): boolean => {
    //  eslint-disable-next-line
    const regexp = /^(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
    return regexp.test(url)
}

/**
 * This util is used to append returnURL as query param if it is currently available in url
 * @param {string} originalUrl - redirection url if no returnURL present
 * @return {string}
 */
export const appendReturnUrl = (originalUrl: string): string => {
    const returnURL = new URLSearchParams(window.location.search)?.get('returnURL')
    return returnURL ? `${originalUrl}?returnURL=${encodeURIComponent(returnURL)}` : originalUrl
}

export const urlRedirection = (path: string, params?: string, query?: string): string => {
    const updatedQuery = query && query.replace(/ /g, '+')
    const strParams = String(params)
    return updatedQuery ? `${path}?${strParams}=${updatedQuery}` : `${path}?${strParams}`
}

/**
 * @param {string} url
 * @return {string} // param separator
 */
const getParameterSeparator = (url: string): string => {
    return url.includes('?') ? '&' : '?'
}

/**
 * util to append marketing information to URL (defaultPCID or pcid extracted from local storage and other marketing info)
 * @param {string} url
 * @param {string} pcidFromLocalStorage //  pcid saved in local storage
 * @param {string} defaultPcid //  default pcid from props
 * @param {string} otherTrackingInfoFromLocalStorage // marketing info other than PCID from local storage
 * @return {string} // new url - with appended values
 */
export const appendTrackingInfo = (
    url: string,
    pcidFromLocalStorage: string,
    defaultPcid: string,
    otherTrackingInfoFromLocalStorage: string,
): string => {
    const paramSeperator = getParameterSeparator(url)
    const pcidToAppend = pcidFromLocalStorage != null ? `pcid=${pcidFromLocalStorage}` : `pcid=${defaultPcid}`
    const restTrackingInfo = otherTrackingInfoFromLocalStorage ? `&${otherTrackingInfoFromLocalStorage}` : ``
    const urlUpdatedWithPcid = url.includes('pcid') ? url : encodeURI(`${url}${paramSeperator}${pcidToAppend}`)
    return encodeURI(`${urlUpdatedWithPcid}${restTrackingInfo}`)
}

/**
 * check return url in the window location and check value is matching
 * @param {string} value
 * @return {boolean}
 */
export const checkReturnURL = (value: string): boolean => {
    const returnURL = new URLSearchParams(window.location.search)?.get('returnURL')
    return Boolean(returnURL?.includes(value))
}

/**
 * Redirect to the page
 * @param {string} url
 * @return {void}
 */
export const redirectToPage = (url: string): void => {
    window.location.href = encodeURI(url)
}

/**
 * Function to check if current page pathname is equal to given pathname
 * @param {string} pathname
 * @return {boolean}
 */
export const checkPathname = (pathname: string): boolean => {
    return window.location.pathname === pathname
}
