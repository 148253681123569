import { PREFIX } from '../components/config'

/**
 * scrolling from top to footer/ rating container
 * @param {HTMLElement} element scroll from top to footer/rating Container on click of rating or Triangle
 */
export const scrollToElement = (element: HTMLElement | null) => {
    const stickyNav = document.getElementById(`${PREFIX}-nav`)
    const primaryNav = document.getElementsByClassName(`${PREFIX}-primary-navigation-bar`)[0]
    const primaryNavHeight = primaryNav?.clientHeight || 0
    const primaryNavPos = primaryNav?.getBoundingClientRect().y || 0
    const secondaryNavHeight =
        document.getElementsByClassName(`${PREFIX}-secondary-navigation-bar`)[0]?.clientHeight || 0

    const elementOffSet = element?.getClientRects()[0]?.top
    const scrollPos = document.documentElement.scrollTop

    if (element) {
        const sectionTop = stickyNav
            ? elementOffSet - secondaryNavHeight - primaryNavPos - primaryNavHeight - stickyNav.clientHeight
            : elementOffSet - secondaryNavHeight - primaryNavPos - primaryNavHeight

        window.scroll({
            top: sectionTop + scrollPos,
            behavior: 'smooth',
        })
    }
}

/**
 * scrollToFooterHandler scrolling from tire recycle fee to footer container
 */

const scrollToFooterHandler = (event: React.MouseEvent<Element, MouseEvent>): void => {
    event.stopPropagation()
    event.preventDefault()
    const element = document.getElementById('legal-disclaimer-text')

    scrollToElement(element)
}

export const scrollToFooter = scrollToFooterHandler
