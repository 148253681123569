import { StoreWithAvailability } from '../redux/models/storeDetails.interface'

/* eslint-disable @typescript-eslint/restrict-template-expressions */
export const getMedalliaStoreData = (storeDetails: StoreWithAvailability): unknown => {
    const storeNumberStandardLength = 4
    let storeNumber = storeDetails.id?.toString()

    while (storeNumber?.length < storeNumberStandardLength) {
        storeNumber = `0${storeNumber}`
    }

    return {
        storeNumber: storeNumber,
        orderThreshold: storeDetails.threshold,
        handlingFee: storeDetails.fee,
        onlineOrdering: storeDetails.onlineOrdering,
        sleName: storeDetails.sleConfigurationName,
        curbsideAtCheckout: storeDetails.isEligibleForCurbside,
    }
}

export const setOrderIdToMedalliaData = (orderId: string | undefined): void => {
    if (orderId) {
        window.medalliaData = window.medalliaData || {}
        window.medalliaData['orderId'] = orderId
    }
}
