import React, { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import OfferCarousel from './OfferCarousel'
import { OfferCarouselWrapperProps } from './OfferCarousel.type'
import { useMobileLayoutState } from '../../hooks/layout.hook'
import { userProfileDataSelector } from '../../redux/selectors/userProfile.selectors'
import { checkDataLength, isArrayNotEmpty, libUtils } from '@nl/lib'
import { fetchCustomerOffers } from '../../redux/actions'
import { FetchOffersPayload } from '../../redux/models/offers.interface'
import { offerConstants } from '../Accounts/Rewards/Offers/OffersCard.constants'
import { offersDataSelector, offersSelector } from '../../redux/selectors/offers.selectors'
import { getFilteredOffersBanner } from '../../helpers/offers.helper'

const OfferCarouselWrapper: React.FC<OfferCarouselWrapperProps> = () => {
    const { loyaltyOffersProps } = useSelector(offersSelector)
    const { enableDesktopTablet, enableMobile, offerBannerList, nlUserOffers } = loyaltyOffersProps || {}

    const dispatch = useDispatch()
    const userProfileData = useSelector(userProfileDataSelector)
    const isLoggedIn = checkDataLength(userProfileData)
    const hasLoyaltyAccount = checkDataLength(userProfileData?.loyalty)
    const [isMobileLayout] = useMobileLayoutState() as [boolean]

    const offersData = useSelector(offersDataSelector)
    const loyaltyOffersData = !!hasLoyaltyAccount ? offersData?.offers : nlUserOffers

    let offersToDisplay =
        loyaltyOffersData?.filter(
            offer => offer?.offerStatus !== offerConstants.redeemed && offer?.redeemed !== offerConstants.yes,
        ) || []
    if (offerBannerList?.length && !!hasLoyaltyAccount) {
        // To get the filtered offers based on authored banners list from AEM
        offersToDisplay = getFilteredOffersBanner(offersToDisplay, offerBannerList)
    }
    const hasOffers = Boolean(offersToDisplay?.length)

    const loadOffers = useCallback(() => {
        const payload = {
            lang: libUtils.getLanguage(),
            resultsPerPage: offerConstants.maxRewardsCount.toString(),
            pageNumber: offerConstants.currentPageInitialValue.toString(),
        } as FetchOffersPayload
        dispatch(fetchCustomerOffers(payload))
    }, [dispatch])
    useEffect(() => {
        if (
            isLoggedIn &&
            hasLoyaltyAccount &&
            ((isMobileLayout && enableMobile) || (!isMobileLayout && enableDesktopTablet))
        ) {
            loadOffers()
        }
    }, [isLoggedIn, hasLoyaltyAccount, loadOffers, isMobileLayout, enableMobile, enableDesktopTablet])
    const isDisplayComponent = (): boolean => {
        const displayOfferCarousel = (isMobileLayout && enableMobile) || (!isMobileLayout && enableDesktopTablet)
        return isLoggedIn && hasOffers && displayOfferCarousel && isArrayNotEmpty(offerBannerList)
    }

    return isDisplayComponent() ? (
        <OfferCarousel {...loyaltyOffersProps} offersData={offersToDisplay} isMobileLayout={isMobileLayout} />
    ) : null
}

export default OfferCarouselWrapper
