import React, { useCallback, useEffect, useState, useRef, Validator, useMemo } from 'react'
import PropTypes from 'prop-types'
import { PREFIX } from '../config'
import { cardPropTypes, FeatureBullets, ProductImage } from './product.types'
import ProductInfo from '../ProductInfo'
import Price from '../Price'
import Badges from '../Badges'
import Rebate from '../Rebate'
import { getSecondaryImage } from '../ProductCardVariants/productCardData'
import { ProductCardType as GridProductCardType, ProductOptionValue } from '../ProductGridView/ProductGrid.types'
import { ProductCardType } from '../ProductCardVariants/ProductCard.type'
import { grid, minOptionsArrayLength, plpUrl, saleOrClearanceBadge } from './productCard.constant'
import { isArrayNotEmpty } from '../../utils/isArrayNotEmpty'
import { scrollToFooter } from '../../utils/scrollToFooter'
import { checkDataLength } from '../../utils/checkDataLength'
import { magicNumber, stringKeyCodes, replaceStrWithDynamicVal } from '../../utils'
import Icon from '../Icon'
import { productCardScrollToTop } from '../../utils/ProductCardScrollToTop/productCardScrollToTop'
import { ProductCardScrollEvent } from '../../utils/ProductCardScrollToTop/productCardScrollToTop.type'
import { BREAKPOINTS } from '../config'
import PromoMessages from '../PromoMessages'
import ProductCardColorSwatches from '../ProductCardColorSwatches'
import { COLOR } from '../Variants/Variants.constant'
import { useGenerateSaveMessage } from '../Price/Price.helper'
import { useScrollRestoration } from './ProductCard.hooks'
import {
    getAccessibilityAvailabilityId,
    getAccessibilityPriceId,
    getAccessibilityTitleId,
} from '../../utils/getAccessibilityId'
/**
 * ProductCard component
 * @return {JSX.Element} returns ProductCardVariants,ProductInfo,Price,Badges
 */

const ProductCard: React.FC<cardPropTypes> = ({
    productProps: {
        ratingsAndReview,
        featureHeaderLabel,
        variantAvailableMsg,
        a11yPriceRangeFrom,
        a11yPriceRangeTo,
        fulfillment,
        sellable,
        orderable,
    },
    product,
    idx,
    cardType,
    title,
    brand,
    code,
    ratingsCount,
    price,
    badgePriorities,
    badges,
    showBadge = true,
    timeTestedPrice,
    discount,
    hideDisclaimer,
    hideDescription,
    productCardClick,
    hideSaveLabel,
    a11yStrikeOutPrice,
    a11yStrikeOutPriceRange,
    a11yCloseIconLabel,
    a11yTooltipIcon,
    images,
    featureBullets,
    options,
    currentPrice,
    originalPrice,
    displayWasLabel,
    language,
    feeTitle,
    feeDisclaimerMessage,
    feeDisclaimerTitle,
    showFBTComponent,
    priceMessage,
    rebateIcon,
    rebate,
    nowFromLabel,
    saveFromLabel,
    wasFromLabel,
    fromLabel,
    feeDisclaimerType,
    imageDataComponentName,
    returnPolicy,
    isMultiSku,
    inStockOnlineLabel,
    outOfStockLabel,
    inStorePurchaseLabel,
    inStockAisleLabel,
    checkAvailabilityLabel,
    checkOtherStoresLabel,
    overridePriceHeight,
    unitPriceLabel,
    clearancePriceLabel,
    promotionalPriceLabel,
    thresholdValue,
    enableSaveStory,
    url,
    isUrgentLowStock,
    isUrgentLowStockLabel,
    isWheelOrTirePDP,
    eachLabel,
    isAutomotiveProduct,
    isScrollingRequired,
    isLazyRequire,
    productDataId,
    saleEndDaySoonMessage,
    isColourSwatchesActive,
    saleMessagesRules,
    saleEndDisableShift,
    a11yVariantSelected,
    a11yVariantUnSelected,
    a11yClickToReadFootnote,
    accessibilityId,
}) => {
    const GridView = cardType === grid
    const [data, setData] = useState<ProductCardType>()
    const [isHovered, setIsHovered] = useState<number>(null)

    const colorVariant = useMemo(() => {
        return options?.find(variant => {
            return variant.descriptor === COLOR
        })
    }, [options])

    const [currentColorVariantData, setCurrentColorVariantData] = useState<ProductOptionValue>(
        colorVariant?.values?.[0],
    )
    const [isColorChanged, setIsColorChanged] = useState(false)

    const currentLevelData = useMemo(() => {
        return colorVariant
            ? currentColorVariantData
            : {
                  ...product,
                  originalPrice,
                  currentPrice,
                  badges,
                  displayWasLabel,
                  priceMessage,
              }
    }, [
        colorVariant,
        currentColorVariantData,
        product,
        originalPrice,
        currentPrice,
        badges,
        displayWasLabel,
        priceMessage,
    ])

    const saveMessage = useGenerateSaveMessage(
        currentLevelData?.saleCut,
        currentLevelData?.originalPrice,
        saleMessagesRules,
        currentLevelData?.currentPrice,
    )

    const {
        restorationRef,
        getScrollRestorationId,
        removeScrollRestorationId,
        restoreScroll,
        saveDataAsScrollRestorationId,
    } = useScrollRestoration()

    const onProductClick = (
        event: React.KeyboardEvent<HTMLElement> | React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        selectedColorId,
    ) => {
        if (GridView) {
            saveDataAsScrollRestorationId(productDataId)
        }
        productCardClick(event, product, idx, null, selectedColorId)
    }

    // function to get product card details
    const productCardInfo = useRef({
        title,
        brand,
        ratingsCount,
        price,
        timeTestedPrice,
        images,
    })

    const createUrlWithPolicy = (source: string, policyType: string) => {
        return policyType
            ? `${source?.toString()?.split('?', magicNumber.ONE).toString()}${returnPolicy(policyType)}`
            : `${source}`
    }

    useEffect(() => {
        const productData = getSecondaryImage(productCardInfo.current)
        setData({ ...productData })
        productCardInfo.current = productData
    }, [])

    useEffect(() => {
        const savedDataAsScrollRestorationId = getScrollRestorationId()

        const isIdsMatched = productDataId === savedDataAsScrollRestorationId

        if (isIdsMatched) {
            restoreScroll()
            removeScrollRestorationId()
        }
    }, [restorationRef, productDataId, getScrollRestorationId, removeScrollRestorationId, restoreScroll])

    /**
     * @method onMouseEnter  : Function invoked on mouse enter event
     * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} e
     * @param {productDataRefType} productCard
     * @param {number} productIndex
     * @param {ProductImage[]} productImages
     */

    const onMouseEnter = (
        _e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        productIndex: number,
        productCard: ProductCardType,
        productImages?: ProductImage[],
    ) => {
        if ((_e.target as HTMLElement)?.parentElement?.className === `${PREFIX}-product-card__image-wrap`) {
            _e.target['src'] = `${createUrlWithPolicy(
                getImage(productIndex, productIndex, productCard, productImages),
                imageDataComponentName,
            )}`
        }
    }

    /**
     * @method onMouseEnter  : Function invoked on mouse enter event if color swatches active
     * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} _e
     * @param {productDataRefType} productCard
     * @param {number} productIndex
     * @param {ProductImage[]} productImages
     */

    const onMouseEnterWithColorSwatches = (
        _e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        productIndex: number,
        productCard: ProductCardType,
        productImages?: ProductImage[],
    ) => {
        if (!isColourSwatchesActive) {
            onMouseEnter(_e, productIndex, productCard, productImages)
        }
    }

    /**
     * @method onMouseLeave  : Function invoked on mouse leave event
     * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} e
     * @param {productDataRefType} productCard
     * @param {number} productIndex
     * @param {ProductImage[]} productImages
     */

    const onMouseLeave = (
        _e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        productIndex: number,
        productCard: ProductCardType,
        productImages?: ProductImage[],
    ) => {
        if ((_e.target as HTMLElement)?.parentElement?.className === `${PREFIX}-product-card__image-wrap`) {
            _e.target['src'] = `${createUrlWithPolicy(
                getImage(productIndex, isHovered, productCard, productImages),
                imageDataComponentName,
            )}`
            setIsHovered(null)
        }
    }

    /**
     * @method onMouseEnter  : Function invoked on mouse leave event if color swatches active
     * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} _e
     * @param {productDataRefType} productCard
     * @param {number} productIndex
     * @param {ProductImage[]} productImages
     */

    const onMouseLeaveWithColorSwatches = (
        _e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        productIndex: number,
        productCard: ProductCardType,
        productImages?: ProductImage[],
    ) => {
        if (!isColourSwatchesActive) {
            onMouseLeave(_e, productIndex, productCard, productImages)
        }
    }

    /**
     * @method getPrimaryImage  : Function returns primary image
     * @return {string} : returns the primary image
     */

    const getPrimaryImage = (): string => {
        const currentImages = images ? images : productCardInfo.current.images
        return currentImages?.[0]?.url
    }

    /**
     * Availability statement component
     * @return {JSX.Element} returns availability statement to show
     */
    const renderAvailabilityStatement = (): JSX.Element => {
        if (fulfillment) {
            const quantityFlag = fulfillment.availability.quantity
            const corporateQuantityFlag = fulfillment.availability.Corporate.Quantity
            const sellableFlag = sellable
            const orderableFlag = orderable
            return getAvailabilityMessage(quantityFlag, corporateQuantityFlag, sellableFlag, orderableFlag)
        }
    }

    const renderUrgencyLowStockMessage = (quantityFlag: number): JSX.Element => {
        const urgencyMsg = isUrgentLowStockLabel
            ? replaceStrWithDynamicVal(isUrgentLowStockLabel, quantityFlag?.toString())
            : ''
        return (
            <span>
                <span className={`${PREFIX}-product-card__urgentLowStock`}>{urgencyMsg}</span>
            </span>
        )
    }

    // eslint-disable-next-line complexity
    const getAvailabilityMessage = (
        quantityFlag: number,
        corporateQuantityFlag: number,
        isSellableFlag: boolean,
        isOrderableFlag: boolean,
    ): JSX.Element => {
        let availabilityLabel: string | JSX.Element = replaceStrWithDynamicVal(
            inStockAisleLabel,
            quantityFlag?.toString() ?? '',
        )
        let availabilityMessage: JSX.Element
        if (isSellableAndInStock(quantityFlag, isSellableFlag)) {
            if (isUrgentLowStock) {
                availabilityLabel = renderUrgencyLowStockMessage(quantityFlag)
            }
            availabilityMessage = (
                <div
                    className={`${PREFIX}-product-card__availability-message`}
                    id={getAccessibilityAvailabilityId(accessibilityId)}>
                    <div>
                        <span className={`${PREFIX}-product-card__availability-icon`}>
                            <Icon type="ct-checkmark" size="sm" />
                        </span>
                        {availabilityLabel}
                    </div>
                </div>
            )
        } else if (isNotSellableAndInStock(quantityFlag, isSellableFlag)) {
            if (isUrgentLowStock) {
                availabilityLabel = renderUrgencyLowStockMessage(quantityFlag)
            }
            availabilityMessage = (
                <div
                    className={`${PREFIX}-product-card__availability-message`}
                    id={getAccessibilityAvailabilityId(accessibilityId)}>
                    <div>
                        <span className={`${PREFIX}-product-card__availability-icon`}>
                            <Icon type="ct-checkmark" size="sm" />
                        </span>
                        {availabilityLabel}
                    </div>
                    <div>
                        <span className={`${PREFIX}-product-card__availability-icon`}>
                            <Icon type="ct-checkmark" size="sm" />
                        </span>
                        {inStorePurchaseLabel}
                    </div>
                </div>
            )
        } else if (isSellableAndOrderable(quantityFlag, corporateQuantityFlag, isSellableFlag, isOrderableFlag)) {
            availabilityMessage = (
                <div
                    className={`${PREFIX}-product-card__availability-message`}
                    id={getAccessibilityAvailabilityId(accessibilityId)}>
                    <div>
                        <span className={`${PREFIX}-product-card__availability-icon`}>
                            <Icon type="ct-checkmark" size="sm" />
                        </span>
                        {inStockOnlineLabel}
                    </div>
                </div>
            )
        } else if (checkOtherStores(quantityFlag, corporateQuantityFlag, isSellableFlag, isOrderableFlag)) {
            availabilityMessage = (
                <div
                    className={`${PREFIX}-product-card__availability-message`}
                    id={getAccessibilityAvailabilityId(accessibilityId)}>
                    <div>
                        <span className={`${PREFIX}-product-card__availability-icon`}>
                            <Icon type="ct-prohibited" size="sm" />
                        </span>
                        {availabilityLabel}
                    </div>
                    {corporateQuantityFlag > 0 && (
                        <div>
                            <span className={`${PREFIX}-product-card__availability-icon`}>
                                <Icon type="ct-checkmark" size="sm" />
                            </span>
                            <span className={`${PREFIX}-product-card__link-text`}>{checkOtherStoresLabel}</span>
                        </div>
                    )}
                </div>
            )
        } else if (isMultiSku) {
            availabilityMessage = (
                <div
                    className={`${PREFIX}-product-card__availability-message`}
                    id={getAccessibilityAvailabilityId(accessibilityId)}>
                    <div>
                        <span className={`${PREFIX}-product-card__availability-icon`}>
                            <Icon type="ct-checkmark" size="sm" />
                        </span>
                        <span className={`${PREFIX}-product-card__link-text`}>{checkAvailabilityLabel}</span>
                    </div>
                </div>
            )
        } else if (isOutOfStock(quantityFlag, corporateQuantityFlag, isSellableFlag, isOrderableFlag)) {
            availabilityMessage = (
                <div
                    className={`${PREFIX}-product-card__availability-message`}
                    id={getAccessibilityAvailabilityId(accessibilityId)}>
                    <div>
                        <span className={`${PREFIX}-product-card__availability-icon`}>
                            <Icon type="ct-prohibited" size="sm" />
                        </span>
                        {outOfStockLabel}
                    </div>
                </div>
            )
        }
        return availabilityMessage
    }

    const isSellableAndInStock = (quantityFlag: number, isSellableFlag: boolean): boolean => {
        return !isMultiSku && quantityFlag > 0 && isSellableFlag === true
    }

    const isNotSellableAndInStock = (quantityFlag: number, isSellableFlag: boolean): boolean => {
        return !isMultiSku && quantityFlag > 0 && isSellableFlag === false
    }

    const isSellableAndOrderable = (
        quantityFlag: number,
        corporateQuantityFlag: number,
        isSellableFlag: boolean,
        isOrderableFlag: boolean,
    ): boolean => {
        return (
            !isMultiSku &&
            quantityFlag === 0 &&
            corporateQuantityFlag > 0 &&
            isSellableFlag === true &&
            isOrderableFlag === true
        )
    }

    const checkOtherStores = (
        quantityFlag: number,
        corporateQuantityFlag: number,
        isSellableFlag: boolean,
        isOrderableFlag: boolean,
    ): boolean => {
        return (
            !isMultiSku &&
            (isInStockCheckOtherStores(quantityFlag, corporateQuantityFlag, isSellableFlag, isOrderableFlag) ||
                isNotInStockCheckOtherStores(quantityFlag, corporateQuantityFlag, isSellableFlag, isOrderableFlag))
        )
    }

    const isInStockCheckOtherStores = (
        quantityFlag: number,
        corporateQuantityFlag: number,
        isSellableFlag: boolean,
        isOrderableFlag: boolean,
    ): boolean => {
        return (
            quantityFlag === 0 &&
            corporateQuantityFlag > 0 &&
            ((isSellableFlag === true && isOrderableFlag === false) ||
                (isSellableFlag === false && isOrderableFlag === true) ||
                (isSellableFlag === false && isOrderableFlag === false))
        )
    }

    const isNotInStockCheckOtherStores = (
        quantityFlag: number,
        corporateQuantityFlag: number,
        isSellableFlag: boolean,
        isOrderableFlag: boolean,
    ): boolean => {
        return (
            quantityFlag === 0 &&
            !corporateQuantityFlag &&
            ((isSellableFlag === true && isOrderableFlag === true) ||
                (isSellableFlag === true && isOrderableFlag === false))
        )
    }

    const isOutOfStock = (
        quantityFlag: number,
        corporateQuantityFlag: number,
        isSellableFlag: boolean,
        isOrderableFlag: boolean,
    ): boolean => {
        return (
            isMultiSku &&
            quantityFlag === 0 &&
            corporateQuantityFlag > 0 &&
            isSellableFlag === true &&
            isOrderableFlag === false
        )
    }

    const ratingsView = () => {
        // TODO: if cds sends rating as an object i.e. averageRating and number of reviews need to pass the object keys as the props.
        return (
            ratingsAndReview && (
                <div
                    className={`${PREFIX}-reviews__list`}
                    data-bv-show="inline_rating"
                    data-bv-product-id={productDataId}
                    data-bv-seo="false"
                    aria-hidden={true}
                />
            )
        )
    }

    /**
     * function to check for sale or clearance badge
     * @return {boolean}
     */
    const hasSaleOrClearanceBadge = (): boolean =>
        badges.some(badge => Number(saleOrClearanceBadge.indexOf(badge)) >= 0)

    /**
     * function to render price component
     *
     * @return {JSX.Element} returns Price Component
     */
    const renderPriceComponent = () => {
        return (
            <Price
                discountValue={discount}
                promotionalPriceLabel={discount ? promotionalPriceLabel : null}
                thresholdValue={thresholdValue}
                unitPriceLabel={unitPriceLabel}
                clearancePriceLabel={clearancePriceLabel}
                a11yPriceRangeFrom={a11yPriceRangeFrom}
                a11yPriceRangeTo={a11yPriceRangeTo}
                hideSaveLabel={hideSaveLabel}
                a11yStrikeOutPrice={a11yStrikeOutPrice}
                a11yStrikeOutPriceRange={a11yStrikeOutPriceRange}
                a11yTooltipIcon={a11yTooltipIcon}
                a11yCloseIconLabel={a11yCloseIconLabel}
                feeTitle={feeTitle}
                feeDisclaimerTitle={feeDisclaimerTitle}
                feeDisclaimerMessage={feeDisclaimerMessage}
                currentPrice={currentLevelData?.currentPrice}
                originalPrice={currentLevelData?.originalPrice}
                displayWasLabel={currentLevelData?.displayWasLabel}
                language={language}
                priceMessage={currentLevelData?.priceMessage}
                scrollToFooter={scrollToFooter}
                nowFromLabel={nowFromLabel}
                saveFromLabel={saveFromLabel}
                wasFromLabel={wasFromLabel}
                fromLabel={fromLabel}
                feeDisclaimerType={feeDisclaimerType}
                overridePriceHeight={overridePriceHeight}
                showEachTextForSaveLabel={enableSaveStory}
                isAutomotiveEachLabel={isWheelOrTirePDP}
                eachLabel={eachLabel}
                saveMessage={saveMessage}
                a11yClickToReadFootnote={a11yClickToReadFootnote}
                accessibilityPriceId={getAccessibilityPriceId(accessibilityId)}
                ariaHidden={true}
                highlightDiscountedPrice={true}
                isOnSaleOrClearance={hasSaleOrClearanceBadge()}
            />
        )
    }

    const renderColorSwatches = (): JSX.Element => {
        return (
            <div className={`${PREFIX}-product-card__color-swatches`}>
                {colorVariant && (
                    <ProductCardColorSwatches
                        colors={colorVariant.values}
                        onColorChange={onColorChange}
                        selectedValue={currentColorVariantData?.id}
                        a11yVariantSelected={a11yVariantSelected}
                        a11yVariantUnSelected={a11yVariantUnSelected}
                        display={colorVariant.display}
                    />
                )}
            </div>
        )
    }

    const onColorChange = useCallback(currentVariant => {
        setCurrentColorVariantData({ ...currentVariant })
        setIsColorChanged(true)
    }, [])

    /**
     * function to display productInfo  price and badges
     *
     * @return {JSX.Element} returns productInfo,price and badges components
     */

    const { message, greyCopyMsg, pdfLink } = rebate || {}
    /**
     * @return {JSX.Element} returns productInfo Component
     */

    /**
     * function to display Promo Messages  and tooltip
     *
     * @return {JSX.Element} returns Promo Messages  and tooltip from PromoMessages components
     */
    const renderPromoMessages = (): JSX.Element => {
        const promoMessages = currentLevelData?.promoMessages
        const restrictedPromoMessages = currentLevelData?.restrictedPromoMessages
        const isRenderPromoMessages = checkDataLength(promoMessages) || checkDataLength(restrictedPromoMessages)
        return isRenderPromoMessages ? (
            <PromoMessages
                isProductLevel={true}
                productPromoMessages={promoMessages}
                productRestrictedPromoMessages={restrictedPromoMessages}
                a11yTooltipIcon={a11yTooltipIcon}
                a11yCloseIconLabel={a11yCloseIconLabel}
                ariaHidden={true}
            />
        ) : null
    }

    /**
     * @method getDisclaimerData  : Function return Disclaimer data
     */

    const getDisclaimerData = (): boolean => !colorVariant?.showDisclaimer || hideDisclaimer

    /**
     * Function to return productcode
     * @return {JSX.Element}
     */
    const productCodeDisplay = (): JSX.Element => {
        return (
            code && (
                <p className={`${PREFIX}-product__code`} aria-hidden={true}>
                    #{code}
                </p>
            )
        )
    }

    const productInfoComponent = (): JSX.Element => {
        const saleEndDate = currentColorVariantData?.saleEndDate || currentLevelData?.saleEndDate
        const badgesToDisplay = currentLevelData?.badges
        return (
            <div className={`${PREFIX}-product-card__product-information`}>
                <ProductInfo
                    brand={brand}
                    title={title}
                    hideDescription={hideDescription}
                    titleStyle={`${PREFIX}-product-card__title ${PREFIX}-product--trim`}
                    titleContainerStyle={`${PREFIX}-product-card__title-container`}
                    accessibilityTitleId={getAccessibilityTitleId(accessibilityId)}
                />
                {ratingsView()}
                {renderPriceComponent()}
                {showBadge && badgesToDisplay && badgesToDisplay.length > 0 && (
                    <div className={`${PREFIX}-plp-badges`} aria-hidden={true}>
                        <Badges
                            badges={badgesToDisplay}
                            badgesAndPriorities={badgePriorities}
                            hideDisclaimer={getDisclaimerData()}
                            saleEndDaySoonMessage={saleEndDaySoonMessage}
                            salePriceEndDate={saleEndDate}
                            saleEndDisableShift={saleEndDisableShift}
                        />
                    </div>
                )}
                {productCodeDisplay()}
                {rebate && Object.keys(rebate).length && (
                    <Rebate
                        title={message}
                        rebateIcon={rebateIcon}
                        greyCopyMsg={greyCopyMsg}
                        pdfLink={pdfLink}
                        ariaHidden={true}
                    />
                )}
                {renderAvailabilityStatement()}
                {renderPromoMessages()}
                {Array.isArray(options) && options.length > minOptionsArrayLength && !isAutomotiveProduct && (
                    <div
                        className={`${PREFIX}-product-card__colors ${PREFIX}-product-card__colour-label`}
                        aria-hidden={true}>
                        <span className={`${PREFIX}-product-card__show-colour`}>{variantAvailableMsg}</span>
                    </div>
                )}
            </div>
        )
    }

    /**
     * function to display featureSection
     *
     * @return {JSX.Element}
     */

    const featureSection = () => {
        return (
            <>
                {!GridView && featureBullets && (
                    <div className={`${PREFIX}-product-card__features`} data-testid={`product-card-features`}>
                        <h4 className={`${PREFIX}-product-card__features-heading`}>{featureHeaderLabel}</h4>
                        <ul className={` ${PREFIX}-product-card__features-deck`}>
                            {featureBullets.slice(0, magicNumber.THREE).map((value: FeatureBullets, index: number) => {
                                return <li key={index.toString() + value.description}>{value.description}</li>
                            })}
                        </ul>
                    </div>
                )}
            </>
        )
    }

    type imgAttribute = {
        'data-src'?: string
        src?: string
        className?: string
        'data-component-name': string
    }

    /**
     * @param {string} path
     * @param {string} dataComponentName
     * @return {imgAttribute}
     */
    const imageAttributes = (path: string, dataComponentName: string) => {
        const attributeObject: imgAttribute = {
            'data-component-name': dataComponentName,
        }
        if (isLazyRequire && !isColorChanged) {
            attributeObject['data-src'] = path

            attributeObject.className = 'lazyload'
        } else {
            attributeObject.src = path
        }
        return attributeObject
    }

    const renderImage = () => {
        if (isColourSwatchesActive && currentColorVariantData?.mediaSet?.[0].url) {
            return (
                <div className={`${PREFIX}-product-card__image-wrap`} aria-hidden={true}>
                    <img
                        alt={title}
                        {...imageAttributes(
                            isArrayNotEmpty(currentColorVariantData?.mediaSet) &&
                                `${createUrlWithPolicy(
                                    currentColorVariantData?.mediaSet?.[0]?.url,
                                    imageDataComponentName,
                                )}`,
                            imageDataComponentName,
                        )}
                    />
                </div>
            )
        }

        return (
            <>
                {window.innerWidth < BREAKPOINTS.mobileMaxWidth ? (
                    <div className={`${PREFIX}-product-card__image-wrap`} aria-hidden={true}>
                        <img
                            alt={title}
                            {...imageAttributes(
                                isArrayNotEmpty(images) &&
                                    `${createUrlWithPolicy(images?.[0]?.url, imageDataComponentName)}`,
                                imageDataComponentName,
                            )}
                        />
                    </div>
                ) : (
                    <div className={`${PREFIX}-product-card__image-wrap`} aria-hidden={true}>
                        <img
                            alt={title}
                            onMouseEnter={e => onMouseEnterWithColorSwatches(e, idx, data, images)}
                            onMouseLeave={e => onMouseLeaveWithColorSwatches(e, idx, data, images)}
                            {...imageAttributes(
                                `${createUrlWithPolicy(
                                    getImage(idx, isHovered, data, images),
                                    imageDataComponentName,
                                )}`,
                                imageDataComponentName,
                            )}
                        />
                    </div>
                )}
            </>
        )
    }

    const modifiedUrl = currentColorVariantData?.id ? `${url}${plpUrl}&colorCode=${currentColorVariantData?.id}` : url

    const renderProductCardLink = () => {
        const selectedColorId = currentColorVariantData?.id ?? null
        return (
            <a
                ref={restorationRef}
                aria-labelledby={`title__${accessibilityId} price__${accessibilityId} availability__${accessibilityId}`}
                className={`${PREFIX}-product-card__no-button`}
                onClick={event => onProductClick(event, selectedColorId)}
                onKeyUp={(event: React.KeyboardEvent<HTMLElement>) => {
                    if (event.key === stringKeyCodes.tab && isScrollingRequired) {
                        productCardScrollToTop(event as ProductCardScrollEvent)
                    }
                }}
                onKeyPress={(event: React.KeyboardEvent<HTMLElement>) => {
                    if (event.key === stringKeyCodes.enter) {
                        onProductClick(event, selectedColorId)
                    }
                }}
                href={`${modifiedUrl}`}>
                <div
                    data-testid={`product-card-container${idx}`}
                    className={`${PREFIX}-product-card__content`}
                    onMouseEnter={e => onMouseEnter(e, idx, data, images)}
                    onMouseLeave={e => onMouseLeave(e, idx, data, images)}>
                    <div className={`${PREFIX}-product-card__primary-details`}>
                        {renderImage()}
                        {isColourSwatchesActive && renderColorSwatches()}
                        {productInfoComponent()}
                    </div>
                    {featureSection()}
                </div>
            </a>
        )
    }

    return showFBTComponent ? (
        <div
            data-testid={`product-card-container-fbt${idx}`}
            className={`${PREFIX}-product-card__content`}
            aria-labelledby={`title__${accessibilityId} price__${accessibilityId} availability__${accessibilityId}`}>
            <div className={`${PREFIX}-product-card__image-wrap`}>
                <img
                    aria-hidden={true}
                    onMouseEnter={e => onMouseEnter(e, idx, data, images)}
                    onMouseLeave={e => onMouseLeave(e, idx, data, images)}
                    alt={title}
                    {...imageAttributes(
                        `${createUrlWithPolicy(getPrimaryImage(), imageDataComponentName)}`,
                        imageDataComponentName,
                    )}
                />
            </div>
            {productInfoComponent()}
        </div>
    ) : (
        renderProductCardLink()
    )
}

/** function to get product image
 * @param { ProductImage[] } images
 * @return { string | null }
 */
const getProductImage = (images?: ProductImage[]): string | null => {
    return isArrayNotEmpty(images) && images[0].url
}

/**
 * Function uses to take image URL
 * @param { number } idx
 * @param { number | null } isHovered
 * @param { ProductCardType } data
 * @param { ProductImage[] } images
 * @return { string }
 */
const getImage = (idx: number, isHovered: number | null, data: ProductCardType, images?: ProductImage[]): string => {
    const currentImages = images ? images : data?.images
    return data && isHovered === idx && data.secondaryImage ? data.secondaryImage : getProductImage(currentImages)
}

ProductCard.defaultProps = {
    showFBTComponent: false,
}

ProductCard.propTypes = {
    path: PropTypes.string,
    productProps: PropTypes.any,
    idx: PropTypes.number.isRequired,
    cardType: PropTypes.string.isRequired,
    type: PropTypes.string,
    title: PropTypes.string.isRequired,
    code: PropTypes.string,
    brand: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.exact({
            label: PropTypes.string,
            url: PropTypes.string,
        }),
    ]).isRequired,
    rating: PropTypes.number.isRequired,
    ratingsCount: PropTypes.number.isRequired,
    price: PropTypes.any,
    priceShortMessage: PropTypes.string,
    priceLongMessage: PropTypes.string,
    longDescription: PropTypes.string,
    timeTestedPrice: PropTypes.any,
    permanentMarkdown: PropTypes.bool,
    sellable: PropTypes.bool,
    assemblyRequired: PropTypes.bool,
    inStock: PropTypes.bool,
    onlineOnly: PropTypes.bool,
    onFloor: PropTypes.bool,
    bopisOnly: PropTypes.bool,
    productCardClick: PropTypes.func,
    redirectAfterProductCardClick: PropTypes.func,
    badgePriorities: PropTypes.any,
    badges: PropTypes.array,
    showBadge: PropTypes.bool,
    discount: PropTypes.any,
    hideDisclaimer: PropTypes.bool,
    hideDescription: PropTypes.bool,
    hideSaveLabel: PropTypes.bool,
    a11yStrikeOutPrice: PropTypes.string,
    a11yStrikeOutPriceRange: PropTypes.string,
    a11yTooltipIcon: PropTypes.string,
    images: PropTypes.array,
    featureBullets: PropTypes.array,
    a11yCloseIconLabel: PropTypes.string,
    ecoFeeTitleChange: PropTypes.func,
    options: PropTypes.array,
    skus: PropTypes.array,
    currentPrice: PropTypes.any,
    originalPrice: PropTypes.any,
    displayWasLabel: PropTypes.bool,
    language: PropTypes.string,
    feeTitle: PropTypes.string,
    feeDisclaimerMessage: PropTypes.string,
    feeDisclaimerTitle: PropTypes.string,
    selectedProduct: PropTypes.bool,
    showFBTComponent: PropTypes.bool,
    priceMessage: PropTypes.any,
    rebate: PropTypes.any,
    rebateIcon: PropTypes.string,
    scrollToFooter: PropTypes.func,
    nowFromLabel: PropTypes.string,
    saveFromLabel: PropTypes.string,
    wasFromLabel: PropTypes.string,
    fromLabel: PropTypes.string,
    feeDisclaimerType: PropTypes.string,
    imageDataComponentName: PropTypes.string,
    returnPolicy: PropTypes.func,
    inStockLabel: PropTypes.string,
    inStockOnlineLabel: PropTypes.string,
    outOfStockLabel: PropTypes.string,
    inStorePurchaseLabel: PropTypes.string,
    inStockAisleLabel: PropTypes.string,
    checkAvailabilityLabel: PropTypes.string,
    checkOtherStoresLabel: PropTypes.string,
    overridePriceHeight: PropTypes.bool,
    unitPriceLabel: PropTypes.string,
    clearancePriceLabel: PropTypes.string,
    promotionalPriceLabel: PropTypes.string,
    thresholdValue: PropTypes.number,
    enableSaveStory: PropTypes.bool,
    a11yReviewRating: PropTypes.string,
    url: PropTypes.string,
    isUrgentLowStock: PropTypes.bool,
    isUrgentLowStockLabel: PropTypes.string,
    productWheelType: PropTypes.string,
    isWheelOrTirePDP: PropTypes.bool,
    eachLabel: PropTypes.string,
    isAutomotiveProduct: PropTypes.bool,
    isScrollingRequired: PropTypes.bool,
    isLazyRequire: PropTypes.bool,
    product: PropTypes.object.isRequired as Validator<GridProductCardType>,
    productDataId: PropTypes.string,
    saleEndDaySoonMessage: PropTypes.string,
    a11yClickToReadFootnote: PropTypes.string,
}

export default ProductCard
