import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { pageTypes, PREFIX, previousElementName } from '../../../config'
import { HeaderWrapperProps } from './HeaderWrapper.type'
import SearchModal from '../SearchModal/SearchModal'
import SearchBarWrapper from '../SearchBarWrapper/SearchBarWrapper'
import AutoServiceButton from '../../AutoServiceButton'
import {
    Icon,
    HeaderStoreLocator,
    FlyoutModalComponent,
    enableDestructOnUndefinedData,
    getStoreTimings,
    getNextDayStoreTimings,
} from '@nl/lib'
import { getEnvironment } from '../../../environments'
import getPageType from '../../../utils/getPageType'
import StoreSelectorModalComp from '../../StoreSelectorModal/StoreSelectorModalComp'
import { replaceMultipleStrWithDynamicVal, replaceStrWithDynamicVal } from '@nl/lib/src/utils'
import { fetchNearbyStoreList } from '../../../redux/actions'
import {
    storeListRequestType,
    StoreWithAvailability,
    WeekDayOpeningList,
} from '../../../redux/models/storeDetails.interface'
import { setStoreLocatorPopupToggleState } from '../../../redux/actionCreators/popupsToggleState.actionCreators'
import { getSideNavigationIsOpen } from '../../../redux/reducers/popupsToggleState.reducer'
import { useMobileScrollLock } from './HeaderWrapper.hooks'
import { commonContentSelector } from '../../../redux/selectors/commonContent.selectors'
import { checkNotNullAndUndefined } from '../../../utils/checkNotNullAndUndefined'
import { dispatchToast } from '../../ToastMessage/ToastMessage.helper'
import { ToastComponentNames } from '../../../redux/models/toastMessage.interface'
import { StoreChangeSource } from '../../../globalConstants'
import { afternoonHours } from './HeaderWrapper.constants'
import {
    isStoreDetailsFetchedSelector,
    nearbyStoreListSelector,
    storeAPITriangulationFailSelector,
    storeChangeSourceSelector,
} from '../../../redux/selectors/storeDetails.selectors'

/**
 * HeaderWrapper component
 * @param {HeaderWrapperProps} props
 * @return {JSX.Element} returns SearchLocator and SearchBar components
 */
const HeaderWrapper: React.FC<HeaderWrapperProps> = props => {
    const {
        searchIconSize,
        path,
        closeIconSize,
        searchCallBack,
        searchPagePathURL,
        a11ySearchIconLabel,
        allyCloseIconLabel,
        a11yHeaderStorePreferred,
        a11yHeaderStoreCurrently,
        a11yUpdateQueryIconLabel,
        a11yHeaderStoreAt,
        openLabel,
        opensLabel,
        closesLabel,
        storeLocatorList,
        preferredStoreId,
        storeLocatorGlobalPropData,
        fetchNearbyStoreListOnSearch,
        fetchStoreListOnLoad,
        inputMaxLength,
        viewMoreAnalytics,
        mapIconOnClickHandler,
        windowRef,
        setPreferredStore,
        searchStoreAnalyticsHandler,
        minChars,
        delay,
        count,
        minCatLevel,
        maxNumCats,
        ctrPriceUrl,
        searchLabel,
        searchBoxPlaceholder,
        suggestionsLabel,
        categoriesLabel,
        seeMoreResultsLabel,
        searchHistoryTitle,
        searchHistoryCountDesktop,
        searchHistoryCountMobile,
        searchHistoryClearLabel,
        onlineOrdersNotAcceptedMsg,
        storeSuccessfullySelectedMsg,
        seeMoreStoresMsg,
        forcePreferredStoreMsg,
        selectAStoreLabel,
        storeLocationLabel,
        currentUserLocation,
        didYouMeanLabel,
        maxNumberOfStoresForOldStoreLocator,
        a11yArrowButtonsAriaLabel,
        a11ySuggestionsAvailableLabel,
    } = props

    const pageType = getPageType()
    const [showModal, setShowModal] = useState(false)
    const [shouldOpen, setShouldOpen] = useState(false)
    const [isHeaderStoreLocator, setIsHeaderStoreLocator] = useState(false)
    const [selectedStore, setSelectedStore] = useState<StoreWithAvailability>(null)
    const tassProps = {
        minChars,
        delay,
        count,
        minCatLevel,
        maxNumCats,
        ctrPriceUrl,
        searchLabel,
        searchBoxPlaceholder,
        suggestionsLabel,
        categoriesLabel,
        seeMoreResultsLabel,
        searchHistoryTitle,
        searchHistoryCountDesktop,
        searchHistoryCountMobile,
        searchHistoryClearLabel,
        searchPagePathURL,
        didYouMeanLabel,
    }

    const environment = getEnvironment()
    const { baseSiteId } = environment

    const { commonContentAvailable } = useSelector(commonContentSelector)
    const storeAPITriangulationFail = useSelector(storeAPITriangulationFailSelector)
    const nearbyStoreList = useSelector(nearbyStoreListSelector)
    const sideNavigationIsOpen = useSelector(getSideNavigationIsOpen)
    const isStoreDetailsFetched = useSelector(isStoreDetailsFetchedSelector)
    const storeChangeSource = useSelector(storeChangeSourceSelector)

    const [featureFlagContent, setFeatureFlagContent] = useState(
        commonContentAvailable?.featureFlag || ({} as typeof commonContentAvailable.featureFlag),
    )

    const [storeLocatorContent, setStoreLocatorContent] = useState(
        commonContentAvailable?.storeLocator || ({} as typeof commonContentAvailable.storeLocator),
    )

    useEffect(() => {
        commonContentAvailable?.storeLocator && setStoreLocatorContent(commonContentAvailable.storeLocator)
        commonContentAvailable?.featureFlag && setFeatureFlagContent(commonContentAvailable.featureFlag)
    }, [commonContentAvailable])

    const {
        closedLabel,
        maxNumberOfStores,
        maxRadiusToSearchStore,
        maxNumberOfStoresHeader,
        headerOnlyTitle: title,
        storeChangeToastTimeOut,
    } = storeLocatorContent

    const { enableNewHeaderStoreLocator } = featureFlagContent

    const weekdayHours = selectedStore && (getStoreTimings(selectedStore) as StoreWithAvailability)
    const nextDayHours = selectedStore && (getNextDayStoreTimings(selectedStore) as StoreWithAvailability)
    const weekdayHoursList = enableDestructOnUndefinedData(weekdayHours)
    const { closingTime, closed } = weekdayHoursList as WeekDayOpeningList
    const { openingTime } =
        new Date().getHours() < afternoonHours
            ? (weekdayHoursList as WeekDayOpeningList)
            : (enableDestructOnUndefinedData(nextDayHours) as WeekDayOpeningList)

    const timeText = closed
        ? `⋅ ${opensLabel} ${' '} ${openingTime?.formattedHour}`
        : `⋅ ${closesLabel} ${' '} ${closingTime?.formattedHour}`
    const storeTimingsPresent = closed ? openingTime?.formattedHour : closingTime?.formattedHour
    const dispatch = useDispatch()

    /**
     * Dispatch and Set Store Locator State Open/Close
     * @param {boolean} state
     */
    const dispatchStoreLocatorPopupState = (state: boolean): void => {
        dispatch(setStoreLocatorPopupToggleState(state))
    }

    /**
     * sets showModal as true to show the SearchModal
     */
    const showSearchModal = (): void => {
        setShowModal(true)
        setClassAvoidOverlapping(true)
    }

    /**
     * sets showModal as false to hide the SearchModal
     */
    const hideSearchModal = (): void => {
        setShowModal(false)
        setClassAvoidOverlapping(false)
    }

    const setClassAvoidOverlapping = (show: boolean): void => {
        const stickySoreLocator = document.getElementsByClassName(`${PREFIX}-primary-navigation__sticky_store-locator`)
        const stickyNav = document.getElementsByClassName(`${PREFIX}-primary-navigation__sticky_nav`)

        if (stickySoreLocator.length) {
            if (show) {
                stickySoreLocator[0].classList.add(`${PREFIX}-primary-navigation__sticky_store-locator-avoid-overlap`)
                stickyNav[0].classList.add(`${PREFIX}-primary-navigation__sticky_nav-avoid-overlap`)
            } else {
                stickySoreLocator[0].classList.remove(
                    `${PREFIX}-primary-navigation__sticky_store-locator-avoid-overlap`,
                )
                stickyNav[0].classList.remove(`${PREFIX}-primary-navigation__sticky_nav-avoid-overlap`)
            }
        }
    }

    const renderAutoAppointmentButton = (): JSX.Element | null => {
        const isAOAFlow = pageType === pageTypes.aoaPage
        return !isAOAFlow ? <AutoServiceButton /> : null
    }

    /**
     * Return Store Locator Ally Label
     * @return {string}
     */
    const getAllyStoreLocatorLabel = (): string => {
        const a11yHeaderStorePreferredLabel = replaceStrWithDynamicVal(a11yHeaderStorePreferred, selectedStore?.name)
        const a11yHeaderStoreCurrentlyLabel = replaceStrWithDynamicVal(
            a11yHeaderStoreCurrently,
            closed ? closedLabel : openLabel,
        )
        const a11yHeaderStoreAtLabel = replaceMultipleStrWithDynamicVal(a11yHeaderStoreAt, [
            closed ? opensLabel : closesLabel,
            closed ? openingTime?.formattedHour : closingTime?.formattedHour,
        ])
        return `${a11yHeaderStorePreferredLabel}, ${a11yHeaderStoreCurrentlyLabel}, ${a11yHeaderStoreAtLabel}`
    }

    /**
     * Function to check if no preferred store in localstorage and stores api fails with error code "00114"
     * @return {boolean}
     */
    const checkIfStoresApiFails = (): boolean => {
        return !props.preferredStoreDetails?.id && !!storeAPITriangulationFail
    }
    const isStoresApiFailed = checkIfStoresApiFails()

    // useEffect to open header store locator flyout if there is no prefered store id in localstorage & if stores api fails with error code "00114"
    useEffect(() => {
        if (isStoresApiFailed) {
            setShouldOpen(true)
        }
    }, [isStoresApiFailed])

    useEffect(() => {
        setSelectedStore(props.preferredStoreDetails)
    }, [props.preferredStoreDetails])

    useMobileScrollLock(shouldOpen)

    /**
     * function used to set state variable to open the flyout modal
     */
    const closeFlyout = (): void => {
        setShouldOpen(false)
        dispatchStoreLocatorPopupState(false)
        setIsHeaderStoreLocator(false)
    }

    /**
     * Display toast message on preferred store change
     * @return {void}
     */
    const displaySuccessToast = useCallback((): void | null => {
        const toastProps = {
            options: {
                toastSuccessMessage: storeSuccessfullySelectedMsg,
                toastSuccessIcon: 'ct-notification-success',
            },
            success: true,
            hideCTA: true,
            enableTimer: true,
            toastTimeOutValue: parseInt(storeChangeToastTimeOut),
        }
        dispatchToast(true, toastProps, ToastComponentNames.NONE, dispatch)
    }, [dispatch, storeSuccessfullySelectedMsg, storeChangeToastTimeOut])

    useEffect(() => {
        if (isStoreDetailsFetched && Object.values(StoreChangeSource).includes(storeChangeSource)) {
            displaySuccessToast()
        }
    }, [displaySuccessToast, isStoreDetailsFetched, storeChangeSource])

    // Props required for flyout modal
    const FlyOutModalComponentProps = {
        isOpen: shouldOpen,
        closeFlyout,
        disableCloseByEscOrOutsideClick: isStoresApiFailed,
        title,
        isStoreSelectorModalUsed: true,
    }

    /**
     * method returns Store locator jsx element
     * @return {JSX.Element}
     */
    const headerStoreLocatorComponent = (): JSX.Element | null => {
        if (checkNotNullAndUndefined(enableNewHeaderStoreLocator) || enableNewHeaderStoreLocator === '') {
            return null
        }
        if (Boolean(enableNewHeaderStoreLocator)) {
            return shouldOpen ? (
                <FlyoutModalComponent {...FlyOutModalComponentProps}>
                    <StoreSelectorModalComp
                        maxNumberOfStores={maxNumberOfStores}
                        closeFlyout={closeFlyout}
                        isHeaderStoreLocator={isHeaderStoreLocator}
                        storeList={nearbyStoreList}
                        viewMoreAnalytics={viewMoreAnalytics}
                        forcePreferredStoreMsg={forcePreferredStoreMsg}
                        isOpen={shouldOpen}
                    />
                </FlyoutModalComponent>
            ) : null
        } else {
            return (
                <HeaderStoreLocator
                    path={path}
                    a11yHeaderStorePreferred={a11yHeaderStorePreferred}
                    a11yHeaderStoreCurrently={a11yHeaderStoreCurrently}
                    a11yHeaderStoreAt={a11yHeaderStoreAt}
                    openLabel={openLabel}
                    opensLabel={opensLabel}
                    closedLabel={closedLabel}
                    closesLabel={closesLabel}
                    storeLocatorList={storeLocatorList}
                    preferredStoreId={preferredStoreId}
                    storeLocatorGlobalPropData={storeLocatorGlobalPropData}
                    fetchNearbyStoreListOnSearch={fetchNearbyStoreListOnSearch}
                    fetchStoreListOnLoad={fetchStoreListOnLoad}
                    inputMaxLength={inputMaxLength}
                    viewMoreAnalytics={viewMoreAnalytics}
                    mapIconOnClickHandler={mapIconOnClickHandler}
                    windowRef={windowRef}
                    setPreferredStore={setPreferredStore}
                    searchStoreAnalyticsHandler={searchStoreAnalyticsHandler}
                    onlineOrdersNotAcceptedMsg={onlineOrdersNotAcceptedMsg}
                    storeSuccessfullySelectedMsg={storeSuccessfullySelectedMsg}
                    seeMoreStoresMsg={seeMoreStoresMsg}
                    renderAutoAppointmentButton={renderAutoAppointmentButton}
                    preferredStoreDetails={props.preferredStoreDetails}
                    currentUserLocation={currentUserLocation}
                    baseStoreId={baseSiteId}
                    storeAPITriangulationFail={storeAPITriangulationFail}
                    forcePreferredStoreMsg={forcePreferredStoreMsg}
                    selectAStoreLabel={selectAStoreLabel}
                    storeLocationLabel={storeLocationLabel}
                    didYouMeanLabel={didYouMeanLabel}
                    sideNavigationIsOpen={sideNavigationIsOpen}
                    dispatchStoreLocatorPopupState={dispatchStoreLocatorPopupState}
                    maxNumberOfStores={maxNumberOfStoresForOldStoreLocator}
                />
            )
        }
    }

    /**
     * Function to open store selector modal and send analytics.
     * @param {MouseEvent} event click event
     */
    const checkNearbyStoreCTAHandler = (event: MouseEvent): void => {
        const button = event.currentTarget as HTMLElement
        setShouldOpen(true)
        dispatchStoreLocatorPopupState(true)
        setIsHeaderStoreLocator(true)
        button.setAttribute(previousElementName, 'true')
        const requestPayload = { maxCount: maxNumberOfStoresHeader }
        const radius = Number(maxRadiusToSearchStore) || 0
        const { latitude, longitude } = props.preferredStoreDetails.geoPoint
        dispatch(
            fetchNearbyStoreList({
                ...requestPayload,
                radius,
                latitude,
                longitude,
            } as storeListRequestType),
        )
    }

    /**
     * method returns Store locator jsx element
     *
     * @return {JSX.Element}
     */
    const headerWrapperRender = (): JSX.Element => {
        return (
            <>
                <div className={`${PREFIX}-header ${PREFIX}-row`}>
                    <div className={`${PREFIX}-header__mobile-row`}>
                        {showModal && (
                            <SearchModal
                                searchIconSize={searchIconSize}
                                showModal={showModal}
                                closeModal={hideSearchModal}
                                path={path}
                                closeIconSize={closeIconSize}
                                searchCallBack={searchCallBack}
                                searchPagePath={searchPagePathURL}
                                a11ySearchIconLabel={a11ySearchIconLabel}
                                allyCloseIconLabel={allyCloseIconLabel}
                                a11yUpdateQueryIconLabel={a11yUpdateQueryIconLabel}
                                a11yArrowButtonsAriaLabel={a11yArrowButtonsAriaLabel}
                                a11ySuggestionsAvailableLabel={a11ySuggestionsAvailableLabel}
                                mobileSearchBarId="mobile-search-bar"
                                {...tassProps}
                            />
                        )}
                    </div>
                    <div className={`${PREFIX}-header-row`}>
                        {headerStoreLocatorComponent()}
                        {enableNewHeaderStoreLocator ? (
                            <div className={`${PREFIX}-store-locator`}>
                                <button
                                    dap-wac-link-section="top-nav"
                                    dap-wac-value="Store Locator"
                                    className={`${PREFIX}-store-locator__icon ${PREFIX}-md-none`}
                                    aria-label="Store Locator Icon"
                                    onClick={(e: MouseEvent) => checkNearbyStoreCTAHandler(e)}
                                    data-testid="header-store-locator-icon">
                                    <Icon type="ct-location" size="lg" path={path} />
                                </button>
                                <div
                                    className={`${PREFIX}-store-locator__row`}
                                    data-testid="header-store-locator-section">
                                    <div className={`${PREFIX}-store-locator--section`}>
                                        <button
                                            dap-wac-link-section="top-nav"
                                            dap-wac-value="Store Locator"
                                            className={`${PREFIX}-store-locator--section-button`}
                                            data-testid="header-store-locator-section-button"
                                            onClick={(e: MouseEvent) => checkNearbyStoreCTAHandler(e)}>
                                            <span className="sr-only">{getAllyStoreLocatorLabel()}</span>
                                            <span
                                                className={`${PREFIX}-store-locator__icon
                            ${PREFIX}-store-locator__icon_md ${PREFIX}-md-inline`}>
                                                <Icon type="ct-location" size="lg" path={path} />
                                            </span>
                                            <div className={`${PREFIX}-store-locator--section-name`}>
                                                <span
                                                    aria-hidden="true"
                                                    className={`${PREFIX}-store-locator--selected-store`}>
                                                    {selectedStore?.name}
                                                </span>
                                                {storeTimingsPresent && (
                                                    <div className={`${PREFIX}-store-locator--timing`}>
                                                        <span aria-hidden="true">
                                                            {closed ? closedLabel : openLabel}
                                                        </span>
                                                        <span
                                                            aria-hidden="true"
                                                            className={`${PREFIX}-store-locator--timing-end`}>
                                                            {timeText}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className={`${PREFIX}-store-locator-skeleton`} />
                        )}
                        <SearchBarWrapper
                            searchIconSize={searchIconSize}
                            showCloseButton={false}
                            showModal={showModal}
                            path={path}
                            searchCallBack={searchCallBack}
                            searchPagePath={searchPagePathURL}
                            a11ySearchIconLabel={a11ySearchIconLabel}
                            allyCloseIconLabel={allyCloseIconLabel}
                            a11yUpdateQueryIconLabel={a11yUpdateQueryIconLabel}
                            a11yArrowButtonsAriaLabel={a11yArrowButtonsAriaLabel}
                            a11ySuggestionsAvailableLabel={a11ySuggestionsAvailableLabel}
                            {...tassProps}
                        />
                    </div>
                </div>

                {
                    <div
                        className={`${PREFIX}-header__mobile-search`}
                        onClick={showSearchModal}
                        onKeyPress={showSearchModal}
                        role="button"
                        tabIndex={0}>
                        {!showModal && (
                            <SearchBarWrapper
                                searchIconSize={searchIconSize}
                                showCloseButton={false}
                                showModal={showModal}
                                suggestionPannel={true}
                                path={path}
                                searchCallBack={searchCallBack}
                                searchPagePath={searchPagePathURL}
                                a11ySearchIconLabel={a11ySearchIconLabel}
                                allyCloseIconLabel={allyCloseIconLabel}
                                a11yArrowButtonsAriaLabel={a11yArrowButtonsAriaLabel}
                                a11ySuggestionsAvailableLabel={a11ySuggestionsAvailableLabel}
                                {...tassProps}
                            />
                        )}
                    </div>
                }
            </>
        )
    }

    return headerWrapperRender()
}

HeaderWrapper.propTypes = {
    searchIconSize: PropTypes.string.isRequired,
    path: PropTypes.string,
    closeIconSize: PropTypes.string.isRequired,
    searchCallBack: PropTypes.func,
    searchPagePathURL: PropTypes.string.isRequired,
    a11ySearchIconLabel: PropTypes.string.isRequired,
    allyCloseIconLabel: PropTypes.string.isRequired,
    a11yHeaderStorePreferred: PropTypes.string.isRequired,
    a11yHeaderStoreCurrently: PropTypes.string.isRequired,
    a11yHeaderStoreAt: PropTypes.string.isRequired,
    a11yUpdateQueryIconLabel: PropTypes.string.isRequired,
    openLabel: PropTypes.string.isRequired,
    closesLabel: PropTypes.string.isRequired,
    storeLocatorList: PropTypes.array.isRequired,
    preferredStoreId: PropTypes.string.isRequired,
    storeLocatorGlobalPropData: PropTypes.any.isRequired,
    fetchNearbyStoreListOnSearch: PropTypes.func.isRequired,
    fetchStoreListOnLoad: PropTypes.func.isRequired,
    inputMaxLength: PropTypes.number.isRequired,
    viewMoreAnalytics: PropTypes.func.isRequired,
    mapIconOnClickHandler: PropTypes.func.isRequired,
    windowRef: PropTypes.any.isRequired,
    setPreferredStore: PropTypes.func.isRequired,
    searchStoreAnalyticsHandler: PropTypes.func.isRequired,
    minChars: PropTypes.string.isRequired,
    delay: PropTypes.string.isRequired,
    count: PropTypes.string.isRequired,
    minCatLevel: PropTypes.string.isRequired,
    maxNumCats: PropTypes.string.isRequired,
    ctrPriceUrl: PropTypes.string.isRequired,
    searchLabel: PropTypes.string.isRequired,
    searchBoxPlaceholder: PropTypes.string.isRequired,
    suggestionsLabel: PropTypes.string.isRequired,
    categoriesLabel: PropTypes.string.isRequired,
    seeMoreResultsLabel: PropTypes.string.isRequired,
    searchHistoryTitle: PropTypes.string.isRequired,
    searchHistoryCountDesktop: PropTypes.number.isRequired,
    searchHistoryCountMobile: PropTypes.number.isRequired,
    searchHistoryClearLabel: PropTypes.string.isRequired,
    onlineOrdersNotAcceptedMsg: PropTypes.string.isRequired,
    storeSuccessfullySelectedMsg: PropTypes.string.isRequired,
    seeMoreStoresMsg: PropTypes.string.isRequired,
    preferredStoreDetails: PropTypes.any.isRequired,
    currentUserLocation: PropTypes.any.isRequired,
    forcePreferredStoreMsg: PropTypes.string.isRequired,
    selectAStoreLabel: PropTypes.string.isRequired,
    storeLocationLabel: PropTypes.string.isRequired,
    sideNavigationIsOpen: PropTypes.bool.isRequired,
    a11yArrowButtonsAriaLabel: PropTypes.string,
    a11ySuggestionsAvailableLabel: PropTypes.string,
}
export default HeaderWrapper
