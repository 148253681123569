import { magicNumber } from '../../utils/magicNumber'

export const offerConstants = {
    offerCode: 'offerCode',
    maxRewardsCount: magicNumber.ONETHOUSAND,
    currentPageInitialValue: magicNumber.ONE,
    view: 'view',
    click: 'click',
    loyaltyOffersAppearedTimes: 'loyaltyOffersAppearedTimes',
    loyaltyOffersWereExpanded: 'loyaltyOffersWereExpanded',
    loyaltyOffersPanelDismiss: 'loyaltyOffersPanelDismiss',
    flatrate: 'FLATRATE',
    zeroMultiplier: '0X',
    percent: '%',
    activated: 'ACTIVATED',
    activate: 'ACTIVATE',
    redeemed: 'REDEEMED',
    offersCardImgComponentName: 'offers-card',
    bannerLogoImgComponentName: 'banner-logo',
}

export enum offerComponentLocation {
    accountOffers = 'accountOffers',
    pdpWidget = 'pdpWidget',
    loyaltyOffer = 'loyaltyOffer',
    pdpOffers = 'pdpOffers',
}
