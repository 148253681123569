import React from 'react'
import PropTypes from 'prop-types'

import { TabProps } from './Tabs.type'
import { PREFIX } from '../config'

/**
 * Tab component
 * @return {JSX.Element} returns Tab component
 */
const Tab: React.FC<TabProps> = ({ ...props }): JSX.Element => {
    let tabClass = `${PREFIX}-tabs__tab`
    const tabClassLabel = `${tabClass}__label`
    /**
     * Function to call callback function to set active tab
     */
    const onTabClick = (): void => {
        const { label, onClick } = props
        onClick(label)
    }

    if (props.activeTab === props.label) {
        tabClass += ` ${tabClass}--active`
    }

    return (
        <li className={tabClass}>
            <div
                data-testid={props.dataTestId}
                className={tabClassLabel}
                role="button"
                tabIndex={0}
                onKeyDown={onTabClick}
                onClick={onTabClick}>
                {props.label}
            </div>
        </li>
    )
}

Tab.propTypes = {
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    dataTestId: PropTypes.string,
}

export default Tab
