import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import {
    DynamicButton,
    MediaGalleryProps,
    ProductImages,
    MediaTypeValue,
    MediaGalleryHTMLVideoElement,
} from './MediaGalleryViewer.type'
import { isArrayNotEmpty } from '../../utils/isArrayNotEmpty'
import { BREAKPOINTS, PREFIX } from '../config'
import {
    mediaGalleryViewerClassName,
    mediaGalleryClassName,
    mediaGalleryConstants,
    thumbnailImageCountMobile,
    hideAkamaiViewerImageClassName,
    mediaGalleryNoPaddingClassName,
    staticImageContainerClassName,
    AKAMAI_IMAGE_LOAD_EVENT,
} from './MediaGalleryViewer.constant'
import { useGlobalResizeEvent } from '../../utils/useWindowEvent'
import { setThumbnailHeightMobile } from '../../utils/setThumbnailHeight'
import {
    addNavigationArrowButtons,
    checkIfMediaSetExist,
    getImagePath,
    thumbnailArrowClick,
} from './MediaGalleryViewer.helper'
import { AkamaiViewer } from './Akamai/Akamai.Viewer'
import { customizeAkamai } from './Akamai/customizeAkamai'
import usePrevious from '../../hooks/usePrevious.hook'
import { MagnifierOptions } from './Akamai/Akamai.type'
import { getDeviceType } from '../../utils/getDeviceType'
import { deviceTypes } from '../../globalConstants/global.constant'

/* MediaGalleryViewer component
 * @param {MediaGalleryProps} props
 * @return {JSX.Element} returns MediaGalleryViewer component
 */
const MediaGalleryViewer: React.FC<MediaGalleryProps> = props => {
    const {
        productImages,
        windowRef,
        hostNames,
        path,
        a11yCarouselNextLabel,
        a11yCarouselPreviousLabel,
        thumbnailImageCount,
        mediaGalleryClick,
        selectedVariant,
        selectedVariantId,
        selectedProductCode,
        buyboxHeight,
        productData,
        isAutomotive,
        fullscreenEnable,
        enablePDPZoomEnhancement,
        watchFullScreenLabel,
        setMagnifierOptions,
        enableFasterPdpLogic,
        mobileStaticImageSize,
        desktopStaticImageSize,
    } = props

    const mediaGalleryRef = useRef(null)
    const [isMobile, setIsMobile] = useState(window.innerWidth <= BREAKPOINTS.tabletMaxWidth)
    const [akamaiViewerRef, setAkamaiViewerRef] = useState<AkamaiViewer>(null)
    const [isAkamaiCarouselInit, setIsAkamaiCarouselInit] = useState(false)
    const [isCarouselGotoTriggered, setIsCarouselGotoTriggered] = useState(false)
    const [isAkamaiImageLoaded, setIsAkamaiImageLoaded] = useState(false)
    const currentImageSrc = useRef(null)
    const {
        thumbnailPrevArrowClass,
        thumbnailNextArrowClass,
        prevArrowIcon,
        nextArrowIcon,
        minimumImageLength,
        minimumThumbnailCount,
        maximumThumbnailCount,
        playButtonIcon,
        thumbnailPlayButtonClass,
        defaultAkamaiTagname,
        mediaGalleryThumbnailImageWidth,
        mediaGalleryViewerClassNameFullscreen,
        defaultStaticImageSize,
    } = mediaGalleryConstants

    const playButton = {
        className: thumbnailPlayButtonClass,
        type: playButtonIcon,
        size: 'md',
    }

    const playButtonHrefLink = getImagePath(playButtonIcon, path)

    const variantTag = selectedProductCode || defaultAkamaiTagname
    const tagValue = isAutomotive ? variantTag : selectedVariant?.toLowerCase() || variantTag
    const checkIfTagValueExist = productImages?.find((image: ProductImages) => image.tags?.includes(tagValue))
    const selectedTagValue = !!checkIfTagValueExist ? tagValue : productData?.code
    const previousSelectedTagValue = usePrevious(selectedTagValue)
    const thumbnailCount = isMobile ? thumbnailImageCountMobile : thumbnailImageCount

    /**
     * function to check whether the device is tablet/mobile/desktop
     */
    const handleResize = (): void => {
        setIsMobile(window.innerWidth <= BREAKPOINTS.tabletMaxWidth)
        setMediaGalleryHeight()
    }

    /**
     * function to play the active video in fullscreen
     */
    const playVideoInFullScreen = (): void => {
        const video: MediaGalleryHTMLVideoElement | null = document.querySelector(
            `.${mediaGalleryViewerClassName} [data-akamai-carousel-item-active] video`,
        )
        const svgElement = document.querySelector(
            `.${mediaGalleryViewerClassName} [data-akamai-carousel-item-active] svg`,
        )
        svgElement?.classList.add('is-hidden')
        video?.setAttribute('controls', 'controls')
        if (video && video.requestFullscreen) {
            void video.requestFullscreen()
            void video.play()
        } else if (video && video.webkitRequestFullScreen) {
            video.webkitRequestFullScreen()
            void video.play()
        } else if (video && video.webkitEnterFullscreen) {
            video.webkitEnterFullscreen()
            void video.play()
        }
    }

    useGlobalResizeEvent(handleResize)

    const prevArrowHrefLink = getImagePath(prevArrowIcon, path)
    const nextArrowHrefLink = getImagePath(nextArrowIcon, path)

    /**
     * function to calculate margin for last thumbnail image in the row
     * @param {string} thumbnailImageMargin
     * @return {number}
     */
    const getLastThumbnailImageMargin = useCallback(
        (thumbnailImageMargin: string): number => {
            const thumbnailMargin = parseFloat(thumbnailImageMargin)

            switch (thumbnailCount) {
                case maximumThumbnailCount:
                    return thumbnailMargin - (maximumThumbnailCount + minimumThumbnailCount) // get last image margin in the row for thumbnail count is 4
                case minimumThumbnailCount:
                    return maximumThumbnailCount * minimumThumbnailCount // get last image margin in the row for thumbnail count is 2
                case minimumImageLength:
                    return minimumThumbnailCount // get last image margin in the row for thumbnail count is 1
                default:
                    return thumbnailMargin / minimumThumbnailCount // get last image margin in the row
            }
        },
        [maximumThumbnailCount, minimumImageLength, minimumThumbnailCount, thumbnailCount],
    )

    /**
     * function to add vide play  buttons
     * @param {Element} elementRef
     * @param {string} position
     */
    const addVideoPlayButton = useCallback(
        (elementRef: Element, position: string): void => {
            insertDynamicPlayButtons(
                Object.assign(playButton, {
                    elementRef,
                    position: position as InsertPosition,
                    iconHrefLink: playButtonHrefLink,
                    buttonId: 'play-button',
                }),
            )
        },
        [playButton, playButtonHrefLink],
    )

    /**
     * function to insert dynamic play buttons
     * @param {DynamicButton} dynamicButton
     */
    const insertDynamicPlayButtons = (dynamicButton: DynamicButton): void => {
        const { elementRef, position, className, type, iconHrefLink, size } = dynamicButton
        elementRef.insertAdjacentHTML(
            position,
            `<svg class='${className} ${PREFIX}-icon ${PREFIX}-icon-${type} ${PREFIX}-icon--${size}' alt='Play video'>
            <use href=${iconHrefLink} />
            </svg>`,
        )
    }

    /**
     * function show play icon in video
     * @param { NodeListOf<Element> } videoDiv
     * @param { Element } mainProductVideo
     */
    const insertPlayButtonToVideo = useCallback(() => {
        const thumbnailDiv = document.querySelectorAll(
            `.${mediaGalleryViewerClassName} [data-akamai-viewer-tag='${selectedTagValue}'] .snapper_nav .snapper_nav_inner a`,
        )
        thumbnailDiv &&
            thumbnailDiv.forEach(video => {
                if (video.getAttribute('data-akamai-carousel-thumb-type') === MediaTypeValue.video) {
                    addVideoPlayButton(video, 'beforeend')
                }
            })
    }, [selectedTagValue, addVideoPlayButton])

    /**
     * function to add thumbnail navigation arrows
     * @param {Element} thumbnailDiv
     */
    const addThumbnailNavigationArrows = useCallback(
        (thumbnailDiv: Element) => {
            const thumbnailImages = thumbnailDiv?.querySelectorAll(`.snapper_nav_inner a`)
            const isThumbnailArrowsExist = thumbnailDiv?.querySelector(`.${thumbnailPrevArrowClass}`)
            const variantSelected = selectedVariantId || selectedProductCode
            thumbnailDiv &&
                thumbnailImages.length > thumbnailCount &&
                variantSelected &&
                !isThumbnailArrowsExist &&
                addNavigationArrowButtons(
                    thumbnailDiv,
                    `thumb-prev-arrow-id-${variantSelected}`,
                    `thumb-next-arrow-id-${variantSelected}`,
                    prevArrowHrefLink,
                    nextArrowHrefLink,
                    a11yCarouselPreviousLabel,
                    a11yCarouselNextLabel,
                    thumbnailPrevArrowClass,
                    thumbnailNextArrowClass,
                )
        },
        [
            a11yCarouselNextLabel,
            a11yCarouselPreviousLabel,
            nextArrowHrefLink,
            prevArrowHrefLink,
            selectedProductCode,
            selectedVariantId,
            thumbnailCount,
            thumbnailNextArrowClass,
            thumbnailPrevArrowClass,
        ],
    )

    /**
     * function to add event listener on navigation arrows
     * @param {Element} thumbnailDiv
     */
    const addListenerToNavigationArrows = useCallback(
        (thumbnailDiv: Element): void => {
            const mediaGalleryFullscreenEle = document.querySelector(`.${PREFIX}-media-gallery-viewer--fullscreen`)
            const modalClassName = mediaGalleryFullscreenEle
                ? mediaGalleryViewerClassNameFullscreen
                : mediaGalleryViewerClassName
            const prevArrowBtn =
                thumbnailDiv &&
                thumbnailDiv.querySelector(`#thumb-prev-arrow-id-${selectedVariantId || selectedProductCode}`)
            if (prevArrowBtn && prevArrowBtn.getAttribute('listener') !== 'true') {
                prevArrowBtn.addEventListener('click', () =>
                    thumbnailArrowClick(true, thumbnailCount, modalClassName, mediaGalleryClick),
                )
                prevArrowBtn.setAttribute('listener', 'true')
            }
            const nextArrowBtn =
                thumbnailDiv &&
                thumbnailDiv.querySelector(`#thumb-next-arrow-id-${selectedVariantId || selectedProductCode}`)
            if (nextArrowBtn && nextArrowBtn.getAttribute('listener') !== 'true') {
                nextArrowBtn.addEventListener('click', () =>
                    thumbnailArrowClick(false, thumbnailCount, modalClassName, mediaGalleryClick),
                )
                nextArrowBtn.setAttribute('listener', 'true')
            }
        },
        [
            mediaGalleryClick,
            mediaGalleryViewerClassNameFullscreen,
            selectedProductCode,
            selectedVariantId,
            thumbnailCount,
        ],
    )

    /**
     * function to calculate and set the thumbnail height only for desktop/widescreen
     * @param {Element} thumbnailDiv
     */
    const setThumbnailHeight = useCallback(
        (thumbnailDiv: Element): void => {
            const snapperNavInnersFocused = document.querySelector(
                `.${mediaGalleryViewerClassName} .focused .snapper_nav_inner a`,
            )
            const thumbnailInnerDiv =
                snapperNavInnersFocused ||
                document.querySelector(`.${mediaGalleryViewerClassName} .snapper_nav_inner a`)
            const thumbnailImageWidth =
                (thumbnailInnerDiv && (thumbnailInnerDiv as HTMLElement).offsetWidth) || mediaGalleryThumbnailImageWidth // get individual thumbnail image width
            const thumbnailImageMargin =
                thumbnailInnerDiv && window.getComputedStyle(thumbnailInnerDiv).getPropertyValue('margin-right') // get individual thumbnail image margin

            const thumbnailNavWidth =
                (thumbnailCount - minimumImageLength) * (parseFloat(thumbnailImageMargin) + thumbnailImageWidth) // get width & margin of thumbnailImageCount -1

            const lastImageMargin = getLastThumbnailImageMargin(thumbnailImageMargin)

            const navInner = thumbnailDiv && thumbnailDiv.querySelector('.snapper_nav_inner')
            navInner &&
                navInner.setAttribute('style', `width:${thumbnailNavWidth + lastImageMargin + thumbnailImageWidth}px`) // thumbnail navigation width is sum of width & margin of thumbnailImageCount -1 and last image width & margin
        },
        [getLastThumbnailImageMargin, mediaGalleryThumbnailImageWidth, minimumImageLength, thumbnailCount],
    )

    /**
     * function to add carousel arrows to Thumbnails
     */
    const addThumbnailArrows = useCallback(() => {
        const snapperNavEle =
            selectedVariant &&
            document.querySelector(
                `.${mediaGalleryViewerClassName} [data-akamai-viewer-tag='${selectedTagValue}'] .snapper_nav`,
            )
        const thumbnailDiv = snapperNavEle
            ? snapperNavEle
            : document.querySelector(`.${mediaGalleryViewerClassName} .snapper_nav`)
        const isDesktop = window.innerWidth > BREAKPOINTS.tabletMaxWidth

        // Calculate and set the thumbnail height only for desktop/widescreen
        if (isDesktop) {
            setThumbnailHeight(thumbnailDiv)
        } else {
            setThumbnailHeightMobile(thumbnailDiv)
        }
        // Add thumbnail navigation arrows
        addThumbnailNavigationArrows(thumbnailDiv)
        // Add event listeners to thumbnail arrows
        addListenerToNavigationArrows(thumbnailDiv)
    }, [
        addListenerToNavigationArrows,
        addThumbnailNavigationArrows,
        selectedTagValue,
        selectedVariant,
        setThumbnailHeight,
    ])

    /**
     * function to create media gallery viewer
     * @param {RefObject<HTMLElement>} elementRef
     * @param {boolean} isModalGallery
     */
    const createMediaGalleryViewer = useCallback(
        (elementRef: RefObject<HTMLElement>): void => {
            const widthParam = 'imdensity=1&imwidth'
            const allWidths = [
                mobileStaticImageSize || defaultStaticImageSize,
                desktopStaticImageSize || defaultStaticImageSize,
            ]
            const magnifierOption = {
                button: true,
                enabled: enablePDPZoomEnhancement,
                placement: 'inline',
                image: {
                    widths: allWidths,
                    widthParam: widthParam,
                },
            }
            if (elementRef.current) {
                magnifierOption.image['sizes'] = `${elementRef.current.offsetWidth}px`
            }
            const zoomEnhancement = {
                hoverZoomWithoutClick: getDeviceType() === deviceTypes.DESKTOP ? false : true,
                fullscreen: {
                    enabled: fullscreenEnable,
                },
            }
            const breakpointOptions = {
                [BREAKPOINTS.tabletMaxWidth]: {
                    magnifier: {
                        hoverZoomWithoutClick: false,
                    },
                },
            }

            const magnifierOptions = enablePDPZoomEnhancement
                ? { ...magnifierOption, ...zoomEnhancement }
                : magnifierOption

            const Akamai = customizeAkamai(windowRef.Akamai)

            const akamaiViewer = new Akamai.Viewer(elementRef.current, {
                items: {
                    hostnames: hostNames,
                    data: productImages,
                    limit: mediaGalleryConstants.limitThreshold,
                },
                breakpoints: breakpointOptions,
                carousel: {
                    arrows: true,
                    thumbnail: {
                        placement: 'bottom',
                        policy: 'thumbnail',
                    },
                    aspectratio: 66.9,
                    removeOnTagSwitch: true,
                    images: {
                        widths: allWidths,
                        widthParam: widthParam,
                    },
                },
                magnifier: magnifierOptions,
                video: {
                    autoplay: !enablePDPZoomEnhancement,
                },
            })

            setAkamaiViewerRef(akamaiViewer)
            if (setMagnifierOptions && typeof setMagnifierOptions === 'function') {
                setMagnifierOptions(magnifierOptions as MagnifierOptions)
            }
        },
        [
            enablePDPZoomEnhancement,
            fullscreenEnable,
            windowRef.Akamai,
            hostNames,
            productImages,
            setMagnifierOptions,
            defaultStaticImageSize,
            mobileStaticImageSize,
            desktopStaticImageSize,
        ],
    )

    /**
     * Function to add skeleton class if no media set present
     * @param {string} mediaClassName
     */
    const showSkeleton = useCallback(
        (mediaClassName: string): void => {
            if (checkIfMediaSetExist(selectedTagValue, selectedProductCode, isAutomotive, productImages, productData)) {
                const tagEle = document.querySelector(
                    `.${mediaClassName} [data-akamai-viewer-tag='${selectedTagValue}']`,
                )
                tagEle?.classList?.add(`${PREFIX}-skeleton`, `${PREFIX}-media-gallery__skeleton`)
            }
        },
        [isAutomotive, productData, productImages, selectedProductCode, selectedTagValue],
    )

    /**
     * function to set akamai switch tag viewer
     * @param {string} mediaClassName
     * @param {any} viewerRef
     */

    const setSwitchTag = useCallback(
        (mediaClassName: string, viewerRef: AkamaiViewer): void => {
            const tagAlreadyExist = document.querySelector(
                `.${mediaClassName} [data-akamai-viewer-tag='${selectedTagValue}']`,
            )
            if (tagAlreadyExist) {
                document.querySelectorAll(`.${mediaClassName} [data-akamai-viewer-tag]`).forEach(viewerTag => {
                    viewerTag.setAttribute('style', 'display:none')
                })
                tagAlreadyExist.setAttribute('style', 'display:block')
            } else {
                selectedTagValue &&
                    selectedTagValue !== previousSelectedTagValue &&
                    viewerRef.switchTag(selectedTagValue)

                const tagExist = document.querySelector(
                    `.${mediaClassName} [data-akamai-viewer-tag='${selectedTagValue}']`,
                )
                const filteredImagesArr = productImages?.filter((image: ProductImages) =>
                    image.tags?.includes(selectedTagValue),
                )
                if (tagExist) {
                    document.querySelectorAll(`.${mediaClassName} [data-akamai-viewer-tag]`).forEach(viewerTag => {
                        viewerTag.setAttribute('style', 'display:none')
                    })
                    tagExist?.setAttribute('style', 'display:block')
                }
                filteredImagesArr.length > thumbnailCount && addThumbnailArrows()
                // add listener to product image, on image click should open the image in modal
                const mainProductImage = document.querySelector(
                    `.${mediaClassName} [data-akamai-viewer-tag='${selectedTagValue}'] .snapper_items`,
                )
                if (mainProductImage) {
                    insertPlayButtonToVideo()
                }
            }
            // Add skeleton class if no media set present
            showSkeleton(mediaClassName)
        },
        [
            addThumbnailArrows,
            insertPlayButtonToVideo,
            productImages,
            selectedTagValue,
            previousSelectedTagValue,
            showSkeleton,
            thumbnailCount,
        ],
    )

    // For Gen merch pdp media changes are dependent on selectedVariant value (eg: black, 12cm etc.) for automotive can dependent on pcode, skucode or selected variant
    const isVariantSelected = isAutomotive ? selectedProductCode || selectedVariant : !!selectedVariant

    const previousSelectedVariant = usePrevious(selectedVariant)
    const previousAkamaiViewerRef = usePrevious(akamaiViewerRef)
    const previousSelectedProductCode = usePrevious(selectedProductCode)
    const previousProductImages = usePrevious(productImages)
    const [isVideoActive, setIsVideoActive] = useState(false)

    /**
     * function to insert dynamic CTA
     */
    const insertWatchFullScreenCTA = useCallback((): void => {
        const mediaContainer = document.querySelector(`.${mediaGalleryViewerClassName} .snapper_nextprev_contain`)
        mediaContainer?.insertAdjacentHTML(
            'afterend',
            `<div id ="${mediaGalleryViewerClassName}-btn" class="${mediaGalleryViewerClassName}-btn"><button
        class="${mediaGalleryViewerClassName}-btn-cta"
        type="button">            
        ${watchFullScreenLabel}
    </button></div>`,
        )
        const fullScreenCTA = document.querySelector(`.${mediaGalleryViewerClassName}-btn`)
        fullScreenCTA?.classList.add('hide')
        fullScreenCTA?.addEventListener('click', () => playVideoInFullScreen())
        handleCarouselEvent()
    }, [watchFullScreenLabel])

    /**
     * function to handle carousel event on media selection
     */
    const handleCarouselEvent = (): void => {
        setIsCarouselGotoTriggered(true)
        const video: HTMLVideoElement = document.querySelector(
            `.${mediaGalleryViewerClassName} [data-akamai-carousel-item-active] video`,
        )
        video ? setIsVideoActive(true) : setIsVideoActive(false)
    }

    /**
     * Use effect on akamai carousel init
     */
    useEffect(() => {
        if (isAkamaiCarouselInit) {
            insertWatchFullScreenCTA()
            setIsAkamaiCarouselInit(false)
        }
    }, [insertWatchFullScreenCTA, isAkamaiCarouselInit])

    /**
     * Use effect to set the class names to show/hide watchFullScreen CTA
     */
    useEffect(() => {
        const fullScreenCTA = document.getElementById(`${mediaGalleryViewerClassName}-btn`)
        isVideoActive ? fullScreenCTA?.classList.remove('hide') : fullScreenCTA?.classList.add('hide')
        if (isCarouselGotoTriggered) setIsCarouselGotoTriggered(false)
    }, [isVideoActive, isCarouselGotoTriggered])

    /**
     * Use effect to add akamai carousel event listeners
     */
    useEffect(() => {
        windowRef.addEventListener('akamai-carousel-init', () => {
            setIsAkamaiCarouselInit(true)
        })
        windowRef.addEventListener('akamai-carousel-goto', handleCarouselEvent, false)
    }, [windowRef])

    useEffect(() => {
        if (
            previousSelectedVariant !== selectedVariant ||
            previousAkamaiViewerRef !== akamaiViewerRef ||
            previousSelectedProductCode !== selectedProductCode ||
            previousProductImages !== productImages
        ) {
            if (akamaiViewerRef && productImages && !!isVariantSelected) {
                setSwitchTag(mediaGalleryViewerClassName, akamaiViewerRef)
            } else {
                showSkeleton(mediaGalleryViewerClassName)
            }
        }
    }, [
        selectedVariant,
        akamaiViewerRef,
        selectedProductCode,
        productImages,
        previousSelectedVariant,
        previousAkamaiViewerRef,
        previousSelectedProductCode,
        previousProductImages,
        isVariantSelected,
        setSwitchTag,
        showSkeleton,
    ])

    /**
     * function to trigger analytics while swiping the product images
     */
    const swipeCarouselCallback = useCallback(() => {
        // Triggered when the carousel has completed it transition to a new index due to user interaction or a call to
        windowRef.addEventListener('akamai-carousel-goto', event => {
            const mediaGalleryId = (event?.target as unknown as HTMLElement)?.offsetParent?.id
            const currentImageSource = mediaGalleryId
                ? (document.querySelector(
                      `.${mediaGalleryId} .snapper_nav .snapper_nav_inner .snapper_nav_item-selected img`,
                  )?.['currentSrc'] as string)
                : ''

            if (
                (mediaGalleryId === `${mediaGalleryViewerClassName}` ||
                    mediaGalleryId === `${mediaGalleryViewerClassName}--fullscreen`) &&
                currentImageSource !== currentImageSrc.current
            ) {
                currentImageSrc.current = currentImageSource
                mediaGalleryClick(mediaGalleryId === `${mediaGalleryViewerClassName}--fullscreen`)
            }
        })
    }, [mediaGalleryClick, windowRef])

    // Element Reference
    const parentRef = useRef<HTMLDivElement>(null)

    const setMediaGalleryHeight = useCallback(() => {
        // Get the parent container
        const parent = parentRef.current?.parentElement?.parentElement?.parentElement
        const isMediaGalleryContainer = parent?.classList.contains('mediagallery') ?? false
        const isDesktop = document.body.clientWidth > BREAKPOINTS.tabletMaxWidth
        // validate that it's the mediagallery class div
        if (!isDesktop && isMediaGalleryContainer) {
            parent.style.minHeight = ''
        } else if (isDesktop && buyboxHeight && isMediaGalleryContainer) {
            // Set the new height
            parent.style.minHeight = `${buyboxHeight}px`
        }
    }, [buyboxHeight])

    // Check on buybox height changes
    useEffect(() => {
        setMediaGalleryHeight()
    }, [buyboxHeight, setMediaGalleryHeight])

    useEffect(() => {
        if (productImages !== previousProductImages && isArrayNotEmpty(productImages) && windowRef.Akamai) {
            createMediaGalleryViewer(mediaGalleryRef)

            const mainProductImage = document.querySelector(`.snapper_items`)
            if (mainProductImage) {
                insertPlayButtonToVideo()
            }
            // To add or remove ZoomIn & zoomOut icons based on featureToggle
            const snapperPane = document.querySelector(`.snapper_pane`)
            if (!enablePDPZoomEnhancement) {
                snapperPane.classList.add('enlarge_pane__feature')
            }

            swipeCarouselCallback()

            // Show thumbnail arrows only if the product images count is greater than thumbnailImageCount
            if (productImages.length > thumbnailCount) {
                addThumbnailArrows()
            }
        }
    }, [
        addThumbnailArrows,
        createMediaGalleryViewer,
        insertPlayButtonToVideo,
        previousProductImages,
        productImages,
        swipeCarouselCallback,
        thumbnailCount,
        windowRef.Akamai,
        enablePDPZoomEnhancement,
        fullscreenEnable,
    ])

    useEffect(() => {
        if (enableFasterPdpLogic) {
            windowRef.addEventListener(AKAMAI_IMAGE_LOAD_EVENT, handleAkamaiImageLoadEvent, { once: true })
        }
    }, [enableFasterPdpLogic, windowRef])

    const handleAkamaiImageLoadEvent = (): void => {
        setIsAkamaiImageLoaded(true)
    }

    useEffect(() => {
        if (enableFasterPdpLogic) {
            const akamaiViewerElement = document.getElementsByClassName(mediaGalleryViewerClassName)[0]
            const mediaGalleryElement = document.getElementsByClassName(mediaGalleryClassName)[0]
            const staticImageElement = document.getElementsByClassName(staticImageContainerClassName)[0]

            akamaiViewerElement.classList.add(hideAkamaiViewerImageClassName)

            if (isAkamaiImageLoaded) {
                staticImageElement.parentNode.removeChild(staticImageElement)
                akamaiViewerElement.classList.remove(hideAkamaiViewerImageClassName)
                mediaGalleryElement.classList.remove(mediaGalleryNoPaddingClassName)
            }
        }
    }, [isAkamaiImageLoaded, enableFasterPdpLogic])

    return (
        <div
            className={`${PREFIX}-media-gallery ${enableFasterPdpLogic ? mediaGalleryNoPaddingClassName : ``}`}
            data-testid="media-gallery"
            ref={parentRef}>
            <div
                data-akamai-viewer
                ref={mediaGalleryRef}
                className={`${mediaGalleryViewerClassName}`}
                id={`${mediaGalleryViewerClassName}`}
            />
        </div>
    )
}

MediaGalleryViewer.propTypes = {
    productImages: PropTypes.array,
    windowRef: PropTypes.any,
    hostNames: PropTypes.array,
    path: PropTypes.string,
    a11yCarouselNextLabel: PropTypes.string,
    a11yCarouselPreviousLabel: PropTypes.string,
    thumbnailImageCount: PropTypes.number,
    mediaGalleryClick: PropTypes.func,
    selectedVariant: PropTypes.string,
    selectedVariantId: PropTypes.number,
    selectedProductCode: PropTypes.string,
    buyboxHeight: PropTypes.number,
    productData: PropTypes.any,
    isAutomotive: PropTypes.bool,
    fullscreenEnable: PropTypes.bool,
    enablePDPZoomEnhancement: PropTypes.bool,
    watchFullScreenLabel: PropTypes.string,
    enableFasterPdpLogic: PropTypes.bool,
}

export default MediaGalleryViewer
