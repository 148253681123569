import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import SideNavigation from './SideNavigation/SideNavigation'
import { SideNavigationProps } from './SideNavigation.type'
import { maximumCount } from '../WishlistAndCart/WishlistAndCart.constant'
import checkNestedProps from '../../utils/checkNestedProps'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { commonContentSelector } from '../../redux/selectors/commonContent.selectors'
import { wishlistItemsSelector } from '../../redux/selectors/wishlist.selectors'
import { pencilBannerPropSelector, secondaryNavPropsSelector } from '../../redux/selectors/headerProps.selectors'

/**
 * SideNavigation component
 * @param {SideNavigationProps} props
 * @return {JSX.Element} returns SideNavigationComponent
 */
const SideNavigationComponent: React.FC<SideNavigationProps> = () => {
    const [wishlistCount, setWishlistCount] = useState(0)
    const pencilBannerProp = useSelector(pencilBannerPropSelector)
    const secondaryNavProps = useSelector(secondaryNavPropsSelector)
    const wishlistItems = useSelector(wishlistItemsSelector)
    const { commonContentAvailable } = useSelector(commonContentSelector)

    const closeIconLabel = checkNestedProps(commonContentAvailable, 'accessibility', 'a11yCloseIconLabel') as string
    const a11yMenuIconLabel = checkNestedProps(commonContentAvailable, 'accessibility', 'a11yHamburgerIcon') as string
    const wishlistUrl = (checkNestedProps(commonContentAvailable, 'globalLinks', 'wishlistPageLink') as string) || ''
    const a11yWishlist = (checkNestedProps(commonContentAvailable, 'wishlist', 'a11yWishlistIcon') as string) || ''
    /**
     * useEffect to set count for entries in Wishlist
     */
    useEffect(() => {
        if (checkDataLength(wishlistItems) && Array.isArray(wishlistItems.products)) {
            setWishlistCount(wishlistItems.products.length)
        }
    }, [wishlistItems])

    return (
        <SideNavigation
            wishlistUrl={wishlistUrl}
            a11yWishlist={a11yWishlist}
            wishlistCount={wishlistCount}
            maximumCount={maximumCount}
            a11yCloseIconLabel={closeIconLabel}
            a11yMenuIconLabel={a11yMenuIconLabel}
            pencilBannerProps={pencilBannerProp}
            secondaryNavProps={secondaryNavProps}
        />
    )
}

export default SideNavigationComponent
