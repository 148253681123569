import { analyticsAttributes } from '../../../globalConstants/analyticsParams.constant'
import { getProvider } from '../../../analytics/providers/index'

/**
 * function to fire analytics when postal code updated successfully
 * @param {string} label
 */
export const megaNavClicked = (label: string): void => {
    const {
        event: { interaction },
        eventParameters: {
            action: { megaNav },
            category: { header: HeaderSection },
            location: { header },
        },
    } = analyticsAttributes
    getProvider().push({
        event: interaction,
        eventParameters: {
            action: megaNav,
            category: HeaderSection,
            label,
            value: '',
            location: header,
        },
    })
}
