import { AddVehicleDataResponse } from '../../components/BuyBox/BuyBox.type'
import { DropdownOptionsState, DropdownType } from '../models/vehicleDropdowns.types'
import { vehiclesDataInterface } from '../models/vehiclesData.interface'
import { VehicleTypeIds } from '../../components/Vehicles/Vehicles.constant'
import { uniqueStringsUnion } from './uniqueStringsUnion'
import { generateDropDownById } from './generateDropDownById'
import { magicNumber } from '@nl/lib'

/**
 * Adapt response and localization key to full dropdown model
 * @param {AddVehicleDataResponse} response - APi response
 * @param {vehiclesDataInterface} state - current state
 * @param {Record<string, string>} labels - localized labels{Record<string, string>} labels
 * @return {DropdownType[]}
 */
export const adaptVehicleResponseToDropdowns = (
    response: AddVehicleDataResponse,
    state: vehiclesDataInterface,
    labels?: Record<string, string>,
): DropdownType[] => {
    const valueMap = Object.fromEntries(response.attributes.known.map(({ id, value }) => [id, value])) as Record<
        string,
        string
    >
    const suggestionsMap = Object.fromEntries(
        response.attributes.suggested.map(({ id, values }) => [id, values]),
    ) as Record<string, string[]>

    const attributes = suggestionsMap?.attributes || []
    /*
     * Dropdown ids with filtered out unknown and un-suggested param.
     * baseDropdownsKeys is always kept and used as default if dropdown isn't created
     */
    const ids = uniqueStringsUnion(
        [VehicleTypeIds.vehicleType],
        state.baseDropdownsKeys,
        state.dropdowns?.map(drop => drop.id).filter(dropId => valueMap[dropId] || suggestionsMap[dropId]) || [],
        Object.keys(suggestionsMap).filter(item => item !== 'attributes'),
        attributes,
        Object.keys(valueMap),
    )

    const vehicleNumericalIds = labels?.['vehicleConfigIds']?.split(',') || []
    // only options could saved from previos dropdown state.
    const dropdowns = ids.map(
        generateDropDownById(state, valueMap, suggestionsMap, labels || {}, vehicleNumericalIds, attributes),
    )

    dropdowns.forEach(dropdown => {
        dropdown.value = valueMap[dropdown.id]
        if (dropdown.options) {
            const isIdsOptions = vehicleNumericalIds.includes(dropdown.id)
            if (!dropdown.value && dropdown.options.length === magicNumber.ONE) {
                const [firstOption] = dropdown.options
                dropdown.value = isIdsOptions ? String(firstOption.id) : firstOption.label
                dropdown.optionsState = DropdownOptionsState.UPDATED
            }

            dropdown.options.forEach(item => {
                const optionValue = isIdsOptions ? String(item.id) : item.label
                item.selected = optionValue === dropdown.value
            })
        } else {
            dropdown.options = []
        }
    })

    return dropdowns
}
