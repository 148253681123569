import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    getOrderConfirmationDetails,
    placeOrderFromConfirmationPage,
} from '../../../redux/actions/orderConfirmation.action'
import checkNestedProps from '../../../utils/checkNestedProps'
import getQueryString from '../../../utils/getQueryString'
import { checkDataLength } from '../../Accounts/Addresses/checkDataLength'
import { paymentInformationConst } from '../../Checkout/PaymentInformation/cardTypeConstants'
import { analyticsInteraction } from '../../../redux/actions/pageAnalytics.action'
import { analyticsAttributes } from '../../../globalConstants/analyticsParams.constant'
import { thirdPartyOrderConfirmationAnalytics } from '../../../analytics/components/orderConfirmationAnalytics'
import { commonContentSelector } from '../../../redux/selectors/commonContent.selectors'
import { IFeatureFlag } from '../../../redux/models/commonContent.interface'
import { isAuthFlowExecutedSelector } from '../../../redux/selectors/userProfile.selectors'
import { placeOrderConfirmationErrorSelector } from '../../../redux/selectors/orderItemsDetails.selectors'
import InitTruefitOrderReport from './InitTruefitOrderReport'
import { orderIdParameter } from '../OrderConfirmation.constant'

/**
 * OrderConfirmationPage to invoke order confirmation api
 * @return {JSX.Element}
 */
const OrderConfirmationPageInit: React.FC = () => {
    const dispatch = useDispatch()
    const placeOrderConfirmationError = useSelector(placeOrderConfirmationErrorSelector)
    const { commonContentAvailable } = useSelector(commonContentSelector)
    const isAuthFlowExecuted = useSelector(isAuthFlowExecutedSelector)

    const [featureFlagContent, setFeatureFlagContent] = useState(
        commonContentAvailable?.featureFlag || ({} as typeof commonContentAvailable.featureFlag),
    )

    useEffect(() => {
        commonContentAvailable?.featureFlag && setFeatureFlagContent(commonContentAvailable.featureFlag)
    }, [commonContentAvailable])

    const { enableTmx, enableSignifyd }: IFeatureFlag = featureFlagContent

    useEffect(() => {
        if (isAuthFlowExecuted) {
            const {
                event: { interaction },
                eventParameters: {
                    action: { thirdPartyPaymentCompleted },
                    category: { payment3rdParty },
                    labels: { masterpass },
                },
            } = analyticsAttributes
            const cartIdAvailable = getQueryString(window.location.search, 'cartId')
            if (cartIdAvailable) {
                thirdPartyOrderConfirmationAnalytics()
                dispatch(analyticsInteraction(masterpass, '', interaction, thirdPartyPaymentCompleted, payment3rdParty))
                // OCCP-17325: masterpass=true if redirected from masterpass flow. if masterpass is used, send isNewCard=true when invoking placeOrder api.
                //             at the moment, only masterpass payment flow results in a redirect, so the isNewCard flag takes the same value as masterpassUsed (this may change in the future)
                const masterpassUsed = 'true' === getQueryString(window.location.search, 'masterpass')
                dispatch(
                    placeOrderFromConfirmationPage(cartIdAvailable, masterpassUsed, true, {
                        enableTmx: enableTmx,
                        enableSignifyd: enableSignifyd,
                    }),
                )
            } else {
                const orderId = getQueryString(window.location.search, orderIdParameter)
                dispatch(getOrderConfirmationDetails(orderId))
            }
        }
    }, [isAuthFlowExecuted, dispatch, enableTmx, enableSignifyd])

    useEffect(() => {
        if (checkDataLength(placeOrderConfirmationError)) {
            const placeOrderConfirmationErrorCode: string = placeOrderConfirmationError.errCode
            window.location.href = encodeURI(
                `${checkNestedProps(commonContentAvailable, 'globalLinks', 'checkoutPageLink') as string}?${
                    paymentInformationConst.thirdPartyPaymentErrorKey
                }=${placeOrderConfirmationErrorCode}`,
            )
        }
    }, [placeOrderConfirmationError, commonContentAvailable])

    return <InitTruefitOrderReport />
}

export default OrderConfirmationPageInit
