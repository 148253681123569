import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { isArrayNotEmpty, MegaNavigation } from '@nl/lib'
import { fetchHeaderNavigationCategories, setMegaNavPayload } from '../../redux/actions/secondaryNavigation.action'
import { NavigationCategory, SecondaryNavigationWrapperProps } from './SecondaryNavigationWrapper.type'
import SecondaryNavigation from './SecondaryNavigation/SecondaryNavigation'
import { RootState } from '../../redux/reducers'
import { MegaNavConst } from './SecondaryNavigation.constants'
import checkNestedProps from '../../utils/checkNestedProps'
import { InitialCategoryPayloadType } from '../../redux/models/secondaryNavigation.interface'
import HeaderNavigationHelper from '../../helpers/headerNavigation.helper'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { addSecondaryNavigation } from '../../redux/actions/headerProps.action'
import { megaNavClicked } from '../../analytics/components/MegaNavigation/megaNavigation.Analytics'
import { usePageAnalytics } from '../../analytics/hooks/usePageAnalytics'
import AutoServiceButton from '../AutoServiceButton'
import { commonContentSelector } from '../../redux/selectors/commonContent.selectors'
import { secondaryNavPropsSelector } from '../../redux/selectors/headerProps.selectors'
import SecondaryNavigationMenu from './SecondaryNavigationMenu/SecondaryNavigationMenu'

/**
 * Search Title component
 * @return {JSX.Element} returns Search Title component
 */
const SecondaryNavigationWrapper: React.FC<SecondaryNavigationWrapperProps> = ({ ...props }): JSX.Element => {
    // To enable common page level analytics
    usePageAnalytics()
    const { shopAllLabel, isSideNav = false, secondaryNavAriaLabel, isNewMegaNav, secondaryNavMenuLabel } = props
    const dispatch = useDispatch()
    const [showData, setShowData] = useState<null | NavigationCategory | InitialCategoryPayloadType[]>(null)
    const initialBackSectionRef = useRef<InitialCategoryPayloadType[]>(null)
    const L2BackSectionRef = useRef<null | NavigationCategory>(null)
    const initialViewSectionRef = useRef(MegaNavConst.L1level)
    const isUpdateCategoryData = useRef(false)
    // TODO once the mapping need to be used can be un commented
    const secondaryNavigationData = useSelector((state: RootState) => state.secondaryNavigation)
    const secondaryNavProps = useSelector(secondaryNavPropsSelector)
    const { commonContentAvailable: commonContent } = useSelector(commonContentSelector)

    const backToLabel = checkNestedProps(commonContent, 'breadcrumb', 'backToLabel') as string
    const backToHomeLabel = checkNestedProps(commonContent, 'breadcrumb', 'backToHomeLabel') as string
    const { commonContentAvailable } = useSelector(commonContentSelector)
    const autoAppointmentPagePath = checkNestedProps(
        commonContentAvailable,
        'autoAppointment',
        'autoAppointmentPagePath',
    ) as string

    // TODO once the mapping need to be used can be un commented

    // Update redux with AEM props
    useEffect(() => {
        if (!secondaryNavigationData?.length && backToLabel && backToHomeLabel) {
            dispatch(
                setMegaNavPayload(
                    HeaderNavigationHelper.transformAemResponse(
                        props,
                        shopAllLabel,
                        backToLabel,
                        backToHomeLabel,
                        autoAppointmentPagePath,
                    ),
                ),
            )
            isUpdateCategoryData.current = true
        }
    }, [dispatch, secondaryNavigationData, shopAllLabel, backToLabel, backToHomeLabel, props, autoAppointmentPagePath])

    useEffect(() => {
        !checkDataLength(secondaryNavProps as unknown as Record<string, unknown>) &&
            dispatch(addSecondaryNavigation(props))
    }, [props, dispatch, secondaryNavProps])

    // Fetch categories data from CDS
    useEffect(() => {
        if (isArrayNotEmpty(secondaryNavigationData) && isUpdateCategoryData.current) {
            dispatch(fetchHeaderNavigationCategories(shopAllLabel))
            isUpdateCategoryData.current = false
        }
        // common content need for link mapping
    }, [dispatch, shopAllLabel, secondaryNavigationData])

    const updateCurrentViewId = (currentView: string) => {
        if (currentView === MegaNavConst.L1level) {
            initialViewSectionRef.current = MegaNavConst.L2level
        } else if (currentView === MegaNavConst.L2level) {
            initialViewSectionRef.current = MegaNavConst.L3level
        }
    }

    /**
     * click handler for category click
     * @param {NavigationCategory} data
     * @param {string} currentView
     */
    const categoryClickHandler = (data: NavigationCategory, currentView: string) => {
        data.categories?.forEach((category: NavigationCategory) => {
            if (category.subcategories) {
                category.categories = category.subcategories
            }
        })
        L2BackSectionRef.current = currentView === MegaNavConst.L2level ? (showData as NavigationCategory) : null
        updateCurrentViewId(currentView)
        data && setShowData(data as unknown as NavigationCategory)
    }

    const backButtonHandler = () => {
        if (initialViewSectionRef.current === MegaNavConst.L3level) {
            initialViewSectionRef.current = MegaNavConst.L2level
            setShowData(L2BackSectionRef.current)
        } else if (initialViewSectionRef.current === MegaNavConst.L2level) {
            initialViewSectionRef.current = MegaNavConst.L1level
            setShowData(initialBackSectionRef.current)
        }
    }

    const renderAutoAppointmentButton = (): JSX.Element => {
        return <AutoServiceButton />
    }

    const renderMegaNavigation = (): JSX.Element => {
        return isArrayNotEmpty(secondaryNavigationData) ? (
            <MegaNavigation
                categoryData={showData || secondaryNavigationData}
                onCategoryClicked={categoryClickHandler}
                onBackLabelClicked={backButtonHandler}
                currentView={initialViewSectionRef.current}
                backToLabel={backToLabel}
                shopAllLabel={shopAllLabel}
                megaNavClicked={megaNavClicked}
                autoServiceButton={renderAutoAppointmentButton}
            />
        ) : (
            <></>
        )
    }

    return !isSideNav ? (
        !isNewMegaNav ? (
            <SecondaryNavigation
                categoriesList={secondaryNavigationData}
                secondaryNavAriaLabel={secondaryNavAriaLabel}
            />
        ) : (
            <SecondaryNavigationMenu
                categoryData={secondaryNavigationData}
                secondaryNavAriaLabel={secondaryNavAriaLabel}
                shopAllLabel={shopAllLabel}
                secondaryNavMenuLabel={secondaryNavMenuLabel}
            />
        )
    ) : (
        renderMegaNavigation()
    )
}
SecondaryNavigationWrapper.propTypes = {
    categoriesL1: PropTypes.array.isRequired,
    additionalLinksMegaNav: PropTypes.array,
    additionalLinksNoMegaNav: PropTypes.array.isRequired,
    shopAllLabel: PropTypes.string.isRequired,
    saleLabel: PropTypes.string.isRequired,
    clearanceLabel: PropTypes.string.isRequired,
    newArrivalsLabel: PropTypes.string.isRequired,
    megaNavCtaLabel: PropTypes.string.isRequired,
    isSideNav: PropTypes.bool,
    needServiceButton: PropTypes.bool,
    autoServiceLabel: PropTypes.string.isRequired,
    bookAppointmentLabel: PropTypes.string.isRequired,
    secondaryNavAriaLabel: PropTypes.string,
}
export default SecondaryNavigationWrapper
