/**
 * function to get sticky banner information based on view port
 * @param { boolean } isDesktop
 * @param { boolean } sbEnableSignUpModelOnDesktop
 * @param { boolean } sbEnableSignUpModelOnMobileAndTab
 * @param { string } sbDesktopText
 * @param { string } sbMobileAndTabletText
 * @param { string } sbDesktopUrl
 * @param { string } sbMobileAndTabletDestinationUrl
 * @return { sbEnableSignUpModelVal: boolean, textVal: string, urlVal: string }
 */

export const filterStickyBannerData = (
    isDesktop: boolean,
    sbEnableSignUpModelOnDesktop: boolean,
    sbEnableSignUpModelOnMobileAndTab: boolean,
    sbDesktopText: string,
    sbMobileAndTabletText: string,
    sbDesktopUrl: string,
    sbMobileAndTabletDestinationUrl: string,
): {
    sbEnableSignUpModelVal: boolean
    textVal: string
    urlVal: string
} => {
    if (isDesktop) {
        return { sbEnableSignUpModelVal: sbEnableSignUpModelOnDesktop, textVal: sbDesktopText, urlVal: sbDesktopUrl }
    } else {
        return {
            sbEnableSignUpModelVal: sbEnableSignUpModelOnMobileAndTab,
            textVal: sbMobileAndTabletText,
            urlVal: sbMobileAndTabletDestinationUrl,
        }
    }
}
