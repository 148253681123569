import React from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { CommonCardWrapperProps } from './CommonCardWrapper.type'
import SkeletonComponent from '../Skeleton/SkeletonComponent'

/**
 * CommonCardWrapper component
 * @param {CommonCardWrapperProps} props
 * @return {JSX.Element} returns a div with title and children
 */
const CommonCardWrapper: React.FC<CommonCardWrapperProps> = ({ ...props }) => {
    const { children, title, headerChildren, noPadding } = props
    const noPaddingClass = noPadding ? `${PREFIX}-common-wrapper--no-padding` : `${PREFIX}-common-wrapper`
    /**
     * function to check if either title or headerChildren is available and show skeleton accordingly
     * @return {JSX.Element}
     */
    const renderCardComponent = (): JSX.Element | null => {
        if (!!title || !!headerChildren) {
            if (title) return <h2 className={`${PREFIX}-common-wrapper__title`}>{title}</h2>
            if (headerChildren) return <>{headerChildren}</>
            return <SkeletonComponent skeletonClass={`${PREFIX}-common-skeleton-title`} />
        }
        return null
    }

    return (
        <div className={`${PREFIX}-common-wrapper ${noPaddingClass}`}>
            {renderCardComponent()}
            {children}
        </div>
    )
}

CommonCardWrapper.propTypes = {
    children: PropTypes.element,
    title: PropTypes.string,
    headerChildren: PropTypes.element,
    noPadding: PropTypes.bool,
}

export default CommonCardWrapper
