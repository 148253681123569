import React from 'react'
import { PREFIX } from '../config'
import { OfferBannerType } from './OfferBanner.type'

const OfferBanner: React.FC<OfferBannerType> = props => {
    const { bannerImagePath, bannerImageAlt, badgeImageAlt, badgeImageUrl } = props
    return (
        <>
            <div className={`${PREFIX}-offers-card__banner`}>
                <img className={`${PREFIX}-offers-card__banner-icon`} src={bannerImagePath} alt={bannerImageAlt} />
                <img className={`${PREFIX}-offers-card__banner-icon`} src={badgeImageUrl} alt={badgeImageAlt} />
            </div>
        </>
    )
}

export default OfferBanner
