/**
 * this file contains constants which are used across the app
 */
export const currencyShortForm = 'CA' // currency short form
export const currencyFormat = 'CAD' // currency format
export const digitsPattern = /[^0-9]/g // Regular expression for digits
export const defaultLocale = 'en' // default locale
export const frenchLocale = 'fr' // french locale
export const googleMapsUrl = 'http://maps.google.com?q=' // google map url
export enum countryCode {
    ca = 'ca',
    us = 'us',
}
export const defaultCountryName = 'Canada'
export const autocompleteMinLength = 3
export const maximumAccountManagementLinks = 8
export const quebecIsocode = 'CA-QC'

// just a class-marker for sticky to bottom elements
export const STICKY_TO_BOTTOM = 'sticky-to-bottom-elem'
export const saleBadge = 'SALE'
export const ariaType = 'page' // Aria-type for Current Page
export const phoneNumberFormat = /^(\d{3})(\d{3})(\d{4})$/
export const phoneNumberPattern = '$1-$2-$3'
export const AUTO_SERVICE_INSTALLATION_ID = 'AUTO'
/**
 * This can be used to support multi languages label
 */
export enum multiLanguageLabel {
    englishLangLabel = 'EN',
    frenchLangLabel = 'FR',
}

// This const can be used for open link target options
export enum linkTargetOptions {
    selfTarget = '_self',
    newTarget = '_blank',
}

export enum checkToggle {
    yes = 'Y',
    no = 'N',
}

export const accountsPageUrlPattern = {
    en: '/account/',
    fr: '/compte/',
}

export const myAccountsPageUrlPattern = {
    en: '/my-account/',
    fr: '/mon-compte/',
}

export enum dashboardBannerTypes {
    dashboard = 'dashboard', // Represents Tweb dashboard
    selectDashboard = 'triangle-select-dashboard', // Represents Triangle-select dashboard
}

export const recommendationQueryParam = '?rrec=true'

export const subscriptionStatus = {
    active: 'A',
    expired: 'E',
}

export const componentType = {
    default: 'default',
    triangleSelect: 'triangle-select',
}

export const geocode = 'geocode'
export const replaceNumberFormattedPrice = /[^0-9.-]+/g
export const replaceCardValue = /x/g

export const statusLabel = {
    activateStatus: 'Activate',
    activatedStatus: 'Activated',
    redeemedStatus: 'Redeemed',
}

export const multiBannerId = 'XBN'

export const canadaPostApi = {
    next: 'Find',
    retrieve: 'Retrieve',
    canadaPostAPIDebounce: 500,
    postalCodeMaxLength: 5,
    unity: 1,
}

export const LESS = 'LESS'
export const PERCENT = '[percent]'
export const CUT = '[cut]'
export const EMPTY_STRING = ''
export const DOLLAR_SYMBOL = '$'

export const accessibility = {
    a11yStoreLocatorViewTextId: 'storeLocatorViewTextId',
}

export enum sizes {
    SMALL = 'sm',
    MEDIUM = 'md',
    LARGE = 'lg',
}

export enum colorComboType {
    NAVY_TEAL = 'navyTeal',
    TEAL_NAVY = 'tealNavy',
    LIMESTONE_TEAL = 'limestoneTeal',
}

export const colorCombos = {
    navyTeal: {
        fill: '#051C2C',
        stroke: '#00C4B8',
    },
    tealNavy: {
        fill: '#00C4B8',
        stroke: '#051C2C',
    },
    limestoneTeal: {
        fill: '#838074',
        stroke: '#BAFAF6',
    },
}

export enum OSType {
    android = 'android',
    ios = 'iOS',
}

export enum BannerViewType {
    DEFAULT = 'default',
    CT_MONEY_EARNINGS = 'ctMoneyEarnings',
}

/**
 * Customizing the stylings from FED for CTFS iframe fields, implementing this change in CTFS would impact all the consumers.
 * We have the base styling configured below, respective banner need to extend this based on their styling requirements.
 */
export const baseCTFSInputStyles: Record<string, unknown> = {
    width: '100%',
    height: '3.25rem',
    cursor: 'pointer',
    padding: '0 0 0 8px',
    border: '0',
    outline: 'none',
    'font-size': '0.875rem',
    'line-height': '1.25rem',
    'font-weight': '400',
    'font-family': '"Lato", sans-serif',
    'margin-top': '0',
    placeholder: {
        color: '#737373',
    },
}

export enum shippingAddressErrorCodes {
    addressLineOne = '00600',
    city = '00601',
    province = '00602',
    street = '00603',
    postalCode = '00604',
    addressPOBox = '00616',
    addressQuebec = '00618',
}

export enum errorCodes {
    error00001 = '00001',
    error00106 = '00106',
    error00211 = '00211',
    error00121 = '00121',
    error00122 = '00122',
    error00000 = '00000',
    error00055 = '00055',
    error00129 = '00129',
    error00130 = '00130',
    error00140 = '00140',
    error00141 = '00141',
    error00142 = '00142',
    error00143 = '00143',
    error00144 = '00144',
    error00145 = '00145',
    error00146 = '00146',
    error00030 = '00030',
    error00138 = '00138',
    error00043 = '00043',
    error00049 = '00049',
    error101 = '101',
    error400 = '400',
    errorPartialAuth = '00033',
}

export enum errorOrderModificationCodes {
    error00191 = '00191',
    error00192 = '00192',
    error00193 = '00193',
    error00194 = '00194',
    error00195 = '00195',
    error00196 = '00196',
    error00197 = '00197',
    error00198 = '00198',
    error00199 = '00199',
}

export enum BrandingColors {
    TEAL = 'teal',
    RED = 'red',
    BURGUNDY = 'burgundy',
    WHITE = 'white',
    GREY = 'grey',
    GREEN = 'green',
}

export const technicalDifficultiesErrCode = 500
// localeCookieName is added for language key stored in cookie
export const localeCookieName = 'CTC.locale'

export const balanceCheckerScreen = {
    balanceScreen: 'balanceScreen',
    errorScreen: 'errorScreen',
    successScreen: 'successScreen',
}

export enum Types {
    String = 'string',
    Number = 'number',
    Boolean = 'boolean',
    Object = 'object',
}

export const dateOptions = {
    style1: {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        timeZone: 'UTC',
    },
    style2: {
        month: 'long',
        day: '2-digit',
        year: 'numeric',
        timeZone: 'UTC',
    },
}

export enum TextInputModes {
    SEARCH = 'search',
    TEXT = 'text',
    EMAIL = 'email',
    TEL = 'tel',
    URL = 'url',
    NONE = 'none',
    NUMERIC = 'numeric',
    DECIMAL = 'decimal',
}

export const hideClassName = 'hide'
export const dateFormatter = 'do' // date formattor and gives day like th or st Ex: 1st, 10th

export enum CertonaConstants {
    CURRENCY = 'CAD',
    STORE_ID = 'CTR',
}

export enum deviceTypes {
    MOBILE = 'mobile',
    TABLET = 'tablet',
    DESKTOP = 'desktop',
}

export const RoadRatingsImageSize = {
    width: 70,
    height: 70,
}

export enum Symbols {
    AMPERSAND = '&',
    QUESTION_MARK = '?',
}
