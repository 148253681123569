/**
 * To set appropriate css class based on focus and error scenarios.
 * @param {sring} error
 * @param {boolean} isFocused
 * @param {boolean} isErrorFocus
 * @param {sring} componentClassName
 * @param {boolean} disabled
 * @param {boolean} success
 * @return {string}
 */
export const getTextInputClassNames = (
    error: string,
    isFocused: boolean,
    isErrorFocus: boolean,
    componentClassName: string,
    disabled?: boolean,
    success?: boolean,
): string => {
    const errorClass = error ? `${componentClassName}__container--error` : ''
    const focusClass = isFocused || success ? `${componentClassName}__container--focus` : ''
    const disabledClass = disabled ? `${componentClassName}__container--disabled` : ''

    let errorFocusClass = `${errorClass} ${focusClass} ${disabledClass}`

    if (isErrorFocus) {
        if (error) errorFocusClass = errorClass
        else if (isFocused) errorFocusClass = focusClass
        else if (disabled) errorFocusClass = disabledClass
    }

    return errorFocusClass
}
