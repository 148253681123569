export enum bannerTypeConstants {
    short = 'short',
    medium = 'medium',
    long = 'long',
}

export enum imagePosition {
    imageBottomLeft = 'BottomLeft',
    imageTopRight = 'TopRight',
}

export enum SelectBannerType {
    trianglemcbanner = 'triangleMCBanner',
    trianglemcbanneradvanced = 'triangleMCBannerAdvanced',
    dynamicbannerultraslim = 'dynamicBannerUltraSlim',
}

export enum RewardsCardType {
    triangleMasterCard = 'TriangleMC',
    worldEliteMasterCard = 'WorldMC',
    rewardsCard = 'RewardsCard',
}
