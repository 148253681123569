import { completeSearchAnalytics } from '../../analytics/components/completeSearchAnalytics'
import appCacheService from '../../utils/appCacheService'

/**
 * Sets redirect parameter in complete search GA to true and if fireCompleteSearch is on, fire an event complete-search
 * @param {boolean} fireCompleteSearch
 * @return {function}
 */
export const setRedirect = (fireCompleteSearch?: boolean) => (): void => {
    const data = appCacheService.completeSearchEventGA.get()
    if (data) {
        data.redirect = 'true'
        appCacheService.completeSearchEventGA.set(data)
        if (fireCompleteSearch) {
            completeSearchAnalytics('complete-search', data)
        }
    }
}
