import { createAction } from 'typesafe-actions'

import {
    GET_ADDRESS_SUGGESTIONS_SUCCESS,
    GET_ADDRESS_SUGGESTIONS_ERROR,
    GET_SHIPPING_ADDRESS_SUCCESS,
    GET_SHIPPING_ADDRESS_FAILURE,
    GET_ERROR_SUGGESTIONS,
    ERROR_SUGGESTIONS_CLEANUP,
    SET_HPP_TOKEN,
    PLACE_ORDER_SUCCESS,
    PLACE_ORDER_FAILURE,
    SEMAFONE_ENABLED,
    REDEEM_CT_MONEY_RESPONSE,
    GET_PAYMENT_DETAILS,
    GET_PAYMENT_DETAILS_FAILURE,
    EDIT_PAYMENT_CLICKED,
    IS_GIFT_CARD_JUST_DELETED_ACTION,
    SET_INIT_PAYMENT_STATUS_ACTION,
} from '../types/checkout/checkout.actionTypes.constant'
import {
    AddressSuggestionsError,
    AddressAutoSuggestions,
    AddressSuggestions,
    InitPayment,
    PaymentDetails,
    PaymentDetailsError,
} from '../models/checkout.interface'

export const getSuggestionsSuccess = createAction(
    GET_ADDRESS_SUGGESTIONS_SUCCESS,
    (payload: AddressAutoSuggestions) => payload,
)<AddressAutoSuggestions>()
export const getSuggestionsFailure = createAction(
    GET_ADDRESS_SUGGESTIONS_ERROR,
    (payload: AddressSuggestionsError) => payload,
)<AddressSuggestionsError>()
export const getAddressSuggestionsSuccess = createAction(
    GET_SHIPPING_ADDRESS_SUCCESS,
    (payload: AddressSuggestions) => payload,
)<AddressSuggestions>()
export const getAddressSuggestionsFailure = createAction(
    GET_SHIPPING_ADDRESS_FAILURE,
    (payload: AddressSuggestionsError) => payload,
)<AddressSuggestionsError>()
export const getErrorSuggestionsSuccess = createAction(
    GET_ERROR_SUGGESTIONS,
    (payload: AddressSuggestions) => payload,
)<AddressSuggestions>()

export const getPaymentDetailsAction = createAction(GET_PAYMENT_DETAILS)<PaymentDetails>()
export const getPaymentDetailsFailure = createAction(GET_PAYMENT_DETAILS_FAILURE)<PaymentDetailsError>()
export const getAddressSuggestionsCleanup = createAction(ERROR_SUGGESTIONS_CLEANUP)()
export const setHppToken = createAction(SET_HPP_TOKEN)<InitPayment>()
export const placeOrderSuccess = createAction(PLACE_ORDER_SUCCESS)()
export const placeOrderFailure = createAction(PLACE_ORDER_FAILURE)()
export const semaFoneEnabled = createAction(SEMAFONE_ENABLED)()
export const editPaymentClickedAction = createAction(EDIT_PAYMENT_CLICKED)()
export const redeemCTMoneyResponseAction = createAction(REDEEM_CT_MONEY_RESPONSE)<{
    isSuccess: boolean
    error?: Record<string, unknown>
} | null>()

export const isGiftCardJustDeletedAction = createAction(IS_GIFT_CARD_JUST_DELETED_ACTION)<boolean>()
export const setInitPaymentStatusAction = createAction(
    SET_INIT_PAYMENT_STATUS_ACTION,
    (payload: boolean) => payload,
)<boolean>()
