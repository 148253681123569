// Custom Components
import BreadcrumbsComp from '../components/Breadcrumbs'
import GigyaScreen from '../components/GigyaScreen'
import ButtonToggle from '../components/GridListToggle'
import Header from '../components/Header'
import SearchTitle from '../components/SearchTitle'
import StickyFilters from '../components/StickyFilters'
import AccountsPencilBanner from '../components/AccountsPencilBanner'
import WishlistAndCart from '../components/WishlistAndCart'
import SideNavigation from '../components/SideNavigation'
import FeaturedProductListComponent from '../components/FeaturedProductList'
import StoreDetailsComp from '../components/StoreDetails'
import NotificationBanner from '../components/NotificationBanner'
import ToastMessageComponent from '../components/ToastMessage'
import WishlistSummary from '../components/WishListSummary'
import { CertonaInit } from '../components/CertonaInit'
import { TmxInit } from '../components/TmxInit'
import OrderUpdatesSubscription from '../components/OrderUpdatesSubscription'
import CategoryMasthead from '../components/CategoryMasthead'
import DynamicFeaturedList from '../components/DynamicFeaturedList'
import LanguageToggleHandler from '../components/AccountsPencilBanner/PencilBanner/LanguageToggle/LanguageToggleHandler'
import CategoryStacked from '../components/CategoryStacked/CategoryStacked'
import StackedCategoryList from '../components/StackedCategoryList/StackedCategoryList'
import PageInit from '../components/PageInit'
import { FeaturedProductListDynamic } from '../components/FeaturedProductListDynamic'
import CategoryCarousel from '../components/CategoryCarousel'
import StoreSelectorModalComp from '../components/StoreSelectorModal'
import SecondaryNavigationWrapper from '../components/SecondaryNavigation/SecondaryNavigationWrapper'
import NearbyStores from '../components/NearByStores/NearbyStores'
import ReferenceComponentWrapper from '../components/ReferenceComponentWrapper/ReferenceComponentWrapper'
import OrderExpiredError from '../components/OrderDetails/OrderExpired/OrderExpiredError'
import Video from '../components/Video'
import { FullPageSpinnerComponent } from '../components/FullPageSpinnerComponent'
import SiteMap from '../components/SiteMap/SiteMap'
import AutoServiceButton from '../components/AutoServiceButton'
import BackToTop from '../components/BackToTopButton/BackToTop'
import ProgressBanner from '../components/AutomotivePackage/ProgressBanner/ProgressBanner'
import TriangleMCBanner from '../components/TriangleMCBanner/TriangleMCBanner'
import BannerDisclaimer from '../components/BannerDisclaimer/BannerDisclaimer'
import BannerVideoModal from '../components/BannerVideoModal/BannerVideoModal'
import LoyaltyOffersWrapper from '../components/LoyaltyOffers/LoyaltyOffersWrapper'
import OfferCarouselWrapper from '../components/OfferCarousel/OfferCarouselWrapper'
import EmailOfferActivation from '../components/EmailOfferActivation/EmailOfferActivation'
import BookingSummary from '../components/BookingSummary'
import OpenGraphImageTagComponent from '../components/OpenGraphImageTagComponent'
import SignifydInit from '../components/SignifydInit'
import TruefitButton from '../components/TruefitButton'
import Truefit from '../components/Accounts/Truefit'
import BrowseOnlyInformationBanner from '../components/BrowseOnlyInformationBanner'
import BannerContentWrapper from '../components/UltraSlimBanner/BannerContentWrapper'
import BalanceCheckerWrapper from '../components/BalanceChecker/BalanceCheckerWrapper'
import ProductInformationWrapper from '../components/ProductInformation/ProductInformationWrapper'
import DynamicVehicleSeoCopyBlock from '../components/DynamicVehicleSeoCopyBlock'
import StickyBanner from '../components/StickyBanner'

export const staticComponentMap: {
    [key: string]: unknown
} = {
    Breadcrumb: BreadcrumbsComp,
    GigyaScreen: GigyaScreen,
    ButtonToggle: ButtonToggle,
    SearchBar: Header,
    SearchTitle,
    StickyFilters,
    ThreatMetrixTag: TmxInit,
    SignifydTag: SignifydInit,
    PencilBannerLinks: AccountsPencilBanner,
    WishlistAndCart,
    SideNavigation,
    NotificationBanner,
    FeaturedProductListComponent,
    StoreDetailsComp,
    ToastMessage: ToastMessageComponent,
    WishlistSummary,
    CertonaInit,
    NearbyStores,
    SecondaryNavigation: SecondaryNavigationWrapper,
    DynamicFeaturedList,
    CategoryMasthead,
    ReferenceComponent: ReferenceComponentWrapper,
    LanguageToggleHandler,
    CategoryStacked,
    PageInit,
    FeaturedProductListDynamic,
    CategoryCarousel: CategoryCarousel,
    StoreSelectorModalComp,
    StackedCategoryList,
    OrderExpiredError,
    Video,
    FullPageSpinnerComponent,
    OrderUpdatesSubscription,
    HtmlSiteMap: SiteMap,
    AutoServiceButton,
    BackToTop,
    PackageProgressBanner: ProgressBanner,
    TriangleMCBannerComponent: TriangleMCBanner,
    BannerDisclaimer,
    BannerVideoModal,
    LoyaltyOffers: LoyaltyOffersWrapper,
    LoyaltyOffersCarousel: OfferCarouselWrapper,
    EmailOfferActivation,
    BookingSummary,
    OpenGraphImageTagComponent,
    TruefitButton,
    TruefitComponent: Truefit,
    BrowseOnlyInformationBanner,
    UltraSkinnyBanner: BannerContentWrapper,
    BalanceChecker: BalanceCheckerWrapper,
    TitleWrapper: ProductInformationWrapper,
    DynamicVehicleSeoCopyBlock,
    StickyBanner,
}
