import { MagicNumber } from '../analytics/analytics.type'

const getFileNameFromUrl = (videoUrl: string): string => {
    const index = videoUrl.lastIndexOf('/') + MagicNumber.ONE

    const imageName = videoUrl.substring(index)

    return imageName.split('.').slice(MagicNumber.ZERO, MagicNumber.MINUS_ONE).join('.') // remove file extension
}

export default getFileNameFromUrl
