import { PREFIX } from '../config'
import { NavigationLinkHighlightStyle } from '../components/SecondaryNavigation/SecondaryNavigation/SecondaryNavigation.type'

/**
 * function to create classname add yellow color for flyer text
 * @param {string} style
 * @return {string}
 */
export const getStyle = (style: string): string => {
    if (style?.toLowerCase() === NavigationLinkHighlightStyle.exclusiveNavCatColor.toLocaleLowerCase()) {
        return `${PREFIX}-secondary-navigation-bar__nav-list--item-exclusiveNavCatColor`
    } else if (style?.toLowerCase() === NavigationLinkHighlightStyle.highlight.toLocaleLowerCase()) {
        return `${PREFIX}-secondary-navigation-bar__nav-list--item-highlight`
    } else {
        return ''
    }
}
