import React from 'react'

import { PREFIX } from '../config'
import { BrandFooterProps } from './BrandFooter.type'

/**
 * Brand Footer
 * @param {BrandFooterProps} props
 * @return {JSX.Element} returns footer brand
 */
const BrandFooter: React.FC<BrandFooterProps> = ({ ...props }) => {
    const { image, altText, classname, showDivider } = props
    const customClass = classname ? classname : ''
    const componentName = `${PREFIX}-brand-footer`
    return (
        image && (
            <div className={`${componentName} ${customClass}`}>
                {showDivider && <hr />}
                <img className={`${componentName}__img`} src={image} alt={altText} />
            </div>
        )
    )
}

export default BrandFooter
