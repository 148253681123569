import { magicNumber } from './magicNumber'

/**
 * Converts amount to full decimal format
 * @param {number | null | undefined | string} amount
 * @return {number} return decimal implied format number
 */
export const convertDecimalImpliedFormat = (amount: number | null | undefined | string): number => {
    const multipliedAmt = Number(amount) * magicNumber.HUNDRED || 0
    return parseInt(multipliedAmt.toString())
}
