import { MegaNavigationDesktopType } from './MegaNavigationDesktop.type'
import classnames from 'classnames'
import { PREFIX } from '../config'
import { getAriaCurrentValue } from '../MegaNavigation/MegaNavigation.helper'
import { ariaType } from '../../globalConstants/global.constant'
import MegaNavigationTile from '../MegaNavigation/MegaNavigationTile'
import React from 'react'

const SubCategories: React.FC<MegaNavigationDesktopType> = ({
    catalog,
    InteractionAnalytics,
    eventName,
    actionName,
}) => (
    <div className={classnames(`${PREFIX}-row`, `${PREFIX}-sub-categories`)}>
        {/* L3 columns */}
        {catalog?.categories?.map((chunk, index) => {
            return (
                <div key={index} className={classnames(`${PREFIX}-col-sm-2`, `${PREFIX}-sub-categories__padding`)}>
                    {chunk.map(category => {
                        return (
                            <ul key={category.name}>
                                <li className={classnames(`${PREFIX}-sub-categories__category--l2`)}>
                                    <InteractionAnalytics
                                        componentName="MegaNavigation"
                                        label={category.name}
                                        eventName={eventName}
                                        actionName={actionName}>
                                        <a
                                            aria-current={getAriaCurrentValue(category) ? ariaType : false}
                                            href={encodeURI(
                                                typeof category?.url === 'string' ? category?.url : category?.url?.url,
                                            )}
                                            data-link-value={category.name}>
                                            {category.name}
                                        </a>
                                    </InteractionAnalytics>
                                </li>
                                {category?.categories?.map(cat => (
                                    <li key={cat.name} className={classnames(`${PREFIX}-sub-categories__category`)}>
                                        <InteractionAnalytics
                                            componentName="MegaNavigation"
                                            label={cat.name}
                                            eventName={eventName}
                                            actionName={actionName}>
                                            <a
                                                aria-current={getAriaCurrentValue(cat) ? ariaType : false}
                                                href={encodeURI(
                                                    typeof cat?.url === 'string' ? cat?.url : cat?.url?.url,
                                                )}
                                                data-link-value={cat.name}>
                                                {cat.name}
                                            </a>
                                        </InteractionAnalytics>
                                    </li>
                                ))}
                            </ul>
                        )
                    })}
                </div>
            )
        })}
        {/* Promotion/Sales section */}
        {catalog.saleCategories && (
            <div className={classnames(`${PREFIX}-col-sm-2`)}>
                <MegaNavigationTile tileData={catalog.saleCategories} />
            </div>
        )}
    </div>
)

export default SubCategories
