import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { PREFIX } from '../../config'
import { TruefitButtonProps } from './TruefitButton.type'
import { SkeletonComponent } from '@nl/lib'
import { userTruefitSelector } from '../../redux/selectors/Truefit.selectors'

/**
 * Truefit Button component
 * @param {TruefitButtonProps} props - label
 * @return {JSX.Element} returns Truefit Button
 */
const TruefitButton: React.FC<TruefitButtonProps> = ({ label }): JSX.Element => {
    const userTruefit = useSelector(userTruefitSelector)
    const [isPending, setIsPending] = useState(userTruefit.isPending)

    useLayoutEffect(() => {
        if (!isPending) {
            window?.tfcapi('calculate')
        }
    }, [isPending])

    useEffect(() => {
        setIsPending(userTruefit.isPending)
    }, [userTruefit])

    const renderTruefitButton = (): JSX.Element => {
        return (
            <div className={`${PREFIX}-truefit__button--wrapper`}>
                <a
                    className={`${PREFIX}-button ${PREFIX}-button--primary ${PREFIX}-truefit__button tfc-fitrec-register`}
                    href="# "
                    {...userTruefit.props}>
                    {label}
                </a>
            </div>
        )
    }
    return !!label && !isPending ? (
        <>{renderTruefitButton()}</>
    ) : (
        <SkeletonComponent skeletonClass={`${PREFIX}-truefit__skeleton-button`} />
    )
}

export default TruefitButton
