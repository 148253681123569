import { Dispatch } from 'redux'
import getFilteredCartItems from '../../utils/getFilteredCartItems'
import getPostalCodeMock from '../../utils/getPostalCodeMock'
import { libUtils, getParamValue } from '@nl/lib'

import {
    addToCartGuestSuccessAction,
    addToCartGuestFailureAction,
    resetCartGUIDCreatedFlagAction,
    addServiceToCartSuccessAction,
    addServiceToCartFailureAction,
    getCartItemsSuccessAction,
    getMiniCartItemsSuccessAction,
    getCartItemsFailureAction,
    updateCartDataSuccessAction,
    updateCartDataFailureAction,
    reservationRequestInProgress,
    reservationRequestFinished,
    setSelectedServiceAction,
    resetSelectedServiceAction,
    removeCartItemSuccessAction,
    removeCartItemFailureAction,
    updateDeliveryMethodSuccessAction,
    updateDeliveryMethodFailureAction,
    changeCartDeliveryModeFailure,
    changeCartDeliveryModeReset,
    updatePostalCodeSuccessAction,
    setSelectedFulFillmentOption,
    updatePostalCodeErrorAction,
    getPostalCodeDataAction,
    getPostalCodeDataError,
    resetCartValidationsAction,
    updateAnalyticsInfo,
    setCheckoutPickup,
    setCheckoutContact,
    setCheckoutShipping,
    setShowSpinner,
    setXhrInfoForGetCart,
    mergeCartSuccessAction,
    getCartItemsForAuthForMergeSuccessAction,
    setCheckoutPayment,
    updatePostalCodeDispatchStartedAction,
    updateStoreInitiatedCart,
    updateStoreInitiatedCartPostalCode,
    updateStoreInitiatedCartPostalCodeFail,
    updatePostalCodeFailureAction,
    shippingEstimationInProgress,
    shippingEstimationSuccess,
    shippingEstimationError,
    resetShippingEstimation,
    getPromoMessageSuccessAction,
    setSflToastVariables,
    applyPromoCodeSuccessAction,
    applyPromoCodeErrorAction,
    clearAddToCartErrorDataAction,
    setCartFlyoutOpenAction,
    initCheckoutFailure,
    setExpressDeliveryPostalCodeOutOfRange,
    resetReservationVariables,
    setShoppingCartLimitItemsFailure,
    clearShoppingCartLimitItemsFailure,
} from '../actionCreators'

import httpClient from '../utils/httpClient'
import { API_ENDPOINTS } from '../../environments/apiConfig'
import {
    AddProductToCartGuestRequestDTO,
    CartItemsData,
    ServiceDTO,
    UpdateDeliveryRequest,
    CartModificationDTO,
    PostalCodeData,
    FilteredCartData,
    CartModificationsDTO,
    CartResponseErrorDTO,
    VehicleInformation,
    AddProductToCartGuestResponseDTO,
    RemoveCartItem,
    MiniCartData,
} from '../models/cart.interface'
import { HttpRequestState } from '../../httpClient/client.type'
import { CartUpdateType } from '../../components/ShoppingCart/ShoppingCart.type'
import { cartService } from '../../services'
import { RootState } from '../reducers'
import appCacheService from '../../utils/appCacheService'
import sessionStorageService from '../../utils/sessionStorageService'
import {
    AutoPackages,
    PackageLandingInitialState,
} from '../../components/AutomotivePackage/PackageLanding/PackageLanding.type'
import { MagicNumber } from '../../analytics/analytics.type'
import {
    setCreatePackageClick,
    updateAutoPackages,
    updateCurrentPackageId,
    updateUserInitializedPackageFlow,
} from './automotiveVehicle.action'
import { checkDataLength } from '../../components/Accounts/Addresses/checkDataLength'
import { productWheelTypes, AutoPackageKeys } from '../../globalConstants'
import { filterAutoPackageProductOnload, updateSessionDataForPackageFlow } from '../../helpers/autoPackage.helper'
import { errorAnalytics } from '../../analytics/components/errorAnalytics'
import fireAnalyticsForMerge from '../../analytics/components/mergeCartAnalytics'
import { CertonaInitialization } from '../../certona/certona.service'
import { certonaEventConst } from '../../certona/certona.constant'
import { pageTypes, STORE_INITIATED_CART_KEY } from '../../config'
import getPageType from '../../utils/getPageType'
import getEnvironment from '../../utils/getEnvironment'
import { enableDestructOnUndefinedData } from '../../utils/PDP/enableDestructOnUndefinedData.utils'
import { isOneTimeCartForAuthUser } from '../../utils/isOneTimeCartForAuthUser.utils'
import { AutoPackageModalData } from '../../components/ProductGridView/ProductCard.types'
import { getAutoPackageList, incompletePackageList } from '../../components/ProductGridView/ProductCard.helper'
import { isDeliveryModeForciblyChanged } from '../../components/ShoppingCart/CartItemSTH.helper'
import { AxiosError, AxiosResponse } from 'axios'
import { StoreSharedCart } from '../reducers/sharedCart.reducer'
import { replaceEmptyImagesWithDefault } from '../../utils/replaceEmptyImagesWithDefault'
import convertToCartItemsDataForAuth from '../../utils/convertToCartItemsDataForAuth'
import { httpStatusCodes } from '../../globalConstants/global.constant'
import { deliveryOptionsService } from '../../services/deliveryOptionsService/deliveryOptionsService'
import { SuccessResponse } from '../models/deliveryOptions.interface'
import { resetCheckOutVariables } from './checkoutButtonClick.action'
import { cartLimitItemsErrorCode } from '../../globalConstants/cdsErrorCodes'
import { CertonaConstants } from '@nl/lib/src/globalConstants/global.constant'

/**
 * Function for Error logging
 * @param {Record<string, unknown>} err
 * @param {string} message
 * @return {void}
 */
export const logError = (err: Record<string, unknown>, message: string): void => {
    console.group(message)
    console.log(`error Message: ${err?.message as string}`)
    console.log(`errorType: ${err?.error as string}`)
    console.log(`statusCode: ${err?.statusCode as string}`)
    console.groupEnd()
}

/**
 * This function is used to update the session storage package flow data based on the selected package
 * @param {string | undefined} selectedPackage
 * @param {CartModificationsDTO} cartModificationDTO
 * @param {AutoPackages} autoPackages
 * @param {boolean} isCreatePackage
 * @return {void}
 */
export const updateSessionStorageData =
    (
        selectedPackage: string | undefined,
        cartModificationDTO: CartModificationsDTO,
        autoPackages: AutoPackages,
        isCreatePackage?: boolean,
    ): void =>
    (dispatch: Dispatch): void => {
        if (selectedPackage === productWheelTypes.Tire || selectedPackage === productWheelTypes.WinterTire) {
            const updateAutoPackage = updateSessionDataForPackageFlow(
                AutoPackageKeys.tireData,
                cartModificationDTO,
                autoPackages,
                isCreatePackage,
            )
            dispatch(updateAutoPackages(updateAutoPackage))
            dispatch(updateUserInitializedPackageFlow(true))
            dispatch(updateCurrentPackageId(updateAutoPackage[updateAutoPackage.length - MagicNumber.ONE].packageId))
        } else if (selectedPackage === productWheelTypes.Wheel) {
            const updateAutoPackage = updateSessionDataForPackageFlow(
                AutoPackageKeys.wheelData,
                cartModificationDTO,
                autoPackages,
                isCreatePackage,
            )
            dispatch(updateAutoPackages(updateAutoPackage))
            dispatch(updateUserInitializedPackageFlow(true))
            dispatch(updateCurrentPackageId(updateAutoPackage[updateAutoPackage.length - MagicNumber.ONE].packageId))
        }
    }

export const addProductToCartGuest =
    (
        requestPayload: AddProductToCartGuestRequestDTO,
        addServiceToCart = false,
        showSpinner = false,
        isBuybox = false,
        postalCodeVal?: string,
        vehicleInformation?: VehicleInformation,
        isBulk?: boolean,
        isPackageFlow?: boolean,
        autoPackages?: AutoPackages[],
        isStaggered?: boolean,
        isCreatePackage?: boolean,
        isShippingPostalCodeHide?: boolean,
    ) =>
    (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const { selectedPreferredStoreId, preferredStoreDetails } = getState().storeDetails
        const { isAuthFlowExecuted, userProfileData } = getState().userProfile
        const lastCartStoreId = getState().cart?.cartItemsData?.cart?.store?.id
        const lastCartDeliveryMode = getState().cart?.cartItemsData?.cart?.deliveryMode
        const postalCode = isShippingPostalCodeHide
            ? userProfileData?.addresses?.postalCode
            : postalCodeVal || preferredStoreDetails?.address?.postalCode

        showSpinner && dispatch(setShowSpinner(true)) // Display spinner if required

        /**
         * TODO - this mock data is for pdp error messages. to be removed once we api sends error codes
         */
        const isMockData = getParamValue(window.location.search, 'isMockData', '?')

        if (isMockData) {
            return httpClient.apiGet(API_ENDPOINTS.addToCartError, {}, 'AEM_MOCK_JSON').then(data => {
                dispatch(addToCartGuestFailureAction(data.data))
            })
        }

        const isPackageFlowFunc = (data: AddProductToCartGuestResponseDTO) => {
            dispatch(resetATCService())
            if (isPackageFlow) {
                const lastAutoPackageItem = autoPackages[autoPackages.length - MagicNumber.ONE]
                if (!lastAutoPackageItem?.nextSelectedContainer) {
                    updateSessionStorageData(
                        lastAutoPackageItem?.currentSelectedContainer,
                        data.cartModifications[0],
                        autoPackages,
                        isCreatePackage,
                    )(dispatch)
                } else {
                    updateSessionStorageData(
                        lastAutoPackageItem?.nextSelectedContainer,
                        data.cartModifications[0],
                        autoPackages,
                        isCreatePackage,
                    )(dispatch)
                }
                dispatch(updateCurrentPackageId(data.cartModifications[0].entry.packageId))
            }
        }

        return cartService
            .addItemToCart(
                requestPayload.guid,
                requestPayload.entries.orderEntries,
                selectedPreferredStoreId,
                postalCode,
                isStaggered,
                isBulk,
            )
            .then((data: AxiosResponse<AddProductToCartGuestResponseDTO>) => {
                replaceEmptyImagesWithDefault(
                    data?.data?.cartModifications?.map(cartModification => cartModification?.entry),
                    'images',
                )
                isBuybox && dispatch(setCartFlyoutOpenAction(true))
                const guid = data.data.guid
                const environment = getEnvironment()
                // OCCP-19905 store cart guid only for guest user in local storage
                // OCCP-24280 removed guid check, as FED should replace guid always from ATC response
                const isGuestUser = (): boolean => {
                    const isLoggedIn = isAuthFlowExecuted && Boolean(checkDataLength(userProfileData))
                    return !isLoggedIn
                }
                isGuestUser() && appCacheService.setCartGuid(guid)
                getMiniCartItemsData(guid, '')(dispatch, getState)
                const cartEntriesData = { ...data.data }
                cartEntriesData.cartModifications[0].numberOfItems = cartEntriesData?.numberOfItems || 0

                if (lastCartStoreId && String(lastCartStoreId) !== selectedPreferredStoreId) {
                    appCacheService.cartStoreChanged.set(selectedPreferredStoreId)
                }

                if (isDeliveryModeForciblyChanged(requestPayload, lastCartDeliveryMode)) {
                    appCacheService.isDeliveryModeConflict.set('yes')
                }

                if (addServiceToCart) {
                    // OCCP-6273: vehicle information is not returned in cartModification for hardware addon but required for analytics purposes
                    cartEntriesData.cartModifications[0].entry.vehicleInformation = vehicleInformation
                }

                dispatch(addToCartGuestSuccessAction(cartEntriesData))
                if (addServiceToCart) {
                    dispatch(
                        addServiceToCartSuccessAction({
                            success: true,
                            showErrorToast: false,
                            selectedService: data.data.serviceList[0],
                        }),
                    )
                } else {
                    const filteredProductCodes = cartEntriesData.cartModifications
                        .map(({ entry }) => entry.baseProduct)
                        .join(';')
                    CertonaInitialization.triggerCertona({
                        itemid: filteredProductCodes,
                        event: certonaEventConst.addToCart,
                        environment: environment,
                        recommendations: true,
                        filter: {
                            storeid: appCacheService.preferredStoreId.get() || '',
                            currencycode: CertonaConstants.CURRENCY,
                            language: libUtils.getLanguage(),
                            fitmentproducts: false,
                        },
                    })
                }

                isPackageFlowFunc(cartEntriesData)
            })
            .catch((err: AxiosError<CartResponseErrorDTO>) => {
                logError(err?.response?.data, 'Reason for add to cart api failure')
                const errorResponse = err.response
                const errCode = errorResponse?.data?.errCode
                if (errCode === cartLimitItemsErrorCode) {
                    dispatch(setShoppingCartLimitItemsFailure(errorResponse))
                } else {
                    addServiceToCart
                        ? dispatch(addServiceToCartFailureAction({ success: false, showErrorToast: true }))
                        : dispatch(addToCartGuestFailureAction(errorResponse))
                }
            })
            .finally(() => {
                dispatch(setShowSpinner(false))
            })
    }
/**
 * setting flag to call redux action to set SFL ID in local storage.
 * @param {string | undefined} sflId
 * @return {void}
 */
export const setSflIdToLocalStorage = (sflId: string | undefined): void => {
    const slfguid = appCacheService.getSflGuid() || sflId
    !!slfguid && appCacheService.setSflGuid(slfguid)
}
export const resetCartGUIDCreatedFlag =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetCartGUIDCreatedFlagAction())
    }

export const setATCService =
    (services: ServiceDTO[]) =>
    (dispatch: Dispatch): void => {
        dispatch(setSelectedServiceAction(services))
    }

export const resetATCService =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetSelectedServiceAction())
    }

/**
 * Fills checkout reducer with cart items
 * @param {CartItemsData} cartData
 * @param {Dispatch} dispatch
 */
export const fillCheckoutDataFromAPI = (cartData: CartItemsData, dispatch: Dispatch): void => {
    if (checkDataLength(cartData)) {
        if (cartData.contactInfo) {
            dispatch(setCheckoutContact(cartData.contactInfo))
        }
        if (checkDataLength(cartData.deliveryAddress?.address)) {
            const shippingAddressFromAPI = cartData.deliveryAddress
            const updateData = {
                firstName: shippingAddressFromAPI.firstName,
                lastName: shippingAddressFromAPI.lastName,
                addressLineOne: shippingAddressFromAPI.address.address1,
                addressLineTwo: shippingAddressFromAPI.address.address2,
                city: shippingAddressFromAPI.address.city,
                province: shippingAddressFromAPI.address.province,
                country: '',
                postalCode: shippingAddressFromAPI.address.postalCode,
            }
            dispatch(setCheckoutShipping(updateData))
        }
        if (cartData.pickupLocation) {
            dispatch(
                setCheckoutPickup({
                    orderPickupInPerson: cartData.orderPickupInPerson,
                    pickupLocation: cartData.pickupLocation,
                    vin: cartData.vin,
                    requestCallback: cartData.customerCallbackRequested,
                }),
            )
        }
        if (cartData.paymentInfo?.billingAddress?.firstName) {
            dispatch(
                setCheckoutPayment({
                    billingAddress: {
                        ...cartData.paymentInfo?.billingAddress,
                        country: cartData.paymentInfo?.billingAddress.country?.isocode,
                    },
                    newCreditCard: true,
                }),
            )
        }
    }
}

/**
 * function to come out of package flow once complete package is available
 * @param {boolean} isCartFlyoutOpen
 * @param {PackageLandingInitialState} packages
 * @param {AutoPackages} currentPackage
 */
const discardPackageFlow = (
    isCartFlyoutOpen?: boolean,
    packages?: PackageLandingInitialState,
    currentPackage?: AutoPackages,
) => {
    if (
        !isCartFlyoutOpen &&
        packages &&
        currentPackage &&
        checkDataLength(currentPackage?.vehicleInfo?.wheelData) &&
        checkDataLength(currentPackage?.vehicleInfo?.tireData) &&
        !currentPackage?.changeSelectedLink &&
        !currentPackage?.cartFlyoutChangeSelectedLink
    ) {
        sessionStorageService.removeItem('packageFlow')
    }
}

/**
 * function to process received cart data
 * @param {CartItemsData} cartData
 * @param {Boolean} authenticatedUser
 * @param {Boolean} isOneTimeCart
 * @param {Boolean} isCartFlyoutOpen
 * @param {Boolean} isGetCartAfterUpdatingPaymentInfo
 * @param {Dispatch} dispatch
 * @param {Boolean} saveLastVisitedDate
 */
const processCartData = (
    cartData: CartItemsData,
    authenticatedUser: boolean,
    isOneTimeCart: boolean,
    isCartFlyoutOpen: boolean,
    isGetCartAfterUpdatingPaymentInfo: boolean,
    dispatch: Dispatch,
    saveLastVisitedDate: boolean,
) => {
    replaceEmptyImagesWithDefault(cartData?.orderEntries, 'images')
    const updatedGuid = cartData?.cartId
    !authenticatedUser && appCacheService.setCartGuid(updatedGuid)
    setSflIdToLocalStorage(cartData?.saveForLaterId)
    const cartFilteredData = getFilteredCartItems(cartData)
    cartFilteredData.xhrInfo = {
        getCartInfo: HttpRequestState.done,
    }
    if (saveLastVisitedDate) appCacheService.cartLastVisitedDate.set(new Date().toLocaleDateString())
    filterAutoPackageProductOnload(cartFilteredData.cart)
    const packages = (sessionStorageService.getItem('packageFlow') &&
        JSON.parse(sessionStorageService.getItem('packageFlow'))) as PackageLandingInitialState
    const currentPackage = packages?.autoPackages?.find(item => item.packageId === packages.currentPackageId)
    discardPackageFlow(isCartFlyoutOpen, packages, currentPackage)
    const pageType = getPageType()
    !(pageType === pageTypes.orderConfirmation || pageType === pageTypes.orderDetails) &&
        !isGetCartAfterUpdatingPaymentInfo &&
        fillCheckoutDataFromAPI(cartFilteredData.cart, dispatch)
    // TODO: code commented which can be used to handle 206 error
    // if (cartData.status === httpStatusCodes.successCode) {
    isOneTimeCart
        ? dispatch(updateStoreInitiatedCart(cartFilteredData))
        : dispatch(getCartItemsSuccessAction(cartFilteredData))

    isOneTimeCart && sessionStorageService.setItem(STORE_INITIATED_CART_KEY, JSON.stringify(cartFilteredData))
    // }
    // added for OCCP-17990 cds api change for postalcode not eligible
    // if (cartData.status === httpStatusCodes.partialSuccessCode) {
    //     dispatch(updatePostalCodePartialSuccessAction(cartFilteredData))
    // }
}

/**
 * function to receive and process cart items data
 * @param {string} guid
 * @param {string} option
 * @param {string | number} storeId
 * @param {boolean} authenticatedUser
 * @param {boolean} isOneTimeCart
 * @param {boolean} isCartFlyoutOpen
 * @param {boolean} isGetCartAfterUpdatingPaymentInfo
 * @param {Dispatch} dispatch
 * @param {boolean} isHideSpinnerAtExit
 * @param {boolean} saveLastVisitedDate
 */
const processCartCall = (
    guid: string,
    option: string,
    storeId: string | number,
    authenticatedUser = false,
    isOneTimeCart = false,
    isCartFlyoutOpen: boolean,
    isGetCartAfterUpdatingPaymentInfo: boolean,
    dispatch: Dispatch,
    isHideSpinnerAtExit = true,
    saveLastVisitedDate: boolean,
) => {
    cartService
        .getCartItems(guid, option, storeId, authenticatedUser && isOneTimeCart)
        .then(cartData => {
            processCartData(
                cartData?.data,
                authenticatedUser,
                isOneTimeCart,
                isCartFlyoutOpen,
                isGetCartAfterUpdatingPaymentInfo,
                dispatch,
                saveLastVisitedDate,
            )
        })
        .catch((err: AxiosError<CartResponseErrorDTO>) => {
            logError(err?.response?.data, 'Reason for Get Cart Error')
            const errorResponse = err.response
            dispatch(setXhrInfoForGetCart(HttpRequestState.failed))
            dispatch(getCartItemsFailureAction(errorResponse))
        })
        .finally(() => {
            isHideSpinnerAtExit && dispatch(setShowSpinner(false))
        })
}

/**
 * Function to get cart items for shopping cart
 * @param {string} guid
 * @param {string} option
 * @param {boolean} showSpinner
 * @param {isOneTimeCart} isOneTimeCart
 * @param {authenticatedUser} authenticatedUser
 * @param {boolean} isGetCartAfterUpdatingPaymentInfo
 * @param {boolean} isHideSpinnerAtExit
 * @param {boolean} saveLastVisitedDate
 * @return {Promise}
 */
export const getCartItemsData =
    (
        guid: string,
        option: string,
        showSpinner = false,
        isOneTimeCart = false,
        authenticatedUser = false,
        isGetCartAfterUpdatingPaymentInfo = false,
        isHideSpinnerAtExit = true,
        saveLastVisitedDate = false,
    ) =>
    (dispatch: Dispatch, getState: () => RootState): void => {
        const { selectedPreferredStoreId } = getState().storeDetails
        const { oneTimeCartStore } = enableDestructOnUndefinedData(
            getState().sharedCart?.cartConsuming?.storeSharedCart,
        ) as StoreSharedCart
        const { isCartFlyoutOpen } = getState().product
        const storeId = isOneTimeCart ? oneTimeCartStore.id : selectedPreferredStoreId
        showSpinner && dispatch(setShowSpinner(true)) // Display spinner if required
        dispatch(setXhrInfoForGetCart(HttpRequestState.inProgress))

        const { prefetchedCartPromise } = window

        if (!prefetchedCartPromise) {
            processCartCall(
                guid,
                option,
                storeId,
                authenticatedUser,
                isOneTimeCart,
                isCartFlyoutOpen,
                isGetCartAfterUpdatingPaymentInfo,
                dispatch,
                isHideSpinnerAtExit,
                saveLastVisitedDate,
            )
        } else {
            prefetchedCartPromise
                .then(response => {
                    if (response.ok) return response.json()
                    throw new Error('Prefetch request fall')
                })
                .then(cartData => {
                    cartService.updateMiniCartData(cartData)
                    processCartData(
                        cartData,
                        authenticatedUser,
                        isOneTimeCart,
                        isCartFlyoutOpen,
                        isGetCartAfterUpdatingPaymentInfo,
                        dispatch,
                        saveLastVisitedDate,
                    )
                })
                .catch(() => {
                    processCartCall(
                        guid,
                        option,
                        storeId,
                        authenticatedUser,
                        isOneTimeCart,
                        isCartFlyoutOpen,
                        isGetCartAfterUpdatingPaymentInfo,
                        dispatch,
                        isHideSpinnerAtExit,
                        saveLastVisitedDate,
                    )
                })
                .finally(() => {
                    isHideSpinnerAtExit && dispatch(setShowSpinner(false))
                    window.prefetchedCartPromise = undefined
                })
        }
    }

/**
 * Function to get mini cart items for shopping cart
 * @param {string} guid
 * @param {string} option
 * @param {boolean} showSpinner
 * @param {boolean} enableCache
 * @param {boolean} isMiniCartCall
 * @return {Promise}
 */
export const getMiniCartItemsData =
    (guid: string, option: string, showSpinner = false, enableCache = false, isMiniCartCall = true) =>
    (dispatch: Dispatch, getState: () => RootState): void => {
        const storeId = getState().storeDetails.selectedPreferredStoreId
        showSpinner && dispatch(setShowSpinner(true)) // Display spinner if required
        dispatch(setXhrInfoForGetCart(HttpRequestState.inProgress))
        cartService
            .getMiniCartItems(guid, option, storeId, enableCache, isMiniCartCall)
            .then(cartData => {
                const updatedGuid = cartData?.data?.cartId
                appCacheService.setCartGuid(updatedGuid)
                dispatch(getMiniCartItemsSuccessAction(cartData.data))
                dispatch(setXhrInfoForGetCart(HttpRequestState.done))
            })
            .catch((err: AxiosError<CartResponseErrorDTO>) => {
                const errorResponse = err.response || ({} as CartResponseErrorDTO)

                if (errorResponse.data.statusCode === httpStatusCodes.gone) {
                    appCacheService.removeCartGuid()
                } else {
                    logError(errorResponse.data as Record<string, unknown>, 'Reason for Get Mini Cart Error')
                    dispatch(setXhrInfoForGetCart(HttpRequestState.failed))
                    dispatch(getCartItemsFailureAction(errorResponse as CartResponseErrorDTO))
                }
            })
            .finally(() => {
                dispatch(setShowSpinner(false))
            })
    }

/**
 * Function to call initCheckout Api to reserve the cart items for the current user
 * @param {string} guid
 * @param {boolean} showSpinner
 * @param {boolean} hideSpinnerOnExit
 * @return {Promise}
 */

export const reserveCartItems =
    (guid: string, showSpinner = false, hideSpinnerOnExit = false) =>
    (dispatch: Dispatch, getState: () => RootState): void => {
        const { selectedPreferredStoreId } = getState().storeDetails
        const { storeInitiatedCart, isStoreSharedCart } = enableDestructOnUndefinedData(
            getState().sharedCart?.cartConsuming?.storeSharedCart,
        ) as StoreSharedCart
        const storeId = isStoreSharedCart ? storeInitiatedCart?.storeData?.id : selectedPreferredStoreId
        const isOneTimeCartFlag = isOneTimeCartForAuthUser(
            enableDestructOnUndefinedData(getState().userProfile),
            enableDestructOnUndefinedData(getState().sharedCart),
        )
        dispatch(reservationRequestInProgress())
        showSpinner && dispatch(setShowSpinner(true)) // Display spinner if required
        cartService
            .initCheckout(guid, storeId, isOneTimeCartFlag)
            .then(data => {
                replaceEmptyImagesWithDefault(data?.data?.orderEntries, 'images')
                const cartFilteredData = getFilteredCartItems(data.data)
                cartFilteredData.xhrInfo = {
                    getCartInfo: HttpRequestState.done,
                }
                isStoreSharedCart
                    ? dispatch(updateStoreInitiatedCart(cartFilteredData))
                    : dispatch(updateCartDataSuccessAction(cartFilteredData))
            })
            .then(() => {
                dispatch(reservationRequestFinished())
            })
            .catch((err: AxiosError<CartResponseErrorDTO>) => {
                const errorResponse = err.response
                dispatch(getCartItemsFailureAction(errorResponse))
                dispatch(initCheckoutFailure())
                dispatch(resetCheckOutVariables())
                dispatch(resetReservationVariables())
            })
            .finally(() => {
                hideSpinnerOnExit && dispatch(setShowSpinner(false))
            })
    }

// TODO: mockFileName param will be removed once the actual api is integrated.
export const removeCartItem =
    (
        guid: string,
        mockFileName?: string,
        showSpinner = false,
        entryNumberArray: number[] = [],
        productIds: string[] = [],
        isFromSFL = false,
    ) =>
    (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const { selectedPreferredStoreId } = getState().storeDetails
        showSpinner && dispatch(setShowSpinner(true)) // Display spinner if required
        return cartService
            .updateCartEntry(guid, 0, true, mockFileName, selectedPreferredStoreId, entryNumberArray)
            .then(data => {
                replaceEmptyImagesWithDefault((data?.data as CartItemsData)?.orderEntries, 'images')
                isFromSFL &&
                    dispatch(
                        setSflToastVariables({
                            addedToSflSuccess: true,
                        }),
                    )
                productIds.forEach(product => {
                    CertonaInitialization.triggerCertona({
                        itemid: product,
                        event: certonaEventConst.removeFromCart,
                        recommendations: false,
                    })
                })
                const cartFilteredData = getFilteredCartItems(data.data)
                dispatch(removeCartItemSuccessAction(cartFilteredData))
            })
            .catch((err: AxiosError<Record<string, RemoveCartItem[]>>) => {
                const errorMessage = err.response.data.errors?.[0].message as string
                dispatch(
                    removeCartItemFailureAction({
                        errorFlag: true,
                        errorMessage: errorMessage,
                    }),
                )
            })
            .finally(() => {
                dispatch(setShowSpinner(false))
            })
    }

// Action for updating the cart data when clicked on quantity selector
// TODO: this action should have a payload for a PUT call.
export const updateCartData =
    (
        guid: string,
        quantity: number,
        isAdd: boolean,
        selectedPreferredStoreId: string,
        entryNumberArray: number[],
        inputRef?: React.MutableRefObject<HTMLElement>,
    ) =>
    // (guid: string, entryNumber: number, quantity: number, isAdd: boolean, selectedPreferredStoreId: string) =>
    (dispatch: Dispatch): Promise<void> => {
        dispatch(setShowSpinner(true))
        return cartService
            .updateCartEntry(guid, quantity, false, '', selectedPreferredStoreId, entryNumberArray)
            .then(data => {
                replaceEmptyImagesWithDefault((data?.data as CartItemsData)?.orderEntries, 'images')
                const cartFilteredData = getFilteredCartItems(data.data, quantity)
                dispatch(updateCartDataSuccessAction(cartFilteredData))
                dispatch(
                    updateAnalyticsInfo({
                        updateType: isAdd ? CartUpdateType.ADD_QUANTITY : CartUpdateType.REMOVE_QUANTITY,
                    }),
                )
            })
            .catch((err: AxiosError<CartResponseErrorDTO>) => {
                const errorResponse = err.response
                dispatch(updateCartDataFailureAction(errorResponse))
            })
            .finally(() => {
                dispatch(setShowSpinner(false))
                inputRef?.current?.focus()
            })
    }

// OCCP-18331: action to create an auto package. changed from calling add-to-cart to calling update-cart-entry
/**
 * create auto package
 * @param {string} guid - guid for the user
 * @param {string} storeId
 * @param {number[]} entryNumberArray
 * @param {AutoPackages[] } autoPackages
 * @param { AutoPackageModalData } selectedCard
 * @return {AxiosPromise}
 */
export const createAutoPackage =
    (
        guid: string,
        storeId?: string,
        entryNumberArray?: number[],
        autoPackages?: AutoPackages[],
        selectedCard?: AutoPackageModalData,
    ) =>
    (dispatch: Dispatch): Promise<void> => {
        return cartService
            .createAutoPackage(guid, storeId, entryNumberArray)
            .then((data: AxiosResponse<CartItemsData>) => {
                replaceEmptyImagesWithDefault(data?.data?.orderEntries, 'images')
                const cartFilteredData = getFilteredCartItems(data.data)
                dispatch(updateCartDataSuccessAction(cartFilteredData))
                const lastAutoPackageItem = autoPackages?.[autoPackages.length - MagicNumber.ONE]
                const unfinishedAutoPackageArray = incompletePackageList(getAutoPackageList(data?.data?.orderEntries))
                const updatingProduct = unfinishedAutoPackageArray?.find(item => item.code === selectedCard?.code)
                const modifiedData = {
                    entry: updatingProduct,
                    errorCode: updatingProduct?.errorCode,
                    errorMessage: data?.data?.errorMessage,
                    quantityAdded: updatingProduct?.quantity,
                }
                dispatch(setCreatePackageClick(true))
                if (!lastAutoPackageItem?.nextSelectedContainer) {
                    updateSessionStorageData(
                        lastAutoPackageItem?.currentSelectedContainer,
                        modifiedData,
                        autoPackages,
                        true,
                    )(dispatch)
                } else {
                    updateSessionStorageData(
                        lastAutoPackageItem?.nextSelectedContainer,
                        modifiedData,
                        autoPackages,
                        true,
                    )(dispatch)
                }
            })
            .catch((err: AxiosError<CartResponseErrorDTO>) => {
                const errorResponse = err.response
                dispatch(updateCartDataFailureAction(errorResponse))
            })
    }

/**
 * Function to call update cart Api to update pickup location for BOPIS products
 * @param {string} guid
 * @param {string} storeId
 * @return {Promise}
 */
export const updateCartPickupLocation =
    (guid: string, storeId: string) =>
    (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const { cartItemsData } = getState().cart
        return cartService
            .updateCart(guid, storeId, cartItemsData.cart?.orderEntries?.length)
            .then(data => {
                replaceEmptyImagesWithDefault((data?.data as CartItemsData)?.orderEntries, 'images')
                const cartFilteredData = getFilteredCartItems(data.data)
                // TODO: code commented which can be used to handle 206 error
                // if (data.status === httpStatusCodes.successCode) {
                dispatch(updateCartDataSuccessAction(cartFilteredData))
                // }
                // // added for OCCP-18509 cds api change for postalcode change response
                // if (data.status === httpStatusCodes.partialSuccessCode) {
                //     dispatch(updatePostalCodePartialSuccessAction(cartFilteredData))
                // }
            })
            .catch((err: AxiosError<CartResponseErrorDTO>) => {
                const errorResponse = err.response
                dispatch(updateCartDataFailureAction(errorResponse))
            })
    }

// Action for updating the cart data when delivery method for the bulk item changed
export const updateBulkDeliveryMethod =
    (requestPayload: UpdateDeliveryRequest) =>
    (dispatch: Dispatch): Promise<void> => {
        const guid = appCacheService.getCartGuid()
        return cartService
            .updateBulkDeliveryOption(guid, requestPayload)
            .then(data => {
                replaceEmptyImagesWithDefault((data?.data as CartItemsData)?.orderEntries, 'images')
                const cartFilteredData = getFilteredCartItems(data.data)
                if (requestPayload.bulkDeliveryOptionType) {
                    // Analytics info updated
                    dispatch(updateAnalyticsInfo({ updateType: CartUpdateType.BULK_DELIVERY_OPTION }))
                }
                dispatch(updateDeliveryMethodSuccessAction(cartFilteredData))
            })
            .catch((err: AxiosError<CartResponseErrorDTO>) => {
                const errorResponse = err.response
                dispatch(updateDeliveryMethodFailureAction(errorResponse))
            })
    }

/**
 * function to update postal code
 * @param {string} postalCode - given postal code
 * @param {Object} postalCodeData - list of postal codes available
 * @param {boolean} showSpinner
 * @return {Dispatch}
 */
export const updatePostalCode =
    (postalCode: string, postalCodeData: PostalCodeData, showSpinner = false) =>
    (dispatch: Dispatch): Promise<void> => {
        const baseEndPoint = getPostalCodeMock(postalCode, postalCodeData)
        showSpinner && dispatch(setShowSpinner(true)) // Display spinner if required
        return httpClient
            .apiGet(baseEndPoint, {}, 'AEM_MOCK_JSON')
            .then((data: AxiosResponse<CartItemsData>) => {
                if (data.data.successFlag) {
                    replaceEmptyImagesWithDefault(data?.data?.orderEntries, 'images')
                    const cartFilteredData = getFilteredCartItems(data.data)
                    dispatch(updatePostalCodeSuccessAction(cartFilteredData))
                    // Analytics
                    // TODO: dispatching separately looks like repetition of work
                    // update information for analytics
                    dispatch(updateAnalyticsInfo({ updateType: CartUpdateType.FULFILLMENT_CHANGE }))
                } else dispatch(updatePostalCodeErrorAction(data.data as unknown as CartResponseErrorDTO))
            })
            .catch((err: AxiosError<CartResponseErrorDTO>) => {
                const errorResponse = err.response
                dispatch(updatePostalCodeErrorAction(errorResponse))
            })
            .finally(() => {
                dispatch(setShowSpinner(false))
            })
    }

/**
 * action to update postal code in cart
 * @param {string} postalCode - given postal code
 * @param {string} cartGUID - list of postal codes available
 * @param {boolean} showSpinner
 * @param {boolean} isBulk - for bulk items
 * @param {string} context - from which page the api is getting called
 * @param {string} outOfRangeErrorCode
 * @return {Dispatch}
 */
export const updatePostalCodeForCart =
    (
        postalCode: string,
        cartGUID: string,
        showSpinner = false,
        isBulk?: boolean,
        context?: string,
        outOfRangeErrorCode?: string,
    ) =>
    (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        showSpinner && dispatch(setShowSpinner(true)) // Display spinner if required
        dispatch(updatePostalCodeDispatchStartedAction(true))
        dispatch(resetCartValidationsAction())
        const { selectedPreferredStoreId, preferredStoreDetails } = getState().storeDetails
        const { oneTimeCartStore, isStoreSharedCart } = enableDestructOnUndefinedData(
            getState().sharedCart?.cartConsuming?.storeSharedCart,
        ) as StoreSharedCart
        const isOneTimeCartFlag = isOneTimeCartForAuthUser(
            enableDestructOnUndefinedData(getState().userProfile),
            enableDestructOnUndefinedData(getState().sharedCart),
        )
        return cartService
            .updatePostalCodeToCart(
                postalCode,
                cartGUID,
                isStoreSharedCart ? oneTimeCartStore?.id : selectedPreferredStoreId,
                isOneTimeCartFlag,
                isBulk,
                context,
                preferredStoreDetails?.isExpressDeliveryEligible,
            )
            .then(data => {
                replaceEmptyImagesWithDefault((data?.data as CartItemsData)?.orderEntries, 'images')
                const cartFilteredData = getFilteredCartItems(data.data)
                cartFilteredData.isPostalCodePartialSuccess = Boolean(
                    cartFilteredData.cart?.errorCode && cartFilteredData.cart.errorCode === outOfRangeErrorCode,
                )
                // TODO: code commented which can be used to handle 206 error for future reference when all APIs return status code as 206
                // if (data.status === httpStatusCodes.successCode) {
                const pageType = getPageType() === pageTypes.checkout
                // intentional revert for OCCP 20426 to address the primary issue
                isStoreSharedCart
                    ? dispatch(updateStoreInitiatedCartPostalCode(cartFilteredData))
                    : dispatch(updatePostalCodeSuccessAction(cartFilteredData, pageType))
                // }
                // // added for OCCP-18509 cds api change for postalcode change response
                // if (data.status === httpStatusCodes.partialSuccessCode) {
                //     dispatch(updatePostalCodePartialSuccessAction(cartFilteredData))
                // }
            })
            .catch((err: AxiosError<CartResponseErrorDTO>) => {
                isStoreSharedCart
                    ? dispatch(updateStoreInitiatedCartPostalCodeFail(err?.response))
                    : dispatch(updatePostalCodeFailureAction(err?.response))
            })
            .finally(() => {
                dispatch(setShowSpinner(false))
            })
    }

/**
 * Action to update postal code in cart when postal code is express delivery elligible
 *
 * @param {string} postalCode - given postal code
 * @param {string} selectedPreferredStore - given postal code
 * @param {string} selectedSku - product sku elligible for express delivery
 * @param {boolean} isBulk - for bulk items
 * @param {boolean} isProductAndStoreAvailableForED
 * @param {string} cartGUID - list of postal codes available
 * @param {string} outOfRangeErrorCode
 * @return {void}
 */
export const getDeliveryOptionsForCartPage =
    (
        postalCode: string,
        selectedPreferredStore: string | number,
        selectedSku: string | undefined,
        isBulk = false,
        isProductAndStoreAvailableForED = false,
        cartGUID: string,
        outOfRangeErrorCode?: string,
    ) =>
    (dispatch: Dispatch<any>): void => {
        deliveryOptionsService
            .fetchDeliveryOptions(
                postalCode,
                selectedPreferredStore,
                selectedSku,
                isBulk,
                isProductAndStoreAvailableForED,
            )
            .then((deliveryOptionsData: Record<string, SuccessResponse>) => {
                const response = deliveryOptionsData.data
                if (response.isExpressDeliveryEligible) {
                    dispatch(setExpressDeliveryPostalCodeOutOfRange(false))
                    dispatch(updatePostalCodeForCart(postalCode, cartGUID, true, isBulk, '', outOfRangeErrorCode))
                } else {
                    dispatch(setExpressDeliveryPostalCodeOutOfRange(true))
                }
            })
            .catch((err: AxiosError<CartResponseErrorDTO>) => {
                dispatch(updatePostalCodeFailureAction(err?.response))
            })
    }

/**
 * action to estimate shipping cost without updating cart
 * @param {string} postalCode - given postal code
 * @param {string} cartGUID - list of postal codes available
 * @param {boolean} showSpinner
 * @return {Dispatch}
 */
export const estimateShippingCost =
    (postalCode: string, cartGUID: string, showSpinner = false) =>
    (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        showSpinner && dispatch(setShowSpinner(true)) // Display spinner if required
        dispatch(resetShippingEstimation())
        dispatch(shippingEstimationInProgress(true))
        const { selectedPreferredStoreId, preferredStoreDetails } = getState().storeDetails
        return cartService
            .estimateShippingCost(
                postalCode,
                cartGUID,
                selectedPreferredStoreId,
                preferredStoreDetails?.isExpressDeliveryEligible,
            )
            .then(data => {
                dispatch(shippingEstimationSuccess(data.data))
            })
            .catch((err: AxiosError<CartResponseErrorDTO>) => {
                dispatch(shippingEstimationError(err.response))
            })
            .finally(() => {
                dispatch(setShowSpinner(false))
                dispatch(shippingEstimationInProgress(false))
            })
    }

/**
 * Action to change cart delivery mode
 * @param {string} deliveryMode - delivery mode value
 * @return {Dispatch}
 */
export const changeCartDeliveryMode =
    (deliveryMode: string) =>
    (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        dispatch(setShowSpinner(true))
        dispatch(changeCartDeliveryModeReset())
        const cartId = getState().cart.cartItemsData.cart.cartId
        return cartService
            .changeCartDeliveryMode(cartId, deliveryMode)
            .then(() => {
                getCartItemsData(cartId, '', true)(dispatch, getState)
            })
            .catch((error: AxiosError<CartResponseErrorDTO>) => {
                dispatch(changeCartDeliveryModeFailure(error.response))
            })
            .finally(() => {
                dispatch(setShowSpinner(false))
            })
    }

/**
 * Action to apply promo code to the cart
 * @param {string} promoCode - promo code
 * @return {Dispatch}
 */
export const applyPromoCode =
    (promoCode: string) =>
    (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        dispatch(setShowSpinner(true))

        const cartId = getState().cart.cartItemsData?.cart?.cartId
        const { selectedPreferredStoreId } = getState().storeDetails
        return cartService
            .applyPromoCode(cartId, promoCode, selectedPreferredStoreId)
            .then(response => {
                const cartFilteredData = getFilteredCartItems(response.data)
                dispatch(updateCartDataSuccessAction(cartFilteredData))
                dispatch(applyPromoCodeSuccessAction(promoCode))
            })
            .catch((error: AxiosError<CartResponseErrorDTO>) => {
                dispatch(applyPromoCodeErrorAction(error?.response?.data))
            })
            .finally(() => {
                dispatch(setShowSpinner(false))
            })
    }

/**
 * Action to delete promo code from the cart
 * @param {string} promoCode - promo code
 * @return {Dispatch}
 */
export const deletePromoCode =
    (promoCode: string) =>
    (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        dispatch(setShowSpinner(true))

        const cartId = getState().cart.cartItemsData?.cart?.cartId
        const { selectedPreferredStoreId } = getState().storeDetails
        return cartService
            .deletePromoCode(cartId, promoCode, selectedPreferredStoreId)
            .then(response => {
                const cartFilteredData = getFilteredCartItems(response.data)
                dispatch(updateCartDataSuccessAction(cartFilteredData))
            })
            .catch((error: AxiosError<CartResponseErrorDTO>) => {
                logError(error?.response?.data, 'Reason for Delete Promo Code error')
            })
            .finally(() => {
                dispatch(setShowSpinner(false))
            })
    }

/**
 * function to track selected fulfillment option
 * @param {string} value - selected fulfillment option
 * @param {number} entryNumber
 * @return {Dispatch}
 */
export const changeFulFillmentOption =
    (value: string, entryNumber: number) =>
    (dispatch: Dispatch): void => {
        dispatch(
            setSelectedFulFillmentOption({
                mode: value,
                entryNumber,
            }),
        )
    }

/**
 * function to track selected fulfillment option
 * @param {string} value - selected fulfillment option
 * @param {string} guid
 * @param {number[]} entryNumberArray
 * @param {boolean} showSpinner
 * @return {Dispatch}
 */
export const updateDeliveryMethodAction =
    (value: string, guid: string, entryNumberArray: number[], showSpinner = false) =>
    (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        showSpinner && dispatch(setShowSpinner(true)) // Display spinner if required
        const postalCode = getState().cart.cartItemsData.deliveryAddress?.address?.postalCode || ''
        const { selectedPreferredStoreId } = getState().storeDetails
        return cartService
            .updateDeliveryOption(value, guid, selectedPreferredStoreId, postalCode, entryNumberArray)
            .then(data => {
                replaceEmptyImagesWithDefault((data?.data as CartItemsData)?.orderEntries, 'images')
                const cartFilteredData = getFilteredCartItems(data.data)
                dispatch(updateCartDataSuccessAction(cartFilteredData))
                // update information for analytics
                dispatch(
                    updateAnalyticsInfo({
                        updateType: CartUpdateType.FULFILLMENT_CHANGE,
                        option: { type: value, entryNumber: entryNumberArray[0] },
                    }),
                )
            })
            .catch((err: AxiosError<CartResponseErrorDTO>) => {
                const errorResponse = err.response
                dispatch(updateCartDataFailureAction(errorResponse))
            })
            .finally(() => {
                dispatch(setShowSpinner(false))
            })
    }

/**
 * function to get all postal code data related to different error scenario
 * to-do: to be removed when actual api integration is done
 * @return {Dispatch}
 */
export const getPostalCodeData =
    () =>
    (dispatch: Dispatch): Promise<void> => {
        const url = API_ENDPOINTS.getPostalCodeData

        return httpClient
            .apiGet(url, {}, 'AEM_MOCK_JSON')
            .then(data => {
                dispatch(getPostalCodeDataAction(data.data as PostalCodeData))
            })
            .catch((err: AxiosError<CartModificationDTO>) => {
                const errorResponse = err.response
                dispatch(getPostalCodeDataError(errorResponse))
            })
    }

/**
 * function to reset cart validation error messages
 * @return {Dispatch}
 */
export const resetCartValidations =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetCartValidationsAction())
    }

/**
 * gets cart items for auth users
 * @param {string} guid
 * @return {void}
 */
export const getCartItemsForAuthForMerge =
    (guid: string) =>
    (dispatch: Dispatch, getState: () => RootState): void => {
        const { selectedPreferredStoreId } = getState().storeDetails
        cartService
            .getCartItems(guid, '', selectedPreferredStoreId)
            .then((cartData: { data: CartItemsData }) => {
                replaceEmptyImagesWithDefault(cartData?.data?.orderEntries, 'images')
                setSflIdToLocalStorage(cartData?.data?.saveForLaterId)
                const cartFilteredData = getFilteredCartItems(cartData.data)
                cartFilteredData.xhrInfo = {
                    getCartInfo: HttpRequestState.done,
                }
                fillCheckoutDataFromAPI(cartFilteredData.cart, dispatch)
                dispatch(getCartItemsSuccessAction(cartFilteredData))
                dispatch(getCartItemsForAuthForMergeSuccessAction(cartFilteredData))
            })
            .catch((err: Record<string, unknown>) => {
                logError(err?.response?.data, 'Reason for Get Cart Error')
                dispatch(getCartItemsForAuthForMergeSuccessAction({} as FilteredCartData))
            })
    }

/**
 * gets mini cart items for auth users
 * @param {string} guid
 * @return {void}
 */
export const getMiniCartItemsForAuthForMerge =
    (guid: string) =>
    (dispatch: Dispatch, getState: () => RootState): void => {
        const { selectedPreferredStoreId } = getState().storeDetails
        cartService
            .getMiniCartItems(guid, '', selectedPreferredStoreId)
            .then((cartData: { data: MiniCartData }) => {
                dispatch(getMiniCartItemsSuccessAction(cartData.data))
                dispatch(getCartItemsForAuthForMergeSuccessAction(convertToCartItemsDataForAuth(cartData.data)))
            })
            .catch((err: AxiosError<CartResponseErrorDTO>) => {
                const errorResponse = err.response.data
                if (errorResponse.statusCode === httpStatusCodes.gone) {
                    appCacheService.removeCartGuid(guid)
                } else {
                    logError(errorResponse, 'Reason for Get Mini Cart Error')
                }
                dispatch(getCartItemsForAuthForMergeSuccessAction({} as FilteredCartData))
            })
    }

/**
 * Calls merge cart API
 * @param {string} guestCartId
 * @param {string} authCartId
 * @param {number[]} orderEntries
 * @param {boolean} fromLogin
 * @param {boolean} showSpinner
 * @return {Promise<void>}
 */
export const mergeAuthCartWithGuest =
    (guestCartId: string, authCartId: string, orderEntries?: number[], fromLogin?: boolean, showSpinner = false) =>
    (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const { selectedPreferredStoreId } = getState().storeDetails
        showSpinner && dispatch(setShowSpinner(true)) // Display spinner if required
        return cartService
            .mergeCartItems(guestCartId, authCartId, selectedPreferredStoreId, orderEntries)
            .then((data: AxiosResponse<CartItemsData>) => {
                const updatedGuid = data?.data?.cartId
                appCacheService.setCartGuid(updatedGuid)
                replaceEmptyImagesWithDefault(data?.data?.orderEntries, 'images')
                const cartFilteredData = getFilteredCartItems(data.data)
                cartFilteredData.xhrInfo = {
                    getCartInfo: HttpRequestState.done,
                }
                fillCheckoutDataFromAPI(cartFilteredData.cart, dispatch)
                dispatch(getCartItemsSuccessAction(cartFilteredData))
                fireAnalyticsForMerge(guestCartId, authCartId, cartFilteredData, orderEntries, fromLogin)
                dispatch(mergeCartSuccessAction(true))
            })
            .catch((err: AxiosError<CartResponseErrorDTO>) => {
                const errorResponse = err.response
                dispatch(mergeCartSuccessAction(false))
                dispatch(setXhrInfoForGetCart(HttpRequestState.failed))
                dispatch(getCartItemsFailureAction(errorResponse))
                errorAnalytics(errorResponse?.data.message, errorResponse?.data.errOrigin)
            })
            .finally(() => {
                showSpinner && dispatch(setShowSpinner(false))
            })
    }

/**
 * @param {string} guid
 * @return {void}
 */
export const getPromoMessage =
    (guid: string) =>
    (dispatch: Dispatch): void => {
        cartService
            .getPromoMessage(guid)
            .then(promoData => {
                dispatch(getPromoMessageSuccessAction(promoData.data))
            })
            .catch((err: AxiosError<CartResponseErrorDTO>) => {
                logError(err?.response?.data, 'Reason for Get Promo Message')
            })
    }

/**
 * Function to remove cart error data
 * @return {Promise<void>}
 */
export const clearAddToCartErrorData =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(clearAddToCartErrorDataAction())
    }

/**
 * Function to add or remove reusable bag to cart
 * @param {boolean} optInOut
 * @param {string} guid
 * @param {string} selectedPreferredStoreId
 * @param {boolean} showSpinner
 * @return {Promise<void>}
 */
export const updateValueBagsOptIn =
    (optInOut: boolean, guid: string, selectedPreferredStoreId: string, showSpinner = false) =>
    (dispatch: Dispatch): Promise<void> => {
        showSpinner && dispatch(setShowSpinner(true))

        return cartService
            .updateReusableBagsOptIn(optInOut, guid, selectedPreferredStoreId)
            .then(data => {
                replaceEmptyImagesWithDefault((data?.data as CartItemsData)?.orderEntries, 'images')
                const newCartFilteredData = getFilteredCartItems(data.data)
                dispatch(updateCartDataSuccessAction(newCartFilteredData))
            })
            .catch((err: AxiosError<CartResponseErrorDTO>) => {
                const errorResponse = err.response
                dispatch(updateCartDataFailureAction(errorResponse))
            })
            .finally(() => {
                dispatch(setShowSpinner(false))
            })
    }

/**
 * Clear shopping cart limit failure value
 * @return {void}
 */
export const clearCartLimitItemsFailure =
    () =>
    (dispatch: Dispatch, getState: () => RootState): void => {
        const cartLimitItemsFailure = getState().cart.cartLimitItemsFailure
        if (checkDataLength(cartLimitItemsFailure)) {
            dispatch(clearShoppingCartLimitItemsFailure())
        }
    }

/**
 * Clear shipping fees data if store eligible for express - reset shippingEstimationsStandardAndExpress if not - reset shippingEstimation
 * @return {void}
 */
export const clearShippingFeesData =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetShippingEstimation())
    }
