import React, { useEffect, useState } from 'react'
import { libUtils, updateUrlHistory } from '@nl/lib'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../redux/reducers'

import { MagicNumber } from '../../analytics/analytics.type'
import { commonContentSelector } from '../../redux/selectors/commonContent.selectors'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { HrefLangLinkData, ProductResponseErrorDTO } from '../../redux/models/product.interface'
import { CartResponseErrorDTO } from '../../redux/models/cart.interface'
import { skuNotFoundAction } from '../../redux/actionCreators'
import BaseService from '../../services/base.service'
import useProduct from './useProduct'
import { storeDetailsDataSelector } from '../../redux/selectors/storeDetails.selectors'
import { fetchVariantsProductDataBySku } from '../../redux/actions'
import { isAutomotivePDP } from '../Vehicles/Vehicle.helper'
import { SeoHelper } from '../../Seo/helpers/Seo.helper'
import { httpStatusCodes } from '../../globalConstants'
import { secondaryNavPropsSelector } from '../../redux/selectors/headerProps.selectors'

/**
 * ProductPageInit to invoke product details api
 * @return {null}
 */

const ProductPageInit: React.FC = () => {
    const dispatch = useDispatch()
    const { productData, productErrorResponse, skuCode } = useProduct()
    const { skuNotFound } = useSelector((state: RootState) => state.product)
    const { addToCartErrorData } = useSelector((state: RootState) => state.cart)
    const { selectedPreferredStoreId } = useSelector(storeDetailsDataSelector)
    const [runOnce, setRunOnce] = useState(true)
    const { commonContentAvailable } = useSelector(commonContentSelector)
    const { notFoundOnAbsentSKuEnabled } = commonContentAvailable?.product || {}

    const isAutomotive = isAutomotivePDP(productData?.fitmentTypeCode, productData?.productWheelType)
    const secondaryNavProps = useSelector(secondaryNavPropsSelector)
    const { enablePrerenderMetaTag } = secondaryNavProps || {}
    useEffect(() => {
        if (
            notFoundOnAbsentSKuEnabled &&
            skuCode &&
            checkDataLength(productData) &&
            !productData?.skus?.some(sku => sku.code === skuCode && sku.variantStatus === 'ACTIVE')
        ) {
            dispatch(skuNotFoundAction())
        }
    }, [dispatch, notFoundOnAbsentSKuEnabled, skuCode, productData])
    useEffect(() => {
        if (!BaseService.isMock() && runOnce && checkDataLength(productData)) {
            const { hreflangLinkData } = productData
            if (!!hreflangLinkData) {
                const language = libUtils.getLanguage()
                const currentPath = window.location.pathname
                const linkData = hreflangLinkData.find((hrefFlagLink: HrefLangLinkData) => {
                    return hrefFlagLink.hreflang === language
                })
                if (checkDataLength(linkData)) {
                    const search = window.location.search
                    const hash = window.location.hash
                    const path = window.location.pathname.toLowerCase().split('.')
                    const extension = path[path.length - MagicNumber.ONE]
                    const urlToUpdate = !!skuCode
                        ? `${String(linkData?.href).split('.html')[0]}.${skuCode}.${extension}${search}${hash}`
                        : `${String(linkData?.href)}${search}${hash}`
                    if (
                        enablePrerenderMetaTag &&
                        linkData?.href.split('.')[MagicNumber.ZERO] !== currentPath.split('.')[MagicNumber.ZERO]
                    ) {
                        const prerenderHeaderURL = window.location.origin + urlToUpdate
                        SeoHelper.createMetaTag('name', 'prerender-status-code', String(httpStatusCodes.redirectCode))
                        SeoHelper.createMetaTag('name', 'prerender-header', `Location: ${prerenderHeaderURL}`)
                    }
                    if (linkData?.href !== currentPath) {
                        updateUrlHistory(urlToUpdate)
                    }
                }
            }
            setRunOnce(false)
        }
    }, [productData, runOnce, skuCode, enablePrerenderMetaTag])

    /**
     * useEffect to make panda call on page load for all possible skus
     */
    useEffect(() => {
        if (!isAutomotive && checkDataLength(productData) && productData.skus) {
            const skus = productData.skus.map(item => {
                return {
                    code: item.code,
                    lowStockThreshold: productData.lowStockThreshold,
                    brand: productData?.brand?.label || '',
                }
            })
            dispatch(fetchVariantsProductDataBySku(skus, selectedPreferredStoreId))
        }
    }, [productData, selectedPreferredStoreId, dispatch, isAutomotive])

    const is404Response = (response: ProductResponseErrorDTO | CartResponseErrorDTO) =>
        checkDataLength(response) && response.status === MagicNumber.FOURHUNDREDFOUR

    useEffect(() => {
        const isNotMock = !window.location.search.includes('mock')
        const notFoundProduct = is404Response(productErrorResponse)
        const noSkuOnAddToCart = notFoundOnAbsentSKuEnabled && is404Response(addToCartErrorData)
        const redirectDueToAbsentSku = notFoundOnAbsentSKuEnabled && skuNotFound
        if (isNotMock && (redirectDueToAbsentSku || notFoundProduct || noSkuOnAddToCart)) {
            window.location.href = encodeURI(window.ODP?.globalLinks.pageNotFoundPath)
        }
    }, [productErrorResponse, addToCartErrorData, skuNotFound, notFoundOnAbsentSKuEnabled])

    return null
}

export default ProductPageInit
