import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon, extractKeyValues, replaceStrWithDynamicVal } from '@nl/lib'
import { PREFIX } from '../../config'
import { BannerVideoModalProps } from './BannerVideoModal.type'
import { VideoModal } from '../VideoModal'
import getFileNameFromUrl from '../../utils/getFileNameFromUrl'
import { useSelector } from 'react-redux'
import { commonContentSelector } from '../../redux/selectors/commonContent.selectors'

const BannerVideoModal: React.FC<BannerVideoModalProps> = ({ ...props }): JSX.Element => {
    const { commonContentAvailable } = useSelector(commonContentSelector)
    const [visibility, setVisibility] = useState(false)
    const { videoUrl, videoThumbnailUrl, videoType } = props
    const containerClass = 'bannerVideoModal'
    const [[a11yVideoPlayLabel]] = extractKeyValues(commonContentAvailable, [{ accessibility: ['a11yVideoPlayLabel'] }])
    const videoName = getFileNameFromUrl(videoUrl)
    const videoLabel = replaceStrWithDynamicVal(a11yVideoPlayLabel, videoName)

    /**
     * Close Video Modal
     * @return {void}
     */
    const closeModal = (): void => {
        setVisibility(false)
    }

    /**
     * Render Video
     * @return {{JSX.Element}} Render component
     */
    const renderVideo = (): JSX.Element => {
        const buttonProps = {
            id: `${PREFIX}-${containerClass}__button`,
            type: 'icon_button',
            ariaLabel: videoLabel,
            onClick: () => setVisibility(true),
            buttonType: 'button',
        }
        return (
            <div className={`${PREFIX}-${containerClass} `} data-testid="banner-video-modal">
                {/* Thubnail container and click event element */}
                <img src={videoThumbnailUrl} alt="" className={`${PREFIX}-${containerClass}__img`} />
                <Button {...buttonProps}>
                    <Icon type="ct-video-light" size="large" />
                </Button>
                <VideoModal
                    videoUrl={videoUrl}
                    videoThumbnailUrl={videoThumbnailUrl}
                    isOpen={visibility}
                    closeModal={closeModal}
                    videoType={videoType}
                />
            </div>
        )
    }

    return renderVideo()
}

BannerVideoModal.propTypes = {
    videoUrl: PropTypes.string.isRequired,
    videoThumbnailUrl: PropTypes.string.isRequired,
    videoType: PropTypes.string.isRequired,
}
export default BannerVideoModal
