import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { RoadRatingBadgeProps } from './RoadRatingBadge.types'
import { magicNumber } from '../../utils/magicNumber'

/**
 * Road rating badge
 * @return {JSX.Element} returns Overlay component
 */
declare const window: Window & typeof globalThis
if (typeof window !== 'undefined') {
    window.analyticsLayer = window.analyticsLayer || []
}

const RoadRatingBadge: React.FC<RoadRatingBadgeProps> = ({ ...props }): JSX.Element => {
    const roadRatingBadgeClick = (): void => {
        window.analyticsLayer = window.analyticsLayer || []
        window.analyticsLayer.push({
            event: 'interaction',
            eventParameters: {
                action: 'click on internal link',
                category: 'interaction',
                label: 'road rating badge',
                value: '',
                location: 'pdp - road rating badge', // sample output: 'pdp - header'
            },
        })

        props.scrollToRoadRating()
    }

    const isReviewAvailable = !!props.isReviewAvailable ? `${PREFIX}-road-rating-badge--avaialble` : ''

    const buttonRef = useRef()
    return props.aggregateValue ? (
        <button
            data-testid="road-rating-badge-id"
            ref={buttonRef}
            aria-hidden={props.ariaHidden ? props.ariaHidden : false}
            tabIndex={props.ariaHidden && magicNumber.MINUS_ONE}
            className={`${PREFIX}-road-rating-badge ${PREFIX}-road-rating-badge-btn ${isReviewAvailable}`}
            onClick={roadRatingBadgeClick}>
            {props.roadRatedLabel} - {props.aggregateValue}
        </button>
    ) : null
}

RoadRatingBadge.propTypes = {
    roadRatedLabel: PropTypes.string,
    aggregateValue: PropTypes.number,
    scrollToRoadRating: PropTypes.func,
    isReviewAvailable: PropTypes.bool,
}

export default RoadRatingBadge
