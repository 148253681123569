/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PREFIX, BREAKPOINTS } from '../../../config'
import { AccountInfo, PencilBannerProps } from './PencilBanner.type'
import {
    currencyShortForm,
    currencyFormat,
    chevronDown,
    chevronUp,
    transactionNavLink,
} from '../../../globalConstants/global.constant'
import {
    AccountsDropdown,
    getLoyaltyCards,
    LoyaltyCards,
    Icon,
    extractKeyValues,
    SkeletonComponent,
    magicNumber,
    CTMoneySnippet,
    Wishlist,
    Button,
    removeHtmlTagsFromString,
    useGlobalResizeEvent,
    isEscPressed,
    isTabPressed,
    libUtils,
    isSpacePressed,
    isEnterPressed,
    useOnKeyDownOutside,
    useOnKeyUpOutside,
    useOnClickOutside,
    urlRedirection,
} from '@nl/lib'
import { isLoyaltyCard } from './PencilBanner.helper'
import { UserProfileData } from '../../../redux/models/user.profile.interface'
import HeaderNavigationHelper from '../../../helpers/headerNavigation.helper'
import LanguageToggleHandler from './LanguageToggle/LanguageToggleHandler'
import { AccountOptions } from '../AccountsPencilBanner.type'
import { analyticsAttributes } from '../../../globalConstants/analyticsParams.constant'
import { setMenuPosition } from '../../SideNavigation/SideNavigation/SideNavigation.helper'
import checkNestedProps from '../../../utils/checkNestedProps'
import { gigyaLoadFunctions } from '../../GigyaScreen/GigyaScreen'
import { fetchUserProfile } from '../../../redux/actions'
import { commonContentSelector } from '../../../redux/selectors/commonContent.selectors'
import { lastFourDigitsRegex as cardRegex } from '../../LinkRewards/LinkRewards.constants'
import {
    isAuthFlowExecutedSelector,
    signOutSuccessSelector,
    userProfileDataSelector,
} from '../../../redux/selectors/userProfile.selectors'
import { tokenStateSelector } from '../../../redux/selectors/tokenState.selectors'
import { isGigyaLoadedSelector } from '../../../redux/selectors/gigyaScreenSetData.selectors'
import { semaFoneEnabledSelector } from '../../../redux/selectors/checkout.selectors'
import SanitizeStringContentWrapper, { sanitizeStringContent } from '@nl/lib/src/utils/sanitizeStringContent'

/**
 * this function returns the acount information
 * @param { UserProfileData }  profileDetails - profile details
 * @param { string }  authenticatedUserGreetingText - greeting text for logged in user
 * @param { string } accountsLabel - label for guest user
 * @return { accountInfo }
 */
const accountInformation = (profileDetails: UserProfileData): AccountInfo => {
    const isLoggedIn = profileDetails ? Boolean(Object.keys(profileDetails).length) : false
    const isLoyalAccountLinked = !!profileDetails?.loyalty?.cardNumber
    const userBalance = profileDetails?.balance

    return {
        isLoggedIn,
        isLoyalAccountLinked,
        userBalance,
    }
}

const {
    eventParameters: {
        location: { header },
    },
} = analyticsAttributes

const featureType = header

/**
 * PencilBanner component
 * @param {PencilBannerProps} props
 * @return {JSX.Element} returns PencilBanner component
 */
const PencilBanner: React.FC<PencilBannerProps> = ({
    signOutCallBack,
    profileDetails,
    currentLocale,
    isAccountsPage,
    accountsLabel,
    accountOptions: redirectionLinks,
    custServiceLabel,
    custServiceURL,
    languageLabel,
    customerServiceTarget,
    alternateLanguageA11y,
    buttonName,
    profileAPIRef,
    quantumMetricAttribute,
    ctMoneyBalanceLabel,
    ctMoneyBalanceAllyLabel,
    ctMoneyBalanceURL,
    ctMoneyBalanceTarget,
    wishlistUrl,
    a11yWishlist,
    wishlistCount,
    maximumCount,
    isSingleSignInRender,
    orderStatusLabel,
    orderStatusAllylabel,
    orderStatusURL,
    orderStatusTarget,
    emailSignUpLabel,
    emailSignUpAllylabel,
    isBrowseOnlyMod,
    accountButtonId,
}: PencilBannerProps) => {
    // For Toggling the dropdown.
    const [toggleDropDown, setToggleDropDown] = useState(isAccountsPage)
    const [dropdownIcon, setDropdownIcon] = useState(chevronDown)
    const [selectLastMenuItem, setSelectLastMenuItem] = useState(false)
    const { commonContentAvailable } = useSelector(commonContentSelector)
    const semaFoneEnabled = useSelector(semaFoneEnabledSelector)
    const {
        signOutLabel,
        orText,
        signInLabel,
        registerLink,
        registerLabel,
        registerNowLabel,
        triangleRewardsIcon,
        triangleRewardsLabel,
        rewardsRegisterRichText,
        rewardsBalanceLabel,
        backToMainLabel,
        cardNumberLabel,
        cardNumberLabelIcon,
        rewardsUpsellingMessage,
    } = HeaderNavigationHelper.getPencilBannerGlobalProps(commonContentAvailable)

    // Checking for the user profile data response and then assigning the accounts label
    const isAuthFlowExecuted = useSelector(isAuthFlowExecutedSelector)
    const userProfileData = useSelector(userProfileDataSelector)
    const signOutSuccess = useSelector(signOutSuccessSelector)
    const isGigyaLoaded = useSelector(isGigyaLoadedSelector)
    const [isDesktop, setIsDesktop] = useState(window.innerWidth >= BREAKPOINTS.desktopMinWidth)
    const handleResize = () => {
        setIsDesktop(window.innerWidth >= BREAKPOINTS.desktopMinWidth)
    }
    useGlobalResizeEvent(handleResize)

    const getQmDataAttribute = () =>
        quantumMetricAttribute?.type
            ? { [`data-qm-${quantumMetricAttribute?.type}`]: quantumMetricAttribute?.value }
            : {}
    const quantumMetricDataAttribute = getQmDataAttribute()
    const className = `${PREFIX}-pencil-banner`

    if (!isAuthFlowExecuted && !signOutSuccess) {
        buttonName = <SkeletonComponent skeletonClass={`${PREFIX}-pencil-banner__skeleton`} />
    } else {
        buttonName = accountsLabel
    }
    const tokenState = useSelector(tokenStateSelector)

    const keyCodes: Record<string, number> = libUtils.keyCodes

    const { isLoggedIn, isLoyalAccountLinked, userBalance } = accountInformation(profileDetails)

    const [[loginPageLink]] = extractKeyValues(commonContentAvailable, [
        {
            globalLinks: ['loginPageLink'],
        },
    ]) as [[string]]

    const registerNowLink = checkNestedProps(commonContentAvailable, 'accountDashboard', 'registerNowLink') as string

    const isLoyaltyCardUser = isLoyaltyCard(userProfileData)

    const nonLoyaltyAccountOption = redirectionLinks?.filter(
        entry => !entry?.navigationRedirectionLink?.includes(transactionNavLink),
    )

    const accountOptions = isLoggedIn
        ? isLoyaltyCardUser
            ? redirectionLinks
            : nonLoyaltyAccountOption
        : redirectionLinks?.map((link: AccountOptions) => {
              return {
                  ...link,
                  navigationRedirectionLink: `${loginPageLink}?returnURL=${encodeURIComponent(
                      link.navigationRedirectionLink,
                  )}`,
              }
          })

    // use Ref to achieve focus on Button for escapeHandler
    const accountButton = useRef<HTMLDivElement>(null)

    // state to set focus on the account button.
    const [isDropDownClosed, setIsDropDownClosed] = useState(false)

    /**
     * Handle Enter event on the account button
     * @param {React.KeyboardEvent<HTMLButtonElement>} e - account button event.
     */
    const buttonKeyBoardClickHandler = (e: React.KeyboardEvent<HTMLButtonElement>): void => {
        if (e.keyCode === keyCodes['downArrow'] || e.keyCode === keyCodes['upArrow']) {
            setToggleDropDown(true)
            e.keyCode === keyCodes['upArrow'] && setSelectLastMenuItem(true)
        }
    }

    /**
     * state handler.
     * @return { void }
     */
    const stateHandler = (): void => {
        setToggleDropDown(false)
        setIsDropDownClosed(true)
    }

    /**
     * When accounts button clicked using mouse.
     * @return { void }
     */
    const accountButtonOnClick = (): void => {
        setIsDropDownClosed(false)
        setToggleDropDown(prevState => !prevState)
    }

    /**
     * Close the dropdown when user press esc button.
     * @param {KeyboardEvent} e - use to capture the esc keycode.
     */
    const menuEscapeHandler = (e: KeyboardEvent): void => {
        if (isEscPressed(e.key)) {
            stateHandler()
            accountButton?.current?.focus()
        }
    }

    // to change the chevron-icon based on dropdown open or close
    useEffect(() => {
        setDropdownIcon(toggleDropDown && !isDropDownClosed ? chevronUp : chevronDown)
        setSelectLastMenuItem(false) // To set the last item when up arrow is pressed on account buttons.
    }, [toggleDropDown, isDropDownClosed])

    const checkVisibilityDropdown = document.querySelector(`.${PREFIX}-pencil-banner__dropdown`)

    useEffect(() => {
        if (toggleDropDown && checkVisibilityDropdown && !isDesktop) {
            setMenuPosition(`.${PREFIX}-pencil-banner__dropdown`)
        }
    }, [toggleDropDown, checkVisibilityDropdown, isDesktop])

    const dispatch = useDispatch()

    useEffect(() => {
        if (tokenState === 'SUCCESS' && profileAPIRef?.current === 0) {
            dispatch(fetchUserProfile())
            profileAPIRef.current = 1
            window.gigya.accounts.hideScreenSet({ screenSet: 'ODP-LoginSignIn' })
        }
    }, [tokenState, dispatch, profileAPIRef])

    // use Ref to achieve functionality for right-part of navigation and button
    const navigationRef = useRef<HTMLDivElement>(null)
    const filterRef = useRef<HTMLDivElement>(null)
    /**
     * function to click outside
     * @param {React.RefObject<HTMLDivElement>} filterRef
     */
    useOnClickOutside(filterRef, () => {
        if (toggleDropDown) {
            stateHandler()
        }
    })

    /**
     * callback function handles dropdown close on space, enter
     * @param { React.KeyboardEvent<HTMLButtonElement> } event
     * @return { void }
     */
    const closeDropDownOnDown = useCallback((event: KeyboardEvent) => {
        if (isSpacePressed(event.key) || isEnterPressed(event.key)) {
            stateHandler()
        }
    }, [])

    useOnKeyDownOutside(navigationRef, closeDropDownOnDown)

    /**
     * callback function handles dropdown close on tab
     * @param { React.KeyboardEvent<HTMLButtonElement> } event
     * @return { void }
     */
    const closeDropDown = useCallback(
        (event: KeyboardEvent) => {
            if (isDesktop && isTabPressed(event.key)) {
                stateHandler()
            }
        },
        [isDesktop],
    )

    useOnKeyUpOutside(navigationRef, closeDropDown)

    /**
     * Function to format the balance
     *
     * @param {string} balance - triangle rewards balance which comes from the api
     * @return {string}
     */
    const getBalance = (balance: string): string => {
        const language = currentLocale + '-' + currencyShortForm
        return new Intl.NumberFormat(language, {
            style: 'currency',
            currency: currencyFormat,
        }).format(Number(balance))
    }

    const renderRegisterOrLinkCards = (): string => {
        return isLoggedIn ? registerNowLink : registerLink
    }

    const renderCTBalance = (): JSX.Element | null => {
        return isLoggedIn && isLoyalAccountLinked ? (
            <CTMoneySnippet
                balance={getBalance(userProfileData?.balance || magicNumber.ZERO.toString())}
                title={ctMoneyBalanceLabel}
                url={ctMoneyBalanceURL}
                target={ctMoneyBalanceTarget}
                allyLabel={ctMoneyBalanceAllyLabel}
                isShowIcon={true}></CTMoneySnippet>
        ) : null
    }

    const getText = removeHtmlTagsFromString(registerLabel)

    /**
     * Function to render join triangle rewards text in header section for guest or when user signs out
     *
     * @return {JSX.Element}
     */

    const renderJoinTriangleRewards = (): JSX.Element => {
        return (
            <span className={`${PREFIX}-pencil-banner__join-triangle-rewards ${PREFIX}-xs-flex`}>
                {registerLabel && <Icon size="md" type="ct-join-triangle-rewards-white" />}
                <SanitizeStringContentWrapper stringContent={registerLabel}>
                    {memoizedStringContent => (
                        <a
                            href={sanitizeStringContent(renderRegisterOrLinkCards())}
                            rel="noopener noreferrer"
                            data-feature-type={featureType}
                            title={getText}
                            data-link-value={getText}
                            dangerouslySetInnerHTML={{ __html: memoizedStringContent }}></a>
                    )}
                </SanitizeStringContentWrapper>
            </span>
        )
    }

    /**
     * Function to render rewards section (display CT Money / join triangle rewards text)
     *
     * @return {JSX.Element}
     */

    const renderRewardsSection = (): JSX.Element | null => {
        if (isBrowseOnlyMod) {
            return null
        }

        return (isAuthFlowExecuted && (!isLoggedIn || !isLoyalAccountLinked)) || signOutSuccess
            ? renderJoinTriangleRewards()
            : renderCTBalance()
    }

    /**
     * Function to render usergreeting text in header for logged in
     *
     * @return {JSX.Element}
     */

    const renderAccountButtonName = (): JSX.Element => {
        return (
            <button
                disabled={!!semaFoneEnabled}
                ref={accountButton}
                aria-haspopup="true"
                aria-controls="accountsMenuDropDown"
                aria-expanded={showToggleDropdown}
                {...quantumMetricDataAttribute}
                className={`${PREFIX}-button--tertiary ${PREFIX}-pencil-banner__first-item`}
                id={accountButtonId}
                onKeyUp={e => {
                    buttonKeyBoardClickHandler(e)
                }}
                onKeyDown={e => {
                    if (e.keyCode === keyCodes['downArrow'] || e.keyCode === keyCodes['upArrow']) {
                        e.stopPropagation()
                        e.preventDefault()
                    }
                }}
                onClick={accountButtonOnClick}>
                <span>
                    {typeof buttonName === 'string' && <Icon size="md" type="ct-account" />}
                    {buttonName}
                </span>
                <span className={`${PREFIX}-pencil-banner__dropdown--icon`}>
                    {typeof buttonName === 'string' && <Icon size={accountBanner} type={dropdownIcon} />}
                </span>
            </button>
        )
    }

    const accountBanner = isSingleSignInRender === 'true' ? 'lg' : 'md'
    const showToggleDropdown = toggleDropDown ? 'true' : 'false'
    const TOP_NAV = 'top-nav'

    /**
     * renders Email Signup option
     *
     * @return {JSX.Element}
     */
    const renderEmailSignUpButton = (): JSX.Element | null => {
        return (
            <>
                <div className={`${PREFIX}-pencil-banner__email-sign-up`}>
                    <Button
                        id="email-signup-button"
                        ariaLabel={emailSignUpAllylabel}
                        onClick={() => gigyaLoadFunctions('ODP-LiteRegistration', undefined, '', false, false)}
                        data-feature-type={emailSignUpLabel}
                        linkSection={TOP_NAV}
                        linkValue={emailSignUpLabel}
                        modifierClass={`${PREFIX}-xs-flex`}>
                        <Icon size="lg" type="ct-inbox" />
                        {emailSignUpLabel}
                    </Button>
                </div>
            </>
        )
    }

    /**
     * renders sign in button or account button
     *
     * @return {JSX.Element}
     */
    const renderSignInOption = (): JSX.Element | null => {
        if (isBrowseOnlyMod) {
            return null
        }
        return (
            <div ref={filterRef}>
                {(isAuthFlowExecuted && !isLoggedIn) || signOutSuccess ? (
                    <div className={`${PREFIX}-xs-none ${PREFIX}-md-block`}>
                        <SanitizeStringContentWrapper
                            stringContent={urlRedirection(
                                loginPageLink,
                                'returnURL',
                                encodeURIComponent(window.location.pathname),
                            )}>
                            {memoizedStringContent => (
                                <a
                                    className={`${PREFIX}-pencil-banner__login ${PREFIX}-xs-flex`}
                                    href={memoizedStringContent}
                                    title={signInLabel}
                                    data-link-value={signInLabel}
                                    data-feature-type={featureType}>
                                    <Icon size="md" type="ct-account" />
                                    {signInLabel}
                                </a>
                            )}
                        </SanitizeStringContentWrapper>
                    </div>
                ) : (
                    renderAccountButtonName()
                )}
                {renderDropDownSection()}
            </div>
        )
    }

    const renderOrderStatus = () => {
        return isBrowseOnlyMod ? null : (
            <a
                className={`${PREFIX}-pencil-banner__second-item ${PREFIX}-xs-flex`}
                href={orderStatusURL}
                target={orderStatusTarget}
                aria-label={orderStatusAllylabel}
                data-link-value={orderStatusLabel}
                data-feature-type={orderStatusLabel}
                dap-wac-link="true"
                dap-wac-loc={TOP_NAV}
                dap-wac-value={orderStatusLabel}>
                <Icon size="lg" type="ct-order-history" />
                {orderStatusLabel}
            </a>
        )
    }

    const renderWishlistLink = () => {
        return isBrowseOnlyMod ? null : (
            <div className={`${PREFIX}-xs-block ${PREFIX}-md-none`}>
                <Wishlist
                    wishlistUrl={wishlistUrl}
                    a11yWishlist={a11yWishlist}
                    wishlistCount={wishlistCount}
                    maximumCount={maximumCount}
                    label={commonContentAvailable?.wishlist?.wishlistLabel || ''}
                    isSideNav={true}
                />
            </div>
        )
    }

    /**
     * Function to render customer support
     *
     * @return {JSX.Element}
     */

    const renderCustomerSupport = (): JSX.Element | null => {
        return isBrowseOnlyMod ? null : (
            <a
                className={`${PREFIX}-pencil-banner__second-item ${PREFIX}-xs-flex`}
                href={custServiceURL}
                target={customerServiceTarget}
                rel="noopener noreferrer"
                data-link-value={custServiceLabel}
                data-feature-type={header}>
                <Icon size="lg" type="ct-customer-support" />
                {custServiceLabel}
            </a>
        )
    }

    /**
     * returns AccountDetailsSection
     *
     * @return {JSX.Element}
     */
    const accountDetailsSection = (): JSX.Element => {
        return (
            <div className={`${PREFIX}-pencil-banner__right-part`} ref={navigationRef}>
                {isSingleSignInRender === 'true' ? (
                    <div className={`${PREFIX}-md-none ${PREFIX}-xs-block`}>{renderSignInOption()}</div>
                ) : (
                    <>
                        {renderWishlistLink()}
                        {isDesktop ? (
                            <>
                                {renderCustomerSupport()}
                                {renderEmailSignUpButton()}
                                {renderOrderStatus()}
                                {renderRewardsSection()}
                                {renderSignInOption()}
                            </>
                        ) : (
                            <>
                                {renderOrderStatus()}
                                {renderCustomerSupport()}
                                {renderEmailSignUpButton()}
                            </>
                        )}
                        <div className={`${PREFIX}-pencil-banner__divider--right`}></div>
                        <LanguageToggleHandler
                            languageLabel={languageLabel}
                            alternateLanguageA11y={alternateLanguageA11y}
                            featureType={header}
                        />
                    </>
                )}
            </div>
        )
    }

    /**
     * Click handler on sign out button.
     */
    const signOutButtonClick = (): void => {
        setToggleDropDown(false)
        signOutCallBack()
    }

    /**
     * click handler for back button
     */
    const backToMain = (): void => {
        stateHandler()
    }

    const userProfileBalance = {
        ...userProfileData,
        balance: null,
    }
    const loyaltyCardProps = {
        isLoggedInRewards: true,
        formatCardNumber: true,
        cardRegex,
        cardSeparator: ' ',
        cardNumberLabelIcon,
        cardNumberLabel,
        userProfileData: userProfileBalance,
    }

    const noLoyaltyCardProps = {
        triangleRewardsLabel,
        rewardsUpsellingMessage,
        rewardsRegisterRichText,
        triangleRewardsIcon,
        registerNowLink,
        registerNowLabel,
        registerLabel,
    }

    /**
     * render Back button
     * @return {JSX.Element}
     */
    const renderBacktoButton = (): JSX.Element | null => {
        return (
            <>
                <div className={`${className}__back-button-container`}>
                    <Button type="tertiary" onClick={() => backToMain()}>
                        <Icon size="lg" type="ct-chevron-left" />
                        {backToMainLabel}
                    </Button>
                </div>
            </>
        )
    }

    /**
     * render Dropdown section
     * @return {JSX.Element}
     */
    const renderDropDownSection = (): JSX.Element => {
        return (
            <>
                {toggleDropDown && (
                    <div className={`${className}__dropdown`}>
                        {renderBacktoButton()}
                        <div className={`${isLoyaltyCardUser ? `${PREFIX}-md-none ${PREFIX}-xs-block` : ''}`}>
                            <LoyaltyCards
                                hasLoyalty={isLoyaltyCardUser}
                                isDashboard={false}
                                isNotTriangle={true}
                                loyalty={getLoyaltyCards(isLoyaltyCardUser ? loyaltyCardProps : noLoyaltyCardProps)}
                            />
                        </div>

                        <AccountsDropdown {...accountDropdownProps} />
                    </div>
                )}
            </>
        )
    }

    const accountDropdownProps = {
        signOutButtonClick,
        isLoggedIn,
        menuEscapeHandler,
        orText,
        signInLabel,
        registerLink,
        registerLabel,
        registerNowLabel,
        triangleRewardsIcon,
        triangleRewardsLabel,
        isLoyalAccountLinked,
        rewardsRegisterRichText,
        rewardsBalanceLabel,
        getBalance,
        userBalance,
        accountOptions,
        signOutLabel,
        selectLastMenuItem,
        backToMain,
        accountsLabel,
        backToMainLabel,
        featureType,
        isGigyaLoaded,
        accountButton,
    }

    return <div className={`${PREFIX}-pencil-banner`}>{accountDetailsSection()}</div>
}

export default PencilBanner
