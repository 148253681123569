import React from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { ErrorScreenProps } from './ErrorScreen.type'
import Button from '../Button'
import { appendReturnUrl } from '../../utils/isUrl'
import BrandFooter from '../BrandFooter'
import { ButtonTypes } from '../Button/Button.types'

/**
 * Error Screen
 * @param {ErrorCardProps} props
 * @return {JSX.Element} returns Error screen
 */

const ErrorScreen: React.FC<ErrorScreenProps> = ({ ...props }) => {
    const {
        errorImage,
        errorImgAltText,
        errorRichText,
        tryAgainCTALabelText,
        tryAgainCTAAllyText,
        btnType,
        onClickTryAgain,
        errorScreenCancelCTALabel,
        cancelError,
        showSpinner,
        cancelUrl,
        footer,
    } = props.data
    const cancelButtonSpacing = !tryAgainCTALabelText ? `${PREFIX}-error-card__cancel-button` : ''
    const btnSpinner = showSpinner ? showSpinner : false
    return (
        <div className={`${PREFIX}-error-card`}>
            <div className={`${PREFIX}-error-card__error-img`}>
                <img src={`${errorImage}`} alt={`${errorImgAltText}`} />
            </div>
            <div className={`${PREFIX}-error-card__content`} dangerouslySetInnerHTML={{ __html: errorRichText }} />
            {!!tryAgainCTALabelText && (
                <Button
                    type={(btnType as ButtonTypes) || 'primary'}
                    size="large"
                    onClick={onClickTryAgain}
                    showSpinner={btnSpinner}
                    ariaLabel={tryAgainCTAAllyText}>
                    {tryAgainCTALabelText}
                </Button>
            )}
            {cancelUrl ? (
                <a
                    href={appendReturnUrl(cancelUrl)}
                    className={`${PREFIX}-error-card__link`}
                    data-link-value={errorScreenCancelCTALabel}>
                    {errorScreenCancelCTALabel}
                </a>
            ) : (
                <div className={`${cancelButtonSpacing}`}>
                    <Button type="tertiary" onClick={cancelError}>
                        {errorScreenCancelCTALabel}
                    </Button>
                </div>
            )}

            {footer?.bannerImage && (
                <BrandFooter
                    image={footer.bannerImage}
                    altText={footer.bannerImageAltText}
                    showDivider={footer.showDivider}
                />
            )}
        </div>
    )
}

ErrorScreen.propTypes = {
    data: PropTypes.exact({
        errorImage: PropTypes.string,
        errorImgAltText: PropTypes.string,
        errorRichText: PropTypes.string,
        tryAgainCTALabelText: PropTypes.string,
        tryAgainCTAAllyText: PropTypes.string,
        btnType: PropTypes.string,
        onClickTryAgain: PropTypes.func,
        errorScreenCancelCTALabel: PropTypes.string,
        cancelError: PropTypes.func,
        showSpinner: PropTypes.bool,
        cancelUrl: PropTypes.string,
    }).isRequired,
}

export default ErrorScreen
