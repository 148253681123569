import React, { useCallback, useEffect, useRef, useState } from 'react'

import { CustomDropdownProps } from './CustomDropdown.type'
import Icon from '../Icon'
import { getInteractiveElements } from '../../helpers/getInteractiveElements.helper'
import { isEnterPressed, isEscPressed, isSpacePressed, isTabPressed } from '../../helpers/checkKeyboardKey.helper'
import { useOnClickOutside, magicNumber } from '../../utils'
import PropTypes from 'prop-types'
import { useOnKeyDownOutside } from '../../utils/useOnKeyDownOutside'
import { useOnKeyUpOutside } from '../../utils/useOnKeyUpOutside'

/**
 * This component should be used as an all-purpose dropdown component.
 * You can provide any component as a child to this component as well as the label prop.
 * If you need a dropdown list (dropdown menu) consider using "Dropdown" component.
 * Note:
 * You will have to stylise children (and label) you provided by yourself in your styles files.
 * @param {CustomDropdownProps} props
 * @return {Element} dropdown component
 */
const CustomDropdown: React.FC<CustomDropdownProps> = props => {
    const { id, children, label, showIcon = true, filterRow, filterPanelRef, forSEOOnLoad } = props

    const [isOpen, setIsOpen] = useState(false)
    // Close dropdown when we press esc in dropdown area

    useEffect(() => {
        const dropdownNode = dropdownPanelRef?.current
        if (dropdownNode) {
            dropdownNode.addEventListener('keydown', (event: KeyboardEvent) => {
                if (isEscPressed(event.key)) {
                    setIsOpen(false)
                    mainRef?.current?.focus()
                }
            })
        }
    }, [])

    const filterRef = useRef<HTMLDivElement>()
    const mainRef = useRef<HTMLButtonElement>()
    const dropdownPanelRef: React.MutableRefObject<HTMLDivElement> | undefined = useRef()

    const handleClick = (e: React.MouseEvent) => {
        if (dropdownPanelRef?.current?.contains(e.target as Node)) return
        setIsOpen(!isOpen)
    }

    // Handler should be wrapped with useCallback in order to prevent unnecessary fire of the effect
    const clickOutsideHandler = useCallback(() => {
        isOpen && setIsOpen(false)
    }, [isOpen])

    useOnClickOutside(filterRef, clickOutsideHandler)

    /**
     * callback function handles dropdown close on esc, space, enter
     * @param { React.KeyboardEvent<HTMLButtonElement> } event
     * @return { void }
     */
    const closeDropDown = useCallback((event: KeyboardEvent) => {
        if (isEscPressed(event.key) || isSpacePressed(event.key) || isEnterPressed(event.key)) {
            setIsOpen(false)
        }
    }, [])

    useOnKeyDownOutside(filterRef, closeDropDown, filterRow)

    // In order to close the last dropdown filter on row change.
    const closeDropDownOnTab = useCallback((event: KeyboardEvent) => {
        if (isTabPressed(event.key)) {
            setIsOpen(false)
        }
    }, [])

    // this hook is used to handle key up event
    useOnKeyUpOutside(filterRow, closeDropDownOnTab, filterPanelRef)

    // In order to close the first dropdown filter on shift-tab
    useEffect(() => {
        const filterRowNode = filterRow?.current
        if (filterRowNode) {
            filterRowNode.addEventListener('keydown', (event: KeyboardEvent) => {
                const firstInteractiveElement = getInteractiveElements(filterRowNode)[magicNumber.ZERO]
                if (firstInteractiveElement === event.target && event.shiftKey && isTabPressed(event.key)) {
                    // Do your action here
                    setIsOpen(false)
                }
            })
        }
    }, [filterRow])

    /**
     * This function handles key down event on custom dropdown button
     * @param { React.KeyboardEvent<HTMLButtonElement> } event
     * @return { void }
     */
    const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>): void => {
        if (isEscPressed(event.key)) {
            setIsOpen(false)
        }
    }

    // Below we have introduced a div in order to have dropdown button and dropdown list bind to each other as per HTML an UI in a perticular div.
    return (
        <div ref={filterRef}>
            <button
                className={`custom-dropdown`}
                onClick={handleClick}
                onKeyDown={handleKeyDown}
                ref={mainRef}
                data-testid={`custom-dropdown${id ? `_${id}` : ''}`}
                id={id}
                aria-expanded={isOpen}>
                <div className={'custom-dropdown__label'}>
                    {label}
                    {showIcon ? <Icon type={isOpen ? 'ct-chevron-up' : 'ct-chevron-down'} /> : null}
                </div>
            </button>
            <div ref={dropdownPanelRef} className={isOpen ? 'custom-dropdown-panel--open' : 'custom-dropdown-panel'}>
                {(isOpen || forSEOOnLoad) && children}
            </div>
        </div>
    )
}

CustomDropdown.propTypes = {
    children: PropTypes.element.isRequired,
    label: PropTypes.element.isRequired,
    showIcon: PropTypes.bool,
}

export default CustomDropdown
