import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import Icon from '../Icon'
import { accordionPropTypes } from './Accordion.type'

/**
 * Accordion component
 * @param {accordionPropTypes} props
 * @return {JSX.Element} returns Accordion component
 */
const Accordion: React.FC<accordionPropTypes> = ({ ...props }): JSX.Element => {
    const {
        children,
        path,
        isHeaderOpen,
        isHeaderHidden,
        title,
        hideControls,
        collapseControl,
        disableOnClick = false,
        collapseLabel,
        expandLabel,
        subtitle,
        id,
    } = props

    const [isOpen, setIsOpen] = useState(isHeaderOpen)
    const hideIcons = hideControls ? hideControls : false
    const displaySubtitle = Boolean(!isOpen && subtitle)

    useEffect(() => {
        setIsOpen(isHeaderOpen)
    }, [isHeaderOpen])
    /**
     * Handles Accordion Open & Collapse Events
     */
    const onAccordionClick = (): void => {
        !disableOnClick && setIsOpen(!isOpen)
        if (collapseControl) {
            collapseControl(!isOpen)
        }
    }

    const renderAccordionButton = (): JSX.Element => {
        return (
            <>
                <button
                    id={id}
                    className={`${PREFIX}-accordion__button ${isOpen ? `${PREFIX}-accordion__button--expanded` : ''}
                    `}
                    data-testid="accordion-button"
                    type="button"
                    data-qm-allow="true"
                    aria-expanded={isOpen}
                    onClick={onAccordionClick}
                    disabled={disableOnClick}>
                    <div
                        className={`${PREFIX}-accordion__title ${
                            displaySubtitle ? `${PREFIX}-xs-col-row ${PREFIX}-xs-align-items-start` : ''
                        }`}>
                        {title}
                        {displaySubtitle && <p className={`${PREFIX}-accordion__title--stacked`}>{subtitle}</p>}
                    </div>
                    {!hideIcons && (
                        <span className={`${PREFIX}-accordion__icon`} aria-label={isOpen ? expandLabel : collapseLabel}>
                            <Icon type={isOpen ? 'ct-subtract' : 'ct-plus'} size="lg" path={path} />
                        </span>
                    )}
                </button>
            </>
        )
    }

    return (
        <div className={`${PREFIX}-accordion`}>
            <div
                className={`${PREFIX}-accordion__item ${
                    isOpen ? `${PREFIX}-accordion--expanded` : `${PREFIX}-accordion--minimize`
                }
                `}>
                {!isHeaderHidden && <div className={`${PREFIX}-accordion__header`}>{renderAccordionButton()}</div>}
                <div
                    className={`${PREFIX}-accordion__panel ${
                        isOpen ? `${PREFIX}-accordion__panel--show` : `${PREFIX}-accordion__panel--hidden`
                    }`}
                    role="region">
                    {children}
                </div>
            </div>
        </div>
    )
}

Accordion.propTypes = {
    children: PropTypes.element,
    path: PropTypes.string,
    isHeaderOpen: PropTypes.bool,
    title: PropTypes.string.isRequired,
    collapseControl: PropTypes.any,
    hideControls: PropTypes.bool,
    isHeaderHidden: PropTypes.bool,
    disableOnClick: PropTypes.bool,
    collapseLabel: PropTypes.string,
    expandLabel: PropTypes.string,
}

export default Accordion
