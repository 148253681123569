import { createAction } from 'typesafe-actions'

import {
    SET_IS_INITIAL_DROP_DOWNS_FETCH_IN_PROGRESS,
    SET_IS_INITIAL_DROP_DOWNS_RESPONSE_DATA_FETCHED,
    SET_CLEAR_TO_VEHICLE_ATTRIBUTE,
    SET_VEHICLE_BASE_ATTRIBUTES_ORDER,
    SET_VEHICLE_KNOWN_VALUES,
    SET_VEHICLE_RESPONSE,
    SET_VEHICLE_LAZY_LOAD_RESPONSE,
    SET_IS_VEHICLE_FETCH_IN_PROGRESS,
    SET_SAVED_VEHICLE_CHECKED,
    SET_VEHICLE_DROPDOWN_LOCALIZATION,
    SET_REMOVED_VEHICLE,
    SET_VEHICLE_KNOWN_VALUES_AND_CLEAR_OTHER,
} from '../types/vehiclesData/vehiclesData.actionTypes.constant'
import { AddVehicleDataResponse } from '../../components/BuyBox/BuyBox.type'
import { VehicleDropdownLocalization } from '../types/vehiclesData/vehicleActions.types'

export const setIsInitialDropDownsFetchInProgress = createAction(
    SET_IS_INITIAL_DROP_DOWNS_FETCH_IN_PROGRESS,
    (payload: boolean) => payload,
)<boolean>()
export const setIsFetchInProgress = createAction(
    SET_IS_VEHICLE_FETCH_IN_PROGRESS,
    (payload: boolean) => payload,
)<boolean>()

export const setIsInitialDropDownsResponseDataFetched = createAction(
    SET_IS_INITIAL_DROP_DOWNS_RESPONSE_DATA_FETCHED,
    (payload: boolean) => payload,
)<boolean>()

export const setVehicleKnownValues = createAction(
    SET_VEHICLE_KNOWN_VALUES,
    (payload: Record<string, { label: string; value: string }>) => payload,
)<Record<string, { label: string; value: string }>>()

export const setVehicleKnownValuesAndClearOther = createAction(
    SET_VEHICLE_KNOWN_VALUES_AND_CLEAR_OTHER,
    (payload: Record<string, { label: string; value: string }>) => payload,
)<Record<string, { label: string; value: string }>>()

export const setSavedVehicleChecked = createAction(SET_SAVED_VEHICLE_CHECKED)<void>()

export const setVehicleBaseAttributesOrder = createAction(
    SET_VEHICLE_BASE_ATTRIBUTES_ORDER,
    (payload: string[]) => payload,
)<string[]>()

export const setClearToVehicleAttribute = createAction(
    SET_CLEAR_TO_VEHICLE_ATTRIBUTE,
    (payload: string) => payload,
)<string>()

export const setVehicleChooseResponse = createAction(
    SET_VEHICLE_RESPONSE,
    (payload: { response: AddVehicleDataResponse; labels: Record<string, string> }) => payload,
)<{ response: AddVehicleDataResponse; labels: Record<string, string> }>()

export const setVehicleLazyLoadResponse = createAction(
    SET_VEHICLE_LAZY_LOAD_RESPONSE,
    (payload: AddVehicleDataResponse) => payload,
)<AddVehicleDataResponse>()

export const setDropdownAttributeLocalization = createAction(
    SET_VEHICLE_DROPDOWN_LOCALIZATION,
    (payload: VehicleDropdownLocalization) => payload,
)<VehicleDropdownLocalization>()

export const setRemovedVehicle = createAction(SET_REMOVED_VEHICLE, (payload: string | undefined) => payload)<string>()
