import { Dispatch } from 'redux'

import { ProductDataType, ProductDataTypeObj, SortOption } from '../models/productData.interface'
import {
    fetchProductCardDataRequestAction,
    fetchProductCardDataSuccessAction,
    fetchProductCardDataErrorAction,
    fetchLoadMoreProductDataSuccessAction,
    updateFacetClickAction,
    updateSelectedSortAction,
    clearProductListAction,
    fetchWeatherTechProductCardDataSuccessAction,
} from '../actionCreators/productData.actionCreators'
import getNextSetOfResults from '../../utils/getNextSetOfResults'
import { PlpService } from '../../services/plpService'
import { setCreatePackageClick } from './automotiveVehicle.action'
import { ProductResponseErrorDTO } from '../models/product.interface'
import { IGlobalProps } from '../models/commonContent.interface'
import { Vehicle, checkDataLength } from '@nl/lib'
import { AxiosError } from 'axios'
import { RootState } from '../reducers'
import { enableDestructOnUndefinedData } from '../../utils/PDP/enableDestructOnUndefinedData.utils'
import { replaceEmptyImagesWithDefault } from '../../utils/replaceEmptyImagesWithDefault'
import { setWeatherTechVehicleVendorResults } from '../actionCreators/weatherTech.actionCreators'
import { WeatherTechVehicle } from '../models/weatherTech.interface'

/**
 * function to fetch list of products
 * @param {string} requestPayload
 * @param {string} selectedPreferredStoreId
 * @param {boolean | undefined} isFitmentRequired
 * @param {number} productToFetch
 * @param {number} pageNumber
 * @param {string} pathwayId
 * @param {string[]} breadcrumbList
 * @param {Vehicle} defaultVehicle
 * @param {string} currentPackageId
 * @param {boolean} isCompleteVehicleState
 * @param {string[]} searchSuppressFacets
 * @param {string} searchWidgetType
 * @param {string} searchCategoryLevel
 * @param {string} searchCategoryId
 * @param {boolean | undefined} searchPassQParameter
 * @param {string} searchQParameter
 * @param {string} searchExperience
 * @param {string} tireWheelSpecification
 * @return {Promise}
 */
export const fetchProductCardData =
    (
        requestPayload: string,
        selectedPreferredStoreId: string,
        isFitmentRequired?: boolean,
        productToFetch?: number,
        pageNumber?: number,
        pathwayId?: string,
        breadcrumbList?: string[],
        defaultVehicle?: Vehicle,
        currentPackageId?: string,
        isCompleteVehicleState?: boolean,
        searchSuppressFacets?: string[],
        searchWidgetType?: string,
        searchCategoryLevel?: string,
        searchCategoryId?: string,
        searchPassQParameter?: boolean,
        searchQParameter?: string,
        searchExperience?: string,
        tireWheelSpecification?: string,
    ) =>
    (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const { commonContentAvailable } = enableDestructOnUndefinedData(getState().commonContent) as IGlobalProps
        const { enableXRingFence } = enableDestructOnUndefinedData(commonContentAvailable?.featureFlag)

        dispatch(fetchProductCardDataRequestAction())

        return PlpService.getProductData(
            requestPayload,
            selectedPreferredStoreId,
            isFitmentRequired,
            productToFetch,
            pageNumber,
            pathwayId,
            breadcrumbList,
            defaultVehicle,
            searchCategoryId,
            undefined,
            undefined,
            currentPackageId,
            isCompleteVehicleState,
            searchSuppressFacets,
            searchWidgetType,
            searchCategoryLevel,
            searchPassQParameter,
            searchQParameter,
            searchExperience,
            tireWheelSpecification,
            enableXRingFence,
        )
            .then(data => {
                replaceEmptyImagesWithDefault(data?.data?.products, 'images')
                dispatch(fetchProductCardDataSuccessAction(data.data as ProductDataType))
                dispatch(setCreatePackageClick(false))
            })
            .catch((error: AxiosError<ProductResponseErrorDTO>) => {
                const { data, status } = error.response as unknown as ProductResponseErrorDTO
                dispatch(fetchProductCardDataErrorAction({ data, status }))
            })
    }

/**
 * function to fetch list of products
 * @param {string} requestPayload
 * @param {WeatherTechVehicle} weatherTechVehicle
 * @param {string} selectedPreferredStoreId
 * @param {number} productToFetch
 * @param {number} pageNumber
 * @param {string} pathwayId
 * @param {string} searchSuppressFacets
 * @param {string} searchWidgetType
 * @param {boolean} searchCategoryLevel
 * @param {string} searchCategoryId
 * @param {string} searchExperience
 * @return {Promise}
 */
export const fetchWeatherTechProductData =
    (
        requestPayload: string,
        weatherTechVehicle: WeatherTechVehicle,
        selectedPreferredStoreId: string,
        productToFetch?: number,
        pageNumber?: number,
        pathwayId?: string,
        searchSuppressFacets?: string[],
        searchWidgetType?: string,
        searchCategoryLevel?: string,
        searchCategoryId?: string,
        searchExperience?: string,
    ) =>
    (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const { commonContentAvailable } = enableDestructOnUndefinedData(getState().commonContent) as IGlobalProps
        const { enableXRingFence } = enableDestructOnUndefinedData(commonContentAvailable?.featureFlag)

        dispatch(fetchProductCardDataRequestAction())

        return PlpService.getWeatherTechProductData(
            requestPayload,
            weatherTechVehicle,
            selectedPreferredStoreId,
            productToFetch,
            pageNumber,
            pathwayId,
            searchCategoryId,
            searchSuppressFacets,
            searchWidgetType,
            searchCategoryLevel,
            searchExperience,
            enableXRingFence,
        )
            .then(data => {
                replaceEmptyImagesWithDefault(data?.data?.products, 'images')
                if (!checkDataLength(weatherTechVehicle.vendorResults) && checkDataLength(data?.data?.vendorResults)) {
                    dispatch(setWeatherTechVehicleVendorResults(data?.data?.vendorResults))
                }
                dispatch(fetchWeatherTechProductCardDataSuccessAction(data.data as ProductDataType))
            })
            .catch((error: AxiosError<ProductResponseErrorDTO>) => {
                const { data, status } = error.response as unknown as ProductResponseErrorDTO
                dispatch(fetchProductCardDataErrorAction({ data, status }))
            })
    }

/**
 * function to fetch next set of products based on current page
 * @param {ProductDataTypeObj[]} products
 * @param {number} currentPage
 * @param {number} loadCount
 * @param {number} initialCount
 * @param {number} productsDisplayCount
 * @return {void} // Void needs to be returned for action
 */
export const fetchLoadMoreProductData =
    (
        products: ProductDataTypeObj[],
        currentPage: number,
        loadCount: number,
        initialCount: number,
        productsDisplayCount?: number,
    ) =>
    (dispatch: Dispatch): void => {
        const loadProducts = getNextSetOfResults(products, currentPage, loadCount, initialCount, productsDisplayCount)
        dispatch(fetchLoadMoreProductDataSuccessAction(loadProducts as unknown as ProductDataTypeObj[]))
    }

/**
 * function to update facet click action
 * @param {string} requestPayload
 * @return {void} // Void needs to be returned for action
 */
export const updateFacetClick =
    (requestPayload: string) =>
    (dispatch: Dispatch): void => {
        dispatch(updateFacetClickAction(requestPayload))
    }

/**
 * function to update selected sort option
 * @param {string} requestPayload
 * @return {void} // Void needs to be returned for action
 */
export const updateSelectedSort =
    (requestPayload: SortOption) =>
    (dispatch: Dispatch): void => {
        dispatch(updateSelectedSortAction(requestPayload))
    }

/**
 * function to clear the product list
 * @return {void} // Void needs to be returned for action
 */
export const clearProductList =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(clearProductListAction())
    }
