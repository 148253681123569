import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { PREFIX } from '../../config'
import { SkeletonComponent, Button } from '@nl/lib'
import { WishlistSummaryProps } from './WishListSummary.type'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { commonContentSelector } from '../../redux/selectors/commonContent.selectors'
import { userProfileDataSelector } from '../../redux/selectors/userProfile.selectors'

/**
 * WishListBenefits component returns  react loading skeleton for WishListBenefits
 * @param {WishlistSummaryProps} props
 * @return {JSX.Element}
 */
const WishlistBenefits = (props: WishlistSummaryProps): JSX.Element => {
    const { wbTitle, wbDescription, wbTriangleIDCTA, wbSIgnInText, wishlistData } = props
    const { commonContentAvailable } = useSelector(commonContentSelector)

    const userProfileData = useSelector(userProfileDataSelector)
    const isLoggedIn = userProfileData ? Boolean(checkDataLength(userProfileData)) : false

    /**
     * function to dangerously set rich text
     * @param {string} text heading/title
     * @param {string} className class applied to rich text
     * @return {JSX.Element}
     */
    const setHTML = (text: string): JSX.Element => {
        return <div dangerouslySetInnerHTML={{ __html: text }} className={`${PREFIX}-wishlistsummary-benefits__msg`} />
    }

    /**
     * function on click of create triangle id cta
     * @return {void}
     */
    const handleCreateTriangleClick = (): void => {
        window.location.href = encodeURI(commonContentAvailable.globalLinks.registrationPageLink)
    }

    /**
     * conditionally render skeleton or component based on wishlist user logged in data
     * @return {JSX.Element}
     */
    const renderComponent = (): JSX.Element => {
        return (
            <>
                {!isLoggedIn && (
                    <div className={`${PREFIX}-wishlistsummary-benefits`}>
                        <div className={`${PREFIX}-wishlistsummary-benefits__wrapper`}>
                            <h3 className={`${PREFIX}-wishlistsummary-benefits__label`}>{wbTitle}</h3>
                        </div>

                        <div className={`${PREFIX}-wishlistsummary-benefits__description`}>
                            {setHTML(wbDescription)}
                        </div>
                        <div className={`${PREFIX}-wishlistsummary-benefits__create-triangleId`}>
                            <Button
                                type="secondary"
                                size="small"
                                buttonType="submit"
                                onClick={handleCreateTriangleClick}
                                quantumMetricAttribute={{ type: 'allow', value: 'true' }}>
                                {wbTriangleIDCTA}
                            </Button>
                        </div>
                        <div className={`${PREFIX}-wishlistsummary-benefits__details`}>{setHTML(wbSIgnInText)}</div>
                    </div>
                )}
            </>
        )
    }

    return !checkDataLength(wishlistData) ? (
        <div>
            <SkeletonComponent skeletonClass={`${PREFIX}-wishlistsummary-benefits__skeleton`} />
        </div>
    ) : (
        renderComponent()
    )
}

WishlistBenefits.propTypes = {
    wbTitle: PropTypes.string,
    wbDescription: PropTypes.string,
    wbTriangleIDCTA: PropTypes.string,
    wbSIgnInText: PropTypes.string,
    wishlistData: PropTypes.string,
}

export default WishlistBenefits
