import React, { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { MagicNumber } from '../../analytics/analytics.type'
import GigyaService from '../../services/gigyaService/gigya.service'

import appCacheService from '../../utils/appCacheService'
import { GigyaJWTResp } from '../../utils/gigya.type'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { isAuthFlowExecutedSelector, userProfileDataSelector } from '../../redux/selectors/userProfile.selectors'

const gigyaService = new GigyaService()
const JwtTokenHelper: React.FC = () => {
    const userProfileData = useSelector(userProfileDataSelector)
    const isAuthFlowExecuted = useSelector(isAuthFlowExecutedSelector)
    const [intervalVal, setIntervalVal] = useState<NodeJS.Timeout | string>('')
    const isAuth = isAuthFlowExecuted && checkDataLength(userProfileData)

    /**
     * @method updateJwtBeforeExpire uses to check if expire time is 1 minute or less than that and refreshing token in each 5 seconds.
     */
    const updateJwtBeforeExpire = useCallback(() => {
        const parsedData = getAndParseJwtToken()
        if (checkDataLength(parsedData)) {
            const expTime = parsedData.exp

            const currInterval = setInterval(() => {
                // Gigya token is expires after 5 mins. So here we are refreshing token on 4th minute. e.g exprire time 10:10:00, refresh call will be at 10:09:00
                const isTokenAboutToExpire =
                    new Date(Date.now()) > new Date(expTime * MagicNumber.ONETHOUSAND - MagicNumber.SIXTYTHOUSAND)

                if (isTokenAboutToExpire) {
                    clearTimeInterval(currInterval)
                    gigyaService
                        .jwtToken()
                        .then((resp: GigyaJWTResp) => {
                            appCacheService.gigyaJWTToken.set(resp.id_token)
                            updateJwtBeforeExpire()
                        })
                        .catch(err => {
                            // TODO: Need to either navigate user to login screen or show the partial auth modal.
                            console.error(err)
                        })
                }
            }, MagicNumber.FIVETHOUSAND)
            setIntervalVal(currInterval)
        }
    }, [setIntervalVal])

    useEffect(() => {
        if (isAuth) {
            updateJwtBeforeExpire()
        }
    }, [isAuth, updateJwtBeforeExpire])

    useEffect(() => {
        return () => {
            if (intervalVal) {
                clearTimeInterval(intervalVal)
            }
        }
    }, [intervalVal])

    /**
     * Function uses to clear the timer.
     * @param {NodeJS.Timeout} intervalValue
     * @return {void}
     */
    const clearTimeInterval = (intervalValue: NodeJS.Timeout): void => {
        if (intervalValue) {
            clearInterval(intervalValue)
        }
    }

    /**
     * Function returns the decoded JWT token
     * @return {Record<string, unknown>}
     */
    const getAndParseJwtToken = (): Record<string, unknown> => {
        const gigyaJWTToken = appCacheService.gigyaJWTToken.get()

        if (!!gigyaJWTToken) {
            try {
                const base64Url = gigyaJWTToken.split('.')[MagicNumber.ONE]
                const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
                const jsonPayload = decodeURIComponent(
                    atob(base64)
                        .split('')
                        .map(function (c) {
                            return (
                                '%' +
                                ('00' + c.charCodeAt(0).toString(MagicNumber.SIXTEEN)).slice(MagicNumber.MINUS_TWO)
                            )
                        })
                        .join(''),
                )
                return JSON.parse(jsonPayload) as Record<string, unknown>
            } catch (error) {
                console.error('Parse token', error)
            }
        }
        return {}
    }

    return null
}

export default JwtTokenHelper
export { JwtTokenHelper }
