import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { PREFIX } from '../config'
import { FiltersProps } from './Filters.type'
import Accordion from '../Accordion'
import Checkbox from '../Checkbox'
import { FacetValue, FacetTypes, Facet, FacetRange } from './FacetPanelModal.type'
import { replaceStrWithDynamicVal, magicNumber } from '../../utils'
import ShowMoreLessButton from './ShowMoreLessButton'
import Icon from '../Icon'
import Radio from '../Radio'
import Button from '../Button'
import TextInput from '../TextInput/TextInput'
import { defaultLocale, digitsPattern } from '../../globalConstants/global.constant'
import { getFormattedPrice, getPriceRangePill, stringKeyCodes } from '../../utils'

const Filters: React.FC<FiltersProps> = props => {
    const {
        selectedFacetType,
        facet,
        showMoreThreshold,
        showMoreLabel,
        showLessLabel,
        onFilterChange,
        currentLocale,
        minPriceErrorMessage,
        maxPriceErrorMessage,
        minPriceLabel,
        maxPriceLabel,
        customPriceFacet,
        isTriangleOffer,
        getFilterOnly, // to get only filter without accordion
        showSearch,
        enableFilterFacetsEnhancement,
        searchInputPlaceholderText,
        noSearchResults,
        isCategoryPage,
    } = props

    const [filterExpanded, setFilterExpanded] = useState(selectedFacetType === facet.label)

    const componentClassName = `${PREFIX}-filter-section`

    /**
     * Gets facet values based on type of facet
     *
     * @param {Facet} facetObj - a facet object coming from API
     * @return {FacetValue[]} facetValues - returns an array of all values object present inside facet
     */
    const getFacetValues = (facetObj: Facet | FacetRange): FacetValue[] => {
        return facetObj.values as unknown as FacetValue[]
    }

    const facetValues = useMemo(() => {
        return getFacetValues(facet)
    }, [facet])
    const facetValuesShownList = useMemo(() => facetValues?.filter(facVal => facVal.count > 0), [facetValues])
    const [searchValue, setSearchValue] = useState('')
    /**
     * Replaces [0] inside view more label with remaining list length to be shown
     */

    const rowsToShow = useMemo(
        () =>
            !enableFilterFacetsEnhancement || (searchValue == '' && selectedFacetType)
                ? facetValues?.slice(0, showMoreThreshold)
                : facetValues?.slice(0, (facetValuesShownList as unknown as Record<string, unknown>[]).length),
        [
            enableFilterFacetsEnhancement,
            facetValues,
            facetValuesShownList,
            searchValue,
            showMoreThreshold,
            selectedFacetType,
        ],
    )

    const [showAllRows, setShowAllRows] = useState(false)
    const [minimumPrice, setMinimumPrice] = useState('$')
    const [maximumPrice, setMaximumPrice] = useState('$')
    const [selectedMinPrice, setSelectedMinPrice] = useState<number | undefined>(undefined)
    const [selectedMaxPrice, setSelectedMaxPrice] = useState<number | undefined>(undefined)
    const [minPriceError, setMinPriceError] = useState('')
    const [maxPriceError, setMaxPriceError] = useState('')

    const filteredData = useMemo(
        () =>
            rowsToShow?.filter(item => {
                return (
                    (item.label &&
                        item.label.toLowerCase().indexOf(searchValue.toLowerCase()) !== magicNumber.MINUS_ONE) ||
                    !searchValue
                )
            }),
        [rowsToShow, searchValue],
    )

    const mainRef = useRef()

    const updatedShowMoreLabel =
        !enableFilterFacetsEnhancement || searchValue == ''
            ? replaceStrWithDynamicVal(showMoreLabel, facetValuesShownList?.length - showMoreThreshold)
            : replaceStrWithDynamicVal(showMoreLabel, filteredData?.length - showMoreThreshold)

    const [listData, setListData] = useState({
        listViewData: filteredData,
        icon: 'ct-chevron-down',
        link: updatedShowMoreLabel,
    })

    /**
     * function to get formatted price
     * @param {Number} price
     * @return {string} formatted price
     */
    const getFormattedPriceValue = useCallback(
        (price: number): string => {
            const formattedPrice = getFormattedPrice(price, currentLocale)
            return currentLocale === defaultLocale ? formattedPrice.split('$').join('$ ') : formattedPrice
        },
        [currentLocale],
    )

    /**
     * function to update price range values
     */
    const updatePriceRangeValues = useCallback((): void => {
        const { min = 0, max = 0 } = customPriceFacet || {}
        setMinimumPrice(getFormattedPriceValue(min))
        setMaximumPrice(getFormattedPriceValue(max))
        setSelectedMinPrice(min)
        setSelectedMaxPrice(max)
    }, [customPriceFacet, getFormattedPriceValue])

    /**
     * Sets related label, icon and list data based on show less / show more
     */
    useEffect(() => {
        if (showAllRows) {
            setListData({
                listViewData: enableFilterFacetsEnhancement && searchValue != '' ? filteredData : facetValues,
                icon: 'ct-chevron-up',
                link: showLessLabel,
            })
        } else {
            setListData({
                listViewData:
                    enableFilterFacetsEnhancement && searchValue != '' && selectedFacetType
                        ? filteredData.slice(0, showMoreThreshold)
                        : filteredData,
                icon: 'ct-chevron-down',
                link: updatedShowMoreLabel,
            })
        }

        if (facet?.type === FacetTypes.RANGE) updatePriceRangeValues()
    }, [
        showAllRows,
        facetValues,
        searchValue,
        facet,
        updatePriceRangeValues,
        enableFilterFacetsEnhancement,
        filteredData,
        showLessLabel,
        selectedFacetType,
        showMoreThreshold,
        updatedShowMoreLabel,
    ])

    /**
     * Returns array of all selected values from a particular facet
     */

    const getSelectedValues = (facetObj: Facet | FacetRange): string[] => {
        const facetVals = getFacetValues(facetObj)
        return facetVals?.filter(facVal => facVal.selected).map(val => (val?.name ? val.name : val.label))
    }

    /**
     * Returns title component with selected comma separated values incase of collapsed
     */

    const getTitleComponent = (): React.ReactNode => {
        const selectedFacetOptions = getSelectedValues(facet)
        return (
            <>
                <div className={`${PREFIX}-facet-name`}>{facet.label}</div>
                {!filterExpanded && selectedFacetOptions && selectedFacetOptions.length ? (
                    <div className={`${PREFIX}-facet-selection`}>{selectedFacetOptions.join(', ')}</div>
                ) : null}
            </>
        )
    }

    /**
     * function to fire change event on multi selection
     * @param {React.ChangeEvent<HTMLInputElement>} _
     * @param {FacetValue} val
     */
    const multiSelectOnChange = (_, val: FacetValue): void => {
        onFilterChange(val.selected ? val.clearUrl : val.url, val.label, facet.label, !val.selected)
    }

    const getPriceValue = (): boolean => {
        return facet.type === FacetTypes.RANGE && (selectedMinPrice || selectedMaxPrice) != 0
    }

    /**
     * function to update selected price range in the url
     * @param {number} minPrice
     * @param {number} maxPrice
     */
    const onPriceRangeSelection = useCallback(
        (minPrice: number, maxPrice: number): void => {
            const pricePills = getPriceRangePill(minPrice, maxPrice, currentLocale)
            onFilterChange(
                pricePills ? `?mock&facet=${pricePills}` : '',
                `${pricePills}`,
                facet.label,
                true,
                true,
                minPrice,
                maxPrice,
                customPriceFacet?.url ?? '',
            )
        },
        [currentLocale, facet.label, onFilterChange, customPriceFacet],
    )

    /**
     * callback to validate min and max price fields
     */
    const validateMinMaxPrice = useCallback(
        (minPrice: number, maxPrice: number, errorSetter: (val?: string) => void, errorMessage?: string): void => {
            let errorMsg = ''
            if (typeof minPrice === 'number' && typeof maxPrice === 'number') {
                errorMsg = minPrice > maxPrice ? errorMessage : ''
            }
            errorSetter(errorMsg)
            setMaxPriceError(errorMsg !== '' ? maxPriceErrorMessage : '')
            if (errorMsg === '') {
                onPriceRangeSelection(minPrice, maxPrice)
            }
        },
        [maxPriceErrorMessage, onPriceRangeSelection],
    )

    /**
     * function to update maximum price
     * @param {string} value
     */
    const updateMinimumPrice = (value: string): void => {
        const price = Number(value.replace(digitsPattern, ''))
        setSelectedMinPrice(price)
        setMinimumPrice(getFormattedPriceValue(price))
    }

    /**
     * function to update minimum price
     * @param {string} value
     */
    const updateMaximumPrice = (value: string): void => {
        const price = Number(value.replace(digitsPattern, ''))
        setSelectedMaxPrice(price)
        setMaximumPrice(getFormattedPriceValue(price))
    }

    /**
     * function to render price range filter
     * @return {JSX.Element}
     */
    const renderPriceFacet = (): JSX.Element => {
        const priceId = 'price-range'
        const handleBlur = () =>
            validateMinMaxPrice(selectedMinPrice, selectedMaxPrice, setMinPriceError, minPriceErrorMessage)

        return (
            <div className={`${componentClassName}__price-range`}>
                <TextInput
                    disabled={getSelectedValues(facet).length !== 0}
                    id={`${priceId}-minPrice`}
                    label={minPriceLabel}
                    value={minimumPrice}
                    onChange={updateMinimumPrice}
                    maxLength={7}
                    error={minPriceError}
                    size="small"
                    onBlur={handleBlur}
                />
                <div className={`${componentClassName}__price-range__separator`}>-</div>
                <TextInput
                    disabled={getSelectedValues(facet).length !== 0}
                    id={`${priceId}-maxPrice`}
                    label={maxPriceLabel}
                    value={maximumPrice}
                    onChange={updateMaximumPrice}
                    maxLength={7}
                    error={maxPriceError}
                    size="small"
                    onBlur={handleBlur}
                />
            </div>
        )
    }

    const highlightKeyword = useCallback(
        (labels: string) => {
            const regex = searchValue.replace(/[.*+?^${}()|[]\\]/g, '\\$&')
            const searchTerms = labels.split(new RegExp(`(${regex})`, 'gi'))
            return (
                <span>
                    {searchTerms.map((searchTerm, i) => (
                        <span
                            key={i}
                            className={
                                searchTerm.toLowerCase() !== regex.toLowerCase()
                                    ? `${PREFIX}-suggestion-text__highlighted`
                                    : ''
                            }>
                            {searchTerm}
                        </span>
                    ))}
                </span>
            )
        },
        [searchValue],
    )

    const renderShowMoreLessButton = (): JSX.Element => {
        return (
            <ShowMoreLessButton
                totalResults={
                    searchValue == ''
                        ? (facetValuesShownList as unknown as Record<string, unknown>[])
                        : (filteredData as unknown as Record<string, unknown>[])
                }
                setShowAllRows={setShowAllRows}
                showAllRows={showAllRows}
                listData={listData}
                showMoreThreshold={showMoreThreshold}
            />
        )
    }

    // Renders list of checkboxes of facet values
    const renderMultiSelection = (): JSX.Element => (
        <div ref={mainRef}>
            {facet.type === FacetTypes.RANGE && renderPriceFacet()}
            {enableFilterFacetsEnhancement && showSearch && (
                <div className={`${componentClassName}__search-brand-icon`}>
                    <span className={`${componentClassName}__search-brand-button`}>
                        <Button type="icon_button" id="trigger-search-icon" ariaLabel={searchInputPlaceholderText}>
                            <Icon type="ct-search" size="md" />
                        </Button>
                    </span>
                    <input
                        type="text"
                        className={
                            searchValue != ''
                                ? `${componentClassName}__close-search`
                                : `${componentClassName}__search-brand`
                        }
                        placeholder={searchInputPlaceholderText}
                        onChange={e => setSearchValue(e.target.value)}
                        autoComplete="off"
                        value={searchValue}
                        onKeyDown={event => {
                            if (event.key === stringKeyCodes.enter || event.key === stringKeyCodes.space) {
                                event.preventDefault()
                                setSearchValue(searchValue + ' ')
                            }
                        }}
                    />
                    {searchValue != '' && (
                        <span className={`${componentClassName}__search-brand-button`}>
                            <Button onClick={() => setSearchValue('')} type="icon_button" id="close-btn">
                                <Icon type="ct-close" size="md" />
                            </Button>
                        </span>
                    )}
                </div>
            )}
            <ul>
                {enableFilterFacetsEnhancement && showSearch && listData?.listViewData?.length == 0 && (
                    <div className={`${componentClassName}__search-no-match`}>{noSearchResults}</div>
                )}
                {listData?.listViewData?.map(val => {
                    const labelComponent = (
                        <span>
                            {enableFilterFacetsEnhancement && searchValue != ''
                                ? highlightKeyword(val.label)
                                : val.name
                                ? val.name
                                : val.label}
                            <span className={`${componentClassName}__count`}> ({val.count})</span>
                        </span>
                    )

                    const checkboxId = `${isTriangleOffer && !val.label ? val?.count?.toString() : val.label}${
                        getFilterOnly ? ' custom' : ''
                    }`

                    return (
                        val.count > 0 && (
                            <li
                                className={`${componentClassName}__multiselect-item`}
                                key={isTriangleOffer && !val.label ? val.count.toString() : val.label}>
                                <Checkbox
                                    disabled={getPriceValue()}
                                    facetId={facet?.id}
                                    label={labelComponent}
                                    id={checkboxId}
                                    checked={val.selected}
                                    value={val.label}
                                    onChange={e => multiSelectOnChange(e, val)}
                                />
                            </li>
                        )
                    )
                })}
                {selectedFacetType && renderShowMoreLessButton()}
            </ul>
        </div>
    )

    const onFilterAccordionChange = (isOpen: boolean): void => {
        setFilterExpanded(isOpen)
    }

    // Check for facet should only render if at-least one of the facet values has result count > 0
    const shouldRenderFacet = (): boolean => {
        const facetVals = getFacetValues(facet)
        const totalResultCount = facetVals?.reduce((count, val) => count + val.count, 0)
        return totalResultCount > 0
    }

    const stars = ['1', '2', '3', '4', '5']

    const renderRatingFacet = (): JSX.Element => {
        return (
            <ul>
                {listData.listViewData.map(val => {
                    const labelComponent = (
                        <span className={`${componentClassName}__rating-review`}>
                            {stars.map((_item, index) => {
                                return index < Number(val.value) ? (
                                    <Icon size="lg" type="ct-reviews-active" key={index} />
                                ) : (
                                    <Icon size="lg" type="ct-reviews-inactive" key={index} />
                                )
                            })}
                            <span className={`${PREFIX}-radio__label-container`}>{val.label}</span>
                        </span>
                    )

                    const radioId = `${isTriangleOffer && !val.label ? val?.count?.toString() : val.label}${
                        getFilterOnly ? ' custom' : ''
                    }`

                    return (
                        val.count > 0 && (
                            <li className={`${componentClassName}__multiselect-item`} key={val.label}>
                                <Radio
                                    label={facet.type === FacetTypes.RATING ? labelComponent : val.label}
                                    id={radioId}
                                    name="facets"
                                    checked={val.selected}
                                    onChange={e => multiSelectOnChange(e, val)}
                                />
                            </li>
                        )
                    )
                })}

                {selectedFacetType && (
                    <ShowMoreLessButton
                        totalResults={facetValuesShownList as unknown as Record<string, unknown>[]}
                        setShowAllRows={setShowAllRows}
                        showAllRows={showAllRows}
                        listData={listData}
                        showMoreThreshold={showMoreThreshold}
                    />
                )}
            </ul>
        )
    }

    const breadCrumbOnChange = (val: FacetValue): void => {
        onFilterChange(val.selected ? val.clearUrl : val.url, val.label, facet.label, !val.selected)
    }

    const parentCategoryClicked = (): void => {
        onFilterChange(facet?.current?.clearUrl, facet?.current?.label, facet.label, false)
    }

    const renderParentCategory = (): JSX.Element => {
        return facet?.current?.label ? (
            <div
                role="button"
                onKeyDown={(event: React.KeyboardEvent): void => {
                    if (event.key === stringKeyCodes.enter) {
                        parentCategoryClicked()
                    }
                }}
                onClick={parentCategoryClicked}
                tabIndex={0}
                className={`${componentClassName}__parent-category-wrapper`}>
                <Icon type="ct-chevron-left" size="md" />
                <span className={`${componentClassName}__parent-category-label`}>
                    {facet.values.length !== 0 ? facet?.current?.label : facet?.parent?.label}
                </span>
            </div>
        ) : null
    }

    const renderCategoriesFacet = (): JSX.Element => {
        return !isCategoryPage ? (
            <ul>
                {renderParentCategory()}
                {facet.values.length === 0 ? (
                    <li className={`${componentClassName}__multiselect-item`}>
                        <span
                            data-testid="categories-facet"
                            role="button"
                            className={`${componentClassName}__breadcrumb-category`}>
                            {facet.current.label}
                            <span className={`${componentClassName}__count`}> </span>
                        </span>
                    </li>
                ) : (
                    listData?.listViewData?.map(val => {
                        const labelComponent = (
                            <span
                                data-testid="categories-facet"
                                role="button"
                                onKeyDown={(event: React.KeyboardEvent): void => {
                                    if (event.key === stringKeyCodes.enter) {
                                        breadCrumbOnChange(val)
                                    }
                                }}
                                onClick={() => breadCrumbOnChange(val)}
                                tabIndex={0}
                                className={`${componentClassName}__breadcrumb-item`}>
                                {val.label}
                                <span className={`${componentClassName}__count`}> ({val.count})</span>
                            </span>
                        )

                        return (
                            val.count > 0 && (
                                <li className={`${componentClassName}__multiselect-item`} key={val.label}>
                                    {labelComponent}
                                </li>
                            )
                        )
                    })
                )}
                {selectedFacetType && (
                    <ShowMoreLessButton
                        totalResults={facetValuesShownList as unknown as Record<string, unknown>[]}
                        setShowAllRows={setShowAllRows}
                        showAllRows={showAllRows}
                        listData={listData}
                        showMoreThreshold={showMoreThreshold}
                    />
                )}
            </ul>
        ) : null
    }

    const getFilter = () => (
        <>
            {(facet.type === FacetTypes.MULTISELECT ||
                facet.type === FacetTypes.RANGE ||
                facet.type === FacetTypes.COLOURPICKER) &&
                renderMultiSelection()}
            {(facet.type === FacetTypes.RATING || facet.type === FacetTypes.RADIO) && renderRatingFacet()}
            {facet.id === FacetTypes.CATEGORY_ID && renderCategoriesFacet()}
        </>
    )

    if (getFilterOnly) {
        return getFilter()
    }

    return isCategoryPage && facet.id === FacetTypes.CATEGORY_ID
        ? null
        : shouldRenderFacet() && (
              <li>
                  <Accordion
                      title={getTitleComponent()}
                      isHeaderOpen={filterExpanded}
                      collapseControl={onFilterAccordionChange}>
                      {getFilter()}
                  </Accordion>
              </li>
          )
}

Filters.propTypes = {
    selectedFacetType: PropTypes.string,
    facet: PropTypes.any,
    showMoreThreshold: PropTypes.number,
    showMoreLabel: PropTypes.string,
    showLessLabel: PropTypes.string,
    onFilterChange: PropTypes.func,
    currentLocale: PropTypes.string,
    minPriceErrorMessage: PropTypes.string,
    maxPriceErrorMessage: PropTypes.string,
    minPriceLabel: PropTypes.string,
    maxPriceLabel: PropTypes.string,
    customPriceFacet: PropTypes.any,
    getFilterOnly: PropTypes.bool,
}

export default Filters
