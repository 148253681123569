import {
    BannerStyleDetailsProps,
    IndividualBannerDetails,
    BannerDetailsProps,
    BannerStyleParameters,
    UtmParamsType,
} from './TriangleMCBanner.types'
import checkNestedProps from '../../utils/checkNestedProps'
import { IProduct } from '../../redux/models/commonContent.interface'
import { priceCategory } from '../../config'

/**
 * To find the banner styling details depending on which ultraslimbanner to be displayed
 * @param {BannerStyleDetailsProps} bannerStyleProps
 * @return {BannerStyleParameters}
 */

export const findBannerStyleDetails = (bannerStyleProps: BannerStyleDetailsProps): BannerStyleParameters => {
    return {
        showImage: !!bannerStyleProps.bannerImage,
        fontColor: bannerStyleProps.fontColour,
        imgOrientation: bannerStyleProps.bannerImageOrientation,
    }
}

/**
 * Determine Banner props for triangle mastercard user and other users
 * @param {BannerDetailsProps} bannerDetailsProps
 * @return {IndividualBannerDetails}
 */

export const findBannerDetails = (bannerDetailsProps: BannerDetailsProps): IndividualBannerDetails => {
    return {
        bannerTitle: bannerDetailsProps.bannerTitle,
        bannerDescription: bannerDetailsProps.bannerDescription,
        bannerImage: bannerDetailsProps.bannerImage,
        bannerImageAltText: bannerDetailsProps.bannerImageAltText,
        backgroundColour: bannerDetailsProps.backgroundColour,
        displayImageOnMobile: bannerDetailsProps.displayImageOnMobile,
    }
}

/**
 * To get the threshold category depending on the price
 * @param {number} price
 *  @param {IProduct} commonContentProduct
 * @return {string}
 */
export const getPriceCategory = (price: number, commonContentProduct: IProduct): string => {
    const threshold1 = checkNestedProps(commonContentProduct, 'threshold1') as number
    const threshold2 = checkNestedProps(commonContentProduct, 'threshold2') as number
    const threshold3 = checkNestedProps(commonContentProduct, 'threshold3') as number
    let priceState: string
    switch (true) {
        case price < threshold1:
            priceState = priceCategory.THRESHOLD1
            break
        case price >= threshold1 && price <= threshold2:
            priceState = priceCategory.THRESHOLD2
            break
        case price >= threshold3:
            priceState = priceCategory.THRESHOLD3
            break
        default:
            priceState = priceCategory.THRESHOLD1
            break
    }
    return priceState
}

/**
 * Appends utm params to the url
 * @param {string} url
 * @param {string} userType
 * @param {UtmParamsType[]} utmList
 * @return {string}
 */
export const appendUtmParams = (url: string, userType: string, utmList: UtmParamsType[]): string => {
    const utmUserType = utmList?.find(el => el.userType === userType) as UtmParamsType
    const utmAll = utmList?.find(el => el.userType === 'all') as UtmParamsType
    const utmItem = utmUserType ? utmUserType : utmAll
    return encodeURI(
        `${url}${utmItem?.utmSource ? `&utm_source=${utmItem.utmSource}` : ''}${
            utmItem?.utmMedium ? `&utm_medium=${utmItem.utmMedium}` : ''
        }${utmItem?.utmCampaign ? `&utm_campaign=${utmItem.utmCampaign}` : ''}${
            utmItem?.utmTerm ? `&utm_term=${utmItem.utmTerm}` : ''
        }${utmItem?.utmContent ? `&utm_content=${utmItem.utmContent}` : ''}`,
    )
}
