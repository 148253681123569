const bannerContent = document.getElementsByClassName('nl-edge-banner__content--right')[0] as HTMLDivElement
const nlBreadcrumbs = 'nl-breadcrumbs'
const nlMasterheadBanner = 'nl-masthead-banner'

const edgeToEdgeBanner = (): void => {
    const isEdgeBanner = document.getElementsByClassName('edge-to-edge-banner')

    const breadcrumbComp = document.getElementsByClassName(nlBreadcrumbs)
    const isMasterheadBanner = document.getElementsByClassName(nlMasterheadBanner)

    if (isEdgeBanner.length > 0 && breadcrumbComp.length > 0) {
        // Added breadcrumb css for Mathead Banner
        if (isMasterheadBanner.length > 0) {
            for (let i = 0; i < breadcrumbComp.length; i++) {
                breadcrumbComp[i].classList.add('nl-masthead-banner__breadcrumb')
            }
        }
        for (let i = 0; i < breadcrumbComp.length; i++) {
            breadcrumbComp[i].classList.add('nl-edge-banner__breadcrumb')

            breadcrumbComp[i].parentElement?.classList.add('nl-row', 'nl-container')
        }
        bannerContent?.classList.add('nl-edge-banner__content--top')
    }
}

export { edgeToEdgeBanner }
