import { PREFIX } from '../config'

export const videoGalleryViewerClassName = `${PREFIX}-video-gallery-viewer`

export const thumbnailScrollWidth = 0
export const thumbnailImageCount = 3

export const videoGalleryConstants = {
    thumbnailPrevArrowClass: `${videoGalleryViewerClassName}__thumb-prev`,
    thumbnailNextArrowClass: `${videoGalleryViewerClassName}__thumb-next`,
    thumbnailPlayButtonClass: `${videoGalleryViewerClassName}__play-icon`,
    playButtonIcon: 'ct-play',
    videoPlayButtonClass: `${videoGalleryViewerClassName}__play-icon`,
}
