import { getFormattedPhoneNumber } from '@nl/lib'
import React from 'react'
import { useSelector } from 'react-redux'
import { PREFIX } from '../../../config'
import { RootState } from '../../../redux/reducers'
import { AOACurrentLocationProps } from './AOACurrentLocation.type'

const AOACurrentLocation: React.FC<AOACurrentLocationProps> = ({ ...props }): JSX.Element => {
    const { currentLocationTitle } = props

    const { preferredStoreDetails } = useSelector((state: RootState) => state.storeDetails)

    const { address } = preferredStoreDetails

    return (
        <div className={`${PREFIX}-aoa-current-location`}>
            <div>
                <div className={`${PREFIX}-aoa-current-location__title`}>{currentLocationTitle}</div>
                <div className={`${PREFIX}-aoa-current-location__description`}>
                    <div>{preferredStoreDetails?.name}</div>
                    {`${address?.line1 ?? ''} ${address?.line2 ?? ''}, ${address?.town}, ${address?.region?.name}, ${
                        address?.postalCode
                    }`}
                    <div>{address?.phone && getFormattedPhoneNumber(address.phone)}</div>
                </div>
            </div>
        </div>
    )
}

export default AOACurrentLocation
