import React, { Validator } from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { EmptyCartProps, EmptyCartWishlistProps } from './EmptyCard.type'

/**
 * EmptyCart component
 *
 * @param {EmptyCartProps} props -  emptyCartTitle,emptyCartMessage,continueLabel, primaryButtonLabel, homePageLink
           emptyCartButtonLink
 * @return {JSX.Element} returns Emptycart
 */
const EmptyCartWishlist: React.FC<EmptyCartProps> = (props: EmptyCartProps): JSX.Element => {
    const { emptyScreenProps } = props

    const {
        title,
        subTitle,
        ctaContinueLabel,
        ctaPrimaryLabel,
        cta1Link,
        cta2Link,
        isCartEmpty,
        isLoggedIn,
        isWishlistPage = false,
    } = emptyScreenProps

    return (
        <div className={`${PREFIX}-empty-cart`}>
            <div className={`${PREFIX}-empty-cart__title`}>{title}</div>
            <div className={`${PREFIX}-empty-cart__message`}>{subTitle}</div>
            <a className={`${PREFIX}-empty-cart__wishlist-link`} href={cta1Link} data-link-value={ctaPrimaryLabel}>
                {ctaPrimaryLabel}
            </a>
            {isCartEmpty && isLoggedIn && isWishlistPage ? null : (
                <a className={`${PREFIX}-empty-cart__continue-link`} href={cta2Link} data-link-value={ctaContinueLabel}>
                    {ctaContinueLabel}
                </a>
            )}
        </div>
    )
}
EmptyCartWishlist.propTypes = {
    emptyScreenProps: PropTypes.object.isRequired as Validator<EmptyCartWishlistProps>,
}
export default EmptyCartWishlist
