import { analyticsAttributes } from '../../globalConstants/analyticsParams.constant'
import getPageType from '../../utils/getPageType'
import { getProvider } from '../providers/index'

export const openAccordionAnalytics = (accordionTitle: string): void => {
    const {
        event: { interaction },
        eventParameters: {
            action: { accordionClick },
            category: { storeDetails },
        },
    } = analyticsAttributes

    getProvider().push({
        event: interaction,
        eventParameters: {
            action: accordionClick,
            category: storeDetails,
            label: accordionTitle,
            value: undefined,
            location: getPageType() || '',
        },
    })
}
