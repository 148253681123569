import { FilteredCartData } from '../../redux/models/cart.interface'

interface CertonaPurchase {
    ItemId: string
    qty: string
    price: string
    total: string
    transactionId: string
}
export const getOrderDetailsForCertona = (order: FilteredCartData): CertonaPurchase => {
    const orderObj = order?.cart
    const orderEntries = orderObj?.entries

    const itemIds: string[] = []
    const qty: number[] = []
    const price: number[] = []

    orderEntries?.forEach(entry => {
        itemIds.push(entry.code)
        qty.push(entry.quantity)
        entry.totalPrice && price.push(entry.totalPrice.value as number)
    })

    return {
        ItemId: itemIds.join(';'),
        qty: qty.join(';'),
        price: price.join(';'),
        total: orderObj?.orderSummary?.subTotalAmt?.value?.toString() || '',
        transactionId: orderObj?.code,
    }
}
