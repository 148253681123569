import { PREFIX } from '../../config'
import { RootState } from '../../redux/reducers'
import {
    AOAContactInfoState,
    AOADateTimeInfoState,
    AOAServiceInfoState,
} from '../../redux/models/aoaBookingForm.interface'
import { SELECTED_DATE, SELECTED_DROP_OFF_OPTION, SELECTED_TIME } from './BookingSummary.constants'
import { getUnpaddedTimeFormat } from '@nl/lib/src/utils/getUnpaddedTimeFormat'
import { getUsLocale } from '@nl/lib/src/utils/getUsLocale'
import { getLocationInfo, getVehicleInfo } from '../../helpers/aoaBookingFormState.helper'
import { timeConstants } from '../AOABookingForm/AOADateTimeStep/AOADateTimeStep.constants'

/**
 * Calculates the height of app header (Navigation bar + store locator banner)
 * @return {number}
 */
export const getHeightOfNavHeader = (): number => {
    const primaryNavigationHeight = document.querySelector(`.${PREFIX}-primary-navigation`)?.clientHeight || 0
    const storeLocatorHeight = document.querySelector(`.nl-header-row`)?.clientHeight || 0

    return primaryNavigationHeight + storeLocatorHeight
}

/**
 * Function to set the height for mobile booking summary view.
 */
export const calcBookingSummaryMobileHeight = (): void => {
    const bgWrapperEl = document.querySelector(`.${PREFIX}-booking-summary__bg-wrapper`) as HTMLElement
    if (bgWrapperEl) {
        const bookingSummaryMobileBannerHeight =
            document.querySelector(`.${PREFIX}-booking-summary__mobile-banner`)?.clientHeight || 0
        const top = getHeightOfNavHeader() + bookingSummaryMobileBannerHeight - 1
        bgWrapperEl.style.top = `${top}px`
        bgWrapperEl.style.height = `calc(100% - ${top}px)`
    }
}

/**
 * Calculates and sets the top of the Booking Summary mobile banner if booking summary is open.
 * @param {boolean} isMobileBookingSummaryOpen
 */
export const setMobileBannerTop = (isMobileBookingSummaryOpen: boolean) => {
    const bsMobileBanner = document.querySelector(`.${PREFIX}-booking-summary__mobile-banner`) as HTMLElement
    if (bsMobileBanner) {
        bsMobileBanner.style.top = isMobileBookingSummaryOpen ? `${getHeightOfNavHeader()}px` : ''
    }
}

/**
 * Hides the Info banner when booking summary is open
 * @param {boolean} isMobileBookingSummaryOpen
 */
export const hideInfoBanner = (isMobileBookingSummaryOpen: boolean) => {
    const infoBanner = document.querySelector(`.${PREFIX}-information-banner`) as HTMLElement
    if (infoBanner) {
        infoBanner.style.display = isMobileBookingSummaryOpen ? 'none' : ''
    }
}
/**
 * Sets visibility of booking form based on specified parameter
 * @param {boolean}show
 */
export const setBookingFormVisibility = (show: boolean): void => {
    const el = document.querySelector(`.${PREFIX}-aoa-booking-form`) as HTMLElement
    if (el) {
        el.style.display = show ? '' : 'none'
    }
}

/**
 * Listener for making Booking Summary mobile banner sticky to the primary navigation
 * @return {function}
 */
export const onBookingSummaryScroll = (): (() => void) => {
    const stickyClassName = `${PREFIX}-booking-summary__mobile-banner-sticky`
    const infoBannerHeight = document.querySelector('.infobanner')?.clientHeight || 0
    const bookingSummaryMobileBanner = document.querySelector(`.${PREFIX}-booking-summary__mobile-banner`)

    let lastScrollPosition = window.pageYOffset || document.documentElement.scrollTop
    const initialScrollPosition = 0
    return (): void => {
        const currentPosition = window.pageYOffset || document.documentElement.scrollTop
        if (window.scrollY > infoBannerHeight && currentPosition < lastScrollPosition) {
            bookingSummaryMobileBanner?.classList.add(stickyClassName)
        } else {
            bookingSummaryMobileBanner?.classList.remove(stickyClassName)
        }
        lastScrollPosition = currentPosition <= initialScrollPosition ? initialScrollPosition : currentPosition
    }
}

// redux Selector callbacks
export const reduxSelectors = {
    currentStepSelector: (state: RootState): number => state.aoaBookingFormData.currentBookingFormStep || 0,
    vehicleInfoSelector: getVehicleInfo,
    contactInfoSelector: (state: RootState): AOAContactInfoState | null => state.aoaBookingFormData.contactInfo,
    locationInfoSelector: getLocationInfo,
    dateTimeInfoSelector: (state: RootState): AOADateTimeInfoState | null => state.aoaBookingFormData.dateTimeInfo,
    serviceInfoSelector: (state: RootState): AOAServiceInfoState | null => state.aoaBookingFormData.serviceInfo,
    showSkeletonSelector: (state: RootState): boolean => state.aoaBookingFormData.showSkeleton,
}

/**
 * Prepares the required block containing selectedDate , selectedTime and dropOffOptions to show on booking summary
 * @param { AOADateTimeInfoState } dateTimeInfo
 * @return {string}
 */
export const getDateTimeBlockFormatted = (dateTimeInfo: AOADateTimeInfoState): string => {
    const formattedDateTimeBlockInfo: (string | number)[] = []
    Object.keys(dateTimeInfo).forEach(key => {
        if (key === SELECTED_DATE && dateTimeInfo[key]) {
            const date = new Date(`${dateTimeInfo[key] as string}T00:00:00`)
            formattedDateTimeBlockInfo.push(
                `${date.toLocaleString(getUsLocale(), {
                    weekday: 'long',
                })}, ${date.toLocaleString(getUsLocale(), {
                    month: 'long',
                })} ${date.getDate()}`,
            )
        }
        if (key === SELECTED_TIME && dateTimeInfo[key]) {
            const date = new Date(dateTimeInfo[key] as string)
            const timeFormat = date.toLocaleTimeString(getUsLocale(), {
                hour: timeConstants.hourFormat,
                minute: timeConstants.minuteFormat,
            })
            formattedDateTimeBlockInfo.push(getUnpaddedTimeFormat(timeFormat))
        }
        if (key === SELECTED_DROP_OFF_OPTION && dateTimeInfo[key]) {
            formattedDateTimeBlockInfo.push(dateTimeInfo[key] as string)
        }
    })
    return formattedDateTimeBlockInfo.filter(Boolean).join(', ')
}
