import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchBrandList } from '../../redux/actions'
import { isArrayNotEmpty, ProductInformation, SkeletonComponent } from '@nl/lib'
import { MagicNumber } from '../../analytics/analytics.type'
import { PREFIX } from '../../config'
import { VehicleFitType } from '../../globalConstants'
import { ProductSku, ProductSpecifications } from '../../redux/models/product.interface'
import { commonContentSelector } from '../../redux/selectors/commonContent.selectors'
import { productSelector } from '../../redux/selectors/product.selectors'
import { userProfileVehicleSelector } from '../../redux/selectors/userProfile.selectors'
import { tiresDataSelector } from '../../redux/selectors/vehicleTires.selectors'
import checkNestedProps from '../../utils/checkNestedProps'
import extractPCodeFromUrl from '../../utils/PDP/extractPCodeFromUrl'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { getCriticalFitmentProducts } from '../AutomotivePDP/AutomotiveBuyBox/AutomotiveBuyBox.helper'
import {
    getProductTitle,
    isHomeServiceItem,
    getProductUrl,
    getFormattedSkuCode,
    findIntersection,
    getBrandListEndpoint,
} from '../BuyBox/BuyBox.helper'
import ProductReviewsRatings from '../BuyBox/ProductReviewRatings/ProductReviewRatings'
import { isAutomotivePDP, isAutoPartPDP, isTirePDP } from '../Vehicles/Vehicle.helper'
import { tireSizeId } from '../Vehicles/Vehicles.constant'
import { GlobalPropsHelper } from '../../analytics/helpers/globalProps'
import { sportCheckBannerId } from '../../globalConstants'

const ProductInformationWrapper = () => {
    const vehicle = useSelector(userProfileVehicleSelector)
    const { commonContentAvailable } = useSelector(commonContentSelector)
    const { vehicleFitType, criticalFitment } = vehicle || {}
    const { homeServicesTitle, serviceLandingPage } =
        commonContentAvailable?.product || ({} as typeof commonContentAvailable.product)
    const { a11yReviewRating } =
        commonContentAvailable?.accessibility || ({} as typeof commonContentAvailable.accessibility)
    const {
        productData,
        selectedFirstVariant,
        selectedSecondVariant,
        selectedThirdVariant,
        selectedProductCode,
        productSkuData,
        isProductDataAvailable,
        brandList,
    } = useSelector(productSelector)
    const divRef = useRef<HTMLDivElement>(null)
    const counterRef = useRef(0)
    // get the sku code from url
    const extractedCodesFromUrl = useRef(extractPCodeFromUrl()).current
    const { skuCode } = extractedCodesFromUrl
    const [isVariantSelected, setIsVariantSelected] = useState(false)
    const dispatch = useDispatch()
    const triggerBrandlistOnce = useRef(0)

    /**
     * useEffect sets the `isVariantSelected` state based on the length of the `productSkuData` array.
     */
    useEffect(() => {
        checkDataLength(productSkuData) ? setIsVariantSelected(true) : setIsVariantSelected(false)
    }, [productSkuData])

    /**
     * useEffect dispatch the action for getting the brandList from AEM only for sportcheck.
     */
    useEffect(() => {
        if (triggerBrandlistOnce.current === MagicNumber.ZERO) {
            const globalProps = new GlobalPropsHelper()
            const configs = globalProps.init()
            const bannerID = configs['bannerId']
            bannerID === sportCheckBannerId && dispatch(fetchBrandList(getBrandListEndpoint()))
        }
        triggerBrandlistOnce.current = MagicNumber.ONE
    }, [dispatch])

    const roadRatedLabel = checkNestedProps(commonContentAvailable, 'automotive', 'roadRatedLabel') as string
    const tiresData = useSelector(tiresDataSelector)
    const [homeService, setHomeService] = useState(false)
    const [isProductLevel, setIsProductLevel] = useState(true)
    const isAutomotive = isAutomotivePDP(productData?.fitmentTypeCode, productData?.productWheelType)
    const [styleCodes, setStyleCodes] = useState<string[]>([])
    const isStyleCodeFieldExist =
        productData?.options?.[0]?.values?.[0]?.styleCodes !== undefined || productData?.styleCodes !== undefined
    const partLabel = checkNestedProps(commonContentAvailable, 'automotive', 'partLabel') as string

    /**
     * useEffect updates the `styleCodes` state based on the selected variants and the product data.
     */
    useEffect(() => {
        const isStyleCodeExist = !!productData?.options?.[0]?.values?.find(characteristic => characteristic.styleCodes)
        if (!!productData?.styleCodes) {
            setStyleCodes(productData.styleCodes)
        } else if ((!!selectedFirstVariant || !!selectedSecondVariant || !!selectedThirdVariant) && isStyleCodeExist) {
            const selectedValues = productData?.options?.reduce((acc, productCharacteristics): any => {
                const firstVariantStyleCodes = productCharacteristics.values?.find(
                    characteristic => characteristic.value === selectedFirstVariant,
                )?.styleCodes
                const secondVariantStyleCodes = productCharacteristics.values?.find(
                    characteristic => characteristic.value === selectedSecondVariant,
                )?.styleCodes
                const thirdVariantStyleCodes = productCharacteristics.values?.find(
                    characteristic => characteristic.value === selectedThirdVariant,
                )?.styleCodes
                const allStyleCodes = [firstVariantStyleCodes, secondVariantStyleCodes, thirdVariantStyleCodes].filter(
                    chosenStyleCodes => chosenStyleCodes?.length,
                )
                return [...acc, ...allStyleCodes]
            }, [])
            const result: string[] = isArrayNotEmpty(selectedValues) ? findIntersection(...selectedValues) : []
            setStyleCodes(result)
        } else if (isStyleCodeExist) {
            // TODO: Ask author for help
            // eslint-disable-next-line
            const allFirstVariantStyleCodes = productData?.options?.[0]?.values
                ?.reduce((acc, characteristic): string[] => [...acc, characteristic.styleCodes] as string[], [])
                ?.flat()
                .filter((styleCodesList: string): string[] => styleCodesList?.length) as string[]
            setStyleCodes(Array.from(new Set(allFirstVariantStyleCodes)))
        }
    }, [selectedFirstVariant, selectedSecondVariant, selectedThirdVariant, productData])

    /**
     * function that returns the part number of the first SKU if the product is an auto part and the vehicle fitment is applicable.
     * @returns {string} - if the product is an auto part and the vehicle fitment is applicable,
     * otherwise an empty string.
     */
    const getPartNumber = useCallback((): string => {
        const isFittedAutoParts =
            isAutoPartPDP(productData?.fitmentTypeCode, productData?.productWheelType) &&
            (vehicleFitType === VehicleFitType.Fit || !isProductLevel)
        return isFittedAutoParts ? productSkuData?.skus?.[0]?.partNumber : ''
    }, [productData, vehicleFitType, productSkuData, isProductLevel])

    /**
     * function to return product code
     * @return {string} if home service is true return product code else return selected product code
     */
    const getSkuCode = useCallback((): string => {
        return !isVariantSelected ? productData.code : selectedProductCode
    }, [isVariantSelected, productData.code, selectedProductCode])

    /**
     * UseEffect updates the `isProductLevel` and `homeService` states based on the product data and the selected product code.
     */
    useEffect(() => {
        productData && selectedProductCode && setIsProductLevel(selectedProductCode === productData.code)
        setHomeService(isHomeServiceItem(productData?.type))
    }, [productData, selectedProductCode])

    const brand = getProductTitle(homeService, homeServicesTitle, productData)
    /**
     * Check if have tireData and specification matches then returns the sku accordingly.
     * @return { string | undefined } sku
     */
    // eslint-disable-next-line complexity
    const getSkuCodeForTireSizePDP = useCallback((): string | undefined => {
        if (
            checkDataLength(tiresData) &&
            isTirePDP(productData?.productWheelType) &&
            isArrayNotEmpty(productData?.skus)
        ) {
            const { skuCode: currentSelectedSku } = extractPCodeFromUrl()
            if (skuCode && (counterRef.current === MagicNumber.ZERO || currentSelectedSku === skuCode)) {
                counterRef.current = MagicNumber.ONE
                // navigated from plp to pdp with sku (shop by tire size)
                if (productData.skus.find(item => item.code === skuCode)) {
                    const tireSizeData = `${String(tiresData?.selectedSectionWidth)}${String(
                        tiresData?.selectedAspectRatio,
                    )}${String(tiresData?.selectedDiameterValue)}`
                    const selectedskuSpecification = productData.skus.find(
                        item => item.code === skuCode,
                    )?.specifications
                    const isSpecificationPresent = selectedskuSpecification?.find(
                        (spec: ProductSpecifications) =>
                            spec.code?.toLowerCase() === tireSizeId.toLowerCase() &&
                            spec.value.split(' ')[0].replace(/\D/g, '').includes(tireSizeData),
                    )
                    return isSpecificationPresent ? skuCode : productData.code
                } else {
                    return productData?.code
                }
            } else {
                // navigated from plp to pdp with pcode (shop by tire size)
                const tireSizeData = `${String(tiresData?.selectedSectionWidth)}/${String(
                    tiresData?.selectedAspectRatio,
                )}R${String(tiresData?.selectedDiameterValue)}`
                const skuDetails = productData.skus.find((skuDetail: ProductSku) =>
                    skuDetail.specifications?.find((spec: ProductSpecifications) => spec.value.includes(tireSizeData)),
                )
                if (checkDataLength(skuDetails)) {
                    return skuDetails?.code
                } else {
                    return productData?.code
                }
            }
        } else {
            return getSkuCode()
        }
    }, [tiresData, productData, getSkuCode, skuCode])

    /**
     *
     * @return {string}
     */
    const getCodeForProduct = useMemo(() => {
        return isAutomotive
            ? vehicleFitType === VehicleFitType.Fit
                ? selectedProductCode
                : vehicleFitType !== VehicleFitType.PartialFit && getSkuCodeForTireSizePDP()
            : getSkuCode()
    }, [isAutomotive, vehicleFitType, selectedProductCode, getSkuCode, getSkuCodeForTireSizePDP])

    const formattedSkuCode =
        !!getCodeForProduct && getCodeForProduct !== productData?.code
            ? getFormattedSkuCode(getCodeForProduct, productData)
            : ''

    // function return product information
    const productInformation = (): JSX.Element => {
        return (
            <ProductInformation
                brand={brand}
                brandUrl={getProductUrl(homeService, serviceLandingPage, productData, brandList)}
                title={productData.name}
                isFitmentRequired={isAutomotive}
                sku={isStyleCodeFieldExist ? styleCodes : formattedSkuCode}
                isStyleCodeExist={isStyleCodeFieldExist}
                tireCategory={productData.categoryLevel}
                isSKUVisible={getCriticalFitmentProducts(criticalFitment).isStaggered}
                partNumber={getPartNumber()}
                partLabel={partLabel}
                isSticky={false}
            />
        )
    }

    return (
        <>
            {!!isProductDataAvailable ? (
                <div
                    id={`${PREFIX}-product-information-wrapper`}
                    className={`${PREFIX}-product-information-wrapper`}
                    ref={divRef}>
                    {productInformation()}
                    <ProductReviewsRatings
                        productData={productData}
                        reviewsAndRatingA11yLabel={a11yReviewRating}
                        roadRatedLabel={roadRatedLabel}
                    />
                </div>
            ) : (
                <SkeletonComponent skeletonClass={`${PREFIX}-title-wrapper__skeleton`} />
            )}
        </>
    )
}

export default ProductInformationWrapper
