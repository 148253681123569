import { StoreWithAvailability, WeekDayOpeningList } from '../../redux/models/storeDetails.interface'
import appCacheService from '../appCacheService'
import { SortStoreDataType } from '../../components/Header/Header.type'
import storeLocatorAnalytics from '../../analytics/components/storeLocatorAnalytics'
import { checkNeedUpdateWeekdayHours, getCurrentDay, libUtils } from '@nl/lib'
import { checkDataLength } from '../../components/Accounts/Addresses/checkDataLength'
import { analyticsAttributes } from '../../globalConstants/analyticsParams.constant'
import { setStoreAPITriangulationFailAction } from '../../redux/actionCreators'

/**
 * Function to change store.
 *
 * @param {StoreWithAvailability} store
 * @param {StoreWithAvailability[]} storeList
 * @param {React.SetStateAction} setSortedData
 * @param {Function} sortStoreDataList
 * @param {boolean} isLoggedIn
 * @param {React.Dispatch} dispatch
 * @param {React.SetStateAction} setPreferredStoreId
 * @param {string} pageTypeForAnalytics
 * @param {StoreWithAvailability} preferredStore
 */
const setPreferredStore = (
    store: StoreWithAvailability,
    storeList: StoreWithAvailability[],
    setSortedData: React.Dispatch<React.SetStateAction<SortStoreDataType>>,
    sortStoreDataList: (storeList: StoreWithAvailability[], preferredStore: StoreWithAvailability) => SortStoreDataType,
    isLoggedIn: boolean,
    dispatch: React.Dispatch<any>,
    setPreferredStoreId: React.Dispatch<React.SetStateAction<string>>,
    pageTypeForAnalytics = '',
    preferredStore: StoreWithAvailability,
): void => {
    const { get, set } = appCacheService.preferredStoreId
    const { setPreferredStoreAnalytics } = storeLocatorAnalytics
    const {
        eventParameters: {
            selection: { userSelected },
        },
    } = analyticsAttributes
    set(String(store.id)) // set the preferred store in local storage.
    setPreferredStoreId(get()) // Get the preferred store from local storage and set it to the state variable which will be used by sortStoreDataList.
    setSortedData(sortStoreDataList(storeList, preferredStore)) // Filter the data based on the preferred store id. UI change will be based on this.
    dispatch(setStoreAPITriangulationFailAction(false)) // update redux state for maxMindApiFail state
    // Below is used when user logs in. Used to send store id to his profile.
    // OCCP-21600
    // if (isLoggedIn) {
    //     const requestPayload = {
    //         ecommerce: {
    //             CTR: {
    //                 preferredStore: String(store.id),
    //             },
    //         },
    //     }
    //     dispatch(updateProfile(requestPayload))
    // }

    // Below is to get closed flag from the store.
    if (checkDataLength(store as unknown as Record<string, unknown>)) {
        const { openingHours } = store
        const weekdayHours = openingHours?.weekDayOpeningList?.find(
            (day: WeekDayOpeningList) => day.weekDay.toLowerCase() === getCurrentDay(libUtils.getLanguage()),
        )
        const weekdayHoursUpdate = checkNeedUpdateWeekdayHours(store, weekdayHours) as WeekDayOpeningList
        const { closed } = weekdayHoursUpdate || {}
        setPreferredStoreAnalytics(store, Boolean(closed), pageTypeForAnalytics, userSelected)
    }
}

export { setPreferredStore }
export default setPreferredStore
