import { MagicNumber } from '../../analytics/analytics.type'
import { AspotAutoplayControls, SlickElement } from '../SlickCarouselComponent/SlickCarouselComponent.type'
const $ = window.$ // Defined in the external.ts file.
const ASPOT_CAROUSEL_AUTOSPEED = MagicNumber.EIGHTTHOUSAND
const aspotDataProps = document.getElementsByClassName('aspot-carousel')[MagicNumber.ZERO]?.getAttribute('data-props')
const autoPlayControls = aspotDataProps && (JSON.parse(aspotDataProps) as unknown as AspotAutoplayControls)
const autoplay = autoPlayControls ? autoPlayControls?.autoplay : false
const autoplaySpeed = autoPlayControls
    ? autoPlayControls?.autoplaySpeed * MagicNumber.ONETHOUSAND
    : ASPOT_CAROUSEL_AUTOSPEED
const prevArrow =
    '<svg viewBox="0 0 20 20" fill="#262626" width="16" height="16"><path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd"/></svg>'
const nextArrow =
    '<svg viewBox="0 0 20 20" fill="#262626" width="16" height="16"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"/></svg>'
const playIcon =
    '<svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14"><path d="M6.431.345a.642.642,0,0,1,1.139,0l6.3,10.331a.967.967,0,0,1,.057.869A.7.7,0,0,1,13.3,12H.7a.7.7,0,0,1-.626-.455.967.967,0,0,1,.057-.869Z" transform="translate(12) rotate(90)" fill="#262626"/></svg>'
const pauseIcon =
    '<svg xmlns="http://www.w3.org/2000/svg" width="11.996" height="13.996" viewBox="0 0 11.996 13.996"><path d="M33334,28099v-14h4v14Zm-8,0v-14h4v14Z" transform="translate(-33326.004 -28085.004)" fill="#262626"/></svg>'
/**
 * carouselJS - converts the div element which is accessed below in to carousel using slick js.
 * @param {Element} element - HTML element
 * @param {Record<string, string>} params - Additional Params which includes slickClass.
 */
const AspotCarouselComponent = (element?: SlickElement, params?: Record<string, string>): void => {
    const slickElement = $(element).children(`.${String(params?.slickClass)}`)
    // Apply slick on the each instance of regular class.
    slickElement.slick({
        dots: true,
        infinite: true,
        slidesToShow: MagicNumber.ONE,
        slidesToScroll: MagicNumber.ONE,
        autoplay,
        autoplaySpeed,
        pauseOnFocus: false,
        pauseOnHover: false,
        prevArrow: null,
        nextArrow: null,
        playing: autoplay,
        responsive: [
            {
                breakpoint: MagicNumber.THOUSANDTWOHUNDRED,
                settings: {
                    autoplay: false,
                    playing: false,
                },
            },
        ],
    })
    const slickDots = slickElement.find('.slick-dots')[0]
    $(slickDots).wrap('<div class="buttons-container">')
    // insert prev button before play/pause button
    // need to use prev-button class because slick-prev comes with built in styles
    $(
        `<button type="button" data-qm-allow="true" aria-label="previous" class="prev-button nl-xs-none nl-sm-none nl-md-block">${prevArrow}</button>`,
    ).insertBefore($(slickDots))

    // insert play/pause button before.slick-dots
    if (autoplay) {
        $(
            `<button type="button" class="pause-button nl-xs-none nl-sm-none nl-md-block" aria-label="pause">${pauseIcon}</button>`,
        ).insertBefore($(slickDots))
    } else {
        $(
            `<button type="button" class="play-button nl-xs-none nl-sm-none nl-md-block" aria-label="play">${playIcon}</button>`,
        ).insertBefore($(slickDots))
    }

    // inert next button after .slick-dots
    // need to use next-button class because slick-next comes with built in styles
    $(
        `<button type="button" data-qm-allow="true" aria-label="next" class="next-button nl-xs-none nl-sm-none nl-md-block">${nextArrow}</button>`,
    ).insertAfter($(slickDots))

    const slickPlayButton = $(slickElement).find('.play-button')[0] || $(slickElement).find('.pause-button')[0]

    $(slickPlayButton).click(function () {
        const isPlaying = $(slickElement).slick('slickGetOption', 'playing')

        if (isPlaying) {
            $(slickElement).slick('slickPause')
            $(slickPlayButton).html(`${playIcon}`)
        } else {
            $(slickElement).slick('slickPlay')
            $(slickPlayButton).html(`${pauseIcon}`)
        }
        $(slickElement).slick('slickSetOption', 'playing', !isPlaying)
    })

    $($(slickElement).find('.next-button')[0]).click(function () {
        $(slickElement).slick('slickNext')
    })

    $($(slickElement).find('.prev-button')[0]).click(function () {
        $(slickElement).slick('slickPrev')
    })
}
export { AspotCarouselComponent }
