import { AxiosPromise } from 'axios'
import { getEnvironment } from '../../environments'
import { DevConfig } from '../../environments/dev.environment'
import { getHttpClient } from '../../httpClient'
import BaseService from '../base.service'
import { TriangleMCResponse } from './triangleMCResponse.interface'
import { libUtils } from '@nl/lib'

const environment: DevConfig = getEnvironment()
const httpClient = getHttpClient()
const lang = libUtils.getLanguage()

/**
 * Creates an URL with the requestingSystem and customerInfo received
 */
class TriangleMCBannerService extends BaseService {
    /**
     * Creates de URL to send to CDS
     * @param {string} requestingSystem
     * @param {Record<string, unknown>} customerInfo
     * @return {AxiosPromise}
     */
    getApplyTriangleMCService(
        requestingSystem: string,
        customerInfo: Record<string, unknown>,
    ): AxiosPromise<TriangleMCResponse> {
        const url: URL = this.createTriangleMCUrl()
        return TriangleMCBannerService.isMock()
            ? httpClient.apiGet<TriangleMCResponse>(url.toString(), { requestingSystem, customerInfo, lang })
            : httpClient.apiPost<TriangleMCResponse>(url.toString(), { requestingSystem, customerInfo, lang })
    }

    /**
     * Creates the new url
     * @return {URL}
     */
    createTriangleMCUrl(): URL {
        const url = new URL(`${environment.API_BASE_URL}${environment.API_ENDPOINTS.triangleMCBanner}`)
        if (TriangleMCBannerService.isMockUri(environment.API_ENDPOINTS.triangleMCBanner)) {
            return TriangleMCBannerService.prepareMockUrl(url, environment.API_MOCK_URL)
        } else {
            return url
        }
    }
}

const triangleMCBannerService = new TriangleMCBannerService()

export { triangleMCBannerService }
export default triangleMCBannerService
