import { analyticsAttributes } from '../../globalConstants/analyticsParams.constant'
import { VideoModalAnalytics, VideoEventAnalytics } from '../analytics.type'
import getPageType from '../../utils/getPageType'
import { getProvider } from '../providers'

const provider = getProvider()

export const videoAnalytics = (() => {
    /**
     * Function to fire analytics when Banner Video Modal is open
     * @param {string} videoType
     * @param {string} VideoLabel
     *  @return { videoEventAnalytics }
     */
    const prepareData = (videoType: string, VideoLabel: string): VideoModalAnalytics => {
        return {
            action: analyticsAttributes.eventParameters.action.videoPlay,
            category: `${getPageType()} | ${videoType}`,
            label: VideoLabel,
            value: '',
        }
    }

    /**
     * This function is to trigger analytics for single page
     * @param {string} videoType
     * @param {string} VideoLabel
     * @return { videoEventAnalytics }
     */
    const _videoAnalyticsData = (videoType: string, VideoLabel: string): VideoEventAnalytics => {
        return {
            event: analyticsAttributes.event.video,
            eventParameters: prepareData(videoType, VideoLabel),
        }
    }
    return {
        setAnalytics: (videoType: string, VideoLabel: string): void => {
            provider.push(_videoAnalyticsData(videoType, VideoLabel))
        },
    }
})()
