import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { RootState } from '../../redux/reducers'
import { pageTypes, PREFIX } from '../../config'
import { CategoryMastheadProps } from './CategoryMasthead.type'
import { edgeToEdgeBanner } from '../EdgeToEdgeBanner/EdgeToEdgeBanner'
import { edgeBannerLength } from './CategoryMasthead.constants'
import getPageType from '../../utils/getPageType'

/**
 * returns Loading Skeleton for CategoryMasthead
 * @return {JSX.Element}
 */
const CategoryMasthead: React.FC<CategoryMastheadProps> = ({ ...props }): JSX.Element | null => {
    const { backgroundColor } = props
    const { name, description, breadcrumbList, isFitmentRequired } = useSelector(
        (state: RootState) => state.categoryIdData,
    )
    const parentClass = `${PREFIX}-categoryMasthead-banner`
    const [isHideCategoryBanner, hideCategoryBanner] = useState(true)

    useEffect(() => {
        breadcrumbList && edgeToEdgeBanner()
        const pageType = getPageType()
        if (isFitmentRequired || pageType === pageTypes.packageLanding) {
            hideCategoryBanner(true)
        } else {
            const parentElement = document.getElementsByClassName('edge-to-edge-banner')
            // show the category mast head only if no edge to edge banner is authored
            parentElement.length === edgeBannerLength && hideCategoryBanner(false)
        }
    }, [breadcrumbList, parentClass, isFitmentRequired])

    // TO-DO: need to modify edgeToEdgeBanner script getting called for this also
    return name && !isHideCategoryBanner ? (
        <div className="edge-to-edge-banner">
            <div
                className={`${PREFIX}-full-width-container ${parentClass}`}
                style={{ backgroundColor: backgroundColor }}>
                <div className="nl-row nl-container">
                    <div className={`nl-col-xs-6 nl-col-sm-7 nl-col-md-7 ${PREFIX}-no-left-padding`}>
                        <h1 className={`${parentClass}__title`}>{name}</h1>
                        <p className={`${parentClass}__paragraph`}>{description}</p>
                    </div>
                </div>
            </div>
        </div>
    ) : null
}

CategoryMasthead.propTypes = {
    backgroundColor: PropTypes.string.isRequired,
}

export default CategoryMasthead
