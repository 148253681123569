import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { PREFIX } from '../../config'
import { StoreDetailsCompProps } from './NearByStores.type'
import {
    Button,
    replaceStrWithDynamicVal,
    generateGoogleMapOnAddress,
    isArrayNotEmpty,
    getFormattedPhoneNumber,
    getStoreTimings,
    getNextDayStoreTimings,
    formatedPhoneNumberAccessibility,
} from '@nl/lib'
import { StoreAddress, StoreGeoPoint, StoreWithAvailability } from '../../redux/models/storeDetails.interface'
import { minimumNearByStoresToShow } from './NearByStores.constant'
import { fetchNearByStoreDetailsForSelectedStore } from '../../redux/actions/storeDetails.action'
import { analyticsAttributes } from '../../globalConstants/analyticsParams.constant'
import { invokeGenericLinkClickAnalytics } from '../../analytics/components/genericLinkClickAnalytics'
import { Icon } from '@nl/lib'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { MagicNumber } from '../../analytics/analytics.type'
import { commonContentSelector } from '../../redux/selectors/commonContent.selectors'
import { nearByStoreListForAStoreSelector, storeDetailsSelector } from '../../redux/selectors/storeDetails.selectors'

const NearByStores: React.FC<StoreDetailsCompProps> = ({ ...props }) => {
    const {
        title,
        findMoreCTALabel,
        storeDetailsCTALabel,
        findMoreCTAURL,
        onlineOrdersNotAcceptedMsg,
        maxNumberOfStores,
    } = props
    const { commonContentAvailable } = useSelector(commonContentSelector)
    const { closeLabel, closesAtLabel, openLabel, openingAtLabel } = commonContentAvailable?.storeLocator || {}
    const storeDetails = useSelector(storeDetailsSelector)
    const nearByStoreListForAStore = useSelector(nearByStoreListForAStoreSelector)
    const excludeCurrentStoreFromNearByList = nearByStoreListForAStore?.filter(({ id }) => {
        return id !== storeDetails?.id
    })
    const storeLimit = minimumNearByStoresToShow
    const storeList = excludeCurrentStoreFromNearByList?.slice(0, storeLimit)
    const { maxRadiusToSearchStore } = commonContentAvailable?.storeLocator || {}
    const [accessibility, setAccessibility] = useState(
        commonContentAvailable?.accessibility || ({} as typeof commonContentAvailable.accessibility),
    )

    useEffect(() => {
        commonContentAvailable?.accessibility && setAccessibility(commonContentAvailable.accessibility)
    }, [commonContentAvailable])

    const dispatch = useDispatch()

    const getNearbyStores = useCallback(
        (geoPoint: StoreGeoPoint) => {
            const { latitude, longitude } = geoPoint
            const requestPayload = {
                // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                maxCount: maxNumberOfStores + MagicNumber.ONE,
                radius: Number(maxRadiusToSearchStore) || 0,
                latitude,
                longitude,
            }
            dispatch(fetchNearByStoreDetailsForSelectedStore(requestPayload))
        },
        [maxRadiusToSearchStore, maxNumberOfStores, dispatch],
    )

    const {
        eventParameters: {
            location: { nearByStores },
        },
    } = analyticsAttributes

    useEffect(() => {
        if (checkDataLength(storeDetails)) {
            getNearbyStores(storeDetails.geoPoint)
        }
    }, [storeDetails, getNearbyStores])

    /**
     * This findMoreStores redirect to the view more near by stores.
     */

    const findMoreStores = (): void => {
        window.open(findMoreCTAURL, '_blank', 'noopener')
        invokeGenericLinkClickAnalytics(internal, `${findMoreCTALabel}`, nearByStores)
    }
    const { external, internal } = analyticsAttributes.linkTypes

    /**
     * function to get store timings block
     * @param {StoreWithAvailability} store
     * @return {JSX.Element}
     */
    const storeTimings = (store: StoreWithAvailability): JSX.Element => {
        const weekdayHours = store && getStoreTimings(store)
        const nextWeekDayHours = store && getNextDayStoreTimings(store)

        const { closed, closingTime } = weekdayHours || {}
        const { openingTime } = nextWeekDayHours || {}

        const storeOpenOrCloseLabel = closed
            ? replaceStrWithDynamicVal(openingAtLabel, openingTime?.formattedHour)
            : replaceStrWithDynamicVal(closesAtLabel, closingTime?.formattedHour)

        const storeTimingsPresent = closed ? openingTime?.formattedHour : closingTime?.formattedHour

        return storeTimingsPresent ? (
            <div className={`${PREFIX}-near-stores__store-status`}>
                <span>{closed ? closeLabel : openLabel}</span> ⋅{' '}
                <span className={`${PREFIX}-near-stores__store-timings`}>{storeOpenOrCloseLabel}</span>
            </div>
        ) : (
            <></>
        )
    }

    /**
     * redirect User to google map of click of store address
     * @param {StoreAddress} address store address
     * @return {string}
     */
    const redirectToGoogleMaps = (address: StoreAddress): string => {
        return generateGoogleMapOnAddress(`${address.line1 ?? ''} ${address.line2 ?? ''},${address.postalCode ?? ''}`)
    }

    return (
        <>
            {isArrayNotEmpty(storeList) && (
                <div className={`${PREFIX}-store-details`}>
                    <div className={` ${PREFIX}-container`}>
                        <h2 className={`${PREFIX}-row ${PREFIX}-container ${PREFIX}-near-stores__heading`}>{title}</h2>
                        <div className={`${PREFIX}-near-stores__text-content `}>
                            {storeList.map((store: StoreWithAvailability, index: number) => (
                                <div
                                    className={`${PREFIX}-near-stores__address ${PREFIX}-col-xs-6 ${PREFIX}-col-sm-6 ${PREFIX}-col-md-4`}
                                    key={index}>
                                    <div className={`${PREFIX}-near-stores__store-name`}>{store.displayName}</div>
                                    {storeTimings(store)}
                                    <a
                                        href={redirectToGoogleMaps(store.address)}
                                        target={`_blank`}
                                        className={`${PREFIX}-near-stores__store-open`}
                                        data-testid="stores-open"
                                        onClick={() =>
                                            invokeGenericLinkClickAnalytics(
                                                external,
                                                `${store.address.formattedAddress}, ${store.address.postalCode}`,
                                                nearByStores,
                                            )
                                        }>
                                        {store.address?.line1}, {store.address?.postalCode}
                                    </a>
                                    <a
                                        className={`${PREFIX}-near-stores__store-contact`}
                                        aria-label={`${
                                            accessibility?.a11yTelephoneAriaLabel
                                        } ${formatedPhoneNumberAccessibility(store.address?.phone)}`}
                                        href={`tel://` + store.address?.phone}
                                        data-testid="store-contact"
                                        onClick={() =>
                                            invokeGenericLinkClickAnalytics(
                                                external,
                                                `${store.address.phone}`,
                                                nearByStores,
                                            )
                                        }>
                                        {getFormattedPhoneNumber(store.address?.phone)}
                                    </a>
                                    <a
                                        href={store.url}
                                        className={`${PREFIX}-near-stores__view-store`}
                                        data-testid="view-store"
                                        onClick={() =>
                                            invokeGenericLinkClickAnalytics(
                                                internal,
                                                `${storeDetailsCTALabel}`,
                                                nearByStores,
                                            )
                                        }>
                                        {storeDetailsCTALabel}
                                    </a>
                                    {!store.onlineOrdering && (
                                        <div className={`${PREFIX}-near-stores__online_orders_status`}>
                                            <div>
                                                <Icon type="ct-notification-caution-stroked" size="md" />
                                            </div>
                                            <span className={`${PREFIX}-near-stores__online_orders_status-message`}>
                                                {onlineOrdersNotAcceptedMsg}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    {nearByStoreListForAStore.length > storeLimit && (
                        <div className={`${PREFIX}-near-stores__button`}>
                            <Button id="findMoreStores" type="secondary" size="large" onClick={findMoreStores}>
                                {findMoreCTALabel}
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

NearByStores.propTypes = {
    title: PropTypes.string.isRequired,
    findMoreCTALabel: PropTypes.string.isRequired,
    storeDetailsCTALabel: PropTypes.string.isRequired,
    findMoreCTAURL: PropTypes.string,
    onlineOrdersNotAcceptedMsg: PropTypes.string,
    maxNumberOfStores: PropTypes.number,
}

export default NearByStores
