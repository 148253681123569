import { storageData, regionsData } from '../globalConstants/global.constant'
import { FilteredCartData } from '../redux/models/cart.interface'
import { searchConstants, Vehicle, Suggest } from '@nl/lib'
import localStorageService from './localStorageService'
import { getEnvironment } from '../environments'
import sessionStorageService from './sessionStorageService'
import { CompleteSearchAnalytics } from '../analytics/analytics.type'
import { removeContactInfoFromStorageData } from './removeContactInfoFromStorageData'
import { StickyBannerTimerData } from '../components/StickyBanner/StickyBanner.type'

/**
 * Util that would return necessary values from local storage
 */
const appCacheService = ((localStorage, sessionStorage) => {
    const environment = getEnvironment()
    /**
     * get cart guid from local storage
     * @return {string}
     */
    const _getCartGuid = (): string => {
        return localStorage.getItem(storageData.cartGuid) || ''
    }

    /**
     * get save for later guid from local storage
     * @return {string}
     */
    const _getSflGuid = (): string => {
        return localStorage.getItem(storageData.sflGuid) || ''
    }

    /**
     *
     * @param {string} guid
     * @return {void}
     */
    const _setCartGuid = (guid: string) => localStorage.setItem(storageData.cartGuid, guid)

    /**
     *
     * @param {string} guid
     * @return {void}
     */
    const _setSflGuid = (guid: string) => localStorage.setItem(storageData.sflGuid, guid)

    /**
     * remove sfl from local storage
     * @return {string}
     */
    const _removeSflGuid = (): void => {
        return localStorage.removeItem(storageData.sflGuid)
    }

    /**
     * get wishlist guid from local storage
     * @return {string}
     */
    const _getWishlistGuid = (): string => {
        return localStorage.getItem(storageData.wishlistGuid) || ''
    }

    /**
     * get cart items data from local storage
     * @return {FilteredCartData }
     */
    const _getCartData = (): FilteredCartData => {
        const data = localStorage.getItem(storageData.cartData) || ''
        try {
            return JSON.parse(data) as FilteredCartData
        } catch (error) {
            return {} as FilteredCartData
        }
    }

    /**
     * get cart items data from local storage
     * @param {string} value
     * @return {void}
     */
    const _setCartData = (value: string): void => {
        return localStorage.setItem(storageData.cartData, removeContactInfoFromStorageData(value))
    }

    /**
     * get cart store changed flag
     * @return {string}
     */
    const _getCartStoreChanged = (): string => {
        return localStorage.getItem(storageData.cartStoreChanged) || ''
    }

    /**
     * set cart store changed flag
     * @param {string} value
     * @return {void}
     */
    const _setCartStoreChanged = (value: string): void => {
        return localStorage.setItem(storageData.cartStoreChanged, value)
    }

    /**
     * remove cart store changed flag
     * @return {void}
     */
    const _removeCartStoreChanged = (): void => {
        return localStorage.removeItem(storageData.cartStoreChanged)
    }

    /**
     * get flag of delivery mode conflict
     * @return {string}
     */
    const _isDeliveryModeConflict = (): string => {
        return localStorage.getItem(storageData.isDeliveryModeConflict) || ''
    }

    /**
     * set flag of delivery mode conflict
     * @param {string} value
     * @return {void}
     */
    const _setDeliveryModeConflict = (value: string): void => {
        return localStorage.setItem(storageData.isDeliveryModeConflict, value)
    }

    /**
     * remove flag of delivery mode conflict
     * @return {void}
     */
    const _removeDeliveryModeConflict = (): void => {
        return localStorage.removeItem(storageData.isDeliveryModeConflict)
    }

    /**
     * set optimizely shopping cart data to local storage
     * @param {string} value
     * @return {void}
     */
    const _setOptimizelyShoppingCart = (value: string): void => {
        return localStorage.setItem(storageData.optimizelyShoppingCart, value)
    }

    /**
     * get gigya JWT from local storage
     * @return {string}
     */
    const _getGigyaJWTToken = (): string => {
        return localStorage.getItem(storageData.gigyaJWT) || ''
    }

    const _setGigyaJWTToken = (jwtToken: string): void => {
        localStorage.setItem(storageData.gigyaJWT, jwtToken)
    }

    const _deleteGigyaJWTToken = (): void => {
        localStorage.removeItem(storageData.gigyaJWT)
    }

    /**
     * get delivery Postal Code from local storage
     * @return {string}
     */
    const _getDeliveryPostalCode = (): string => {
        return localStorage.getItem(storageData.deliveryPostalCode) || ''
    }

    const _setDeliveryPostalCode = (value: string): void => {
        localStorage.setItem(storageData.deliveryPostalCode, value)
    }

    const _deleteDeliveryPostalCode = (): void => {
        localStorage.removeItem(storageData.deliveryPostalCode)
    }

    const _deleteAllUserDetails = (): void => {
        localStorage.removeItem(storageData.UID)
        localStorage.removeItem(storageData.regToken)
        localStorage.removeItem(storageData.tokenState)
        localStorage.removeItem(storageData.forgotEmail)
        localStorage.removeItem(storageData.gigyaJWT)
        localStorage.removeItem(storageData.cdsToken)
        localStorage.removeItem(storageData.ctfsMember)
        localStorage.removeItem(storageData.totalTraingleTransaction)
    }

    /**
     * get Preferred store id from local storage
     * @return {string}
     */
    const _getPreferredStoreId = (): string => localStorage.getItem(storageData.preferredStoreId) || ''

    /**
     * Set Preferred store id from local storage
     * @param {string} value
     */
    const _setPreferredStoreId = (value: string): void => {
        localStorage.setItem(storageData.preferredStoreId, value)
    }

    /**
     * get Preferred store timezone from local storage
     * @return {string}
     */
    const _getStoreTimeZone = (): string => localStorage.getItem(storageData.storeTimezone) || ''

    /**
     * Set Preferred store timezone from local storage
     * @param {string} value
     */
    const _setStoreTimeZone = (value: string): void => {
        const oldValue = window.localStorage.getItem(storageData.storeTimezone)
        localStorage.setItem(storageData.storeTimezone, value)
        const event = new StorageEvent('storage', {
            key: storageData.storeTimezone,
            oldValue,
            newValue: value,
        })
        window.dispatchEvent(event)
    }

    /**
     * set banner timer data to local storage
     * @param {string} value
     * @return {void}
     */
    const _setBannerTimer = (value: string): void => {
        return localStorage.setItem(storageData.emailStickyBannerTimer, value)
    }

    /**
     * get banner timer data from local storage
     * @return {StickyBannerTimerData }
     */
    const _getBannerTimer = (): StickyBannerTimerData => {
        const data = localStorage.getItem(storageData.emailStickyBannerTimer)
        return JSON.parse(data) as StickyBannerTimerData
    }

    /**
     * get vehicle from local storage
     * @return {Vehicle[] | null}
     */
    const _getVehicles = (): Vehicle[] | null => {
        const data = localStorage.getItem(storageData.vehicles) as string
        try {
            return JSON.parse(data) as Vehicle[]
        } catch (error) {
            return null
        }
    }

    /**
     * Get Postal Code from local storage.
     * @return {string}
     */
    const _getPostalCode = (): string => {
        return localStorage.getItem(storageData.postalCode) || ''
    }

    /**
     * Set postal Code in local storage.
     * @param {string} value - postal code value.
     */
    const _setPostalCode = (value: string): void => {
        localStorage.setItem(storageData.postalCode, value)
    }

    /**
     * Delete postal code from local storage.
     */
    const _deletePostalCode = (): void => {
        localStorage.removeItem(storageData.postalCode)
    }

    const _getCountriesData = (locale: string): string => {
        return localStorage.getItem(`${storageData.countries}-${locale}`) || '[]'
    }

    /**
     * Get searched term from local storage.
     * @return {string}
     */
    const _getRecentSearchList = (): string => {
        return localStorage.getItem(storageData.recentSearchList) || ''
    }

    /**
     * Set searched term  in local storage.
     * @param {string} value - saerch term
     */
    const _setRecentSearchList = (value: string): void => {
        localStorage.setItem(storageData.recentSearchList, value)
    }

    /**
     * Set postal Code in local storage.
     * @param {string} value - postal code value.
     */
    const _setTiresData = (value: string): void => {
        localStorage.setItem(storageData.tiresSizeData, value)
    }

    /**
     * Get is gift card was applied
     * @param {string} cartId - cart id value.
     * @return {boolean}
     */
    const _getIsGiftCardWasApplied = (cartId: string): boolean => {
        return !!JSON.parse(localStorage.getItem(storageData.isGiftCardWasApplied + cartId) || 'false')
    }

    /**
     * Set gift card was applied
     * @param {string} cartId - cart id value
     * @param {boolean} isGiftCardWasApplied - is gift card was applied
     */
    const _setIsGiftCardWasApplied = (cartId: string, isGiftCardWasApplied: boolean): void => {
        localStorage.setItem(storageData.isGiftCardWasApplied + cartId, JSON.stringify(isGiftCardWasApplied))
    }

    /**
     * Get are paypal tenders were applied
     * @param {string} cartId - cart id value.
     * @return {boolean}
     */
    const _getIsPaypalTendersApplied = (cartId: string): boolean => {
        return !!JSON.parse(localStorage.getItem(storageData.isPaypalTendersApplied + cartId) || 'false')
    }

    /**
     * Set paypal tenders  were applied
     * @param {string} cartId - cart id value
     * @param {boolean} isPaypalTendersApplied - is gift card was applied
     */
    const _setIsPaypalTendersApplied = (cartId: string, isPaypalTendersApplied: boolean): void => {
        localStorage.setItem(storageData.isPaypalTendersApplied + cartId, JSON.stringify(isPaypalTendersApplied))
    }

    /**
     * Remove tenders applied flags from local storage
     * @param {string} cartId - cart id value
     */
    const _removeTendersStorageData = (cartId: string): void => {
        localStorage.removeItem(storageData.isPaypalTendersApplied + cartId)
        localStorage.removeItem(storageData.isGiftCardWasApplied + cartId)
    }
    /**
     * Get complete search GA data
     * @return {CompleteSearchAnalytics} - data
     */
    const _getCompleteSearchGAData = (): CompleteSearchAnalytics => {
        return JSON.parse(
            sessionStorageService.getItem(searchConstants.COMPLETE_SEARCH_EVENT_DATA),
        ) as CompleteSearchAnalytics
    }

    /**
     * Set complete search GA data
     * @param {CompleteSearchAnalytics} data - data to set
     */
    const _setCompleteSearchGAData = (data: CompleteSearchAnalytics): void => {
        sessionStorageService.setItem(searchConstants.COMPLETE_SEARCH_EVENT_DATA, JSON.stringify(data))
    }

    /**
     * Delete complete search GA data
     */
    const _deleteCompleteSearchGAData = (): void => {
        sessionStorageService.removeItem(searchConstants.COMPLETE_SEARCH_EVENT_DATA)
    }

    /**
     * get all suggest cached data
     * @return {Suggest | null} - all suggest cached data
     */
    const _getAllSuggestionCacheData = (): { [key: string]: Suggest } | null => {
        const cacheData = sessionStorageService.getItem(searchConstants.SUGGESTION_CACHE_DATA)

        if (cacheData) {
            return JSON.parse(cacheData) as { [key: string]: Suggest }
        }

        return null
    }

    /**
     * get suggestion data for specified key
     * @param {string} cacheKey
     * @return {Suggest | undefined} - cached data
     */
    const _getSuggestionCacheDataByKey = (cacheKey: string): Suggest | null => {
        const cacheData = _getAllSuggestionCacheData()
        if (cacheData) {
            return cacheData[cacheKey] || null
        } else {
            return null
        }
    }

    /**
     * set suggestion cache data
     * @param {string} cacheKey
     * @param {Suggest} data
     */
    const _setSuggestionCacheData = (cacheKey: string, data: Suggest): void => {
        const cacheData = _getAllSuggestionCacheData()
        if (cacheData) {
            cacheData[cacheKey] = data
            sessionStorageService.setItem(searchConstants.SUGGESTION_CACHE_DATA, JSON.stringify(cacheData))
        } else {
            sessionStorageService.setItem(searchConstants.SUGGESTION_CACHE_DATA, JSON.stringify({ [cacheKey]: data }))
        }
    }

    /**
     * delete all cached data
     * @return {void}
     */
    const _deleteAllSuggestionCacheData = (): void =>
        sessionStorageService.removeItem(searchConstants.SUGGESTION_CACHE_DATA)

    /**
     * delete cached data by key
     * @param {string} cacheKey
     * @return {void}
     */
    const _deleteSuggestionCacheDataByKey = (cacheKey: string): void => {
        const cacheData = _getAllSuggestionCacheData()
        if (cacheData) {
            delete cacheData[cacheKey]
            sessionStorageService.setItem(searchConstants.SUGGESTION_CACHE_DATA, JSON.stringify(cacheData))
        }
    }

    /**
     * Get tire size data from local storage.
     * @return {string}
     */
    const _getTiresData = (): string | null => {
        return localStorage.getItem(storageData.tiresSizeData)
    }

    /**
     * Set value as true when user will add new vehicle in package flow.
     * @param {string} value
     */
    const _setAddedNewVehicleInPackage = (value: string): void => {
        localStorage.setItem(storageData.addedNewVehicleInPackage, value)
    }

    /**
     * Get value if new vehicle added in package flow from local storage.
     * @return {string}
     */
    const _getAddedNewVehicleInPackage = (): string | null => {
        return localStorage.getItem(storageData.addedNewVehicleInPackage)
    }

    /**
     * Remove value from local storage.
     * @return {void}
     */
    const _deleteAddedNewVehicleInPackage = (): void => {
        localStorage.removeItem(storageData.addedNewVehicleInPackage)
    }

    const _getRegionsData = (countryName: string, locale: string): string => {
        return localStorage.getItem(`${regionsData}${countryName}-${locale}`) || ''
    }

    const _setWishlistGuid = (value: string): void => localStorage.setItem(storageData.wishlistGuid, value)

    const _getWishlistCache = (): string => {
        return localStorage.getItem(storageData.wishlistCache) || ''
    }

    const _setWishlistCache = (value: string): void => {
        localStorage.setItem(storageData.wishlistCache, value)
    }

    const _deleteWishlistCache = (): void => localStorage.removeItem(storageData.wishlistCache)

    const _deleteWishlistGuid = (key: string): void => localStorage.removeItem(key)

    const _removeCartGuid = (): void => localStorage.removeItem(storageData.cartGuid)

    const _removeCartData = (): void => {
        localStorage.removeItem(storageData.cartData)
    }

    const _getMedalliaStoreData = () => localStorage.getItem(storageData.medalliaStoreData)

    const _setMedalliaStoreData = (value: string) => localStorage.setItem(storageData.medalliaStoreData, value)

    const _getTMXSessionId = (key: string): string => localStorage.getItem(key) || '{}'

    const _getSIGNIFYDSessionId = (key: string): string => localStorage.getItem(key) || '{}'

    const _setCTFSMember = (value: string) => localStorage.setItem(storageData.ctfsMember, value)

    const _getCTFSMember = (): string => localStorage.getItem(storageData.ctfsMember) || ''

    const _removeTmxSession = (): void => localStorage.removeItem(environment.TMX_SESSION_VALUE)
    const _removeSignifydSession = (): void => localStorage.removeItem(environment.SIGNIFYD_SESSION_VALUE)
    const _setSemafoneEnabled = (value: string): void => sessionStorage.setItem(storageData.semafoneMode, value)
    const _getSemafoneEnabled = () => sessionStorage.getItem(storageData.semafoneMode)
    const _removeSemafoneEnabled = (): void => sessionStorage.removeItem(storageData.semafoneMode)

    const _setSaveForLater = (value: string): void => sessionStorage.setItem(storageData.moveToSFLItem, value)
    const _getSaveForLater = () => sessionStorage.getItem(storageData.moveToSFLItem)
    const _removeSaveForLater = (): void => sessionStorage.removeItem(storageData.moveToSFLItem)

    const _setTotalTraingleTransactions = (value: string) =>
        localStorage.setItem(storageData.totalTraingleTransaction, value)
    const _getTotalTraingleTransactions = () => localStorage.getItem(storageData.totalTraingleTransaction)

    const _setCartDataForAnonymous = (value: string): void =>
        localStorage.setItem(storageData.cartDataForAnonymous, value)

    const _getCartDataForAnonymous = (): string => localStorage.getItem(storageData.cartDataForAnonymous) || ''

    const _removeCartDataForAnonymous = (): void => localStorage.removeItem(storageData.cartDataForAnonymous)

    const _setCartMergeFlag = (value: string): void => localStorage.setItem(storageData.cartMergeFlag, value)

    const _getCartMergeFlag = (): string => localStorage.getItem(storageData.cartMergeFlag) || ''

    const _removeCartMergeFlag = (): void => localStorage.removeItem(storageData.cartMergeFlag)

    /**
     * set cart last visited date in local storage
     * @param {string} date
     * @return {void}
     */
    const _setCartLastVisitedDate = (date: string): void => localStorage.setItem(storageData.cartLastVisitedDate, date)

    /**
     * get cart last visited date
     * @return {string}
     */
    const _getCartLastVisitedDate = (): string => localStorage.getItem(storageData.cartLastVisitedDate) || ''

    return {
        getCartGuid: (): string => {
            return _getCartGuid()
        },
        getSflGuid: (): string => {
            return _getSflGuid()
        },
        getVehicles: (): Vehicle[] | null => {
            return _getVehicles()
        },
        setCartGuid: _setCartGuid,
        setSflGuid: _setSflGuid,
        removeSflGuid: _removeSflGuid,
        gigyaJWTToken: {
            get: _getGigyaJWTToken,
            set: _setGigyaJWTToken,
            delete: _deleteGigyaJWTToken,
        },
        userDetails: {
            delete: _deleteAllUserDetails,
        },
        storeTimeZone: {
            get: _getStoreTimeZone,
            set: _setStoreTimeZone,
        },
        emailStickyBannerTimer: {
            get: _getBannerTimer,
            set: _setBannerTimer,
        },
        cartData: {
            get: _getCartData,
            set: _setCartData,
            delete: _removeCartData,
        },
        cartStoreChanged: {
            get: _getCartStoreChanged,
            set: _setCartStoreChanged,
            delete: _removeCartStoreChanged,
        },
        isDeliveryModeConflict: {
            get: _isDeliveryModeConflict,
            set: _setDeliveryModeConflict,
            delete: _removeDeliveryModeConflict,
        },
        preferredStoreId: {
            get: _getPreferredStoreId,
            set: _setPreferredStoreId,
        },
        isGiftCardWasApplied: {
            get: _getIsGiftCardWasApplied,
            set: _setIsGiftCardWasApplied,
        },
        isPaypalTendersApplied: {
            get: _getIsPaypalTendersApplied,
            set: _setIsPaypalTendersApplied,
        },
        vehicles: {
            get: _getVehicles,
        },
        postalCode: {
            get: _getPostalCode,
            set: _setPostalCode,
            delete: _deletePostalCode,
        },
        deliveryPostalCode: {
            get: _getDeliveryPostalCode,
            set: _setDeliveryPostalCode,
            delete: _deleteDeliveryPostalCode,
        },
        getCountriesData: (locale: string): string => {
            return _getCountriesData(locale)
        },
        searchedList: {
            get: _getRecentSearchList,
            set: _setRecentSearchList,
        },
        getRegionsData: (countryName: string, locale: string): string => {
            return _getRegionsData(countryName, locale)
        },
        tiresSize: {
            set: _setTiresData,
            get: _getTiresData,
        },
        newVehicleInPackage: {
            set: _setAddedNewVehicleInPackage,
            get: _getAddedNewVehicleInPackage,
            delete: _deleteAddedNewVehicleInPackage,
        },
        wishlistGuid: {
            set: _setWishlistGuid,
            get: _getWishlistGuid,
            delete: _deleteWishlistGuid,
        },
        wishlistCache: {
            set: _setWishlistCache,
            get: _getWishlistCache,
            delete: _deleteWishlistCache,
        },
        removeCartGuid: _removeCartGuid,
        medalliaStoreData: {
            get: _getMedalliaStoreData,
            set: _setMedalliaStoreData,
        },
        _getTMXSessionId: _getTMXSessionId,
        _getSIGNIFYDSessionId: _getSIGNIFYDSessionId,
        ctfsMember: {
            set: _setCTFSMember,
            get: _getCTFSMember,
        },
        removeSignifydSession: (): void => {
            return _removeSignifydSession()
        },
        removeTmxSession: (): void => {
            return _removeTmxSession()
        },
        removeTendersStorageData: (cartId: string): void => {
            return _removeTendersStorageData(cartId)
        },
        semafoneMode: {
            set: _setSemafoneEnabled,
            get: _getSemafoneEnabled,
            remove: _removeSemafoneEnabled,
        },
        saveForLater: {
            set: _setSaveForLater,
            get: _getSaveForLater,
            remove: _removeSaveForLater,
        },

        totalTraingleTransaction: {
            set: _setTotalTraingleTransactions,
            get: _getTotalTraingleTransactions,
        },
        completeSearchEventGA: {
            set: _setCompleteSearchGAData,
            get: _getCompleteSearchGAData,
            delete: _deleteCompleteSearchGAData,
        },
        suggestionCacheService: {
            set: _setSuggestionCacheData,
            get: _getSuggestionCacheDataByKey,
            deleteByKey: _deleteSuggestionCacheDataByKey,
            deleteAll: _deleteAllSuggestionCacheData,
        },
        setOptimizelyShoppingCart: _setOptimizelyShoppingCart,
        cartDataForAnonymous: {
            set: _setCartDataForAnonymous,
            get: _getCartDataForAnonymous,
            remove: _removeCartDataForAnonymous,
        },
        cartMergeFlag: {
            set: _setCartMergeFlag,
            get: _getCartMergeFlag,
            remove: _removeCartMergeFlag,
        },
        cartLastVisitedDate: {
            set: _setCartLastVisitedDate,
            get: _getCartLastVisitedDate,
        },
    }
})(localStorageService, sessionStorageService)

export default appCacheService
