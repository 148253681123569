import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { getUserType } from '../../../utils/getUserType'
import checkNestedProps from '../../../utils/checkNestedProps'
import {
    SnippetCard,
    replaceStrWithDynamicVal,
    magicNumber,
    removeHtmlTagsFromString,
    checkStateProvince,
    LineSummary,
    checkDataLength,
    getDivideByAmount,
    getMultipleByAmount,
    getCTA,
    getTooltipText,
    ExpandCard,
    Icon,
    isTriangleSelectMember,
    getBalance,
} from '@nl/lib'
import { TriangleRewardsCardType, CardPropsType, LineSummaryType } from './ProductTriangleRewards.type'
import { userState, priceCategory, PREFIX } from '../../../config'
import { userProfileDataSelector } from '../../../redux/selectors/userProfile.selectors'
import { preferredStoreDetailsSelector } from '../../../redux/selectors/storeDetails.selectors'
import {
    browseOnlyModeSelector,
    commonAPIContentAvailableSelector,
    commonContentAvailableSelector,
} from '../../../redux/selectors/commonContent.selectors'
import {
    StoresProvinceList,
    isTriangleMastercardLinked,
    separatorProps,
    triangleSelectSeparatorProps,
    expandCardSeparatorProps,
} from '../../../globalConstants/global.constant'
import { getRewardsCardProps } from '../../../helpers/getUpsellingProps.helper'
import { calculateProductSelectDiscount } from '../../../utils/getTriangleSelectBenefits'

/**
 * Renders Triangle Rewards component to be used in Buybox
 * @return {JSX.Element[]} returns Triangle Rewards component
 */

const ProductTriangleRewards: React.FC<TriangleRewardsCardType> = ({ ...props }): JSX.Element => {
    const {
        cardTitleLoggedInCCUser,
        cardImageLoggedInCCUser,
        cardImageAltTextLoggedInCCUser,
        cardTitleRewardsUser,
        cardImageRewardsUser,
        cardImageAltTextRewardsUser,
        ctaLabelRewardsUser,
        ctaLinkRewardsUser,
        ctaA11yLabelRewardsUser,
        ctaLinkTargetRewardsUser,
        ctmoneybackmessage,
        numberOfMonths,
        monthlyPriceLabel,
        productPrice,
        ctMoneyTooltipHeading,
        ctMoneyTooltipDescription,
        creditOfferedMessageQuebec,
        creditOfferedTooltipDescQuebec,
        creditOfferedTooltipHeadingQuebec,
        paymentInstallmentTooltipHeading,
        paymentInstallmentTooltipDescription,
        upsellingPercentage,
        perMonthLabel,
        productData,
        selectMembershipLabel,
        selectMembershipImg,
        selectMembershipImgAltText,
        selectMembershipCTALabel,
        selectMembershipCTAUrl,
        selectMembershipTarget,
        selectMembershipCtaA11yLabel,
        selectMembershipText,
        selectMembershipTooltipHeading,
        selectMembershipTooltipDesc,
        selectEverydayInstoreBonusText,
        selectEverydayInstoreBonusTooltipHeading,
        selectEverydayInstoreBonusTooltipDesc,
        selectInstoreBrandBoostText,
        selectInstoreBrandBoostTooltipHeading,
        selectInstoreBrandBoostTooltipDesc,
        selectAdditionalBrandBoostText,
        selectAdditionalBrandBoostTooltipHeading,
        selectAdditionalBrandBoostTooltipDesc,
        selectCtMoneyAndBrandBoostTextQuebec,
        selectCtMoneyAndBrandBoostTooltipHeadingQuebec,
        selectCtMoneyAndBrandBoostTooltipDescQuebec,
        displaySelectMembershipUpsell,
        selectCTMoneyBackText,
        selectCTMoneyBackTooltipHeading,
        selectCTMoneyBackTooltipDesc,
    } = props

    const componentName = `${PREFIX}-product-triangle-rewards`
    const ctMoneyIcon = 'ct-triangleMoney'
    const selectTooltipIcon = 'ct-information-details'

    const userProfileData = useSelector(userProfileDataSelector)
    const preferredStoreDetails = useSelector(preferredStoreDetailsSelector)
    const isoCode = preferredStoreDetails?.address?.region?.isocode
    const { canadapost } = useSelector(commonAPIContentAvailableSelector)
    const commonContentAvailable = useSelector(commonContentAvailableSelector)
    const isBrowseOnlyMode = useSelector(browseOnlyModeSelector)
    const threshold1 = checkNestedProps(commonContentAvailable, 'product', 'threshold1') as number
    const threshold2 = checkNestedProps(commonContentAvailable, 'product', 'threshold2') as number
    const threshold3 = checkNestedProps(commonContentAvailable, 'product', 'threshold3') as number

    const authenticatedUser = userProfileData ? Boolean(Object.keys(userProfileData).length) : false
    const isLoyaltyUser = Boolean(userProfileData?.loyalty?.cardNumber)
    const rewardsCardType = userProfileData?.triangleType?.rewardsCardType as string
    const rewardsPercentage = userProfileData?.triangleType?.rewardsPercentage as number
    const linkedFlag = userProfileData?.linkedFlag === isTriangleMastercardLinked
    const userType = getUserType(authenticatedUser, isLoyaltyUser, rewardsCardType, linkedFlag)
    const isQuebecRegion = checkStateProvince(isoCode, canadapost.Country, StoresProvinceList.qc)

    const percentageValue = (
        userType === userState.loyaltyCard ? upsellingPercentage : rewardsPercentage * magicNumber.HUNDRED
    ) as number
    const isSelectMember = isTriangleSelectMember(userProfileData?.loyalty?.tsSubscriptionStatus)
    const isCreditCardUserAndSelectMember = userType === userState.triangleCreditCard && isSelectMember

    const ctMoneyCashBackLabel = `${replaceStrWithDynamicVal(ctmoneybackmessage, `${percentageValue}`)}`

    const monthlyEMILabel = `${replaceStrWithDynamicVal(monthlyPriceLabel, numberOfMonths)}`
    const monthlyEMIValue = `${replaceStrWithDynamicVal(
        perMonthLabel,
        getDivideByAmount(productPrice, numberOfMonths),
    )}`

    const [accessibility, setAccessibility] = useState(
        commonContentAvailable?.accessibility || ({} as typeof commonContentAvailable.accessibility),
    )
    useEffect(() => {
        commonContentAvailable?.accessibility && setAccessibility(commonContentAvailable.accessibility)
    }, [commonContentAvailable])

    const payload = {
        price: { value: productPrice },
        tSelectBenefits: productData?.triangleSelectBenefits,
    }
    const productDiscountValue = calculateProductSelectDiscount(
        payload,
        Boolean(productPrice >= threshold3 && isQuebecRegion),
    )

    const cashBackLineSummaryWithoutValue = {
        title: ctMoneyCashBackLabel,
        tooltip: getTooltipText(ctMoneyTooltipHeading, ctMoneyTooltipDescription, accessibility),
        showTooltipWithTitle: true,
    }

    const cashBackLineSummaryWithValue = {
        title: ctMoneyCashBackLabel,
        tooltip: getTooltipText(ctMoneyTooltipHeading, ctMoneyTooltipDescription, accessibility),
        value: getMultipleByAmount(percentageValue, productPrice),
        showTooltipWithTitle: true,
        iconType: ctMoneyIcon,
    }

    const selectLineSummaryWithValue = {
        title: selectCTMoneyBackText,
        tooltip: getTooltipText(selectCTMoneyBackTooltipHeading, selectCTMoneyBackTooltipDesc, accessibility),
        value: getMultipleByAmount(percentageValue, productPrice),
        showTooltipWithTitle: true,
        iconType: ctMoneyIcon,
    }

    const emiLineSummaryOtherLoc = {
        title: monthlyEMILabel,
        tooltip: getTooltipText(paymentInstallmentTooltipHeading, paymentInstallmentTooltipDescription, accessibility),
        value: monthlyEMIValue,
        showTooltipWithTitle: true,
        isHeading: true,
        iconType: ctMoneyIcon,
    }

    const emiLineSummaryQuebecLoc = {
        title: creditOfferedMessageQuebec,
        tooltip: getTooltipText(creditOfferedTooltipHeadingQuebec, creditOfferedTooltipDescQuebec, accessibility),
        showTooltipWithTitle: true,
        isHeading: true,
    }

    const selectSummaryWithoutValue = {
        title: selectMembershipText,
        tooltip: getTooltipText(
            selectMembershipTooltipHeading,
            selectMembershipTooltipDesc,
            accessibility,
            selectTooltipIcon,
        ),
        showTooltipWithTitle: true,
    }

    const showTooltipIcon = {
        showTooltipWithTitle: true,
        iconType: ctMoneyIcon,
    }

    const selectSummaryWithValue = {
        title: selectEverydayInstoreBonusText,
        tooltip: getTooltipText(
            selectEverydayInstoreBonusTooltipHeading,
            selectEverydayInstoreBonusTooltipDesc,
            accessibility,
            selectTooltipIcon,
        ),
        value: getBalance(
            productDiscountValue?.tsEverydayDiscount ? productDiscountValue?.tsEverydayDiscount : productDiscountValue,
        ),
        ...showTooltipIcon,
    }

    const selectInstoreSummaryWithValue = {
        title: selectInstoreBrandBoostText,
        tooltip: getTooltipText(
            selectInstoreBrandBoostTooltipHeading,
            selectInstoreBrandBoostTooltipDesc,
            accessibility,
            selectTooltipIcon,
        ),
        value: getBalance(productDiscountValue?.tsBrandDiscount),
        ...showTooltipIcon,
        isHeading: true,
    }

    const selectTheshold3InBrandWithValue = {
        title: selectAdditionalBrandBoostText,
        tooltip: getTooltipText(
            selectAdditionalBrandBoostTooltipHeading,
            selectAdditionalBrandBoostTooltipDesc,
            accessibility,
            selectTooltipIcon,
        ),
        value: getBalance(productDiscountValue?.tsBrandDiscount),
        ...showTooltipIcon,
        isHeading: true,
    }

    const selectEverdayQubecSummaryWithValue = {
        title: selectCtMoneyAndBrandBoostTextQuebec,
        tooltip: getTooltipText(
            selectCtMoneyAndBrandBoostTooltipHeadingQuebec,
            selectCtMoneyAndBrandBoostTooltipDescQuebec,
            accessibility,
            selectTooltipIcon,
        ),
        value: getBalance(productDiscountValue.toString()),
        ...showTooltipIcon,
    }

    const selectCashbackTooltipUpdate = {
        ...selectLineSummaryWithValue,
        tooltip: getTooltipText(
            selectCTMoneyBackTooltipHeading,
            selectCTMoneyBackTooltipDesc,
            accessibility,
            selectTooltipIcon,
        ),
    }

    const selectSummaryQubecLoc = {
        ...emiLineSummaryQuebecLoc,
        tooltip: getTooltipText(
            creditOfferedTooltipHeadingQuebec,
            creditOfferedTooltipDescQuebec,
            accessibility,
            selectTooltipIcon,
        ),
    }

    /**
     * To get the threshold category depending on the price
     * @param {number} price
     * @return {string}
     */
    const getPriceCategory = useCallback(
        (price: number): string => {
            let priceState: string
            switch (true) {
                case price < threshold1:
                    priceState = priceCategory.THRESHOLD1
                    break
                case price >= threshold1 && price <= threshold2:
                    priceState = priceCategory.THRESHOLD2
                    break
                case price >= threshold3:
                    priceState = priceCategory.THRESHOLD3
                    break
                default:
                    priceState = priceCategory.THRESHOLD1
                    break
            }
            return priceState
        },
        [threshold1, threshold2, threshold3],
    )

    /**
     * Appending lineSummary to cardProps based on price and location
     * @param {CardPropsType} cProps
     * @return {CardPropsType}
     */
    const getPropsBasedOnProductPriceAndLocation = useCallback(
        (cProps: CardPropsType): CardPropsType => {
            const pCategory = getPriceCategory(Math.floor(productPrice))
            if (pCategory === priceCategory.THRESHOLD1) {
                cProps = {
                    ...cProps,
                    lineSummary: [cashBackLineSummaryWithoutValue],
                }
            } else if (pCategory === priceCategory.THRESHOLD2) {
                cProps = {
                    ...cProps,
                    lineSummary: [cashBackLineSummaryWithValue],
                }
            } else if (pCategory === priceCategory.THRESHOLD3 && !isQuebecRegion) {
                cProps = { ...cProps, lineSummary: [cashBackLineSummaryWithValue, emiLineSummaryOtherLoc] }
            } else if (pCategory === priceCategory.THRESHOLD3 && isQuebecRegion) {
                cProps = { ...cProps, lineSummary: [cashBackLineSummaryWithValue, emiLineSummaryQuebecLoc] }
            }
            return cProps
        },
        [
            cashBackLineSummaryWithValue,
            cashBackLineSummaryWithoutValue,
            emiLineSummaryOtherLoc,
            emiLineSummaryQuebecLoc,
            getPriceCategory,
            isQuebecRegion,
            productPrice,
        ],
    )

    /**
     * based on brand boost will return the array types for lineSummary
     * @param {boolean} tsBrand
     * @param {CardPropsType} everyDaySelectValue
     * @param {CardPropsType} inStoreSelectValue
     * @return {CardPropsType[]}
     */
    const isBrandBoost = (
        tsBrand: boolean,
        everyDaySelectValue: CardPropsType,
        inStoreSelectValue: CardPropsType,
    ): CardPropsType[] => {
        return tsBrand ? [everyDaySelectValue, inStoreSelectValue] : [everyDaySelectValue]
    }

    /**
     * Appending lineSummary to cardProps based on price and location for select
     * @param {CardPropsType} cProps
     * @return {CardPropsType}
     */
    const getPropsBasedOnSelectProductView = useCallback(
        (cProps: CardPropsType): CardPropsType => {
            const pCategory = getPriceCategory(Math.floor(productPrice))
            const tsBrand = productData?.triangleSelectBenefits?.tsBrand
            if (pCategory === priceCategory.THRESHOLD1) {
                cProps = { ...cProps, lineSummary: [selectSummaryWithoutValue] }
            } else if (pCategory === priceCategory.THRESHOLD2) {
                cProps = {
                    ...cProps,
                    lineSummary: isBrandBoost(tsBrand, selectSummaryWithValue, selectInstoreSummaryWithValue),
                }
            } else if (pCategory === priceCategory.THRESHOLD3 && !isQuebecRegion) {
                cProps = {
                    ...cProps,
                    lineSummary: isBrandBoost(tsBrand, selectCashbackTooltipUpdate, selectTheshold3InBrandWithValue),
                }
            } else if (pCategory === priceCategory.THRESHOLD3 && isQuebecRegion) {
                cProps = {
                    ...cProps,
                    lineSummary: tsBrand
                        ? [selectEverdayQubecSummaryWithValue, selectSummaryQubecLoc]
                        : [selectCashbackTooltipUpdate, selectSummaryQubecLoc],
                }
            }
            return cProps
        },
        [
            getPriceCategory,
            isQuebecRegion,
            productPrice,
            productData,
            selectCashbackTooltipUpdate,
            selectTheshold3InBrandWithValue,
            selectEverdayQubecSummaryWithValue,
            selectSummaryQubecLoc,
            selectSummaryWithValue,
            selectInstoreSummaryWithValue,
            selectSummaryWithoutValue,
        ],
    )

    /**
     * renders summary value with triangle icon
     * @param {LineSummaryType} item
     * @return {JSX.Element}
     */
    const renderSummaryValue = (item: LineSummaryType): JSX.Element => {
        return (
            <>
                <Icon type={item?.iconType} size="md" />
                {item?.value}
            </>
        )
    }

    /**
     * render snippetCard component to display triangle rewards
     * @param {CardPropsType} cardProps
     * @param {string} iconType
     * @return {JSX.Element}
     */
    const renderSnippetCardComponent = useCallback(
        (cardProps: CardPropsType, iconType: string): JSX.Element => {
            return checkDataLength(cardProps) ? (
                <div className={componentName}>
                    <SnippetCard {...cardProps}>
                        {(cardProps.imageOffSet || isLoyaltyUser) && (
                            <div className={`${PREFIX}-snippet-card__lines`}>
                                {cardProps.lineSummary?.map(
                                    (item: LineSummaryType) =>
                                        !!item && (
                                            <LineSummary
                                                key={removeHtmlTagsFromString(item.title ?? '')}
                                                title={<div dangerouslySetInnerHTML={{ __html: item.title }} />}
                                                value={renderSummaryValue(item)}
                                                isHeading={item?.isHeading}
                                                tooltip={item.tooltip}
                                                showTooltipWithTitle={item?.showTooltipWithTitle}
                                            />
                                        ),
                                )}
                            </div>
                        )}
                        {cardProps.cta && checkDataLength(cardProps.cta) && (
                            <div className={`${PREFIX}-xs-flex`}>
                                <a
                                    target={cardProps.cta?.target}
                                    rel="noopener noreferrer"
                                    href={cardProps.cta?.link}
                                    aria-label={cardProps.cta?.a11yLabel}>
                                    {cardProps.cta?.label}
                                </a>
                                <Icon size="md" type={cardProps?.customClass ? 'ct-external-white' : iconType} />
                            </div>
                        )}
                    </SnippetCard>
                </div>
            ) : (
                <></>
            )
        },
        [componentName, isLoyaltyUser],
    )

    /**
     * function create props for expanded select view
     * @return {CardPropsType}
     */
    const expandSelectProps = useMemo((): CardPropsType => {
        let cardProps: CardPropsType = {}

        if (userState.loyaltyCard === userType || isCreditCardUserAndSelectMember) {
            cardProps = {
                title: selectMembershipLabel,
                imageOffSet: false,
                cta: getCTA(
                    selectMembershipCTALabel,
                    selectMembershipCTAUrl,
                    selectMembershipTarget,
                    selectMembershipCtaA11yLabel,
                ),
                image: selectMembershipImg,
                imageAlt: selectMembershipImgAltText,
            }
            cardProps = getPropsBasedOnSelectProductView(cardProps)
        } else if (userState.triangleCreditCard === userType) {
            cardProps = {
                title: cardTitleLoggedInCCUser,
                imageOffSet: false,
                image: cardImageLoggedInCCUser,
                imageAlt: cardImageAltTextLoggedInCCUser,
                separatorProps: separatorProps,
            }
            cardProps = getPropsBasedOnSelectProductView(cardProps)
        }
        return cardProps
    }, [
        cardImageAltTextLoggedInCCUser,
        cardImageLoggedInCCUser,
        cardTitleLoggedInCCUser,
        getPropsBasedOnSelectProductView,
        selectMembershipCTALabel,
        selectMembershipCTAUrl,
        selectMembershipTarget,
        selectMembershipCtaA11yLabel,
        selectMembershipImg,
        selectMembershipImgAltText,
        selectMembershipLabel,
        userType,
        isCreditCardUserAndSelectMember,
    ])

    /**
     * render expandCard view for select
     * @return {JSX.Element}
     */
    const renderExpandedContent = useCallback(
        (): JSX.Element => (
            <ExpandCard separatorProps={expandCardSeparatorProps} customClass={`${PREFIX}-select-upsell__footer`}>
                {renderSnippetCardComponent(expandSelectProps, 'ct-external-white')}
            </ExpandCard>
        ),
        [expandSelectProps, renderSnippetCardComponent],
    )

    /**
     * Determining cardProps based on user type
     * @return {CardPropsType}
     */
    const processCardProps = useMemo(() => {
        let cardProps = getRewardsCardProps(userType, props)

        if (isCreditCardUserAndSelectMember) {
            cardProps = {
                title: cardTitleLoggedInCCUser,
                imageOffSet: false,
                image: cardImageLoggedInCCUser,
                imageAlt: cardImageAltTextLoggedInCCUser,
            }
            if (displaySelectMembershipUpsell) {
                cardProps.footerContent = renderExpandedContent()
            } else {
                cardProps.separatorProps = separatorProps
            }
            cardProps = getPropsBasedOnProductPriceAndLocation(cardProps)
        } else if (userState.loyaltyCard === userType) {
            cardProps = {
                title: cardTitleRewardsUser,
                imageOffSet: false,
                cta: getCTA(ctaLabelRewardsUser, ctaLinkRewardsUser, ctaLinkTargetRewardsUser, ctaA11yLabelRewardsUser),
                image: cardImageRewardsUser,
                imageAlt: cardImageAltTextRewardsUser,
            }
            if (!isSelectMember && displaySelectMembershipUpsell) {
                cardProps.footerContent = renderExpandedContent()
            } else {
                cardProps.separatorProps = separatorProps
            }
            cardProps = getPropsBasedOnProductPriceAndLocation(cardProps)
        } else if (userState.triangleCreditCard === userType) {
            if (displaySelectMembershipUpsell) {
                cardProps = {
                    title: selectMembershipLabel,
                    imageOffSet: false,
                    image: selectMembershipImg,
                    imageAlt: selectMembershipImgAltText,
                    customClass: 'reverse theme-select',
                    cta: getCTA(
                        selectMembershipCTALabel,
                        selectMembershipCTAUrl,
                        selectMembershipTarget,
                        selectMembershipCtaA11yLabel,
                    ),
                    separatorProps: triangleSelectSeparatorProps,
                }
                cardProps = getPropsBasedOnSelectProductView(cardProps)
            } else {
                cardProps = {}
            }
        }

        return cardProps
    }, [
        cardImageAltTextRewardsUser,
        cardImageRewardsUser,
        cardTitleRewardsUser,
        ctaA11yLabelRewardsUser,
        ctaLabelRewardsUser,
        ctaLinkRewardsUser,
        ctaLinkTargetRewardsUser,
        displaySelectMembershipUpsell,
        getPropsBasedOnProductPriceAndLocation,
        userType,
        props,
        getPropsBasedOnSelectProductView,
        renderExpandedContent,
        selectMembershipCTALabel,
        selectMembershipCTAUrl,
        selectMembershipTarget,
        selectMembershipCtaA11yLabel,
        selectMembershipImg,
        selectMembershipImgAltText,
        selectMembershipLabel,
        isCreditCardUserAndSelectMember,
        isSelectMember,
        cardTitleLoggedInCCUser,
        cardImageAltTextLoggedInCCUser,
        cardImageLoggedInCCUser,
    ])

    return !isBrowseOnlyMode ? renderSnippetCardComponent(processCardProps, 'ct-external-black') : <></>
}

export default ProductTriangleRewards
