import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { PREFIX, REF_URL_KEY, pageTypes } from '../../../config'
import { FacetTypes, PillBarTypes } from './HorizontalPillBar.types'
import { FacetRange, FacetValue, Facet, CustomPriceFacet } from '../../../redux/models/productData.interface'
import { checkFacetValueType } from '../../../utils/checkFacetValueType'
import { Pill, getPriceRangePill, libUtils, pushUrlHistory } from '@nl/lib'
import { filterCleared } from '../Facets.constant'
import {
    clearFilterAnalytics,
    filterActionAdvancedAnalytics,
    filterActionAnalytics,
    updateFilterAnalyticsObject,
} from '../../../analytics/components/facetAnalytics'
import checkNestedProps from '../../../utils/checkNestedProps'
import { MagicNumber } from '../../../analytics/analytics.type'
import { productCardDataSelector } from '../../../redux/selectors/productData.selectors'
import sessionStorageService from '../../../utils/sessionStorageService'
import { FilterAnalyticsData } from '../../../analytics/providers/provider.type'
import getPageType from '../../../utils/getPageType'

const HorizontalPillBar: React.FC<PillBarTypes> = (props): JSX.Element => {
    const productCardData = useSelector(productCardDataSelector)
    const { facets = [], clearUrl, resultCount } = productCardData
    const breadcrumbFacets = facets?.find((facet: Facet) => facet.type === FacetTypes.BREADCRUMB)
    const parentCategory = { ...breadcrumbFacets?.current, ...{ filterType: breadcrumbFacets?.label } }
    const { clearAllFilterLabel, toggleDispatch, a11yRemoveFilter, a11yAppliedFiltersLabel, a11yClearFiltersLabel } =
        props
    const [facetsMap, setFacetMap] = useState<{ [key: string]: FacetValue | CustomPriceFacet }>({})
    const [initialLoad, setInitialLoad] = useState(true)
    const pageType = getPageType()
    const currentLocale = libUtils.getLanguage()

    const queryParams = window.location.search
    /**
     * function to get price fills
     * @param {Facet | CustomPriceFacet} value
     * @return {string} price fills
     */
    const getPricePill = useCallback(
        (value: Facet | CustomPriceFacet): string => {
            const priceRange = value as CustomPriceFacet
            const minPrice = priceRange.min
            const maxPrice = priceRange.max
            return getPriceRangePill(minPrice, maxPrice, currentLocale)
        },
        [currentLocale],
    )

    /**
     * function to get price range pills
     * @param {(Facet | FacetRange)[]} selectedFacets
     * @return {object} selected price fill
     */

    const getSelectedPriceRangePill = useCallback(
        (
            selectedFacets: (Facet | CustomPriceFacet)[],
        ): {
            [key: string]: FacetValue
        } => {
            return selectedFacets.reduce((defaultPillValue, currentValue: Facet | CustomPriceFacet) => {
                if (currentValue && currentValue.selected && currentValue.type === FacetTypes.CUSTOM_PRICE) {
                    const priceFill = getPricePill(currentValue)
                    if (priceFill) {
                        return { ...defaultPillValue, [priceFill]: currentValue }
                    }
                }
                return defaultPillValue
            }, {})
        },
        [getPricePill],
    )

    // useEffect to set initialLoad value
    useEffect(() => {
        queryParams && setInitialLoad(false)
    }, [queryParams])

    useEffect(() => {
        let filterAnalyticsData: FilterAnalyticsData = {}
        if (facets.length) {
            const selectedFacets = facets.filter((val: Facet | FacetRange) => val.selected)
            const facetsSelectedListWithFilterType: (FacetValue | FacetRange)[][] = []
            const facetsSelectedList = selectedFacets.map((value: Facet) => value.values)
            const showCatFltr = [pageTypes.searchPage, pageTypes.brandListingPage].includes(pageType)
            facetsSelectedList.forEach((facetValue: FacetValue[], index) => {
                const selectedFacetValues: (FacetValue | FacetRange)[] = []
                facetValue &&
                    facetValue.forEach((val: FacetValue) => {
                        selectedFacetValues.push({
                            ...val,
                            ...{
                                filterType: selectedFacets[index].label,
                                facetId: selectedFacets[index].id,
                                type: selectedFacets[index].type,
                            },
                        })
                    })
                facetsSelectedListWithFilterType.push(selectedFacetValues)
            })
            const filteredSelectedArray = Array.prototype.concat.apply(
                [],
                facetsSelectedListWithFilterType,
            ) as FacetValue[]

            // case:  to update filter analytics data for category
            selectedFacets.forEach((val: Facet) => {
                if (val.current && (selectedFacets.length > 1 || showCatFltr)) {
                    filterAnalyticsData = updateFilterAnalyticsObject(
                        val?.label,
                        val.current?.label,
                        val?.type || '',
                        val?.id || '',
                        resultCount,
                        filterAnalyticsData,
                    )
                }
            })

            const filterPillList = filteredSelectedArray.reduce((defaultPillValue, currentValue: FacetValue) => {
                if (currentValue.selected && currentValue?.filterType && currentValue?.label) {
                    filterAnalyticsData = updateFilterAnalyticsObject(
                        currentValue?.filterType,
                        currentValue?.label,
                        currentValue?.type || '',
                        currentValue?.facetId || '',
                        currentValue?.count || MagicNumber.ZERO,
                        filterAnalyticsData,
                    )
                }
                if (currentValue && checkFacetValueType(currentValue) && currentValue.selected) {
                    return { ...defaultPillValue, [currentValue.label]: currentValue }
                }
                return defaultPillValue
            }, {})

            const pricePillList = getSelectedPriceRangePill(selectedFacets)

            if (Object.keys(pricePillList).length) {
                const customPrice = Object.values(pricePillList)[MagicNumber.ZERO]
                filterAnalyticsData = updateFilterAnalyticsObject(
                    'Price',
                    Object.keys(pricePillList)[MagicNumber.ZERO],
                    customPrice?.type || '',
                    'custom-price-range',
                    resultCount,
                    filterAnalyticsData,
                )
            }

            setFacetMap(Object.assign(filterPillList, pricePillList))

            if (Object.values(filterAnalyticsData).length) {
                filterActionAdvancedAnalytics([...Object.values(filterAnalyticsData)], resultCount)
            }
        }
    }, [facets, getSelectedPriceRangePill, pageType, resultCount])

    /**
     * function to get facet label based on selected pill to remove
     * @param {FacetValue} facetMapDataObj
     * @return {string}
     */
    const getFacetLabel = (facetMapDataObj: FacetValue): string => {
        const selectedFacets = facets.filter(
            (val: Facet | FacetRange) => val.selected && val.values?.includes(facetMapDataObj),
        )
        return (checkNestedProps(selectedFacets, '0', 'label') as string) || facetMapDataObj.label
    }

    /**
     * function to remove pill
     * @param {string} data
     */
    const handleCloseClick = (data: string): void => {
        const facetMapDataObj = facetsMap[data]
        if (!!facetMapDataObj && checkFacetValueType(facetMapDataObj)) {
            const urlToClear =
                (facetMapDataObj as FacetValue).clearUrl || (facetMapDataObj as CustomPriceFacet).clearLink
            sessionStorageService.setItem(REF_URL_KEY, window.location.href)
            pushUrlHistory(urlToClear)
            toggleDispatch()
            filterActionAnalytics(getFacetLabel(facetMapDataObj), data, filterCleared)
        } else if (parentCategory?.label) {
            sessionStorageService.setItem(REF_URL_KEY, window.location.href)
            pushUrlHistory(parentCategory?.clearUrl)
            toggleDispatch()
            filterActionAnalytics(breadcrumbFacets?.label as string, data, filterCleared)
        }
    }
    const renderPillItem = (val: string, filterType: string): JSX.Element => {
        return (
            <li key={val}>
                <Pill
                    pillLabel={val}
                    onCloseClick={handleCloseClick}
                    a11yRemoveFilter={a11yRemoveFilter}
                    filterType={filterType}
                />
            </li>
        )
    }

    const renderFacetValue = (): JSX.Element => {
        return (
            <>
                {parentCategory?.label && !initialLoad
                    ? renderPillItem(parentCategory.label, parentCategory.filterType)
                    : ''}
                {Object.entries(facetsMap).map(value => {
                    return renderPillItem(
                        value[MagicNumber.ZERO],
                        value[MagicNumber.ONE].filterType || value[MagicNumber.ONE].label,
                    )
                })}
            </>
        )
    }

    const renderClearAllSection = (): JSX.Element | null => {
        return !!clearUrl ? (
            <li className={`${PREFIX}-horizontal-pill-bar-container__clear-list-item`}>
                <button
                    className={`${PREFIX}-button ${PREFIX}-button--tertiary ${PREFIX}-horizontal-pill-bar-container__clear`}
                    aria-label={a11yClearFiltersLabel}
                    onClick={clearAllHandler}>
                    {clearAllFilterLabel}
                </button>
            </li>
        ) : null
    }

    // to invoke api with clearURL
    const clearAllHandler = (): void => {
        sessionStorageService.setItem(REF_URL_KEY, window.location.href)
        pushUrlHistory(clearUrl)
        toggleDispatch()
        clearFilterAnalytics()
    }
    return (
        <>
            {!!Object.keys(facetsMap).length || parentCategory?.label ? (
                <>
                    <div className={`${PREFIX}-sm-none`}>
                        <div className={`${PREFIX}-horizontal-pill-bar-container ${PREFIX}-full-width-container`}>
                            <ul
                                aria-label={a11yAppliedFiltersLabel}
                                className={`${PREFIX}-horizontal-pill-bar-container__pill-list`}>
                                {renderFacetValue()}
                                {renderClearAllSection()}
                            </ul>
                        </div>
                    </div>
                    <div className={`${PREFIX}-xs-none ${PREFIX}-sm-block ${PREFIX}-md-block`}>
                        <div className={`${PREFIX}-horizontal-pill-bar-container`}>
                            <ul
                                aria-label={a11yAppliedFiltersLabel}
                                className={`${PREFIX}-horizontal-pill-bar-container__pill-list`}>
                                {renderFacetValue()}
                                {renderClearAllSection()}
                            </ul>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    )
}

HorizontalPillBar.propTypes = {
    clearAllFilterLabel: PropTypes.string.isRequired,
    toggleDispatch: PropTypes.func.isRequired,
    a11yRemoveFilter: PropTypes.string.isRequired,
    a11yClearFiltersLabel: PropTypes.string,
    a11yAppliedFiltersLabel: PropTypes.string,
}

export default HorizontalPillBar
