import { RootState } from '../reducers'
import { AOASelectedService } from '../../components/AOABookingForm/AOAServiceView/AOAServiceView.type'
import {
    AOAServiceInfoState,
    AOADateTimeInfoState,
    AOABookingFormState,
    AOAServiceResponseState,
    AOAEtireInfoState,
    AOAEtireOrderStoreInfo,
} from '../models/aoaBookingForm.interface'
import { StepsQueue } from '../../components/AOABookingForm/AOABookingForm.constants'
import { InitialCategoryPayloadType } from '../models/secondaryNavigation.interface'

export const getIsOpenedStoreLocatorModal = (state: RootState): boolean =>
    state.aoaBookingFormData.isOpenedStoreLocatorModal
export const getIsContactInfoEditMode = (state: RootState): boolean => state.aoaBookingFormData.isContactInfoEditMode

export const getAOABookingFormData = (state: RootState): AOABookingFormState => state.aoaBookingFormData

export const getActiveStep = (state: RootState): StepsQueue =>
    getAOABookingFormData(state).currentBookingFormStep || StepsQueue.Info

export const getServiceInfo = (state: RootState): AOAServiceInfoState | null =>
    getAOABookingFormData(state)?.serviceInfo

export const getDateTimeInfo = (state: RootState): AOADateTimeInfoState | null =>
    getAOABookingFormData(state)?.dateTimeInfo

export const getPreviouslySelectedServices = (state: RootState): Array<AOASelectedService> | undefined =>
    getServiceInfo(state)?.previouslySelectedServices

export const getSelectedServices = (state: RootState): Array<AOASelectedService> | undefined =>
    getServiceInfo(state)?.selectedServices

export const getServiceData = (state: RootState): AOAServiceResponseState | undefined =>
    getServiceInfo(state)?.serviceData

export const getHasStartedAOAFlow = (state: RootState): boolean | undefined =>
    getAOABookingFormData(state).hasStartedAOAFlow

export const getEtireInfo = (state: RootState): AOAEtireInfoState | null => getAOABookingFormData(state)?.eTireInfo

export const getEtireStoreInfo = (state: RootState): AOAEtireOrderStoreInfo | undefined =>
    getEtireInfo(state)?.storeInfo

export const getEtireStoreId = (state: RootState): string | undefined => getEtireStoreInfo(state)?.storeId

export const getOrderNumber = (state: RootState): string | undefined => getEtireInfo(state)?.orderNumber

export const getShowSkeleton = (state: RootState): boolean => getAOABookingFormData(state).showSkeleton

export const getAdditionalComments = (state: RootState): string | undefined => getServiceInfo(state)?.additionalComments

export const getSecondaryNavigationData = (state: RootState): InitialCategoryPayloadType[] => state.secondaryNavigation
