import getPageType from './getPageType'
import { pageTypes } from '../config'
import { isVehicleSelected, isWheelOrTirePDP } from '../components/Vehicles/Vehicle.helper'
import store from '../store'

const { checkout, cart, packageLanding } = pageTypes
const pageType = getPageType()

/**
 * Function to check the pages where minicart api to be called
 * @return { boolean }
 */
const isPageForMiniCart = (): boolean => {
    const rootState = store.getState()
    const productWheelType = rootState.categoryIdData.productWheelType

    return (
        !isCheckoutPage() &&
        !isShoppingCartPage() &&
        !(isVehicleSelected() && isWheelOrTirePDP(productWheelType)) &&
        !isPackageLanding()
    )
}

/**
 * Function to check if page is checkout
 * @return { boolean }
 */
const isCheckoutPage = (): boolean => {
    return pageType === checkout
}

/**
 * Function to check if page is cart
 * @return { boolean }
 */
const isShoppingCartPage = (): boolean => {
    return pageType === cart
}

/**
 * Function to check if page is packageLanding
 * @return { boolean }
 */
const isPackageLanding = (): boolean => {
    return pageType === packageLanding
}

export { isCheckoutPage, isShoppingCartPage, isPageForMiniCart }
