import { BrandingColors } from '@nl/lib'

export const formFieldSize = {
    mini: 'mini',
    small: 'small',
    medium: 'medium',
    large: 'large',
    default: 'default',
}

export const queryParameters = {
    language: 'lang',
    searchQuery: 'q',
    divider: ';',
    count: 'count',
    page: 'page',
    priceUpperBound: 'priceUpperBound',
    priceLowerBound: 'priceLowerBound',
    store: 'store',
    plpCDSDivider: '&',
} as const

type queryParametersWithoutDividerType = Omit<typeof queryParameters, 'divider'>

export type queryParametersKeyType = queryParametersWithoutDividerType[keyof queryParametersWithoutDividerType]

export const wishlistErrorObject = {
    maxLimit: 'WishlistOverLimitError',
}

export const accountsPageUrlPattern = {
    en: '/my-account/',
    fr: '/mon-compte/',
}

export const flyerPageUrlPattern = {
    en: '/flyer.html',
    fr: '/circulaires.html',
}

export const componentType = {
    default: 'default',
    triangleSelect: 'triangle-select',
}

export const status = {
    success: 'Success',
    failed: 'Failed',
}

export const EMAIL_MAX_LENGTH = 254
export const EMAIL_MAX_LENGTH_STORE_PICK_UP = 256
export const PHONE_MAX_LENGTH = 12
export const LAST_NAME_MAXLENGTH = 50
export const FIRST_NAME_MAXLENGTH = 50
export const ADDRESS_MAXLENGTH = 40
export const POSTAL_CODE_MAXLENGTH = 7
export const transactionNavLink = 'triangle-rewards-transaction'
export const EMAIL_ACQUISITION_SIGNUP_STICKY_BANNER = 'Email Acquisition Sticky SignUp Banner'

// just a class-marker for sticky to bottom elements
export const STICKY_TO_BOTTOM = 'sticky-to-bottom-elem'
export const STICKY_TOC_ON = 'on'

export enum storageData {
    cartGuid = 'cart-guid',
    sflGuid = 'sfl-guid',
    wishlistGuid = 'wishlist-guid',
    wishlistCache = 'wishlist-cache',
    gigyaJWT = 'gigya.JWT',
    preferredStoreId = 'preferred-store-id',
    cartStoreChanged = 'cartStoreChanged',
    isDeliveryModeConflict = 'isDeliveryModeConflict',
    storeTimezone = 'preferred-store-time-zone',
    cartData = 'cartItemsData',
    postalCode = 'postalCode',
    countries = 'countries-data',
    recentSearchList = 'CTC.recentSearchList',
    vehicles = 'vehicles',
    tiresSizeData = 'tiresSizeData',
    medalliaStoreData = 'medalliaStoreData',
    addedNewVehicleInPackage = 'addedNewVehicleInPackage',
    userId = 'userId',
    UID = 'UID',
    regToken = 'regToken',
    tokenState = 'tokenState',
    forgotEmail = 'forgotEmail',
    cdsToken = 'cds.token',
    ctfsMember = 'ctfsMember',
    deliveryPostalCode = 'deliveryPostalCode',
    backToResultLink = 'backToResultLink',
    semafoneMode = 'semafoneMode',
    totalTraingleTransaction = 'totalTriangleTransaction',
    optimizelyShoppingCart = 'optimizelyShoppingCart',
    orderStatus = 'orderStatus',
    cartDataForAnonymous = 'cartDataForAnonymous',
    cartMergeFlag = 'cartMergeFlag',
    isGiftCardWasApplied = 'isGiftCardApplied',
    isPaypalTendersApplied = 'isPaypalTendersApplied',
    moveToSFLItem = 'moveToSFLItem',
    emailStickyBannerTimer = 'emailStickyBannerTimer',
    cartLastVisitedDate = 'cartLastVisitedDate',
}

/**
 * This can be used to support multi languages label
 */
export enum multiLanguageLabel {
    englishLangLabel = 'EN',
    frenchLangLabel = 'FR',
}

export enum multiLanguageAltValue {
    englishLang = 'en_CA',
    frenchLang = 'fr_CA',
}

/**
 * this file contains constants which are used across the app
 */
export const currencyShortForm = 'CA' // currency short form
export const currencyFormat = 'CAD' // currency format
export const digitsPattern = /[^0-9]/g // Regular expression for digits
export const googleMapsUrl = 'http://maps.google.com?q=' // google map url
export const countryCode = 'ca'
export const chevronDown = 'ct-chevron-down'
export const chevronUp = 'ct-chevron-up'
export enum signoutLocation {
    account = 'my account',
    header = 'header',
}
export const regionsData = 'regions-data-'
export const monthList = [
    { label: '01', value: '01' },
    { label: '02', value: '02' },
    { label: '03', value: '03' },
    { label: '04', value: '04' },
    { label: '05', value: '05' },
    { label: '06', value: '06' },
    { label: '07', value: '07' },
    { label: '08', value: '08' },
    { label: '09', value: '09' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
]

export const CTAClickedName = {
    mainCTAClickedName: 'MAIN',
    stickyCTAClickedName: 'STICKY',
    fbtCTAClickedName: 'FBT',
    wishListCTAClickedName: 'WISHLIST',
}

export enum VehicleFitType {
    Fit = 'Fit',
    PartialFit = 'PartialFit',
    NotFit = 'NotFit',
    Unknown = 'UnKnown',
}

export const userType = {
    current: 'current',
    anonymous: 'anonymous',
}

export enum userLoginStatus {
    fullyAuthenticatedUser = 'AUTHORIZED',
    partiallyAuthenticatedUser = 'REMEMBER_ME',
    guestUser = 'ANONYMOUS',
}

export const autoServiceInstallationName = 'AUTO'

export enum productWheelTypes {
    Tire = 'TIRE',
    Wheel = 'WHEEL',
    WinterTire = 'WINTER_TIRE',
}

export enum eProductStatus {
    Active = 'ACTIVE (ACT)',
    Available = 'AVAILABLE (AVA)',
    FullDiscontinued = 'FULLY_DISCONTINUED (FD)',
    WhenOutDiscontinued = 'DISCONTINUED_WHEN_OUT (DW)',
    SeasonalDiscontinued = 'SEASONALLY_DISCONTINUED (SD)',
    TempDiscontinued = 'TEMPORARY_DISCONTINUED (TD)',
    OutOfStock = 'OUT_OF_STOCK (OOS)',
    SeasonalOutOfStock = 'SEASONAL_OUT_OF_STOCK (SOS)',
    TempOutOfStock = 'TEMPORARY_OUT_OF_STOCK (TOS)',
    Discontinued = 'DISCONTINUED (DIS)',
}

export enum dropdownSizes {
    mini = 'mini',
    small = 'small',
    default = 'default',
    medium = 'medium',
}

export enum FitmentTypeEnum {
    UNIVERSAL_FIT_01 = '01_UNIVERSAL_FIT',
    DIRECT_FIT_02 = '02_DIRECT_FIT',
    VEH_COND_FIT_03 = '03_VEH_COND_FIT',
    PROD_VEH_COND_FIT_04 = '04_PROD_VEH_COND_FIT',
    PART_COND_FIT_05 = '05_PART_COND_FIT',
}

export enum AutoPackageKeys {
    nextSelectedContainer = 'nextSelectedContainer',
    currentSelectedContainer = 'currentSelectedContainer',
    changeSelectedLink = 'changeSelectedLink',
    cartFlyoutChangeSelectedLink = 'cartFlyoutChangeSelectedLink',
    tireData = 'tireData',
    wheelData = 'wheelData',
}
export enum ProductTypeList {
    SERVICE = 'STANDALONE (SER_STA)',
    PRODUCT = 'PRODUCT (PRO)',
    SERVICE_ADDON = 'SERVICE_ADDON (SER_ADD)',
}

export enum TireWheelFitmentNote {
    OEfront = 'OE-front',
    OErear = 'OE-rear',
    OE = 'OE',
    PlusMinus = 'plus-minus',
    OIfront = 'OI-front',
    OIrear = 'OI-rear',
    IsFrontTire = 'isFrontTire',
}

export enum TireWheelFitmentTitle {
    staggered = 'STAGGERED',
    regular = 'REGULAR',
}

export const waitTime = 1000

export const flyerStoreId = 'flyerStoreId'

export const currentPageNumber = 1
export const errorCodePrefix = 'error'

export const httpStatusCodes = {
    partialSuccessCode: 206,
    successCode: 200,
    badRequest: 400,
    gone: 410,
    redirectCode: 301,
}

export const excludeHttpStatusCodes = [httpStatusCodes.partialSuccessCode]

export const dateOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC',
}

// hideSoftRecallModal is added as part of OCCP-20147"
export const hideSoftRecallModal = true

export const specifictionsCode = {
    tireWidth: 'ps_tire_width',
    tireAspectRatio: 'ps_aspect_ratio_percent',
    tireDiameter: 'ps_tirerim_diameter',
    wheelWidth: 'ps_rim_width',
    wheeDiameter: 'ps_wheelrim_diameter',
}

export const countParam = 'count'
export const colorCodeParam = 'colorCode'

export enum StoreChangeSource {
    header = 'HEADER',
    cart = 'CART',
    pdp = 'NEARBY AVAILABILITY MODAL',
    storeLocatorPage = 'STORE LOCATOR PAGE',
    wishlist = 'WISHLIST',
    other = 'OTHER',
}

export const LESS = 'LESS'
export const PERCENT = '[percent]'
export const CUT = '[cut]'
export const versionForMiniCartData = 'v1'

export enum RewardsCardType {
    triangleMasterCard = 'TriangleMC',
    worldEliteMasterCard = 'WorldMC',
    rewardsCard = 'RewardsCard',
}

export enum StoresProvinceList {
    qc = 'QC',
}

export const authenticationClasses = {
    authenticated: 'loggedIn',
    authenticatedRewards: 'loggedIn-rewards',
    authenticatedTSSubscription: 'loggedIn-ts-subscription',
    showSkeleton: 'show-skeleton',
}

export const accessibility = {
    a11yPopUpTitleId: 'popUpTitleId',
    a11yViewOrderHistoryId: 'viewOrderHistoryId',
    a11yTriangleTransactionId: 'triangleTransactionId',
}

export const browseOnlyCookieName = 'odpbrowsemode'

export const triangleUpSellComp = 'triangleUpsell'

export const fontColor = {
    WHITE: '#ffffff',
    BLACK: '#000000',
}
export const mergeGuestCartEventName = 'mergeGuestCart'
export const isTriangleMastercardLinked = 'Y'

export const separatorProps = {
    colors: [BrandingColors.RED, BrandingColors.BURGUNDY, BrandingColors.TEAL],
    style: 'skew',
}

export enum offerTypes {
    MULTIPLIER = 'MULTIPLIER',
    SPENDANDGET = 'SPENDANDGET',
}

export const triangleSelectSeparatorProps = {
    colors: [BrandingColors.WHITE, BrandingColors.GREY, BrandingColors.GREEN],
    style: 'skew',
}

export const expandCardSeparatorProps = {
    colors: [triangleSelectSeparatorProps.colors, separatorProps.colors],
    style: 'skew',
    isColorDynamic: true,
}

export const emptySpace = ''

/** various type of status icons we use for toast */
export const statusIcons = {
    successIcon: 'ct-notification-success',
    cautionIcon: 'ct-notification-caution',
    failureIcon: 'ct-toast-error',
    infoIcon: 'ct-information-blue',
}
export const sportCheckBannerId = 'SC'
