import React from 'react'

import { PREFIX } from '../config'
import { SnippetCardType } from './SnippetCard.type'
import Separator from '../Separator'
import Icon from '../Icon'
import { SeparatorStyle } from '../Separator/Separator.types'
import { BrandingColors } from '../../globalConstants/global.constant'
import SanitizeStringContentWrapper from '../../utils/sanitizeStringContent'

const SnippetCard: React.FC<SnippetCardType> = ({ ...props }): JSX.Element => {
    const {
        title,
        description,
        imageOffSet,
        image,
        imageAlt,
        children,
        separatorProps,
        footerContent,
        customClass = '',
        iconType,
    } = props
    const classname = `${PREFIX}-snippet-card`
    const separatorColors = separatorProps?.colors
        ? separatorProps.colors
        : [BrandingColors.RED, BrandingColors.BURGUNDY, BrandingColors.TEAL]
    const separatorStyle = separatorProps?.style ? separatorProps.style : SeparatorStyle.SKEW

    const renderCardDetails = (): JSX.Element => (
        <>
            <SanitizeStringContentWrapper stringContent={title}>
                {memoizedStringContent => (
                    <div
                        className={`${classname}__title`}
                        dangerouslySetInnerHTML={{ __html: memoizedStringContent }}
                    />
                )}
            </SanitizeStringContentWrapper>
            {description && <div className={`${classname}__label`} dangerouslySetInnerHTML={{ __html: description }} />}
        </>
    )

    /**
     * Function to render Icon component
     * @return {JSX.Element} returns Icon component
     */
    const renderIcon = (): JSX.Element | void => {
        if (iconType) {
            return (
                <div className={`${classname}__icon`}>
                    <Icon type={iconType} size="lg"></Icon>
                </div>
            )
        }
    }

    /**
     * Function to render ImageOrIcon component
     * @return {JSX.Element} returns ImageOrIcon component
     */
    const renderImageOrIcon = (): JSX.Element => {
        return image ? (
            <img
                className={`${classname}__img${imageOffSet ? '--offset' : ''}`}
                src={image}
                alt={imageAlt}
                aria-hidden="true"
            />
        ) : (
            <>{renderIcon()}</>
        )
    }

    return (
        <section className={`${classname}`}>
            <div
                className={`${PREFIX}-xs-flex ${customClass} ${PREFIX}-xs-align-items-start ${classname}__container${
                    imageOffSet ? '--offset' : ''
                }`}>
                {renderImageOrIcon()}
                <div className={`${classname}__details${imageOffSet ? '--offset' : ''}`}>
                    {renderCardDetails()}
                    {children}
                </div>
            </div>
            {footerContent}
            {separatorProps && <Separator colors={separatorColors} style={separatorStyle} />}
        </section>
    )
}

export default SnippetCard
