import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { SkeletonComponent, isArrayNotEmpty, CategoryCard, Button, Icon } from '@nl/lib'
import { PREFIX } from '../../config'
import { centerCard, maxCategoriesToShow, noOfItemsToShow } from './StackedCategoryList.constant'
import { StackedCategoryListProps } from './StackedCategoryList.type'
import { RootState } from '../../redux/reducers'
import { SubCategory } from '../../redux/models/category.interface'
import { analyticsInteraction } from '../../redux/actions'
import { analyticsAttributes } from '../../globalConstants'
import { usePageAnalytics } from '../../analytics/hooks/usePageAnalytics'
import { MagicNumber } from '../../analytics/analytics.type'
import { AkamaiImagePolicies } from '../../akamaiPolicy/akamaiPolicy.service'
import { commonContentSelector } from '../../redux/selectors/commonContent.selectors'
import { getEnvironment } from '../../environments'
import { linkTargetOptions } from '@nl/lib/src/globalConstants/global.constant'

// Component for Dynamic Category Featured List
const StackedCategoryList: React.FC<StackedCategoryListProps> = ({
    title,
    numberOfItems,
    viewLessCategoriesLabel,
    viewMoreCategoriesLabel,
}: StackedCategoryListProps): JSX.Element => {
    const subCategories = useSelector((state: RootState) => state.categoryIdData.subcategories)
    const { commonContentAvailable } = useSelector(commonContentSelector)
    const config = getEnvironment()

    const dispatch = useDispatch()

    const [noOfCategoriesToShow, setNoOfCategoriesToShow] = useState(0)

    const {
        event: { filter },
        eventParameters: {
            action: { shopByCategory },
        },
    } = analyticsAttributes

    const [showViewMore, setShowViewMore] = useState(true)
    const [showSkeleton, setShowSkeleton] = useState(false)

    const { a11yExpandText, a11yCollapseText } =
        commonContentAvailable?.accessibility || ({} as typeof commonContentAvailable.accessibility)

    /**
     * Function is used to show button on mobile screen only.
     * Also to center the card items when the items are less than no of cards in a single row.
     */
    const renderViewMoreButton = useCallback(() => {
        setShowSkeleton(true)
        if (isArrayNotEmpty(subCategories)) {
            const categoriesLength =
                subCategories.length > maxCategoriesToShow ? maxCategoriesToShow : subCategories.length
            setNoOfCategoriesToShow(categoriesLength)
        }
        setShowSkeleton(false)
    }, [subCategories])

    useEffect(() => renderViewMoreButton(), [renderViewMoreButton])

    usePageAnalytics()

    // Aria-label for view button.
    const buttonAriaLabel = showViewMore ? a11yExpandText : a11yCollapseText
    // Icon
    const buttonChevron = showViewMore ? 'ct-chevron-down' : 'ct-chevron-up'

    /**
     * Function to trigger analytics.
     * @param {string} label
     */
    const cardClicked = (label: string | undefined): void => {
        dispatch(analyticsInteraction(label as string, '', filter, shopByCategory))
    }

    /**
     * Renders skeleton when there is no recommendation data.
     * @return {JSX.Element[]}
     */
    const renderSkeletonComponent = (): JSX.Element => {
        return (
            <ul className={`${PREFIX}-col-lg-12 ${PREFIX}-product-category__grid-items`}>
                {Array(noOfItemsToShow)
                    .fill('')
                    .map((_el, index) => (
                        <li key={index} className={`${PREFIX}-product-category__content`}>
                            <SkeletonComponent skeletonClass={`${PREFIX}-product-category__skeleton-wrapper`} />
                        </li>
                    ))}
            </ul>
        )
    }

    /**
     * Render title component.
     * @return {JSX.Element | false}
     */
    const renderTitleComponent = (): JSX.Element | false => {
        return !!title && <h2 className={`${PREFIX}-product-category__title`}>{title}</h2>
    }

    /**
     * Button on click handler for toggling between the no of items to show in mobile..
     */
    const onButtonClick = (): void => {
        setShowViewMore(prevState => !prevState)
        if (!showViewMore) {
            const appliancesCategoryElement = document.querySelector(`.${PREFIX}-product-category__title`)
            if (typeof appliancesCategoryElement?.scrollIntoView === 'function') {
                appliancesCategoryElement?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                })
            }
        }
    }

    /**
     * Function to render categories.
     * @return {JSX.Element}
     */
    const renderCategories = (): JSX.Element => {
        const buttonText = showViewMore ? viewMoreCategoriesLabel : viewLessCategoriesLabel
        const gridClass = `${PREFIX}-product-category__grid-items`
        const desktopCenterAlignThreshold =
            noOfCategoriesToShow < centerCard.desktop ? `${gridClass}__desktop--center` : ''
        const wideCenterAlignThreshold = noOfCategoriesToShow < centerCard.wide ? `${gridClass}__wide--center` : ''
        return (
            <>
                <ul
                    className={`${PREFIX}-col-md-12 ${PREFIX}-col-lg-12  ${gridClass} ${desktopCenterAlignThreshold} ${wideCenterAlignThreshold}`}>
                    {subCategories.slice(0, noOfCategoriesToShow).map((singleCategory: SubCategory, index: number) => {
                        const url = singleCategory?.url
                        const hideClass =
                            index + MagicNumber.ONE > numberOfItems
                                ? showViewMore
                                    ? `${PREFIX}-product-category__content--hide-items`
                                    : ''
                                : ''
                        return (
                            <li
                                key={`${singleCategory.id}_${index}`}
                                className={`${PREFIX}-product-category__content ${hideClass}`}>
                                <CategoryCard
                                    href={url}
                                    linkTarget={linkTargetOptions.selfTarget}
                                    imagePolicies={AkamaiImagePolicies.initIndPolicy}
                                    cardClicked={cardClicked}
                                    linkClass={`${PREFIX}-product-category__link`}
                                    imageClass={`${PREFIX}-product-category__image`}
                                    labelClass={`${PREFIX}-product-category__label`}
                                    image={singleCategory.image ? singleCategory.image : config.defaultProductImage}
                                    label={singleCategory.name}
                                />
                            </li>
                        )
                    })}
                </ul>
                {noOfCategoriesToShow > numberOfItems && (
                    <Button
                        id="view-more-less-button"
                        type="tertiary"
                        onClick={onButtonClick}
                        ariaLabel={buttonAriaLabel}>
                        {buttonText}
                        <Icon type={buttonChevron} size="lg" />
                    </Button>
                )}
            </>
        )
    }

    return (
        <div className={`${PREFIX}-stacked-component`}>
            {renderTitleComponent()}
            {noOfCategoriesToShow > 0 ? renderCategories() : showSkeleton && renderSkeletonComponent()}
        </div>
    )
}

export default StackedCategoryList
