import React, { useLayoutEffect, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { checkDataLength } from '../Addresses/checkDataLength'
import { CommonCardWrapper, CommonCard, SkeletonComponent } from '@nl/lib'
import { PREFIX } from '../../../config'
import { userTruefitSelector } from '../../../redux/selectors/Truefit.selectors'
import { TruefitProps } from './Truefit.type'

const Truefit: React.FC<TruefitProps> = ({ title }): JSX.Element => {
    const userTruefit = useSelector(userTruefitSelector)
    const [isPending, setIsPending] = useState(userTruefit.isPending)

    useLayoutEffect(() => {
        if (!isPending) {
            window?.tfcapi('calculate')
        }
    }, [isPending])

    useEffect(() => {
        setIsPending(userTruefit.isPending)
    }, [userTruefit])

    return (
        <CommonCardWrapper noPadding={true}>
            <>
                {checkDataLength(title) && !isPending ? (
                    <>
                        <div className={`${PREFIX}-truefit__section-title`}>{title}</div>
                        <CommonCard>
                            <div className={`${PREFIX}-truefit__container`}>
                                <div className={'tfc-fitrec-account'} {...userTruefit.props}></div>
                            </div>
                        </CommonCard>
                    </>
                ) : (
                    <SkeletonComponent skeletonClass={`${PREFIX}-truefit__skeleton-my-account-settings`} />
                )}
            </>
        </CommonCardWrapper>
    )
}

export default Truefit
