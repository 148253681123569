import { AutoPackages, TireWheelDetails } from '../PackageLanding/PackageLanding.type'
import { checkDataLength } from '../../Accounts/Addresses/checkDataLength'
import { MagicNumber } from '../../../analytics/analytics.type'
import sessionStorageService from '../../../utils/sessionStorageService'
import { AutoPackageKeys } from '../../../globalConstants'
import { getEnvironment } from '../../../environments'
import store from '../../../store'
import { updateAutoPackages } from '../../../redux/actions'
import { PackageDataType } from './ProgressBanner.type'

/**
 * We are passing user selected value and preparing array for autoPackage array.
 * @param {string} updatedKey
 * @param {string | boolean} type
 * @param {AutoPackages} autoPackages
 * @return {AutoPackages[]}
 */
export const prepareInitialAutoPackageData = (
    updatedKey: string,
    type: string | boolean,
    autoPackages: AutoPackages[],
): AutoPackages[] => {
    return autoPackages.reduce((initialValue: AutoPackages[], currentValue: AutoPackages, index) => {
        if (autoPackages.length - MagicNumber.ONE === index) {
            return [...initialValue, { ...currentValue, [updatedKey]: type }]
        }
        return [...initialValue, currentValue]
    }, [])
}

export const removeInitialAutoPackageData = (deleteKey: string, autoPackages: AutoPackages[]): AutoPackages[] => {
    return autoPackages.reduce((initialValue: AutoPackages[], currentValue: AutoPackages, index) => {
        if (autoPackages.length - MagicNumber.ONE === index) {
            delete currentValue[deleteKey]
            return [...initialValue, { ...currentValue }]
        }
        return [...initialValue, currentValue]
    }, [])
}
/**
 * Get only tire data
 * @param {TireWheelDetails} tireData
 * @param {TireWheelDetails} wheelData
 * @return {boolean}
 */
export const isOnlyTireData = (tireData: TireWheelDetails, wheelData: TireWheelDetails): boolean => {
    return !checkDataLength(wheelData) && checkDataLength(tireData)
}

/**
 * Get only wheel data
 * @param {TireWheelDetails} tireData
 * @param {TireWheelDetails} wheelData
 * @return {boolean}
 */
export const isOnlyWheelData = (tireData: TireWheelDetails, wheelData: TireWheelDetails): boolean => {
    return !checkDataLength(tireData) && checkDataLength(wheelData)
}

/**
 * Function to store data in session storage on the basis of key and value
 * @param {AutoPackages[]} autoPackages
 * @param {string} nextSelectedContainer
 * @param {PackageDataType | string} packageData
 * @param {string} selectedContainerKey
 * @param {string | boolean} containerType
 * @param {boolean} dispatchItem
 * @param {boolean} cartFlyoutChangeSelectedLink
 * @return {void}
 */
export const updatePackageFlowState = (
    autoPackages: AutoPackages[],
    nextSelectedContainer: string,
    packageData: PackageDataType | string,
    selectedContainerKey: string,
    containerType: string | boolean,
    dispatchItem: boolean,
    cartFlyoutChangeSelectedLink?: boolean,
): void => {
    let initialAutoPackageData = prepareInitialAutoPackageData(selectedContainerKey, containerType, autoPackages)
    if (cartFlyoutChangeSelectedLink) {
        if (nextSelectedContainer) {
            initialAutoPackageData = removeInitialAutoPackageData(
                AutoPackageKeys.nextSelectedContainer,
                initialAutoPackageData,
            )
        } else {
            initialAutoPackageData = prepareInitialAutoPackageData(
                AutoPackageKeys.cartFlyoutChangeSelectedLink,
                containerType,
                initialAutoPackageData,
            )
        }
    }
    sessionStorageService.setItem(
        getEnvironment().SESSION_STORAGE_KEYS.packageFlow,
        JSON.stringify({ ...packageData, autoPackages: initialAutoPackageData }),
    )
    dispatchItem && store.dispatch(updateAutoPackages(initialAutoPackageData))
}
