/**
 * Interface for SortOption
 * @interface
 */
export interface SortOption {
    url: string
    selected: boolean
    title: string
}

/**
 * Interface for FacetTypes
 * @interface
 */
export enum FacetTypes {
    BREADCRUMB = 'breadcrumb',
    RADIO = 'radio',
    MULTISELECT = 'multiselect',
    RANGE = 'range',
    RATING = 'rating',
    COLOURPICKER = 'colourPicker',
    CUSTOM_PRICE = 'custom_price',
    CATEGORY_ID = 'category_ast',
}

/**
 * Interface for Facet
 * @interface
 */

export interface Facet {
    type: FacetTypes
    collapsed?: boolean
    label: string
    id?: string // will be removed in the future sprint when implementing search for all filters
    clearLink: string
    values: FacetValue[]
    selected: boolean
    isTriangleOffer?: boolean
    current?: {
        label: string
        clearUrl: string
    }
    parent?: {
        label: string
        url: string
    }
}

/**
 * Interface  for FacetValue
 * @interface
 */
export interface FacetValue {
    label: string
    count?: number
    selected: boolean
    disabled?: boolean
    url: string
    clearUrl: string
    image?: string
    value?: string
    skuCodes?: string[]
    id?: number
    isUnavailable?: boolean
    name?: string
}

/**
 * Interface for FacetRange
 * @interface
 */
export interface FacetRange {
    url?: string
    type: string
    collapsed?: boolean
    label: string
    id?: string // will be removed in the future sprint when implementing search for all filters
    clearLink?: string
    values: FacetValue[]
    selected: boolean
    lowerAvailable: number | null
    upperAvailable: number | null
    min: number
    max: number
    upperBound: number | null
    lowerBound: number | null
    current?: {
        label: string
        clearUrl: string
    }
    parent?: {
        label: string
        url: string
    }
}

/**
 * interface for last Selected category
 */
export interface ParentCategory {
    label?: string
    url?: string
    clearUrl?: string
}

/**
 * Interface for FacetPanelModalProps
 * @interface
 */

export interface FacetPanelModalProps {
    isOpen: boolean
    closeFlyout: () => void
    closeBtnLabel: string
    title: string
    viewResultsLabel: string
    filtersLabel: string
    mSortByCTALabel: string
    clearAllLabel: string
    sortLabel?: string
    refineByLabel?: string
    categoryLabel?: string
    showMoreThreshold: number
    showMoreLabel: string
    showLessLabel: string
    resultCount: number
    sortings: SortOption[]
    facets: (Facet | FacetRange)[]
    path?: string
    onSortChange: () => void
    selectedFacetType?: string
    selectedLabel: string
    clearAllLink: string
    onFilterChange: (url, value, facetTitle, facetSelected, isChecked, type, id) => void
    currentLocale: string
    minPriceErrorMessage: string
    maxPriceErrorMessage: string
    minPriceLabel: string
    maxPriceLabel: string
    isClientSideFilter?: boolean
    isTriangleOffer?: boolean
    enableFilterFacetsEnhancement: boolean
    searchInputPlaceholderText?: string
    noSearchResults?: string
    categoryData: FacetValue[]
    showCategoryDropdown?: boolean
    isCategoryPage?: boolean
}
