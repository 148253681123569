import { getProvider } from '../providers'
import { analyticsAttributes } from '../../globalConstants/analyticsParams.constant'
import { CartAnalytics, FilteredCartData, GiftCard, AppliedVoucher } from '../../redux/models/cart.interface'
import { checkout } from '../components/analytics.constant'
import { ProductHelper } from '../helpers/product.helper'
import { isProductTire, isProductWheel, isProductOtherAutoPart } from '../../utils/automotiveProducts'
import { isArrayNotEmpty } from '@nl/lib'
import { getDaysDifference } from '../../utils/getDaysDifference.utils'
import { checkIsGuaranteedFitment } from '../../utils/checkIsGuaranteedFitment'
import { MagicNumber } from '../../analytics/analytics.type'

export const orderConfirmationAnalytics = (order: FilteredCartData): void => {
    const {
        event: { purchase },
        eventParameters: {
            labels: { ctMoneyLabel },
        },
    } = analyticsAttributes
    const coupons = isArrayNotEmpty(order.cart?.appliedVouchersData)
        ? order.cart?.appliedVouchersData
              ?.reduce(
                  (accumulator: string[], coupon: AppliedVoucher): string[] =>
                      [...accumulator, coupon.code] as string[],
                  [],
              )
              .join(' | ')
        : ''

    const {
        cart: { entries }, // as per interface it should be entries and not orderEntries
    } = order
    const getOrderConfirmationProducts = (): CartAnalytics[] => {
        const productAnalyticsList: CartAnalytics[] = []
        if (entries && Object.keys(entries).length) {
            Object.values(entries).forEach(entry => {
                const isFitted = (entry?.vehicleInformation && entry?.fitmentTypeCode) !== null
                const { variant1, variant2, variant3 } = ProductHelper.transFormVariantData(entry)
                const [level1, level2, level3, level4, level5] = entry?.merchCategories || []
                const feeMessage = isArrayNotEmpty(entry?.feeMessages) && entry?.feeMessages?.[0]
                productAnalyticsList.push({
                    badge: entry?.badges,
                    bulk: entry?.isBulk,
                    brand: entry?.brand?.label,
                    category: entry?.merchCategories?.join('/'),
                    level1,
                    level2,
                    level3,
                    level4,
                    level5,
                    fulfillment: ProductHelper.transformFulfillment(entry, true),
                    guaranteedFitment: checkIsGuaranteedFitment(entry),
                    fullfillmentDaysAway: getDaysDifference(entry?.fulfillment?.sourceTypes?.[0]?.etaEarliest),
                    fee: ProductHelper.transformFee(feeMessage),
                    fitted: isFitted,
                    package: String(entry?.isPackageItem),
                    name: entry?.name,
                    pcode: entry?.baseProduct,
                    price: ProductHelper.transformPrice(entry),
                    rebate: ProductHelper.transformRebate(entry?.rebate),
                    ror: isProductTire(entry) || isProductWheel(entry) || isProductOtherAutoPart(entry),
                    quantity: entry?.quantity,
                    rating: entry?.rating,
                    shipFrom:
                        ProductHelper.transformFulfillment(entry, true) === 'STH'
                            ? ProductHelper.getShipFrom(entry?.fulfillment?.sourceTypes)
                            : undefined,
                    sku: entry?.code,
                    variant1,
                    variant2,
                    variant3,
                    vehicle: ProductHelper.transformVehicle(entry),
                })
            })
        }
        return productAnalyticsList
    }

    const getPaymentDetails = (orderDetails: FilteredCartData, ctLabel: string): string[] => {
        const twoDecimalRound = (num: number) => Math.round(Number(num) * MagicNumber.HUNDRED) / MagicNumber.HUNDRED

        const paymentInfo = []

        const isGiftCardApplied = isArrayNotEmpty(orderDetails?.cart?.paymentInfo?.giftCards)
        const giftCards = orderDetails?.cart?.paymentInfo?.giftCards
        const giftCardsAmount = giftCards?.reduce((accumulator, giftCard) => accumulator + giftCard.amount, 0)
        const giftCardsAmountRounded = twoDecimalRound(Number(giftCardsAmount))
        if (isGiftCardApplied) {
            const giftCardsList = giftCards?.reduce(
                (accumulator: string[], giftCardData: GiftCard): string[] =>
                    [...accumulator, `giftCard: ${giftCardData.amount}`] as string[],
                [],
            ) as string[]
            paymentInfo.push(...giftCardsList)
        }

        const isPayPalApplied = !!orderDetails?.cart?.paymentInfo?.payPalInfo?.amount
        let payPalAmount = Number(orderDetails?.cart?.paymentInfo?.payPalInfo?.amount)
        payPalAmount = isGiftCardApplied ? payPalAmount - giftCardsAmountRounded : payPalAmount
        isPayPalApplied && paymentInfo.push(`PayPal: ${payPalAmount}`)

        const cardType = orderDetails?.cart?.paymentInfo?.cardType?.code
        let cardAmount = isArrayNotEmpty(orderDetails?.cart?.orderSummary?.taxLines)
            ? orderDetails?.cart?.orderSummary?.totalWithTaxesAmt?.value
            : orderDetails?.cart?.orderSummary?.totalAmt?.value

        cardAmount = isGiftCardApplied ? twoDecimalRound(Number(cardAmount)) - giftCardsAmountRounded : cardAmount
        Number(cardAmount) > 0 && !isPayPalApplied && paymentInfo.push(`${cardType}: ${String(cardAmount)}`)

        const ctMoney = orderDetails?.cart?.paymentInfo?.redeemCTAmount?.value ? ctLabel : null
        const ctAmount = orderDetails?.cart?.paymentInfo?.redeemCTAmount?.value
        ctMoney && paymentInfo.push(`${ctMoney}: ${String(ctAmount)}`)

        return paymentInfo
    }

    getProvider().push({
        event: purchase,
        cart: {
            id: order?.cart?.guid,
            fulfillmentCost: String(order?.cart?.orderSummary?.shippingFees?.value),
            financingSelected: order?.cart?.paymentInfo?.financingSelected,
        },
        transaction: {
            id: order?.cart?.code,
            affiliation: 'online',
            revenue: String(order?.cart?.orderSummary?.subTotalAmt?.value),
            tax: String(order?.cart?.orderSummary?.totalTaxAmt?.value),
            taxExemption: undefined,
            shipping: String(order?.cart?.orderSummary?.shippingFees?.value),
            discount: String(order?.cart?.orderSummary?.totalDiscount?.value),
            coupon: coupons,
            paymentMethod: getPaymentDetails(order, ctMoneyLabel),
        },
        products: getOrderConfirmationProducts(),
    })
}

/**
 * @return {void} When user will do 3rd party payment and redirects on orderconfirmation page, below analytic should fire.
 */
export const thirdPartyOrderConfirmationAnalytics = (): void => {
    const {
        event,
        eventParameters: {
            action: { thirdPartyPayment },
        },
    } = analyticsAttributes

    getProvider().push({
        event: event.checkout,
        checkout: {
            step: checkout.orderSummaryInfoStep,
            option: thirdPartyPayment,
        },
    })
}
