import { accountsPageUrlPattern } from '../globalConstants/global.constant'

/**
 * function to redirect to page after logout
 *
 * @param {string} redirectionURL - url where to redirect after signout
 */
export const signoutRedirection = (redirectionURL: string): void => {
    const currentPage = window.location.href
    if (currentPage.includes(accountsPageUrlPattern.en) || currentPage.includes(accountsPageUrlPattern.fr)) {
        window.location.href = redirectionURL
    }
}
