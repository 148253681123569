import React from 'react'
import Icon from '../Icon'
import { linkClassName, listItemClassName, listPipeItemClassName, textClassName } from './Breadcrumb.constant'

type SharedProps = {
    link: string
    name: string
    nofollow?: boolean
}

type OwnBreadcrumbLinkProps = {
    showPipeIcon: boolean
    isLastLinkAndNotInteractive: boolean
    pName: string
}

type BreadcrumbLinkProps = SharedProps & OwnBreadcrumbLinkProps

type InteractiveLinkProps = SharedProps

type NotInteractiveLinkProps = SharedProps & Pick<BreadcrumbLinkProps, 'pName'>

const refNoFollow = (nofollow = false) => (nofollow ? { rel: 'nofollow' } : {})

const InteractiveLink = ({ link, name, nofollow }: InteractiveLinkProps) => {
    return (
        <a className={linkClassName} href={link} data-link-value={name} {...refNoFollow(nofollow)}>
            {name}
        </a>
    )
}

const NotInteractiveLink = ({ link, name, pName, nofollow }: NotInteractiveLinkProps) => {
    return (
        <a
            key={link}
            className={textClassName}
            href={link}
            onClick={e => e.preventDefault()}
            aria-label={pName}
            aria-current="page"
            {...refNoFollow(nofollow)}>
            {name}
        </a>
    )
}

export const BreadcrumbLink = ({
    link,
    name,
    isLastLinkAndNotInteractive,
    pName,
    showPipeIcon,
    nofollow,
}: BreadcrumbLinkProps): JSX.Element => (
    <li key={link} className={showPipeIcon ? listPipeItemClassName : listItemClassName}>
        <Icon type="ct-chevron-left" size="md" />
        {isLastLinkAndNotInteractive ? (
            <NotInteractiveLink name={name} link={link} pName={pName} nofollow={nofollow} />
        ) : (
            <InteractiveLink link={link} name={name} nofollow={nofollow} />
        )}
    </li>
)
