import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { pageTypes, PREFIX } from '../../config'
import { StoreDetailsCompProps, AccordionList } from './StoreDetailsComp.type'
import { Position } from '../Header/Header.type'
import StoreServices from './StoreServices/StoreServices'
import { setSelectedPreferredStoreId, setSelectedStoreName, analyticsInteraction } from '../../redux/actions/'
import { googleMapsProps, markerProps } from './StoreDetails.constant'
import { getStoreId } from '../StoreDetails/StoreDetail.helper'
import { setPreferredStoreDetailsAction, storeLatLongDetails } from '../../redux/actionCreators'

import {
    Button,
    Icon,
    Map,
    replaceStrWithDynamicVal,
    Accordion,
    extractKeyValues,
    generateGoogleMapOnAddress,
    getFormattedPhoneNumber,
    isAutoServiceAvailableInStore,
    getStoreTimings,
    getNextDayStoreTimings,
    SkeletonComponent,
    formatedPhoneNumberAccessibility,
    isArrayNotEmpty,
} from '@nl/lib'
import StoreHoursInfo from './StoreHoursInfo/StoreHoursInfo'
import appCacheService from '../../utils/appCacheService'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { getDepartmentStoreSchema, getLocalBusinessSchema } from '../../Seo/helpers/SeoSchema.helper'
import AutoServiceButton from '../AutoServiceButton'
import { getEnvironment } from '../../environments'
import { seoHoc } from '../../Seo/components/Hoc/Seo.hoc'
import { navigatorService } from '../../utils/navigatorService'
import { analyticsAttributes } from '../../globalConstants/analyticsParams.constant'
import StoreHoursList from './StoreHoursInfo/StoreHoursList/StoreHoursList'
import checkNestedProps from '../../utils/checkNestedProps'
import accordionClick from '../AccordionClick'
import { commonContentSelector } from '../../redux/selectors/commonContent.selectors'
import {
    currentUserLocationSelector,
    isStoreDetailsFetchedSelector,
    storeDetailsSelector,
} from '../../redux/selectors/storeDetails.selectors'
import { docLoadStatusSelector } from '../../redux/selectors/docLoadStatus.selectors'
import { StoreWithAvailability } from '../../redux/models/storeDetails.interface'
import SanitizeStringContentWrapper from '@nl/lib/src/utils/sanitizeStringContent'

const StoreDetailsComp: React.FC<StoreDetailsCompProps> = ({ ...props }) => {
    const environment = getEnvironment()
    const { baseSiteId: baseStoreId } = environment
    const storeHoursInfoContainer = `${PREFIX}-store-hours-info-container`
    const { commonContentAvailable } = useSelector(commonContentSelector)
    const storeDetails = useSelector(storeDetailsSelector)
    const isStoreDetailsFetched = useSelector(isStoreDetailsFetchedSelector)
    const currentUserLocation = useSelector(currentUserLocationSelector)
    const isWindowGoogleLoaded = useSelector(docLoadStatusSelector)
    const { defaultMapId, mapZoomValue, defaultStoreLocations } = googleMapsProps
    const [mapZoom, setMapZoom] = useState(mapZoomValue)
    const enableStoreFlyer = checkNestedProps(commonContentAvailable, 'featureFlag', 'enableStoreFlyer') as boolean
    const [userLocation, setUserLocation] = useState({
        lat: 0,
        lng: 0,
    })
    const [accessibility, setAccessibility] = useState(
        commonContentAvailable?.accessibility || ({} as typeof commonContentAvailable.accessibility),
    )

    useEffect(() => {
        commonContentAvailable?.accessibility && setAccessibility(commonContentAvailable.accessibility)
    }, [commonContentAvailable])
    const {
        markerTypes: { preferredSelectedMarker, numericMarker },
    } = markerProps
    const {
        phoneLabel,
        feedbackCTALabel,
        flyerCTALabel,
        faxLabel,
        contactLabel,
        hoursInfoTitle,
        flyerCTAURL,
        contactPosition,
        closesInLabel,
        holidayMsg,
        closedLabel,
        collapseLabel,
        expandLabel,
        servicesTitle,
        accordionItems,
        onlineOrdersNotAcceptedMsg,
        storeHoursLabel,
        enableProvideFeedbackEnhancement,
        enableStoreHoursUnavailableMessage,
        storeHourMissingMsg,
        storeHourWeekdayMissingMsg,
        separator,
    } = props

    const storeHoursListContent = {
        phoneLabel,
        feedbackCTALabel,
        flyerCTALabel,
        faxLabel,
        contactLabel,
        hoursInfoTitle,
        flyerCTAURL,
        contactPosition,
        closesInLabel,
        holidayMsg,
        closedLabel,
        collapseLabel,
        expandLabel,
        servicesTitle,
        accordionItems,
        enableStoreFlyer,
    }
    const defaultMapProps = {
        mapId: defaultMapId,
        locations: defaultStoreLocations,
        setMapZoom,
        mapZoom,
        windowRef: window,
        userGeoLocation: userLocation,
        isWindowGoogleLoaded,
    }
    const storeServices = storeDetails.services || []
    const [marker, setMarker] = useState('')
    const [isPreferredStore, setIsPreferredStore] = useState(false)
    const [isStoreOpen, setIsStoreOpen] = useState(false)
    const [openHours, setOpenHours] = useState('')
    const [closeHours, setCloseHours] = useState('')
    const [newMapProps, setNewMapProps] = useState(defaultMapProps)
    const queryParams = window.location.search

    const storeId = getStoreId(queryParams)

    const dispatch = useDispatch()

    const { displayName, geoPoint, id } = storeDetails
    const address = storeDetails.address || {}
    const { closeLabel, closesAtLabel, openLabel, openingAtLabel, googleMapsMarkersColor } =
        commonContentAvailable?.storeLocator || {}
    const [CTCLogoURL] = extractKeyValues(commonContentAvailable, [{ general: ['CTCLogoURL'] }])

    const {
        event: { interaction },
        eventParameters: {
            action: { searchStore },
            location: { buyBoxLocation },
            labels: { geolocation },
            category: { store: storeName },
        },
    } = analyticsAttributes

    const setStoreProps = useCallback(() => {
        const updateMapProps = {
            mapId: id.toString(),
            userGeoLocation: userLocation,
            locations: [
                {
                    name: displayName,
                    latitude: geoPoint.latitude,
                    longitude: geoPoint.longitude,
                    storeMarker: marker,
                    storeMarkerLabel: ' ',
                    markerColor: googleMapsMarkersColor,
                },
            ],
            setMapZoom,
            mapZoom: mapZoomValue,
            windowRef: window,
            isWindowGoogleLoaded,
        }
        setNewMapProps(updateMapProps)
    }, [marker, displayName, geoPoint, id, mapZoomValue, googleMapsMarkersColor, userLocation, isWindowGoogleLoaded])

    useEffect(() => {
        if (checkDataLength(storeDetails)) {
            const openingHours = getStoreTimings(storeDetails)
            const nextDayOpeningHours = getNextDayStoreTimings(storeDetails)
            const { closingTime } = openingHours || {}
            const { openingTime } = nextDayOpeningHours || {}
            const { formattedHour: closeFormattedHour } = closingTime || {}

            const { formattedHour: openingFormattedHour } = openingTime || {}

            const preferredStoreId = appCacheService.preferredStoreId.get()
            if (preferredStoreId && Number(preferredStoreId) === storeDetails.id) {
                setMarker(preferredSelectedMarker(googleMapsMarkersColor))
                setIsPreferredStore(true)
                dispatch(setPreferredStoreDetailsAction(storeDetails))
            } else {
                setMarker(numericMarker(googleMapsMarkersColor))
                setIsPreferredStore(false)
            }

            const storeStatus = openingHours?.closed
            if (!storeStatus) {
                setIsStoreOpen(true)
            }
            setOpenHours(openingFormattedHour)
            setCloseHours(closeFormattedHour)
            setStoreProps()
        }
    }, [
        storeDetails,
        setStoreProps,
        preferredSelectedMarker,
        numericMarker,
        googleMapsMarkersColor,
        isWindowGoogleLoaded,
        dispatch,
        isStoreDetailsFetched,
    ])

    useEffect(() => {
        if (currentUserLocation) {
            setUserLocation({
                lat: currentUserLocation.latitude,
                lng: currentUserLocation.longitude,
            })
        }
    }, [currentUserLocation])

    /**
     * Set preferred store & fetch nearby stores
     */
    const setPreferredStore = () => {
        appCacheService.preferredStoreId.set(String(storeDetails.id))
        setIsPreferredStore(true)
        setMarker(preferredSelectedMarker(googleMapsMarkersColor))
        dispatch(setSelectedPreferredStoreId(String(storeDetails.id)))
        dispatch(setSelectedStoreName(storeDetails.displayName))
    }

    const redirectLink = generateGoogleMapOnAddress(`${address?.line1} ${address?.line2},${address?.postalCode}`)

    /**
     * returns store's timings data
     * @return { string }
     */
    const storeTimings = (): string => {
        return isStoreOpen
            ? replaceStrWithDynamicVal(closesAtLabel, closeHours)
            : replaceStrWithDynamicVal(openingAtLabel, openHours)
    }

    /**
     * Returns the Preferred store's DOM
     * @param { boolean } showPreferredStore
     * @return { JSX.Element | null }
     */
    const renderPreferredStore = (showPreferredStore: boolean): JSX.Element | null => {
        return showPreferredStore ? (
            <div className={`${PREFIX}-store-details__preferred-store`}>
                <div className={`${PREFIX}-preferred-store-label`}>
                    <Icon size="lg" type="ct-star-white"></Icon>
                    <span>{props.preferredStoreLabel}</span>
                </div>
                <a className={`${PREFIX}-update-link`} href={props.updateCTAURL} data-link-value={props.updateCTALabel}>
                    {props.updateCTALabel}
                </a>
            </div>
        ) : null
    }

    /**
     * function to render Store Hours Information and Store Services List
     * @return {JSX.Element}
     */
    const renderStoreHoursAndServices = (): JSX.Element => {
        return (
            <div className={`${PREFIX}-row ${PREFIX}-container ${PREFIX}-store-details__services-container`}>
                <div className={`${PREFIX}-col-xs-6 ${PREFIX}-col-sm-12 ${PREFIX}-col-md-8`}>
                    <div className={storeHoursInfoContainer}>
                        <h2>{storeHoursListContent.hoursInfoTitle}</h2>
                        <Accordion
                            title={storeHoursLabel}
                            isHeaderOpen={true}
                            expandLabel={expandLabel}
                            collapseLabel={collapseLabel}
                            collapseControl={(isOpen: boolean) => isOpen && accordionClick(storeHoursLabel)}>
                            <StoreHoursList
                                storeDetails={storeDetails}
                                storeHoursListContent={storeHoursListContent}
                                enableProvideFeedbackEnhancement={enableProvideFeedbackEnhancement}
                                enableStoreHoursUnavailableMessage={enableStoreHoursUnavailableMessage}
                                storeHourMissingMsg={storeHourMissingMsg}
                                storeHourWeekdayMissingMsg={storeHourWeekdayMissingMsg}
                                separator={separator}
                            />
                        </Accordion>
                        {storeDetails.storeServices?.length ? renderServices() : renderServicesFromAEM()}
                    </div>
                </div>

                <div className={`${PREFIX}-col-xs-6 ${PREFIX}-col-sm-12 ${PREFIX}-col-md-3 nl-col-md-offset-1`}>
                    {isArrayNotEmpty(storeServices) && (
                        <StoreServices servicesTitle={servicesTitle} services={storeServices} />
                    )}
                </div>
            </div>
        )
    }

    /**
     * function to render Store Hours Services from API
     * @return {JSX.Element}
     */

    const renderServices = (): JSX.Element => {
        return (
            <>
                {storeDetails && (
                    <StoreHoursInfo
                        storeDetails={storeDetails}
                        storeHoursListContent={storeHoursListContent}
                        storeId={storeId}
                        separator={separator}
                    />
                )}
            </>
        )
    }

    /**
     * function to render Store Hours Services from AEM
     * @return {JSX.Element}
     */

    const renderServicesFromAEM = (): JSX.Element => {
        return (
            <div className={`${PREFIX}-store-details__hours-info`}>
                {props.accordionItems &&
                    props.accordionItems.map((item: AccordionList, index: number) => (
                        <Accordion key={index} title={item.itemLabel} isHeaderOpen={false}>
                            {item.additionalCopy && (
                                <span
                                    className={`${PREFIX}-store-details__hours-info--additional-Copy`}
                                    dangerouslySetInnerHTML={{ __html: item.additionalCopy }}
                                />
                            )}
                        </Accordion>
                    ))}
            </div>
        )
    }

    /**
     * function to return concatenated string containing town, region and postal code
     * @return {string}
     */
    const getAddressLine3 = () => {
        if (!address) {
            return ''
        }

        const addressParts = []
        const { town, region, postalCode } = address
        town && addressParts.push(town)
        region && region.name && addressParts.push(region.name)
        postalCode && addressParts.push(postalCode)

        return addressParts.join(', ')
    }

    const renderAutoServiceButton = (): JSX.Element | null => {
        return isAutoServiceAvailableInStore(storeDetails) ? <AutoServiceButton storeNumberForFrame={storeId} /> : null
    }

    /**
     * function to return StoreTitle value
     * @return {string}
     */
    const getStoreTitle = (): string => {
        return !!storeDetails?.seoTag ? storeDetails?.seoTag : storeDetails?.displayName
    }

    /**
     * function to render Store Address section
     * @return {JSX.Element}
     */
    const renderStoreAddress = (): JSX.Element => {
        const showPreferredStoreButton = storeDetails?.baseStoreId ? storeDetails?.baseStoreId === baseStoreId : true
        const storeTimingsPresent = isStoreOpen ? closeHours : openHours
        return (
            <div className={`${PREFIX}-store-details__address`}>
                {!!storeTimingsPresent && (
                    <div className={`${PREFIX}-store-status`}>
                        <span className={`${PREFIX}-store-open`}>{isStoreOpen ? openLabel : closeLabel}</span> ⋅{' '}
                        <span className={`${PREFIX}-store-timings`}>{storeTimings()}</span>
                    </div>
                )}
                <h1>{getStoreTitle()}</h1>
                <p>{address?.line1}</p>
                <p>{address?.line2}</p>
                <p>{getAddressLine3()}</p>
                {redirectLink && (
                    <a
                        href={redirectLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        data-link-value={props.getDirCTALabel}>
                        {props.getDirCTALabel}
                        <Icon type="ct-chevron-right" size="md" />
                    </a>
                )}
                <a
                    className={`${PREFIX}-store-contact`}
                    aria-label={`${accessibility?.a11yTelephoneAriaLabel} ${formatedPhoneNumberAccessibility(
                        address.phone,
                    )}`}
                    href={`tel://` + address?.phone}
                    data-link-value={address?.phone}>
                    {getFormattedPhoneNumber(address?.phone)}
                </a>
                {!storeDetails.onlineOrdering && (
                    <div className={`${PREFIX}-store-details__status`}>
                        <div>
                            <Icon type="ct-notification-caution-stroked" size="md" />
                        </div>
                        <span className={`${PREFIX}-store-details__status-message`}>{onlineOrdersNotAcceptedMsg}</span>
                    </div>
                )}
                {renderAutoServiceButton()}
                {!isPreferredStore && showPreferredStoreButton && (
                    <Button
                        id="btnSetStore"
                        type="secondary"
                        size="small"
                        onClick={() => setPreferredStore()}
                        buttonType="submit"
                        quantumMetricAttribute={{ type: 'allow', value: 'true' }}>
                        {props.setPreferredCTALabel}
                    </Button>
                )}
            </div>
        )
    }

    /**
     * function called on click of allow location
     * @param {Position} position
     */
    const getCurrentLocation = (position: Position): void => {
        const { latitude, longitude } = position.coords
        dispatch(
            storeLatLongDetails({
                latitude,
                longitude,
            }),
        )
    }

    /**
     * function called on click of block location or closing popup without any button click
     * @param {string} error
     */
    const disallowLocation = (error?: Error): void => {
        // TODO: Create a toast message alerting customers about location settings in browser.
        console.error(`Browser geolocation error !\n\nPosition unavailable ${error?.message ?? ''}`)
    }

    /**
     * function to set preferred store on location icon clicked
     */
    const setPreferredStoreOnMapIconClicked = (): void => {
        navigatorService.getCurrentPosition(getCurrentLocation, disallowLocation)
        dispatch(analyticsInteraction(geolocation, ' ', interaction, searchStore, storeName, buyBoxLocation))
    }

    const renderSkeleton = (): JSX.Element => {
        return <SkeletonComponent skeletonClass={`${PREFIX}-store-details__skeleton`} />
    }

    /**
     * function to render schema Elements/Tag for LocalBusiness and  Department Store
     * @callback requestCallback
     * @param {requestCallback} schemaName
     * @return {JSX.Element | null}
     */

    const renderSchemas = (
        schemaName: (storeDetails: StoreWithAvailability, logoURL: string) => string | undefined,
    ): JSX.Element | null => {
        const storeDetailSchema = schemaName(storeDetails, CTCLogoURL)
        return !!storeDetailSchema ? (
            <SanitizeStringContentWrapper stringContent={`${storeDetailSchema}`}>
                {memoizedStringContent => (
                    <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: memoizedStringContent }} />
                )}
            </SanitizeStringContentWrapper>
        ) : null
    }

    return (
        <>
            {checkDataLength(storeDetails) ? (
                <div className={`${PREFIX}-store-details`}>
                    <div className={`${PREFIX}-row ${PREFIX}-container`}>
                        <div
                            className={`${PREFIX}-store-details__text-content ${PREFIX}-col-xs-6 ${PREFIX}-col-sm-6 ${PREFIX}-col-md-4`}>
                            {renderStoreAddress()}
                            {renderPreferredStore(isPreferredStore)}
                        </div>
                        {newMapProps && (
                            <div
                                className={`${PREFIX}-store-details__map-content ${PREFIX}-col-xs-6 ${PREFIX}-col-sm-6 ${PREFIX}-col-md-8`}>
                                <Map
                                    {...newMapProps}
                                    setPreferredStoreOnMapIconClicked={setPreferredStoreOnMapIconClicked}
                                    a11yMapLocateMeButtonLabel={accessibility?.a11yMapLocateMeButtonLabel}
                                />
                            </div>
                        )}
                    </div>
                    {renderSchemas(getDepartmentStoreSchema)}
                    {renderSchemas(getLocalBusinessSchema)}
                    {(storeDetails.services || storeDetails.storeServices || props.accordionItems) &&
                        renderStoreHoursAndServices()}
                </div>
            ) : (
                renderSkeleton()
            )}
        </>
    )
}

StoreDetailsComp.propTypes = {
    getDirCTALabel: PropTypes.string,
    setPreferredCTALabel: PropTypes.string,
    preferredStoreLabel: PropTypes.string,
    updateCTALabel: PropTypes.string,
    updateCTAURL: PropTypes.string,
    hoursInfoTitle: PropTypes.string.isRequired,
    accordionItems: PropTypes.array.isRequired,
    servicesTitle: PropTypes.string,
    flyerCTAURL: PropTypes.string.isRequired,
    contactPosition: PropTypes.string.isRequired,
    closesInLabel: PropTypes.string.isRequired,
    holidayMsg: PropTypes.string.isRequired,
    closedLabel: PropTypes.string.isRequired,
    phoneLabel: PropTypes.string.isRequired,
    feedbackCTALabel: PropTypes.string.isRequired,
    flyerCTALabel: PropTypes.string.isRequired,
    faxLabel: PropTypes.string.isRequired,
    contactLabel: PropTypes.string.isRequired,
    collapseLabel: PropTypes.string,
    expandLabel: PropTypes.string,
    onlineOrdersNotAcceptedMsg: PropTypes.string,
    storeHoursLabel: PropTypes.string,
}

export default seoHoc(StoreDetailsComp, pageTypes.store)
