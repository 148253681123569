import React, { useEffect, useState, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { magicNumber } from '../../utils/magicNumber'
import { BREAKPOINTS, PREFIX } from '../config'
import { DropdownList, DropdownOptionItem, DropdownProps } from './Dropdown.type'
import Icon from '../Icon'
import { useClickOutsideClose } from '../Tooltip/useClickOutside'
import Overlay from '../Overlay/Overlay'
import {
    ARROW_UP_KEYCODE,
    ARROW_DOWN_KEYCODE,
    CHANGE_INDEX,
    ESCAPE_KEYCODE,
    TAB_KEYCODE,
    UPPERCASE_A_KEYCODE,
    UPPERCASE_Z_KEYCODE,
    LOWERCASE_A_KEYCODE,
    LOWERCASE_Z_KEYCODE,
} from './Dropdown.constant'
import Button from '../Button'
import DropdownItem from './DropdownItem/DropdownItem'
import DropdownButton from './DropdownButton/DropdownButton'

/**
 * Dropdown component
 *
 * @param {DropdownProps} props - dropdownTitle,dropdownList,dropdownIcon,
        size,dropdownId,path,selectedItem,type,filterTitle,isFilter,
        closeButtonLabel,isMobile,error,isDatePicker,isDsm,
 * @return {JSX.Element} returns Dropdown component
 */
const Dropdown: React.FC<DropdownProps> = ({ ...props }) => {
    const {
        dropdownTitle,
        dropdownList,
        dropdownIcon,
        size,
        dropdownId,
        path,
        selectedItem,
        type,
        filterTitle,
        isFilter,
        closeButtonLabel,
        isMobile,
        error,
        isDatePicker,
        isDsm,
        isOnlyErrorStyle = false,
        disabled,
        isChangeColourDropdown,
        buttonContent,
        resetDropdown,
        ariaLabel,
        enableEmptySelection,
        prePopulateFirstItem,
        quantumMetricAttribute,
        a11yDropDownNotSelected,
    } = props

    const getTitle = useCallback(() => {
        if (isFilter) {
            let selectedTitle = ''
            const dropdownSelectedItem = dropdownList?.find((item: DropdownList) => item.selected)
            if (dropdownSelectedItem) {
                selectedTitle = dropdownSelectedItem.label
            }
            return selectedTitle
        }
        return dropdownTitle
    }, [dropdownList, isFilter, dropdownTitle])

    /**
     * Function used to set dropdown selected element index
     * @return {number | null}
     */
    const setIndex = (): number | null => {
        if (isFilter) {
            let currentIndex = dropdownList?.findIndex((item: DropdownList) => item.selected)
            // if dropdownlist has an emptyItem, then the index for all items should be incremented
            if (enableEmptySelection) {
                // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                currentIndex = currentIndex + magicNumber.ONE
            }
            return currentIndex
        }

        return null
    }

    const [selected, setSelected] = useState(getTitle)
    const [opened, setOpened] = useState(isChangeColourDropdown || false)
    const [transformed, setTransformClass] = useState(false)
    const [currentItem, setCurrentItem] = useState(setIndex)
    const [selectedCurrentItem, setSelectedCurrentItem] = useState(setIndex)
    const dropdownRef = useRef<HTMLDivElement>(null)
    const dropdownButtonref = useRef<HTMLButtonElement>(null)
    const [shadowClass, setShadowClass] = useState('')
    const [sortOption, setSortOption] = useState<DropdownOptionItem>(null)
    const [dropIcon, setDropIcon] = useState(dropdownIcon)
    const [windowWidth, setWindowWidth] = useState(0)
    const [hexNumber, setHexNumber] = useState('')
    const dropdownListUpdate = dropdownList
    const updatedSelectedTitle = getTitle()

    const interactiveElements: NodeListOf<Element> = dropdownRef?.current?.querySelectorAll('a,button')
    const quantumMetricDataAttribute = quantumMetricAttribute?.type
        ? { [`data-qm-${quantumMetricAttribute?.type}`]: quantumMetricAttribute?.value }
        : {}
    /**
     * drop down click event
     * This function is used to close dropdown
     */
    const closeDropdown = (): void => {
        setOpened(false)
    }
    const dropdownListDependency = JSON.stringify(dropdownList)
    const windowWidthDependency = window.innerWidth
    useClickOutsideClose(dropdownRef, closeDropdown, opened, !isMobile)

    useEffect(() => {
        // Adding a new item to the list for those dropdowns that should allow an empty selection
        if (enableEmptySelection) {
            const emptyItem = {
                id: '-1',
                label: '',
                selected: false,
            }
            dropdownList.unshift(emptyItem)
        }
    }, [enableEmptySelection, dropdownList])

    useEffect(() => {
        if (!resetDropdown) {
            setSelected(dropdownTitle)
        }
    }, [resetDropdown, dropdownTitle])

    useEffect(() => {
        !opened && dropdownButtonref?.current?.focus()
    }, [opened])
    useEffect(() => {
        if (
            !isDatePicker &&
            updatedSelectedTitle &&
            updatedSelectedTitle.length &&
            updatedSelectedTitle !== dropdownTitle
        ) {
            setSelected(updatedSelectedTitle)
        } else {
            setSelected(getTitle())
            setTransformClass(false)
        }
    }, [dropdownListDependency, getTitle, dropdownTitle, updatedSelectedTitle, isDatePicker])

    useEffect(() => {
        setDropIcon(opened ? 'ct-chevron-up' : 'ct-chevron-down')
        if (opened && type === 'primary') {
            setShadowClass(`${PREFIX}-shadow--small`)
        } else if (type !== 'primary') {
            setShadowClass(`${PREFIX}-shadow--small`)
        } else {
            setShadowClass('')
        }
        if (isFilter && !opened && selected) {
            setTransformClass(true)
        }
    }, [type, opened, selected, isFilter])

    useEffect(() => {
        if (isDatePicker) {
            setSelected('')
            setCurrentItem(null)
            setSelectedCurrentItem(null)
            setTransformClass(false)
        }
    }, [isDatePicker, dropdownList])

    useEffect(() => {
        setWindowWidth(window.innerWidth)
    }, [windowWidthDependency])

    const runOnce = useRef(0)
    const itemSelect = useCallback(
        (item: DropdownOptionItem, index) => {
            // if the emptyItem is selected, then replace the id to send an empty value.
            if (item.id === '-1') {
                item.id = ''
            }
            if (isMobile) {
                if (!isFilter) {
                    setOpened(false)
                    selectedItem(item)
                } else {
                    setSortOption(item)
                }
            } else {
                setOpened(false)
                selectedItem(item)
            }
            setCurrentItem(index)
            setSelectedCurrentItem(index)
            setSelected(item.label)
            isChangeColourDropdown && setHexNumber(item.hexNumber)
            dropdownListUpdate.forEach(value => (value.selected = value.label === item.label))
        },
        [dropdownListUpdate, isChangeColourDropdown, isFilter, isMobile, selectedItem],
    )
    useEffect(() => {
        if (
            runOnce.current === magicNumber.ZERO &&
            dropdownListUpdate?.length === magicNumber.ONE &&
            !dropdownListUpdate[0].selected &&
            prePopulateFirstItem
        ) {
            itemSelect(dropdownListUpdate[magicNumber.ZERO], magicNumber.ZERO)
            runOnce.current = 1
        }
    }, [itemSelect, dropdownListUpdate, prePopulateFirstItem])
    /**
     * drop down click event
     * While clicking the dropdown setting the class to modal-open
     */
    const dropdownClicked = () => {
        if (isFilter && !opened && selected) setTransformClass(!transformed)
        setOpened(!opened)
        if (isMobile && isFilter) document.body.classList.add('modal-open')
    }

    const keyboardeventHandler = (event: React.KeyboardEvent) => {
        if (event.which === ARROW_DOWN_KEYCODE) {
            handleArrowDownKeycode(event)
        } else if (event.which === ESCAPE_KEYCODE || event.which === TAB_KEYCODE) {
            if (!selected) {
                setCurrentItem(null)
            }
            setOpened(false)
        } else if (
            (event.which >= UPPERCASE_A_KEYCODE && event.which <= UPPERCASE_Z_KEYCODE) ||
            (event.which >= LOWERCASE_A_KEYCODE && event.which <= LOWERCASE_Z_KEYCODE)
        ) {
            handleLetterKeycode(event)
        }
    }

    const handleArrowDownKeycode = (event: React.KeyboardEvent): void => {
        if (opened) {
            interactiveElements.forEach((element: Element) => {
                element.setAttribute('tabindex', '-1')
            })
            event.preventDefault()
            const item = document.getElementById(`${dropdownId}${selectedCurrentItem}`)
            if (item) {
                item.focus()
            } else {
                const nextItem = selectedCurrentItem ? selectedCurrentItem + CHANGE_INDEX : 0
                setSelectedCurrentItem(nextItem)
                const nextitem = document.getElementById(`${dropdownId}${nextItem}`)
                nextitem.focus()
            }
        } else {
            setOpened(true)
        }
    }

    const handleLetterKeycode = (event: React.KeyboardEvent): void => {
        const keyPressed = String.fromCharCode(event.which)
        if (opened) {
            selectNextMatchedItem(event, keyPressed)
        } else {
            setOpened(true)
        }
    }

    const selectNextMatchedItem = (event: React.KeyboardEvent, keyPressed: string): void => {
        let itemToFocusIndex = 0
        interactiveElements.forEach((element: Element) => {
            if (element.textContent.startsWith(keyPressed)) {
                event.preventDefault()
                setSelectedCurrentItem(itemToFocusIndex)
                const itemToFocus = document.getElementById(`${dropdownId}${itemToFocusIndex}`)
                itemToFocus.focus()
            } else {
                itemToFocusIndex++
            }
        })
    }

    const itemSelectKeyboardHandler = (event: React.KeyboardEvent) => {
        if (event.which === ARROW_DOWN_KEYCODE && selectedCurrentItem < dropdownListUpdate.length - CHANGE_INDEX) {
            event.preventDefault()
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            const nextItem = selectedCurrentItem + CHANGE_INDEX
            setSelectedCurrentItem(nextItem)
            const item = document.getElementById(`${dropdownId}${nextItem}`)
            item.focus()
        } else if (event.which === ARROW_UP_KEYCODE && selectedCurrentItem > 0) {
            event.preventDefault()
            const previousItem = selectedCurrentItem - CHANGE_INDEX
            setSelectedCurrentItem(previousItem)
            const item = document.getElementById(`${dropdownId}${previousItem}`)
            item.focus()
        } else if (event.which === ESCAPE_KEYCODE || event.which === TAB_KEYCODE) {
            if (!selected) {
                setCurrentItem(null)
            }
            setOpened(false)
        }
    }

    /**
     * close click event
     * removes modal class from mobile and set the dropdown to collapsed view
     */
    const closeClick = (): void => {
        if (isMobile) {
            document.body.classList.remove('modal-open')
        }
        setOpened(!opened)
        selectedItem(sortOption)
    }
    /**
     * @method isFilterSection : Method used for checking if the dropdown list needs to be filtered
     * @returns {boolean}
     */

    const isFilterSection = (): boolean => {
        return isMobile && isFilter
    }

    /**
     * function used to toggle error class
     */

    const errorClass = () => (error || isOnlyErrorStyle ? `${PREFIX}-dropdown__button--error` : '')

    /**
     * @method showError : Method used to show error elements
     * @returns {JSX.Element}
     */

    const showError = (): JSX.Element =>
        error && (
            <div className={`${PREFIX}-dropdown__error ${PREFIX}-dropdown-native__error`}>
                <Icon type="ct-warning" size="sm" path={path} />
                <span
                    className={`${PREFIX}-dropdown__error-text ${PREFIX}-dropdown-native__error-text`}
                    id={`${dropdownId}__error`}>
                    {error}
                </span>
            </div>
        )

    /**
     * @method closeButtonMobile : Method used to close dropdown for mobile
     * @returns {JSX.Element}
     */

    const closeButtonMobile = (): JSX.Element =>
        isFilter && (
            <div className={`${PREFIX}-dropdown__filter-close`}>
                <Button
                    type="tertiary"
                    size="small"
                    onClick={() => {
                        closeClick()
                    }}>
                    {closeButtonLabel}
                </Button>
            </div>
        )

    /**
     * @method nativeDropdownClick : Method used for sending selected category data to itemSelect method
     *  @param { string } category : refers to the category label
     *  @param { string } index : refers to the selected category index
     */

    const nativeDropdownClick = (category: string, index: number): void => {
        setTransformClass(true)
        const data = dropdownListUpdate.filter(item => item.label === category)[0]
        itemSelect(data, index)
    }

    /**
     * Returns the classname for showing option value as disabled according to isUnavailable value.
     * @param { DropdownList } item
     * @return { string }
     */
    const getDropdownOptionClass = (item: DropdownList): string => {
        return item.isUnavailable ? `${PREFIX}-dropdown__content--not-selected` : `${PREFIX}-dropdown__content--default`
    }

    /**
     * Checks if modifier class is present then will append modifier class
     * else will return empty string
     * @return {string}
     */
    const getModifierClass = (): string => {
        return !!props.modifierClass ? props.modifierClass : ''
    }

    const renderNativeDropdown = (): JSX.Element => {
        return (
            <>
                <div
                    {...quantumMetricDataAttribute}
                    id={dropdownId}
                    className={`${PREFIX}-dropdown-native ${
                        size === 'default' ? `${PREFIX}-dropdown--${size}-mobile` : `${PREFIX}-dropdown--${size}`
                    }`}>
                    <select
                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                            nativeDropdownClick(event.currentTarget.value, event.currentTarget.selectedIndex)
                        }}
                        onBlur={() => false}
                        disabled={disabled}
                        className={`${PREFIX}-dropdown-native__select-overlay`}
                        id="native-select">
                        {!selected ? (
                            <>
                                <option key={0} value={filterTitle} aria-label={ariaLabel}>
                                    {filterTitle}
                                </option>
                                {dropdownList?.map((item: DropdownList, index: number) => (
                                    <option key={index} value={item.label} className={getDropdownOptionClass(item)}>
                                        {item.label}
                                    </option>
                                ))}
                            </>
                        ) : (
                            dropdownList?.map((item: DropdownList, index: number) => (
                                <option
                                    key={index}
                                    value={item.label}
                                    selected={item.selected}
                                    className={getDropdownOptionClass(item)}>
                                    {item.label}
                                </option>
                            ))
                        )}
                    </select>
                    <DropdownButton
                        title={selected}
                        icon={dropIcon}
                        path={path}
                        disabled={disabled}
                        dropdownClick={() => {
                            dropdownClicked()
                        }}
                        keyDownEvent={event => {
                            keyboardeventHandler(event)
                        }}
                        transformLabel={transformed}
                        filterTitle={filterTitle}
                        isFilter={isFilter}
                        opened={opened}
                        isDsm={isDsm}
                        errorClass={errorClass()}
                        quantumMetricAttribute={quantumMetricAttribute}
                        id={error ? dropdownId : ''}
                        ariaLabel={ariaLabel}
                    />
                    {showError()}
                </div>
            </>
        )
    }

    /**
     * dropdown component
     * @return {JSX.Element} returns customdropdown
     */

    const renderCustomDropdown = (): JSX.Element => {
        return (
            <div
                className={`${PREFIX}-dropdown ${shadowClass} ${getModifierClass()} ${
                    isDsm ? `${PREFIX}-dropdown--highlight` : ''
                } ${size === 'default' ? `${PREFIX}-dropdown--${size}-desktop` : `${PREFIX}-dropdown--${size}`}`}
                {...quantumMetricDataAttribute}
                id={dropdownId}>
                <DropdownButton
                    title={selected}
                    icon={dropIcon}
                    path={path}
                    disabled={disabled}
                    dropdownClick={() => {
                        dropdownClicked()
                    }}
                    keyDownEvent={event => {
                        keyboardeventHandler(event)
                    }}
                    transformLabel={transformed}
                    filterTitle={filterTitle}
                    isFilter={isFilter}
                    opened={opened}
                    isDsm={isDsm}
                    errorClass={errorClass()}
                    hexNumber={hexNumber}
                    buttonContent={buttonContent}
                    dropdownButtonref={dropdownButtonref}
                    ariaLabel={ariaLabel}
                    quantumMetricAttribute={quantumMetricAttribute}
                    id={error ? dropdownId : ''}
                />
                {isFilterSection() && opened ? <Overlay /> : null}

                <div
                    className={` ${
                        isFilterSection() ? `${PREFIX}-dropdown__filter` : `${PREFIX}-dropdown__content`
                    } ${PREFIX}-shadow--small ${isFilter ? `${PREFIX}-dropdown__content-sort` : ''}
                ${opened ? 'show' : 'hide'}`}
                    ref={dropdownRef}>
                    {isFilterSection() ? <div className={`${PREFIX}-dropdown__filter-title`}>{filterTitle}</div> : null}
                    {dropdownListUpdate?.map((item: DropdownList, index: number) => (
                        <DropdownItem
                            index={index}
                            path={path}
                            key={index}
                            item={item}
                            currentItem={currentItem}
                            itemSelect={() => {
                                itemSelect(item, index)
                            }}
                            isMobile={isMobile}
                            isFilter={isFilter}
                            itemSelectKeyBoardEvent={event => {
                                itemSelectKeyboardHandler(event)
                            }}
                            dropdownId={dropdownId}
                            buttonContent={buttonContent}
                            a11yAriaLabel={item?.id === '-1' ? a11yDropDownNotSelected : item.label}
                        />
                    ))}
                    {closeButtonMobile()}
                </div>
                {showError()}
            </div>
        )
    }

    return (
        <>
            {windowWidth <= BREAKPOINTS.mobileMaxWidth && !isChangeColourDropdown
                ? renderNativeDropdown()
                : renderCustomDropdown()}
        </>
    )
}

Dropdown.propTypes = {
    dropdownTitle: PropTypes.string,
    dropdownList: PropTypes.any,
    dropdownIcon: PropTypes.string,
    size: PropTypes.oneOf(['mini', 'small', 'medium', 'large', 'default', 'xsmall']),
    outsideClick: PropTypes.any,
    dropdownId: PropTypes.string,
    path: PropTypes.string,
    selectedItem: PropTypes.func,
    type: PropTypes.string,
    filterTitle: PropTypes.string,
    isFilter: PropTypes.bool,
    closeButtonLabel: PropTypes.string,
    index: PropTypes.number,
    isMobile: PropTypes.bool,
    error: PropTypes.string,
    isDatePicker: PropTypes.bool,
    isDsm: PropTypes.bool,
    isDropdownValueSelected: PropTypes.string,
    isOnlyErrorStyle: PropTypes.bool,
    disabled: PropTypes.bool,
    isChangeColourDropdown: PropTypes.bool,
    buttonContent: PropTypes.func,
    resetDropdown: PropTypes.bool,
    ariaLabel: PropTypes.string,
    enableEmptySelection: PropTypes.bool,
    prePopulateFirstItem: PropTypes.bool,
    modifierClass: PropTypes.string,
}

export default Dropdown
