import { PREFIX } from '../../config'

// This file contains navigation constants

export const navigationConst = {
    primaryNavigation: `.${PREFIX}-primary-navigation`,
    primaryNavigationSticky: `${PREFIX}-primary-navigation__sticky`,
    primaryNavigationStickyStoreLocator: `${PREFIX}-primary-navigation__sticky_store-locator`,
    primaryNavigationStickyNav: `${PREFIX}-primary-navigation__sticky_nav`,
    secondaryNavigation: `.${PREFIX}-secondary-navigation`,
    secondaryNavigationSticky: `${PREFIX}-secondary-navigation__sticky`,
    storeLocatorPencilBanner: `.store-locator-pencil-banner`,
    stickyTOC: `.${PREFIX}-Scrollspy-nav`,
    stickyBuyBox: `.${PREFIX}-sticky-buy-box`,
    stickyBuyBar: `.${PREFIX}-sticky-buy-bar`,
    footer: `div[role="contentinfo"]`,
    footerLinks: '.footer-links',
}
