export const SET_IS_INITIAL_DROP_DOWNS_FETCH_IN_PROGRESS = 'SET_IS_INITIAL_DROP_DOWNS_FETCH_IN_PROGRESS'
export const SET_IS_INITIAL_DROP_DOWNS_RESPONSE_DATA_FETCHED = 'SET_IS_INITIAL_DROP_DOWNS_RESPONSE_DATA_FETCHED'
export const SET_REMOVED_VEHICLE = 'SET_REMOVED_VEHICLE'
export const SET_VEHICLE_KNOWN_VALUES = 'SET_KNOWN_VALUES'
export const SET_VEHICLE_KNOWN_VALUES_AND_CLEAR_OTHER = 'SET_VEHICLE_KNOWN_VALUES_AND_CLEAR_OTHER'
export const SET_VEHICLE_BASE_ATTRIBUTES_ORDER = 'SET_VEHICLE_BASE_ATTRIBUTES_ORDER'
export const SET_CLEAR_TO_VEHICLE_ATTRIBUTE = 'SET_CLEAR_TO_VEHICLE_ATTRIBUTE'
export const SET_VEHICLE_RESPONSE = 'SET_VEHICLE_RESPONSE'
export const SET_VEHICLE_LAZY_LOAD_RESPONSE = 'SET_VEHICLE_LAZY_LOAD_RESPONSE'
export const SET_IS_VEHICLE_FETCH_IN_PROGRESS = 'SET_IS_VEHICLE_FETCH_IN_PROGRESS'
export const SET_SAVED_VEHICLE_CHECKED = 'SET_SAVED_VEHICLE_CHECKED'
export const SET_VEHICLE_DROPDOWN_LOCALIZATION = 'SET_VEHICLE_DROPDOWN_LOCALIZATION'
