import React from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../../../config'
import { SearchModalProps } from './SearchModal.type'
import SearchBarWrapper from '../SearchBarWrapper/SearchBarWrapper'

/**
 * SearchModal component
 * @param {SearchModalProps} props -  searchPlaceholderText, showModal, searchIconSize, closeModal, path, closeIconSize, searchCallBack, searchPagePath, a11ySearchIconLabel,
 * @return {JSX.Element} returns SearchModal component
 */
const SearchModal: React.FC<SearchModalProps> = props => {
    const { searchBoxPlaceholder, showModal } = props

    return (
        <div className={`${PREFIX}-search`}>
            {showModal && (
                <div className={`${PREFIX}-search__modal`}>
                    <div className={`${PREFIX}-row`}>
                        <SearchBarWrapper
                            {...props}
                            searchBoxPlaceholder={searchBoxPlaceholder}
                            showCloseButton={true}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

SearchModal.propTypes = {
    searchBoxPlaceholder: PropTypes.string.isRequired,
    showModal: PropTypes.bool.isRequired,
    searchIconSize: PropTypes.string.isRequired,
    closeModal: PropTypes.any,
    path: PropTypes.string,
    closeIconSize: PropTypes.string.isRequired,
    searchCallBack: PropTypes.any,
    searchPagePath: PropTypes.string.isRequired,
    a11ySearchIconLabel: PropTypes.string.isRequired,
    allyCloseIconLabel: PropTypes.string.isRequired,
    a11yUpdateQueryIconLabel: PropTypes.string.isRequired,
    autoCompleteUrl: PropTypes.string.isRequired,
    minChars: PropTypes.string.isRequired,
    delay: PropTypes.string.isRequired,
    count: PropTypes.string.isRequired,
    minCatLevel: PropTypes.string.isRequired,
    maxNumCats: PropTypes.string.isRequired,
    ctrPriceUrl: PropTypes.string.isRequired,
}

export default SearchModal
