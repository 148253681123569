import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
    ErrorTriangleMCBanner,
    TriangleMCBannerProps,
    IndividualBannerDetails,
    CardPropsType,
    LineSummaryType,
} from './TriangleMCBanner.types'
import {
    useGlobalScrollEvent,
    libUtils,
    SkeletonComponent,
    SnippetCard,
    replaceStrWithDynamicVal,
    magicNumber,
    getBalance,
    removeHtmlTagsFromString,
    checkStateProvince,
    LineSummary,
    checkDataLength,
} from '@nl/lib'
import { PREFIX, userState, priceCategory } from '../../config'
import { bannerTypeConstants, imagePosition, SelectBannerType } from './TriangleMCBanner.constants'
import triangleMCBannerService from '../../services/triangleMCBanner/triangleMCBanner.service'
import { gigyaLoadFunctions } from '../GigyaScreen/GigyaScreen'
import { gigyaScreens } from '../GigyaScreen/gigya.constants'
import checkNestedProps from '../../utils/checkNestedProps'
import { useDispatch, useSelector } from 'react-redux'
import { onBannerViewAnalytics, onBannerClickAnalytics } from '../../analytics/components/triangleMCBannerAnalytics'
import { MagicNumber } from './../../analytics/analytics.type'
import { cartguidParam } from '../../services/cartService/cart.service.constants'
import appCacheService from '../../utils/appCacheService'
import { HttpRequestState } from '../../httpClient/client.type'
import { GlobalPropsHelper } from '../../analytics/helpers/globalProps'
import { getEnvironment } from '../../environments'
import { DevConfig } from '../../environments/dev.environment'
import { fetchInterceptorPartialResponse, setOgImageTag, setShowSpinner } from '../../redux/actionCreators'
import { ToastComponentNames } from '../../redux/models/toastMessage.interface'
import { dispatchToast } from '../ToastMessage/ToastMessage.helper'
import { globalPartialAuthCode, netWorkErrorCode, timeoutErrorCode } from '../../globalConstants/cdsErrorCodes'
import { commonContentSelector, commonAPIContentAvailableSelector } from '../../redux/selectors/commonContent.selectors'
import {
    isAuthFlowExecutedSelector,
    signOutSuccessSelector,
    userProfileDataSelector,
} from '../../redux/selectors/userProfile.selectors'
import { findBannerStyleDetails, findBannerDetails, getPriceCategory, appendUtmParams } from './TriangleMCBanner.helper'
import { getUserType } from '../../utils/getUserType'
import { cartItemsDataSelector, xhrInfoSelector } from '../../redux/selectors/cart.selectors'
import { preferredStoreDetailsSelector } from '../../redux/selectors/storeDetails.selectors'
import { partialResponseDataSelector } from '../../redux/selectors/errorInterceptorData.selectors'
import { StoresProvinceList, isTriangleMastercardLinked, separatorProps } from '../../globalConstants/global.constant'

const environment: DevConfig = getEnvironment() as DevConfig

const TriangleMCBanner: React.FC<TriangleMCBannerProps> = props => {
    const dispatch = useDispatch()
    const {
        bannerType,
        bannerTitle,
        bannerDescription,
        bannerImage,
        bannerImageAltText,
        bannerImageOrientation = imagePosition.imageTopRight,
        displayImageOnMobile,
        backgroundColour,
        fontColour,
        saveStoryText,
        saveStoryType,
        saveStoryValue,
        ctaTitle,
        pcid,
        showTriangleBanner,
        applyNowErrorMessage,
        selectBanner,
        ctaLink,
        ctaA11yLabel,
        ctaLinkTarget,
        monthlyPriceLabel,
        numberOfMonths,
        ctMoneyTooltipHeading,
        ctMoneyTooltipDescription,
        paymentInstallmentTooltipHeading,
        paymentInstallmentTooltipDescription,
        creditOfferedTooltipHeadingQuebec,
        creditOfferedTooltipDescQuebec,
        creditOfferedMessageQuebec,
        utmList,
        upsellingPercentage,
        perMonthLabel,
        ctaTitleAdvanced,
    } = props

    const bannerStyleDetails = {
        bannerImage,
        bannerImageOrientation,
        fontColour,
    }

    const bannerDetailsProps = {
        bannerTitle,
        bannerDescription,
        bannerImage,
        bannerImageAltText,
        backgroundColour,
        displayImageOnMobile,
    }

    const signOutSuccess = useSelector(signOutSuccessSelector)
    const userProfileData = useSelector(userProfileDataSelector)
    const isAuthFlowExecuted = useSelector(isAuthFlowExecutedSelector)
    const profileDataAvailability = userProfileData && Object.values(userProfileData).length > 0
    const { commonContentAvailable } = useSelector(commonContentSelector)
    const cartItemsData = useSelector(cartItemsDataSelector)
    const xhrInfo = useSelector(xhrInfoSelector)
    const gigyaScreen = checkNestedProps(commonContentAvailable, 'authentication', 'loginScreenId') as string
    const commonContentProduct = checkNestedProps(commonContentAvailable, 'product')
    const lang = libUtils.getLanguage()

    const [isCartItems, setCartItems] = useState(false)
    const [showSkeleton, setShowSkeleton] = useState(true)
    const [isUserGuest, setIsUserGuest] = useState<boolean>(false)
    const partialResponseData = useSelector(partialResponseDataSelector)

    const authenticatedUser = userProfileData ? Boolean(Object.keys(userProfileData).length) : false
    const isLoyaltyUser = userProfileData?.loyalty?.cardNumber ? true : false
    const rewardsCardType = userProfileData?.triangleType?.rewardsCardType as string
    const linkedFlag = userProfileData?.linkedFlag === isTriangleMastercardLinked
    const preferredStoreDetails = useSelector(preferredStoreDetailsSelector)
    const isoCode = preferredStoreDetails?.address?.region?.isocode
    const { canadapost } = useSelector(commonAPIContentAvailableSelector)
    const isQuebecRegion = checkStateProvince(isoCode, canadapost?.Country, StoresProvinceList.qc)
    const userType = getUserType(authenticatedUser, isLoyaltyUser, rewardsCardType, linkedFlag)
    const cartGUID = appCacheService.getCartGuid()
    const noGuid = !(window.location.search.includes(cartguidParam) || !!cartGUID)
    const isLoggedIn = isAuthFlowExecuted && !!profileDataAvailability
    const bannerRef = useRef<HTMLDivElement>(null)
    const anchorRef = useRef<HTMLAnchorElement>(null)
    const componentId = `${PREFIX}-triangleMCBanner`
    const cautionIcon = 'ct-notification-caution'
    const globalProps = new GlobalPropsHelper()
    const configs = globalProps.init()
    const requestingSystem = configs.requestingSystem
    const cartSummary = cartItemsData?.cart?.cartSummary
    const totalPrice = cartSummary?.subTotalAmt.value as number
    const totalAmtWithTax = cartSummary?.totalWithTaxesAmt.value as number
    const percentageValue = upsellingPercentage as number
    const ctMoneyCashBackValue = getBalance(((percentageValue / magicNumber.HUNDRED) * totalPrice).toString())
    const monthlyEMILabel = `${replaceStrWithDynamicVal(monthlyPriceLabel, numberOfMonths)}`
    const monthlyPayment = getBalance((totalAmtWithTax / numberOfMonths).toString())
    const monthlyEMIValue = `${replaceStrWithDynamicVal(perMonthLabel, monthlyPayment)}`

    const [accessibility, setAccessibility] = useState(
        commonContentAvailable?.accessibility || ({} as typeof commonContentAvailable.accessibility),
    )
    useEffect(() => {
        commonContentAvailable?.accessibility && setAccessibility(commonContentAvailable.accessibility)
    }, [commonContentAvailable])

    useEffect(() => {
        if (bannerImage) {
            dispatch(setOgImageTag({ component: 'TriangleMCBannerComponent', imageLink: bannerImage }))
        }
    }, [bannerImage, dispatch])

    const isBannerTop = (): boolean => {
        let isTop = false
        if (window.pageYOffset < document.body.offsetHeight / MagicNumber.TWO) {
            isTop = true
        }
        return isTop
    }

    const [firstLoadAnalyticsFired, setFirstLoadAnalyticsFired] = useState<boolean>(true)
    const isInViewport = (): boolean => {
        const bannerBounding = bannerRef.current?.getBoundingClientRect()
        return bannerBounding
            ? bannerBounding.top >= 0 &&
                  bannerBounding.left >= 0 &&
                  bannerBounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                  bannerBounding.right <= (window.innerWidth || document.documentElement.clientWidth)
            : false
    }

    const handleAnalyticsOnView = useCallback((): void => {
        if (isInViewport() && firstLoadAnalyticsFired) {
            onBannerViewAnalytics(bannerType, isBannerTop() ? 'Top' : 'Bottom')
            setFirstLoadAnalyticsFired(false)
        }
    }, [firstLoadAnalyticsFired, bannerType])

    handleAnalyticsOnView()

    useEffect(() => {
        if (cartItemsData && Object.keys(cartItemsData).length) {
            setShowSkeleton(false)
            cartItemsData.cart.numberOfItems > 0 ? setCartItems(true) : setCartItems(false)
        }
    }, [cartItemsData, setCartItems, isCartItems])

    const getCartApiStatus =
        xhrInfo?.getCartInfo === HttpRequestState.done || xhrInfo?.getCartInfo === HttpRequestState.failed
    useEffect(() => {
        if (
            (isLoggedIn && getCartApiStatus) ||
            (isAuthFlowExecuted && !isLoggedIn && noGuid) ||
            (!noGuid && getCartApiStatus)
        ) {
            setShowSkeleton(false)
        }
    }, [isLoggedIn, isAuthFlowExecuted, noGuid, getCartApiStatus])

    /**
     * To get the summary line
     * @param {string} title
     * @param {string} value
     *  @param {string} tooltipHeading
     *  @param {string} tooltipDesc
     *  @param {boolean} showTooltipWithTitle
     * @return {LineSummaryType}
     */

    const getSummaryLine = useCallback(
        (
            title: string,
            value: string,
            tooltipHeading: string,
            tooltipDesc: string,
            showTooltipWithTitle: boolean,
        ): LineSummaryType => {
            return {
                title: title,
                tooltip: {
                    title: removeHtmlTagsFromString(tooltipHeading),
                    desc: tooltipDesc,
                    a11yTooltipIcon: accessibility?.a11yTooltipIcon,
                    a11yCloseIconLabel: accessibility?.a11yCloseIconLabel,
                },
                showTooltipWithTitle: showTooltipWithTitle,
                value: value,
            }
        },
        [accessibility],
    )

    /**
     * Appending lineSummary to cardProps based on price and location
     * @param {CardPropsType} cProps
     * @return {CardPropsType}
     */
    const getPropsBasedOnProductPriceAndLocation = useCallback(
        (cProps: CardPropsType): CardPropsType => {
            const pCategory = getPriceCategory(Math.floor(totalPrice), commonContentProduct)
            const ctMoneyCashBackLabel = `${replaceStrWithDynamicVal(bannerDescription, `${percentageValue}`)}`
            const cashBackLineSummaryWithoutValue = getSummaryLine(
                ctMoneyCashBackLabel,
                '',
                ctMoneyTooltipHeading,
                ctMoneyTooltipDescription,
                true,
            )
            const cashBackLineSummaryWithValue = getSummaryLine(
                ctMoneyCashBackLabel,
                ctMoneyCashBackValue,
                ctMoneyTooltipHeading,
                ctMoneyTooltipDescription,
                false,
            )
            const emiLineSummaryOtherLoc = getSummaryLine(
                monthlyEMILabel,
                monthlyEMIValue,
                paymentInstallmentTooltipHeading,
                paymentInstallmentTooltipDescription,
                false,
            )
            const emiLineSummaryQuebecLoc = getSummaryLine(
                creditOfferedMessageQuebec,
                '',
                creditOfferedTooltipHeadingQuebec,
                creditOfferedTooltipDescQuebec,
                true,
            )
            switch (true) {
                case pCategory === priceCategory.THRESHOLD1:
                    cProps = { ...cProps, lineSummary: [cashBackLineSummaryWithoutValue] }
                    break
                case pCategory === priceCategory.THRESHOLD2:
                    cProps = { ...cProps, lineSummary: [cashBackLineSummaryWithValue] }
                    break
                case pCategory === priceCategory.THRESHOLD3 && !isQuebecRegion:
                    cProps = { ...cProps, lineSummary: [cashBackLineSummaryWithValue, emiLineSummaryOtherLoc] }
                    break
                case pCategory === priceCategory.THRESHOLD3 && isQuebecRegion:
                    cProps = { ...cProps, lineSummary: [cashBackLineSummaryWithValue, emiLineSummaryQuebecLoc] }
                    break
            }
            return cProps
        },
        [
            bannerDescription,
            commonContentProduct,
            creditOfferedMessageQuebec,
            creditOfferedTooltipDescQuebec,
            creditOfferedTooltipHeadingQuebec,
            ctMoneyCashBackValue,
            ctMoneyTooltipDescription,
            ctMoneyTooltipHeading,
            getSummaryLine,
            isQuebecRegion,
            monthlyEMILabel,
            monthlyEMIValue,
            paymentInstallmentTooltipDescription,
            paymentInstallmentTooltipHeading,
            percentageValue,
            totalPrice,
        ],
    )

    /**
     * Determining cardProps based on user type
     * @return {CardPropsType}
     */
    const processCardProps = useMemo((): CardPropsType => {
        const defaulPropValues = {
            title: bannerTitle,
            imageOffSet: true,
            cta: {
                label: ctaTitleAdvanced,
                link: appendUtmParams(ctaLink, userType, utmList),
                ally: ctaA11yLabel,
                target: ctaLinkTarget,
            },
            image: bannerImage,
            imageAlt: bannerImageAltText,
            separatorProps: separatorProps,
        }
        let cardProps: CardPropsType
        switch (userType) {
            case userState.guest:
            case userState.noLoyaltyCard:
            case userState.loyaltyCard:
                cardProps = {
                    title: bannerTitle,
                    imageOffSet: true,
                    cta: {
                        label: ctaTitleAdvanced,
                        link: appendUtmParams(ctaLink, userType, utmList),
                        ally: ctaA11yLabel,
                        target: ctaLinkTarget,
                    },
                    image: bannerImage,
                    imageAlt: bannerImageAltText,
                    separatorProps: separatorProps,
                }
                cardProps = getPropsBasedOnProductPriceAndLocation(cardProps)
                break
            default:
                cardProps = defaulPropValues
                break
        }
        return cardProps
    }, [
        bannerImage,
        bannerImageAltText,
        bannerTitle,
        ctaA11yLabel,
        ctaLink,
        ctaLinkTarget,
        ctaTitleAdvanced,
        getPropsBasedOnProductPriceAndLocation,
        userType,
        utmList,
    ])

    /**
     * Builds medium and short banner type
     * @param {string} classNameType
     * @param {string} bannerTypeClassName
     * @param {boolean} showImage
     * @param {boolean} showSaveStory
     * @param {string} aemFontColor
     * @return {JSX.Element}
     */
    // eslint-disable-next-line complexity
    const buildMediumShortBanner = (
        classNameType: string,
        bannerTypeClassName: string,
        showImage: boolean,
        showSaveStory: boolean,
        aemFontColor: string,
    ): JSX.Element => {
        const imageMobileClass = displayImageOnMobile ? `` : `${bannerTypeClassName}__image-container--mobile`
        const imageClass = showImage ? `${bannerTypeClassName}__image-container--show` : ``
        const saveStoryImageClass = showImage ? `${bannerTypeClassName}__save-container--image` : ``
        const saveStoryClass = showSaveStory ? `` : `${bannerTypeClassName}__save-container--void`
        const saveStorySymbolPercentage = saveStoryType === '%'
        const saveStorySymbolDollar = saveStoryType === '$'
        const isFrench = lang === 'fr'

        return (
            <div
                id={componentId}
                className={`${classNameType} ${bannerTypeClassName}`}
                style={{ background: backgroundColour }}
                ref={bannerRef}>
                <div className={`${bannerTypeClassName}__container--image`}>
                    <div className={`${bannerTypeClassName}__image-container ${imageClass} ${imageMobileClass}`}>
                        <img alt={bannerImageAltText} src={bannerImage}></img>
                    </div>
                    <div
                        className={`${bannerTypeClassName}__save-container ${saveStoryImageClass} ${saveStoryClass}`}
                        tabIndex={0}
                        role="button">
                        <span className={`${bannerTypeClassName}__save-text`}>{saveStoryText}</span>
                        <span className={`${bannerTypeClassName}__save-amount`}>
                            {saveStorySymbolDollar && !isFrench && (
                                <span className={`${bannerTypeClassName}__symbol--dollar`}>{saveStoryType}</span>
                            )}
                            {saveStoryValue}
                            {saveStorySymbolPercentage && (
                                <span className={`${bannerTypeClassName}__symbol--percentage`}>{saveStoryType}</span>
                            )}
                            {saveStorySymbolDollar && isFrench && (
                                <span className={`${bannerTypeClassName}__symbol--dollar`}>{saveStoryType}</span>
                            )}
                        </span>
                    </div>
                </div>
                <div className={`${bannerTypeClassName}__container--text`}>
                    <div className={`${bannerTypeClassName}__text-container`} style={{ color: aemFontColor }}>
                        <div dangerouslySetInnerHTML={{ __html: bannerTitle || '' }} tabIndex={0} role="button"></div>
                        <div
                            dangerouslySetInnerHTML={{ __html: bannerDescription || '' }}
                            tabIndex={0}
                            role="button"></div>
                        {ctaTitle && (
                            <a
                                className={`${PREFIX}-button ${PREFIX}-button--primary ${PREFIX}-button--mini`}
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`#${componentId}`}
                                ref={anchorRef}
                                onClick={handleBannerClick}>
                                {ctaTitle.trim()}
                            </a>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    /**
     * Builds the slim type banner
     * @param {boolean} showImage
     * @param {string} aemFontColor
     * @param {string} bannerTypeClassName
     * @param {IndividualBannerDetails} bannerDetails
     * @return {JSX.Element}
     */
    const buildLongBanner = (
        showImage: boolean,
        aemFontColor: string,
        bannerTypeClassName: string,
        bannerDetails: IndividualBannerDetails,
    ): JSX.Element => {
        const imageClass = showImage ? `${componentId}-slim__image-container--show` : ``
        const imageMobileClass = bannerDetails.displayImageOnMobile
            ? `${componentId}-slim__image-container--mobile`
            : ``
        return (
            <div
                id={componentId}
                className={`${componentId}-slim${bannerTypeClassName}`}
                style={{ background: bannerDetails.backgroundColour }}
                ref={bannerRef}>
                <div className={`${componentId}-slim__image-container ${imageClass} ${imageMobileClass}`}>
                    <img alt={bannerDetails.bannerImageAltText} src={bannerDetails.bannerImage} />
                </div>
                <div className={`${componentId}-slim__text-container`} style={{ color: aemFontColor }}>
                    <div className={`${componentId}-slim__text-title`}>
                        <div dangerouslySetInnerHTML={{ __html: bannerDetails.bannerTitle || '' }} />
                    </div>
                    <div className={`${componentId}-slim__text-description`}>
                        <div dangerouslySetInnerHTML={{ __html: bannerDetails.bannerDescription || '' }} />
                    </div>
                </div>
                {selectBanner !== SelectBannerType.dynamicbannerultraslim && (
                    <div className={`${componentId}-slim__text-button`}>
                        {ctaTitle && (
                            <a
                                className={`${PREFIX}-button ${PREFIX}-button--primary ${PREFIX}-button--mini`}
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`#${componentId}`}
                                ref={anchorRef}
                                onClick={handleBannerClick}>
                                {ctaTitle.trim()}
                            </a>
                        )}
                    </div>
                )}
            </div>
        )
    }

    /**
     * Decides which type of default banner to build depending on bannerType ie; short, medium or long
     * @return {JSX.Element}
     */
    const buildDefaultMCBanner = (): JSX.Element => {
        let triangleMCBanner = <></>
        if (isCartItems) {
            let generalClassName = ''
            let bannerTypeClassName = ''
            const showSaveStory = !!saveStoryText
            const showImage = !!bannerImage
            const aemFontColor = fontColour === `${PREFIX}-banner--black` ? 'black' : 'white'
            const imageOrientation =
                bannerImageOrientation === imagePosition.imageTopRight ? '-topRight' : '-bottomLeft'

            if (bannerType === bannerTypeConstants.medium) {
                bannerTypeClassName = componentId
                generalClassName = `${bannerTypeClassName}${imageOrientation}`
                triangleMCBanner = buildMediumShortBanner(
                    generalClassName,
                    bannerTypeClassName,
                    showImage,
                    showSaveStory,
                    aemFontColor,
                )
            } else if (bannerType === bannerTypeConstants.short) {
                bannerTypeClassName = `${componentId}-short`
                generalClassName = `${bannerTypeClassName}${imageOrientation}`
                triangleMCBanner = buildMediumShortBanner(
                    generalClassName,
                    bannerTypeClassName,
                    showImage,
                    showSaveStory,
                    aemFontColor,
                )
            } else if (bannerType === bannerTypeConstants.long) {
                const bannerDetails = findBannerDetails(bannerDetailsProps)
                triangleMCBanner = buildLongBanner(showImage, aemFontColor, imageOrientation, bannerDetails)
            }
        }
        return triangleMCBanner
    }

    /**
     * Decides which ultraslim banner to display based on type of user
     * @return {JSX.Element | null}
     */
    const buildUltraSlimBanner = (): JSX.Element | null => {
        const guestBannerDetails = findBannerDetails(bannerDetailsProps)
        const bannerAlignmentDetails = findBannerStyleDetails(bannerStyleDetails)
        const { showImage, fontColor, imgOrientation } = bannerAlignmentDetails
        const aemFontColor = fontColor === `${PREFIX}-banner--black` ? 'black' : 'white'
        const imageOrientation = imgOrientation === imagePosition.imageTopRight ? '-topRight' : '-bottomLeft'
        const dynamicBannerClassname = imageOrientation + ` ${PREFIX}-dynamicBanner`
        let ultraSlimBanner: JSX.Element | null
        switch (userType) {
            case userState.guest:
            case userState.loyaltyCard:
            case userState.noLoyaltyCard:
                ultraSlimBanner = buildLongBanner(showImage, aemFontColor, dynamicBannerClassname, guestBannerDetails)
                break
            default:
                ultraSlimBanner = null
                break
        }
        return ultraSlimBanner
    }

    /**
     * Decides which type of banner to build - to be taken up as part of MTE-1255
     * @return {JSX.Element | null}
     */
    const buildAdvancedMCBanner = (): JSX.Element | null => {
        if (isCartItems) {
            switch (userType) {
                case userState.guest:
                case userState.loyaltyCard:
                case userState.noLoyaltyCard:
                    return (
                        <div className={`${componentId}-advanced`}>
                            <SnippetCard {...processCardProps}>
                                {(processCardProps.imageOffSet || isLoyaltyUser) && (
                                    <div className={`${PREFIX}-snippet-card__lines`}>
                                        {processCardProps.lineSummary?.map(
                                            (item: LineSummaryType) =>
                                                !!item && (
                                                    <LineSummary
                                                        key={removeHtmlTagsFromString(item.title ?? '')}
                                                        title={<div dangerouslySetInnerHTML={{ __html: item.title }} />}
                                                        value={item.value}
                                                        isHeading={item?.isHeading}
                                                        tooltip={item.tooltip}
                                                        showTooltipWithTitle={item?.showTooltipWithTitle}
                                                    />
                                                ),
                                        )}
                                    </div>
                                )}
                                {processCardProps.cta && checkDataLength(processCardProps.cta) && (
                                    <a
                                        className={`${PREFIX}-button ${PREFIX}-button--secondary ${PREFIX}-button--mini`}
                                        target={processCardProps.cta?.target}
                                        rel="noopener noreferrer"
                                        href={processCardProps.cta?.link}
                                        aria-label={processCardProps.cta?.ally}>
                                        {processCardProps.cta?.label}
                                    </a>
                                )}
                            </SnippetCard>
                        </div>
                    )
                default:
                    return null
            }
        } else return null
    }

    /**
     * Decides which type of banner to build ie; defaultMCbanner , ultraslimbanner, advancedMCbanner
     * @return {JSX.Element | null}
     */
    const renderSelectBanner = (): JSX.Element | null => {
        switch (selectBanner) {
            case SelectBannerType.trianglemcbanner:
                return buildDefaultMCBanner()
            case SelectBannerType.dynamicbannerultraslim:
                return buildUltraSlimBanner()
            case SelectBannerType.trianglemcbanneradvanced:
                return buildAdvancedMCBanner()
            default:
                return buildDefaultMCBanner()
        }
    }

    /**
     * Appends utm props to the url
     * @param {string} url
     * @return {string}
     */
    const getApplyNowUrl = useCallback(
        (url: string): string => {
            return encodeURI(
                `${url}${pcid ? `&pcid=${pcid}` : ''}${props.utmSource ? `&utm_source=${props.utmSource}` : ''}${
                    props.utmMedium ? `&utm_medium=${props.utmMedium}` : ''
                }${props.utmCampaign ? `&utm_campaign=${props.utmCampaign}` : ''}${
                    props.utmTerm ? `&utm_term=${props.utmTerm}` : ''
                }${authenticatedUser && props.utmContent ? `&utm_content=${props.utmContent}` : ''}`,
            )
        },
        [authenticatedUser, pcid, props.utmCampaign, props.utmContent, props.utmMedium, props.utmSource, props.utmTerm],
    )

    useEffect(() => {
        if (signOutSuccess) {
            if (anchorRef.current) {
                anchorRef.current.href = `${componentId}`
            }
            setIsUserGuest(false)
            setCartItems(false)
        }
    }, [signOutSuccess, componentId])
    /**
     * Funcion To Redirect To Triangle URL
     */
    const triangleMCReditect = useCallback(
        (url: string): void => {
            if (url) {
                // Assign URL to archor tag
                anchorRef.current.href = getApplyNowUrl(url)
                // Execute regular click on tag to open
                setTimeout(() => {
                    anchorRef.current.click()
                }, MagicNumber.HUNDRED)
            }
        },
        [getApplyNowUrl],
    )
    /**
     * Toast prop values when failure toast message from Clicking on ApplyNow
     */
    const triangleMastercardErrorToastParams = useMemo(
        () => ({
            options: {
                toastErrorMessage: applyNowErrorMessage,
                toastErrorIcon: cautionIcon,
            },
            success: false,
            enableTimer: true,
        }),
        [applyNowErrorMessage, cautionIcon],
    )

    /**
     * function to show an errorHandling of TriangleMCBanner
     */
    const failureTriangleBannerErrorToastComponent = useCallback(() => {
        dispatchToast(true, triangleMastercardErrorToastParams, ToastComponentNames.TRIANGLE_MC_BANNER_ERROR, dispatch)
    }, [dispatch, triangleMastercardErrorToastParams])

    /**
     * Calls the TriangleMC service to retrieve the URL
     */
    const applyTriangleMCRedirect = useCallback((): void => {
        const customerInfo = {
            email: userProfileData?.email,
            ecommCustomerId: userProfileData?.UID,
        }
        dispatch(setShowSpinner(true))
        triangleMCBannerService
            .getApplyTriangleMCService(requestingSystem, customerInfo)
            .then(response => {
                if (response?.data?.url) {
                    triangleMCReditect(response?.data?.url)
                }
                dispatch(setShowSpinner(false))
            })
            .catch((err: ErrorTriangleMCBanner) => {
                if (
                    err?.response?.data?.errCode !== globalPartialAuthCode &&
                    err?.response?.data?.errorCode?.toString() !== timeoutErrorCode &&
                    err?.message?.toLowerCase() !== netWorkErrorCode.toLowerCase()
                ) {
                    failureTriangleBannerErrorToastComponent()
                }

                dispatch(setShowSpinner(false))
            })
    }, [triangleMCReditect, requestingSystem, userProfileData, dispatch, failureTriangleBannerErrorToastComponent])

    /**
     * Set Apply Now URL and Triggerring Click using partialResponseData
     */
    useEffect(() => {
        if (
            partialResponseData?.url.includes(environment.API_ENDPOINTS.triangleMCBanner) &&
            partialResponseData.response &&
            (partialResponseData.response?.data as Record<string, string>)?.url
        ) {
            triangleMCReditect((partialResponseData.response?.data as Record<string, string>)?.url)
            dispatch(fetchInterceptorPartialResponse(null))
        }
    }, [partialResponseData, triangleMCReditect, dispatch])

    /**
     * Function For Apply Now Click
     * @param {Event} event
     */
    const handleBannerClick = (event: Event): void => {
        const target = event.target as HTMLAnchorElement
        // If href value is not the trangle mastercard URL then prevent default to wait for the URL from API
        if (target.href.includes(componentId)) {
            event.preventDefault()
            onBannerClickAnalytics(bannerType, isBannerTop() ? 'Top' : 'Bottom')
            !authenticatedUser ? checkLoginStatus() : applyTriangleMCRedirect()
        }
    }
    /**
     * Function To Check the user is Currently Guest or Loggedin already And Based on that
     * decide to showgigyaModal or isUserGuest to True
     */
    const checkLoginStatus = (): void => {
        isUserGuest && !isLoggedIn ? showGigyaSignInModal() : setIsUserGuest(true)
    }
    /**
     * Function to display the gigya modal
     */
    const showGigyaSignInModal = useCallback((): void => {
        gigyaLoadFunctions(
            gigyaScreen,
            undefined,
            gigyaScreens.loginScreen,
            false,
            false,
            '',
            authenticatedUser ? applyTriangleMCRedirect : undefined,
        )
        window.gigya.showSignInModal = true
    }, [applyTriangleMCRedirect, authenticatedUser, gigyaScreen])

    useEffect(() => {
        if (authenticatedUser && isUserGuest) {
            applyTriangleMCRedirect()
            setIsUserGuest(false)
        } else if (!authenticatedUser && isUserGuest) {
            showGigyaSignInModal()
        }
    }, [isUserGuest, authenticatedUser, applyTriangleMCRedirect, showGigyaSignInModal])

    /**
     * function to render skeleton OR Banner
     * @return {JSX.Element | null}
     */
    const renderSkeletonOrBanner = (): JSX.Element | null => {
        return showSkeleton ? (
            <SkeletonComponent skeletonClass={`${PREFIX}-triangle-mc-banner__skeleton`} />
        ) : (
            renderSelectBanner()
        )
    }

    useGlobalScrollEvent(handleAnalyticsOnView)
    return <>{showTriangleBanner === 'true' && renderSkeletonOrBanner()}</>
}

TriangleMCBanner.propTypes = {
    bannerType: PropTypes.string.isRequired,
    bannerTitle: PropTypes.string.isRequired,
    bannerDescription: PropTypes.string.isRequired,
    bannerImage: PropTypes.string,
    bannerImageAltText: PropTypes.string,
    bannerImageOrientation: PropTypes.string,
    displayImageOnMobile: PropTypes.bool,
    backgroundColour: PropTypes.string.isRequired,
    fontColour: PropTypes.string,
    saveStoryText: PropTypes.string,
    saveStoryType: PropTypes.string,
    saveStoryValue: PropTypes.string,
    ctaTitle: PropTypes.string,
    pcid: PropTypes.string,
    utmSource: PropTypes.string,
    utmMedium: PropTypes.string,
    utmCampaign: PropTypes.string,
    utmTerm: PropTypes.string,
    utmContent: PropTypes.string,
    showTriangleBanner: PropTypes.string.isRequired,
    selectBanner: PropTypes.string.isRequired,
    ctaLink: PropTypes.string,
    ctaA11yLabel: PropTypes.string,
    ctaLinkTarget: PropTypes.string,
    monthlyPriceLabel: PropTypes.string,
    numberOfMonths: PropTypes.number,
    ctMoneyTooltipHeading: PropTypes.string,
    ctMoneyTooltipDescription: PropTypes.string,
    paymentInstallmentTooltipHeading: PropTypes.string,
    paymentInstallmentTooltipDescription: PropTypes.string,
    creditOfferedTooltipHeadingQuebec: PropTypes.string,
    creditOfferedTooltipDescQuebec: PropTypes.string,
    creditOfferedMessageQuebec: PropTypes.string,
    utmList: PropTypes.array,
    upsellingPercentage: PropTypes.number,
    perMonthLabel: PropTypes.string,
    ctaTitleAdvanced: PropTypes.string,
}

export default TriangleMCBanner
