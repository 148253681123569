import BaseService from '../base.service'
import { getEnvironment } from '../../environments'
import { getHttpClient } from '../../httpClient'
import { SuccessResponse } from '../../redux/models/deliveryOptions.interface'
import { libUtils } from '@nl/lib'

const environment = getEnvironment()
const httpClient = getHttpClient()
/**
 * deliveryOptionsService To fetch the eligibility of ship to home products
 */
class DeliveryOptionsService extends BaseService {
    /**
     * Load service eligibility
     * @param  {postalCode} postalCode
     * @param  {selectedPreferredStore} selectedPreferredStore
     * @param  {selectedSku} selectedSku
     * @param  {isBulk} isBulk
     * @param  {boolean} isProductAndStoreAvailableForED
     * @return {SuccessResponse}
     */
    fetchDeliveryOptions(
        postalCode: string,
        selectedPreferredStore: string | number,
        selectedSku: string | undefined,
        isBulk: boolean | undefined,
        isProductAndStoreAvailableForED: boolean,
    ): Promise<{ data: SuccessResponse }> {
        const url = this.createDeliveryOptionsUrl(
            postalCode,
            selectedPreferredStore,
            selectedSku,
            isBulk,
            isProductAndStoreAvailableForED,
        )
        return httpClient.apiGet(url, {}, undefined, true)
    }

    /**
     * Create deliveryOptions url based on mock or cds
     * @param  {string} postalCode
     * @param  {string | number} selectedPreferredStore
     * @param  {string | undefined} selectedSku
     * @param  {boolean | undefined} isBulk
     * @param  {boolean} isProductAndStoreAvailableForED
     * @return {URL}
     */
    createDeliveryOptionsUrl(
        postalCode: string,
        selectedPreferredStore: string | number,
        selectedSku: string | undefined,
        isBulk: boolean | undefined,
        isProductAndStoreAvailableForED: boolean,
    ): string {
        const {
            API_ENDPOINTS: { deliveryOptions, deliveryOptionsMock },
        } = environment
        const strippedPostal = postalCode.replace(/\s+/g, '')
        const expressDeliveryQueryParam = '&expressDeliveryCheck=true'
        return `${DeliveryOptionsService.switchEndpoints(
            deliveryOptionsMock,
            deliveryOptions,
        )}?store=${selectedPreferredStore}&sku=${String(
            selectedSku,
        )}&postalCode=${strippedPostal}&lang=${libUtils.getLanguage()}&isBulk=${String(isBulk)}${
            isProductAndStoreAvailableForED ? expressDeliveryQueryParam : ''
        }`
    }
}

const deliveryOptionsService = new DeliveryOptionsService()

export { deliveryOptionsService }
export default deliveryOptionsService
