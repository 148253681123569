import React, { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { RootState } from '../../redux/reducers'
import interceptorConfig from '../../services/ResponseInterceptors/interceptorConfig.service'
import { fetchInterceptorPromiseState } from '../../redux/actionCreators/tokenUpdate.actionCreators'
import { gigyaLoadFunctions } from '../GigyaScreen/GigyaScreen'
import {
    globalPartialAuthCode,
    gigyaTokenMissedErrorCode,
    invalidTokenErrorCode,
    tokenMissedErrorCode,
} from '../../globalConstants/cdsErrorCodes'
import appCacheService from '../../utils/appCacheService'
import getHttpClient from '../../httpClient'
import { extractKeyValues, isArrayNotEmpty } from '@nl/lib'
import { commonContentSelector } from '../../redux/selectors/commonContent.selectors'
import { tokenStateSelector } from '../../redux/selectors/tokenState.selectors'
import {
    actionPerformedOnScreenSelector,
    errorInterceptorDataSelector,
} from '../../redux/selectors/errorInterceptorData.selectors'
import { redirectToLoginPage, deleteCookie } from './PageInit.helper'
import getPageType from '../../utils/getPageType'
import { pageTypes } from '../../config'

const PartialAuthModal = (): JSX.Element => {
    const tokenState = useSelector(tokenStateSelector)
    const pageType = getPageType()
    const { interceptorState } = useSelector((state: RootState) => state.interceptorState)
    const errorInterceptorData = useSelector(errorInterceptorDataSelector)
    const actionPerformedOnScreen = useSelector(actionPerformedOnScreenSelector)
    const partialAuthList = interceptorConfig.getInterceptorPartialAuthList()
    const dispatch = useDispatch()
    const { commonContentAvailable } = useSelector(commonContentSelector)
    const [[partiallyAuthScreenId]] = extractKeyValues(commonContentAvailable, [
        { authentication: ['partiallyAuthScreenId'] },
    ])

    useEffect(() => {
        const cdsCodeErrors = [
            globalPartialAuthCode,
            gigyaTokenMissedErrorCode,
            invalidTokenErrorCode,
            tokenMissedErrorCode,
        ]
        const screensNotToRedirect = [
            window?.ODP?.globalLinks.loginPageLink,
            window?.ODP?.globalLinks.checkoutPageLink,
            window?.ODP?.globalLinks.passwordResetPageLink,
            window?.ODP?.globalLinks.registrationPageLink,
        ]
        const errorInterceptorCode =
            (errorInterceptorData as unknown as { response?: { data?: { errCode?: string } } })?.response?.data
                ?.errCode ?? ''

        if (cdsCodeErrors.includes(errorInterceptorCode) && pageType !== pageTypes.emailVerificationPage) {
            if (errorInterceptorCode === invalidTokenErrorCode) {
                deleteCookie('Access-Token')
            }
            if (window.location.pathname === window?.ODP?.globalLinks.checkoutPageLink) {
                gigyaLoadFunctions(partiallyAuthScreenId, undefined, '', false, null, actionPerformedOnScreen)
                window.gigya.showSignInModal = true
                dispatch(fetchInterceptorPromiseState(true))
            } else if (!screensNotToRedirect.includes(window.location.pathname)) {
                redirectToLoginPage(window?.ODP?.globalLinks.loginPageLink)
            }
        }
    }, [partiallyAuthScreenId, errorInterceptorData, dispatch, actionPerformedOnScreen, pageType])

    useEffect(() => {
        // if the user JWT token is available get the user profile
        if (tokenState === 'SUCCESS') {
            // gigya screenset is not closing on own , closing using sdk
            window.gigya.accounts?.hideScreenSet({ screenSet: 'ODP-LoginSignIn' })
        }
    }, [tokenState, dispatch])

    const updateInterceptorList = useCallback(
        (tokenValue: string) => {
            if (isArrayNotEmpty(partialAuthList)) {
                const updatedToken = appCacheService.gigyaJWTToken.get()
                partialAuthList.forEach((partialAuthReq: Record<string, unknown>) => {
                    const { rejectFunc } = partialAuthReq
                    if (tokenValue.toUpperCase() === 'SUCCESS' && !!updatedToken) {
                        getHttpClient().customInterceptorCall(partialAuthReq)
                    } else rejectFunc(Error)
                })
                interceptorConfig.clearAll()
            }
        },
        [partialAuthList],
    )

    useEffect(() => {
        if (tokenState && interceptorState) {
            updateInterceptorList(tokenState)
        }
    }, [tokenState, updateInterceptorList, interceptorState])

    return <>{null}</>
}

export default PartialAuthModal
