import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'
import { WishlistAndCartProps } from './WishlistAndCart.type'
import { PREFIX } from '../../config'
import { RootState } from '../../redux/reducers'
import { Wishlist, Cart } from '@nl/lib'
import { maximumCount } from './WishlistAndCart.constant'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { analyticsAttributes } from '../../globalConstants/analyticsParams.constant'
import { replaceStrWithDynamicVal } from '@nl/lib'

/**
 * WishlistAndCartComponent
 * @param {WishlistAndCartProps} props - wishlistUrl, cartUrl, a11yWishlist, a11yCart
 * @return {JSX.Element} returns wishlist and cart components
 */
const WishlistAndCartComponent: React.FC<WishlistAndCartProps> = ({ ...props }) => {
    const {
        wishlistUrl,
        cartUrl,
        a11yWishlist,
        a11yCart,
        a11yHeaderWishlistEmpty,
        a11yHeaderWishlist,
        a11yHeaderCartEmpty,
        a11yHeaderCart,
    } = props
    const [wishlistCount, setWishlistCount] = useState(0)
    const [cartCount, setCartCount] = useState(0)

    const {
        eventParameters: {
            location: { header },
        },
    } = analyticsAttributes

    const wishlistItems = useSelector((state: RootState) => state.wishlist.wishlistItems)
    const miniCart = useSelector((state: RootState) => state.cart.miniCartItemsData)
    const isBrowseOnlyMod = useSelector((state: RootState) => state.browseOnly.enable)

    const ariaLabelWishlist = !wishlistCount
        ? a11yHeaderWishlistEmpty
        : replaceStrWithDynamicVal(a11yHeaderWishlist, wishlistCount)
    const ariaLabelCart = !cartCount ? a11yHeaderCartEmpty : replaceStrWithDynamicVal(a11yHeaderCart, cartCount)
    useEffect(() => {
        if (checkDataLength(wishlistItems) && Array.isArray(wishlistItems.products)) {
            setWishlistCount(wishlistItems.products.length)
        }
    }, [wishlistItems])

    useEffect(() => {
        if (checkDataLength(miniCart)) {
            setCartCount(miniCart.numberOfItems)
        } else {
            setCartCount(0)
        }
    }, [miniCart])

    return isBrowseOnlyMod ? null : (
        <div className={`${PREFIX}-row ${PREFIX}-wishlist-cart-container`}>
            <Wishlist
                wishlistUrl={wishlistUrl}
                a11yWishlist={a11yWishlist}
                ariaLabelWishlist={ariaLabelWishlist}
                wishlistCount={wishlistCount}
                maximumCount={maximumCount}
                featureType={header}
            />
            <Cart
                cartUrl={cartUrl}
                a11yCart={a11yCart}
                ariaLabelCart={ariaLabelCart}
                cartCount={cartCount}
                maximumCount={maximumCount}
                featureType={header}
            />
        </div>
    )
}
WishlistAndCartComponent.propTypes = {
    wishlistUrl: PropTypes.string.isRequired,
    cartUrl: PropTypes.string.isRequired,
    a11yWishlist: PropTypes.string.isRequired,
    a11yCart: PropTypes.string.isRequired,
    a11yHeaderWishlistEmpty: PropTypes.string,
    a11yHeaderWishlist: PropTypes.string,
    a11yHeaderCartEmpty: PropTypes.string,
    a11yHeaderCart: PropTypes.string,
}

export default WishlistAndCartComponent
