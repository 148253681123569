import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { analyticsInteraction } from '../../redux/actions/pageAnalytics.action'
import { isArrayNotEmpty } from '@nl/lib'
import HeaderWrapper from './HeaderWrapper/HeaderWrapper'
import { HeaderProps, SortStoreDataType, defaultStoreUserAgentItem } from './Header.type'
import { getProductSearchData } from '../../redux/actions/search.action'
import {
    fetchNearbyStoreList,
    resetSelectedStoreMessageToast,
    setIsNearByStoreListApiDone,
    setSelectedPreferredStoreId,
    setSelectedStoreName,
} from '../../redux/actions/storeDetails.action'

import { resetEstimateFees } from '../../redux/actions/estimateFees.action'

import { analyticsAttributes } from '../../globalConstants/analyticsParams.constant'
import { storeListRequestType, StoreWithAvailability } from '../../redux/models/storeDetails.interface'
import { ADDRESS_MAXLENGTH } from '../../globalConstants/global.constant'
import storeLocatorAnalytics from '../../analytics/components/storeLocatorAnalytics'
import { getLocationUsingNavigator } from '../../utils/StoreLocatorUtil/getLocationUsingNavigator'
import { setPreferredStore } from '../../utils/StoreLocatorUtil/setPreferredStore'
import appCacheService from '../../utils/appCacheService'
import getPageType from '../../utils/getPageType'
import sessionStorageService from '../../utils/sessionStorageService'
import { pageTypes, STORE_INITIATED_CART_KEY } from '../../config'
import { getMedalliaStoreData } from '../../utils/medalliaUtills'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { MagicNumber } from '../../analytics/analytics.type'
import { setIsProductDataAvailable } from '../../redux/actions'
import { commonContentSelector } from '../../redux/selectors/commonContent.selectors'
import { userProfileDataSelector } from '../../redux/selectors/userProfile.selectors'
import { storeDetailsDataSelector } from '../../redux/selectors/storeDetails.selectors'

/**
 * Header Component
 * @param {HeaderProps} props-desktopPlaceholderLabel, mobilePlaceholderLabel, searchResultPageUrl, a11ySearchIconLabel
 * @return {JSX.Element} returns Header Component
 */
const HeaderComponent: React.FC<HeaderProps> = ({ ...props }) => {
    const {
        desktopPlaceholderText,
        mobilePlaceholderText,
        searchResultPageUrl,
        a11ySearchIconLabel,
        allyCloseIconLabel,
        a11yUpdateQueryIconLabel,
        minChars,
        delay,
        count,
        minCatLevel,
        maxNumCats,
        ctrPriceUrl,
        searchLabel,
        searchBoxPlaceholder,
        suggestionsLabel,
        categoriesLabel,
        seeMoreResultsLabel,
        searchHistoryTitle,
        searchHistoryCountDesktop,
        searchHistoryCountMobile,
        searchHistoryClearLabel,
        seeMoreStoresMsg,
        maxNumberOfStores,
        forcePreferredStoreMsg,
        selectAStoreLabel,
        storeLocationLabel,
        didYouMeanLabel,
        defaultStoreUserAgent,
        defaultStoreId,
        a11yArrowButtonsAriaLabel,
        a11ySuggestionsAvailableLabel,
    } = props

    const dispatch = useDispatch()
    const {
        nearbyStoreList,
        selectedPreferredStoreId,
        preferredStoreDetails,
        storeAPITriangulationFail,
        currentUserLocation,
    } = useSelector(storeDetailsDataSelector)
    const userProfileData = useSelector(userProfileDataSelector)
    const isLoggedIn = userProfileData ? Boolean(Object.keys(userProfileData).length) : false

    const [preferredStoreId, setPreferredStoreId] = useState(appCacheService.preferredStoreId.get())
    const [defaultPreferredStore, setDefaultPreferredStore] = useState('')

    const componentRef = useRef<HTMLDivElement>(null)
    const maxNumberOfStoresValue = maxNumberOfStores || MagicNumber.HUNDRED

    // get the page type
    const pageType = getPageType()

    useEffect(() => {
        checkDataLength(preferredStoreDetails) &&
            appCacheService.medalliaStoreData.set(JSON.stringify(getMedalliaStoreData(preferredStoreDetails)))
    }, [preferredStoreDetails])

    /**
     * Clear store initiated cart from the session storage
     */
    useEffect(() => {
        if (sessionStorageService.getItem(STORE_INITIATED_CART_KEY)) {
            sessionStorageService.removeItem(STORE_INITIATED_CART_KEY)
        }
    }, [])

    /**
     *  useEffect to set selected preferred store id
     */
    useEffect(() => {
        selectedPreferredStoreId && setPreferredStoreId(selectedPreferredStoreId)
    }, [selectedPreferredStoreId])

    const {
        event: { interaction },
        eventParameters: {
            action: { searchStore, storeLocator },
            labels: { userInput },
            location: { header, storeLocatorPage, defaultStore },
            category: { store: storeName },
            selection: { userSelected, defaultSelection },
        },
    } = analyticsAttributes

    /**
     * function to pass data to analytics function when user clicks search icon
     */
    const searchStoreAnalytics = (): void => {
        dispatch(analyticsInteraction(userInput, ' ', interaction, searchStore, storeName, storeLocatorPage))
    }

    const { setPreferredStoreAnalytics } = storeLocatorAnalytics

    const searchCallBack = (searchKeyword: string) => {
        // searchCallBack is invoked when searchKeyword exists
        dispatch(getProductSearchData(searchKeyword, searchResultPageUrl, 'q', selectedPreferredStoreId))
    }

    const { commonContentAvailable } = useSelector(commonContentSelector)

    const [accessibilityContent, setAccessibilityContent] = useState(
        commonContentAvailable?.accessibility || ({} as typeof commonContentAvailable.accessibility),
    )
    const [storeLocatorContent, setStoreLocatorContent] = useState(
        commonContentAvailable?.storeLocator || ({} as typeof commonContentAvailable.storeLocator),
    )

    useEffect(() => {
        commonContentAvailable?.accessibility && setAccessibilityContent(commonContentAvailable.accessibility)
        commonContentAvailable?.storeLocator && setStoreLocatorContent(commonContentAvailable.storeLocator)
    }, [commonContentAvailable])

    const { a11yHeaderStorePreferred, a11yHeaderStoreCurrently, a11yHeaderStoreAt } = accessibilityContent
    const {
        openLabel,
        opensLabel,
        closesLabel,
        maxRadiusToSearchStore: radius,
        onlineOrdersNotAcceptedMsg,
        storeSuccessfullySelectedMsg,
    } = storeLocatorContent

    const analyticsCount = useRef(0)

    /**
     * function to pass data to analytics function
     * @param {buttonLabel} buttonLabel
     */
    const viewMoreAnalytics = (buttonLabel: string): void => {
        dispatch(analyticsInteraction(buttonLabel, header, interaction, storeLocator, '', header))
    }

    /**
     * function to update preferred store
     * @param {string} storeId
     */
    const updatePreferredStore = useCallback(
        (storeId: string) => {
            dispatch(setSelectedPreferredStoreId(String(storeId)))
            setPreferredStoreId(String(storeId))
        },
        [dispatch],
    )

    /**
     * Effect to update preferred store ID
     */
    useEffect(() => {
        if (isArrayNotEmpty(nearbyStoreList)) {
            if (!preferredStoreId && !storeAPITriangulationFail) {
                // If no preferred, for the first , pick up the first item from NearByStore list.
                updatePreferredStore(String(nearbyStoreList[0].id))
            }
        } else if (selectedPreferredStoreId || preferredStoreId) {
            updatePreferredStore(selectedPreferredStoreId || preferredStoreId)
        }
    }, [
        nearbyStoreList,
        updatePreferredStore,
        preferredStoreId,
        selectedPreferredStoreId,
        storeAPITriangulationFail,
        dispatch,
    ])

    const requestPayload = useMemo(() => {
        return {
            maxCount: maxNumberOfStoresValue, // TODO: Need remove or condition when AEM add and authours the prop.
        } as storeListRequestType
    }, [maxNumberOfStoresValue])

    const loadStoreList = useRef(0)

    /**
     * function to fetch store list on load
     */
    const fetchStoreListOnLoad = useCallback(() => {
        if (loadStoreList.current === 0) {
            dispatch(
                fetchNearbyStoreList({
                    ...requestPayload,
                } as storeListRequestType),
            )
            loadStoreList.current = 1
        }
    }, [dispatch, requestPayload])

    useEffect(() => {
        if (
            isArrayNotEmpty(nearbyStoreList) &&
            selectedPreferredStoreId &&
            selectedPreferredStoreId !== String(nearbyStoreList[0]?.id) &&
            selectedPreferredStoreId !== appCacheService.preferredStoreId.get()
        ) {
            updatePreferredStore(selectedPreferredStoreId)
        }
    }, [nearbyStoreList, selectedPreferredStoreId, dispatch, updatePreferredStore])

    /**
     * Callback for the google autocomplete.
     * Will trigger when someone clicks on the search icon or inputs enter.
     * @param {number} latitude
     * @param {number} longitude
     */
    const fetchNearbyStoreListOnSearch = useCallback(
        (latitude: number, longitude: number): void => {
            dispatch(
                fetchNearbyStoreList({
                    latitude,
                    longitude,
                    ...requestPayload,
                } as storeListRequestType),
            )
        },
        [dispatch, requestPayload],
    )

    /**
     * Function to return the store with matching id with default or id received in query param
     * @param {string} storeId
     * @return {StoreWithAvailability}
     */
    const getStoreDetails = useCallback(
        (storeId: string): StoreWithAvailability | undefined => {
            return nearbyStoreList?.find(
                (store: StoreWithAvailability) => store.id === Number(storeId || selectedPreferredStoreId),
            )
        },
        [nearbyStoreList, selectedPreferredStoreId],
    )

    /**
     * Function to set prefrred store analytics
     * @param {string} storeId
     */
    const updatePreferredStoreAnalytics = useCallback(
        (storeId: string): void => {
            const selectedStoreDetails = getStoreDetails(storeId)
            updatePreferredStore(storeId)
            selectedStoreDetails &&
                setPreferredStoreAnalytics(selectedStoreDetails, false, pageType || '', userSelected)
        },
        [getStoreDetails, updatePreferredStore, setPreferredStoreAnalytics, userSelected, pageType],
    )

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase()

        if (defaultStoreId && defaultStoreUserAgent?.length) {
            const isUserAgentMatchingConfig = defaultStoreUserAgent.some(
                (configItem: defaultStoreUserAgentItem) =>
                    userAgent.indexOf(configItem?.userAgent?.toLowerCase()) > MagicNumber.MINUS_ONE,
            )
            if (isUserAgentMatchingConfig) {
                setDefaultPreferredStore(defaultStoreId)
            }
        }
    }, [defaultStoreId, defaultStoreUserAgent, setDefaultPreferredStore])

    useEffect(() => {
        const storeId = defaultPreferredStore || null
        if (storeId && selectedPreferredStoreId && storeId !== selectedPreferredStoreId) {
            updatePreferredStore(Number(storeId))
        } else if (storeId) {
            updatePreferredStoreAnalytics(Number(storeId))
        }
    }, [selectedPreferredStoreId, updatePreferredStore, updatePreferredStoreAnalytics, defaultPreferredStore])

    const runOnce = useRef(0)

    /**
     * Function is triggered when clicked on map Icon.
     * Function will show the "allow location dialog".
     */
    const fetchNearByStoreDataByLocation = useCallback(
        (mapIconClicked?: boolean) => {
            if (!!radius && (runOnce.current === 0 || mapIconClicked)) {
                getLocationUsingNavigator({
                    dispatch,
                    requestPayload,
                    pageTypeForAnalytics: storeLocatorPage,
                }).setPreferredStoreOnClick()
                runOnce.current = 1
            }
        },
        [dispatch, requestPayload, storeLocatorPage, radius],
    )

    useEffect(() => {
        if (preferredStoreId) {
            const storeData = getStoreDetails(preferredStoreId)
            if (storeData && analyticsCount.current === 0) {
                setPreferredStoreAnalytics(storeData, false, defaultStore, defaultSelection)
                analyticsCount.current = 1
            }
        }
    }, [preferredStoreId, getStoreDetails, setPreferredStoreAnalytics, defaultStore, defaultSelection])

    const headerStoreLocator = {
        storeLocatorGlobalPropData: storeLocatorContent,
        inputMaxLength: ADDRESS_MAXLENGTH,
        forcePreferredStoreMsg,
        selectAStoreLabel,
        storeLocationLabel,
    }

    /**
     * Function to set preferred store.
     * @param {StoreWithAvailability} store
     * @param {React.SetStateAction} setSortedData
     * @param {Function} sortStoreDataList
     */
    const setPreferredStoreHandler = (
        store: StoreWithAvailability,
        setSortedData: React.Dispatch<React.SetStateAction<SortStoreDataType>>,
        sortStoreDataList: (
            storeList: StoreWithAvailability[],
            preferredStore: StoreWithAvailability,
        ) => SortStoreDataType,
    ): void => {
        setPreferredStore(
            store,
            nearbyStoreList,
            setSortedData,
            sortStoreDataList,
            isLoggedIn,
            dispatch,
            setPreferredStoreId,
            '',
            preferredStoreDetails,
        )
        dispatch(setSelectedPreferredStoreId(String(store.id)))
        dispatch(setSelectedStoreName(store.displayName))
        pageType === pageTypes.pdpPage && dispatch(resetEstimateFees())
        dispatch(setIsNearByStoreListApiDone(false))
        dispatch(setIsProductDataAvailable(false))
        dispatch(resetSelectedStoreMessageToast())
    }

    return (
        <div ref={componentRef}>
            <HeaderWrapper
                desktopPlaceholderText={desktopPlaceholderText}
                mobilePlaceholderText={mobilePlaceholderText}
                searchPagePathURL={searchResultPageUrl}
                viewMoreAnalytics={viewMoreAnalytics}
                searchCallBack={searchCallBack}
                searchIconSize="md"
                closeIconSize="md"
                a11ySearchIconLabel={a11ySearchIconLabel}
                allyCloseIconLabel={allyCloseIconLabel}
                a11yHeaderStorePreferred={a11yHeaderStorePreferred}
                a11yHeaderStoreCurrently={a11yHeaderStoreCurrently}
                a11yHeaderStoreAt={a11yHeaderStoreAt}
                openLabel={openLabel}
                opensLabel={opensLabel}
                closesLabel={closesLabel}
                storeLocatorList={nearbyStoreList}
                preferredStoreId={preferredStoreId}
                onlineOrdersNotAcceptedMsg={onlineOrdersNotAcceptedMsg}
                {...headerStoreLocator}
                fetchNearbyStoreListOnSearch={fetchNearbyStoreListOnSearch}
                fetchStoreListOnLoad={fetchStoreListOnLoad}
                mapIconOnClickHandler={fetchNearByStoreDataByLocation}
                windowRef={window}
                setPreferredStore={setPreferredStoreHandler}
                searchStoreAnalyticsHandler={searchStoreAnalytics}
                minChars={minChars}
                delay={delay}
                count={count}
                minCatLevel={minCatLevel}
                maxNumCats={maxNumCats}
                ctrPriceUrl={ctrPriceUrl}
                searchLabel={searchLabel}
                searchBoxPlaceholder={searchBoxPlaceholder}
                suggestionsLabel={suggestionsLabel}
                categoriesLabel={categoriesLabel}
                seeMoreResultsLabel={seeMoreResultsLabel}
                searchHistoryTitle={searchHistoryTitle}
                searchHistoryCountDesktop={searchHistoryCountDesktop}
                searchHistoryCountMobile={searchHistoryCountMobile}
                searchHistoryClearLabel={searchHistoryClearLabel}
                storeSuccessfullySelectedMsg={storeSuccessfullySelectedMsg}
                seeMoreStoresMsg={seeMoreStoresMsg}
                preferredStoreDetails={preferredStoreDetails}
                currentUserLocation={currentUserLocation}
                a11yUpdateQueryIconLabel={a11yUpdateQueryIconLabel}
                didYouMeanLabel={didYouMeanLabel}
                maxNumberOfStoresForOldStoreLocator={maxNumberOfStoresValue}
                a11yArrowButtonsAriaLabel={a11yArrowButtonsAriaLabel}
                a11ySuggestionsAvailableLabel={a11ySuggestionsAvailableLabel}
            />
        </div>
    )
}
HeaderComponent.propTypes = {
    desktopPlaceholderText: PropTypes.string.isRequired,
    mobilePlaceholderText: PropTypes.string.isRequired,
    searchResultPageUrl: PropTypes.string.isRequired,
    a11ySearchIconLabel: PropTypes.string.isRequired,
    allyCloseIconLabel: PropTypes.string.isRequired,
    a11yUpdateQueryIconLabel: PropTypes.string.isRequired,
    minChars: PropTypes.string.isRequired,
    delay: PropTypes.string.isRequired,
    count: PropTypes.string.isRequired,
    minCatLevel: PropTypes.string.isRequired,
    maxNumCats: PropTypes.string.isRequired,
    ctrPriceUrl: PropTypes.string.isRequired,
    searchLabel: PropTypes.string.isRequired,
    searchBoxPlaceholder: PropTypes.string.isRequired,
    suggestionsLabel: PropTypes.string.isRequired,
    categoriesLabel: PropTypes.string.isRequired,
    seeMoreResultsLabel: PropTypes.string.isRequired,
    searchHistoryTitle: PropTypes.string.isRequired,
    searchHistoryCountDesktop: PropTypes.number.isRequired,
    searchHistoryCountMobile: PropTypes.number.isRequired,
    searchHistoryClearLabel: PropTypes.string.isRequired,
    seeMoreStoresMsg: PropTypes.string.isRequired,
    maxNumberOfStores: PropTypes.number.isRequired,
    forcePreferredStoreMsg: PropTypes.string.isRequired,
    selectAStoreLabel: PropTypes.string.isRequired,
    storeLocationLabel: PropTypes.string.isRequired,
    a11yArrowButtonsAriaLabel: PropTypes.string,
    a11ySuggestionsAvailableLabel: PropTypes.string,
}

export default HeaderComponent
