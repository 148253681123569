import React from 'react'
import PropTypes from 'prop-types'
import { HeaderBlockType } from './HeaderBlock.type'
import Button from '../Button'
import { PREFIX } from '../config'
import { headerBlockClassName } from './HeaderBlock.constant'
import SanitizeStringContentWrapper from '../../utils/sanitizeStringContent'

const HeaderBlock: React.FC<HeaderBlockType> = ({ ...props }): JSX.Element => {
    const { title, subtitle, cta, toggleView, isCTAlink } = props

    const onViewAllClick = (link: string) => {
        window.location.href = encodeURI(link)
    }

    const ctaRedirectButton = (): JSX.Element => {
        return (
            <>
                {isCTAlink ? (
                    <a
                        role="button"
                        data-link-value={cta?.ctaLabel}
                        href={cta?.ctaLink}
                        className={`${headerBlockClassName}__linkbutton ${PREFIX}-button ${PREFIX}-button--secondary ${PREFIX}-button--small`}>
                        {cta?.ctaLabel}
                    </a>
                ) : (
                    <Button
                        type="secondary_reverse"
                        size="small"
                        label={cta?.ctaLabel}
                        onClick={() => onViewAllClick(cta?.ctaLink)}
                    />
                )}
            </>
        )
    }

    const dashboardViewButton = () => {
        return (
            <div className={`${title ? `${PREFIX}-xs-none ${PREFIX}-sm-none ${PREFIX}-md-block` : ''}`}>
                <div className={`${PREFIX}-md-block ${PREFIX}-xs-none`}>{ctaRedirectButton()}</div>
                <div className={`${PREFIX}-md-none ${PREFIX}-xs-block`}>{ctaRedirectButton()}</div>
            </div>
        )
    }

    return title || toggleView ? (
        <div className={`${headerBlockClassName} ${PREFIX}-md-flex`}>
            {title && (
                <div className={`${headerBlockClassName}__title-view`}>
                    <SanitizeStringContentWrapper stringContent={title || ''}>
                        {memoizedStringContent => (
                            <h4
                                className={`${
                                    toggleView
                                        ? `${headerBlockClassName}__title ${PREFIX}-body-xl`
                                        : `${headerBlockClassName}__header`
                                }`}
                                dangerouslySetInnerHTML={{ __html: memoizedStringContent }}
                            />
                        )}
                    </SanitizeStringContentWrapper>
                    {subtitle && (
                        <p
                            className={`${headerBlockClassName}__subtitle`}
                            dangerouslySetInnerHTML={{ __html: subtitle || '' }}
                        />
                    )}
                </div>
            )}
            {toggleView && dashboardViewButton()}
        </div>
    ) : (
        <></>
    )
}

HeaderBlock.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
}
export default HeaderBlock
