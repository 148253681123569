import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import store from '../../store'
import PencilBanner from './PencilBanner/PencilBanner'
import { AccountOptions, AccountPencilBannerProps } from './AccountsPencilBanner.type'
import { signOutUser, resetUserProfileData } from '../../redux/actions/user.profile.action'
import { addPencilBanner } from '../../redux/actions/headerProps.action'
import { logOutUser } from '../../utils/gigyaLogout'
import { UserProfileData } from '../../redux/models/user.profile.interface'
import { libUtils } from '@nl/lib'
import accountAnalytics from '../../analytics/components/accountAnalytics'
import checkNestedProps from '../../utils/checkNestedProps'
import { signoutRedirection } from '../../utils/signoutRedirecton'
import { accountsPageUrlPattern, signoutLocation } from '../../globalConstants/global.constant'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { PREFIX } from '../../config'
import sessionStorageService from '../../utils/sessionStorageService'
import { commonContentSelector, browseOnlyModeSelector } from '../../redux/selectors/commonContent.selectors'
import {
    isAuthFlowExecutedSelector,
    signOutSuccessSelector,
    userProfileDataSelector,
    userProfileLocationSelector,
} from '../../redux/selectors/userProfile.selectors'
import { pencilBannerPropSelector } from '../../redux/selectors/headerProps.selectors'
import { fetchTokenUpdateAction } from '../../redux/actionCreators/tokenUpdate.actionCreators'

/**
 * AccountsPencilBanner component
 * @param {AccountPencilBannerProps} props
 * @return {JSX.Element} returns AccountsPencilBanner component
 */
const AccountsPencilBanner: React.FC<AccountPencilBannerProps> = ({ ...props }) => {
    const {
        accounts: { accountOptions },
        parentOptions,
        showMenu,
        wishlistUrl,
        a11yWishlist,
        wishlistCount,
        maximumCount,
        isSingleSignInRender,
    } = props

    const filterAccountsOptions = (accountOptionsArray: AccountOptions[]) => {
        return accountOptionsArray.filter(opt => !opt.hideInBrowseOnlyMode)
    }

    const dispatch = useDispatch()

    const userProfileData = useSelector(userProfileDataSelector)
    const isAuthFlowExecuted = useSelector(isAuthFlowExecutedSelector)
    const signOutSuccess = useSelector(signOutSuccessSelector)
    const location = useSelector(userProfileLocationSelector)

    const { commonContentAvailable } = useSelector(commonContentSelector)
    const pencilBannerProp = useSelector(pencilBannerPropSelector)
    const [userDetails, setUserDetails] = useState<UserProfileData | null>(null)
    const locale = libUtils.getLanguage()
    const profileAPIRef = useRef(0)

    const isBrowseOnlyMod = useSelector(browseOnlyModeSelector)

    const signOutCallBackFunction = useCallback(() => {
        dispatch(signOutUser(signoutLocation.header))
        sessionStorageService.removeItem('isLoggedInForToast')
    }, [dispatch])

    /**
     * pass the analytics data on signout
     */
    const passAnalyticsData = useCallback((): void => {
        if (location === signoutLocation.header) {
            accountAnalytics.signoutAnalytics(location)
        }
    }, [location])

    useEffect(() => {
        const resetProfileData = () => {
            dispatch(resetUserProfileData())
            passAnalyticsData()
            const redirectionURL =
                (checkNestedProps(commonContentAvailable, 'globalLinks', 'loginPageLink') as string) || ''
            signoutRedirection(redirectionURL)
        }

        if (signOutSuccess && userProfileData?.UID) {
            logOutUser(resetProfileData, userProfileData.UID)
        }
        if (signOutSuccess) {
            profileAPIRef.current = 0
            // third state for tokenState
            store.dispatch(fetchTokenUpdateAction('SIGN_OUT'))
        }
    }, [dispatch, signOutSuccess, userProfileData, commonContentAvailable, passAnalyticsData])

    useEffect(() => {
        if (isAuthFlowExecuted && userProfileData && checkDataLength(userProfileData)) {
            setUserDetails(userProfileData)
        } else {
            setUserDetails(null)
        }
    }, [isAuthFlowExecuted, userProfileData])

    useEffect(() => {
        !checkDataLength(pencilBannerProp as Record<string, unknown>) && dispatch(addPencilBanner(props))
    }, [dispatch, props, pencilBannerProp])

    /**
     * function to check the page type and disply account menu
     * @return {boolean}
     */
    const checkPageType = (): boolean => {
        const currentURL = window.location.href
        return currentURL.includes(accountsPageUrlPattern.en || accountsPageUrlPattern.fr)
    }

    const filteredAccountOptions = filterAccountsOptions(accountOptions)

    return (
        <>
            <div className={`${PREFIX}-sm-none`}>
                <PencilBanner
                    profileDetails={userDetails}
                    signOutCallBack={signOutCallBackFunction}
                    currentLocale={locale}
                    accountOptions={isBrowseOnlyMod ? filteredAccountOptions : accountOptions}
                    {...parentOptions}
                    isAccountsPage={checkPageType()}
                    accountButtonId={'accountButton-mobile'}
                    showMenu={showMenu}
                    profileAPIRef={profileAPIRef}
                    quantumMetricAttribute={{ type: 'encrypt', value: 'true' }}
                    a11yWishlist={a11yWishlist}
                    wishlistUrl={wishlistUrl}
                    wishlistCount={wishlistCount}
                    maximumCount={maximumCount}
                    isSingleSignInRender={isSingleSignInRender}
                    isBrowseOnlyMod={isBrowseOnlyMod}
                />
            </div>
            <div className={`${PREFIX}-xs-none ${PREFIX}-sm-block ${PREFIX}-md-block`}>
                <PencilBanner
                    profileDetails={userDetails}
                    signOutCallBack={signOutCallBackFunction}
                    currentLocale={locale}
                    accountOptions={isBrowseOnlyMod ? filteredAccountOptions : accountOptions}
                    {...parentOptions}
                    isAccountsPage={false}
                    accountButtonId={'accountButton-desktop'}
                    showMenu={showMenu}
                    profileAPIRef={profileAPIRef}
                    quantumMetricAttribute={{ type: 'encrypt', value: 'true' }}
                    a11yWishlist={a11yWishlist}
                    wishlistUrl={wishlistUrl}
                    wishlistCount={wishlistCount}
                    maximumCount={maximumCount}
                    isSingleSignInRender={isSingleSignInRender}
                    isBrowseOnlyMod={isBrowseOnlyMod}
                />
            </div>
        </>
    )
}

AccountsPencilBanner.propTypes = {
    accounts: PropTypes.shape({
        accountOptions: PropTypes.array.isRequired,
    }).isRequired,
    parentOptions: PropTypes.shape({
        accountsLabel: PropTypes.string.isRequired,
        custServiceLabel: PropTypes.string.isRequired,
        custServiceURL: PropTypes.string.isRequired,
        customerServiceTarget: PropTypes.string.isRequired,
        languageLabel: PropTypes.string.isRequired,
        alternateLanguageA11y: PropTypes.string.isRequired,
    }).isRequired,
    showMenu: PropTypes.bool,
}

export default AccountsPencilBanner
