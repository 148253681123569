import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { Accordion, Icon, isArrayNotEmpty } from '@nl/lib'
import { PREFIX } from '../../config'
import { RootState } from '../../redux/reducers'
import { MagicNumber } from '../../analytics/analytics.type'
import { SiteMapProps, LinkLists } from '../SiteMap/SiteMap.type'
import { Category, InitialCategoryPayloadType } from '../../redux/models/secondaryNavigation.interface'
import { fetchSiteMapCategories } from '../../redux/actions'

/**
 * returns  SiteMap Component
 * @return {JSX.Element}
 */
const SiteMap: React.FC<SiteMapProps> = ({ ...props }): JSX.Element => {
    const { title, otherLinks, viewAll } = props
    const dispatch = useDispatch()
    const siteMapLinks = useSelector((state: RootState) => state.siteMap)
    const [arrayList, setArrayList] = useState({
        id: title,
        name: title,
        categories: [],
    })
    const [secondaryNavigationData, setSecondaryNavigation] = useState(siteMapLinks)

    useEffect(() => {
        otherLinks.forEach(links => {
            const listArray = []
            links.linkLists.forEach((link: LinkLists) => {
                const listArrayDetail = {
                    id: link.ctaLabel,
                    name: link.ctaLabel,
                    url: link.ctaLink,
                }
                listArray.push(listArrayDetail)
            })
            const otherLinkData = {
                id: links.sectionLabel,
                name: links.sectionLabel,
                url: links.sectionLink,
                categories: listArray,
            }
            setArrayList(prev => {
                return {
                    ...prev,
                    categories: [...prev.categories, { ...otherLinkData }],
                }
            })
        })
    }, [otherLinks])

    useEffect(() => {
        dispatch(fetchSiteMapCategories('SITEMAP'))
    }, [dispatch])

    useEffect(() => {
        if (isArrayNotEmpty(siteMapLinks)) {
            siteMapLinks?.forEach((category: Category) => {
                if (category.subcategories) {
                    category.categories = category.subcategories
                    category.categories.forEach((subCategories: Category) => {
                        subCategories.categories = subCategories.subcategories
                    })
                }
            })
            setSecondaryNavigation([...siteMapLinks, arrayList])
        }
    }, [siteMapLinks, arrayList])

    const renderCategoriesList = (cat: InitialCategoryPayloadType, otherList: boolean): JSX.Element => {
        const otherLinkSingleList = otherList ? `${PREFIX}-sub-categories__category__otherlink-single-list` : ''
        return (
            <li key={cat.name} role="none" className={`${PREFIX}-sub-categories__category`}>
                <a
                    className={`${PREFIX}-sub-categories__category__link ${otherLinkSingleList}`}
                    role="menuitem"
                    href={encodeURI(cat.url?.url || cat.url)}
                    data-link-value={cat.name}>
                    {cat.name}
                </a>
            </li>
        )
    }

    const isOtherLinkList = (name: string, size: number): boolean => {
        return name === title && size === MagicNumber.ONE ? true : false
    }

    return (
        <>
            <div className={`${PREFIX}-md-none ${PREFIX}-col-sm-12`}>
                {secondaryNavigationData &&
                    secondaryNavigationData.map((category, index) => {
                        return (
                            <Accordion key={index} title={category.name} collapseControl={(isOpen: boolean) => isOpen}>
                                {category.name !== title && (
                                    <a
                                        role="menuitem"
                                        className={`${PREFIX}-sub-categories__link`}
                                        href={encodeURI(category.url?.url || category.url)}
                                        data-link-value={category.name}>
                                        {`${String(viewAll)} ${category.name}`}
                                    </a>
                                )}
                                {category?.categories?.map(categoriesList => {
                                    const otherList = isOtherLinkList(category.name, categoriesList?.categories?.length)

                                    return (
                                        <ul
                                            role="menu"
                                            key={categoriesList.name}
                                            className={`${PREFIX}-sub-categories__ul ${PREFIX}-sub-categories__ul__spacing ${PREFIX}-col-xs-6`}>
                                            <li
                                                role="none"
                                                key={categoriesList.name}
                                                className={`${PREFIX}-sub-categories__category__l2`}>
                                                <a
                                                    role="menuitem"
                                                    href={encodeURI(categoriesList.url?.url || categoriesList.url)}
                                                    className={`${PREFIX}-sub-categories__category__l2__link`}
                                                    data-link-value={categoriesList.name}>
                                                    {categoriesList.name}
                                                </a>
                                            </li>
                                            {categoriesList?.categories?.map((cat: InitialCategoryPayloadType) =>
                                                renderCategoriesList(cat, otherList),
                                            )}
                                        </ul>
                                    )
                                })}
                            </Accordion>
                        )
                    })}
            </div>
            <div className={`${PREFIX}-xs-none ${PREFIX}-md-block ${PREFIX}-sub-categories__list-details`}>
                {secondaryNavigationData &&
                    secondaryNavigationData.map(category => {
                        return (
                            <>
                                <div className={`${PREFIX}-col-sm-12`}>
                                    {!!category.url || !!category.url?.url ? (
                                        <a
                                            role="menuitem"
                                            className={`${PREFIX}-sub-categories__link`}
                                            href={encodeURI(category.url?.url || category.url)}
                                            data-link-value={category.name}>
                                            {category.name}
                                            <Icon type="ct-chevron-right" size="md" />
                                        </a>
                                    ) : (
                                        <div
                                            role="menuitem"
                                            className={`${PREFIX}-sub-categories__link`}
                                            data-link-value={category.name}>
                                            {category.name}
                                        </div>
                                    )}
                                </div>
                                <hr className={`${PREFIX}-site-map-hr`} />
                                <div className={`${PREFIX}-row`}>
                                    {category?.categories?.map(categoriesLists => {
                                        const otherList = isOtherLinkList(
                                            category.name,
                                            categoriesLists?.categories?.length,
                                        )

                                        return (
                                            <>
                                                <ul
                                                    role="menu"
                                                    className={`${PREFIX}-col-sm-2 ${PREFIX}-sub-categories__ul`}
                                                    key={categoriesLists.name}>
                                                    <li
                                                        role="none"
                                                        key={categoriesLists.name}
                                                        className={`${PREFIX}-sub-categories__category__l2`}>
                                                        <a
                                                            role="menuitem"
                                                            className={`${PREFIX}-sub-categories__category__l2__link`}
                                                            href={encodeURI(
                                                                categoriesLists.url?.url || categoriesLists.url,
                                                            )}
                                                            data-link-value={categoriesLists.name}>
                                                            {categoriesLists.name}
                                                        </a>
                                                    </li>
                                                    {categoriesLists?.categories?.map(cat =>
                                                        renderCategoriesList(cat, otherList),
                                                    )}
                                                </ul>
                                            </>
                                        )
                                    })}
                                </div>
                            </>
                        )
                    })}
            </div>
        </>
    )
}

SiteMap.propTypes = {
    title: PropTypes.string,
    viewAll: PropTypes.string,
    otherLinks: PropTypes.array.isRequired,
}

export default SiteMap
