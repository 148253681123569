import { ProductDataType } from '../models/productData.interface'
import { RootState } from '../reducers'
import { ProductDataState, PRODUCT_DATA_STATE_KEY } from '../reducers/productData.reducer'

export const productDataStateSelector = (state: RootState): ProductDataState => state[PRODUCT_DATA_STATE_KEY]
export const productCardDataSelector = (state: RootState): ProductDataType =>
    state?.weatherTech?.isWeatherTechFlow
        ? state[PRODUCT_DATA_STATE_KEY].weatherTechProductCardData
        : state[PRODUCT_DATA_STATE_KEY].productCardData
export const productCardPageNameSelector = (state: RootState): string =>
    state[PRODUCT_DATA_STATE_KEY].productCardData.pageName
