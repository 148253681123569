import { Vehicle } from '@nl/lib'
import { ProductResponseData } from './redux/models/product.interface'
import { ProductDataType } from './redux/models/productData.interface'
import { CategoryResponseById } from './redux/models/category.interface'
import { AxiosResponseHeaders } from './helpers/axiosResponseHeaders.type'
import { CartItemsData } from './redux/models/cart.interface'

/**
 * Interface for FED Components
 * @interface
 */
export interface Component {
    dynamic: boolean
    name: string
    props: unknown
    element: HTMLElement
}

export interface ComponentOutput {
    index: any
    updateType: string
    data: {
        dataState: {
            vehicle?: Vehicle
        }
        uiState: Record<string, any>
        stateData: {
            vehicle: Record<string, string | Record<string, string>>
        }
    }
}
export interface ComponentProps {
    changeListener?: (eventData: ComponentOutput) => void
}

export interface HttpResponse {
    [key: string]: Record<string, string>
}
export interface HttpProductResponse {
    json: () => ProductResponseData
    ok: boolean
}

export interface HttpCartResponse {
    json: () => CartItemsData
    ok: boolean
}

export interface HttpCategoryResponse {
    json: () => Promise<CategoryResponseById>
    headers?: AxiosResponseHeaders
    ok: boolean
}

export interface HttpSearchResponse {
    json: () => ProductDataType
    ok: boolean
}

export interface HttpVehicleResponse {
    json: () => HttpVehicleResponseBody
    ok: boolean
}

export interface HttpVehicleResponseBody {
    vehicle?: {
        baseVehicleId: unknown
    }
    attributes: {
        suggested: [
            {
                id: string
                values: string[]
            },
        ]
        known: [
            {
                id: string
                value: string
            },
        ]
    }
}

export interface HttpResponseDropdownHelper {
    vehicle?: {
        baseVehicleId: unknown
    }
    attributes: {
        suggested: Record<string, unknown[]>[]
        known: Record<string, unknown>[]
    }
}

export enum CardType {
    visa = 'visa',
    master = 'mastercard',
    amex = 'amex',
    triangle = 'triangle',
    paypal = 'paypal',
}
