import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { TabsProps } from './Tabs.type'
import Tab from './Tab'

/**
 * Tabs component
 * @return {JSX.Element} returns Tabs component
 */
const Tabs: React.FC<TabsProps> = ({ ...props }): JSX.Element => {
    const children = props.children.filter(child => child.props.label)
    const activeLabel = props.selectedTab || children[0]?.props?.label
    const [activeTab, setActiveTab] = useState(activeLabel)
    useEffect(() => {
        setActiveTab(activeLabel)
    }, [activeLabel])
    /**
     * Function to call callback function to set active tab
     * @param {string} tab selected tab label
     */
    const onClickTabItem = (tab: string): void => {
        setActiveTab(tab)
        if (props.tabClickedCallBack) {
            props.tabClickedCallBack(tab)
        }
    }

    /**
     * Function to return Tab header data
     * @return {JSX.Element} returns Overlay component
     */
    const getTabData = (): JSX.Element[] => {
        return children.map((child, index) => {
            return (
                <Tab
                    dataTestId={`testid-${index}`}
                    activeTab={activeTab}
                    key={child.props.label}
                    label={child.props.label}
                    onClick={onClickTabItem}
                />
            )
        })
    }

    return (
        <div className={`${PREFIX}-tabs`}>
            <ol className={`${PREFIX}-tabs__list`}>{getTabData()}</ol>
            <div className={`${PREFIX}-tabs__content`}>
                {props.children.map(child => {
                    if (child.props.label !== activeTab) return null
                    return child.props.children
                })}
            </div>
        </div>
    )
}

Tabs.propTypes = {
    children: PropTypes.any,
    tabClickedCallBack: PropTypes.func,
}

export default Tabs
