import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, checkDataLength, Icon } from '@nl/lib'
import { gigyaLoadFunctions } from '../GigyaScreen/GigyaScreen'
import { MagicNumber } from '../../analytics/analytics.type'
import { PREFIX, BREAKPOINTS, IS_FIRST_PAGE_VISIT } from '../../config'
import appCacheService from '../../utils/appCacheService'
import sessionStorageService from '../../utils/sessionStorageService'
import { isPanelBlockedForCurrentPage } from '../LoyaltyOffers/LoyaltyOffers.helper'
import { userProfileDataSelector } from '../../redux/selectors/userProfile.selectors'
import { StickyBannerProps, StickyBannerTimerData } from './StickyBanner.type'
import { EMAIL_ACQUISITION_SIGNUP_STICKY_BANNER } from '../../globalConstants'
import { filterStickyBannerData } from './StickyBanner.helper'

/**
 * Sticky Banner
 * @return {JSX.Element} returns Sticky Banner
 */
const StickyBanner: React.FC<StickyBannerProps> = ({ ...props }): JSX.Element => {
    const {
        sbNumberOfTimes,
        sbNumberOfDays,
        sbExcludeListPagePaths,
        sbEnableOnDesktop,
        sbDesktopText,
        sbDesktopIcon,
        sbEnableSignUpModelOnDesktop,
        sbDesktopUrl,
        sbEnableOnMobileAndTablet,
        sbMobileAndTabletText,
        sbMobileAndTabletIcon,
        sbEnableSignUpModelOnMobileAndTab,
        sbMobileAndTabletDestinationUrl,
    } = props

    const isBlockListPage = isPanelBlockedForCurrentPage(sbExcludeListPagePaths || [])

    const userProfileData = useSelector(userProfileDataSelector)
    const [showBanner, setShowBanner] = useState(false)
    const [isCloseButtonClicked, setIsCloseButtonClicked] = useState(false)
    const firstRender = useRef(true)
    const isGuestUser = !checkDataLength(userProfileData)
    const isDesktop = window.innerWidth > BREAKPOINTS.tabletMaxWidth
    const currentTime = new Date().getTime()

    const isUserFirstVisit =
        sessionStorageService.getItem(IS_FIRST_PAGE_VISIT) !== null &&
        sessionStorageService.getItem(IS_FIRST_PAGE_VISIT)

    /**
     * function to decrease number pending displays on every visit
     * @param {number} count
     * @return {void}
     */
    const decrementPendingDisplayCount = (count: number): number => {
        return count - (MagicNumber.ONE as number)
    }

    /**
     * function to hide sticky banner and reset number pending displays in local storage
     * @return {void}
     */
    const handleCloseBtn = (): void => {
        setIsCloseButtonClicked(true)
        const bannerTimerData =
            appCacheService.emailStickyBannerTimer.get() !== null && appCacheService.emailStickyBannerTimer.get()
        const { userLastVisit } = bannerTimerData as StickyBannerTimerData
        const timer = {
            userLastVisit,
            pendingDisplay: MagicNumber.ZERO,
        } as unknown as StickyBannerTimerData
        appCacheService.emailStickyBannerTimer.set(JSON.stringify(timer))
    }

    /**
     * function to store number of pending displays, user last visit date and user first page visit data in local storage and session storage
     * @return {void}
     */
    const getTimer = useCallback(() => {
        let initialTimerData = {
            userLastVisit: currentTime,
            pendingDisplay: decrementPendingDisplayCount(sbNumberOfTimes),
        }
        const bannerTimerData =
            appCacheService.emailStickyBannerTimer.get() !== null && appCacheService.emailStickyBannerTimer.get()
        const { userLastVisit, pendingDisplay } = bannerTimerData as StickyBannerTimerData
        if (userLastVisit) {
            sessionStorageService.setItem(IS_FIRST_PAGE_VISIT, 'true')
            const difference = currentTime - Number(userLastVisit)
            const differenceInDays = Math.ceil(
                difference / (MagicNumber.ONETHOUSAND * MagicNumber.SIXTY * MagicNumber.SIXTY * MagicNumber.TWENTYFOUR),
            )
            if (differenceInDays > sbNumberOfDays) {
                appCacheService.emailStickyBannerTimer.set(JSON.stringify(initialTimerData))
                setIsCloseButtonClicked(false)
                setShowBanner(true)
            } else {
                if (pendingDisplay !== MagicNumber.ZERO) {
                    initialTimerData = {
                        userLastVisit,
                        pendingDisplay: decrementPendingDisplayCount(pendingDisplay),
                    } as unknown as StickyBannerTimerData
                    appCacheService.emailStickyBannerTimer.set(JSON.stringify(initialTimerData))
                    setShowBanner(true)
                } else {
                    setShowBanner(false)
                }
            }
        } else if ((sbEnableOnDesktop && isDesktop) || (sbEnableOnMobileAndTablet && !isDesktop)) {
            appCacheService.emailStickyBannerTimer.set(JSON.stringify(initialTimerData))
            sessionStorageService.setItem(IS_FIRST_PAGE_VISIT, 'true')
            setShowBanner(true)
        }
    }, [currentTime, isDesktop, sbEnableOnDesktop, sbEnableOnMobileAndTablet, sbNumberOfDays, sbNumberOfTimes])

    useEffect(() => {
        if (firstRender.current && !isUserFirstVisit) {
            getTimer()
        }
        firstRender.current = false
    }, [getTimer, isUserFirstVisit])

    /**
     * function to render close button
     * @return {JSX.Element}
     */
    const getCloseButton = (): JSX.Element => {
        return (
            <Button type="tertiary" onClick={() => handleCloseBtn()}>
                <Icon size="lg" type="ct-close" />
            </Button>
        )
    }

    /**
     * function to render button and links
     * @param {string} textVal
     * @param {string} urlVal
     * @param {string} sbEnableSignUpModelVal
     * @return {JSX.Element}
     */
    const renderElements = (): JSX.Element => {
        const { sbEnableSignUpModelVal, textVal, urlVal } = filterStickyBannerData(
            isDesktop,
            sbEnableSignUpModelOnDesktop as boolean,
            sbEnableSignUpModelOnMobileAndTab as boolean,
            sbDesktopText,
            sbMobileAndTabletText,
            sbDesktopUrl as string,
            sbMobileAndTabletDestinationUrl as string,
        )
        if (sbEnableSignUpModelVal) {
            return (
                <>
                    <Button
                        type="primary"
                        id="footer-sticky-email-signup-button"
                        ariaLabel={textVal}
                        onClick={() => gigyaLoadFunctions('ODP-LiteRegistration', undefined, '', false, false)}
                        aria-expanded="false"
                        aria-haspopup="true"
                        dap-wac-link-section={EMAIL_ACQUISITION_SIGNUP_STICKY_BANNER}
                        dap-wac-value={textVal}>
                        {textVal}
                    </Button>
                    {getCloseButton()}
                </>
            )
        } else {
            return (
                <>
                    <a
                        className={`${PREFIX}-email-sticky-banner-text-container--link`}
                        dap-wac-link="true"
                        dap-wac-loc={EMAIL_ACQUISITION_SIGNUP_STICKY_BANNER}
                        dap-wac-value={textVal}
                        href={urlVal}>
                        {textVal}
                    </a>
                    {getCloseButton()}
                </>
            )
        }
    }

    const isBannerVisible = !isBlockListPage && isGuestUser && showBanner && !isCloseButtonClicked

    /**
     * function to render sticky banner
     * @return {JSX.Element}
     */
    const renderStickyBanner = (): JSX.Element | null => {
        if ((sbEnableOnDesktop && isDesktop) || (sbEnableOnMobileAndTablet && !isDesktop)) {
            return (
                <div className={`${PREFIX}-email-sticky-banner ${PREFIX}-full-width-container`}>
                    <div
                        className={`${PREFIX}-email-sticky-banner-container ${PREFIX}-container`}
                        data-testid="footer-sticky-banner">
                        <div className={`${PREFIX}-email-sticky-banner-icon-container`}>
                            <div className={`${PREFIX}-email-sticky-banner-icon-container--icon`}>
                                <Icon size="lg" type={isDesktop ? sbDesktopIcon : sbMobileAndTabletIcon} />
                            </div>
                        </div>
                        <div className={`${PREFIX}-email-sticky-banner-text-container`}>{renderElements()}</div>
                    </div>
                </div>
            )
        }
        return null
    }

    return isBannerVisible && renderStickyBanner()
}

StickyBanner.propTypes = {
    sbNumberOfTimes: PropTypes.number.isRequired,
    sbNumberOfDays: PropTypes.number.isRequired,
    sbExcludeListPagePaths: PropTypes.array,
    sbEnableOnDesktop: PropTypes.bool,
    sbDesktopText: PropTypes.string.isRequired,
    sbDesktopIcon: PropTypes.string.isRequired,
    sbEnableSignUpModelOnDesktop: PropTypes.bool,
    sbDesktopUrl: PropTypes.string,
    sbEnableOnMobileAndTablet: PropTypes.bool,
    sbMobileAndTabletText: PropTypes.string.isRequired,
    sbMobileAndTabletIcon: PropTypes.string.isRequired,
    sbEnableSignUpModelOnMobileAndTab: PropTypes.bool,
    sbMobileAndTabletDestinationUrl: PropTypes.string,
}

export default StickyBanner
