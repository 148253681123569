import React from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import { OverviewProps } from './Overview.types'
import Icon from '../Icon'
import { checkDataLength } from '../../utils/checkDataLength'

/**
 * Overview component
 *
 * @param {OverviewProps} props - heading, body
 * @return {JSX.Element} returns Overview component
 */
const Overview: React.FC<OverviewProps> = (props: OverviewProps): JSX.Element => {
    const { bodyText } = props

    /**
     * Function to return RoadRatedSpecifications
     * @param {string} iconType
     * @param {string} iconTitle
     * @param {string} iconDescription
     * @return {JSX.Element}
     */
    const displayRoadRatedSpecifications = (
        iconType: string,
        iconTitle: string,
        iconDescription: string,
    ): JSX.Element => {
        return (
            <div className={`${PREFIX}-col-sm-12 ${PREFIX}-col-md-4 ${PREFIX}-col-xs-12`}>
                <div className={`${PREFIX}-overviewcontent__roadratingspecs`}>
                    <Icon type={iconType} size="lg" />
                    <div>
                        <h4 className={`${PREFIX}-overviewcontent__icon-title`}>{iconTitle}</h4>
                        <p>{iconDescription}</p>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            <div id="overview" className={`${PREFIX}-overviewcontent`} data-testid="overview">
                {checkDataLength(bodyText.longDescription) && (
                    <div className={`${PREFIX}-col-sm-12 ${PREFIX}-overviewcontent__grid`}>
                        <p className={`${PREFIX}-overviewcontent__description`}>{bodyText.longDescription}</p>
                    </div>
                )}

                <div className={`${PREFIX}-row`}>
                    {checkDataLength(props.isSnowPeakRated) &&
                        displayRoadRatedSpecifications(
                            'ct-snwpeak',
                            props.isSnowPeakRated.title,
                            props.isSnowPeakRated.discription,
                        )}
                    {checkDataLength(props.isMudSnowRated) &&
                        displayRoadRatedSpecifications(
                            'ct-madandsnow',
                            props.isMudSnowRated.title,
                            props.isMudSnowRated.discription,
                        )}
                    {checkDataLength(props.isLowResistanceRated) &&
                        displayRoadRatedSpecifications(
                            'ct-lowresistance',
                            props.isLowResistanceRated.title,
                            props.isLowResistanceRated.discription,
                        )}
                    {checkDataLength(props.isAcceptStuds) &&
                        displayRoadRatedSpecifications(
                            'ct-acceptstuds',
                            props.isAcceptStuds.title,
                            props.isAcceptStuds.discription,
                        )}
                </div>
            </div>
        </>
    )
}
Overview.propTypes = {
    bodyText: PropTypes.any,
    isSnowPeakRated: PropTypes.any,
    isMudSnowRated: PropTypes.any,
    isLowResistanceRated: PropTypes.any,
    isAcceptStuds: PropTypes.any,
}
export default Overview
