import { analyticsAttributes } from '../../globalConstants/analyticsParams.constant'
/**
 * Function to fire analytics when triangle Triangle MC Banner is viewed
 * @param {string} bannerName
 *  @param {string} bannerType
 */
export const onBannerViewAnalytics = (bannerName: string, bannerType: string): void => {
    window.analyticsLayer = window.analyticsLayer || []
    window.analyticsLayer.push({
        event: analyticsAttributes.event.promoView,
        promotion: [
            {
                creative: analyticsAttributes.instantMCPromotion.creative,
                name: bannerName,
                position: bannerType,
                componentType: analyticsAttributes.instantMCPromotion.componentType,
                cta: analyticsAttributes.instantMCPromotion.cta,
            },
        ],
    })
}

/**
 * Function to fire analytics when triangle Triangle MC Banner is clicked
 * @param {string} bannerName
 *  @param {string} bannerType
 */
export const onBannerClickAnalytics = (bannerName: string, bannerType: string): void => {
    window.analyticsLayer = window.analyticsLayer || []
    window.analyticsLayer.push({
        event: analyticsAttributes.event.promoClick,
        promotion: [
            {
                creative: analyticsAttributes.instantMCPromotion.creative,
                name: bannerName,
                position: bannerType,
                cta: analyticsAttributes.instantMCPromotion.cta,
                componentType: analyticsAttributes.instantMCPromotion.componentType,
            },
        ],
    })
}
