import React from 'react'

import { Icon, SkeletonComponent } from '@nl/lib'

import { BuyboxProps } from './BuyBox.type'
import { PREFIX } from '../../config'
import { ProductResponseData, ProductSku } from '../../redux/models/product.interface'
import AutomotiveProductDoesNotFit from '../AutomotivePDP/AutomotiveProductDoesNotFit/AutomotiveProductDoesNotFit'
import {
    isAutomotiveProductNotFit,
    seeProductFitVehicleCTAButton,
} from '../AutomotivePDP/AutomotiveBuyBox/AutomotiveBuyBox.helper'
import { Vehicle } from '../../redux/models/user.profile.interface'
import { getProductSchema } from '../../Seo/helpers/SeoSchema.helper'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { TireType } from '../../redux/models/tireVehicle.interface'
import { Price } from '../../redux/models/cart.interface'
import { scrollToReviews } from './scrollToReview.helper'
import ProductTriangleRewards from './ProductTriangleRewards/ProductTriangleRewards'
import { addForceLazyLoadingAttribute } from '../../hooks/useDataComponentObserver.hook'
import SanitizeStringContentWrapper from '@nl/lib/src/utils/sanitizeStringContent'

/**
 * Get Wishlist Icon React component.
 * @param {boolean} isAddedToWishlist
 * @param {boolean} showWishListSpinner
 * @return {JSX.Element}
 */
export const getWishListIconComponent = (isAddedToWishlist: boolean, showWishListSpinner = false): JSX.Element => {
    const iconType = isAddedToWishlist ? 'ct-favorite-active' : 'ct-favorite-inactive'
    return showWishListSpinner ? renderButtonSpinner() : <Icon type={iconType} size="lg" />
}

/**
 * Get Add To WishList Label.
 * @param {boolean} isAddedToWishlist
 * @param {string} addToWishListLabel
 * @param {string} addedToWishListLabel
 * @return {JSX.Element}
 */
export const getAddToWishListLabel = (
    isAddedToWishlist: boolean,
    addToWishListLabel = '',
    addedToWishListLabel = '',
): string => {
    return isAddedToWishlist ? addedToWishListLabel : addToWishListLabel
}

/**
 * Renders button spinner
 * @param {boolean} renderButtonSpinner
 * @return {JSX.Element}
 */
export const renderButtonSpinner = (): JSX.Element => {
    return <div className={`${PREFIX}-button__spinner`}></div>
}

/**
 * Get Returns Financing options component .
 * @param {BuyboxProps} props
 * @param {any} currentPrice
 * @param {string} language
 * @param {ProductResponseData} productData
 * @return {JSX.Element}
 */
export const renderFinancialComponent = (
    props: BuyboxProps,
    currentPrice: Price,
    language: string,
    productData?: ProductResponseData,
): JSX.Element => {
    const {
        monthlyPriceLabel,
        numberOfMonths,
        cardTitleLoggedInCCUser,
        cardImageLoggedInCCUser,
        cardImageAltTextLoggedInCCUser,
        cardImage,
        cardTitle,
        cardImageAltText,
        cardTitleRewardsUser,
        cardImageRewardsUser,
        cardImageAltTextRewardsUser,
        ctaLinkTarget,
        ctaLabel,
        ctaLink,
        ctaA11yLabel,
        ctaLabelRewardsUser,
        ctaLinkRewardsUser,
        ctaA11yLabelRewardsUser,
        ctaLinkTargetRewardsUser,
        cardDescription,
        ctmoneybackmessage,
        ctMoneyTooltipHeading,
        ctMoneyTooltipDescription,
        creditOfferedMessageQuebec,
        creditOfferedTooltipDescQuebec,
        creditOfferedTooltipHeadingQuebec,
        paymentInstallmentTooltipHeading,
        paymentInstallmentTooltipDescription,
        upsellingPercentage,
        ctaLabelNonRewards,
        ctaLinkNonRewards,
        perMonthLabel,
        selectMembershipLabel,
        selectMembershipImg,
        selectMembershipImgAltText,
        selectMembershipCTALabel,
        selectMembershipCTAUrl,
        selectMembershipTarget,
        selectMembershipCtaA11yLabel,
        selectMembershipText,
        selectMembershipTooltipHeading,
        selectMembershipTooltipDesc,
        selectEverydayInstoreBonusText,
        selectEverydayInstoreBonusTooltipHeading,
        selectEverydayInstoreBonusTooltipDesc,
        selectInstoreBrandBoostText,
        selectInstoreBrandBoostTooltipHeading,
        selectInstoreBrandBoostTooltipDesc,
        selectAdditionalBrandBoostText,
        selectAdditionalBrandBoostTooltipHeading,
        selectAdditionalBrandBoostTooltipDesc,
        selectCtMoneyAndBrandBoostTextQuebec,
        selectCtMoneyAndBrandBoostTooltipHeadingQuebec,
        selectCtMoneyAndBrandBoostTooltipDescQuebec,
        tdisplay,
        displaySelectMembershipUpsell,
        selectCTMoneyBackText,
        selectCTMoneyBackTooltipHeading,
        selectCTMoneyBackTooltipDesc,
    } = props
    const rewardProps = {
        cardTitleLoggedInCCUser,
        cardImageLoggedInCCUser,
        cardImageAltTextLoggedInCCUser,
        cardImage,
        cardTitle,
        cardImageAltText,
        cardTitleRewardsUser,
        cardImageRewardsUser,
        cardImageAltTextRewardsUser,
        ctaLinkTarget,
        ctaLabel,
        ctaLink,
        ctaA11yLabel,
        ctaLabelRewardsUser,
        ctaLinkRewardsUser,
        ctaA11yLabelRewardsUser,
        ctaLinkTargetRewardsUser,
        cardDescription,
        ctmoneybackmessage,
        productPrice: currentPrice?.value || currentPrice?.minPrice || 0,
        language,
        monthlyPriceLabel,
        numberOfMonths,
        ctMoneyTooltipHeading,
        ctMoneyTooltipDescription,
        creditOfferedMessageQuebec,
        creditOfferedTooltipDescQuebec,
        creditOfferedTooltipHeadingQuebec,
        paymentInstallmentTooltipHeading,
        paymentInstallmentTooltipDescription,
        upsellingPercentage,
        ctaLabelNonRewards,
        ctaLinkNonRewards,
        perMonthLabel,
        productData: productData,
        selectMembershipLabel,
        selectMembershipImg,
        selectMembershipImgAltText,
        selectMembershipCTALabel,
        selectMembershipCTAUrl,
        selectMembershipTarget,
        selectMembershipCtaA11yLabel,
        selectMembershipText,
        selectMembershipTooltipHeading,
        selectMembershipTooltipDesc,
        selectEverydayInstoreBonusText,
        selectEverydayInstoreBonusTooltipHeading,
        selectEverydayInstoreBonusTooltipDesc,
        selectInstoreBrandBoostText,
        selectInstoreBrandBoostTooltipHeading,
        selectInstoreBrandBoostTooltipDesc,
        selectAdditionalBrandBoostText,
        selectAdditionalBrandBoostTooltipHeading,
        selectAdditionalBrandBoostTooltipDesc,
        selectCtMoneyAndBrandBoostTextQuebec,
        selectCtMoneyAndBrandBoostTooltipHeadingQuebec,
        selectCtMoneyAndBrandBoostTooltipDescQuebec,
        tdisplay,
        displaySelectMembershipUpsell,
        selectCTMoneyBackText,
        selectCTMoneyBackTooltipHeading,
        selectCTMoneyBackTooltipDesc,
    }
    return tdisplay ? <ProductTriangleRewards {...rewardProps} /> : <></>
}

interface BazaarVoiceReviewProps {
    productData: ProductResponseData
    isMobile: boolean
}

export const BazaarVoiceReview = ({ productData, isMobile }: BazaarVoiceReviewProps): JSX.Element => {
    const isProductDataAvailable = checkDataLength(productData)

    const onClickHandler = () => {
        addForceLazyLoadingAttribute('', () => scrollToReviews(false, productData, isMobile))
    }

    return (
        <>
            {isProductDataAvailable ? (
                <div
                    className={`${PREFIX}-buy-box__ratings-btn`}
                    data-bv-show="inline_rating"
                    data-bv-seo="false"
                    data-bv-product-id={productData.code}
                    role="button"
                    onKeyPress={onClickHandler}
                    onClick={onClickHandler}
                    tabIndex={0}
                />
            ) : null}
        </>
    )
}

/**
 * Renders a notification about current store is not accepting online orders
 * @param {string | undefined} message
 * @return {JSX.Element}
 */
export const getStoreStatusMessageComponent = (message: string | undefined): JSX.Element => {
    return (
        <div className={`${PREFIX}-buy-box__store-status`}>
            <div>
                <Icon type="ct-notification-caution" size="lg" />
            </div>
            <span className={`${PREFIX}-buy-box__store-status_message`}>{message}</span>
        </div>
    )
}

/**
 * function to render automotive product doesnt fit
 * @param {BuyboxProps} props
 * @param {ProductResponseData} productData
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setIsSKUVisible
 * @param {boolean} isSuggestiveDropdownState
 * @param {boolean} isTireSizePresent
 * @param {boolean} isCriticalFitmentApiDone
 * @param {TireType} tiresData
 * @return {JSX.Element}
 */
export const renderAutomotiveProductDoesNotFit = (
    props: BuyboxProps,
    productData: ProductResponseData,
    setIsSKUVisible: React.Dispatch<React.SetStateAction<boolean>>,
    isSuggestiveDropdownState: boolean,
    isTireSizePresent?: boolean,
    isCriticalFitmentApiDone?: boolean,
    tiresData?: TireType | null,
): JSX.Element | null => {
    return (tiresData || isCriticalFitmentApiDone) && (!isSuggestiveDropdownState || tiresData) ? (
        <AutomotiveProductDoesNotFit
            productDoesNotFitPrompt={
                isTireSizePresent ? props.productDoesNotFitTireSizePrompt : props.productDoesNotFitPrompt
            }
            productDoesNotFitCopy={
                isTireSizePresent ? props.productDoesNotFitTireSizeCopy : props.productDoesNotFitCopy
            }
            seeProductFitVehicleCTAButton={seeProductFitVehicleCTAButton(
                productData,
                props.seeWheelsFitVehicleCTA as string,
                props.seeProductFitVehicleCTA as string,
                props.seeTiresFitVehicleCTA as string,
                props.seeProductFitTireSizeCTA as string,
                isTireSizePresent as boolean,
            )}
            setIsSKUVisible={setIsSKUVisible}
        />
    ) : !isSuggestiveDropdownState ? (
        <SkeletonComponent skeletonClass={`${PREFIX}-product-does-not-fit__skeleton`} />
    ) : null
}

/**
 * function to render ProductNotFit Or FinancialComponent
 * @param {boolean} isFitmentRequired
 * @param {Vehicle} vehicle
 * @param {BuyboxProps} props
 * @param {ProductResponseData} productData
 * @param {Price} cPrice
 * @param {string} language
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setIsSKUVisible
 * @param {boolean} isSuggestiveDropdownState
 * @param {boolean} isTireSizePresent
 * @param {boolean} isCriticalFitmentApiDone
 * @param {TireType} tiresData
 * @return {JSX.Element}
 */
export const renderProductNotFitOrFinancialComponent = (
    isFitmentRequired: boolean,
    vehicle: Vehicle,
    props: BuyboxProps,
    productData: ProductResponseData,
    cPrice: Price,
    language: string,
): JSX.Element | null => {
    return isAutomotiveProductNotFit(isFitmentRequired, vehicle)
        ? null
        : renderFinancialComponent({ ...props }, cPrice, language, productData)
}

/**
 * function to render schema Elements/Tag for Product
 * @param {ProductResponseData} productData
 * @param {ProductSku} productSkuData
 * @param {string} storeId
 * @param {boolean} isOutOfStock
 * @param {string} defaultProductImage
 * @param {boolean} enableMPNForAll
 * @param {boolean} enableMPNForAutomotive
 * @return {JSX.Element | null}
 */
export const renderProductSchema = (
    productData: ProductResponseData,
    productSkuData: ProductSku,
    storeId: string,
    isOutOfStock: boolean,
    defaultProductImage: string,
    enableMPNForAll?: boolean,
    enableMPNForAutomotive?: boolean,
): JSX.Element | null => {
    const productSchema = getProductSchema(
        productData,
        productSkuData,
        storeId,
        isOutOfStock,
        defaultProductImage,
        enableMPNForAll,
        enableMPNForAutomotive,
    )
    return !!productSchema ? (
        <SanitizeStringContentWrapper stringContent={productSchema}>
            {memoizedStringContent => (
                <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: memoizedStringContent }} />
            )}
        </SanitizeStringContentWrapper>
    ) : null
}
