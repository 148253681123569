import { useMemo, useEffect, useState } from 'react'
import { BREAKPOINTS } from '../../config'
import { getParameterFromURL, queryParameters } from '@nl/lib'
import { constantValues } from './ProductCard.constant'

export const useProductPerPage = (): number => {
    return useMemo(() => {
        const isDesktopViewport = window.innerWidth > BREAKPOINTS.tabletMaxWidth
        return isDesktopViewport
            ? constantValues.productPerPageForDesktopViewport
            : constantValues.productPerPageForMobileAndTabletViewport
    }, [])
}

export const useCurrentPage = () => {
    const pageNumberFromURL = Number(getParameterFromURL(queryParameters.page))

    const [currentPage, setCurrentPage] = useState(
        pageNumberFromURL > 0 ? pageNumberFromURL : constantValues.currentPageInitialValue,
    )

    useEffect(() => {
        if (!pageNumberFromURL) {
            setCurrentPage(constantValues.currentPageInitialValue)
        }
    }, [pageNumberFromURL, setCurrentPage])

    return { currentPage, setCurrentPage }
}
