import React, { useRef } from 'react'
import { PREFIX } from '../../config'
import { Icon } from '@nl/lib'
import { useGlobalScrollEvent } from '@nl/lib/src/utils/useWindowEvent'
import checkNestedProps from '../../utils/checkNestedProps'
import { useSelector } from 'react-redux'
import { STICKY_TO_BOTTOM } from '../../globalConstants'
import { useMobileLayoutState } from '../../hooks/layout.hook'
import { commonContentSelector } from '../../redux/selectors/commonContent.selectors'

export const calculateComponentPosition = (isMobileLayout: boolean): number => {
    // Initialize bottom values
    const pageBottom = window.innerHeight
    const allTopPositions = [pageBottom]

    // If Mobile
    if (isMobileLayout) {
        // Get possible styky elements to the bottom of the page
        // Sticky Buybox for ex and also loyalty banner when NOT on top of the footer
        const stickyToBottomElems = document.querySelectorAll(`
            .${STICKY_TO_BOTTOM}, 
            .${PREFIX}-loyalty-offers_container.on-bottom .${PREFIX}-loyalty-offers_toggle-btn-wrapper
        `)
        // Add stiky elements to position array to calculate the highest
        allTopPositions.push(...Array.from(stickyToBottomElems).map(e => e.getBoundingClientRect().top))
    }
    // get the highest element visible in page bottom
    const highestStickyElementTopPosition = Math.min(...allTopPositions)
    // calculate the distance from the page bottom and return it
    return Math.ceil(pageBottom - highestStickyElementTopPosition)
}

/**
 * BackToTop component
 * @param {ProductDataTypeObj} props
 * @return {JSX.Element} returns BackToTop component
 */
const BackToTop = (): JSX.Element => {
    const { commonContentAvailable } = useSelector(commonContentSelector)
    const backToTopLabel = checkNestedProps(commonContentAvailable, 'general', 'backToTopLabel') as string
    const a11yBackToTopLabel = checkNestedProps(commonContentAvailable, 'accessibility', 'a11yBackToTopLabel') as string
    const [isMobileLayout] = useMobileLayoutState() as [boolean]

    let showBackToTopButton = false
    let oldScrollValue = 0
    let newScrollValue = 0

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    const handleScroll = (): void => {
        newScrollValue = window.pageYOffset
        if (oldScrollValue < newScrollValue || userIsAtTop()) {
            showBackToTopButton = false
        } else if (oldScrollValue > newScrollValue) {
            showBackToTopButton = true
        }
        oldScrollValue = newScrollValue
        setBackToTopButtonDisplay()
    }

    const userIsAtTop = (): boolean => {
        return window.scrollY <= 0
    }

    const setBackToTopButtonDisplay = (): void => {
        const bottom = calculateComponentPosition(isMobileLayout)
        if (btnRef !== null && btnRef.current) {
            btnRef.current.style.bottom = `${bottom}px`
            btnRef.current.style.display = showBackToTopButton ? 'unset' : 'none'
        }
    }

    const btnRef = useRef<null | HTMLDivElement>(null)
    const compClass = `${PREFIX}-backToTop`

    useGlobalScrollEvent(handleScroll)

    return (
        <div className={compClass} ref={btnRef}>
            <button className={`${compClass}__button`} onClick={scrollToTop} aria-label={a11yBackToTopLabel}>
                <div>
                    <Icon type="ct-chevron-up" size="md" />
                    <span className={`${compClass}__label`}>{backToTopLabel}</span>
                </div>
            </button>
        </div>
    )
}

export default BackToTop
