import { getProvider } from '../providers/index'
import { SearchAnalytics } from '@nl/lib'

const nullTernar = (value: string | undefined): string | null => {
    return value || null
}

export const searchAnalytics = (data: SearchAnalytics): void => {
    getProvider().push({
        event: data.event,
        search: {
            userInput: data.userInput ? data.userInput : '',
            searchType: nullTernar(data.type),
            suggestionType: nullTernar(data.suggestionType),
            suggestionValue: nullTernar(data.suggestionValue),
            suggestionUserJourney: nullTernar(data.userJourney),
            suggestionEngagementType: data.suggestionEngagementType,
        },
    })
}

export default searchAnalytics
