import {
    LinkingType,
    UpsellingProps,
} from '../components/OrderConfirmation/TriangleRewardsUpsell/TriangleRewardsUpsell.type'
import { userState } from '../config'
import {
    TriangleRewardsCardType,
    CardPropsType,
} from '../components/BuyBox/ProductTriangleRewards/ProductTriangleRewards.type'
import { separatorProps } from '../globalConstants/global.constant'
import { getCTA } from '@nl/lib'

/**
 * reusable function to generate upselling props to pass to heroBannerSplit component
 *
 * @param {string} title
 * @param {string} subtitle
 * @param {string} disclaimerMessage
 * @param {string} btn
 * @param {LinkingType} link
 * @param {boolean} isForm
 * @param {React.FormEvent} onClickHandler
 * @param {boolean} isHideCta
 * @param {boolean} isButtonCta
 * @return {UpsellingProps}
 */
export const getUpsellingProps = (
    title: string,
    subtitle: string,
    disclaimerMessage: string,
    btn: string,
    link: LinkingType,
    isForm: boolean,
    onClickHandler?: (e: React.FormEvent) => void,
    isHideCta?: boolean,
    isButtonCta?: boolean,
): UpsellingProps => {
    return {
        title,
        subtitle,
        disclaimerMessage,
        btn,
        link,
        isForm,
        onClickHandler,
        isHideCta,
        isButtonCta,
    }
}

/**
 * function to display based on userType props will get changed for guest and non-loyalty user
 * @param {string} userType
 * @param {TriangleRewardsCardType} props
 * @param {boolean} offset
 * @return {CardPropsType}
 */
export const getRewardsCardProps = (
    userType: string,
    props: TriangleRewardsCardType,
    offset?: boolean,
): CardPropsType => {
    const {
        cardTitle,
        cardDescription,
        ctaLabel,
        ctaLink,
        ctaLinkTarget,
        ctaA11yLabel,
        cardImage,
        cardImageAltText,
        ctaLabelNonRewards,
        ctaLinkNonRewards,
    } = props

    const defaulPropValues: CardPropsType = {
        title: cardTitle,
        description: cardDescription,
        imageOffSet: offset || false,
        cta: getCTA(ctaLabel, ctaLink, ctaLinkTarget, ctaA11yLabel),
        image: cardImage,
        imageAlt: cardImageAltText,
        separatorProps: separatorProps,
    }

    let cardProps: CardPropsType

    switch (userType) {
        case userState.guest:
            cardProps = defaulPropValues
            break
        case userState.noLoyaltyCard:
            cardProps = defaulPropValues
            cardProps.cta = getCTA(ctaLabelNonRewards, ctaLinkNonRewards, ctaLinkTarget, ctaA11yLabel)
            break
        default:
            cardProps = defaulPropValues
            break
    }
    return cardProps
}
