import { createAction } from 'typesafe-actions'

import { ProductDataType, ProductDataTypeObj, SortOption } from '../models/productData.interface'
import { ProductResponseErrorDTO } from '../models/product.interface'
import {
    FETCH_PRODUCT_SUCCESS,
    FETCH_WEATHER_TECH_PRODUCT_SUCCESS,
    FETCH_PRODUCT_FAILURE,
    FETCH_LOAD_MORE_PRODUCT_SUCCESS,
    UPDATE_FACET_CLICK,
    UPDATE_SELECTED_SORT,
    CLEAR_PRODUCT_LIST,
    FETCH_PRODUCT_REQUEST,
} from '../types/products/productData.actionTypes.constant'

export const fetchProductCardDataRequestAction = createAction(FETCH_PRODUCT_REQUEST)()

export const fetchProductCardDataSuccessAction = createAction(
    FETCH_PRODUCT_SUCCESS,
    (payload: ProductDataType) => payload,
)<ProductDataType>()

export const fetchWeatherTechProductCardDataSuccessAction = createAction(
    FETCH_WEATHER_TECH_PRODUCT_SUCCESS,
    (payload: ProductDataType) => payload,
)<ProductDataType>()

export const fetchLoadMoreProductDataSuccessAction = createAction(
    FETCH_LOAD_MORE_PRODUCT_SUCCESS,
    (payload: ProductDataTypeObj[]) => payload,
)<ProductDataTypeObj[]>()

export const fetchProductCardDataErrorAction = createAction(
    FETCH_PRODUCT_FAILURE,
    (payload: ProductResponseErrorDTO) => payload,
)<ProductResponseErrorDTO>()

export const updateFacetClickAction = createAction(UPDATE_FACET_CLICK)<string>()

export const updateSelectedSortAction = createAction(UPDATE_SELECTED_SORT)<SortOption>()

export const clearProductListAction = createAction(CLEAR_PRODUCT_LIST)()
