import { ActionType, getType } from 'typesafe-actions'
import { vehicleTireSizeFormRenderedAction } from '../actionCreators/automotiveBuyBox.actionCreators'

type Action = ActionType<typeof vehicleTireSizeFormRenderedAction>

export interface AutomotiveBuyBoxState {
    vehicleTireSizeFormRendered: boolean
}
export const initialState: AutomotiveBuyBoxState = {
    vehicleTireSizeFormRendered: false,
}
export const automotiveBuyBoxReducer = (
    state: AutomotiveBuyBoxState = initialState,
    action: Action,
): AutomotiveBuyBoxState => {
    if (action.type === getType(vehicleTireSizeFormRenderedAction)) {
        return { ...state, vehicleTireSizeFormRendered: action.payload }
    } else {
        return state
    }
}
