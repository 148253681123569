import { ProductSku } from '../../../../app/src/redux/models/product.interface'
/**
 * Interface for Price
 * @interface
 */
export interface Price {
    value?: number | null
    maxPrice?: number | null
    minPrice?: number | null
}

/**
 * Interface for PriceMessage
 * @interface
 */
export interface PriceMessage {
    label?: string | null
    tooltip?: string | null
}

/**
 * Interface for SaleMessagesRules
 * @interface
 */
export interface SaleMessagesRule {
    minPercent: number
    absoluteValueThreshold: number
    maxPercent: number
    upToSaveMessage: string
    absoluteCutType: string
    saveMessage: string
}

/**
 * Interface for PriceType
 * @interface
 */
export interface PriceType {
    priceStyle?: string
    promotionalPriceLabel?: string
    thresholdValue?: number
    unitPriceLabel?: string
    clearancePriceLabel?: string
    a11yPriceRangeFrom?: string
    a11yPriceRangeTo?: string
    hideSaveLabel?: boolean
    a11yStrikeOutPrice?: string
    a11yStrikeOutPriceRange?: string
    a11yTooltipIcon?: string
    a11yCloseIconLabel?: string
    feeTitle?: string
    feeDisclaimerMessage?: string
    feeDisclaimerTitle?: string
    originalPrice?: Price
    currentPrice?: Price
    displayWasLabel?: boolean
    discountValue?: Price
    language?: string
    eachLabel?: string
    isMoreQuantityCartPage?: boolean
    priceValue?: Price
    priceMessage?: PriceMessage[]
    isAutomotiveEachLabel?: boolean
    scrollToFooter?: (event) => void
    a11yRecycleFeesTriangleIcon?: string
    nowFromLabel?: string
    saveFromLabel?: string
    wasFromLabel?: string
    fromLabel?: string
    feeDisclaimerType?: string
    isVehicleInformationSaved?: boolean
    isWheelOrTirePDP?: boolean
    totalCurrentPrice?: Price
    totalOriginalPrice?: Price
    setOfFourLabel?: string
    setOfFourNowLabel?: string
    isWheelOrTire?: boolean
    productSkus?: ProductSku[]
    isWishList?: boolean
    isStaggered?: boolean
    isStaggeredFront?: boolean
    showFromNow?: boolean
    isRebate?: boolean
    isShoppingCartPage?: boolean
    showEachTextForSaveLabel?: boolean
    overridePriceHeight?: boolean
    showSaveMessage?: boolean
    saveMessage?: string
    showDiscountedPrice?: boolean
    highlightDiscountedPrice?: boolean
    totalPrice?: Price
    isAppliedPromoExist?: boolean
    enablePdpPricingEnhancement?: boolean
    a11yClickToReadFootnote?: string
    accessibilityPriceId?: string
    ariaHidden?: boolean
    isOnSaleOrClearance?: boolean
    fromAutomotiveSpecification?: boolean
}

export enum FeeType {
    ECO_FEE = 'ECO_FEE',
    CORE_CHARGE = 'CORE_CHARGE',
    TIRE_RECYCLE_FEE = 'TIRE_RECYCLE_FEE',
}

/**
 * Interface for FeeMessage
 * @interface
 */
export interface FeeMessage {
    feeDisclaimerTitle?: string
    feeDisclaimerMessage?: string
    feeTitle?: string
    type?: string
    value?: number
}

/**
 * Interface for SaleCut
 * @interface
 */
export interface SaleCut {
    percentage?: number | null
    value?: number | null
}
