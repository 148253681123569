import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { PREFIX, SIDEBAR_MODAL_OPEN_CLASS } from '../../../config'
import { SideNavigationProps } from './SideNavigation.type'
import {
    Icon,
    CTMoneySnippet,
    getBalance,
    magicNumber,
    extractKeyValues,
    removeHtmlTagsFromString,
    urlRedirection,
} from '@nl/lib'
import AccountsPencilBanner from '../../AccountsPencilBanner'
import SecondaryNavigationWrapper from '../../SecondaryNavigation/SecondaryNavigationWrapper'
import { checkDataLength } from '../../Accounts/Addresses/checkDataLength'
import { setMenuPosition } from './SideNavigation.helper'
import { useGlobalResizeEvent } from '@nl/lib'
import { useDispatch } from 'react-redux'
import { setSideNavigationPopupToggleState } from '../../../redux/actionCreators/popupsToggleState.actionCreators'
import { getStoreLocatorIsOpen } from '../../../redux/reducers/popupsToggleState.reducer'
import { useClickOutsideClose } from '@nl/lib/src/components/Tooltip/useClickOutside'
import HeaderNavigationHelper from '../../../helpers/headerNavigation.helper'
import { browseOnlyModeSelector, commonContentSelector } from '../../../redux/selectors/commonContent.selectors'
import { RootState } from '../../../redux/reducers'
import SanitizeStringContentWrapper from '@nl/lib/src/utils/sanitizeStringContent'
/**
 * Side Navigation component
 * @param {SideNavigationProps} props
 * @return {JSX.Element} returns side navigation component
 */
const SideNavigation: React.FC<SideNavigationProps> = props => {
    const {
        wishlistUrl,
        a11yWishlist,
        wishlistCount,
        maximumCount,
        path,
        a11yCloseIconLabel,
        a11yMenuIconLabel,
        pencilBannerProps,
        secondaryNavProps,
    } = props
    const sideMenuRef = useRef(null)
    const sideMenuContainerRef = useRef(null)
    const [showMenu, setShowMenu] = useState(false)
    const { commonContentAvailable } = useSelector(commonContentSelector)
    const storeLocatorIsOpen = useSelector(getStoreLocatorIsOpen)
    const isBrowseOnlyMod = useSelector(browseOnlyModeSelector)
    const dispatch = useDispatch()
    const userProfileData = useSelector((state: RootState) => state.userProfile.userProfileData)
    const isLoggedIn = userProfileData ? Boolean(Object.keys(userProfileData).length) : false
    const isLoyalAccountLinked = !!userProfileData?.loyalty?.cardNumber
    const [[registerNowLink, registerLabel], [registrationPageLink]] = extractKeyValues(commonContentAvailable, [
        { accountDashboard: ['registerNowLink', 'registerLabel'] },
        { globalLinks: ['registrationPageLink'] },
    ]) as Array<string[]>

    const { ctMoneyBalanceLabel, ctMoneyBalanceURL, ctMoneyBalanceTarget, ctMoneyBalanceAllyLabel } =
        pencilBannerProps?.parentOptions

    const { signInLabel } = HeaderNavigationHelper.getPencilBannerGlobalProps(commonContentAvailable)

    /**
     * function to show side navigation menu
     */
    const showSideMenu = (): void => {
        dispatch(setSideNavigationPopupToggleState(true))
        setShowMenu(true)
    }

    /**
     * function to hide side navigation menu
     */
    const hideSideMenu = (): void => {
        setShowMenu(false)
    }

    useClickOutsideClose(sideMenuContainerRef, hideSideMenu, true, true)

    useEffect(() => {
        if (storeLocatorIsOpen) {
            setShowMenu(false)
            dispatch(setSideNavigationPopupToggleState(false))
        }
    }, [dispatch, setShowMenu, storeLocatorIsOpen])

    useEffect(() => {
        const stickyElem = document.getElementById(`${PREFIX}-sticky-buy-box-mobile`)
        const navElem = document.getElementById(`${PREFIX}-nav`)
        const stickyComElementCls = 'sidebar-nav-elem'

        if (showMenu) {
            document.body.classList.add(SIDEBAR_MODAL_OPEN_CLASS)
            setMenuPosition(`.${PREFIX}-side-menu`)
            navElem?.classList.add(stickyComElementCls)
            stickyElem?.classList.add(stickyComElementCls)
        } else {
            document.body.classList.remove(SIDEBAR_MODAL_OPEN_CLASS)
            navElem?.classList.remove(stickyComElementCls)
            stickyElem?.classList.remove(stickyComElementCls)
        }
    }, [showMenu])

    const calculateHeaderHeight = useCallback(() => {
        const timeOut = 200
        setTimeout(() => {
            setMenuPosition(`.${PREFIX}-side-menu`)
        }, timeOut)
    }, [])

    const setHeight = () => {
        if (showMenu) {
            setMenuPosition(`.${PREFIX}-side-menu`)
        }
    }

    useGlobalResizeEvent(setHeight)

    useEffect(() => {
        if (showMenu) {
            window.addEventListener('orientationchange', calculateHeaderHeight)
        }
        return () => {
            window.removeEventListener('orientationchange', calculateHeaderHeight)
        }
    }, [showMenu, calculateHeaderHeight])

    const renderRegisterOrLinkCards = (): string => {
        return isLoggedIn ? registerNowLink : registrationPageLink
    }

    const renderCTBalance = (): JSX.Element => {
        return isLoyalAccountLinked ? (
            <>
                <Icon size="lg" type="ct-triangle" />
                <CTMoneySnippet
                    balance={getBalance(userProfileData?.balance || magicNumber.ZERO.toString())}
                    title={ctMoneyBalanceLabel}
                    url={ctMoneyBalanceURL}
                    target={ctMoneyBalanceTarget}
                    allyLabel={ctMoneyBalanceAllyLabel}></CTMoneySnippet>
            </>
        ) : (
            <></>
        )
    }

    const getText = removeHtmlTagsFromString(registerLabel)

    const renderRewardsSection = (): JSX.Element => {
        const authClass = isLoggedIn && isLoyalAccountLinked ? `${PREFIX}-join-triangle-rewards-logged-in` : ''

        return (
            <span className={`${PREFIX}-join-triangle-rewards ${authClass}`}>
                {isLoggedIn && isLoyalAccountLinked ? (
                    renderCTBalance()
                ) : (
                    <SanitizeStringContentWrapper stringContent={renderRegisterOrLinkCards()}>
                        {memoizedStringContent => (
                            <a
                                className={`${PREFIX}-button ${PREFIX}-button--secondary ${PREFIX}-button--mini`}
                                href={memoizedStringContent}
                                title={getText}
                                aria-label={getText}
                                data-link-value={getText}>
                                <Icon size="lg" type="ct-join-triangle-rewards-red" />
                                <SanitizeStringContentWrapper stringContent={registerLabel}>
                                    {innerMemoizedStringContent => (
                                        <span dangerouslySetInnerHTML={{ __html: innerMemoizedStringContent }}></span>
                                    )}
                                </SanitizeStringContentWrapper>
                            </a>
                        )}
                    </SanitizeStringContentWrapper>
                )}
            </span>
        )
    }

    const showPencilBanner = (isSingleSignInRender: string) => {
        return (
            checkDataLength(pencilBannerProps as unknown as Record<string, string>) && (
                <AccountsPencilBanner
                    {...pencilBannerProps}
                    showMenu={showMenu}
                    a11yWishlist={a11yWishlist}
                    wishlistUrl={wishlistUrl}
                    wishlistCount={wishlistCount}
                    maximumCount={maximumCount}
                    isSingleSignInRender={isSingleSignInRender}
                />
            )
        )
    }

    const renderLoginState = (): JSX.Element | null => {
        return !isLoggedIn ? (
            <a
                className={`${PREFIX}-button ${PREFIX}-button--primary ${PREFIX}-button--mini`}
                href={urlRedirection(
                    window?.ODP?.globalLinks.loginPageLink,
                    'returnURL',
                    encodeURIComponent(window.location.pathname),
                )}
                title={signInLabel}
                aria-label={signInLabel}
                data-link-value={signInLabel}>
                <Icon size="md" type="ct-account" />
                <span dangerouslySetInnerHTML={{ __html: signInLabel }}></span>
            </a>
        ) : (
            <div className={`${PREFIX}-side-menu__account-banner`}>{showPencilBanner('true')}</div>
        )
    }

    return (
        <>
            {showMenu ? (
                <div ref={sideMenuRef}>
                    <button
                        className={`${PREFIX}-side-menu__icon ${PREFIX}-md-none`}
                        aria-label={a11yCloseIconLabel}
                        onClick={hideSideMenu}
                        data-testid="header-close-icon">
                        <Icon type="ct-close" size="lg" path={path} />
                    </button>

                    <div className={`${PREFIX}-side-menu ${PREFIX}-md-none`}>
                        <div className={`${PREFIX}-side-menu__container`} ref={sideMenuContainerRef}>
                            <div className={`${PREFIX}-side-menu__banner`}>
                                {!isBrowseOnlyMod && renderLoginState()}
                            </div>

                            <div className={`${PREFIX}-side-menu__navigation-links`}>
                                {!isBrowseOnlyMod && renderRewardsSection()}

                                {checkDataLength(secondaryNavProps as unknown as Record<string, string>) && (
                                    <>
                                        <SecondaryNavigationWrapper {...secondaryNavProps} isSideNav={true} />
                                    </>
                                )}
                            </div>
                            <div className={`${PREFIX}-side-menu__pencil-banner`}>{showPencilBanner('false')}</div>
                        </div>
                    </div>
                </div>
            ) : (
                <button
                    className={`${PREFIX}-side-menu__icon ${PREFIX}-md-none`}
                    aria-label={a11yMenuIconLabel}
                    onClick={showSideMenu}
                    data-testid="header-hamburger-icon">
                    <Icon type="ct-menu" size="lg" path={path} />
                </button>
            )}
        </>
    )
}

SideNavigation.propTypes = {
    wishlistUrl: PropTypes.string.isRequired,
    a11yWishlist: PropTypes.string.isRequired,
    wishlistCount: PropTypes.number.isRequired,
    path: PropTypes.string,
    maximumCount: PropTypes.number.isRequired,
    a11yCloseIconLabel: PropTypes.string.isRequired,
    a11yMenuIconLabel: PropTypes.string,
    pencilBannerProps: PropTypes.any,
    secondaryNavProps: PropTypes.any,
}

export default SideNavigation
