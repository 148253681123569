import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../Icon/Icon'
import { PREFIX } from '../config'
import { switchProps } from './Switch.type'
import { stringKeyCodes } from '../../utils'

/**
 * Switch component
 * @param {switchProps} props
 * @return {JSX.Element} returns Label,Icon and checkbox
 */
const Switch: React.FC<switchProps> = props => {
    const { checked, label, icon, path, handleInputChange, mainClass } = props
    return (
        <>
            <div
                tabIndex={0}
                role="button"
                onClick={event => {
                    handleInputChange(event)
                }}
                onKeyDown={event => {
                    if (event.key === stringKeyCodes.enter || event.key === stringKeyCodes.space) {
                        handleInputChange(event)
                    }
                }}
                className={mainClass}>
                <label className={`${PREFIX}-switch`}>
                    {' '}
                    {label}
                    <input tabIndex={-1} type="checkbox" checked={checked} data-testid="toggle-switch" />
                    <span className={`${PREFIX}-switch__slider`}> </span>
                </label>
            </div>
            {icon && <Icon type={icon} size="sm" path={path} />}
        </>
    )
}

Switch.defaultProps = {
    label: '',
    icon: '',
    mainClass: '',
}
Switch.propTypes = {
    checked: PropTypes.bool,
    label: PropTypes.string,
    icon: PropTypes.string,
    path: PropTypes.string,
    handleInputChange: PropTypes.func,
    mainClass: PropTypes.string,
}

export default Switch
