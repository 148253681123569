import React from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { DEFAULT_CHANGE_LABEL } from '../BookingSummary.constants'
import { BookingSummaryDataBlockProps } from '../BookingSummary.type'

import { PREFIX } from '../../../config'
import checkNestedProps from '../../../utils/checkNestedProps'
import { commonContentSelector } from '../../../redux/selectors/commonContent.selectors'

/**
 * BookingSummaryDataBlock component
 * @param {BookingSummaryDataBlockProps} props
 * @return {JSX.Element}
 */
const BookingSummaryDataBlock: React.FC<BookingSummaryDataBlockProps> = ({ ...props }) => {
    const {
        stepLabel,
        stepInfo,
        className,
        showLabel,
        isVehicleBlock,
        showChangeLink = true,
        onChangeClick,
        id,
    } = props

    const shouldShowBlock = Boolean(stepInfo && stepInfo.length > 0)
    const showChangeButton = (shouldShowBlock || isVehicleBlock) && showChangeLink

    const { commonContentAvailable } = useSelector(commonContentSelector)
    const changeLabel = checkNestedProps(commonContentAvailable, 'autoAppointment', 'change') as string

    return (
        <div id={id} className={`${PREFIX}-booking-summary__data-block`}>
            {(showLabel || shouldShowBlock) && (
                <div className={`${PREFIX}-data-block__header`}>
                    <div className={`${PREFIX}-data-block__header__label`}>{stepLabel}</div>
                    {showChangeButton && (
                        <button className={`${PREFIX}-data-block__header__button`} onClick={onChangeClick}>
                            {changeLabel || DEFAULT_CHANGE_LABEL}
                        </button>
                    )}
                </div>
            )}
            {shouldShowBlock && <div className={`${PREFIX}-data-block__value ${className || ''}`}>{stepInfo}</div>}
        </div>
    )
}

export default BookingSummaryDataBlock

BookingSummaryDataBlock.propTypes = {
    stepLabel: PropTypes.string.isRequired,
    stepInfo: PropTypes.string,
    className: PropTypes.string,
    isVehicleBlock: PropTypes.bool,
    showChangeLink: PropTypes.bool,
    onChangeClick: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
}
