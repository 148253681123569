import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { autoServiceInstallationName } from '../../globalConstants'
import getAOAWorkflowType, { AOAWorkflowType } from '../../helpers/aoaWorkflow.helper'
import { isAOAFlowEnabledSelector } from '../../redux/selectors/storeDetails.selectors'
import { saveCurrentBookingFormStep } from '../../redux/actionCreators'
import { RootState } from '../../redux/reducers'
import { getActiveStep, getHasStartedAOAFlow } from '../../redux/selectors/aoaBookingForm.selectors'
import { checkNotNullAndUndefined } from '../../utils/checkNotNullAndUndefined'
import { StepsQueue } from './AOABookingForm.constants'
import { useVehicleBannerVisibility } from './useVehicleBannerVisibility.hook'

/**
 * A HOC that renders new AOA flow or old Coconut flow depending if store's opted into AOA flow
 * if AOA flow -> render AOABookingForm + BookingSummary (+ VehicleContainer, controlled via external Hook)
 * if Coconut flow -> render AutoAppointmentFrame
 * @param {React.ComponentType} Component
 * @param {string} componentId
 * @return {Component}
 */
export function withBookingFlowControl<T>(Component: React.ComponentType<T>, componentId: string): React.FC<T> {
    return function BookingFlowControlled(props: T): JSX.Element | null {
        const hasStartedAOAFlow = useSelector(getHasStartedAOAFlow)
        const isAOAFlowEnabled = useSelector(isAOAFlowEnabledSelector)

        const aoaWorkflowType = getAOAWorkflowType()
        const shouldDisplayAOA = aoaWorkflowType !== AOAWorkflowType.Regular || hasStartedAOAFlow || isAOAFlowEnabled

        const componentPayload = <Component {...props} />

        const activeStep = useSelector(getActiveStep)

        // Controls Vehicle Banner visilibity
        useVehicleBannerVisibility()

        const { commonContentAvailable } = useSelector((state: RootState) => state.commonContent)

        const [featureFlagContent, setFeatureFlagContent] = useState(
            commonContentAvailable?.featureFlag || ({} as typeof commonContentAvailable.featureFlag),
        )

        const { enableCoconutIframeRemoval } = featureFlagContent

        useEffect(() => {
            commonContentAvailable?.featureFlag && setFeatureFlagContent(commonContentAvailable.featureFlag)
        }, [commonContentAvailable])

        const { preferredStoreDetails, isStoreDetailsFetched } = useSelector((state: RootState) => state.storeDetails)

        const { storeServices } = preferredStoreDetails

        const dispatch = useDispatch()

        // This state is introduced as error step meant to be dispatched on initial load
        const [isStoreErrorStateHandled, setIsStoreErrorStateHandled] = useState(false)

        const checkAutoServiceAvailable = useCallback(() => {
            const autoService = storeServices && storeServices.find(store => store.id === autoServiceInstallationName)
            return autoService && autoService.phone
        }, [storeServices])

        const isErrorStep = activeStep === StepsQueue.ErrorNoAutoService || activeStep === StepsQueue.ErrorAOANotEnabled

        const dispatchErrorStep = useCallback(
            (errorStep: number) => {
                ;(!isStoreErrorStateHandled || isErrorStep) && dispatch(saveCurrentBookingFormStep(errorStep))
            },
            [isStoreErrorStateHandled, dispatch, isErrorStep],
        )

        useEffect(() => {
            if (isStoreDetailsFetched && !checkNotNullAndUndefined(enableCoconutIframeRemoval)) {
                if (enableCoconutIframeRemoval && aoaWorkflowType === AOAWorkflowType.Regular) {
                    if (checkAutoServiceAvailable()) {
                        if (isAOAFlowEnabled) {
                            if (isErrorStep) {
                                dispatch(saveCurrentBookingFormStep(StepsQueue.Info))
                            }
                        } else {
                            dispatchErrorStep(StepsQueue.ErrorAOANotEnabled)
                        }
                    } else {
                        dispatchErrorStep(StepsQueue.ErrorNoAutoService)
                    }
                }
                !isStoreErrorStateHandled && setIsStoreErrorStateHandled(true)
            }
        }, [
            enableCoconutIframeRemoval,
            isStoreDetailsFetched,
            aoaWorkflowType,
            dispatch,
            isAOAFlowEnabled,
            isStoreErrorStateHandled,
            checkAutoServiceAvailable,
            dispatchErrorStep,
            isErrorStep,
        ])

        // This function is used to render the component based on enableCoconutIframeRemoval toggle
        const renderComponent = () => {
            if (enableCoconutIframeRemoval) {
                return componentId !== 'AutoAppointmentFrame' ? componentPayload : null
            } else {
                if (componentId !== 'AutoAppointmentFrame' ? shouldDisplayAOA : !shouldDisplayAOA) {
                    return componentPayload
                } else {
                    return null
                }
            }
        }

        if (isStoreErrorStateHandled) {
            return renderComponent()
        } else {
            return null
        }
    }
}
