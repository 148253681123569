import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { VideoModalProps } from './VideoModal.type'
import { ReactModal, Icon } from '@nl/lib'
import { PREFIX } from '../../config'
import { Video } from '../Video'
import checkNestedProps from '../../utils/checkNestedProps'
import { useClickOutsideClose } from '@nl/lib/src/components/Tooltip/useClickOutside'
import { modalAccessibilityHandler } from '@nl/lib/src/utils/modalAccessibility'
import getFileNameFromUrl from '../../utils/getFileNameFromUrl'
import { videoAnalytics } from '../../analytics/components/videoAnalytics'
import { commonContentSelector } from '../../redux/selectors/commonContent.selectors'

export const VideoModal: React.FC<VideoModalProps> = ({ ...props }): JSX.Element => {
    const componentClassName = `${PREFIX}-videoModal`
    const { commonContentAvailable } = useSelector(commonContentSelector)
    const a11ypcmCloseLabel = checkNestedProps(commonContentAvailable, 'accessibility', 'a11yCloseIconLabel') as string

    const { videoUrl, videoThumbnailUrl, isOpen, closeModal, videoType } = props

    const videoProps = {
        autoPlay: true,
        videoUrl,
        coverImageUrl: videoThumbnailUrl,
    }

    useEffect(() => {
        const videoLabel = getFileNameFromUrl(videoUrl)

        const dataAnalyticsVideoType = document
            .querySelector(`[data-analytics-videotype]`)
            ?.getAttribute('data-analytics-videotype')
        if (isOpen) {
            videoType
                ? videoAnalytics.setAnalytics(videoType, videoLabel)
                : videoAnalytics.setAnalytics(String(dataAnalyticsVideoType), videoLabel)
        }
    }, [isOpen, videoType, videoUrl])

    const closeHandler = (): void => {
        modalAccessibilityHandler({ modalOpen: false, modalClassName: `${PREFIX}-react-modal` })
        closeModal()
    }

    const modalRef = useRef(null)
    useClickOutsideClose(modalRef, closeHandler, isOpen, true)

    return (
        <ReactModal isOpen={isOpen} notScroll={true} closeHandler={closeHandler} role="dialog" ariaModal={true}>
            <div className={componentClassName} ref={modalRef}>
                <div className={`${componentClassName}__content`}>
                    <Video {...videoProps}></Video>
                </div>
                <div className={`${componentClassName}__close-container`}>
                    <button
                        className={`${PREFIX}__close`}
                        onClick={closeHandler}
                        data-testid="video-modal-close-handler"
                        aria-label={a11ypcmCloseLabel}>
                        <Icon type="ct-close" size="lg" />
                    </button>
                </div>
            </div>
        </ReactModal>
    )
}

VideoModal.propTypes = {
    videoUrl: PropTypes.string.isRequired,
    videoThumbnailUrl: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
}

export default VideoModal
