import React from 'react'
import { PREFIX } from '../../../config'
import { useIsMobile } from '../../../hooks/useIsMobile.hook'
import { BazaarVoiceReview } from '../Buybox.component'

import { ProductReviewRatingsProps } from './ProductReviewRatings.type'

const reviewContainerClass = `${PREFIX}-product-card__review-container`
const reviewListClass = `${PREFIX}-product-card__reviews`

/**
 * BazaarVoiceReview
 * @return {JSX.Element} returns BazaarVoiceReview component (user review component)
 */
const ProductReviewsRatings = ({ productData, reviewsAndRatingA11yLabel }: ProductReviewRatingsProps): JSX.Element => {
    const isMobile = useIsMobile()
    const aggregateValue = productData.roadRating?.aggregateValue as number
    return (
        <div className={aggregateValue ? reviewContainerClass : reviewListClass}>
            <BazaarVoiceReview productData={productData} isMobile={isMobile} a11yLabel={reviewsAndRatingA11yLabel} />
        </div>
    )
}

export default ProductReviewsRatings
