/* eslint  no-magic-numbers: 0 */
export interface AnalyticsInfo {
    [key: string]: any
}

export interface PageAnalyticsInfo extends AnalyticsInfo {
    isPageLoadSent: boolean
    pageName?: string
    [key: string]: any
}

export interface SpaPageAnalytics {
    name?: string
    url?: string
}

export interface SpaPageEventAnalytics {
    event: string
    page: SpaPageAnalytics
}

export interface VideoModalAnalytics {
    action: string
    category: string
    label: string
    value: string
}
export interface VideoEventAnalytics {
    event: string
    eventParameters: VideoModalAnalytics
}

export interface CompleteSearchAnalytics {
    userInput: string | null
    redirect: string
    searchType: string | null
    suggestionType: string | null
    suggestionValue: string | null
    numberSearchResults?: string | null
    isTypeaheadUsed: string | null
    pathwaysWidgetId?: string | null
}

export enum MagicNumber {
    MINUS_TWO = -2,
    MINUS_ONE = -1,
    ZERO = 0,
    ONE = 1,
    TWO = 2,
    THREE = 3,
    FIVE = 5,
    TEN = 10,
    ELEVEN = 11,
    FIFTEEN = 15,
    NINETY_NINE = 99,
    HUNDRED = 100,

    SIXHUNDRED = 600,
    TWOHUNDRED = 200,
    FOURHUNDRED = 400,
    FOUR = 4,
    MINUS_FOUR = -4,
    MINUS_FIVE = -5,
    SIX = 6,
    EIGHT = 8,
    SIXTEEN = 16,
    ONETHOUSAND = 1000,
    FIVETHOUSAND = 5000,
    SIXTYTHOUSAND = 60000,
    FOURHUNDREDFOUR = 404,
    NINE = 9,
    TWENTYFOUR = 24,
    SIXTY = 60,
    FIFTYNINE = 59,
    EIGHTTHOUSAND = 8000,
    THOUSANDTWOHUNDRED = 1200,
}
