/**
 * This function concat sku and pCode level badges
 * @param {string[]} skuBadges badges at SKU level
 * @param {string[]} productBadges badges at pCode level
 * @param {string[]} excludeBadgeList badges to exclude
 * @return {string[]} concat badges from sku and pCode level and returns unique badges
 */
export const combineBadges = (
    skuBadges?: string[],
    productBadges?: string[],
    excludeBadgeList?: string[],
): string[] => {
    const productLevelBadges = productBadges?.filter(
        (badge: string) => !excludeBadgeList?.includes(badge?.split('|')[0]),
    )
    return [...Array.from(new Set(skuBadges?.concat(productLevelBadges || [])))]
}
