import React, { createRef, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { SecondaryNavigationProps, NavigationLinkHighlightStyle } from './SecondaryNavigation.type'
import { BREAKPOINTS, PREFIX } from '../../../config'
import { NavigationCategory } from '../SecondaryNavigationWrapper.type'
import { MegaNavigationDesktop, libUtils, isArrayNotEmpty, SkeletonComponent, useClickOutsideClose } from '@nl/lib'
import HeaderNavigationHelper from '../../../helpers/headerNavigation.helper'
import { analyticsAttributes } from '../../../globalConstants/analyticsParams.constant'
import { Interaction } from '../../../analytics/components/React/Interaction'
import { getSiteNavigationSchema } from '../../../Seo/helpers/SeoSchema.helper'
import AutoServiceButton from '../../AutoServiceButton'
import { getStyle } from '../../../helpers/secondaryNavigationMenu.helper'

/**
 * Secondary Navigation component
 * @param {SecondaryNavigationProps} props
 * @return {JSX.Element} returns Wishlist Icon
 */

const SecondaryNavigation: React.FC<SecondaryNavigationProps> = props => {
    const { categoriesList, secondaryNavAriaLabel } = props
    const categoriesListLength = categoriesList?.length
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const buttonRefs = useRef([])
    buttonRefs.current = categoriesList?.map((_element, i) => buttonRefs.current[i] ?? createRef())
    const elementRef = useRef<HTMLDivElement>(null)
    const isWidthSet = useRef<boolean>(false)
    const [width, setWidth] = useState(elementRef?.current?.clientWidth)

    const secondaryMenuBarId = 'secondaryMenuBar'
    const rewardModalRef = useRef<HTMLUListElement>(null)
    const runMenuAccessibilityOnce = useRef(0)

    /**
     * function to calculate dynamic max-width of secondaryNavigation button and links
     */
    const calculateMaxButtonWidth = useCallback(() => {
        const containerWidth = elementRef?.current?.clientWidth
        const maxButtonWidth = containerWidth / categoriesListLength
        setWidth(maxButtonWidth)
    }, [categoriesListLength])
    window.addEventListener('resize', calculateMaxButtonWidth)

    useEffect(() => {
        if (isArrayNotEmpty(categoriesList) && runMenuAccessibilityOnce.current === 0) {
            libUtils.menuBarAccessibility().init(secondaryMenuBarId)
            runMenuAccessibilityOnce.current = 1
        }
    }, [categoriesList])

    useLayoutEffect(() => {
        if (!isWidthSet.current && categoriesListLength) {
            calculateMaxButtonWidth()
            isWidthSet.current = true
        }
    }, [calculateMaxButtonWidth, categoriesListLength])

    const renderAutoAppointmentButton = (): JSX.Element => {
        return <AutoServiceButton />
    }

    /**
     * function to close menu on outside click
     */
    const closeHandler = () => {
        setIsMenuOpen(false)
        const listItem = document.getElementsByClassName(`${PREFIX}-secondary-navigation-bar__nav-list--item`)
        Object.values(listItem).forEach((element, elementIndex) => {
            categoriesList[elementIndex]['isSelected'] = false
            element.classList.remove(`${PREFIX}-secondary-navigation-bar__nav-list--item-active`)
        })
    }

    useClickOutsideClose(rewardModalRef, closeHandler, isMenuOpen, window.innerWidth > BREAKPOINTS.mobileMaxWidth)

    /**
     * function to open menu on click
     * @param {number} itemIndex
     */
    const showDropdownList = (itemIndex: number) => {
        setIsMenuOpen(true)
        const listItem = document.getElementsByClassName(`${PREFIX}-secondary-navigation-bar__nav-list--item`)
        if (categoriesList[itemIndex]['isSelected']) {
            setIsMenuOpen(false)
            categoriesList[itemIndex]['isSelected'] = false
            listItem[itemIndex].classList.remove(`${PREFIX}-secondary-navigation-bar__nav-list--item-active`)
        } else {
            categoriesList[itemIndex]['isSelected'] = true
            listItem[itemIndex].classList.add(`${PREFIX}-secondary-navigation-bar__nav-list--item-active`)
        }
        Object.values(listItem).forEach((element, elementIndex) => {
            if (elementIndex !== itemIndex) {
                categoriesList[elementIndex]['isSelected'] = false
                element.classList.remove(`${PREFIX}-secondary-navigation-bar__nav-list--item-active`)
            }
        })
    }

    /**
     * function to create navigation list item
     * @param {string} category
     * @param {number} index
     * @return {JSX.Element}
     */
    const getNavigationListItem = (category: NavigationCategory, index: number): JSX.Element => {
        const { name: categoryName, style } = category
        const linkItemStyle = getStyle(style)

        const {
            event: { interaction },
            eventParameters: {
                location: { secondaryNavigation },
                action: { clickOn, link },
            },
            linkTypes: { internal },
        } = analyticsAttributes

        const actionName = `${clickOn} ${internal} ${link}`
        const litModifierClass = !isArrayNotEmpty(category.categories)
            ? `${PREFIX}-secondary-navigation-bar__nav-list--item-flex`
            : ''

        return (
            <li
                className={`${PREFIX}-secondary-navigation-bar__nav-list--item ${litModifierClass} ${
                    style?.toLowerCase() === NavigationLinkHighlightStyle.highlight
                        ? `${PREFIX}-secondary-navigation-bar__nav-list--highlight`
                        : category.categories || category.categoryId
                        ? ''
                        : `${PREFIX}-secondary-navigation-bar__nav-list--link`
                }`}
                key={`${index}-${categoryName}`}>
                {/* Using the below line for disabling the "The attribute aria-expanded is not supported by the role menuitem"
                TODO: Need to find a solution. The error is invalid, since the w3 suggest to use aria-expanded on menuitem */}
                {/* eslint-disable-next-line */}
                {!isArrayNotEmpty(category?.categories) ? (
                    <a
                        className={`${PREFIX}-secondary-navigation-bar__nav-list--item-link ${linkItemStyle}`}
                        href={encodeURI(category.url?.url || category.url)}
                        onClick={closeHandler}
                        data-testid={`secondary-navigation-link-${index}`}
                        data-link-value={categoryName}
                        style={{ maxWidth: width }}
                        data-feature-type={secondaryNavigation}>
                        {categoryName}
                    </a>
                ) : (
                    <button
                        className={`${PREFIX}-menu-buttons ${PREFIX}-button--tertiary`}
                        ref={buttonRefs.current[index]}
                        onClick={() => showDropdownList(index)}
                        aria-haspopup={true}
                        aria-expanded={isMenuOpen}
                        data-testid={`secondary-navigation-link-${index}`}
                        style={{ maxWidth: width }}
                        id={`${index}-${categoryName}`}>
                        {categoryName}
                    </button>
                )}
                {category.categories && (
                    <div className={`${PREFIX}-col-md-12`}>
                        <div className={`${PREFIX}-mega-navigation-container`}>
                            <MegaNavigationDesktop
                                eventName={interaction}
                                actionName={actionName}
                                InteractionAnalytics={Interaction}
                                catalog={HeaderNavigationHelper.transformToMegaNavDesktopProps(category)}
                                autoServiceButton={renderAutoAppointmentButton}
                            />
                        </div>
                    </div>
                )}
            </li>
        )
    }

    /**
     * function to create navigation list
     * @return {JSX.Element}
     */
    const getNavigationList = (): JSX.Element => {
        return (
            <ul
                ref={rewardModalRef}
                className={`${PREFIX}-secondary-navigation-bar__nav-list`}
                id={secondaryMenuBarId}
                data-testid="secondary-navigation-list">
                {categoriesList.map((category: NavigationCategory, index: number) => {
                    return getNavigationListItem(category, index)
                })}
            </ul>
        )
    }

    /**
     * function to render schema Elements/Tag for BreadcrumbList
     * @return {JSX.Element | null}
     */

    const renderNavigationSchema = (): JSX.Element | null => {
        const navigationSchema = getSiteNavigationSchema(categoriesList)

        return !!navigationSchema ? (
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: `${navigationSchema}`,
                }}
            />
        ) : null
    }

    return (
        <>
            {categoriesList.length ? (
                <div
                    className={`${PREFIX}-secondary-navigation ${PREFIX}-full-width-container`}
                    id="secondary-navigation"
                    data-testid="secondary-navigation">
                    <div className={`${PREFIX}-row ${PREFIX}-container`} ref={elementRef}>
                        <div className={`${PREFIX}-col-md-12 ${PREFIX}-col-sm-12 ${PREFIX}-col-xs-6`}>
                            <nav
                                aria-label={secondaryNavAriaLabel}
                                className={`${PREFIX}-secondary-navigation-bar ${PREFIX}-secondary-navigation-bar__nav`}>
                                {getNavigationList()}
                            </nav>
                        </div>
                    </div>
                </div>
            ) : (
                <SkeletonComponent
                    skeletonClass={`${PREFIX}-full-width-container ${PREFIX}-secondary-navigation__skeleton`}
                />
            )}
            {renderNavigationSchema()}
        </>
    )
}

SecondaryNavigation.propTypes = {
    categoriesList: PropTypes.array.isRequired,
    secondaryNavAriaLabel: PropTypes.string,
}

export default SecondaryNavigation
