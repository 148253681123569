import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@nl/lib'
import { PREFIX } from '../../config'
import { BannerDisclaimerProps } from './BannerDisclaimer.type'
import { MagicNumber } from '../../analytics/analytics.type'

const BannerDisclaimer: React.FC<BannerDisclaimerProps> = ({ ...props }): JSX.Element => {
    const buttonRef = useRef()
    const [visibility, setVisibility] = useState(false)
    const { disclaimerFormat, disclaimerText, disclaimerTitle, tooltipField } = props

    const toolTipButtonHandler = (): void => {
        setVisibility(!visibility)
    }

    /**
     * Render Disclaimer text
     * @return {{JSX.Element}} Render Disclaimer text
     */
    const renderDisclaimerText = (): JSX.Element => {
        return (
            <div
                className={`${PREFIX}-banner__disclaimer-content`}
                dangerouslySetInnerHTML={{ __html: disclaimerText || '' }}></div>
        )
    }

    /**
     * Get Disclaimer tooltip
     * @param {string | undefined} tooltipText
     * @param {string | undefined} tooltipBtn
     * @param {string | undefined} tooltipPrefix
     * @return {{JSX.Element}} Get Disclaimer tooltip
     */
    const getDisclaimerTooltip = (
        tooltipText: string | undefined,
        tooltipBtn: string | undefined,
        tooltipPrefix: string | undefined,
    ): JSX.Element => {
        return (
            <>
                {tooltipText}
                {tooltipBtn && (
                    <button
                        className={`${PREFIX}-banner__links`}
                        data-qm-allow="true"
                        type="button"
                        ref={buttonRef}
                        data-testid="banner-tooltip-field"
                        onClick={toolTipButtonHandler}>
                        {tooltipBtn}
                    </button>
                )}
                {tooltipPrefix}
                {tooltipBtn && (
                    <Tooltip
                        visibility={visibility}
                        setVisibility={setVisibility}
                        iconID="ct-close"
                        headerText={disclaimerTitle}
                        bodyText={
                            <div
                                data-testid="tooltip-text"
                                className={`${PREFIX}-banner__disclaimer__content`}
                                dangerouslySetInnerHTML={{ __html: disclaimerText || '' }}></div>
                        }
                        coords={buttonRef.current}
                    />
                )}
            </>
        )
    }

    /**
     * Render Disclaimer tooltip
     * @return {{JSX.Element}} Disclaimer tooltip
     */
    const renderDisclaimerTooltip = (): JSX.Element => {
        const strPos1 = tooltipField ? tooltipField.lastIndexOf('[') : MagicNumber.MINUS_ONE
        const strPos2 = tooltipField ? tooltipField.lastIndexOf(']') : MagicNumber.MINUS_ONE
        let tooltipText = tooltipField
        let tooltipBtn
        let tooltipPrefix

        if (tooltipField && strPos1 > MagicNumber.MINUS_ONE && strPos2 > MagicNumber.MINUS_ONE) {
            // tooltipSufix = ''
            tooltipText = tooltipField.substring(MagicNumber.ZERO, strPos1)
            tooltipBtn =
                strPos1 > MagicNumber.MINUS_ONE && strPos2 > MagicNumber.MINUS_ONE
                    ? tooltipField.substring(strPos1 + MagicNumber.ONE, strPos2)
                    : ''
            tooltipPrefix = tooltipField.substring(strPos2 + MagicNumber.ONE)
        }

        return getDisclaimerTooltip(tooltipText, tooltipBtn, tooltipPrefix)
    }

    return <>{disclaimerFormat === 'Tooltip' ? renderDisclaimerTooltip() : renderDisclaimerText()}</>
}

BannerDisclaimer.propTypes = {
    disclaimerFormat: PropTypes.string,
    disclaimerText: PropTypes.string,
    disclaimerTitle: PropTypes.string,
    tooltipField: PropTypes.string.isRequired,
}
export default BannerDisclaimer
