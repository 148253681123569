import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { Breadcrumb, SkeletonComponent, isArrayNotEmpty, AutoAttributes } from '@nl/lib'

import { PREFIX, path, pageTypes } from '../../config'
import { CategoryBreadCrumb } from '../../redux/models/category.interface'
import { BreadcrumbLinkData } from '../../redux/models/productData.interface'
import { BreadcrumbParentPageLink, BreadcrumbsPropsType } from './BreadcrumbsComp.type'
import { goto, srpURL, plpURLRegExp } from './Breadcrumb.constant'
import checkNestedProps from '../../utils/checkNestedProps'
import getPageType from '../../utils/getPageType'

import {
    getBreadcrumbMobileLink,
    getBreadcrumbDesktopLink,
    isDynamicPage,
    checkFacetSelectedValue,
    checkSaleInstoreItemSelected,
    checkSortSelectedValue,
    getBreadcrumbParentPageLink,
} from './Breadcrumbs.helper'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { getBreadCrumbSchema } from '../../Seo/helpers/SeoSchema.helper'
import { MagicNumber } from '../../analytics/analytics.type'
import { setAuthouredBreadcrumbData } from '../../redux/actions'
import { isTirePDP } from '../Vehicles/Vehicle.helper'
import { edgeToEdgeBanner } from '../EdgeToEdgeBanner/EdgeToEdgeBanner'
import { commonContentSelector } from '../../redux/selectors/commonContent.selectors'
import { vehicleListSelector } from '../../redux/selectors/userProfile.selectors'
import { productDataSelector } from '../../redux/selectors/product.selectors'
import { displayNameSelector } from '../../redux/selectors/storeDetails.selectors'
import { tiresDataSelector } from '../../redux/selectors/vehicleTires.selectors'
import { isStoreSharedCartSelector, storeInitiatedCartSelector } from '../../redux/selectors/sharedCart.selectors'
import {
    breadcrumbListSelector,
    isFitmentRequiredSelector,
    productWheelTypeSelector,
} from '../../redux/selectors/categoryIdData.selectors'
import { productCardDataSelector } from '../../redux/selectors/productData.selectors'
import { returnInAppQueryParam } from '../AOABookingForm/AOABookingForm.helper'
import { useVehiclePreselectedValuesBreadcrumbLinks } from './useVehiclePreselectedValuesBreadcrumbLinks.hook'
import { isPreselectedVehicleFromUrl } from '../../redux/selectors/preselectedVehicle.selectors'
import { automotivePackageFlowSelector } from '../../redux/selectors/automotivePackage.selector'

const BreadcrumbsComp: React.FC<BreadcrumbsPropsType> = ({ ...props }): JSX.Element | null => {
    const { currentPageName, currentPageLink, parentPages } = props
    const pageType = getPageType()
    const dispatch = useDispatch()
    const displayName = useSelector(displayNameSelector)

    const isVehiclePreselectedFromURL = useSelector(isPreselectedVehicleFromUrl)

    const vehiclePreselectedValuesBreadcrumbLinks = useVehiclePreselectedValuesBreadcrumbLinks()

    useEffect(() => {
        const updatedBreadcrumbList: BreadcrumbLinkData[] = [] as BreadcrumbLinkData[]
        if (isArrayNotEmpty(parentPages)) {
            parentPages.forEach(item => {
                updatedBreadcrumbList.push({ label: item.name, url: item.link })
            })
            dispatch(setAuthouredBreadcrumbData(updatedBreadcrumbList))
        }
    }, [parentPages, dispatch])

    // isFitmentRequired will be true only for automotive category
    const productWheelType = useSelector(productWheelTypeSelector)
    const categoryBreadCrumbList = useSelector(breadcrumbListSelector)
    const isFitmentRequired = useSelector(isFitmentRequiredSelector)
    const { facets, saleItem, sortBy, inStockAtMyStore, breadcrumbList, clearUrl } =
        useSelector(productCardDataSelector)
    const isTirePLP = isTirePDP(productWheelType)
    const tiresData = useSelector(tiresDataSelector)

    // TODO Need to check mutation of productCard data on resize
    const productData = useSelector(productDataSelector)
    const isPackageLandingPage = pageType === pageTypes.packageLanding
    const [mobileBreadcrumbList, setMobileBreadcrumbList] = useState<BreadcrumbParentPageLink[]>([])
    const [desktopBreadcrumbList, setDesktopBreadcrumbList] = useState<BreadcrumbParentPageLink[]>([])
    const [isLastElementInteractive, setIsLastElementInteractive] = useState(false)
    const { commonContentAvailable } = useSelector(commonContentSelector)
    const [breadcrumbsCommonContent, setBreadcrumbsCommonContent] = useState(
        commonContentAvailable?.breadcrumb || ({} as typeof commonContentAvailable.breadcrumb),
    )
    const [globalLinksCommonContent, setGlobalLinksCommonContent] = useState(
        commonContentAvailable?.globalLinks || ({} as typeof commonContentAvailable.globalLinks),
    )

    const isStoreSharedCart = useSelector(isStoreSharedCartSelector)
    const storeInitiatedCart = useSelector(storeInitiatedCartSelector)
    const isPackageFlow = useSelector(automotivePackageFlowSelector)

    useEffect(() => {
        commonContentAvailable?.breadcrumb && setBreadcrumbsCommonContent(commonContentAvailable.breadcrumb)
        commonContentAvailable?.globalLinks && setGlobalLinksCommonContent(commonContentAvailable.globalLinks)
    }, [commonContentAvailable])

    const {
        homeLabel,
        homeLink,
        backToLabel,
        backToSearchResults,
        displayProductName,
        backToResults,
        searchResultsLabel,
        backToCartLabel,
    } = breadcrumbsCommonContent

    const { cartPageLink } = globalLinksCommonContent

    const backToCartItem = {
        name: backToCartLabel,
        link: cartPageLink,
    }

    const homePageItem = {
        name: homeLabel,
        link: homeLink,
    }

    const list = useSelector(vehicleListSelector)

    const [vehicleList, setVehicleList] = useState<AutoAttributes | null>(null)

    // used to update users currently selected vehicle from profile
    useEffect(() => {
        setVehicleList(
            list.length && !Array.isArray(list[MagicNumber.ZERO]) ? list[MagicNumber.ZERO].autoAttributes : null,
        )
    }, [list])

    const userVehicle = vehicleList

    // set desktop and mobile breadcrumb lists
    const setBreadcrumbList = useCallback(
        (breadcrumb: BreadcrumbParentPageLink[], isBackToSearchResults?: boolean) => {
            const currentBreadcrumb = pageType === pageTypes.store ? displayName : currentPageName

            const sharedBreadcrumbDataProps = {
                backToResults,
                backToSearchResults,
                backToLabel,
                currentPageName: currentBreadcrumb,
                currentPageLink,
                homePageItem,
                displayProductName,
                searchResultsLabel,
                isBackToSearchResults,
                backToCartItem,
            }

            const breadcrumbDataPropsDesktop = {
                ...sharedBreadcrumbDataProps,
                breadcrumb: [...breadcrumb, ...vehiclePreselectedValuesBreadcrumbLinks],
            }

            const breadcrumbDataPropsMobile = {
                ...sharedBreadcrumbDataProps,
                breadcrumb: [...breadcrumb, ...vehiclePreselectedValuesBreadcrumbLinks.slice(MagicNumber.MINUS_ONE)],
            }

            setDesktopBreadcrumbList(getBreadcrumbDesktopLink(breadcrumbDataPropsDesktop))
            setMobileBreadcrumbList(getBreadcrumbMobileLink(breadcrumbDataPropsMobile))
        },
        [
            displayProductName,
            backToResults,
            backToSearchResults,
            backToLabel,
            currentPageLink,
            currentPageName,
            homePageItem,
            searchResultsLabel,
            backToCartItem,
            displayName,
            pageType,
            vehiclePreselectedValuesBreadcrumbLinks,
        ],
    )

    const breadcrumbRef = useRef(MagicNumber.ZERO)

    const isOneTimeCartCheckoutPage = isStoreSharedCart || Object.keys(storeInitiatedCart).length > 0

    // call set desktop and mobile breadcrumb lists for PDP pages
    useEffect(() => {
        if (
            breadcrumbRef.current === 0 &&
            pageType === pageTypes.pdpPage &&
            checkNestedProps(productData, 'breadcrumbList') &&
            !!homeLabel
        ) {
            const breadcrumbsLinks: BreadcrumbParentPageLink[] = productData.breadcrumbList.map(
                (productBreadcrumb: BreadcrumbLinkData) => {
                    return {
                        name: productBreadcrumb.label,
                        link: productBreadcrumb.url,
                    }
                },
            )
            setIsLastElementInteractive(!displayProductName)
            setBreadcrumbList(breadcrumbsLinks)
            breadcrumbRef.current = 1
        }
    }, [pageType, productData, setBreadcrumbList, displayProductName, homeLabel])

    // call set desktop and mobile breadcrumb lists for PLP pages
    useEffect(() => {
        if (breadcrumbRef.current === 0 && pageType === pageTypes.plp && isArrayNotEmpty(breadcrumbList)) {
            const breadcrumbsLinks: BreadcrumbParentPageLink[] = breadcrumbList
                .filter((breadcrumbLink: BreadcrumbLinkData) => {
                    return breadcrumbLink.type === goto
                })
                .map((filteredLink: BreadcrumbLinkData) => {
                    return {
                        name: filteredLink.value,
                        link: filteredLink.url,
                    }
                })
            setBreadcrumbList(breadcrumbsLinks)
            breadcrumbRef.current = 1
        }
    }, [pageType, breadcrumbList, setBreadcrumbList])

    // call set desktop and mobile breadcrumb lists for SRP pages
    useEffect(() => {
        if (facets || sortBy || saleItem || inStockAtMyStore) {
            breadcrumbRef.current = 0
        }
    }, [facets, sortBy, saleItem, inStockAtMyStore])

    useEffect(() => {
        if (
            breadcrumbRef.current === 0 &&
            pageType === pageTypes.searchPage &&
            isArrayNotEmpty(facets) &&
            isArrayNotEmpty(sortBy)
        ) {
            const isBackToSearchResults =
                checkFacetSelectedValue(facets) ||
                checkSortSelectedValue(sortBy) ||
                checkSaleInstoreItemSelected(saleItem) ||
                checkSaleInstoreItemSelected(inStockAtMyStore)

            const breadcrumbsLinks = [
                {
                    name: searchResultsLabel,
                    link: homeLink,
                },
                {
                    name: backToSearchResults,
                    link: clearUrl,
                },
            ]
            isBackToSearchResults && setIsLastElementInteractive(true)
            setBreadcrumbList(breadcrumbsLinks, isBackToSearchResults)
            breadcrumbRef.current = 1
        }
    }, [
        pageType,
        breadcrumbList,
        inStockAtMyStore,
        backToSearchResults,
        clearUrl,
        facets,
        homeLink,
        saleItem,
        searchResultsLabel,
        sortBy,
        setBreadcrumbList,
    ])

    useEffect(() => {
        if (pageType === pageTypes.checkout) setIsLastElementInteractive(true)
    }, [pageType])

    /**
     *  useeffect to call edge-to-edge banner when breadcrumb is loaded
     */
    useEffect(() => {
        if ((isArrayNotEmpty(mobileBreadcrumbList) || isArrayNotEmpty(desktopBreadcrumbList)) && !isFitmentRequired) {
            edgeToEdgeBanner()
        }
    }, [mobileBreadcrumbList, desktopBreadcrumbList, isFitmentRequired])

    /**
     * function to return breadcrumb list for vehicle plp
     * Gets modified breadcrumbs  for tires PDP and PLP or for other PLPs with selected vehicle
     * Returns empty array in other cases
     * @param {BreadcrumbParentPageLink[]} breadcrumbsLinks
     * @return {BreadcrumbParentPageLink[]} - could return empty array
     */
    const getAutomotiveVehicleBreadcrumbLastElement = useCallback(
        (originLastBreadcrumbElement: BreadcrumbParentPageLink) => {
            const { name: lastBreadcrumbElementName } = originLastBreadcrumbElement
            const vehicleAttributeList = [
                userVehicle?.year,
                userVehicle?.make,
                userVehicle?.model,
                userVehicle && lastBreadcrumbElementName,
            ]

            const userVehicleDetailString = vehicleAttributeList.join(' ')
            const userVehicleDetailBreadcrumbLink = getBreadcrumbParentPageLink(userVehicleDetailString, '')

            const generateLastTireBreadcrumbElement = (tData: typeof tiresData): string => {
                if (tData === null) return ''
                return [
                    `${tData.selectedDiameterValue}"`,
                    `${tData.selectedSectionWidth}/${tData.selectedAspectRatio}R${tData.selectedDiameterValue}`,
                    lastBreadcrumbElementName,
                ].join(' ')
            }

            const userVehicleDetailTire = getBreadcrumbParentPageLink(generateLastTireBreadcrumbElement(tiresData), '')

            switch (true) {
                case isVehiclePreselectedFromURL:
                    return originLastBreadcrumbElement
                case isTirePLP && !isPackageFlow && tiresData && !isPackageLandingPage:
                    return userVehicleDetailTire
                case userVehicle && !isVehiclePreselectedFromURL:
                    return userVehicleDetailBreadcrumbLink
                default:
                    return originLastBreadcrumbElement
            }
        },
        [tiresData, userVehicle, isTirePLP, isPackageLandingPage, isVehiclePreselectedFromURL, isPackageFlow],
    )

    /**
     * function to render schema Elements/Tag for BreadcrumbList
     * @param {BreadcrumbParentPageLink[]} breadcrumbsLinks
     * @return {JSX.Element | null}
     */

    const renderBreadCrumbSchema = (breadcrumbsList: BreadcrumbParentPageLink[]): JSX.Element | null => {
        const breadCrumbSchema = getBreadCrumbSchema(breadcrumbsList, breadcrumbsCommonContent)
        return !!breadCrumbSchema ? (
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: `${breadCrumbSchema}`,
                }}
            />
        ) : null
    }

    // call set desktop and mobile breadcrumb lists for Category pages
    useEffect(() => {
        if (
            breadcrumbRef.current === 0 &&
            pageTypes.categoryPages.includes(pageType || '') &&
            checkDataLength(categoryBreadCrumbList) &&
            !!homeLabel
        ) {
            const breadcrumbsLinks: BreadcrumbParentPageLink[] = categoryBreadCrumbList.map(
                (categoryLink: CategoryBreadCrumb) => {
                    return {
                        name: categoryLink.label,
                        link: categoryLink.url,
                    }
                },
            )

            const breadCrumDataList: BreadcrumbParentPageLink[] = breadcrumbsLinks
            setBreadcrumbList(breadCrumDataList)
            breadcrumbRef.current = 1
        }
    }, [pageType, categoryBreadCrumbList, setBreadcrumbList, homeLabel])

    // call set desktop and mobile breadcrumb lists for store details page
    useEffect(() => {
        if (breadcrumbRef.current === 0 && pageType === pageTypes.store && !!displayName && !!homeLabel) {
            setBreadcrumbList(parentPages)
            breadcrumbRef.current = 1
        }
    }, [pageType, parentPages, homePageItem.name, setBreadcrumbList, displayName, homeLabel])

    // call set desktop and mobile breadcrumb lists for non-PDP and non-PLP pages
    useEffect(() => {
        if (breadcrumbRef.current === 0 && isDynamicPage() && !!homePageItem.name) {
            setBreadcrumbList(parentPages)
            breadcrumbRef.current = 1
        }
    }, [pageType, parentPages, homePageItem.name, setBreadcrumbList])

    const isCategoryPLP = pageTypes.categoryPages.includes(pageType || '') && checkDataLength(categoryBreadCrumbList)
    const fitmentRequired = isFitmentRequired
    const isAutomotiveOrPackageLanding = (isCategoryPLP && fitmentRequired) || isPackageLandingPage

    const inApp = returnInAppQueryParam()

    const renderBreadcrumb = (): JSX.Element | null => {
        const breadCrumbLabel = checkNestedProps(
            commonContentAvailable,
            'accessibility',
            'a11yBreadcrumbLabel',
        ) as string
        const isPipe = !!(RegExp(srpURL).exec(window.location.href) || plpURLRegExp.exec(window.location.href))
        if (isArrayNotEmpty(mobileBreadcrumbList) && isArrayNotEmpty(desktopBreadcrumbList)) {
            const originLastBreadcrumbElements = desktopBreadcrumbList.slice(MagicNumber.MINUS_ONE)
            const lastBreadcrumbElement = isAutomotiveOrPackageLanding
                ? getAutomotiveVehicleBreadcrumbLastElement(...originLastBreadcrumbElements)
                : originLastBreadcrumbElements[MagicNumber.ZERO]

            const nofollow = Boolean(vehiclePreselectedValuesBreadcrumbLinks.length)
            const fullMobileBreadcrumbsLinks = [...mobileBreadcrumbList].map(item => ({ ...item, nofollow }))
            const fullDesktopBreadcrumbsLinks = [
                ...desktopBreadcrumbList.slice(MagicNumber.ZERO, MagicNumber.MINUS_ONE),
                lastBreadcrumbElement,
            ].map(item => ({ ...item, nofollow }))

            return (
                <>
                    {!inApp ? (
                        <div className={`${PREFIX}-md-none`}>
                            <Breadcrumb
                                links={fullMobileBreadcrumbsLinks}
                                backToLabel={backToLabel}
                                path={path}
                                isLastElementInteractive={true}
                                showPipeIcon={isPipe}
                                breadCrumbLabel={breadCrumbLabel}
                            />
                        </div>
                    ) : null}
                    <div className={`${PREFIX}-xs-none ${PREFIX}-md-block`}>
                        <Breadcrumb
                            links={fullDesktopBreadcrumbsLinks}
                            backToLabel={backToLabel}
                            path={path}
                            isLastElementInteractive={isLastElementInteractive}
                            showPipeIcon={isPipe}
                            breadCrumbLabel={breadCrumbLabel}
                        />
                    </div>
                    {renderBreadCrumbSchema(fullDesktopBreadcrumbsLinks)}
                </>
            )
        } else return <SkeletonComponent skeletonClass={`${PREFIX}-breadcrumbs__skeleton`} />
    }

    return !isOneTimeCartCheckoutPage ? <>{renderBreadcrumb()}</> : null
}
BreadcrumbsComp.propTypes = {
    currentPageName: PropTypes.string,
    currentPageLink: PropTypes.string,
    parentPages: PropTypes.any,
}
export default BreadcrumbsComp
