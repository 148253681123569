import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { analyticsInteraction } from '../../../../redux/actions/pageAnalytics.action'

/**
 * This function using for google analytics
 * @param {Object} properties
 * @return {JSX.Element}
 */
function Interaction({ label, componentName, children, eventName, actionName, category }) {
    const dispatch = useDispatch()
    const clickHandler = (): void => {
        dispatch(analyticsInteraction(label, componentName, eventName, actionName, category))
    }
    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            data-testid="interaction"
            onClick={clickHandler}
            onKeyDown={() => {
                clickHandler()
            }}>
            {children}
        </div>
    )
}

Interaction.propTypes = {
    label: PropTypes.string,
    componentName: PropTypes.string,
    children: PropTypes.node,
    eventName: PropTypes.string,
    actionName: PropTypes.string,
    category: PropTypes.string,
}

export default Interaction
