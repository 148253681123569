import { Dispatch } from 'redux'

import { checkDataLength } from '../../components/Accounts/Addresses/checkDataLength'
import orderDetailsService from '../../services/orderDetails/orderDetails.service'
import { orderService } from '../../services/orderConfirmation/orderService'
import getFilteredDeliveryModes from '../../utils/getFilteredDeliveryModes'

import {
    orderDetailsConfirmationFailure,
    orderDetailsConfirmationSuccess,
    orderDetailsExpired,
    generateReturnSlipSuccess,
    generateReturnSlipError,
    resetReturnSlipData,
    updateBillingInformationOrderDetailsSuccess,
    updateBillingInformationOrderDetailsFailure,
    setShowSpinner,
} from '../actionCreators'
import { fillCheckoutDataFromAPI } from './cart.action'
import { CartResponseErrorDTO, FilteredCartData } from '../models/cart.interface'
import { AxiosError, AxiosResponse } from 'axios'
import { storageData } from '../../globalConstants'
import sessionStorageService from '../../utils/sessionStorageService'
import { EnvironmentConfig, getEnvironment } from '../../environments'
import { replaceEmptyImagesWithDefault } from '../../utils/replaceEmptyImagesWithDefault'
import { OrderModificationType } from '../../components/ProductCardAndBillingWrapper/BillingAndShippingInfo/BillingAndShippingInfo.type'
import { OrderDetaiilsErrorDTO } from '../models/orderDetails.interface'

/**
 * Function to get cart items for shopping cart
 * @param {string} orderId
 * @param {string} userEmail
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setShowSpinnerState
 * @param {string} sfsOrderNumber OCCP-23648: required for SFS orders
 * @param {string} hashedOrderCode
 * @param {boolean} enableMergingOrderDetailsConsignmentAndUnconsignmentEntries
 * @return {Promise}
 */
export const getOrderService =
    (
        orderId: string,
        userEmail?: string,
        setShowSpinnerState?: React.Dispatch<React.SetStateAction<boolean>>,
        sfsOrderNumber?: string,
        hashedOrderCode?: string,
        enableMergingOrderDetailsConsignmentAndUnconsignmentEntries?: boolean,
    ) =>
    (dispatch: Dispatch): void => {
        const config = getEnvironment()
        orderService
            .getOrderConfirmation(orderId, userEmail, sfsOrderNumber, hashedOrderCode)
            .then((entryGroups: AxiosResponse<FilteredCartData>) => {
                replaceEmptyImages(config, entryGroups)
                const orderFromCart = entryGroups.data.order //  order from API Response
                if (checkDataLength(orderFromCart)) {
                    const orderData = getFilteredDeliveryModes(
                        orderFromCart,
                        enableMergingOrderDetailsConsignmentAndUnconsignmentEntries,
                    )
                    fillCheckoutDataFromAPI(orderData.cart, dispatch)
                    dispatch(orderDetailsConfirmationSuccess(orderData))
                } else {
                    dispatch(orderDetailsExpired(true))
                }
                if (setShowSpinnerState) {
                    setShowSpinnerState(false)
                }
            })
            .catch((err: AxiosError<CartResponseErrorDTO>) => {
                dispatch(orderDetailsConfirmationFailure(err?.response))
                if (setShowSpinnerState) {
                    setShowSpinnerState(false)
                }
            })
    }

export const generateReturnSlip =
    (orderId: string, packageIds: string[]) =>
    (dispatch: Dispatch): void => {
        return orderDetailsService
            .generateReturnSlip(orderId, packageIds)
            .then(response => {
                const file = new Blob([response.data], { type: 'application/pdf' })
                const fileURL = window.URL.createObjectURL(file)
                dispatch(generateReturnSlipSuccess(fileURL))
            })
            .catch((err: AxiosError<string>) => {
                const errorResponse = err.response
                dispatch(generateReturnSlipError(errorResponse))
            })
    }

export const resetReturnSlipGenerationData =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(resetReturnSlipData())
    }

/**
 * Function for getting order status
 * @param {string} orderId
 * @param {string} userEmail
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setShowSpinnerState
 * @return {Promise}
 */
export const getOrderStatus =
    (orderId: string, userEmail?: string, setShowSpinnerState?: React.Dispatch<React.SetStateAction<boolean>>) =>
    (dispatch: Dispatch): void => {
        const config = getEnvironment()
        sessionStorageService.removeItem(storageData.orderStatus)
        orderService
            .getOrderConfirmation(orderId, userEmail)
            .then((entryGroups: AxiosResponse<FilteredCartData>) => {
                replaceEmptyImages(config, entryGroups)
                const orderFromCart = entryGroups.data.order //  order from API Response
                if (checkDataLength(orderFromCart)) {
                    const orderData = getFilteredDeliveryModes(orderFromCart)
                    fillCheckoutDataFromAPI(orderData.cart, dispatch)
                    sessionStorageService.setItem(storageData.orderStatus, JSON.stringify(orderData))
                    dispatch(orderDetailsConfirmationSuccess(orderData))
                } else {
                    dispatch(orderDetailsExpired(true))
                }
                setShowSpinnerState && setShowSpinnerState(false)
            })
            .catch((err: AxiosError<CartResponseErrorDTO>) => {
                dispatch(orderDetailsConfirmationFailure(err?.response))
                setShowSpinnerState && setShowSpinnerState(false)
            })
    }

export const updateBillingInformation =
    (orderId: string, orderModificationData: OrderModificationType, showSpinner = false) =>
    (dispatch: Dispatch): void => {
        showSpinner && dispatch(setShowSpinner(true))
        orderDetailsService
            .updateBillingInformation(orderId, orderModificationData)
            .then(() => dispatch(updateBillingInformationOrderDetailsSuccess(true)))
            .catch((error: AxiosError<OrderDetaiilsErrorDTO>) => {
                dispatch(updateBillingInformationOrderDetailsFailure(error?.response?.data))
            })
            .finally(() => {
                showSpinner && dispatch(setShowSpinner(false))
            })
    }

const replaceEmptyImages = (config: EnvironmentConfig, data: AxiosResponse<FilteredCartData>) => {
    if (config.defaultProductImage) {
        const consignmentsData = data?.data?.order?.consignments
        Array.isArray(consignmentsData) &&
            consignmentsData.forEach(consignment => {
                replaceEmptyImagesWithDefault(consignment?.entries, 'images')
            })
    }
}
