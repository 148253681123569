import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import getAOAWorkflowType, { AOAWorkflowType } from '../../helpers/aoaWorkflow.helper'
import { setVehicleBannerVisible } from '../../redux/actionCreators'
import { getActiveStep, getHasStartedAOAFlow } from '../../redux/selectors/aoaBookingForm.selectors'
import { isAOAFlowEnabledSelector } from '../../redux/selectors/storeDetails.selectors'
import { getVehicleBanner } from '../../redux/selectors/vehicleBanner.selectors'
import { StepsQueue } from './AOABookingForm.constants'

/**
 * Hook that controls VehicleBanner visibility depending on WorkflowType and AOAFlowType
 */
export function useVehicleBannerVisibility(): void {
    const dispatch = useDispatch()

    const hasStartedAOAFlow = useSelector(getHasStartedAOAFlow)
    const isAOAFlowEnabled = useSelector(isAOAFlowEnabledSelector)
    const activeStep = useSelector(getActiveStep)
    const isVehicleBannerHidden = useSelector(getVehicleBanner).hideVehicleBanner
    const isErrorStep = activeStep === StepsQueue.ErrorNoAutoService || activeStep === StepsQueue.ErrorAOANotEnabled

    useEffect(() => {
        // Should display Vehicle Banner if Regular WorkflowType
        // AND (if AOA Flow is enabled or already mid - AOAFlow)
        // AND if booking isn't confirmed yet
        const shouldDisplayVehicleBanner = Boolean(
            getAOAWorkflowType() === AOAWorkflowType.Regular &&
                (isAOAFlowEnabled || hasStartedAOAFlow) &&
                activeStep < StepsQueue.BookingResult &&
                !isErrorStep,
        )

        // if banner is not already displayed or hidden -> display or hide it
        if (isVehicleBannerHidden === shouldDisplayVehicleBanner) {
            dispatch(setVehicleBannerVisible(shouldDisplayVehicleBanner))
        }
    }, [dispatch, isAOAFlowEnabled, hasStartedAOAFlow, activeStep, isVehicleBannerHidden, isErrorStep])
}
