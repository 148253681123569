import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProductDataSuccessAction } from '../../redux/actionCreators'
import { fetchProductData } from '../../redux/actions'
import { productSelector } from '../../redux/selectors/product.selectors'
import { selectedPreferredStoreIdSelector } from '../../redux/selectors/storeDetails.selectors'
import appCacheService from '../../utils/appCacheService'
import extractPCodeFromUrl from '../../utils/PDP/extractPCodeFromUrl'
import { replaceEmptyImagesWithDefault } from '../../utils/replaceEmptyImagesWithDefault'

const useProduct = () => {
    const dispatch = useDispatch()
    const selectedPreferredStoreId = useSelector(selectedPreferredStoreIdSelector)
    const { productData, productErrorResponse } = useSelector(productSelector)
    const { pCode, skuCode } = useMemo(() => extractPCodeFromUrl(), [])

    const preferredStoreIdValue = useMemo(() => {
        return selectedPreferredStoreId || appCacheService.preferredStoreId.get()
    }, [selectedPreferredStoreId])

    useEffect(() => {
        if (preferredStoreIdValue) {
            const { prefetchedProductPromise } = window

            if (!prefetchedProductPromise) {
                dispatch(fetchProductData(pCode, preferredStoreIdValue))
            } else {
                prefetchedProductPromise
                    .then(response => {
                        if (response.ok) return response.json()
                        throw new Error('Prefetch request fall')
                    })
                    .then(prefetchedProduct => {
                        replaceEmptyImagesWithDefault([prefetchedProduct], 'images')
                        dispatch(fetchProductDataSuccessAction(prefetchedProduct))
                    })
                    .catch(() => {
                        dispatch(fetchProductData(pCode, preferredStoreIdValue))
                    })
            }
        }
        return () => {
            window.prefetchedProductPromise = undefined
        }
    }, [dispatch, pCode, preferredStoreIdValue, skuCode])

    return { productData, productErrorResponse, skuCode }
}

export default useProduct
