import querystring, { ParsedUrlQueryInput } from 'querystring'
import { AxiosPromise } from 'axios'

import { BaseService } from '../base.service'
import { getEnvironment } from '../../environments'
import { getHttpClient } from '../../httpClient'
import { ProductDetailsRequestDTO, ProductDetailsResponse } from './productDetailsService.type'
import { ProductCardType, RecommendationResponseDataDTO } from '../../redux/models/recommendations.interface'
import { BreadcrumbLinkData } from '../../redux/models/productData.interface'
import appCacheService from '../../utils/appCacheService'
import { FeaturedListAuthorableScheme } from '../../components/FeaturedProductList/FeaturedProductList.constant'
import { SkuListType } from '../../components/FrequentlyBoughtTogether/FrequentlyBoughtTogether.type'
import { ThresholdValuesType } from '../../components/BuyBox/BuyBox.type'
import { MagicNumber } from '../../analytics/analytics.type'
import { RequestBodyType } from '../../httpClient/client.type'

const httpClient = getHttpClient()
const environment = getEnvironment()

/**
 * Service to get product details
 */
class ProductDetailsService extends BaseService {
    /**
     * Function used to construct the query params
     * @param {ProductDetailsRequestDTO} requestParams - request query params.
     * @return {string} return a=b&c=d&e=f
     */
    private constructQueryParams(requestParams: ProductDetailsRequestDTO): string {
        return querystring.stringify(requestParams as unknown as ParsedUrlQueryInput)
    }

    /**
     * Check and update the root state using scheme id
     * @param {RecommendationResponseDataDTO[]} storeRecommendationData - current Root state value for recommendation.
     * @param {RecommendationResponseDataDTO[]} scheme - id to which id should be added/updated.
     * @param {RecommendationResponseDataDTO[]} productData - cds response data for the pcodes.
     *
     * @return {RecommendationResponseDataDTO[]} return updated root state.
     */
    private updateRecommendationRootStateUsingSchemeId = (
        storeRecommendationData: RecommendationResponseDataDTO[],
        scheme: string,
        productData: ProductCardType[],
    ): RecommendationResponseDataDTO[] => {
        /**
         * If the scheme is already present, update its product data with the response
         * If the scheme is not present, append it.
         */
        const doesSchemeExist = storeRecommendationData.some(singleItem => singleItem.scheme === scheme)
        if (doesSchemeExist) {
            return [
                ...storeRecommendationData.map(singleRecommendationData => {
                    if (singleRecommendationData.scheme === scheme) {
                        return {
                            scheme: singleRecommendationData.scheme,
                            productData,
                        }
                    }
                    return singleRecommendationData
                }),
            ]
        } else {
            storeRecommendationData.push({
                scheme,
                productData,
            })
            return [...storeRecommendationData]
        }
    }

    /**
     * Create the request for recommendations.
     * @param {string} queryParams - Query param string
     * @return {URL} returns the request url
     */
    private createRequestURL(queryParams: string): URL {
        const { getRecommendations, recommendationData } = environment.API_ENDPOINTS

        const isMock = ProductDetailsService.isMock()

        const endPoint = isMock ? recommendationData : getRecommendations

        const url = new URL(`${environment.API_BASE_URL}${endPoint}?${queryParams}`)

        if (isMock) {
            return ProductDetailsService.prepareMockUrl(url, environment.API_MOCK_URL)
        } else {
            return url
        }
    }

    /**
     * Function to fetch data for the product codes acquired from certona
     * @param {RecommendationResponseDataDTO[]} storeData - store recommendation data.
     * @param {string[]} pCodes - List of p codes.
     * @param {string} scheme - id for which u want to extract the p codes from certona response.
     *
     * @return {AxiosPromise} returns promise.
     */
    public getProductDetailsUsingPCodes(storeData: RecommendationResponseDataDTO[], pCodes: string[], scheme: string) {
        const requestBody = JSON.stringify({
            productCodes: pCodes,
        })

        const { baseSiteId, language } = environment

        /**
         * API Request Query Params.
         */
        const queryParams: ProductDetailsRequestDTO = {
            storeName: appCacheService.preferredStoreId.get(),
            baseStoreId: baseSiteId,
            lang: language,
        }

        /**
         * Create the request url.
         */
        const url: string = this.createRequestURL(this.constructQueryParams(queryParams)).toString()

        /**
         * TODO: Remove apiGet when the api is finalized.
         * switch between post and get
         */
        const apiMethod = ProductDetailsService.isMock()
            ? httpClient.apiGet<ProductDetailsResponse>(url, {
                  productData: pCodes,
              })
            : httpClient.apiPost<ProductDetailsResponse>(url, requestBody)

        return new Promise<RecommendationResponseDataDTO[]>((resolve, reject) => {
            apiMethod
                .then(data =>
                    !!scheme
                        ? resolve(
                              this.updateRecommendationRootStateUsingSchemeId(storeData, scheme, data.data.products),
                          )
                        : resolve([
                              {
                                  scheme: FeaturedListAuthorableScheme,
                                  productData: data.data.products,
                              },
                          ]),
                )
                .catch(data => reject(data))
        })
    }

    /**
     * Function to get product details
     * @param {string} productCode
     * @param {string} selectedPreferredStoreId
     * @return {AxiosPromise}
     */
    getProductDetails(productCode: string, selectedPreferredStoreId: string): AxiosPromise<unknown> {
        return httpClient.apiGet(this.createPDPUrl(productCode, selectedPreferredStoreId).toString())
    }

    /**
     * Create url for the product details api
     * @param {string} productCode
     * @param {string} selectedPreferredStoreId
     * @return {URL}
     */
    createPDPUrl(productCode: string, selectedPreferredStoreId: string): URL {
        const language = ProductDetailsService.language

        const {
            API_BASE_URL,
            baseSiteId,
            API_ENDPOINTS: { productDetails },
        } = environment
        const pdpCDSUrl = `${productCode}?baseStoreId=${baseSiteId}&lang=${language}&storeId=${selectedPreferredStoreId}`
        return new URL(`${API_BASE_URL}${productDetails}/${pdpCDSUrl}`)
    }

    /**
     * Function to get sku product details
     * @param {string} skuCode
     * @param {string} selectedPreferredStoreId
     * @param {ThresholdValuesType} thresholdValues
     * @param {boolean} isFBT
     * @param {string} brandName
     * @return {AxiosPromise}
     */
    getProductDetailsOfSku(
        skuCode: string[] | SkuListType[],
        selectedPreferredStoreId: string,
        thresholdValues?: ThresholdValuesType,
        isFBT?: boolean,
        brandName?: string,
    ): AxiosPromise<unknown> {
        const { hotDealThreshold, limitedTimeThreshold, lowStockThreshold } = thresholdValues || {}

        const checkThresholdForNullType: RequestBodyType = {
            hotDealThreshold,
            limitedTimeThreshold,
        }

        Object.keys(checkThresholdForNullType).forEach(
            key => checkThresholdForNullType[key] == null && delete checkThresholdForNullType[key],
        )

        const requestBody = JSON.stringify({
            ...checkThresholdForNullType,
            skus: isFBT
                ? skuCode
                : [
                      {
                          code: skuCode[0],
                          lowStockThreshold,
                          brand: brandName || '',
                      },
                  ],
        })

        return httpClient.apiPost(this.createPDPUrlForSku(selectedPreferredStoreId).toString(), requestBody)
    }

    /**
     * Create url for the product details api
     * @param {string} selectedPreferredStoreId
     * @return {URL}
     */
    createPDPUrlForSku(selectedPreferredStoreId: string): URL {
        const language = ProductDetailsService.language

        const {
            API_BASE_URL,
            API_ENDPOINTS: { priceAvailability },
        } = environment

        const pdpSkuCDSUrl = `?lang=${language}&storeId=${selectedPreferredStoreId}`

        return new URL(`${API_BASE_URL}${priceAvailability}${pdpSkuCDSUrl}`)
    }

    /**
     * function to fetch size chart for current product
     * @param {BreadcrumbLinkData} breadcrumbList
     * @param {string} brand
     * @param {string} language
     * @return {AxiosPromise}
     */
    getSizeChartData(breadcrumbList: BreadcrumbLinkData[], brand: string, language: string): AxiosPromise<unknown> {
        const categoryIdList: string = breadcrumbList
            ?.reduce<string[]>(
                (previousValue, breadcrumb, index): string[] =>
                    index <= MagicNumber.TWO ? [...previousValue, breadcrumb.categoryId] : previousValue,
                [],
            )
            .join('/')
        const brandWithoutSpaces: string = brand.replace(/\s/g, '%20')
        const RELATIVE_URL = window.location.origin
        const url = `${RELATIVE_URL}/${language}/sizechart/${brandWithoutSpaces}/${categoryIdList}.json`

        return httpClient.apiGet(url)
    }
}

export const productDetailsService = new ProductDetailsService()
