import { checkDataLength, offerComponentLocation, offerConstants, replaceStrWithDynamicVal } from '@nl/lib'
import { MagicNumber } from '../analytics/analytics.type'
import {
    emailActivationUrl,
    lmsProfileIdParameterName,
    nppBaseRewardValue,
} from '../components/EmailOfferActivation/EmailOfferActivation.constant'
import { AwardValuesType } from '../components/PDPOfferBenefits/PDPOfferBenefits.type'
import { activateCustomerOffers } from '../redux/actions'
import { Offers } from '../redux/models/offers.interface'
import store from '../store'

/**
 * function render card title
 * @param {boolean} isRewardCard
 * @param {string} pteTriangleRewardsLabel
 * @param {string} pteTriangleMCBenefitsLabel
 * @return {string} returns the card title based on card type
 */
export const renderPTETitle = (
    isRewardCard: boolean,
    pteTriangleRewardsLabel: string,
    pteTriangleMCBenefitsLabel: string,
): string => {
    if (!!isRewardCard) {
        return pteTriangleRewardsLabel
    } else {
        return pteTriangleMCBenefitsLabel // for mastercard
    }
}

/**
 * function render card subtitle
 * @param {boolean} isRewardCard
 * @param {string} pteEverydayBonuses
 * @param {string} ptTriangleEarnCTMoneyText
 * @param {number} rewardsPercentage
 * @return {string} returns the card subtitle based on card type
 */
export const renderPTESubtitle = (
    isRewardCard: boolean,
    pteEverydayBonuses: string,
    ptTriangleEarnCTMoneyText: string,
    rewardsPercentage: number,
): string => {
    const cartSubtitle = !!isRewardCard ? pteEverydayBonuses : ptTriangleEarnCTMoneyText
    return replaceStrWithDynamicVal(cartSubtitle, `${rewardsPercentage}%`)
}

/**
 * function return sum of all award values
 * @param {number[]} awardValues
 * @return {number} returns sum of all award values
 */
export const renderPTEAmount = (awardValues: number[]): number => {
    return awardValues.reduce((a, c) => a + c, 0)
}

/**
 * function to check if offer status activated or not
 * @param {Offers} offer
 * @param {string} offerStatus
 * @return {boolean} returns true/false based on offer status
 */
export const checkOfferStatus = (offer: Offers, offerStatus: string): boolean => {
    return offer?.offerStatus?.toLowerCase() === offerStatus.toLowerCase()
}

/**
 * function to return total award value array
 * @param {Offers[]} offersData
 * @return {number[]} returns total award value array
 */
export const getAwardValues = (offersData: Offers[]): AwardValuesType => {
    const totalAwardValue: number[] = []
    const totalAwardValueForActivatedOffers: number[] = []
    offersData?.forEach((offerObj: Offers) => {
        const awardValue = Number(offerObj.details.awardValue)
        totalAwardValue.push(awardValue)
        !!checkOfferStatus(offerObj, offerConstants.activated) && totalAwardValueForActivatedOffers.push(awardValue)
    })
    return { totalAwardValue, totalAwardValueForActivatedOffers }
}

/**
 * function to return class based on offer status
 * @param {boolean} isActivatedOffer
 * @param {string} prefixClass
 * @return {string} function to return class based on offer status
 */
export const getOfferStatusClass = (isActivatedOffer: boolean, prefixClass: string): string => {
    return isActivatedOffer ? `${prefixClass}--activated` : `${prefixClass}--not-activated`
}

/**
 * @param {Offers[]} allOffers of offers to be activated -- can be one/many/all
 * @return {Offers[]} return offers that was set from ACTIVATE -> ACTIVATED
 */
export const handleOffersToActivated = (allOffers: Offers[]): Offers[] => {
    return allOffers?.map((offer: Offers) => {
        return checkOfferStatus(offer, offerConstants.activate)
            ? {
                  ...offer,
                  offerStatus: offerConstants.activated,
              }
            : offer
    })
}

/** Function to render number of the offers which are unactivated
 * @param {Offers} offers
 * @return {number} returns number of offers that are unactivated
 */
export const getUnactivatedOffersCount = (offers: Offers[]): number => {
    const inActivatedOffers = offers?.filter(offer => offer.offerStatus === offerConstants.activate)
    return inActivatedOffers?.length || MagicNumber.ZERO
}

/** Function to render npp offer title based on offer type eg: MULTIPLIER/SPENDANDGET
 * @param {boolean} isMultiplierOffer
 * @param {string} pteNppSpendAndGetOfferTitle
 * @param {string} pteNppMultiplierOfferTitle
 * @param {number} pteNppMultiplierAmount
 * @return {Offers[]} return offers that was set from ACTIVATE -> ACTIVATED
 */
export const renderNPPOfferTitle = (
    isMultiplierOffer: boolean,
    pteNppSpendAndGetOfferTitle: string,
    pteNppMultiplierOfferTitle: string,
    pteNppMultiplierAmount: number,
): string => {
    if (!!isMultiplierOffer) {
        return replaceStrWithDynamicVal(pteNppMultiplierOfferTitle, pteNppMultiplierAmount)
    } else {
        return pteNppSpendAndGetOfferTitle
    }
}

/** Function to render npp offer value based on offer type eg: MULTIPLIER/SPENDANDGET
 * @param {boolean} isMultiplierOffer
 * @param {number} pteNppSpendAndGetOfferVal
 * @param {number} pteNppMultiplierVal
 * @return {Offers[]} return offers that was set from ACTIVATE -> ACTIVATED
 */
export const renderNPPOfferValue = (
    isMultiplierOffer: boolean,
    pteNppSpendAndGetOfferVal: number,
    pteNppMultiplierVal: number,
): number => {
    if (!!isMultiplierOffer) {
        return pteNppMultiplierVal
    } else {
        return pteNppSpendAndGetOfferVal
    }
}

/**
 * callback function to activate offers and where offersActivatedData is dispatched
 * if there is a param passed, its a singlar offer to activate only; otherwise, its ALL offers
 * @param {Offers} offer
 * @param {React.Dispatch<React.SetStateAction<Offers>>} updateOfferState
 * @param {Offers[]} filteredOffers
 * @param {string} offerComponent
 */
export const activateOffer = (
    offer?: Offers,
    updateOfferState?: React.Dispatch<React.SetStateAction<Offers>>,
    filteredOffers?: Offers[],
    offerComponent?: string,
) => {
    let allOfferCodes: string[]
    const urlParams = new URLSearchParams(window.location.search)
    const activationUrl = window.location.href.includes(emailActivationUrl)
    const lmsProfileIdParam = activationUrl ? urlParams.get(lmsProfileIdParameterName) : null
    if (offerComponent === offerComponentLocation.loyaltyOffer) {
        if (checkDataLength(offer) && checkOfferStatus(offer, offerConstants.activate)) {
            allOfferCodes = [offer.offerCode]
            updateOfferState && updateOfferState(offer)
        }
    } else {
        if (checkDataLength(offer)) {
            allOfferCodes = [offer.offerCode]
            updateOfferState && updateOfferState(offer)
        } else {
            allOfferCodes = filteredOffers?.map((filteroffer: Offers) => {
                return filteroffer[offerConstants.offerCode as keyof typeof filteroffer]
            })
        }
    }
    if (allOfferCodes) {
        const payload = {
            offerCodes: allOfferCodes,
        }

        store.dispatch(
            lmsProfileIdParam ? activateCustomerOffers(payload, lmsProfileIdParam) : activateCustomerOffers(payload),
        )
    }
}

/**
 * function to display offers after filtering with offer banner
 * @param {Offers[]} offersData
 * @param {string[]} offerBannerList
 * @return {Offers[]} returns offers which are filtered
 */
export const getFilteredOffersBanner = (offersData: Offers[], offerBannerList: string[]): Offers[] => {
    return offersData?.filter(offer => offerBannerList?.some((banner: string) => offer?.banner.includes(banner))) || []
}

/**
 * function to render NPP multiplier offer award value
 * @param {number} pteNppMultiplierAmount
 * @param {number} currentPrice
 * @return {number} returns NPP multiplier offer award value
 */
export const renderMultiplierAwardValue = (pteNppMultiplierAmount: number, currentPrice: number): number => {
    return Number(nppBaseRewardValue * pteNppMultiplierAmount * currentPrice) || MagicNumber.ZERO
}

/**
 * function to render bonus earn value
 * @param {boolean} isRewardCard
 * @param {boolean} isMultiplierOffer
 * @param {number} pteNppMultiplierAmount
 * @param {number} pteNppMultiplierAmountMCUser
 * @param {number} pteNppSpendAndGetOfferVal
 * @param {number} subtotal
 * @return {number} return bonus earn value
 */
export const getBonusValue = (
    isRewardCard: boolean,
    isMultiplierOffer: boolean,
    pteNppMultiplierAmount: number,
    pteNppMultiplierAmountMCUser: number,
    pteNppSpendAndGetOfferVal: number,
    subtotal: number,
): number => {
    const nppMultiplierAmount = isRewardCard ? pteNppMultiplierAmount : pteNppMultiplierAmountMCUser
    const nppMultiplierAwardValue = renderMultiplierAwardValue(nppMultiplierAmount, subtotal)
    const bonusEarnValue = isMultiplierOffer ? nppMultiplierAwardValue : pteNppSpendAndGetOfferVal
    return bonusEarnValue ? bonusEarnValue : MagicNumber.ZERO
}

/**
 * function to check whether bonus satisfy minimum purchase criteria
 * @param {boolean} isMultiplierOffer
 * @param {number} pteMinMultiplierVal
 * @param {number} pteMinSpendAndGetVal
 * @param {number} subtotal
 * @return {boolean}
 */
export const bonusToDisplay = (
    isMultiplierOffer: boolean,
    pteMinMultiplierVal: number,
    pteMinSpendAndGetVal: number,
    subtotal: number,
): boolean => {
    const pteMinVal = isMultiplierOffer ? pteMinMultiplierVal : pteMinSpendAndGetVal
    return Boolean(subtotal >= pteMinVal)
}

/**
 * function to display notification Badge
 * @param {number} unActivatedOffersCount
 * @param {number} offersBadgeCountThreshold
 * @return {boolean} returns true/false based on condition
 */
export const showBadge = (unActivatedOffersCount: number, offersBadgeCountThreshold: number): boolean => {
    return Boolean(!!unActivatedOffersCount && unActivatedOffersCount <= offersBadgeCountThreshold)
}
