import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { SkeletonComponent, isArrayNotEmpty, isArrayEmpty, Button, libUtils, Icon, CategoryCard } from '@nl/lib'
import { BREAKPOINTS, PREFIX } from '../../config'
import { RootState } from '../../redux/reducers'
import { CategoryStackedProps } from './CategoryStacked.type'
import { CertonaInitialization } from '../../certona/certona.service'
import { CertonaProductType } from '../../certona/certona.type'
import { categoryItems } from './CateogryStacked.constant'
import { usePageAnalytics } from '../../analytics/hooks/usePageAnalytics'
import { analyticsInteraction } from '../../redux/actions'
import { analyticsAttributes } from '../../globalConstants/analyticsParams.constant'
import { AkamaiImagePolicies } from '../../akamaiPolicy/akamaiPolicy.service'
import { commonContentSelector } from '../../redux/selectors/commonContent.selectors'
import { getEnvironment } from '../../environments'

// Component for Dynamic Category Featured List
const CategoryStacked: React.FC<CategoryStackedProps> = ({
    title,
    schemaId,
    numberOfCategories,
    maximizedStateLinkText,
    minimizedStateLinkText,
}): JSX.Element => {
    const config = getEnvironment()
    const recommendationData = useSelector((state: RootState) => state.certona)
    const { commonContentAvailable } = useSelector(commonContentSelector)
    const [componentTitle, setComponentTitle] = useState('')
    const [recommendationItems, setRecommendationItems] = useState([] as CertonaProductType[])
    const [noOfCategoriesToShowDetails, setNoOfCategoriesToShowDetails] = useState({
        defaultNumber: numberOfCategories,
        currentNumber: numberOfCategories,
    })
    const [viewButtonDetails, setViewButtonDetails] = useState({
        showButton: false,
        buttonText: minimizedStateLinkText,
        showViewMore: true,
    })

    const {
        event: { filter },
        eventParameters: {
            action: { shopByCategory },
        },
    } = analyticsAttributes

    const [noOfItemsToShow, setNoOfItemToShow] = useState(0)
    const [showSkeleton, setShowSkeleton] = useState(true)

    const [[a11yExpandText, a11yCollapseText]] = useMemo(
        () =>
            libUtils.extractKeyValues(commonContentAvailable, [
                { accessibility: ['a11yExpandText', 'a11yCollapseText'] },
            ]) as unknown[][],
        [commonContentAvailable],
    )

    // Aria-label for view button.
    const buttonAriaLabel = viewButtonDetails.showViewMore ? (a11yExpandText as string) : (a11yCollapseText as string)

    // Toggle chevron
    const buttonChevron = viewButtonDetails.showViewMore ? 'ct-chevron-down' : 'ct-chevron-up'

    const dispatch = useDispatch()

    usePageAnalytics()

    useEffect(() => {
        const schemes = recommendationData?.resonance?.schemes
        if (isArrayEmpty(schemes)) {
            setShowSkeleton(false)
        } else {
            const { getItems, getTitle } = CertonaInitialization.extractSchemeDetails(schemes, schemaId)
            setComponentTitle(getTitle)
            setRecommendationItems(getItems)
            setShowSkeleton(false)
        }
    }, [recommendationData, schemaId])

    /**
     * Function to toggle view button text.
     * @param {boolean} showButton
     */
    const updateViewButtonDetails = (showButton: boolean): void => {
        setViewButtonDetails(prevState => {
            return { ...prevState, showButton }
        })
    }

    /**
     * Function to set a fixed number on load.
     * @param {number} currentNumber
     */
    const updateNoOfItemToShow = (currentNumber: number): void => {
        setNoOfCategoriesToShowDetails(prevState => {
            return {
                ...prevState,
                currentNumber,
            }
        })
    }

    /**
     * Function to toggle view button & set fixed no of item on load at certain breakpoints.
     */
    const renderViewButton = useCallback((): void => {
        const {
            mobile,
            tablet: { FOUR, EIGHT },
            noOfCategories: { FIVE },
        } = categoryItems
        if (window.innerWidth < BREAKPOINTS.mobileMaxWidth) {
            updateViewButtonDetails(true)
            updateNoOfItemToShow(mobile)
        } else if (window.innerWidth < BREAKPOINTS.tabletMaxWidth) {
            updateViewButtonDetails(true)
            const itemsToShow = numberOfCategories === FIVE ? FOUR : EIGHT
            updateNoOfItemToShow(itemsToShow)
        } else {
            updateViewButtonDetails(false)
            updateNoOfItemToShow(numberOfCategories)
        }
    }, [numberOfCategories])

    // UseEffect to render view button
    useEffect(() => {
        renderViewButton()
    }, [renderViewButton])

    // Handles resize events.
    libUtils.useWindowEvent('resize', renderViewButton)

    /**
     * Renders skeleton when there is no recommendation data.
     * @return {JSX.Element[]}
     */
    const renderSkeletonComponent = (): JSX.Element => {
        return (
            <ul className={`${PREFIX}-col-lg-12 ${PREFIX}-product-category__grid-items`}>
                {[...Array<undefined>(noOfItemsToShow)].map((_el, index) => (
                    <li key={index} className={`${PREFIX}-product-category__content`}>
                        <SkeletonComponent skeletonClass={`${PREFIX}-product-category__skeleton-wrapper`} />
                    </li>
                ))}
            </ul>
        )
    }

    const cardClicked = (label: string | undefined): void => {
        dispatch(analyticsInteraction(label as string, '', filter, shopByCategory))
    }

    /**
     * Renders category item when certona sends data
     * @return {JSX.Element[]}
     */
    const renderCategoryItems = (): JSX.Element[] => {
        return recommendationItems
            .slice(0, noOfItemsToShow)
            .map(({ url, label, image }: CertonaProductType, index: number) => (
                <li key={`${String(label)}_${index}`} className={`${PREFIX}-product-category__content`}>
                    <CategoryCard
                        href={url}
                        imagePolicies={AkamaiImagePolicies.initIndPolicy}
                        cardClicked={cardClicked}
                        linkClass={`${PREFIX}-product-category__link`}
                        imageClass={`${PREFIX}-product-category__image`}
                        labelClass={`${PREFIX}-product-category__label`}
                        image={image ? image : config.defaultProductImage}
                        label={label}
                    />
                </li>
            ))
    }

    useEffect(() => {
        // to hide and show a particular no. of items.
        const { currentNumber, defaultNumber } = noOfCategoriesToShowDetails
        viewButtonDetails.showViewMore ? setNoOfItemToShow(currentNumber) : setNoOfItemToShow(defaultNumber)
    }, [viewButtonDetails.showViewMore, noOfCategoriesToShowDetails])

    /**
     * View Button click Handler. Applicable only for mobile & tablet.
     * @return {void} sets the view button details.
     */
    const toggleViewButtonText = (): void => {
        setViewButtonDetails(prevState => {
            return {
                ...prevState,
                showViewMore: !prevState.showViewMore,
                buttonText: prevState.showViewMore ? maximizedStateLinkText : minimizedStateLinkText,
            }
        })

        if (viewButtonDetails.showViewMore) {
            const productCategoryElement = document.querySelector(`.${PREFIX}-product-category`)
            if (typeof productCategoryElement?.scrollIntoView === 'function') {
                productCategoryElement?.scrollIntoView({
                    behavior: 'smooth',
                })
            }
        }
    }

    /**
     * Function to render items with view more button.
     * @return {JSX.Element}
     */
    const renderCategoryItemWithButton = (): JSX.Element => {
        const { buttonText, showButton } = viewButtonDetails
        return (
            <>
                <ul className={`${PREFIX}-col-lg-12  ${PREFIX}-product-category__grid-items`}>
                    {renderCategoryItems()}
                </ul>
                {showButton && (
                    <Button
                        type="tertiary"
                        id="toggle-view-button"
                        onClick={toggleViewButtonText}
                        ariaLabel={buttonAriaLabel}>
                        {buttonText}
                        <Icon type={buttonChevron} size="lg" />
                    </Button>
                )}
            </>
        )
    }

    /**
     * Render title component.
     * @return {JSX.Element | null}
     */
    const renderTitleComponent = (): JSX.Element | null => {
        const showCategoryTitle = !!(componentTitle || title) && isArrayNotEmpty(recommendationItems)
        return showCategoryTitle && !showSkeleton ? (
            <h2 className={`${PREFIX}-product-category__title`}>{title || componentTitle}</h2>
        ) : showSkeleton ? (
            <SkeletonComponent skeletonClass={`${PREFIX}-product-category__skeleton-title`} />
        ) : null
    }

    /**
     * Render Category Stacked Component
     * @return {JSX.Element}
     */
    const renderCategorySection = (): JSX.Element => {
        const conntentModifier = isArrayNotEmpty(recommendationItems)
            ? `${PREFIX}-product-category--content-padding`
            : ''
        return (
            <div className={`${PREFIX}-container ${PREFIX}-row ${PREFIX}-product-category ${conntentModifier}`}>
                {renderTitleComponent()}
                {isArrayNotEmpty(recommendationItems)
                    ? !showSkeleton && renderCategoryItemWithButton()
                    : showSkeleton && renderSkeletonComponent()}
            </div>
        )
    }
    return renderCategorySection()
}
export default CategoryStacked
