import { ActionType, getType } from 'typesafe-actions'
import * as actions from '../actionCreators/weatherTech.actionCreators'
import { WeatherTech, WeatherTechVehicle } from '../models/weatherTech.interface'
import { checkDataLength } from '@nl/lib'

export const initialState: WeatherTech = {
    weatherTechVehicle: null,
    isWeatherTechFlow: false,
}
type Action = ActionType<typeof actions>

/**
 * update weather tech values
 * @param { Object } state set to initial state by default
 * @param { Action } action called
 * @return {Object}
 *
 */
export const weatherTechReducer = (state = initialState, action: Action): WeatherTech => {
    switch (action.type) {
        case getType(actions.setWeatherTechVehicle):
            return {
                ...state,
                weatherTechVehicle: action.payload,
                isWeatherTechFlow: isWeatherTechVehicleSelected(action.payload),
            }
        case getType(actions.setWeatherTechVehicleVendorResults):
            const { weatherTechVehicle } = state
            return { ...state, weatherTechVehicle: { ...weatherTechVehicle, vendorResults: action.payload } }
        case getType(actions.setIsWeatherTechFlow):
            return { ...state, isWeatherTechFlow: action.payload }
        default:
            return state
    }
}

/**
 * Function to check that weather tech vehicle selected
 * @param {WeatherTechVehicle} weatherTechVehicle
 * @return {boolean}
 */
const isWeatherTechVehicleSelected = (weatherTechVehicle: WeatherTechVehicle): boolean => {
    return (
        weatherTechVehicle &&
        checkDataLength(weatherTechVehicle.year) &&
        checkDataLength(weatherTechVehicle.vehicleId) &&
        checkDataLength(weatherTechVehicle.groupId) &&
        checkDataLength(weatherTechVehicle.choiceIds)
    )
}
