import { magicNumber } from '@nl/lib'
import { DropdownOptions, DropdownOptionsState, DropdownType } from '../models/vehicleDropdowns.types'
import { vehiclesDataInterface } from '../models/vehiclesData.interface'
import { resetDropdown } from './resetDropdown'
import { uniqueStringsUnion } from './uniqueStringsUnion'
import { VehicleTypeIds } from '../../components/Vehicles/Vehicles.constant'
/**
 * Return updated dropdowns after setting value.
 * Expected as value for dropdowns as label of this dropdowns, because if dropdown is absent - it will be created with state PRESELECTED.
 * If the updated dropdown exists - its state will be UPDATE - what will cause to call API in the next iteration.
 * @param {vehiclesDataInterface} state
 * @param {Record} valuesMap - the key is  dropdown id (vehicle attribute id),  value has label of dropdown and value to insert.
 * @return {DropdownType[]} updated dropdonw
 */
export const updateDropDownValues = (
    state: vehiclesDataInterface,
    valuesMap: Record<string, { label: string; value: string }>,
): DropdownType[] => {
    const keysToUpdate = Object.keys(valuesMap)

    // Joining mandatory dorpdown ids with current ids and not existed ids but with values to update
    const currentIds = uniqueStringsUnion(
        [VehicleTypeIds.vehicleType],
        state.baseDropdownsKeys,
        state.dropdowns.map(item => item.id),
        keysToUpdate,
    )

    // build or update dropdowns for an each id. The result pack into new array to lead all component with dropdown to rerender after.
    const newDropdowns = currentIds.map(key => {
        const value = valuesMap[key]?.value || ''
        // Not using key like label to avoid some cases blink
        const label = valuesMap[key]?.label || ''
        const updatedDropdownIndex = state.dropdowns.findIndex(drop => drop.id === key)
        const updatedDropdown =
            state.dropdowns[updatedDropdownIndex] ||
            ({
                id: key,
                options: [],
                label: label,
                // don't put value here directly, it is necessary to state calculation
                value: '',
                isDisabled: true,
                optionsState: DropdownOptionsState.NOT_LOADED,
            } as DropdownType)
        if (updatedDropdown && value && value !== updatedDropdown.value) {
            updatedDropdown.value = value
            switch (updatedDropdown.optionsState) {
                case DropdownOptionsState.TO_SKIP:
                case DropdownOptionsState.NOT_LOADED:
                    updatedDropdown.options = [
                        {
                            label: updatedDropdown.value,
                            id: updatedDropdown.value,
                            selected: true,
                        },
                    ] as unknown as DropdownOptions[]
                    updatedDropdown.optionsState = DropdownOptionsState.PRESELECTED
                    break
                case DropdownOptionsState.PRESELECTED:
                    updatedDropdown.options = [
                        {
                            label: updatedDropdown.value,
                            id: updatedDropdown.value,
                            selected: true,
                        },
                    ] as unknown as DropdownOptions[]
                    updatedDropdown.optionsState = DropdownOptionsState.PRESELECTED_AND_UPDATED
                    break
                default:
                    updatedDropdown.optionsState = DropdownOptionsState.UPDATED
                    updatedDropdown.options?.forEach(
                        option => (option.selected = option.label === updatedDropdown.value),
                    )
            }
        }

        return updatedDropdown
    })

    // find last index of updated element, can be less that number of base attributes
    const lastKeyIndex = Math.max(...keysToUpdate.map(key => newDropdowns.findIndex(drop => drop.id === key)))

    // reset options of not mandatory dropdown after selected values
    if (lastKeyIndex !== magicNumber.MINUS_ONE) {
        newDropdowns.slice(lastKeyIndex + magicNumber.ONE).forEach(resetDropdown)
    }

    return newDropdowns
}
export default updateDropDownValues
