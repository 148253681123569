import React from 'react'
import PropTypes from 'prop-types'
import { isArrayNotEmpty, Icon } from '@nl/lib'
import { PREFIX } from '../../../config'
import { StoreServicesProps } from './StoreServices.type'

/**
 * StoreServices component
 * @param {storeServicesProps} props
 * @return {JSX.Element} returns returns StoreServices Component
 */
const StoreServices: React.FC<StoreServicesProps> = ({ ...props }) => {
    const { servicesTitle, services } = props
    return (
        <div className={`${PREFIX}-store-details__services`}>
            <h2 className={`${PREFIX}-store-details__services__name`}>{servicesTitle}</h2>
            <ul className={`${PREFIX}-col-sm-6`}>
                {isArrayNotEmpty(services) &&
                    services.map((service: string, index: number) => {
                        return (
                            <li key={`${index}-${service}`}>
                                <div className={`${PREFIX}-store-details__services__icon`}>
                                    <Icon type="ct-confirm" size="sm" />
                                </div>
                                <div className={`${PREFIX}-store-details__services__text`}>{service}</div>
                            </li>
                        )
                    })}
            </ul>
        </div>
    )
}

StoreServices.propTypes = {
    servicesTitle: PropTypes.string.isRequired,
    services: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
}

export default StoreServices
