import React, { Validator } from 'react'
import PropTypes from 'prop-types'
import Icon from '../../components/Icon'
import { PREFIX } from '../../components/config'
import classnames from 'classnames'
import { getAriaCurrentValue } from '../MegaNavigation/MegaNavigation.helper'
import { ariaType } from '../../globalConstants/global.constant'
import { InteractionAnalyticsType, MegaNavigationDesktopType, NavigationCatalog } from './MegaNavigationDesktop.type'
import SubCategories from './SubCategories'

export const MegaNavigationDesktop: React.FC<MegaNavigationDesktopType> = ({
    catalog,
    InteractionAnalytics,
    eventName,
    actionName,
}) => {
    return (
        <div className={classnames(`${PREFIX}-mega-navigation`, `${PREFIX}-row`)}>
            <div className={classnames(`${PREFIX}-col-sm-12`, `${PREFIX}-mega-navigation__padding`)}>
                <h3>
                    <InteractionAnalytics
                        componentName="MegaNavigation"
                        label={catalog?.megaNavTitle}
                        eventName={eventName}
                        actionName={actionName}>
                        <a
                            aria-current={getAriaCurrentValue(catalog) ? ariaType : false}
                            href={encodeURI(typeof catalog?.url === 'string' ? catalog?.url : catalog?.url?.url)}
                            data-link-value={catalog?.megaNavTitle}>
                            {catalog?.megaNavTitle}
                        </a>{' '}
                        <Icon type="ct-chevron-right" size="md" />
                    </InteractionAnalytics>
                </h3>
            </div>
            <hr />
            {/* L2 links container */}
            <SubCategories
                catalog={catalog}
                InteractionAnalytics={InteractionAnalytics}
                eventName={eventName}
                actionName={actionName}
            />
        </div>
    )
}

MegaNavigationDesktop.propTypes = {
    InteractionAnalytics: PropTypes.node.isRequired as unknown as Validator<React.FC<InteractionAnalyticsType>>,
    catalog: PropTypes.object.isRequired as Validator<NavigationCatalog>,
}
