import React, { useEffect, useMemo, useState, useCallback, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import Icon from '@nl/lib/src/components/Icon'

import { BookingSummaryProps } from './BookingSummary.type'
import BookingSummaryDataBlock from './BookingSummaryDataBlock'
import {
    calcBookingSummaryMobileHeight,
    getDateTimeBlockFormatted,
    hideInfoBanner,
    onBookingSummaryScroll,
    reduxSelectors,
    setBookingFormVisibility,
    setMobileBannerTop,
} from './BookingSummary.helper'

import { PREFIX } from '../../config'
import {
    saveCurrentBookingFormStep,
    saveOpenedStoreLocatorModal,
    setVehicleListVisibility,
    saveContactInfoEditMode,
} from '../../redux/actionCreators'
import { StepsQueue } from '../AOABookingForm/AOABookingForm.constants'
import { withBookingFlowControl } from '../AOABookingForm/withBookingFlowControl.hoc'
import { SkeletonComponent, MODAL_OPEN_CLASS_NOT_SCROLL } from '@nl/lib'
import getAOAWorkflowType, { AOAWorkflowType } from '../../helpers/aoaWorkflow.helper'
import { clearDateStepDataIfSelectionChanged, returnInAppQueryParam } from '../AOABookingForm/AOABookingForm.helper'
import {
    getActiveStep,
    getAdditionalComments,
    getPreviouslySelectedServices,
    getSelectedServices,
} from '../../redux/selectors/aoaBookingForm.selectors'
import { getAOAErrorType } from '../../redux/selectors/aoaErrorState.selectors'
import { AOAErrorType } from '../../redux/models/aoaBookingForm.interface'
import { useIsMobile } from '../../hooks/useIsMobile.hook'
import AOACurrentLocation from './AOACurrentLocation'
import { componentType } from '../AOABookingForm/withBookingFlowControl.constants'

/**
 * BookingSummary component
 * @param {BookingSummaryProps} props
 * @return {JSX.Element | null} Renders BookingSummary if store's opted into AOA flow
 */
const BookingSummary: React.FC<BookingSummaryProps> = withBookingFlowControl(({ ...props }) => {
    const {
        title,
        infoLabel,
        vehicleLabel,
        locationLabel,
        dateTimeLabel,
        servicesLabel,
        noVehicleSelectedLabel,
        additionalInformation,
    } = props

    const dispatch = useDispatch()
    const workflowType = getAOAWorkflowType()
    const isAOARegularFlow = workflowType === AOAWorkflowType.Regular

    // Flag for BookingSummary visibility on mobile
    const [isMobileBookingSummaryOpen, setIsMobileBookingSummaryOpen] = useState(false)

    // Redux data from the steps
    const vehicle = useSelector(reduxSelectors.vehicleInfoSelector)
    const contactInfo = useSelector(reduxSelectors.contactInfoSelector)
    const dateTimeInfo = useSelector(reduxSelectors.dateTimeInfoSelector)
    const serviceInfo = useSelector(reduxSelectors.serviceInfoSelector)
    const locationInfo = useSelector(reduxSelectors.locationInfoSelector)
    const showSkeleton = useSelector(reduxSelectors.showSkeletonSelector)
    const selectedServices = useSelector(getSelectedServices)
    const previouslySelectedServices = useSelector(getPreviouslySelectedServices)
    const currentStep = useSelector(reduxSelectors.currentStepSelector)
    const errorType = useSelector(getAOAErrorType)
    const activeStep = useSelector(getActiveStep)
    const additionalCommentsInRedux = useSelector(getAdditionalComments)
    const isMobile = useIsMobile()
    const isErrorStep = activeStep === StepsQueue.ErrorNoAutoService || activeStep === StepsQueue.ErrorAOANotEnabled

    /**
     * Display change link when the Summary block step is not current step and it is Regular AOA workflow
     * @param {number} summaryBlockStep
     * @return {boolean}
     */
    const shouldShowChangeLink = (summaryBlockStep: number): boolean => {
        return currentStep !== summaryBlockStep && isAOARegularFlow
    }

    /**
     * Determines whether to show or hide a component
     * based on the workflow and the active step and errorType
     * @return {boolean}
     */
    const getIsHidden = () => {
        switch (workflowType) {
            case AOAWorkflowType.Regular:
                return currentStep >= StepsQueue.BookingResult
            case AOAWorkflowType.ETire:
                return (
                    currentStep >= StepsQueue.ETireBookingConfirmation ||
                    (errorType && errorType === AOAErrorType.MessageCard)
                )
            case AOAWorkflowType.ChangeOrCancel:
                return [
                    StepsQueue.ModifyOrCancelAppointment,
                    StepsQueue.CancelledAppointment,
                    StepsQueue.ModifyBookingConfirmation,
                ].includes(currentStep)
        }
    }

    // Generates classNames for booking summary container based on workflow type
    const getBSContainerClassNames = useCallback(() => {
        return (
            `${PREFIX}-booking-summary__container` +
            (workflowType !== AOAWorkflowType.ChangeOrCancel
                ? ` ${PREFIX}-booking-summary__container--with-padding`
                : '')
        )
    }, [workflowType])

    // useLayoutEffect to add event listener for scroll to make mobile banner sticky to the main navigation panel
    useLayoutEffect(() => {
        window.addEventListener('scroll', onBookingSummaryScroll())
        return () => {
            window.removeEventListener('scroll', onBookingSummaryScroll())
        }
    }, [isMobileBookingSummaryOpen])

    // [AOA-620] issue useEffect to hide the booking summary component on mobile view when appointment is confirmed
    useEffect(() => {
        currentStep === StepsQueue.BookingResult && isMobileBookingSummaryOpen && setIsMobileBookingSummaryOpen(false)
    }, [currentStep, isMobileBookingSummaryOpen])

    // useLayoutEffect to hide breadcrumb component on mobile and set appropriate marginTop for vehicle selector
    useLayoutEffect(() => {
        document.querySelector('.breadcrumb')?.setAttribute('style', `display: ${isMobile ? 'none' : 'block'}`)
        document
            .querySelector(`.vehicleselector`)
            ?.setAttribute('style', `margin-top: ${isMobile && isAOARegularFlow && !isErrorStep ? '35px' : '0px'}`)
    }, [isMobile, isAOARegularFlow, isErrorStep])

    // useLayoutEffect to set height of BookingSummary element on phone
    useLayoutEffect(() => {
        if (isMobile && isMobileBookingSummaryOpen) {
            // calculate the top and height for BS bg-wrapper with respect to (Navigation bar + store locator banner + BS mobile banner)
            calcBookingSummaryMobileHeight()
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }
    }, [isMobileBookingSummaryOpen, isMobile])

    // useLayoutEffect to set the visibility of booking form on mobile
    useLayoutEffect(() => {
        if (isMobile) {
            setBookingFormVisibility(!isMobileBookingSummaryOpen)
            setMobileBannerTop(isMobileBookingSummaryOpen)
            hideInfoBanner(isMobileBookingSummaryOpen)
        }
    }, [isMobileBookingSummaryOpen, isMobile])

    // useLayoutEffect to close the mobile booking summary if open, show the booking form and show the Info banner
    // when user toggles from portrait(mobile) to landscape(tablet) view (eg: Galaxy Tab S4)
    useLayoutEffect(() => {
        if (!isMobile && isMobileBookingSummaryOpen) {
            setIsMobileBookingSummaryOpen(false)
            setBookingFormVisibility(true)
            hideInfoBanner(!isMobileBookingSummaryOpen)
        }
    }, [isMobile, isMobileBookingSummaryOpen])

    // Change button click handler to redirect to the corresponding step
    const handleOnChangeRedirect = useCallback(
        (step: number) => {
            dispatch(saveCurrentBookingFormStep(step))

            // [AOA-620] defect collapse the Booking summary when user click on change link
            isMobileBookingSummaryOpen && setIsMobileBookingSummaryOpen(false)
        },
        [dispatch, isMobileBookingSummaryOpen],
    )

    // Change button click handler for Vehicle block
    const handleOnChangeVehicle = useCallback(() => {
        dispatch(setVehicleListVisibility(true))

        // [AOA-620] defect collapse the Booking summary when user click on change link
        isMobileBookingSummaryOpen && setIsMobileBookingSummaryOpen(false)
    }, [dispatch, isMobileBookingSummaryOpen])

    // Change button click handler for Contact Info block
    const handleOnChangeContactInfo = useCallback(() => {
        handleOnChangeRedirect(StepsQueue.Info)
        dispatch(saveContactInfoEditMode(true))
    }, [dispatch, handleOnChangeRedirect])

    // Change button click handler to redirect to the corresponding step and show Store Locator
    const handleOnLocationChangeOpenLocator = useCallback(() => {
        handleOnChangeRedirect(StepsQueue.Location)
        dispatch(saveOpenedStoreLocatorModal(true))
    }, [dispatch, handleOnChangeRedirect])

    // Change button click handler for Date time block
    const handleOnChangeDateTimeInfo = useCallback(() => {
        if (selectedServices && previouslySelectedServices) {
            clearDateStepDataIfSelectionChanged(previouslySelectedServices, selectedServices)
        }
        handleOnChangeRedirect(StepsQueue.Date)
    }, [selectedServices, previouslySelectedServices, handleOnChangeRedirect])

    // Toggle mobile Booking Summary visibility handler
    const handleToggleMobileBookingSummary = () => {
        setIsMobileBookingSummaryOpen(current => !current)
    }

    // Hide scroll on body if mobile Booking Summary Open
    useLayoutEffect(() => {
        if (isMobile && isMobileBookingSummaryOpen) {
            document.body.classList.add(MODAL_OPEN_CLASS_NOT_SCROLL)
            return () => {
                document.body.classList.remove(MODAL_OPEN_CLASS_NOT_SCROLL)
            }
        }
    }, [isMobile, isMobileBookingSummaryOpen])

    // Turns Vehicle data from redux to needed string format
    const vehicleBlockInfo = useMemo((): string => {
        let selectedVehicle = ''
        if (!!vehicle) {
            const { year, make, model } = vehicle
            selectedVehicle = [year, make, model].filter(Boolean).join(' ')
        }
        return selectedVehicle || noVehicleSelectedLabel
    }, [vehicle, noVehicleSelectedLabel])

    // Turns Info data from redux to needed string format
    const infoBlockInfo = useMemo((): string => {
        return contactInfo?.email && contactInfo?.mobilePhone ? `${contactInfo.email}, ${contactInfo.mobilePhone}` : ''
    }, [contactInfo])

    // Turns Selected Services data to needed string format
    const getSelectedServicesInfo = (): string => {
        return serviceInfo && serviceInfo.selectedServices
            ? serviceInfo.selectedServices.map(service => service.serviceName).join(', ')
            : ''
    }

    // Turns Date & Time data from redux to needed string format
    const dateBlockInfo = useMemo((): string => {
        return dateTimeInfo ? getDateTimeBlockFormatted(dateTimeInfo) : ''
    }, [dateTimeInfo])

    if (showSkeleton) {
        return <SkeletonComponent skeletonClass={`${PREFIX}-booking-summary__skeleton`} />
    }

    const inApp = returnInAppQueryParam()

    // This function handles Booking Summary Mobile Banner
    const handleBookingSummaryMobileBanner = () => {
        return (
            !(isErrorStep && isMobile) && (
                <button
                    className={`${PREFIX}-booking-summary__mobile-banner`}
                    data-testid={`${PREFIX}-booking-summary__mobile-banner`}
                    onClick={handleToggleMobileBookingSummary}>
                    <span className={`${PREFIX}-booking-summary__mobile-banner__label`}>{title}</span>
                    <Icon type={isMobileBookingSummaryOpen ? 'ct-chevron-up' : 'ct-chevron-down'} size="md" />
                </button>
            )
        )
    }

    return (
        <div
            className={`${PREFIX}-booking-summary` + (getIsHidden() ? ` ${PREFIX}-booking-summary__hidden` : '')}
            data-testid={`${PREFIX}-booking-summary`}>
            {handleBookingSummaryMobileBanner()}
            <div
                className={`${PREFIX}-booking-summary__bg-wrapper`}
                style={isMobileBookingSummaryOpen ? { display: 'block' } : undefined}
                data-testid="booking-summary__wrapper">
                {errorType !== AOAErrorType.MessageCard && (
                    <div className={getBSContainerClassNames()}>
                        {isErrorStep ? (
                            <AOACurrentLocation currentLocationTitle={props.currentLocationTitle} />
                        ) : (
                            <>
                                <div className={`${PREFIX}-booking-summary__header`}>
                                    <div className={`${PREFIX}-booking-summary__header__label`}>{title}</div>
                                </div>
                                <div className={`${PREFIX}-booking-summary__data-section`}>
                                    <BookingSummaryDataBlock
                                        id="AOA-booking-summary-vehicle-block"
                                        stepLabel={vehicleLabel}
                                        stepInfo={vehicleBlockInfo}
                                        showLabel={true}
                                        onChangeClick={handleOnChangeVehicle}
                                        showChangeLink={
                                            isAOARegularFlow && !inApp && vehicleBlockInfo !== noVehicleSelectedLabel
                                        }
                                        isVehicleBlock
                                    />
                                    <BookingSummaryDataBlock
                                        id="AOA-booking-summary-info-block"
                                        stepLabel={infoLabel}
                                        stepInfo={infoBlockInfo}
                                        showLabel={!!infoBlockInfo}
                                        onChangeClick={handleOnChangeContactInfo}
                                        showChangeLink={shouldShowChangeLink(StepsQueue.Info)}
                                    />
                                    <BookingSummaryDataBlock
                                        id="AOA-booking-summary-location-block"
                                        stepLabel={locationLabel}
                                        stepInfo={locationInfo?.storeName}
                                        showLabel={!!locationInfo}
                                        onChangeClick={handleOnLocationChangeOpenLocator}
                                        showChangeLink={shouldShowChangeLink(StepsQueue.Location)}
                                    />
                                    <BookingSummaryDataBlock
                                        id="AOA-booking-summary-services-block"
                                        stepLabel={servicesLabel}
                                        stepInfo={getSelectedServicesInfo()}
                                        showLabel={!!getSelectedServicesInfo()}
                                        onChangeClick={() => handleOnChangeRedirect(StepsQueue.Services)}
                                        showChangeLink={shouldShowChangeLink(StepsQueue.Services)}
                                    />
                                    <BookingSummaryDataBlock
                                        id="AOA-booking-summary-additional-comments"
                                        stepLabel={additionalInformation}
                                        stepInfo={additionalCommentsInRedux}
                                        showLabel={!!additionalCommentsInRedux}
                                        showChangeLink={false}
                                    />
                                    <BookingSummaryDataBlock
                                        id="AOA-booking-summary-date-time-block"
                                        className={`${PREFIX}-date__data-block__width`}
                                        stepLabel={dateTimeLabel}
                                        stepInfo={dateBlockInfo}
                                        showLabel={!!dateBlockInfo}
                                        onChangeClick={handleOnChangeDateTimeInfo}
                                        showChangeLink={shouldShowChangeLink(StepsQueue.Date)}
                                    />
                                </div>
                            </>
                        )}
                        <div className={`${PREFIX}-cta-buttons__container`} />
                    </div>
                )}
            </div>
        </div>
    )
}, componentType.bookingSummary)

export default BookingSummary

BookingSummary.propTypes = {
    title: PropTypes.string.isRequired,
    vehicleLabel: PropTypes.string.isRequired,
    infoLabel: PropTypes.string.isRequired,
    locationLabel: PropTypes.string.isRequired,
    servicesLabel: PropTypes.string.isRequired,
    dateTimeLabel: PropTypes.string.isRequired,
    noVehicleSelectedLabel: PropTypes.string.isRequired,
    currentLocationTitle: PropTypes.string.isRequired,
}
