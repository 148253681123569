import { ProductStatus, StockItemAvailability } from '../../redux/models/cart.interface'
import { FulfillmentMethods, FulfillmentErrorType } from './ShoppingCart.type'
import { enableDestructOnUndefinedData } from '../../utils/PDP/enableDestructOnUndefinedData.utils'
import { CartErrorService } from './CartError.service'
import { GetErrorFunctionPropTypeSfl, ItemInlineErrorType } from './SaveForLater.type'
import { areAllParamsValid } from '../../utils/getFilteredCartItems'
export const SaveforLaterErrorService = (() => {
    /**
     * Frames the error options based on the product status and the info attached to that particular error.
     * @param  {string} message - array of messages used when the item is out of stock in all stores and have a product status.
     * @return {ItemInlineErrorType}
     */
    const _outOfStockConditions = (message: string): ItemInlineErrorType => {
        return { ...CartErrorService.constructErrorResponse(true, message, false, [], '', true), showMovetoCart: true }
    }
    /**
     * Checks multiple conditions and returns an error object.
     * @param {CartOrderEntries} itemData - Single Product Item Data.
     * @param {Record<string, string>} messageObject - object which holds all the error messages with respect to the product item.
     * @param {FulfillmentMethods} itemType - BOPIS or STH
     * @param {StorePickUp | ShipToHome} itemAvailabilityData - fulfillment data of the item.
     * @return {GetErrorFunctionPropTypeSfl} - returns the error based on the conditions.
     * showMovetoCart  - whether to show Move to Cart Button
     */
    const _getItemAvailabilityError = ({
        itemData,
        messageObject,
        itemType,
        itemAvailabilityData,
    }: GetErrorFunctionPropTypeSfl): ItemInlineErrorType => {
        const { dcQuantity, storeQuantity } = enableDestructOnUndefinedData(
            itemAvailabilityData,
        ) as StockItemAvailability
        const { quantity: selectedQuantity, fulfillment, productStatus, sellable } = itemData
        const checkInStock = CartErrorService.checkIsStockInAllStore(
            storeQuantity,
            dcQuantity,
            selectedQuantity,
            fulfillment?.orderableFromDC,
        )
        const inActiveStatus = [
            ProductStatus.DISCONTINUED_WHEN_OUT,
            ProductStatus.FULLY_DISCONTINUED,
            ProductStatus.SEASONALLY_DISCONTINUED,
            ProductStatus.TEMPORARY_DISCONTINUED,
        ]

        if (!sellable) {
            return {
                ...CartErrorService.constructErrorResponse(
                    true,
                    messageObject.productCannotBePurchasedOnlineSfl,
                    true,
                    [],
                    '',
                    true,
                ),
                showMovetoCart: false,
            }
        } else if (areAllParamsValid(itemType === FulfillmentMethods.STH, !fulfillment?.isEligibleToShipHome)) {
            return {
                ...CartErrorService.constructErrorResponse(
                    true,
                    messageObject.deliverySTHErrorSfl,
                    true,
                    [],
                    FulfillmentErrorType.FULFILLMENT_STH_ERROR,
                    true,
                ),
                showMovetoCart: true,
            }
        } else if (checkInStock._discontinuedAndOOS() && inActiveStatus.includes(productStatus)) {
            return {
                ...CartErrorService.discontinuedOOSErrorResponse(
                    false,
                    messageObject?.discontinuedInStorePickUpMsgSfl,
                    messageObject?.tempDiscontinuedInStorePickUpMsgSfl,
                    itemData.productStatus,
                ),
                showMovetoCart: true,
            }
        } else if (productStatus === ProductStatus.ACTIVE && !storeQuantity && !checkInStock._isStockInDC()) {
            return { ..._outOfStockConditions(messageObject?.outOfStockInStorePickUpMsgSfl), showMovetoCart: true }
        } else {
            return {
                showInlineError: false,
                errorMessage: '',
                grayOutImage: false,
                optionsToBeDisplayed: [],
                fulfillmentDeliverError: '',
                hardStopError: false,
                showMovetoCart: true,
            }
        }
    }

    return {
        getError: _getItemAvailabilityError,
    }
})()
