import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { PREFIX, REF_URL_KEY } from '../../config'
import { Switch, Tooltip, Icon, libUtils, pushUrlHistory } from '@nl/lib'
import { StickyFilterProps } from './StickyFilter.type'
import ButtonToggleWrapper from '../GridListToggle'
import FacetHorizontalBar from './FacetHorizontalBar'
import HorizontalPillBar from './HorizontalPillBar/HorizontalPillBar'
import { filterActionAnalytics } from '../../analytics/components/facetAnalytics'
import { filterCleared, filterEnabled, hotSaleUrl, premiumFilter } from './Facets.constant'
import { usePageAnalytics } from '../../analytics/hooks/usePageAnalytics'
import checkNestedProps from '../../utils/checkNestedProps'
import { VehicleFitType } from '../../globalConstants'
import { commonContentSelector } from '../../redux/selectors/commonContent.selectors'
import { useIsMobile } from '../../hooks/useIsMobile.hook'
import { productWheelTypeSelector } from '../../redux/selectors/categoryIdData.selectors'
import { isWheelOrTirePDP } from '../Vehicles/Vehicle.helper'
import sessionStorageService from '../../utils/sessionStorageService'

/**
 * StickyFilters component
 * @param {StickyFilterProps} props
 * @return {JSX.Element} returns StickyFilter
 */
const StickyFilters: React.FC<StickyFilterProps> = (props): JSX.Element => {
    const {
        sortAndFiltersMobileLabel,
        moreFiltersLabel,
        sortTitle,
        productCardListView,
        listViewLabel,
        gridViewLabel,
        showSaleItemsFilter,
        enableFiveFilterFacets,
        saleItemsLabel,
        showInStockAtMyStoreFilter,
        inStockAtMyStoreTitle,
        inStockAtMyStoreTooltip,
        inStockAtMyStoreTooltipHeader,
        saleItem,
        inStockAtMyStore,
        toggleDispatch,
        resultCount,
        resultsLabel,
        clearAllFilterLabel,
        a11yRemoveFilter,
        a11yClearFiltersLabel,
        a11yAppliedFiltersLabel,
        allCategoriesLabel,
        relatedCategoriesLabel,
        a11yTooltipIcon,
        isFitmentRequired,
        defaultView,
        isAutoPartPlp,
        vehicleFitType,
        enableFilterFacetsEnhancement,
        maxPriceErrorMessage,
        maxPriceLabel,
        minPriceErrorMessage,
        minPriceLabel,
        showLessLabel,
        showMoreLabel,
        showMoreThreshold,
        filterByLabel,
        allFiltersMobileLabel,
    } = props
    const [visibleInfo, setVisibleInfo] = useState(false)
    const buttonInfoRef = useRef(null)
    const results = `${resultCount}${' '}${resultsLabel}`
    const isMobile = useIsMobile()
    const productWheelType = useSelector(productWheelTypeSelector)
    const isTireOrWheelPLP = isWheelOrTirePDP(productWheelType)

    /**
     * function to toggle on/off for in-stock at my store or sales items
     * @param {string} queryParam
     * @param {boolean} isSaleItemsToggle
     */
    const toggleHandler = (queryParam: string, isSaleItemsToggle: boolean): void => {
        sessionStorageService.setItem(REF_URL_KEY, window.location.href)
        pushUrlHistory(queryParam)
        toggleDispatch()
        const saleOrInStoreSelected = isSaleItemsToggle ? saleItem?.selected : inStockAtMyStore?.selected
        const saleOrInStoreLabel = isSaleItemsToggle ? saleItemsLabel : inStockAtMyStoreTitle
        filterActionAnalytics(premiumFilter, saleOrInStoreLabel, !saleOrInStoreSelected ? filterEnabled : filterCleared)
    }

    /**
     * function to render sales item component
     * @return {JSX.Element}
     */
    const saleItemComponent = (): JSX.Element | null => {
        return saleItem && showSaleItemsFilter ? (
            <div className={`${PREFIX}-filters__on-sale-toggle`}>
                <Switch
                    handleInputChange={e => {
                        e.preventDefault()
                        toggleHandler((saleItem.selected ? saleItem.clearUrl : saleItem.url) as string, true)
                    }}
                    label={saleItemsLabel}
                    checked={saleItem.selected}
                />
            </div>
        ) : null
    }

    const { commonContentAvailable } = useSelector(commonContentSelector)
    const renderTooltip = () => {
        return (
            <div>
                {!!visibleInfo && (
                    <Tooltip
                        visibility={visibleInfo}
                        setVisibility={setVisibleInfo}
                        iconID="ct-close"
                        headerText={inStockAtMyStoreTooltipHeader}
                        bodyText={inStockAtMyStoreTooltip}
                        coords={buttonInfoRef.current as unknown as HTMLElement}
                        a11yCloseIconLabel={
                            checkNestedProps(commonContentAvailable, 'accessibility', 'a11yCloseIconLabel') as string
                        }
                    />
                )}
            </div>
        )
    }
    const handleVisibility = () => {
        setVisibleInfo(previousState => !previousState)
    }

    const checkTooltipRender = () => {
        if (
            inStockAtMyStoreTooltip &&
            inStockAtMyStoreTooltipHeader &&
            inStockAtMyStore &&
            inStockAtMyStore.tooltip !== null
        ) {
            return (
                <>
                    <button
                        aria-label={a11yTooltipIcon}
                        ref={buttonInfoRef}
                        className={`${PREFIX}-triangle-account-content__info-button`}
                        onClick={handleVisibility}
                        data-testid="triange-tooltip-button">
                        <span className={visibleInfo ? `${PREFIX}-triangle-account-content__info-button--active` : ''}>
                            <Icon type="ct-information" size="md" />
                        </span>
                    </button>
                    {renderTooltip()}
                </>
            )
        }
    }

    /**
     * function to render in-stock at my store component
     * @return {JSX.Element}
     */
    const renderToggleStore = () => {
        if (showInStockAtMyStoreFilter && inStockAtMyStore) {
            return (
                <div className={`${PREFIX}-filters__in-store-toggle`}>
                    <Switch
                        handleInputChange={e => {
                            e.preventDefault()
                            toggleHandler(
                                (inStockAtMyStore.selected
                                    ? inStockAtMyStore.clearUrl
                                    : inStockAtMyStore.url) as string,
                                false,
                            )
                        }}
                        label={inStockAtMyStoreTitle}
                        checked={inStockAtMyStore.selected}
                    />
                    {checkTooltipRender()}
                </div>
            )
        }
    }

    usePageAnalytics()

    /**
     * function to render toggle button
     * @return {JSX.Element}
     */
    const isToggleRequired = (): boolean => {
        return isFitmentRequired ? (isAutoPartPlp ? vehicleFitType !== VehicleFitType.Fit : isTireOrWheelPLP) : true
    }

    const filterPanelRef = useRef(null)
    const filterPanelPlaceHolderRef = useRef(null)

    /**
     * function to check page type and hide sale toggle
     * @return {boolean}
     */
    const isHotSalePage = (): boolean => {
        const language = libUtils.getLanguage()
        return window.location.href.includes(hotSaleUrl[language as keyof typeof hotSaleUrl])
    }

    return (
        <>
            <div
                ref={filterPanelRef}
                data-testid="sticky-filters-component"
                id={`${PREFIX}-filters`}
                className={`${PREFIX}-filters ${PREFIX}-container`}>
                <FacetHorizontalBar
                    moreFiltersLabel={moreFiltersLabel}
                    sortAndFiltersMobileLabel={sortAndFiltersMobileLabel}
                    allCategoriesLabel={allCategoriesLabel}
                    relatedCategoriesLabel={relatedCategoriesLabel}
                    sortTitle={sortTitle}
                    enableFiveFilterFacets={enableFiveFilterFacets}
                    allFiltersMobileLabel={allFiltersMobileLabel}
                    enableFilterFacetsEnhancement={enableFilterFacetsEnhancement}
                    maxPriceErrorMessage={maxPriceErrorMessage}
                    maxPriceLabel={maxPriceLabel}
                    minPriceErrorMessage={minPriceErrorMessage}
                    minPriceLabel={minPriceLabel}
                    showLessLabel={showLessLabel}
                    showMoreLabel={showMoreLabel}
                    showMoreThreshold={showMoreThreshold}
                    filterByLabel={filterByLabel}
                    clearAllFilterLabel={clearAllFilterLabel}
                    filterPanelRef={filterPanelRef}
                />
                <HorizontalPillBar
                    clearAllFilterLabel={clearAllFilterLabel}
                    toggleDispatch={toggleDispatch}
                    a11yRemoveFilter={a11yRemoveFilter}
                    a11yClearFiltersLabel={a11yClearFiltersLabel}
                    a11yAppliedFiltersLabel={a11yAppliedFiltersLabel}
                />
                <div className={`${PREFIX}-filters__sorting`}>
                    <div
                        className={`${PREFIX}-col-lg-6 ${PREFIX}-col-md-6 ${PREFIX}-col-sm-8 ${PREFIX}-col-xs-6 ${PREFIX}-filters__left-section`}>
                        {!isMobile && <span className={`${PREFIX}-filters__results`}>{results}</span>}
                        {renderToggleStore()}
                        {!isHotSalePage() && saleItemComponent()}
                        {isMobile && <span className={`${PREFIX}-filters__results`}>{results}</span>}
                    </div>
                    {isToggleRequired() && (
                        <div
                            className={`${PREFIX}-col-lg-6 ${PREFIX}-col-md-6 ${PREFIX}-col-sm-4 ${PREFIX}-filters__right-section`}>
                            <ButtonToggleWrapper
                                productCardListView={productCardListView}
                                listViewLabel={listViewLabel}
                                gridViewLabel={gridViewLabel}
                                isFitmentRequired={isFitmentRequired}
                                defaultView={defaultView}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div ref={filterPanelPlaceHolderRef} className={`${PREFIX}-filters-placeholder`}></div>
        </>
    )
}

StickyFilters.propTypes = {
    moreFiltersLabel: PropTypes.string.isRequired,
    sortAndFiltersMobileLabel: PropTypes.string.isRequired,
    sortTitle: PropTypes.string.isRequired,
    saleItemsLabel: PropTypes.string.isRequired,
    showSaleItemsFilter: PropTypes.bool.isRequired,
    productCardListView: PropTypes.bool.isRequired,
    listViewLabel: PropTypes.string.isRequired,
    gridViewLabel: PropTypes.string.isRequired,
    showInStockAtMyStoreFilter: PropTypes.bool.isRequired,
    inStockAtMyStoreTitle: PropTypes.string.isRequired,
    inStockAtMyStoreTooltip: PropTypes.string,
    inStockAtMyStoreTooltipHeader: PropTypes.string,
    toggleDispatch: PropTypes.func.isRequired,
    saleItem: PropTypes.shape({
        label: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        selected: PropTypes.bool.isRequired,
        tooltip: PropTypes.string,
        clearUrl: PropTypes.string,
    }),
    inStockAtMyStore: PropTypes.shape({
        label: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        selected: PropTypes.bool.isRequired,
        tooltip: PropTypes.string,
        clearUrl: PropTypes.string,
    }),
    resultCount: PropTypes.number.isRequired,
    resultsLabel: PropTypes.string.isRequired,
    clearAllFilterLabel: PropTypes.string.isRequired,
    a11yRemoveFilter: PropTypes.string.isRequired,
    allCategoriesLabel: PropTypes.string.isRequired,
    relatedCategoriesLabel: PropTypes.string.isRequired,
    a11yTooltipIcon: PropTypes.string,
    isFitmentRequired: PropTypes.bool,
    defaultView: PropTypes.string,
    isAutoPartPlp: PropTypes.bool,
    vehicleFitType: PropTypes.string,
    a11yClearFiltersLabel: PropTypes.string,
    a11yAppliedFiltersLabel: PropTypes.string,
    enableFilterFacetsEnhancement: PropTypes.string,
    allFiltersMobileLabel: PropTypes.string.isRequired,
}

export default StickyFilters
