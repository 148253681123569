import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { FetchOffersPayload } from '../../redux/models/offers.interface'
import { fetchCustomerOffers } from '../../redux/actions'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { offerConstants } from '../Accounts/Rewards/Offers/OffersCard.constants'

import { LoyaltyOffersWrapperProps } from './LoyaltyOffers.type'
import LoyaltyOffers from './LoyaltyOffers'
import {
    isPanelWasDismissed,
    getPanelAppearancesCount,
    dismissLoyaltyOffersPanel,
    isPanelWasExpanded,
    isPanelBlockedForCurrentPage,
    getCookie,
} from './LoyaltyOffers.helper'
import { isArrayNotEmpty, libUtils, magicNumber } from '@nl/lib'
import { userProfileDataSelector } from '../../redux/selectors/userProfile.selectors'
import { useMobileLayoutState } from '../../hooks/layout.hook'
import { offersDataSelector } from '../../redux/selectors/offers.selectors'

const LoyaltyOffersWrapper: React.FC<LoyaltyOffersWrapperProps> = ({ ...props }) => {
    const {
        attemptsToDisplayWithoutInteraction,
        blockListPagePaths,
        enableDesktopTablet,
        enableMobile,
        offerBannerList,
    } = props

    const userProfileData = useSelector(userProfileDataSelector)
    const isLoggedIn = checkDataLength(userProfileData)
    const hasLoyaltyAccount = checkDataLength(userProfileData?.loyalty)
    const [isMobileLayout] = useMobileLayoutState() as [boolean]

    const offersData = useSelector(offersDataSelector)
    const hasOffers = Boolean(offersData?.offers?.length)
    const [isPanelDismissed, setIsPanelDismissed] = useState<boolean>(isPanelWasDismissed())

    const userCanSeeThePanel = isLoggedIn && hasLoyaltyAccount && hasOffers
    const isNotIncremented = useRef<boolean>(true)

    const dispatch = useDispatch()

    const loadOffers = useCallback(() => {
        const payload = {
            lang: libUtils.getLanguage(),
            resultsPerPage: offerConstants.maxRewardsCount.toString(),
            pageNumber: offerConstants.currentPageInitialValue.toString(),
        } as FetchOffersPayload
        dispatch(fetchCustomerOffers(payload))
    }, [dispatch])

    const isBlockListPage = isPanelBlockedForCurrentPage(blockListPagePaths || [])

    useEffect(() => {
        if (isLoggedIn && hasLoyaltyAccount && !isBlockListPage) {
            loadOffers()
        }
    }, [isLoggedIn, hasLoyaltyAccount, loadOffers, isBlockListPage])

    const canIncrementAppearancesCount = (): boolean => {
        return !isPanelWasExpanded() && isNotIncremented.current && userCanSeeThePanel && !isBlockListPage
    }

    if (canIncrementAppearancesCount()) {
        isNotIncremented.current = false
    }

    const hasAttemptToDisplay = !isPanelDismissed && getPanelAppearancesCount() <= attemptsToDisplayWithoutInteraction

    const isDisplayComponent = (): boolean => {
        const displayLoyaltyOffers = (isMobileLayout && enableMobile) || (!isMobileLayout && enableDesktopTablet)
        return (
            userCanSeeThePanel &&
            displayLoyaltyOffers &&
            hasAttemptToDisplay &&
            !isBlockListPage &&
            !getCookie('isLoyaltyOffersDismissed') &&
            isArrayNotEmpty(offerBannerList)
        )
    }

    const dismissPanel = () => {
        setIsPanelDismissed(true)
        const getExpiryTime = (date: Date, dayOfWeek: number): Date => {
            const resultDate = new Date(date.getTime())
            resultDate.setDate(
                date.getDate() +
                    ((magicNumber.SEVEN + dayOfWeek - date.getDay()) % magicNumber.SEVEN || magicNumber.SEVEN),
            )
            resultDate.setUTCDate(resultDate.getDate())
            resultDate.setHours(magicNumber.SEVEN, 0, 0, 0)
            return resultDate
        }
        const dateToExpireLoyaltyCookie = getExpiryTime(new Date(), magicNumber.FIVE)
        dismissLoyaltyOffersPanel('isLoyaltyOffersDismissed', dateToExpireLoyaltyCookie.toString())
    }

    return isDisplayComponent() ? (
        <LoyaltyOffers {...props} loadOffers={loadOffers} onPanelDismiss={dismissPanel} />
    ) : null
}

LoyaltyOffersWrapper.propTypes = {
    attemptsToDisplayWithoutInteraction: PropTypes.number.isRequired,
    blockListPagePaths: PropTypes.arrayOf(PropTypes.string),
}

export default LoyaltyOffersWrapper
