import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { getFormattedPrice, replaceStrWithDynamicVal, getLanguage } from '../../utils'
import { PREFIX, previousElementName } from '../config'
import { StoreDetailsProps } from './StoreDetails.types'
import Icon from '../Icon'
import Tooltip from '../Tooltip'
import ReactModal from '../ReactModal'
import { addModalAttribute, modalAccessibilityHandler } from '../../utils/modalAccessibility'
import StoreTooltip from '../StoreTooltip'

/**
 * StoreDetails component
 * @param {StoreDetailsProps} props
 * @return {JSX.Element} returns Icon,Tooltip,StoreTooltip and ReactModal
 */
export const StoreDetails: React.FC<StoreDetailsProps> = ({ ...props }) => {
    const {
        pickAtStoreTitle,
        freePickUpOrderThreshold,
        freePickUpOverLabel,
        servicesTitle,
        shipToHomeTitle,
        changeCTALabel,
        onlineOrdering,
        onlineOrdersNotAcceptedMsg,
        isShipToHome,
        isFreePickUpAtStore,
        storeDetailsCTALabel,
        curbsidePickupMessage,
        curbsidePickupLearnMoreLabel,
        storeData,
        curbsideText,
        path,
        closeLabel,
        openLabel,
        opensLabel,
        closesLabel,
        isStoreEligibleForCurbside,
        onChangeAddress,
        openModal,
        a11yCloseIconLabel,
        a11yStoreDetailsInformation,
        a11yChangePickupStoreLabel,
        popUpTitleId,
        a11yTooltipIcon,
        isExpressDelivery,
        expressDeliveryLabel,
        deliveryPostalCode = '',
    } = props
    const { address, displayName } = storeData || {}
    const buttonRef = useRef()
    const [visibility, setVisibility] = useState(false)
    const [isCurbSideModalOpen, setIsCurbSideModalOpen] = useState(false)

    const componentClassName = `${PREFIX}-curbside-modal`
    const expressLabelClassName = isExpressDelivery ? `${PREFIX}-shopping-cart__express-label` : ''
    const storeClass = `${PREFIX}-shopping-cart__store-details`

    const storeAddressClass = isShipToHome ? `${storeClass} ${storeClass}__address` : `${storeClass}`
    const isBopisCheck = isFreePickUpAtStore && isStoreEligibleForCurbside
    const modalClassName = `${PREFIX}-previous-element`
    const previousElement: HTMLElement | null = document.querySelector(`button[${previousElementName}]`)

    const createPickUpAtStoreTitle = (): string => {
        if (freePickUpOrderThreshold) {
            const formattedOrderThreshold = getFormattedPrice(freePickUpOrderThreshold, getLanguage())
            return replaceStrWithDynamicVal(freePickUpOverLabel, formattedOrderThreshold)
        }
        return pickAtStoreTitle
    }

    const shipmentTitle = (): string => {
        let title = ''
        if (isShipToHome) {
            title = shipToHomeTitle
        } else if (isFreePickUpAtStore) {
            title = createPickUpAtStoreTitle()
        } else if (isExpressDelivery) {
            title = expressDeliveryLabel
        } else {
            title = servicesTitle
        }
        return title
    }

    // This function returns the CurbsideText value
    const isCurbsideText = (): JSX.Element => {
        return (
            isBopisCheck && (
                <div className={`${storeClass}__curbside-text`}>
                    <span data-testid="curbside-pickup" id="curbside-pickup">
                        {curbsidePickupMessage}
                    </span>
                    <button
                        className={`${storeClass}__curbside-text--learn-more-btn`}
                        aria-describedby={'curbside-pickup'}
                        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                            addModalAttribute(e)
                            setIsCurbSideModalOpen(!isCurbSideModalOpen)
                        }}
                        type="button"
                        data-qm-allow="true">
                        <span className={`${storeClass}__curbside-text--learn-more-text`}>
                            {curbsidePickupLearnMoreLabel}
                        </span>
                    </button>
                </div>
            )
        )
    }

    // This function returns the store address text
    const isStoreAddress = (): JSX.Element => {
        /**
         * function to redirect to store details page in case of FPS and open postal code modal in case of STH
         * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} e - button click event.
         */
        const onChangeButtonAction = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
            if (isShipToHome) {
                onChangeAddress(true)
            } else {
                addModalAttribute(e)
                openModal()
            }
        }
        return (
            <>
                {renderStoreNameAddress(isShipToHome ? address?.postalCode : displayName)}
                {isFreePickUpAtStore && (
                    <button
                        className={`${storeClass}__store-icon`}
                        type="button"
                        data-qm-allow="true"
                        ref={buttonRef}
                        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                            addModalAttribute(e)
                            setVisibility(!visibility)
                        }}
                        aria-label={a11yTooltipIcon}
                        aria-describedby={`${storeClass}__store-name-address-id`}>
                        <Icon size="md" title={`${a11yStoreDetailsInformation}`} type="ct-information-details"></Icon>
                    </button>
                )}
                {(isFreePickUpAtStore || (isShipToHome && onlineOrdering)) && (
                    <button
                        className={`${storeClass}__change-link-btn ${storeClass}__change-link`}
                        type="button"
                        data-qm-allow="true"
                        onClick={onChangeButtonAction}
                        aria-label={a11yChangePickupStoreLabel}>
                        {changeCTALabel}
                    </button>
                )}
            </>
        )
    }

    /**
     * render the postal code or store name
     * @param {string} postalCode
     * @return {JSX.Element | null}
     */
    const renderStoreNameAddress = (postalCode: string): JSX.Element | null => {
        return postalCode ? (
            <span id={`${storeClass}__store-name-address-id`} className={`${storeClass}__store-name-address`}>
                {postalCode}
            </span>
        ) : null
    }

    /**
     * closes the learnmore popup and sets the focus to previous element
     *
     */
    const closeHandler = (): void => {
        modalAccessibilityHandler({ modalOpen: false, modalClassName })
        setIsCurbSideModalOpen(false)
        previousElement?.focus() // Highlight the initiated button
        previousElement?.removeAttribute(previousElementName) // Removing it when user close it.
    }

    return (
        <div className={`${storeAddressClass} ${expressLabelClassName}`}>
            <h3 className={`${storeClass}__store-name`}>{shipmentTitle()}</h3>
            {isExpressDelivery && renderStoreNameAddress(deliveryPostalCode)}
            {(isFreePickUpAtStore || isShipToHome) && (
                <>
                    <div className={`${storeClass}__store-address`}>{isStoreAddress()}</div>
                    {isCurbsideText()}
                </>
            )}
            {!!visibility && (
                <Tooltip
                    visibility={visibility}
                    setVisibility={setVisibility}
                    iconID="ct-close"
                    headerText={displayName}
                    a11yCloseIconLabel={a11yCloseIconLabel}
                    bodyText={
                        <StoreTooltip
                            storeData={storeData}
                            openLabel={openLabel}
                            opensLabel={opensLabel}
                            closesLabel={closesLabel}
                            closeLabel={closeLabel}
                            storeDetailsCTALabel={storeDetailsCTALabel}
                            onlineOrdering={onlineOrdering}
                            onlineOrdersNotAcceptedMsg={onlineOrdersNotAcceptedMsg}
                        />
                    }
                    coords={buttonRef.current as unknown as HTMLElement}
                />
            )}
            <ReactModal closeHandler={closeHandler} isOpen={isCurbSideModalOpen}>
                <div role="dialog" aria-labelledby={popUpTitleId} aria-modal="true" className={componentClassName}>
                    <div className={`${componentClassName}__close-container`}>
                        <button
                            className={`${componentClassName}__close-btn`}
                            onClick={closeHandler}
                            aria-label={closeLabel}
                            data-testid="curbSideModalCloseButton">
                            <Icon type="ct-close" size="lg" path={path} />
                        </button>
                    </div>
                    {curbsideText && (
                        <div
                            className={`${componentClassName}__content`}
                            dangerouslySetInnerHTML={{ __html: curbsideText }}
                        />
                    )}
                </div>
            </ReactModal>
        </div>
    )
}

StoreDetails.propTypes = {
    pickAtStoreTitle: PropTypes.string,
    freePickUpOrderThreshold: PropTypes.number,
    freePickUpOverLabel: PropTypes.string,
    servicesTitle: PropTypes.string,
    shipToHomeTitle: PropTypes.string,
    changeCTALabel: PropTypes.string,
    curbsideLearnMoreCTALabel: PropTypes.string,
    onlineOrdering: PropTypes.bool,
    onlineOrdersNotAcceptedMsg: PropTypes.string,
    isShipToHome: PropTypes.bool,
    isFreePickUpAtStore: PropTypes.bool,
    storeDetailsCTALabel: PropTypes.string,
    curbsidePickupMessage: PropTypes.string,
    curbsidePickupLearnMoreLabel: PropTypes.string,
    storeData: PropTypes.any,
    path: PropTypes.string,
    curbsideText: PropTypes.string,
    closeLabel: PropTypes.string,
    openLabel: PropTypes.string,
    opensLabel: PropTypes.string,
    closesLabel: PropTypes.string,
    isStoreEligibleForCurbside: PropTypes.bool,
    onChangeAddress: PropTypes.func,
    openModal: PropTypes.func,
    a11yCloseIconLabel: PropTypes.string,
    a11yStoreDetailsInformation: PropTypes.string,
    a11yChangePickupStoreLabel: PropTypes.string,
}

export default StoreDetails
