import React, { useEffect, useRef } from 'react'

import { PREFIX, previousElementName, MODAL_OPEN_CLASS } from '../config'
import { useClickOutsideClose } from '../Tooltip/useClickOutside'
import { OfferFlyoutType } from '../OfferCard/OfferCard.type'
import OfferCard from '../OfferCard'
import { modalAccessibilityHandler } from '../../utils/modalAccessibility'
import FlyoutModalComponent from '../FlyoutModalComponent'
import { magicNumber } from '../../utils'

const OfferFlyout: React.FC<OfferFlyoutType> = ({ ...props }) => {
    const { isOpen, setIsFlyoutOpen, offer, activateOffer, status, columns, modalLabel, setIsActivatedOfferClick } =
        props
    const contentRef = useRef(null)
    const modalClassName = `${PREFIX}-previous-element`
    const previousElement: HTMLElement | null = document.querySelector(`button[${previousElementName}]`)

    useEffect(() => {
        if (props.isOpen) {
            document.body.classList.add(MODAL_OPEN_CLASS)
        } else {
            document.body.classList.remove(MODAL_OPEN_CLASS)
        }

        /**
         * Remove body `modalOpen Class` on Flyout unmount, is necessary for the case
         * when Flyout unmount happens by not changing `isOpen` prop to `false`.
         */
        return () => {
            document.body.classList.remove(MODAL_OPEN_CLASS)
        }
    }, [props.isOpen])

    /**
     * Function used to close the flyout
     * @param {boolean} isCloseClicked
     */
    const closeFlyout = (isCloseClicked: boolean): void => {
        modalAccessibilityHandler({ modalOpen: false, modalClassName })
        setIsFlyoutOpen(isCloseClicked)
        setIsActivatedOfferClick(false)
        if (previousElement) {
            setTimeout(() => {
                previousElement?.focus() // Highlight the initiated button
                previousElement?.removeAttribute(previousElementName)
            }, magicNumber.ZERO)
        }
    }

    // Flyout modal props.
    const flyoutModalProps = {
        isOpen,
        closeFlyout,
        isStoreSelectorModalUsed: false,
    }

    useClickOutsideClose(contentRef, () => closeFlyout(false), isOpen, true)

    return (
        <FlyoutModalComponent {...flyoutModalProps}>
            <div ref={contentRef} className={`${PREFIX}-offers-card__flyout`}>
                <OfferCard
                    key="offer_card_flyout"
                    {...props}
                    modalLabel={modalLabel}
                    offer={offer}
                    isFlyout={true}
                    activateOffer={activateOffer}
                    closeModal={closeFlyout}
                    status={status} // coming from parent component in wrapper
                    columns={columns} // coming from parent component in wrapper
                />
            </div>
        </FlyoutModalComponent>
    )
}

export default OfferFlyout
