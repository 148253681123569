import {
    SEOSchemaTypes,
    SEOSchema,
    DepartmentStoreSchema,
    ProductSchema,
    SEOProductAvailability,
    LocalBusinessSchema,
} from './Seo.helper.types'
import { MagicNumber } from '../../analytics/analytics.type'
import { isArrayNotEmpty, extractKeyValues, checkNeedUpdateWeekdayHours } from '@nl/lib'
import { BreadcrumbParentPageLink } from '../../components/Breadcrumbs/BreadcrumbsComp.type'
import { NavigationCategory } from '../../components/SecondaryNavigation/SecondaryNavigationWrapper.type'
import { schemaOrgURL, itemCondition } from '../components/Hoc/Seo.constants'
import {
    StoreWithAvailability,
    WeekDayOpeningList,
    SpecialOpeningDayList,
    StoreLocation,
} from '../../redux/models/storeDetails.interface'

import { generateGoogleMapLink } from '../../utils/generateGoogleMapLink'
import { checkDataLength } from '../../components/Accounts/Addresses/checkDataLength'
import { ProductResponseData, ProductSku, FeatureBulletsDTO } from '../../redux/models/product.interface'
import extractPCodeFromUrl from '../../utils/PDP/extractPCodeFromUrl'
import { currencyFormat } from '../../globalConstants'
import { SeoHelper } from './Seo.helper'
import { GlobalPropsHelper } from '../../analytics/helpers/globalProps'
import { IBreadcrumb } from '../../redux/models/commonContent.interface'
import { checkAndPopulateMpn } from './checkAndPopulateMpn'

const globalProps = new GlobalPropsHelper()
const { storeSchemaType } = globalProps.readDataAlternateLangDetails()

/**
 * function to prepare schema Elements/Tag for BreadCrumbList
 * @param {string} breadcrumbList
 * @param {string} breadcrumbsCommonContent
 * @return {string}
 */
export const getBreadCrumbSchema = (
    breadcrumbList: BreadcrumbParentPageLink[],
    breadcrumbsCommonContent: IBreadcrumb,
): string => {
    const schemaObj: SEOSchema = {
        '@context': schemaOrgURL,
        '@type': SEOSchemaTypes.BreadcrumbList,
        itemListElement: [],
    }
    const { backToSearchResults, backToResults } = breadcrumbsCommonContent
    const interActiveBreadCrumbLinks = breadcrumbList
        .slice(MagicNumber.ZERO, MagicNumber.MINUS_ONE) // exclude current page and back page link
        .filter((item: BreadcrumbParentPageLink) => item.name !== backToSearchResults && item.name !== backToResults)
    isArrayNotEmpty(interActiveBreadCrumbLinks) &&
        interActiveBreadCrumbLinks.map((breadCrumbLink: BreadcrumbParentPageLink, index: number) =>
            schemaObj.itemListElement?.push({
                '@type': SEOSchemaTypes.ListItem,
                name: breadCrumbLink.name,
                position: (index + MagicNumber.ONE).toString(), // Position starts from 1
                item: `${window.location.origin}${breadCrumbLink.link as string}`,
            }),
        )
    return isArrayNotEmpty(schemaObj.itemListElement) && JSON.stringify(schemaObj)
}

/**
 * function to prepare schema Elements/Tag for Secondary Navigation
 * @param {string} categoriesList
 * @return {string}
 */
export const getSiteNavigationSchema = (categoriesList: NavigationCategory[]): string => {
    const schemaObj: SEOSchema = {
        '@context': schemaOrgURL,
        '@type': SEOSchemaTypes.ItemList,
        itemListElement: [],
    }

    isArrayNotEmpty(categoriesList) &&
        categoriesList.map((category: NavigationCategory, index: number) =>
            schemaObj.itemListElement?.push({
                '@type': SEOSchemaTypes.SiteNavigationElement,
                name: category.name,
                position: (index + MagicNumber.ONE).toString(), // Position starts from 1
                url: `${window.location.origin}${category.url as string}`,
            }),
        )

    return isArrayNotEmpty(schemaObj.itemListElement) && JSON.stringify(schemaObj)
}

/**
 * function to prepare schema Elements/Tag for Department Store
 * @param {StoreWithAvailability} storeDetails
 * @param {string} logoURL
 * @return {string}
 */
export const getDepartmentStoreSchema = (storeDetails: StoreWithAvailability, logoURL: string): string | undefined => {
    const [
        [latitude, longitude],
        [line1, line2, town, phone, region, country, postalCode],
        [weekDayOpeningList, specialDayOpeningList],
    ] = extractKeyValues(storeDetails, [
        { geoPoint: ['latitude', 'longitude'] },
        { address: ['line1', 'line2', 'town', 'phone', 'region', 'country', 'postalCode'] },
        { openingHours: ['weekDayOpeningList', 'specialDayOpeningList'] },
    ])

    if (!checkDataLength(storeDetails)) {
        return
    }

    const redirectLink = generateGoogleMapLink(latitude, longitude)
    const schemaObj: DepartmentStoreSchema = {
        '@context': schemaOrgURL,
        '@type': storeSchemaType as string,
        name: storeDetails.displayName,
        url: storeDetails.url,
        hasMap: redirectLink,
        geo: {
            '@type': SEOSchemaTypes.GeoCoordinates,
            latitude: latitude as number,
            longitude: longitude as number,
        },
        openingHoursSpecification: [],
        address: {
            '@type': SEOSchemaTypes.PostalAddress,
            streetAddress: `${line1 as string} ${line2 as string}`,
            addressLocality: town as string,
            addressRegion: (region as StoreLocation)?.name,
            postalCode: postalCode as string,
            addressCountry: (country as StoreLocation)?.name,
        },
        telephone: phone as string,
        image: logoURL,
    }

    isArrayNotEmpty(weekDayOpeningList) &&
        (weekDayOpeningList as WeekDayOpeningList[]).map((weekDayObj: WeekDayOpeningList) => {
            const weekDayObjUpdate = checkNeedUpdateWeekdayHours(storeDetails, weekDayObj) as WeekDayOpeningList
            return schemaObj.openingHoursSpecification?.push({
                '@type': SEOSchemaTypes.OpeningHoursSpecification,
                dayOfWeek: weekDayObjUpdate?.weekDay,
                opens: !weekDayObjUpdate?.closed
                    ? `${weekDayObjUpdate?.openingTime?.hour}:${weekDayObjUpdate?.openingTime?.minute}`
                    : '00:00',
                closes: !weekDayObjUpdate?.closed
                    ? `${weekDayObjUpdate?.closingTime?.hour}:${weekDayObjUpdate?.closingTime?.minute}`
                    : '00:00',
            })
        })

    isArrayNotEmpty(specialDayOpeningList) &&
        (specialDayOpeningList as SpecialOpeningDayList[]).map((specialDayObj: SpecialOpeningDayList) =>
            schemaObj.openingHoursSpecification?.push({
                '@type': SEOSchemaTypes.OpeningHoursSpecification,
                validFrom: specialDayObj?.formattedDate,
                validThrough: specialDayObj?.formattedDate,
                opens: `${specialDayObj?.openingTime?.hour}:${specialDayObj?.openingTime?.minute}`,
                closes: `${specialDayObj?.closingTime?.hour}:${specialDayObj?.closingTime?.minute}`,
            }),
        )

    return JSON.stringify(schemaObj)
}

/**
 *function to prepare schema Element/Tag for LocalBusiness Store
 * @param {StoreWithAvailability} storeDetails
 * @param {string} logoURL
 * @return {string}
 */
export const getLocalBusinessSchema = (storeDetails: StoreWithAvailability, logoURL: string): string | undefined => {
    const [[line1, line2, town, phone, region, country, postalCode]] = extractKeyValues(storeDetails, [
        { address: ['line1', 'line2', 'town', 'phone', 'region', 'country', 'postalCode'] },
    ])

    if (!checkDataLength(storeDetails)) {
        return
    }

    const schemaObj: LocalBusinessSchema = {
        '@context': schemaOrgURL,
        '@type': SEOSchemaTypes.LocalBusiness,
        name: SeoHelper.getSiteBannerTitle(),
        address: {
            '@type': SEOSchemaTypes.PostalAddress,
            postalCode: postalCode as string,
            streetAddress: `${line1 as string} ${line2 as string}`,
            addressCountry: (country as StoreLocation)?.name,
            addressRegion: (region as StoreLocation)?.name,
            addressLocality: town as string,
        },
        image: logoURL,
        telephone: phone as string,
    }
    return JSON.stringify(schemaObj)
}

/**
 * function to fetch sku code for product
 * @param {ProductSku[]} skus
 * @return {string}
 */
export const getSkuCode = (skus: ProductSku[]): string => {
    return isArrayNotEmpty(skus) ? (skus.length > MagicNumber.ONE ? extractPCodeFromUrl()?.skuCode : skus[0]?.code) : ''
}

/**
 * function to prepare schema Elements/Tag for Product
 * @param {ProductResponseData} productDetails
 * @param {ProductSku} productSkuData
 * @param {string} storeId
 * @param {boolean} isOutOfStock
 * @param {string} defaultImage
 * @param {boolean} enableMPNForAll
 * @param {boolean} enableMPNForAutomotive
 * @return {string}
 */
export const getProductSchema = (
    productDetails: ProductResponseData,
    productSkuData: ProductSku | null,
    storeId: string,
    isOutOfStock: boolean,
    defaultImage: string,
    enableMPNForAll = false,
    enableMPNForAutomotive = false,
): string => {
    const { name, longDescription, brand, images, featureBullets, skus } = productDetails
    const skuCode = getSkuCode(skus)
    const primaryImageURL = isArrayNotEmpty(images) && images[0]?.url
    const concatinatedFeatures = []
    concatinatedFeatures.push(
        featureBullets?.map((featureDescription: FeatureBulletsDTO) => featureDescription.description),
    )
    const priceWithFeeValue = (productSkuData?.currentPrice?.value || 0) + (productSkuData?.feeValue || 0) // adding price with fee value for seo schema

    const schemaObj: ProductSchema = {
        '@context': schemaOrgURL,
        '@id': SeoHelper.canonicalUrlData(productDetails, false),
        '@type': SEOSchemaTypes.Product,
        name: name,
        description: longDescription || concatinatedFeatures.join(), // either description or feature bullets
        brand: {
            '@type': SEOSchemaTypes.Brand,
            name: brand?.label,
        },
        image: primaryImageURL || defaultImage,
    }

    checkAndPopulateMpn(schemaObj, productDetails, enableMPNForAll, enableMPNForAutomotive)

    if (Boolean(Number(skuCode)) && storeId) {
        // if sku page  is loaded with store id
        schemaObj.offers = {
            '@type': SEOSchemaTypes.Offer,
            price: priceWithFeeValue.toString(),
            priceValidUntil: productSkuData?.priceValidUntil,
            priceCurrency: currencyFormat,
            itemCondition: itemCondition,
            availability: isOutOfStock ? SEOProductAvailability.OutOfStock : SEOProductAvailability.Instock,
            availableAtOrFrom: {
                branchCode: storeId,
            },
        }
        schemaObj.sku = skuCode
    }

    try {
        return JSON.stringify(schemaObj)
    } catch (e) {
        return ''
    }
}
