import { YesNoNotApplicable } from '../analytics/providers/provider.type'
import { CartOrderEntries } from '../redux/models/cart.interface'

/**
 * This function is used to determine the value for guaranteedFitment key
 * It checks for vehicleInformation and fitmentTypeCode and determines if a product is guaranteed to fit or not
 * @param {CartOrderEntries} entry - represents added cart entries
 * @return {string} returns value for guaranteedFitment key
 */

export const checkIsGuaranteedFitment = (entry: CartOrderEntries): string => {
    const { vehicleInformation, fitmentTypeCode } = entry
    if (vehicleInformation && fitmentTypeCode !== null) {
        return 'true'
    } else if (vehicleInformation === null && fitmentTypeCode !== null) {
        return 'false'
    } else {
        return YesNoNotApplicable.notApplicable
    }
}
