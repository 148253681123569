import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
    getPreselectedVehicleMake,
    getPreselectedVehicleModel,
    getPreselectedVehicleYear,
} from '../../redux/selectors/preselectedVehicle.selectors'
import { BreadcrumbParentPageLink } from './BreadcrumbsComp.type'
import { MagicNumber } from '../../analytics/analytics.type'
import { htmlSuffix, pathSeparator } from './Breadcrumb.constant'

export const useVehiclePreselectedValuesBreadcrumbLinks = (): BreadcrumbParentPageLink[] => {
    const make = useSelector(getPreselectedVehicleMake)
    const model = useSelector(getPreselectedVehicleModel)
    const year = useSelector(getPreselectedVehicleYear)

    const path = window.location.pathname

    return useMemo(() => {
        const [linkWithoutHtml] = path.split(htmlSuffix)
        const preselectedValues = [make, model, year].filter(Boolean)

        return preselectedValues.map<BreadcrumbParentPageLink>((preselectedValue, index) => {
            const valuesToCutFromURLCount = preselectedValues.length - index - MagicNumber.ONE
            const linkParts = linkWithoutHtml.split(pathSeparator)
            const link = linkParts.slice(0, linkParts.length - valuesToCutFromURLCount).join(pathSeparator) + htmlSuffix

            return { name: preselectedValue, link }
        })
    }, [make, model, year, path])
}

export default useVehiclePreselectedValuesBreadcrumbLinks
