import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { PREFIX, INIT_WARN_POPUP } from '../../../config'
import checkNestedProps from '../../../utils/checkNestedProps'
import { TriangleRewardsProps } from './TriangleRewards.type'
import { commonContentSelector } from '../../../redux/selectors/commonContent.selectors'
import { userProfileDataSelector } from '../../../redux/selectors/userProfile.selectors'

/**
 * Triangle rewards section for cart/wishlist summary
 *
 * @return {JSX.Element}
 */
const TriangleRewards: React.FC<TriangleRewardsProps> = ({ ...props }) => {
    const {
        memberMsgUnauthRichtext,
        earningMsgNoLinkedRichText,
        earningMsgAuthRichText,
        memberMsgAuthRichText,
        tRewardsTitle,
    } = props
    const userProfileData = useSelector(userProfileDataSelector)
    const { commonContentAvailable } = useSelector(commonContentSelector)
    const isLoggedIn = userProfileData ? Boolean(Object.keys(userProfileData).length) : false
    const isLoyalAccountLinked = !!userProfileData?.loyalty?.cardNumber
    const earningMessageClass = `${PREFIX}-triangle-rewards__msg--earning`
    const triangleRewardsIcon = checkNestedProps(
        commonContentAvailable,
        'accountDashboard',
        'triangleRewardsIcon',
    ) as string

    /**
     * function to dangerously set rich text
     * @param {string} text heading/title
     * @param {string} className class applied to rich text
     * @return {JSX.Element}
     */
    const setHTML = (text: string, className?: string): JSX.Element => {
        window.postMessage(INIT_WARN_POPUP, window.origin)
        const modifierClass = className || ''
        return (
            <div
                dangerouslySetInnerHTML={{ __html: text }}
                className={`${PREFIX}-triangle-rewards__msg ${modifierClass}`}
            />
        )
    }

    return (
        <div className={`${PREFIX}-triangle-rewards`}>
            <div className={`${PREFIX}-triangle-rewards__container`}>
                <img
                    className={`${PREFIX}-triangle-rewards__logo`}
                    src={triangleRewardsIcon}
                    alt=""
                    aria-hidden="true"
                />
                <h3 className={`${PREFIX}-triangle-rewards__label`}>{tRewardsTitle}</h3>
            </div>
            {isLoggedIn ? (
                <div className={`${PREFIX}-triangle-rewards__details`}>
                    {!isLoyalAccountLinked ? (
                        <>
                            {setHTML(earningMsgNoLinkedRichText, earningMessageClass)}
                            {setHTML(memberMsgAuthRichText)}
                        </>
                    ) : (
                        setHTML(earningMsgAuthRichText)
                    )}
                </div>
            ) : (
                <div className={`${PREFIX}-triangle-rewards__details`}>
                    {setHTML(earningMsgNoLinkedRichText, earningMessageClass)}
                    {setHTML(memberMsgUnauthRichtext)}
                </div>
            )}
        </div>
    )
}

TriangleRewards.propTypes = {
    memberMsgUnauthRichtext: PropTypes.string.isRequired,
    earningMsgNoLinkedRichText: PropTypes.string.isRequired,
    earningMsgAuthRichText: PropTypes.string.isRequired,
    memberMsgAuthRichText: PropTypes.string.isRequired,
    tRewardsTitle: PropTypes.string.isRequired,
}

export default TriangleRewards
