/**
 ** Interface for MediaGallery Component
 * @interface
 */
export interface MediaGalleryProps {
    thumbnailImageCount: number
    fullscreenEnable: boolean
    enablePDPZoomEnhancement: boolean
    watchFullScreenLabel?: string
    enableFasterPdpLogic?: boolean
    mobileStaticImageSize: number
    desktopStaticImageSize: number
}

/**
 ** Interface for ProductImages
 * @interface
 */
export interface ProductImages {
    type: string
    url: string
    alt?: string
    poster?: string
    tags?: string[]
}

export enum MediaType {
    lifeStyleImage = 'LIFESTYLE_IMAGE',
    galleryImage = 'GALLERY_IMAGE',
    galleryVideo = 'GALLERY_VIDEO',
    demoVideo = 'DEMO_VIDEOS',
    images360 = 'IMAGES_360',
    sizeChart = 'SIZE_CHART',
}
