import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { SecondaryNavigationMenuProps } from '../SecondaryNavigation/SecondaryNavigation.type'
import { PREFIX } from '../../../config'
import { NavigationCategory } from '../SecondaryNavigationWrapper.type'
import { Icon, isArrayNotEmpty, isEscPressed, libUtils, SkeletonComponent, useClickOutsideClose } from '@nl/lib'
import { secondaryNavPropsSelector } from '../../../redux/selectors/headerProps.selectors'
import { useDispatch, useSelector } from 'react-redux'
import { getAriaPath } from '@nl/lib/src/components/MegaNavigation/MegaNavigation.helper'
import { ariaType } from '@nl/lib/src/globalConstants/global.constant'
import MegaNavigationTile from '@nl/lib/src/components/MegaNavigation/MegaNavigationTile'
import { setSideNavigationPopupToggleState } from '../../../redux/actionCreators/popupsToggleState.actionCreators'
import { CategoryDataProps } from '@nl/lib/src/components/MegaNavigation/MegaNavigation.type'
import classnames from 'classnames'
import { Url } from '@nl/lib'
import { MagicNumber } from '../../../analytics/analytics.type'
import { getStyle } from '../../../helpers/secondaryNavigationMenu.helper'

const SecondaryNavigationMenu: React.FC<SecondaryNavigationMenuProps> = props => {
    const { categoryData, secondaryNavAriaLabel, shopAllLabel, secondaryNavMenuLabel } = props
    const secondarySideMenuBarId = 'secondarySideMenuBar'
    const rewardModalRef = useRef<HTMLUListElement>(null)
    const runMenuAccessibilityOnce = useRef(0)
    const sideMenuContainerRef = useRef(null)
    const dispatch = useDispatch()
    const [showMenu, setShowMenu] = useState(false)
    const [showL2Menu, setShowL2Menu] = useState(false)
    const [showL3Menu, setShowL3Menu] = useState(false)
    const [selectedFirstCategoryItem, setSelectedFirstCategoryItem] = useState<
        NavigationCategory | Record<string, unknown>
    >({})
    const [selectedSecondCategoryItem, setSelectedSecondCategoryItem] = useState<
        NavigationCategory | Record<string, unknown>
    >({})
    const [hideFirstSaleCategories, setHideFirstSaleCategories] = useState(false)
    const firstClick = useRef(0)
    const megaNavContainerClass = 'nl-desktop-side-menu__container'

    /**
     * function to show side navigation menu
     */
    const showSideMenu = (): void => {
        dispatch(setSideNavigationPopupToggleState(true))
        setShowMenu(true)
    }

    /**
     * function to hide side navigation menu
     */
    const hideSideMenu = (): void => {
        setShowMenu(false)
        setSelectedFirstCategoryItem({})
        setSelectedSecondCategoryItem({})
    }

    /**
     * Close the dropdown when user press esc button.
     * @param {KeyboardEvent} e - use to capture the esc keycode
     */
    const menuEscapeHandler = (e: React.KeyboardEvent<HTMLButtonElement>): void => {
        if (isEscPressed(e.key)) {
            hideSideMenu()
        }
    }

    useClickOutsideClose(sideMenuContainerRef, hideSideMenu, true, true)

    useEffect(() => {
        if (isArrayNotEmpty(categoryData) && runMenuAccessibilityOnce.current === 0) {
            libUtils.menuBarAccessibility().init(secondarySideMenuBarId)
            runMenuAccessibilityOnce.current = 1
        }
    }, [categoryData])
    const secondaryNavProps = useSelector(secondaryNavPropsSelector)
    const { additionalLinksNoMegaNav } = secondaryNavProps

    /**
     * Function to show/hide l2 level categories when click on l1
     * @param {CategoryDataProps} categoryProp
     * @return {void}
     */
    const handleCategories = (categoryProp: CategoryDataProps): void => {
        if (firstClick.current === MagicNumber.ZERO) {
            setSelectedFirstCategoryItem(categoryProp)
            setSelectedSecondCategoryItem({})
            setHideFirstSaleCategories(false)
            setShowL2Menu(true)
            firstClick.current = MagicNumber.ONE
        } else if (firstClick.current === MagicNumber.ONE && selectedFirstCategoryItem.id !== categoryProp.id) {
            setSelectedFirstCategoryItem(categoryProp)
            setSelectedSecondCategoryItem({})
            setHideFirstSaleCategories(false)
            setShowL2Menu(true)
        } else {
            setSelectedFirstCategoryItem({})
            setSelectedSecondCategoryItem({})
            setHideFirstSaleCategories(true)
            setShowL2Menu(false)
        }
    }

    /**
     * Function to show/hide l3 category level when click on l2
     * @param {NavigationCategory} subcategory  - use to capture the esc keycode.
     *  @return {void}
     */
    const handleSubcategories = (subcategory: NavigationCategory[]): void => {
        if (firstClick.current === MagicNumber.ZERO) {
            setSelectedSecondCategoryItem(subcategory)
            setHideFirstSaleCategories(true)
            setShowL3Menu(true)
            firstClick.current = MagicNumber.ONE
        } else if (firstClick.current === MagicNumber.ONE && selectedSecondCategoryItem.id !== subcategory.id) {
            setSelectedSecondCategoryItem(subcategory)
            setHideFirstSaleCategories(true)
            setShowL3Menu(true)
        } else {
            setSelectedSecondCategoryItem({})
            setHideFirstSaleCategories(false)
            setShowL3Menu(false)
        }
    }

    useLayoutEffect(() => {
        document.body.style.overflow = showMenu ? 'hidden' : ''
        return () => {
            document.body.style.overflow = ''
        }
    }, [showMenu])

    /**
     * function to getAriaPath url
     * @param {string} url
     * @return {string}
     */
    const getAriaLink = (url: string | Url) => {
        return getAriaPath(typeof url === 'string' ? encodeURI(url) : encodeURI(url?.url)) ? ariaType : false
    }

    const { subcategories, categories: thirdLevelCategory } =
        selectedSecondCategoryItem as unknown as NavigationCategory
    const getSideNavigationClass =
        subcategories?.length || thirdLevelCategory?.length
            ? `${megaNavContainerClass}--sale-category`
            : `${megaNavContainerClass}--l3 ${megaNavContainerClass}--l3-sale-category`

    /**
     * function to create saleCategories
     * @return {JSX.Element}
     */
    const getSaleCategories = (): JSX.Element => {
        return (
            // hideFirstSaleCategories : Sale category displays twice as we are generating l2 & l3 data in loop hence hiding the first one when second one is open
            <div
                className={`${PREFIX}-desktop-side-menu__container ${getSideNavigationClass} ${
                    hideFirstSaleCategories ? 'hide-first-sale-categories' : ''
                }`}>
                <ul
                    className={`${PREFIX}-mega-navigation__sale-categories-section`}
                    data-testid={`mega-navigation-list-l3`}>
                    <div className={classnames(`${PREFIX}-col-sm-10`)}>
                        <MegaNavigationTile tileData={selectedFirstCategoryItem?.saleCategories} />
                    </div>
                </ul>
            </div>
        )
    }

    /**
     * function to create category button
     * @param {NavigationCategory} category
     * @param {number} index
     * @param {boolean} hasSubcategories
     * @param {string} style
     * @param {function} callback
     * @return {JSX.Element}
     */
    const categoryButton = (
        category: NavigationCategory,
        index: number,
        hasSubcategories: boolean,
        style: string,
        callback: () => void,
    ): JSX.Element => {
        const { url, name } = category
        return (
            <button
                className={`${PREFIX}-mega-navigation__no-button`}
                data-testid={`mega-navigation-list-item-${style}-${index}`}
                aria-current={getAriaLink(url)}
                aria-expanded={showL2Menu || showL3Menu}
                tabIndex={0}
                onKeyDown={e => menuEscapeHandler(e)}
                onClick={() => callback(category)}>
                {name}
                {hasSubcategories && <Icon type={'ct-chevron-right'} size="lg" />}
            </button>
        )
    }

    /**
     * function to create side menu markup
     * @param {NavigationCategory} category
     * @param {number} index
     * @param {string} style
     * @param {function} callback
     * @return {JSX.Element}
     */
    const renderListItem = (
        category: NavigationCategory,
        index: number,
        style: string,
        callback: () => void,
    ): JSX.Element => {
        const listItems = category.categories || category.subcategories
        let children = null
        if (listItems && listItems.length) {
            children = (
                <div className={`${PREFIX}-desktop-side-menu__container ${style}`}>
                    {!category.url ? (
                        <h2 className={`${PREFIX}-mega-navigation__shopall__title`}>{category.name}</h2>
                    ) : (
                        <h2>
                            <a
                                className={`${PREFIX}-mega-navigation__shopall__title`}
                                aria-current={getAriaLink(category.url)}
                                href={category.url}>
                                {category.name}
                            </a>
                        </h2>
                    )}
                    <ul className={`${PREFIX}-mega-navigation__categories`}>
                        {listItems.map((catData, categoryIndex) => {
                            const isMatched = selectedSecondCategoryItem.id === catData.id
                            return renderListItem(
                                catData,
                                categoryIndex,
                                isMatched ? `${megaNavContainerClass}--l3` : '',
                                handleSubcategories,
                            )
                        })}
                    </ul>
                    {selectedFirstCategoryItem?.saleCategories && getSaleCategories()}
                </div>
            )
        }
        const { url, name } = category
        const selected = selectedFirstCategoryItem.id === category.id || selectedSecondCategoryItem.id === category.id
        const hasSubcategories: boolean = isArrayNotEmpty(listItems)
        return (
            <li key={`${name}${index}`} className={selected ? `${PREFIX}-selected` : ''}>
                {hasSubcategories ? (
                    categoryButton(category, index, hasSubcategories, style, callback)
                ) : (
                    <a
                        className={`${PREFIX}-mega-navigation__no-button  ${PREFIX}-mega-navigation__no-subCategory`}
                        data-testid={`mega-navigation-list-item-${style}-${index}`}
                        aria-current={getAriaLink(url)}
                        tabIndex={0}
                        href={url}>
                        {name}
                    </a>
                )}
                {children}
            </li>
        )
    }

    /**
     * function to render categories and subcategories
     * @param {NavigationCategory} categoryList
     * @param {string} heading
     * @param {string} href
     * @param {function} callback
     * @param {style} style
     * @return {JSX.Element}
     */
    const renderCategories = (
        categoryList: NavigationCategory[],
        heading: string,
        href: string,
        callback: () => void,
        style: string,
    ): JSX.Element => {
        return (
            <div className={`${PREFIX}-desktop-side-menu__container ${style}`}>
                {!href ? (
                    <div className={`${PREFIX}-mega-navigation__shopall__title`}>{heading}</div>
                ) : (
                    <a
                        className={`${PREFIX}-mega-navigation__shopall__title`}
                        aria-current={getAriaLink(url)}
                        href={href}>
                        {heading}
                    </a>
                )}

                <ul className={`${PREFIX}-mega-navigation__categories`} data-testid={`mega-navigation-list`}>
                    {categoryList.map((category: NavigationCategory, index: number) => {
                        category.subcategories = category.categories
                        const isMatched = selectedFirstCategoryItem.id === category.id
                        return renderListItem(
                            category,
                            index,
                            isMatched ? `${megaNavContainerClass}--l2` : '',
                            handleCategories,
                        )
                    })}
                </ul>
            </div>
        )
    }

    /**
     * function to create mega navigation list item
     * @param {string} category
     * @param {number} index
     * @return {JSX.Element}
     */
    const getNavigationLinkItem = (category: NavigationCategory, index: number): JSX.Element => {
        const { name: categoryName, style } = category
        const linkClass =
            category.categories || category.categoryId ? '' : `${PREFIX}-secondary-navigation-bar__nav-list--link`
        const linkItemStyle = getStyle(style)

        return (
            <li
                className={`${PREFIX}-secondary-navigation-bar__nav-list--item ${linkClass}`}
                key={`${index}-${categoryName}`}>
                {
                    <a
                        className={`${PREFIX}-secondary-navigation-bar__nav-list--item-link ${linkItemStyle}`}
                        href={encodeURI(category.url?.url || category.url)}
                        data-testid={`secondary-navigation-link-${index}`}
                        data-link-value={categoryName}>
                        {categoryName}
                    </a>
                }
            </li>
        )
    }

    /**
     * function to create side navigation list
     * @return {JSX.Element}
     */
    const getNavigationLinks = (): JSX.Element => {
        return (
            <ul
                ref={rewardModalRef}
                className={`${PREFIX}-secondary-navigation-bar__nav-list`}
                id={secondarySideMenuBarId}
                data-testid="secondary-navigation-list">
                <li className={`${PREFIX}-secondary-navigation-bar__nav-list--item`}>
                    <button
                        className={
                            showMenu
                                ? `${PREFIX}-menu-buttons-active`
                                : `${PREFIX}-menu-buttons ${PREFIX}-button--tertiary`
                        }
                        onClick={showMenu ? hideSideMenu : showSideMenu}
                        onKeyDown={e => menuEscapeHandler(e)}
                        aria-haspopup={true}
                        aria-expanded={showMenu}
                        data-testid={`secondary-navigation-link-${0}`}>
                        {showMenu ? <Icon type="ct-close" size="md" /> : <Icon type="ct-menu" size="md" />}
                        {secondaryNavMenuLabel}
                    </button>
                    {showMenu && (
                        <div
                            className={`${PREFIX}-desktop-side-menu ${PREFIX}-full-width-container`}
                            ref={sideMenuContainerRef}>
                            {filterCategoryData?.length &&
                                renderCategories(
                                    filterCategoryData,
                                    shopAllLabel,
                                    '',
                                    handleCategories,
                                    `${megaNavContainerClass}--l1`,
                                )}
                        </div>
                    )}
                </li>
                {additionalLinksNoMegaNav.map((category: NavigationCategory, index: number) => {
                    return getNavigationLinkItem(category, index)
                })}
            </ul>
        )
    }

    const filterCategoryData = categoryData.filter(category => category?.categories || category?.saleCategories)

    return (
        <>
            {filterCategoryData.length ? (
                <>
                    <div
                        className={`${PREFIX}-secondary-navigation ${PREFIX}-secondary-navigation-new-menu ${PREFIX}-full-width-container`}
                        id="secondary-navigation"
                        data-testid="secondary-navigation">
                        <div className={`${PREFIX}-row ${PREFIX}-container`}>
                            <nav
                                aria-label={secondaryNavAriaLabel}
                                className={`${PREFIX}-secondary-navigation-bar ${PREFIX}-secondary-navigation-bar__nav`}>
                                {getNavigationLinks()}
                            </nav>
                        </div>
                    </div>
                </>
            ) : (
                <SkeletonComponent
                    skeletonClass={`${PREFIX}-full-width-container ${PREFIX}-secondary-navigation__skeleton`}
                />
            )}
        </>
    )
}

SecondaryNavigationMenu.propTypes = {
    categoryData: PropTypes.array.isRequired,
    secondaryNavAriaLabel: PropTypes.string,
}

export default SecondaryNavigationMenu
