import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PREFIX, BREAKPOINTS } from '../../config'

import {
    Carousel,
    checkDataLength,
    magicNumber,
    OfferCard,
    OfferFlyout,
    HeroBannerSplit,
    Button,
    BrandingColors,
    Separator,
    SeparatorStyle,
    SeparatorSize,
    getButtonStatus,
    offerComponentLocation,
    getAkamaiPolicy,
} from '@nl/lib'
import { LoyaltyOffers, OfferCarouselProps } from './OfferCarousel.type'
import { Offers } from '../../redux/models/offers.interface'
import { offerConstants } from '../Accounts/Rewards/Offers/OffersCard.constants'
import { clearOffersActivateAction } from '../../redux/actionCreators'
import { carouselSettingValues } from '@nl/lib/src/components/Carousel/Carousel.helper'
import { userProfileDataSelector } from '../../redux/selectors/userProfile.selectors'
import { AkamaiImagePolicies } from '../../akamaiPolicy/akamaiPolicy.service'
import { commonContentSelector, commonContentAvailableSelector } from '../../redux/selectors/commonContent.selectors'
import { activateOffer, checkOfferStatus } from '../../helpers/offers.helper'
import { MagicNumber } from '../../analytics/analytics.type'
import { offersActivatedDataSelector } from '../../redux/selectors/offers.selectors'

const OfferCarousel: React.FC<OfferCarouselProps> = ({ ...props }) => {
    const {
        offersData,
        activateOfferBtnText,
        activatedOfferBtnText,
        isMobileLayout,
        offersPerTileGroupDesktop,
        offersPerTileGroupTablet,
        offersCountToDisplay,
        seeAllOffersCtaA11yLabel,
        seeAllOffersCtaLabel,
        seeAllOffersCtaLink,
        seeAllOffersCtaLinkTarget,
        titleImageAltText,
        titleImageDesktopTablet,
        titleImageMobile,
        redeemedBtnText,
        carouselInfiniteScrollingLoop,
        offerDetailsLabel,
        ctOfferCarouselTextForNonLoyaltyUser,
        ctaLabelForNonLoyaltyUser,
        ctaLinkForNonLoyaltyUser,
        ctaTargetForNonLoyaltyUser,
        cta11yLabelForNonLoyaltyUser,
        nlUserOffers,
    } = props

    const { mobileMaxWidth, tabletMaxWidth } = BREAKPOINTS
    // states for Offer modal and  activation of offers and
    const [selectedOffer, setSelectedOffer] = useState<Offers>({} as Offers)
    const [isOpenViewDetailsModal, setViewDetailsModalOpen] = useState(false)
    const offersActivatedData = useSelector(offersActivatedDataSelector)
    const userProfileData = useSelector(userProfileDataSelector)
    const hasLoyaltyAccount = checkDataLength(userProfileData?.loyalty)
    const dispatch = useDispatch()
    let offersDataforUsers: Offers[] | LoyaltyOffers[] = []
    offersDataforUsers = !!hasLoyaltyAccount ? offersData : nlUserOffers
    const colors = [BrandingColors.TEAL, BrandingColors.BURGUNDY, BrandingColors.RED]

    const labels = {
        activatedLabel: activatedOfferBtnText,
        activateLabel: activateOfferBtnText,
        redeemedLabel: redeemedBtnText,
    }
    const { commonContentAvailable } = useSelector(commonContentSelector)
    const { loyalty } = useSelector(commonContentAvailableSelector)
    const { fallbackOfferImageUrl, moreDetailsLabel } = loyalty || ({} as typeof commonContentAvailable.loyalty)
    const [offersToDisplay, setOffersToDisplay] = useState<Offers[]>(offersDataforUsers)
    const [isAccordionOpen, setIsAccordionOpen] = useState(false)
    const [isActivateOfferClick, setIsActivatedOfferClick] = useState(false)

    const columns = {
        md: offersPerTileGroupDesktop
            ? magicNumber.TWELVE / offersPerTileGroupDesktop
            : magicNumber.TWELVE / magicNumber.THREE,
        sm: offersPerTileGroupTablet
            ? magicNumber.TWELVE / offersPerTileGroupTablet
            : magicNumber.TWELVE / magicNumber.TWO,
        xs: magicNumber.THREE,
    }
    const carouselContainerClass = `${PREFIX}-offer-carousel__text-container`

    const openOfferModal = (offerInModal: Offers): void => {
        setViewDetailsModalOpen(true)
        setSelectedOffer(offerInModal)
    }

    /**
     * useEffect used to update accordion status
     */
    useEffect(() => {
        if (!hasLoyaltyAccount) {
            setIsAccordionOpen(true)
            setIsActivatedOfferClick(true)
        }
        selectedOffer && !!checkOfferStatus(selectedOffer, offerConstants.activate)
            ? setIsAccordionOpen(true)
            : !isActivateOfferClick && setIsAccordionOpen(false)
    }, [labels, selectedOffer, isActivateOfferClick, hasLoyaltyAccount, setIsAccordionOpen])

    // restricting the number of offers to display on UI for loyalty user
    useEffect(() => {
        if (!!hasLoyaltyAccount) {
            setOffersToDisplay(offersDataforUsers.slice(0, offersCountToDisplay))
        }
    }, [offersDataforUsers, offersCountToDisplay, hasLoyaltyAccount])

    useEffect(() => {
        if (checkDataLength(offersActivatedData)) {
            const activatedOfferCode = offersActivatedData?.offers[0].offerCode
            const activationStatus = offersActivatedData?.offers[0].activated
            if (activationStatus === offerConstants.yes) {
                const updatedOffersToDisplay = offersToDisplay.map((offer: Offers) => {
                    if (offer.offerCode === activatedOfferCode) {
                        offer.offerStatus = offerConstants.activated
                    }
                    return offer
                })
                setOffersToDisplay(updatedOffersToDisplay)
                setIsAccordionOpen(true)
                setIsActivatedOfferClick(true)
                dispatch(clearOffersActivateAction())
            }
        }
    }, [offersActivatedData, offersToDisplay, dispatch])

    const setViewDetailsModal = (isCloseClicked: boolean): void => {
        setViewDetailsModalOpen(isCloseClicked)
    }
    const mobileCarouselSettings = {
        slidesToShow: magicNumber.ONE,
        slidesToScroll: magicNumber.ONE,
        centerMode: true,
        variableWidth: false,
    }

    const offersList = () => {
        return offersToDisplay.map((offer: LoyaltyOffers, index: number): JSX.Element => {
            if (!hasLoyaltyAccount) {
                offer.details = {
                    thumbnailUrl: offer?.nluserOfferImg,
                    badgeImageUrl: offer?.nluserBonusImage,
                    offerShortDesc: offer?.nluserOfferDescription,
                    bonusDescription: offer?.nluserOfferDescription,
                    bonusDetailedDescription: offer?.nluserOfferDescription,
                }
                offer.displayBanner = offer?.nluserBannerId
            }

            return (
                <OfferCard
                    key={`offer_${index}`}
                    {...props}
                    offer={offer}
                    openModal={openOfferModal}
                    activateOffer={activateOffer}
                    classNameBanner={`${PREFIX}-offers-card__banner-icon-loyalty`}
                    classNameDates={`${PREFIX}-offers-card_loyalty-dates`}
                    classNameSubtitle={`${PREFIX}-offers-card_loyalty-subtitle`}
                    classNameAlign={`${PREFIX}-offers-card_loyalty-align`}
                    classNameCarousel={`${PREFIX}-col-md-12 ${PREFIX}-col-sm-12 ${PREFIX}-col-xs-6`}
                    status={getButtonStatus(offer?.offerStatus, offerConstants, labels)}
                    columns={columns}
                    hasLoyaltyAccount={!!hasLoyaltyAccount}
                    updateOfferState={setSelectedOffer}
                    component={offerComponentLocation.loyaltyOffer}
                    akamaiPolicy={getAkamaiPolicy(AkamaiImagePolicies.returnPolicy, fallbackOfferImageUrl)}
                />
            )
        })
    }

    const carouselList = offersList()
    const responsiveDesign = [
        {
            breakpoint: mobileMaxWidth,
            settings: mobileCarouselSettings,
        },
        {
            breakpoint: tabletMaxWidth,
            settings: !!hasLoyaltyAccount
                ? carouselSettingValues(carouselList, offersPerTileGroupTablet, true)
                : mobileCarouselSettings,
        },
    ]

    /**
     * function to render OfferFlyout
     * @return {JSX.Element}
     */
    const renderOfferDetailsModal = (): JSX.Element => {
        return (
            <>
                {checkDataLength(selectedOffer) && isOpenViewDetailsModal && (
                    <div className={`${PREFIX}__offers_details`}>
                        <OfferFlyout
                            {...props}
                            offer={selectedOffer || ({} as Offers)}
                            activateOffer={activateOffer}
                            setIsFlyoutOpen={setViewDetailsModal} // accessibility close button in modal
                            setIsActivatedOfferClick={setIsActivatedOfferClick}
                            isAccordionOpen={isAccordionOpen}
                            isOpen={isOpenViewDetailsModal}
                            modalLabel={offerDetailsLabel}
                            status={getButtonStatus(selectedOffer?.offerStatus, offerConstants, labels)}
                            columns={columns}
                            moreDetailsLabel={moreDetailsLabel}
                            hasLoyaltyAccount={!!hasLoyaltyAccount}
                            updateOfferState={setSelectedOffer}
                            component={offerComponentLocation.loyaltyOffer}
                            akamaiPolicy={getAkamaiPolicy(AkamaiImagePolicies.returnPolicy, fallbackOfferImageUrl)}
                        />
                    </div>
                )}
            </>
        )
    }

    const renderSeeAllOffersButton = (): JSX.Element => {
        return (
            <div className={`${PREFIX}-offer-carousel_button`}>
                <a
                    aria-label={seeAllOffersCtaA11yLabel}
                    className={`${PREFIX}-offer-carousel_button__link`}
                    href={seeAllOffersCtaLink}
                    target={seeAllOffersCtaLinkTarget}>
                    {seeAllOffersCtaLabel}
                </a>
            </div>
        )
    }

    /**
     * Renders Carousel Heading Image in Carousel component
     *
     * @return {JSX.Element}
     */
    const renderCarouselHeader = (): JSX.Element => {
        return (
            <img
                className={`${PREFIX}-offer-carousel__image`}
                src={isMobileLayout ? titleImageMobile : titleImageDesktopTablet}
                alt={titleImageAltText}
            />
        )
    }

    /**
     * Renders Carousel
     *
     * @return {JSX.Element}
     */

    const renderCarousel = (): JSX.Element => {
        const nonLoyaltyUserClass = !hasLoyaltyAccount ? `${PREFIX}-offer-carousel__non-loyalty-carousel` : ''
        return (
            <div className={`${PREFIX}-offer-carousel__container ${nonLoyaltyUserClass}`}>
                <Carousel
                    carouselList={carouselList}
                    carouselClassName={`${PREFIX}-offer-carousel_details`}
                    desktopThreshold={!!hasLoyaltyAccount ? offersPerTileGroupDesktop : MagicNumber.THREE}
                    infiniteScrolling={carouselInfiniteScrollingLoop}
                    mobileLandscapeThreshold={offersPerTileGroupTablet}
                    tabletPortraitThreshold={offersPerTileGroupTablet}
                    mobileCarouselSettings={mobileCarouselSettings}
                    tabletArrowSettings={true}
                    displayArrows={true}
                    responsiveDesign={responsiveDesign}
                />
            </div>
        )
    }

    /**
     * Funtion to redirect user to triangle rewards page for non loyalty user
     * @return {void}
     */
    const joinTriangleRewardsCTAHandler = (): void => {
        window.open(ctaLinkForNonLoyaltyUser, ctaTargetForNonLoyaltyUser, 'noopener')
    }

    /**
     * Renders JoinTriangleRewards button component
     * @return {JSX.Element}
     */
    const rendersJoinTriangleRewards = (): JSX.Element => {
        return (
            <div
                className={`${carouselContainerClass}__cta ${
                    isMobileLayout ? `${carouselContainerClass}__cta--mobile` : ''
                }`}>
                <Button
                    type="primary_red"
                    size="medium"
                    id="join-triangle-rewards"
                    onClick={joinTriangleRewardsCTAHandler}
                    ariaLabel={cta11yLabelForNonLoyaltyUser}>
                    {ctaLabelForNonLoyaltyUser}
                </Button>
            </div>
        )
    }

    /**
     * Renders Carousel compoennt for loyalty user
     * @return {JSX.Element}
     */
    const rendersLoyaltyCarousel = (): JSX.Element => {
        return !!hasLoyaltyAccount ? (
            <>
                <div className={`${PREFIX}-offer-carousel__header`}>
                    {renderCarouselHeader()}
                    {seeAllOffersCtaLabel && !isMobileLayout && renderSeeAllOffersButton()}
                </div>
                {renderCarousel()}
                {seeAllOffersCtaLabel && isMobileLayout && renderSeeAllOffersButton()}
            </>
        ) : (
            <></>
        )
    }

    /**
     * Renders Carousel compoennt for non loyalty user
     * @return {JSX.Element}
     */
    const rendersNonLoyaltyCarousel = (): JSX.Element => {
        return (
            <>
                <HeroBannerSplit className={`${PREFIX}-offer-carousel__non-loyalty`} dynamicImgs={renderCarousel()}>
                    {renderCarouselHeader()}
                    <div
                        className={`${carouselContainerClass}__subtitle`}
                        dangerouslySetInnerHTML={{ __html: ctOfferCarouselTextForNonLoyaltyUser }}></div>
                    {ctaLabelForNonLoyaltyUser && !isMobileLayout && rendersJoinTriangleRewards()}
                </HeroBannerSplit>
                {ctaLabelForNonLoyaltyUser && isMobileLayout && rendersJoinTriangleRewards()}
            </>
        )
    }

    return (
        <>
            {checkDataLength(offersToDisplay) && (
                <div className={`${PREFIX}-offer-carousel`}>
                    <Separator colors={colors} style={SeparatorStyle.HORIZONTAL} size={SeparatorSize.FULL_WIDTH} />
                    {!!hasLoyaltyAccount ? rendersLoyaltyCarousel() : rendersNonLoyaltyCarousel()}
                </div>
            )}
            {renderOfferDetailsModal()}
        </>
    )
}

export default OfferCarousel
