export const queryParameters = {
    language: 'lang',
    searchQuery: 'q',
    searchRequestQuery: 'rq',
    divider: ';',
    count: 'count',
    page: 'page',
    priceUpperBound: 'priceUpperBound',
    priceLowerBound: 'priceLowerBound',
    store: 'store',
    plpCDSDivider: '&',
    mock: 'mock',
} as const
