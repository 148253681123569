import { createAction } from 'typesafe-actions'

import {
    ProductResponseData,
    ProductResponseErrorDTO,
    StickyBuyBoxCTARequestPayload,
    ProductSkusData,
    SizeChartDataType,
    BrandValues,
} from '../models/product.interface'

import {
    FETCH_PRODUCT_DETAILS_REQUEST_SUCCESS,
    FETCH_PRODUCT_DETAILS_REQUEST_FAILURE,
    FETCH_PRODUCT_SKU_DATA,
    SET_SELECTED_FIRST_VARIANT,
    SET_SELECTED_SECOND_VARIANT,
    SET_SELECTED_PRODUCT_CODE,
    SET_SELECTED_THIRD_VARIANT,
    SET_SELECTED_FIRST_VARIANT_ID,
    SET_SELECTED_SECOND_VARIANT_ID,
    SET_SELECTED_THIRD_VARIANT_ID,
    SET_STICKY_BUYBOX_CTA_CLICKED,
    TOGGLE_ADD_TO_CART_CTA,
    SET_IF_ALL_VARIANT_SELECTED,
    SET_FBT_PRODUCT_CODE,
    SET_BUY_BOX_PROPS,
    SET_OUT_OF_STOCK,
    SET_SELECTED_QUANTITY,
    SET_SELECTED_IMAGE_URL,
    RESET_PROD_SKU_DATA,
    SET_FRONT_SELECTED_QUANTITY,
    SET_REAR_SELECTED_QUANTITY,
    FETCH_FBT_PRODUCT_SKU_DATA,
    FETCH_PRODUCT_SKU_REQUEST_FAILURE,
    FETCH_FBT_PRODUCT_SKU_REQUEST_FAILURE,
    SET_TIRE_OR_WHEEL_SHOP_WITH_NO_VEHICLE,
    SET_SKU_PRESENT_IN_URL,
    SET_OUT_OF_STOCK_IN_CURRENT_STORE,
    SET_IS_PRODUCT_DATA_AVAILABLE,
    SET_IS_PRICE_AVAILABILITY_API_DONE,
    SET_IS_CART_FLYOUT_OPEN,
    SKU_NOT_FOUND,
    FETCH_VARIANTS_PRODUCT_SKU_DATA,
    FETCH_VARIANTS_PRODUCT_SKU_REQUEST_FAILURE,
    SET_IS_VEHICLE_CHANGED,
    FETCH_SIZE_CHART_DATA,
    FETCH_SIZE_CHART_DATA_REQUEST_FAILURE,
    SET_BRAND_LIST_FETCH_SUCCESS,
} from '../types/products/product.actionTypes.constant'

export const fetchProductDataSuccessAction = createAction(
    FETCH_PRODUCT_DETAILS_REQUEST_SUCCESS,
    (payload: ProductResponseData) => payload,
)<ProductResponseData>()

export const fetchProductDataErrorAction = createAction(
    FETCH_PRODUCT_DETAILS_REQUEST_FAILURE,
    (payload: ProductResponseErrorDTO) => payload,
)<ProductResponseErrorDTO>()

export const fetchProductDataBySkuAction = createAction(
    FETCH_PRODUCT_SKU_DATA,
    (payload: ProductSkusData) => payload,
)<ProductSkusData>()

export const fetchProductDataBySkuErrorAction = createAction(
    FETCH_PRODUCT_SKU_REQUEST_FAILURE,
    (payload: ProductResponseErrorDTO) => payload,
)<ProductResponseErrorDTO>()

export const setIsOutOfStockAction = createAction(SET_OUT_OF_STOCK, (payload: boolean) => payload)<boolean>()

export const setIsOOSCurrentStoreAction = createAction(
    SET_OUT_OF_STOCK_IN_CURRENT_STORE,
    (payload: boolean) => payload,
)<boolean>()

export const setSelectedFirstVariantAction = createAction(SET_SELECTED_FIRST_VARIANT)<string>()

export const setSelectedSecondVariantAction = createAction(SET_SELECTED_SECOND_VARIANT)<string>()

export const setSelectedSizeProductCodeAction = createAction(SET_SELECTED_PRODUCT_CODE)<string>()

export const setSelectedThirdVariantAction = createAction(SET_SELECTED_THIRD_VARIANT)<string>()

export const setQuantitySelectedAction = createAction(SET_SELECTED_QUANTITY)<number>()

export const setSelectedImageUrlAction = createAction(SET_SELECTED_IMAGE_URL)<string>()

export const setSelectedFirstVariantIdAction = createAction(SET_SELECTED_FIRST_VARIANT_ID)<string>()

export const setSelectedSecondVariantIdAction = createAction(SET_SELECTED_SECOND_VARIANT_ID)<string>()

export const setSelectedThirdVariantIdAction = createAction(SET_SELECTED_THIRD_VARIANT_ID)<string>()

export const setStickyBuyboxCTAClickedAction = createAction(
    SET_STICKY_BUYBOX_CTA_CLICKED,
    (payload: StickyBuyBoxCTARequestPayload) => payload,
)<StickyBuyBoxCTARequestPayload>()

export const toggleAddToCartCTAAction = createAction(TOGGLE_ADD_TO_CART_CTA, (payload: boolean) => payload)<boolean>()

export const setIfAllVariantsSelectedAction = createAction(
    SET_IF_ALL_VARIANT_SELECTED,
    (payload: boolean) => payload,
)<boolean>()

export const setFBTProductCodeAction = createAction(SET_FBT_PRODUCT_CODE, (payload: string[]) => payload)<string[]>()

export const setBuyBoxPropsAction = createAction(SET_BUY_BOX_PROPS, (payload: Record<string, unknown>) => payload)<
    Record<string, unknown>
>()

export const resetProductSKUDataAction = createAction(RESET_PROD_SKU_DATA)()

export const setFrontQuantitySelectedAction = createAction(SET_FRONT_SELECTED_QUANTITY)<number>()

export const setRearQuantitySelectedAction = createAction(SET_REAR_SELECTED_QUANTITY)<number>()

export const fetchFBTProductDataBySkuAction = createAction(
    FETCH_FBT_PRODUCT_SKU_DATA,
    (payload: ProductSkusData) => payload,
)<ProductSkusData>()

export const fetchVariantsProductDataBySkuAction = createAction(
    FETCH_VARIANTS_PRODUCT_SKU_DATA,
    (payload: ProductSkusData) => payload,
)<ProductSkusData>()

export const fetchFBTProductDataBySkuErrorAction = createAction(
    FETCH_FBT_PRODUCT_SKU_REQUEST_FAILURE,
    (payload: ProductResponseErrorDTO) => payload,
)<ProductResponseErrorDTO>()

export const fetchVariantsProductDataBySkuErrorAction = createAction(
    FETCH_VARIANTS_PRODUCT_SKU_REQUEST_FAILURE,
    (payload: ProductResponseErrorDTO) => payload,
)<ProductResponseErrorDTO>()

export const setIsTireOrWheelShopWithNoVehicleAction = createAction(
    SET_TIRE_OR_WHEEL_SHOP_WITH_NO_VEHICLE,
    (payload: boolean) => payload,
)<boolean>()

export const setIsSKUPresentInUrlAction = createAction(SET_SKU_PRESENT_IN_URL, (payload: boolean) => payload)<boolean>()

export const setIsProductDataAvailableAction = createAction(
    SET_IS_PRODUCT_DATA_AVAILABLE,
    (payload: boolean) => payload,
)<boolean>()

export const setIsPriceAvailabilityApiDoneAction = createAction(
    SET_IS_PRICE_AVAILABILITY_API_DONE,
    (payload: boolean) => payload,
)<boolean>()

export const skuNotFoundAction = createAction(SKU_NOT_FOUND)()

export const setCartFlyoutOpenAction = createAction(SET_IS_CART_FLYOUT_OPEN, (payload: boolean) => payload)<boolean>()
export const setIsVehicleChangedAction = createAction(SET_IS_VEHICLE_CHANGED, (payload: boolean) => payload)<boolean>()

export const fetchSizeChartDataAction = createAction(
    FETCH_SIZE_CHART_DATA,
    (payload: SizeChartDataType) => payload,
)<SizeChartDataType>()

export const fetchSizeChartDataErrorAction = createAction(
    FETCH_SIZE_CHART_DATA_REQUEST_FAILURE,
    (payload: ProductResponseErrorDTO) => payload,
)<ProductResponseErrorDTO>()

export const setBrandListFetchSuccess = createAction(SET_BRAND_LIST_FETCH_SUCCESS)<BrandValues[]>()
