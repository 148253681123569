import React from 'react'
import { useSelector } from 'react-redux'
import { Icon } from '@nl/lib'
import { PREFIX } from '../../config'
import PropTypes from 'prop-types'

import { NotificationBannerProps } from './NotificationBanner.type'
import { preferredStoreDetailsSelector } from '../../redux/selectors/storeDetails.selectors'

/**
 * NotificationBanner component
 * @return {JSX.Element} returns NotificationBanner component
 */
const NotificationBanner: React.FC<NotificationBannerProps> = ({ ...props }) => {
    const { onlineOrderingMessage, toShopOnlineMessage, redirectLabel, redirectURL } = props
    const preferredStoreDetails = useSelector(preferredStoreDetailsSelector)
    const { onlineOrdering } = preferredStoreDetails

    return Object.values(preferredStoreDetails).length > 0 && !onlineOrdering ? (
        <div className={`${PREFIX}-notification-banner__body`} data-testid="notification-banner">
            <div>
                <Icon type="ct-notification-caution" size="lg" />
            </div>
            <div>
                <span className={`${PREFIX}-notification-banner__ordering-message`}>{onlineOrderingMessage}</span>
                <span>
                    {toShopOnlineMessage + ' '}
                    <a href={redirectURL} data-link-value={redirectLabel}>
                        {redirectLabel}
                    </a>
                </span>
            </div>
        </div>
    ) : null
}

NotificationBanner.propTypes = {
    onlineOrderingMessage: PropTypes.string.isRequired,
    toShopOnlineMessage: PropTypes.string.isRequired,
    redirectURL: PropTypes.string.isRequired,
    redirectLabel: PropTypes.string.isRequired,
}

export default NotificationBanner
