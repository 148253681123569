import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../Icon'
import { PREFIX } from '../config'
import { MegaNavigationListProps, CategoryDataProps, ItemColorType } from './MegaNavigation.type'
import { isArrayNotEmpty } from '../../utils'
import { getAriaPath } from './MegaNavigation.helper'
import { ariaType } from '../../globalConstants/global.constant'

/**
 * Scroll to the top to container. IF menu contains multiple menu links, and user clicks on bottom / last links
 * then in case of L2/L3 categories, needs to show contents from top.
 * @return { void }
 */
const scrollToTop = (): void => {
    const sideMenuContainerElem = document.querySelector(`.${PREFIX}-side-menu__container`)
    if (!!sideMenuContainerElem) {
        sideMenuContainerElem.scrollTop = 0
    }
}

/* MegaNavigationList component
 * @param {MegaNavigationListProps} props
 * @return {JSX.Element} returns MediaGalleryViewer component
 */
const MegaNavigationList: React.FC<MegaNavigationListProps> = ({ ...props }): JSX.Element => {
    const { categoryMapData, path, onCategoryClicked, currentView, style, megaNavClicked } = props
    const renderListOrShopList = (): JSX.Element => {
        return (
            <ul
                className={`${PREFIX}-mega-navigation__categories-section`}
                data-testid={`mega-navigation-list-${currentView}`}>
                {categoryMapData.map((value: CategoryDataProps, index: number) => {
                    const { style: colorType, categories, url, name } = value
                    const highlightedClass =
                        !!colorType && colorType === ItemColorType.highlight ? `highlight-item` : 'standard-item'
                    return (
                        <li key={`${name}${index}`} className={highlightedClass}>
                            <button
                                className={`${PREFIX}-mega-navigation__no-button ${
                                    style === 'bold' ? 'text-bold' : ''
                                }`}
                                data-testid={`mega-navigation-list-item-${currentView}-${index}`}
                                aria-current={
                                    getAriaPath(typeof url === 'string' ? encodeURI(url) : encodeURI(url?.url))
                                        ? ariaType
                                        : false
                                }
                                onClick={() => {
                                    megaNavClicked && megaNavClicked(name)
                                    currentView === 'L3' || !categories
                                        ? (window.location.href =
                                              typeof url === 'string' ? encodeURI(url) : encodeURI(url?.url))
                                        : onCategoryClicked(value, currentView)
                                    scrollToTop()
                                }}>
                                {name}
                                {!!categories && isArrayNotEmpty(categories) && currentView !== 'L3' && (
                                    <Icon type={'ct-chevron-right'} size="lg" path={path} />
                                )}
                            </button>
                        </li>
                    )
                })}
            </ul>
        )
    }

    return renderListOrShopList()
}

MegaNavigationList.propTypes = {
    categoryMapData: PropTypes.any,
    path: PropTypes.string,
    onCategoryClicked: PropTypes.func,
    currentView: PropTypes.string,
    style: PropTypes.oneOf(['normal', 'bold']),
    megaNavClicked: PropTypes.func,
}

export default MegaNavigationList
