import { offerConstants } from './../../components/Accounts/Rewards/Offers/OffersCard.constants'
import { getProvider } from '../providers'
import { AnalyticsRecordInterface } from '../providers/provider.type'
import { analyticsAttributes } from '../../globalConstants/analyticsParams.constant'
import { Offers } from '../../redux/models/offers.interface'

const provider = getProvider()

const accountAnalytics = (() => {
    /**
     * function to send the update analytucs data
     *
     * @param {string} sectionName - which detail is updated eg: address profile etc.
     * @return {AnalyticsRecordInterface} - analytics data for update events
     */
    const _updateEvents = (sectionName: string): AnalyticsRecordInterface => {
        const {
            event,
            eventParameters: { action, category, labels },
        } = analyticsAttributes
        return {
            event: event.interaction,
            eventParameters: {
                action: action.updateUserProfile,
                category: category.account,
                label: labels.update + ' ' + sectionName, // 'update security', 'update profile', 'update billing', 'update shipping', etc.
                value: '',
            },
        }
    }
    /**
     * function to send signout analytics data when signout button is clicked
     *
     * @param {string} location - the location from where signout action is triggered
     * @return {AnalyticsRecordInterface} - analytics data for signout event
     */
    const _signoutAnalytics = (location: string): AnalyticsRecordInterface => {
        const {
            event,
            eventParameters: { action, category },
        } = analyticsAttributes
        return {
            event: event.signout,
            eventParameters: {
                action: action.signout,
                category: category.account,
                label: undefined,
                value: '',
                location: location, // location where sign out was initiated ex'header', 'my account', etc.
            },
        }
    }

    const _offerAnalytics = (type: string, offers: Offers[], ActivateAll?: boolean): AnalyticsRecordInterface => {
        const {
            event: { view, promoView, promoClick },
            promotion: { componentType, creative, activeLabel, activateLabel },
        } = analyticsAttributes

        const promotionData = offers.map((offer: Offers, index: number) => ({
            creative: creative,
            name: offer.details?.offerShortDesc,
            position: index.toString(),
            componentType: componentType,
            offerStatus:
                offer.offerStatus?.toLowerCase() === offerConstants.activated.toLowerCase()
                    ? activeLabel
                    : activateLabel,
            ActivateAll: ActivateAll,
        }))

        return {
            event: type === view ? promoView : promoClick,
            promotion: promotionData,
        }
    }

    const _offerFilterAnalytics = (
        filterCategory: string,
        filter: string,
        enabled: boolean,
    ): AnalyticsRecordInterface => {
        const {
            event,
            eventParameters: { action, category, labels },
        } = analyticsAttributes
        const enabledLabel = enabled ? labels.enabled : labels.cleared
        const displayLabel =
            filter.toLowerCase() === labels.clearAllFilter.toLowerCase()
                ? labels.clearAllFilter
                : `${filterCategory}  - ${filter} ${enabledLabel}`
        return {
            event: event.facetFilterAction,
            eventParameters: {
                action: action.offerFilter,
                category: category.account,
                label: displayLabel, // ex. 'categories - automotive enabled', 'offer status - activated enabled',
                value: '',
                filterButtonClicked: filterCategory, // button that was clicked to open filter ex. 'offer status', 'categories'
            },
        }
    }

    return {
        accountUpdateEvents: (sectionName: string) => {
            provider.push(_updateEvents(sectionName))
        },

        signoutAnalytics: (location: string) => {
            provider.push(_signoutAnalytics(location))
        },

        offerAnalytics: (type: string, offers: Offers[], ActivateAll?: boolean) => {
            provider.push(_offerAnalytics(type, offers, ActivateAll))
        },

        offerFilterAnalytics: (filterCategory: string, filter: string, enabled: boolean) => {
            provider.push(_offerFilterAnalytics(filterCategory, filter, enabled))
        },
    }
})()

export default accountAnalytics
