/**
 * Returns base total amount
 * @param {number} rewardsPercentage
 * @param {number} currentPriceValue
 * @param {number} pteOfferValue
 * @return {number}
 */
export const getPTEBaseTotal = (rewardsPercentage: number, currentPriceValue: number, pteOfferValue = 0): number => {
    return Number(rewardsPercentage * currentPriceValue) + Number(pteOfferValue)
}
