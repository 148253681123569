import { createAction } from 'typesafe-actions'
import {
    StoreWithAvailability,
    FetchStoreRequest,
    StoreResponseErrorData,
    NearbyStoreList,
    Filter,
    StoreGeoPoint,
} from '../models/storeDetails.interface'

import {
    FETCH_STORE_DETAILS,
    FETCH_STORE_DETAILS_SUCCESS,
    FETCH_STORE_DETAILS_FAILURE,
    FETCH_NEARBY_STORES_REQUEST,
    FETCH_NEARBY_STORES_SUCCESS,
    FETCH_NEARBY_STORES_FAILURE,
    SET_SELECTED_STORE_NAME_SUCCESS,
    RESET_SELECTED_STORE_TOAST_SUCCESS,
    FETCH_STORE_FILTERS_SUCCESS,
    FETCH_STORE_FILTERS_FAILURE,
    SET_PREFERRED_STORE_ID_SUCCESS,
    SET_PREFERRED_STORE_DETAILS,
    SET_PREFERRED_STORE_DETAILS_ERROR,
    SET_STORE_CHANGE,
    STORE_GEO_DETAILS,
    SET_NEARBY_FOR_A_STORE,
    SET_NEARBY_FOR_SKU,
    SET_NEARBY_FOR_POSTAL_CODE,
    SET_NEARBY_FOR_STORE_LOCATOR,
    SET_IS_NEARBY_STORE_LIST_API_DONE,
    SET_STORE_API_TRIANGULATION_FAILS,
    SET_IS_STORE_DETAILS_FETCHED,
    SET_IS_GEOLOCATION_ALLOWED,
    FETCH_NEARBY_STORES_ERROR_CODE,
} from '../types/stores/storeDetails.actionTypes.constant'
import { AxiosResponse } from 'axios'

export const fetchStoreFiltersSuccessAction = createAction(FETCH_STORE_FILTERS_SUCCESS, (payload: Filter[]) => payload)<
    Filter[]
>()

export const fetchStoreFiltersErrorAction = createAction(
    FETCH_STORE_FILTERS_FAILURE,
    (payload: AxiosResponse<StoreResponseErrorData>) => payload,
)<AxiosResponse<StoreResponseErrorData>>()

export const fetchStoreDetailsAction = createAction(
    FETCH_STORE_DETAILS,
    (payload: FetchStoreRequest) => payload,
)<FetchStoreRequest>()

export const fetchStoreDetailsSuccessAction = createAction(
    FETCH_STORE_DETAILS_SUCCESS,
    (payload: StoreWithAvailability) => payload,
)<StoreWithAvailability>()

export const fetchStoreDetailsErrorAction = createAction(
    FETCH_STORE_DETAILS_FAILURE,
    (payload: AxiosResponse<StoreResponseErrorData>) => payload,
)<AxiosResponse<StoreResponseErrorData>>()

export const fetchNearbyStoresErrorCode = createAction(
    FETCH_NEARBY_STORES_ERROR_CODE,
    (payload: AxiosResponse<StoreResponseErrorData>) => payload,
)<AxiosResponse<StoreResponseErrorData>>()

export const fetchNearbyStoreRequestAction = createAction(FETCH_NEARBY_STORES_REQUEST)()

export const fetchNearbyStoreSuccessAction = createAction(FETCH_NEARBY_STORES_SUCCESS)<NearbyStoreList>()

export const setNearByStoreListForSelectedStore = createAction(SET_NEARBY_FOR_A_STORE)<NearbyStoreList>()

export const fetchNearbyStoreErrorAction =
    createAction(FETCH_NEARBY_STORES_FAILURE)<AxiosResponse<StoreResponseErrorData>>()

export const setSelectedStoreNameSuccessAction = createAction(SET_SELECTED_STORE_NAME_SUCCESS)<string>()

export const resetSelectedStoreToastSuccessAction = createAction(RESET_SELECTED_STORE_TOAST_SUCCESS)()

export const setPreferredStoreIdSuccessAction = createAction(SET_PREFERRED_STORE_ID_SUCCESS)<string>()

export const setPreferredStoreDetailsAction = createAction(SET_PREFERRED_STORE_DETAILS)<StoreWithAvailability>()

export const setStoreChangeAction = createAction(SET_STORE_CHANGE)<string>()

export const storeLatLongDetails = createAction(STORE_GEO_DETAILS, (payload: StoreGeoPoint) => payload)<StoreGeoPoint>()

export const setPreferredStoreDetailsErrorAction = createAction(SET_PREFERRED_STORE_DETAILS_ERROR)<
    AxiosResponse<StoreResponseErrorData>
>()

export const setNearByStoreListForSelectedSku = createAction(SET_NEARBY_FOR_SKU)<NearbyStoreList>()

export const setNearByStoreListForPostalCode = createAction(SET_NEARBY_FOR_POSTAL_CODE)<NearbyStoreList>()

export const setNearByStoreListForStoreLocator = createAction(SET_NEARBY_FOR_STORE_LOCATOR)<NearbyStoreList>()

export const setIsNearByStoreListApiDoneAction = createAction(SET_IS_NEARBY_STORE_LIST_API_DONE)<boolean>()

export const setIsStoreDetailsFetched = createAction(SET_IS_STORE_DETAILS_FETCHED)<boolean>()

export const setStoreAPITriangulationFailAction = createAction(SET_STORE_API_TRIANGULATION_FAILS)<boolean>()

export const setIsGeolocationAllowed = createAction(SET_IS_GEOLOCATION_ALLOWED)<boolean>()
