import React, { RefObject, useCallback, useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'

import { VideoGalleryProps, VideoListType } from './VideoGalleryViewer.type'
import { isArrayNotEmpty } from '../../utils/isArrayNotEmpty'
import { PREFIX } from '../config'
import { videoGalleryViewerClassName, videoGalleryConstants, thumbnailImageCount } from './VideoGalleryViewer.constant'
import { DynamicButton } from '../MediaGalleryViewer/MediaGalleryViewer.type'
import { mediaGalleryConstants } from '../MediaGalleryViewer/MediaGalleryViewer.constant'
import {
    addNavigationArrowButtons,
    getImagePath,
    thumbnailArrowClick,
} from '../MediaGalleryViewer/MediaGalleryViewer.helper'

/* VideoGalleryViewer component
 * @param {VideoGalleryProps} props
 * @return {JSX.Element} returns VideoGalleryViewer component
 */
const VideoGalleryViewer: React.FC<VideoGalleryProps> = props => {
    const {
        videoList,
        windowRef,
        hostNames,
        a11yCarouselPreviousLabel,
        path,
        a11yCarouselNextLabel,
        videoNowPlayingLabel,
        videoAnalytics,
    } = props

    const videoGalleryRef = useRef(null)

    const {
        thumbnailPrevArrowClass,
        thumbnailNextArrowClass,
        thumbnailPlayButtonClass,
        playButtonIcon,
        videoPlayButtonClass,
    } = videoGalleryConstants

    const { prevArrowIcon, nextArrowIcon } = mediaGalleryConstants

    const playButton = useMemo(
        () => ({
            className: thumbnailPlayButtonClass,
            type: playButtonIcon,
            size: 'md',
        }),
        [thumbnailPlayButtonClass, playButtonIcon],
    )

    const prevArrowHrefLink = getImagePath(prevArrowIcon, path)
    const nextArrowHrefLink = getImagePath(nextArrowIcon, path)
    const playButtonHrefLink = getImagePath(playButtonIcon, path)

    /**
     * function to insert dynamic play buttons
     * @param {DynamicButton} dynamicButton
     */
    const insertDynamicPlayButtons = useCallback((dynamicButton: DynamicButton): void => {
        const { elementRef, position, className, type, iconHrefLink, size } = dynamicButton
        elementRef.insertAdjacentHTML(
            position,
            `<svg class='${className} ${PREFIX}-icon ${PREFIX}-icon-${type} ${PREFIX}-icon--${size}' alt='Play video'>
                <use href=${iconHrefLink} />
            </svg>`,
        )
    }, [])

    /**
     * function to add vide play  buttons
     * @param {Element} elementRef
     * @param {string} position
     */
    const addVideoPlayButton = useCallback(
        (elementRef: Element, position: string): void => {
            insertDynamicPlayButtons(
                Object.assign(playButton, {
                    elementRef,
                    position: position as InsertPosition,
                    iconHrefLink: playButtonHrefLink,
                    buttonId: 'play-button',
                }),
            )
        },
        [playButton, insertDynamicPlayButtons, playButtonHrefLink],
    )

    /**
     * function to create video gallery viewer
     * @param {RefObject<HTMLElement>} elementRef
     */
    const createVideoGalleryViewer = useCallback(
        (elementRef: RefObject<HTMLElement>): void => {
            new windowRef.Akamai.AsyncViewer(elementRef.current, {
                items: {
                    hostnames: hostNames,
                    data: videoList,
                },
            })
        },
        [hostNames, videoList, windowRef.Akamai.AsyncViewer],
    )

    /**
     * function to handle play video feature
     * @param {string} videoUrl used to return video name to parent component- VideoGallery
     */
    const handleVideoPlayButtonClick = useCallback(
        (videoUrl: string): void => {
            const parentVideoDiv = document.querySelectorAll(
                `.${videoGalleryViewerClassName} .snapper_nextprev_contain .snapper_items .snapper_item`,
            )
            parentVideoDiv.forEach((ele: Element) => {
                const playIconEle = ele.querySelector(`.${videoPlayButtonClass}`)
                if (playIconEle && !playIconEle.classList.contains('is-hidden')) {
                    playIconEle.classList.add('is-hidden')
                }
            })
            videoAnalytics(videoUrl)
        },
        [videoAnalytics, videoPlayButtonClass],
    )

    /**
     * function to handle feature on video thumbnail click
     * @param {Element} elementRef
     */
    const handleThumbnailClick = useCallback(
        (elementRef: Element): void => {
            const thumbnailDiv = document.querySelectorAll(
                `.${videoGalleryViewerClassName} .snapper_nav .snapper_nav_inner a`,
            )

            thumbnailDiv.forEach((thumbEle: Element) => {
                const element = thumbEle.querySelector('.nl-video-gallery-viewer__now-playing')
                const playIconEle = thumbEle.querySelector(`.${videoPlayButtonClass}`)
                // This condition is used to show/hide play icon or playing now text if video is playing or paused
                if (thumbEle.isEqualNode(elementRef) && !playIconEle.classList.contains('is-hidden')) {
                    element.classList.add(`${videoGalleryViewerClassName}__now-playing-visible`)
                    playIconEle && playIconEle.classList.add('hide')
                } else {
                    element.classList.remove(`${videoGalleryViewerClassName}__now-playing-visible`)
                    playIconEle && playIconEle.classList.remove('hide')
                }
            })
        },
        [videoPlayButtonClass],
    )

    /**
     * function to add vide thumbnail description
     * @param {Element} videoListData
     */
    const addThumbnailDescription = useCallback(
        (videoListData: VideoListType[]) => {
            const thumbnailDiv = document.querySelectorAll(
                `.${videoGalleryViewerClassName} .snapper_nav .snapper_nav_inner a`,
            )
            thumbnailDiv.forEach((thumbnail: Element, index: number) => {
                const nowPlayingClass = index === 0 ? `${videoGalleryViewerClassName}__now-playing-visible` : ''
                thumbnail.insertAdjacentHTML(
                    'beforeend',
                    `<span class="${videoGalleryViewerClassName}__thumb-category"><span class="${videoGalleryViewerClassName}__now-playing ${nowPlayingClass}">${videoNowPlayingLabel} . </span>${videoListData[index]?.category}</span><p class="${videoGalleryViewerClassName}__thumb-title">${videoListData[index]?.title}</p>`,
                )
                addVideoPlayButton(thumbnail, 'beforeend')
                if (index === 0) {
                    const playIconEle = thumbnail.querySelector(`.${videoPlayButtonClass}`)
                    playIconEle?.classList.add('hide')
                }

                thumbnail.addEventListener('click', () => handleThumbnailClick(thumbnail))
            })

            const parentVideoDiv = document.querySelectorAll(
                `.${videoGalleryViewerClassName} .snapper_nextprev_contain .snapper_items .snapper_item`,
            )
            parentVideoDiv.forEach((video: Element, index: number) => {
                const videoUrl = videoListData[index]?.url
                const videoEle = video.querySelector('video')
                addVideoPlayButton(videoEle, 'afterend')
                video.insertAdjacentHTML(
                    'beforeend',
                    `<span class="${videoGalleryViewerClassName}__category">${videoListData[index]?.category}</span><p class="${videoGalleryViewerClassName}__video-info"><span class="${videoGalleryViewerClassName}__title">${videoListData[index]?.title}</span><span class="${videoGalleryViewerClassName}__description">${videoListData[index]?.description}</span></p>`,
                )
                video.addEventListener('click', () => handleVideoPlayButtonClick(videoUrl))
            })
        },
        [
            addVideoPlayButton,
            handleThumbnailClick,
            handleVideoPlayButtonClick,
            videoNowPlayingLabel,
            videoPlayButtonClass,
        ],
    )

    /**
     * function to add carousel arrows to Thumbnails
     */
    const addThumbnailArrows = useCallback(() => {
        const thumbnailDiv = document.querySelector(`.${videoGalleryViewerClassName} .snapper_nav`)

        if (thumbnailDiv) {
            addNavigationArrowButtons(
                thumbnailDiv,
                'video-thumb-prev-arrow-id',
                'video-thumb-next-arrow-id',
                prevArrowHrefLink,
                nextArrowHrefLink,
                a11yCarouselPreviousLabel,
                a11yCarouselNextLabel,
                thumbnailPrevArrowClass,
                thumbnailNextArrowClass,
            )
            // Add event listeners to thumbnail arrows
            document
                .querySelector('#video-thumb-prev-arrow-id')
                .addEventListener('click', () =>
                    thumbnailArrowClick(true, thumbnailImageCount, videoGalleryViewerClassName),
                )
            document
                .querySelector('#video-thumb-next-arrow-id')
                .addEventListener('click', () =>
                    thumbnailArrowClick(false, thumbnailImageCount, videoGalleryViewerClassName),
                )
        }
    }, [
        a11yCarouselNextLabel,
        a11yCarouselPreviousLabel,
        nextArrowHrefLink,
        prevArrowHrefLink,
        thumbnailPrevArrowClass,
        thumbnailNextArrowClass,
    ])

    useEffect(() => {
        if (isArrayNotEmpty(videoList) && windowRef.Akamai) {
            createVideoGalleryViewer(videoGalleryRef)
            // Show thumbnail arrows only if the product images count is greater than thumbnailImageCount
            if (videoList.length > thumbnailImageCount) {
                addThumbnailArrows()
            }
            addThumbnailDescription(videoList)
        }
    }, [videoList, windowRef.Akamai, addThumbnailDescription, addThumbnailArrows, createVideoGalleryViewer])

    return (
        <div className={`${videoGalleryViewerClassName}`} data-testid="video-gallery">
            <div data-akamai-viewer ref={videoGalleryRef} className={`${videoGalleryViewerClassName}`}></div>
        </div>
    )
}

VideoGalleryViewer.propTypes = {
    windowRef: PropTypes.any,
    hostNames: PropTypes.array,
    path: PropTypes.string,
    a11yCarouselNextLabel: PropTypes.string,
    a11yCarouselPreviousLabel: PropTypes.string,
    videoList: PropTypes.array,
    videoNowPlayingLabel: PropTypes.string,
    videoAnalytics: PropTypes.func,
}

export default VideoGalleryViewer
