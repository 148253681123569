import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import appCacheService from '../../utils/appCacheService'
import {
    getCartItemsData,
    getMiniCartItemsData,
    getCartItemsForAuthForMerge,
    getMiniCartItemsForAuthForMerge,
    mergeAuthCartWithGuest,
} from '../../redux/actions'
import { CheckoutService } from '../../services/checkoutService'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { useAuth } from '../../hooks'
import { updateCartDataSuccessAction } from '../../redux/actionCreators'
import { FilteredCartData } from '../../redux/models/cart.interface'
import { pageTypes } from '../../config'
import getPageType from '../../utils/getPageType'
import { commonContentSelector } from '../../redux/selectors/commonContent.selectors'
import { storeSharedCartSelector } from '../../redux/selectors/sharedCart.selectors'
import { extractKeyValues } from '@nl/lib'
import {
    isAuthFlowExecutedSelector,
    signOutSuccessSelector,
    userProfileDataSelector,
} from '../../redux/selectors/userProfile.selectors'
import { cartItemsDataForAuthSelector } from '../../redux/selectors/cart.selectors'
import { selectedPreferredStoreIdSelector } from '../../redux/selectors/storeDetails.selectors'
import { isPageForMiniCart } from '../../utils/checkPageType'
import { isNeedToClearTenders } from '../Checkout/Checkout.helper'
import { useATCLimitItemsFailureToast } from './hooks'

const CartInit: React.FC = () => {
    const isAuthFlowExecuted = useSelector(isAuthFlowExecutedSelector)
    const userProfileData = useSelector(userProfileDataSelector)
    const signOutSuccess = useSelector(signOutSuccessSelector)
    const selectedPreferredStoreId = useSelector(selectedPreferredStoreIdSelector)
    const cartItemsDataForAuth = useSelector(cartItemsDataForAuthSelector)
    const { commonContentAvailable } = useSelector(commonContentSelector)
    const storeSharedCart = useSelector(storeSharedCartSelector)
    const dispatch = useDispatch()
    const currentPageType = getPageType()
    const { isStoreSharedCart } = storeSharedCart || {}
    const authenticatedUser = userProfileData ? Boolean(Object.keys(userProfileData).length) : false

    const [[enableCacheCartToLocalStorageForAnonymous]] = extractKeyValues(commonContentAvailable, [
        {
            featureFlag: ['enableCacheCartToLocalStorageForAnonymous'],
        },
    ])

    const commonContentIsFetched = checkDataLength(commonContentAvailable)

    const runUnAuthenticatedOnlyOnce = useRef(0)
    const runAuthenticatedOnlyOnce = useRef(0)
    /**
     * Conditions for auth call to happen
     */
    const authCondition = useAuth({
        runOnce: runAuthenticatedOnlyOnce,
    })

    /**
     * Conditions for Guest call.
     */
    const unAuthCondition = useCallback(() => {
        return (
            (!!appCacheService.getCartGuid() || window.location.search.includes('cart-guid')) &&
            runUnAuthenticatedOnlyOnce.current === 0 &&
            !checkDataLength(userProfileData)
        )
    }, [userProfileData])

    /**
     * Conditions for Cashing cart to LocalStorage
     */
    const isEnableCashingCartToLocalStorage =
        currentPageType !== pageTypes.checkout &&
        currentPageType !== pageTypes.cart &&
        (enableCacheCartToLocalStorageForAnonymous as boolean)

    // Clear cart on sign out.
    useEffect(() => {
        if (signOutSuccess) {
            runAuthenticatedOnlyOnce.current = 0
            dispatch(updateCartDataSuccessAction({} as FilteredCartData))
            appCacheService.removeCartGuid()
            appCacheService.cartData.delete()
            appCacheService.removeSflGuid()
            appCacheService.cartDataForAnonymous.remove()
        }
    }, [signOutSuccess, dispatch])

    /**
     * Function for getting cart data depending on the page type
     */
    const callGetCartItemsData = useCallback(
        (guid: string, option: string, showSpinner = false, enableCache = false) => {
            isPageForMiniCart()
                ? dispatch(getMiniCartItemsData(guid, option, showSpinner, enableCache))
                : !isNeedToClearTenders(guid, true)
                ? dispatch(getCartItemsData(guid, option, false, false, false, false, true, true))
                : CheckoutService.clearTenders(guid, true)
                      .then(() => appCacheService.removeTendersStorageData(guid))
                      .then(() => dispatch(getCartItemsData(guid, option, false, false, false, false, true, true)))
                      .catch(error => console.error('clearTenders request error ', error))
        },
        [dispatch],
    )

    useEffect(() => {
        /**
         * Run the code once the profile call happens i.e. isAuthenticatedFlow becomes true. whether success or failure.
         * isAuthenticatedFlow is true whether user logged or not.
         */
        if (
            !!(selectedPreferredStoreId || appCacheService.preferredStoreId.get()) &&
            isAuthFlowExecuted &&
            commonContentIsFetched
        ) {
            // If user logged in make a authenticated call.
            const currentGuid = appCacheService.getCartGuid()
            if (authCondition()) {
                if (!!currentGuid) {
                    if (!!appCacheService.cartMergeFlag.get()) {
                        isPageForMiniCart()
                            ? dispatch(getMiniCartItemsForAuthForMerge(''))
                            : dispatch(getCartItemsForAuthForMerge(''))
                        appCacheService.cartMergeFlag.remove()
                    } else {
                        callGetCartItemsData(currentGuid, '')
                    }
                } else {
                    callGetCartItemsData('', '')
                    appCacheService.cartMergeFlag.remove()
                }
                runAuthenticatedOnlyOnce.current = 1
            } else {
                if (unAuthCondition()) {
                    callGetCartItemsData(currentGuid, '', false, isEnableCashingCartToLocalStorage)
                    runUnAuthenticatedOnlyOnce.current = 1
                }
            }
        }
    }, [
        dispatch,
        isAuthFlowExecuted,
        selectedPreferredStoreId,
        authCondition,
        unAuthCondition,
        isEnableCashingCartToLocalStorage,
        enableCacheCartToLocalStorageForAnonymous,
        commonContentIsFetched,
        callGetCartItemsData,
    ])

    const isMergeAuthCartWithGuestOnCheckoutPage = useCallback((): boolean => {
        return (
            currentPageType === pageTypes.checkout &&
            checkDataLength(cartItemsDataForAuth) &&
            isStoreSharedCart &&
            authenticatedUser
        )
    }, [currentPageType, cartItemsDataForAuth, isStoreSharedCart, authenticatedUser])

    useEffect(() => {
        const anonymousGuid = appCacheService.getCartGuid()
        if (
            (checkDataLength(cartItemsDataForAuth) &&
                !!anonymousGuid &&
                currentPageType !== pageTypes.checkout &&
                anonymousGuid !== cartItemsDataForAuth.cart.cartId) ||
            isMergeAuthCartWithGuestOnCheckoutPage()
        ) {
            dispatch(mergeAuthCartWithGuest(anonymousGuid, cartItemsDataForAuth.cart.cartId, undefined, true))
        }
    }, [cartItemsDataForAuth, dispatch, currentPageType, isMergeAuthCartWithGuestOnCheckoutPage])

    useATCLimitItemsFailureToast()

    return null
}

export default CartInit
export { CartInit }
