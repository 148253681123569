import { Dispatch } from 'redux'

import {
    ActivateOffersPayload,
    OfferActivation,
    CustomerOffers,
    FetchOffersPayload,
    ActivateTargetedOfferPayload,
    TargetedOfferActivation,
    ActiveOffer,
    Offers,
} from '../models/offers.interface'
import {
    offersSuccessAction,
    offersFailureAction,
    offersActivateFailureAction,
    offersActivateSuccessAction,
    targetedOfferActivateSuccessAction,
    offersFetchInProgressAction,
    updateFilteredOffersSortingAction,
    updateFilteredOffersAction,
    filteredOffersFailureAction,
    filteredOffersSuccessAction,
    setLoyaltyOffersSuccessAction,
} from '../actionCreators'
import { offersService } from '../../services/offersService'
import { offerConstants } from '../../components/Accounts/Rewards/Offers/OffersCard.constants'
import { checkDataLength } from '@nl/lib'
import { AxiosError } from 'axios'
import { ErrorResponse } from '../models/mergeLoyaltyCard.interface'
import {
    areOffersCached,
    parseCachedOffersString,
    cacheOffers,
    offerLanguageKey,
    getOffersPayload,
} from '../../utils/offersCache.helper'
import sessionStorageService from '../../utils/sessionStorageService'
import { emailActivationUrl } from '../../components/EmailOfferActivation/EmailOfferActivation.constant'
import { FilteredOffers } from '../reducers/offers.reducer'
import httpClient from '../utils/httpClient'

/**
 * Fetch customer offers action
 * @param {FetchOffersPayload} requestPayload
 * @param {boolean} forceCall
 * @param {string} lmsProfileId
 * @return {Dispatch}
 */
export const fetchCustomerOffers =
    (requestPayload: FetchOffersPayload, forceCall?: boolean, lmsProfileId?: string) =>
    (dispatch: Dispatch): Promise<void> | void => {
        dispatch(offersFetchInProgressAction(true))
        const url = new URL(window.location.href)
        const pathLanguage: string = url.pathname.includes('/en/') ? 'en' : 'fr'
        const languageMatch = sessionStorageService.getItem(offerLanguageKey) === pathLanguage ? true : false
        if (areOffersCached() && !forceCall && languageMatch) {
            const cachedOffers = parseCachedOffersString()
            dispatch(offersSuccessAction(cachedOffers))
        } else {
            return offersService
                .getOffers(requestPayload, lmsProfileId)
                .then((offersData: { data: CustomerOffers }) => {
                    const unActivatedOffers = offersData.data.offers?.filter(
                        offer => offer.offerStatus === offerConstants.activate,
                    )
                    offersData.data.unActivatedOffersCount = unActivatedOffers?.length
                    cacheOffers(offersData.data, pathLanguage)
                    dispatch(offersSuccessAction(offersData.data))
                })
                .catch(() => {
                    dispatch(offersFailureAction())
                })
        }
    }

/**
 * Activate customer offers action
 * @param {ActivateOffersPayload} requestPayload
 * @param {string} lmsProfileId
 * @return {Dispatch}
 */
export const activateCustomerOffers =
    (requestPayload: ActivateOffersPayload, lmsProfileId?: string) =>
    (dispatch: Dispatch): Promise<void> => {
        offersService
            .activateOffer(requestPayload, lmsProfileId)
            .then((offerActivation: { data: OfferActivation }) => {
                const activationUrl = window.location.href.includes(emailActivationUrl)
                if (!activationUrl) {
                    dispatch(fetchCustomerOffers(getOffersPayload, true))
                }
                dispatch(offersActivateSuccessAction(offerActivation.data))
            })
            .catch((error: AxiosError<ErrorResponse>) => {
                dispatch(offersActivateFailureAction(error?.response?.data))
            })
    }

/**
 * Action for targeted offer activation
 * @param {ActivateTargetedOfferPayload} requestPayload
 * @param {boolean} multiOffers
 * @return {Dispatch}
 */
export const activateTargetedOffer =
    (requestPayload: ActivateTargetedOfferPayload, multiOffers: boolean) =>
    (dispatch: Dispatch): Promise<void> => {
        offersService
            .activateTargetedOffer(requestPayload, multiOffers)

            .then((targetedOfferActivation: { data: TargetedOfferActivation[] }) => {
                dispatch(targetedOfferActivateSuccessAction(targetedOfferActivation.data))
                const offersActivateSuccessData = {} as OfferActivation
                const offerArray = [] as ActiveOffer[]
                targetedOfferActivation.data.forEach(targetedOffer => {
                    if (targetedOffer.activated === offerConstants.yes) {
                        const offerValue = {
                            offerCode: targetedOffer.offerCode,
                            activated: targetedOffer.activated,
                        } as ActiveOffer
                        offerArray.push(offerValue)
                        offersActivateSuccessData.offers = offerArray
                    }

                    return offersActivateSuccessData
                })
                if (checkDataLength(offersActivateSuccessData)) {
                    dispatch(offersActivateSuccessAction(offersActivateSuccessData))
                }
            })
            .catch((error: AxiosError<ErrorResponse>) => {
                dispatch(offersActivateFailureAction(error?.response?.data))
            })
    }

/**
 * Action for filtered offered data
 * @param {FilteredOffers} filteredOffers
 * @return {Dispatch}
 */
export const fetchFilteredCustomerOffers = (filteredOffers: FilteredOffers) => (dispatch: Dispatch) => {
    if (filteredOffers?.filteredOffers !== null) {
        dispatch(filteredOffersSuccessAction(filteredOffers))
    } else {
        dispatch(filteredOffersFailureAction())
    }
}

/**
 * Action for sorted filtered offer
 * @param {string} sortingBy
 * @return {Dispatch}
 */
export const updateFilteredOfferSorting = (sortingBy: string) => (dispatch: Dispatch) => {
    dispatch(updateFilteredOffersSortingAction(sortingBy))
}

/**
 * Action for updating filltered offer data
 * @param {Offers[]} filteredOffers
 * @return {Dispatch}
 */
export const updateFilteredOffers = (filteredOffers: Offers[]) => (dispatch: Dispatch) => {
    dispatch(updateFilteredOffersAction(filteredOffers))
}

/**
 * function to get loyalty offers props endpoints
 * @return {string}
 */
const getLoyaltyOffersEndpoint = (): string =>
    document
        ?.querySelector('.loyaltyofferscarousel span[data-component="LoyaltyOffersCarousel"]')
        ?.getAttribute('data-offers') || ''

/**
 * function call to get loyalty offers data props
 * @return {Promise}
 */
export const setLoyaltyOffers =
    () =>
    (dispatch: Dispatch): Promise<void> | void => {
        const payloadEndpoint = getLoyaltyOffersEndpoint()
        if (payloadEndpoint) {
            return httpClient.apiGet(payloadEndpoint, {}, 'AEM_EXP_FRAG').then(data => {
                if (data.data) {
                    dispatch(setLoyaltyOffersSuccessAction(data.data))
                }
            })
        }
    }
