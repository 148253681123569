import { NavigationCategory } from '../SecondaryNavigationWrapper.type'

/**
 * Interface for SecondaryNavigationProps
 * @interface
 */
export interface SecondaryNavigationProps {
    categoriesList: NavigationCategory[]
    secondaryNavAriaLabel?: string
}

export interface SecondaryNavigationMenuProps {
    categoryData: NavigationCategory[]
    secondaryNavAriaLabel?: string
    secondaryNavMenuLabel?: string
    shopAllLabel?: string
}

/**
 * enum for navigation link highlight style
 */
export enum NavigationLinkHighlightStyle {
    standard = 'standard',
    highlight = 'highlight',
    yellow = 'yellow',
    exclusiveNavCatColor = 'exclusiveNavCatColor',
}
