import { PREFIX } from '../../../config'

/**
 * function to calculate menu positon top and height
 * @param {string} elementClassName
 * @return {void}
 */
export const setMenuPosition = (elementClassName: string): void => {
    const sideMenRef = document.querySelector(elementClassName) as HTMLElement
    const headerElement = document.getElementsByClassName(`${PREFIX}-primary-navigation`)[0]
    const headersHeight = Number(headerElement?.clientHeight || 0)
    const infoBannerHeight =
        isInformationBannerVisible() || !getStickyStoreLocatorHeight()
            ? Number(document.getElementsByClassName(`${PREFIX}-information-banner`)[0]?.clientHeight || 0)
            : 0
    const storeLocatorHeight = Number(
        document.getElementsByClassName(`store-locator-pencil-banner`)[0]?.clientHeight || 0,
    )
    const stickyStoreLocatorHeight = getStickyStoreLocatorHeight()
    const browseOnlyBannerHeight = getBrowseOnlyBannerHeight()
    const headerContainerHeight = stickyStoreLocatorHeight
        ? headersHeight
        : infoBannerHeight + headersHeight + storeLocatorHeight
    const headerRect = headerElement?.getBoundingClientRect()
    const offsetTop = browseOnlyBannerHeight ? headerContainerHeight + browseOnlyBannerHeight : headerContainerHeight

    if (sideMenRef) {
        const windowInnerHeight = window.innerHeight
        sideMenRef.style.top = `${offsetTop}px`
        sideMenRef.style.height = `${
            stickyStoreLocatorHeight
                ? windowInnerHeight - headerContainerHeight - stickyStoreLocatorHeight
                : windowInnerHeight - headerRect?.bottom
        }px`
    }
}

/**
 * Returns height of Sticky Store Locator bar.
 * @return { number }
 */
const getStickyStoreLocatorHeight = (): number => {
    return Number(
        document.getElementsByClassName(`${PREFIX}-primary-navigation__sticky_store-locator`)[0]?.clientHeight || 0,
    )
}

/**
 * Returns height of Browse Only Banner.
 * @return { number }
 */
const getBrowseOnlyBannerHeight = (): number => {
    return Number(document.getElementsByClassName(`${PREFIX}-browse-only-information-banner`)[0]?.clientHeight || 0)
}

/**
 * Returns true if information banner exist on view.
 * @return { boolean }
 */
const isInformationBannerVisible = (): boolean => {
    const infoBannerElement = document.getElementsByClassName(`${PREFIX}-information-banner`)
    if (!!infoBannerElement && !!infoBannerElement[0]) {
        const rect = infoBannerElement[0].getBoundingClientRect()
        const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight)
        return !(rect.bottom < 0 || rect.top - viewHeight >= 0)
    }
    return false
}
