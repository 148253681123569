import { ActionType, getType } from 'typesafe-actions'
import { vehiclesDataInterface } from '../models/vehiclesData.interface'
import * as actions from '../actionCreators/vehiclesData.actionCreators'
import { setInitialDropDownsForPreselectedMakeOnly } from '../actionCreators/preselectedVehicle.actionCreators'
import { adaptVehicleResponseToDropdowns } from '../utils/adaptVehicleResponseToDropdowns'
import { resetDropdown } from '../utils/resetDropdown'
import updateDropDownValues from '../utils/updateDropDownValues'
import { initDropdownByOrders } from '../utils/initDropdownByOrders'
import { generateLocalizedDropdowns } from '../utils/generateLocalizedDropdowns'
import { magicNumber } from '@nl/lib'
import { deleteDropdown } from '../utils/deleteDropdown'

export const initialState: vehiclesDataInterface = {
    baseDropdownsKeys: ['vehicleType', 'year', 'make', 'model'],
    dropdowns: [],
    initialDropDownsFetched: false,
    isSavedVehicleChecked: false,
    isFetchInProgress: false,
    removedVehicle: '',
}
type Action = ActionType<typeof actions | typeof setInitialDropDownsForPreselectedMakeOnly>

export const vehiclesDataReducer = (state = initialState, action: Action): vehiclesDataInterface => {
    switch (action.type) {
        case getType(actions.setIsFetchInProgress):
            return { ...state, isFetchInProgress: action.payload }
        case getType(actions.setVehicleChooseResponse):
            const dropdowns = adaptVehicleResponseToDropdowns(action.payload.response, state, action.payload.labels)
            return { ...state, dropdowns, isFetchInProgress: false, initialDropDownsFetched: true }

        case getType(actions.setVehicleBaseAttributesOrder):
            return { ...state, ...initDropdownByOrders(state, action.payload) }
        case getType(actions.setVehicleKnownValues):
            return { ...state, dropdowns: updateDropDownValues(state, action.payload) }
        case getType(actions.setVehicleKnownValuesAndClearOther):
            const keys = Object.keys(action.payload)
            const updatedAndClearedDropdown = updateDropDownValues(state, action.payload)
            updatedAndClearedDropdown.filter(item => !keys.includes(item.id)).forEach(deleteDropdown)
            return { ...state, dropdowns: updatedAndClearedDropdown }
        case getType(actions.setSavedVehicleChecked):
            return { ...state, isSavedVehicleChecked: true }
        case getType(actions.setClearToVehicleAttribute):
            const firstIndexToClear = magicNumber.ONE + state.dropdowns.findIndex(drop => drop.id === action.payload)
            const cutIndex = Math.max(firstIndexToClear, state.baseDropdownsKeys.length)
            const clearedDropdown = state.dropdowns.slice(0, cutIndex)
            clearedDropdown.slice(firstIndexToClear).forEach(resetDropdown)
            return { ...state, dropdowns: clearedDropdown }
        case getType(actions.setDropdownAttributeLocalization):
            const localizedDropdowns = generateLocalizedDropdowns(state, action.payload)

            return { ...state, dropdowns: localizedDropdowns }

        case getType(actions.setRemovedVehicle):
            return { ...state, removedVehicle: action.payload }

        default:
            return state
    }
}
