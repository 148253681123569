import React from 'react'
import PropTypes from 'prop-types'

import { VehiclePropTypes } from './VehicleProductCard.type'
import Price from '../Price'
import { PREFIX } from '../config'
import Rebate from '../Rebate'
import Badges from '../Badges'
import { BannerType } from '../VehicleBanner/VehicleBanner.type'
import { staggeredSkuLength } from './VehicleProduct.constant'
import { isArrayNotEmpty } from '../../utils/isArrayNotEmpty'
import { combineBadges } from '../../utils/badgeUtil'
import { getAccessibilityPriceId } from '../../utils/getAccessibilityId'

/**
 * Product Vehicle information component displays per tire, front, rare and setOfFourTire details
 * @param {VehiclePropTypes} props
 * @return {JSX.Element} returns Product vehicle information component
 */
const VehicleProductInformation: React.FC<VehiclePropTypes> = props => {
    const staggeredState = props.skus?.length === staggeredSkuLength && props.vehicleInformationPresent
    const { message, greyCopyMsg, pdfLink } = props.rebate || {}
    const ProductCardVehicle = `${PREFIX}-product-card-vehicle`

    /**
     * return badges detail for product
     * @param {string[]} badgesList
     * @return {JSX.Element}
     */
    const productBadges = (badgesList: string[]): JSX.Element => {
        return (
            isArrayNotEmpty(badgesList) && (
                <div className={`${PREFIX}-plp-badges`} aria-hidden={true}>
                    <Badges
                        badges={badgesList}
                        badgesAndPriorities={props.badgePriorities}
                        hideDisclaimer={props.hideDisclaimer}
                    />
                </div>
            )
        )
    }

    /**
     * renders front tire code
     * @return {JSX.Element}
     */
    const renderFrontTireCode = (): JSX.Element => {
        return (
            <div
                aria-hidden={true}
                className={`${ProductCardVehicle}--per-tire__code ${
                    !props.badges && !props.frontTireSpecification
                        ? `${ProductCardVehicle}--per-tire__code--top-margin`
                        : ''
                } ${props.isNonStaggeredGrid ? `${ProductCardVehicle}--per-tire__grid-code` : ''}
            `}>
                {props.frontTireCode && `#${props.frontTireCode}`}
            </div>
        )
    }

    /**
     * front tire detail
     * @return {JSX.Element}
     */
    const frontTire = (): JSX.Element => {
        return (
            <div className={`${ProductCardVehicle}--per-tire`}>
                <div className={`${ProductCardVehicle}--per-tire__text`}>{props.vehicleFrontLabel} </div>
                <div className={`${ProductCardVehicle}--per-tire__price-code`}>
                    <Price
                        currentPrice={props.skus[0].currentPrice}
                        originalPrice={props.skus[0].originalPrice}
                        language={props.language}
                        isAutomotiveEachLabel={true}
                        eachLabel={props.eachLabel}
                        nowFromLabel={props.nowFromLabel}
                        saveFromLabel={props.saveFromLabel}
                        wasFromLabel={props.wasFromLabel}
                        fromLabel={props.fromLabel}
                        priceMessage={props.skus[0].priceMessage}
                        displayWasLabel={props.skus[0].displayWasLabel}
                        promotionalPriceLabel={props.promotionalPriceLabel}
                        unitPriceLabel={props.unitPriceLabel}
                        clearancePriceLabel={props.clearancePriceLabel}
                        a11yStrikeOutPrice={props.a11yStrikeOutPrice}
                        a11yStrikeOutPriceRange={props.a11yStrikeOutPriceRange}
                        ariaHidden={props.ariaHidden}
                        a11yTooltipIcon={props.a11yTooltipIcon}
                        a11yCloseIconLabel={props.a11yCloseIconLabel}
                    />
                    {productBadges(combineBadges(props.skus[0].badges, props.productLevelBadges, props.excludeBadges))}
                    {(props.vehicleFitType === BannerType.Fit ||
                        props.vehicleInformationPresent ||
                        props.tireInformationPresent) && (
                        <div
                            aria-hidden={true}
                            className={`${ProductCardVehicle}--per-tire__specification ${
                                !isArrayNotEmpty(props.skus[0].badges) && props.frontTireSpecification
                                    ? `${ProductCardVehicle}--per-tire__specification--extra-space`
                                    : `${ProductCardVehicle}--per-tire__specification--space`
                            }`}
                            dangerouslySetInnerHTML={{ __html: props.frontTireSpecification }}></div>
                    )}
                    {renderFrontTireCode()}
                    {props.frontAvailability}
                </div>
            </div>
        )
    }

    /**
     * renders tire code
     * @return {JSX.Element}
     */
    const renderRearTireCode = (): JSX.Element => {
        return (
            <div
                aria-hidden={true}
                className={`${ProductCardVehicle}--per-tire__code ${
                    !isArrayNotEmpty(props.badges) && !props.rearTireSpecification
                        ? `${ProductCardVehicle}--per-tire__code--top-margin`
                        : ''
                } ${props.isNonStaggeredGrid ? `${ProductCardVehicle}--per-tire__grid-code` : ''}
            `}>
                {props.rearTireCode && `#${props.rearTireCode}`}
            </div>
        )
    }

    /**
     * to hide per title lable checking for staggered type or not
     * @return {string}
     */
    const getStaggeredModifirClass = (): string => {
        return props.isNonStaggeredGrid ? `${ProductCardVehicle}--text-visibility` : ''
    }

    /**
     * per tire or rear tire detail
     * @return {JSX.Element}
     */
    const perTire = (): JSX.Element => {
        return (
            <div className={`${ProductCardVehicle}--per-tire ${getStaggeredModifirClass()}`}>
                {!props.isSRPPage && (
                    <div
                        className={`${ProductCardVehicle}--per-tire__text ${
                            props.isNonStaggeredGrid ? `${ProductCardVehicle}--per-tire__grid-text` : ''
                        }`}>
                        {props.vehicleRearLabel}{' '}
                    </div>
                )}
                <div className={`${ProductCardVehicle}--per-tire__price-code`}>
                    <Price
                        currentPrice={props.rearTireCurrentPrice}
                        language={props.language}
                        isAutomotiveEachLabel={true}
                        eachLabel={props.eachLabel}
                        nowFromLabel={props.nowFromLabel}
                        saveFromLabel={props.saveFromLabel}
                        wasFromLabel={props.wasFromLabel}
                        fromLabel={props.fromLabel}
                        originalPrice={props.rearTireOriginalPrice}
                        discountValue={props.discount}
                        promotionalPriceLabel={props.promotionalPriceLabel}
                        unitPriceLabel={props.unitPriceLabel}
                        displayWasLabel={props.rearTireDisplayWasLabel}
                        clearancePriceLabel={props.clearancePriceLabel}
                        priceMessage={props.priceMessage}
                        a11yStrikeOutPrice={props.a11yStrikeOutPrice}
                        a11yStrikeOutPriceRange={props.a11yStrikeOutPriceRange}
                        ariaHidden={props.ariaHidden}
                        a11yCloseIconLabel={props.a11yCloseIconLabel}
                        a11yTooltipIcon={props.a11yTooltipIcon}
                    />
                    {productBadges(props.badges)}
                    {(props.vehicleFitType === BannerType.Fit ||
                        props.vehicleInformationPresent ||
                        props.tireInformationPresent) &&
                        props.rearTireSpecification && (
                            <div
                                aria-hidden={true}
                                className={`${ProductCardVehicle}--per-tire__specification ${
                                    !isArrayNotEmpty(props.badges) && props.rearTireSpecification
                                        ? `${ProductCardVehicle}--per-tire__specification--extra-space`
                                        : `${ProductCardVehicle}--per-tire__specification--space`
                                }`}
                                dangerouslySetInnerHTML={{ __html: props.rearTireSpecification }}></div>
                        )}
                    {renderRearTireCode()}
                    {staggeredState ? props.rearAvailability : props.perAvailability}
                </div>
            </div>
        )
    }

    /**
     * setOfFour tire detail
     * @return {JSX.Element}
     */
    const setOfFourTire = (): JSX.Element => {
        const displayWasLabel =
            staggeredState && props.skus?.[0]?.displayWasLabel
                ? props.skus?.[0]?.displayWasLabel
                : props.rearTireDisplayWasLabel
        return (
            <div className={`${ProductCardVehicle}--set-of-four`}>
                <div className={`${ProductCardVehicle}--set-of-four__text`}>{props.setOfFourLabel}</div>
                <div className={`${ProductCardVehicle}--set-of-four__product-information`}>
                    <Price
                        currentPrice={props.totalCurrentPrice}
                        language={props.language}
                        feeTitle={props.feeTitle}
                        nowFromLabel={props.nowFromLabel}
                        saveFromLabel={props.saveFromLabel}
                        wasFromLabel={props.wasFromLabel}
                        fromLabel={props.fromLabel}
                        feeDisclaimerType={props.feeDisclaimerType}
                        feeDisclaimerMessage={props.feeDisclaimerMessage}
                        feeDisclaimerTitle={props.feeDisclaimerTitle}
                        displayWasLabel={displayWasLabel}
                        promotionalPriceLabel={props.promotionalPriceLabel}
                        unitPriceLabel={props.unitPriceLabel}
                        clearancePriceLabel={props.clearancePriceLabel}
                        scrollToFooter={props.scrollToFooter}
                        a11yStrikeOutPrice={props.a11yStrikeOutPrice}
                        a11yStrikeOutPriceRange={props.a11yStrikeOutPriceRange}
                        a11yClickToReadFootnote={props.a11yClickToReadFootnote}
                        ariaHidden={props.ariaHidden}
                        a11yTooltipIcon={props.a11yTooltipIcon}
                        a11yCloseIconLabel={props.a11yCloseIconLabel}
                    />
                    {props.rebate && Object.keys(props.rebate).length && (
                        <Rebate
                            title={message}
                            rebateIcon={props.rebateIcon}
                            greyCopyMsg={greyCopyMsg}
                            pdfLink={pdfLink}
                            ariaHidden={true}
                        />
                    )}
                </div>
            </div>
        )
    }

    return (
        <div className={`${ProductCardVehicle}`} id={getAccessibilityPriceId(props.accessibilityId)}>
            {!props.isSRPPage && staggeredState && frontTire()}
            {perTire()}
            {!props.isSRPPage && setOfFourTire()}
        </div>
    )
}

VehicleProductInformation.propTypes = {
    skus: PropTypes.array,
    vehicleRearLabel: PropTypes.string,
    rearTireCode: PropTypes.string,
    rearTireCurrentPrice: PropTypes.any,
    frontTireSpecification: PropTypes.string,
    totalCurrentPrice: PropTypes.any,
    frontTireCode: PropTypes.string,
    setOfFourLabel: PropTypes.string,
    vehicleFrontLabel: PropTypes.string,
    language: PropTypes.string,
    feeTitle: PropTypes.string,
    vehicleFitType: PropTypes.any,
    rebate: PropTypes.any,
    rebateIcon: PropTypes.string,
    eachLabel: PropTypes.string,
    isNonStaggeredGrid: PropTypes.bool,
    rearTireSpecification: PropTypes.string,
    badgePriorities: PropTypes.any,
    badges: PropTypes.array,
    productLevelBadges: PropTypes.array,
    hideDisclaimer: PropTypes.bool,
    nowFromLabel: PropTypes.string,
    saveFromLabel: PropTypes.string,
    wasFromLabel: PropTypes.string,
    fromLabel: PropTypes.string,
    promotionalPriceLabel: PropTypes.string,
    unitPriceLabel: PropTypes.string,
    displayWasLabel: PropTypes.bool,
    clearancePriceLabel: PropTypes.string,
    originalPrice: PropTypes.any,
    discount: PropTypes.any,
    feeDisclaimerType: PropTypes.string,
    feeDisclaimerMessage: PropTypes.string,
    isSRPPage: PropTypes.bool,
    priceMessage: PropTypes.array,
    vehicleInformationPresent: PropTypes.bool,
    feeDisclaimerTitle: PropTypes.string,
    rearTireOriginalPrice: PropTypes.object,
    rearTireDisplayWasLabel: PropTypes.bool,
    tireInformationPresent: PropTypes.bool,
    scrollToFooter: PropTypes.func,
    a11yStrikeOutPrice: PropTypes.string,
    a11yStrikeOutPriceRange: PropTypes.string,
    excludeBadges: PropTypes.array,
    frontAvailability: PropTypes.any,
    rearAvailability: PropTypes.any,
    perAvailability: PropTypes.any,
    a11yClickToReadFootnote: PropTypes.string,
    accessibilityId: PropTypes.string,
    ariaHidden: PropTypes.bool,
    a11yTooltipIcon: PropTypes.string,
    a11yCloseIconLabel: PropTypes.string,
}

export default VehicleProductInformation
