import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { PREFIX } from '../../../../config'
import { StoreHoursListProps } from './StoreHoursList.type'
import {
    Button,
    Table,
    replaceStrWithDynamicVal,
    getCurrentDay,
    getCapitalizeString,
    isArrayNotEmpty,
    libUtils,
    getFormattedPhoneNumber,
    checkNestedProps,
    checkNeedUpdateWeekdayHours,
    magicNumber,
} from '@nl/lib'
import { WeekDayOpeningList } from '../../../../redux/models/storeDetails.interface'
import {
    StoreServiceWithStoreTimezone,
    StoreWithAvailability,
    StoreAddress,
} from '../../../../redux/models/storeDetails.interface'
import { StoreHoursListContent } from '../StoreHoursInfo.type'
import { flyerStoreId } from '../../../../globalConstants'
import { MagicNumber } from '../../../../analytics/analytics.type'
import { commonContentSelector } from '../../../../redux/selectors/commonContent.selectors'

const storeHoursList = `${PREFIX}-store-hours-list`
const detailsBlockClass = `${storeHoursList}__block`
const detailsBlockValueClass = `${storeHoursList}__block__value`
const additionalCopyClass = `${storeHoursList}__additional__copy`
const currentDay = getCurrentDay(libUtils.getLanguage())

let closesInHours = ''
let closedLabeled = ''
let closesAtLabel = ''

/**
 * Generate Span To Be Used In Table Row
 * @param {props} weekDayList
 * @return {{JSX.Element}} For Remaining Time
 */
const getRemainingTime = (weekDayList: WeekDayOpeningList): JSX.Element => {
    const closesIn = weekDayList.closesIn
    const closed = weekDayList.closed
    if (!closed && closesIn && closesIn > 0) {
        return <span> ({replaceStrWithDynamicVal(closesInHours, closesIn)}) </span>
    } else if (!closed && closesIn === 0) {
        return <span> ({replaceStrWithDynamicVal(closesAtLabel, weekDayList.closingTime.formattedHour)}) </span>
    } else if (!closed && closesIn === MagicNumber.MINUS_ONE) {
        return <></>
    } else {
        return <span> ({closedLabeled}) </span>
    }
}

/**
 * Generate Additional Copy To Be Display Inside Accordion
 * @param {Record<string, string>} storeHoursListContent
 *  @param {any} storeDetailObj
 * @return {JSX.Element} returns JSX Element
 */
const getAEMAdditionalCopy = (
    storeHoursListContent: StoreHoursListContent,
    storeDetailObj: StoreWithAvailability | StoreServiceWithStoreTimezone,
): JSX.Element | null => {
    const { id } = storeDetailObj
    const accordionItem = storeHoursListContent.accordionItems?.filter(item => item.labelID === id?.toString())
    const zeroIndex = 0
    if (accordionItem && Object.keys(accordionItem).length > 0 && accordionItem[zeroIndex].additionalCopy) {
        return (
            <span
                className={additionalCopyClass}
                dangerouslySetInnerHTML={{ __html: accordionItem[zeroIndex].additionalCopy as string }}></span>
        )
    }
    return null
}

/**
 * Generate Element To Be Display Inside Accordion
 * @param {StoreWithAvailability | StoreServiceWithStoreTimezone} storeDetailObj
 * @param {StoreHoursListContent} storeHoursListContent
 * @param {boolean} isStoreHours
 * @return {JSX.Element} returns JSX Element
 */
const storeDetailsHtml = (
    storeDetailObj: StoreWithAvailability | StoreServiceWithStoreTimezone,
    storeHoursListContent: StoreHoursListContent,
    isStoreHours: boolean,
): JSX.Element => {
    let phoneNumber: string | number
    let faxNumber = ''
    let additionalCopyAPI = ''
    let fName = ''
    let lName = ''
    if (isStoreHours) {
        const {
            address: { phone, fax, firstName, lastName },
        } = storeDetailObj as StoreWithAvailability
        phoneNumber = phone
        faxNumber = fax
        fName = firstName
        lName = lastName
    } else {
        const { phone, additionalCopy } = storeDetailObj as StoreServiceWithStoreTimezone
        phoneNumber = phone
        additionalCopyAPI = additionalCopy as string
    }
    return isStoreHours ? (
        <>
            <div className={detailsBlockClass}>
                <h4>{storeHoursListContent.contactLabel}:</h4>
                <div className={detailsBlockValueClass}>
                    {fName} {lName}{' '}
                    <span className={`${storeHoursList}__block__dealer`}>{storeHoursListContent.contactPosition}</span>
                </div>
            </div>
            {faxNumber && (
                <div className={detailsBlockClass}>
                    <h4>{storeHoursListContent.faxLabel}:</h4>
                    <div className={detailsBlockValueClass}>{getFormattedPhoneNumber(faxNumber)}</div>
                </div>
            )}
            {phoneNumber && (
                <div className={detailsBlockClass}>
                    <h4>{storeHoursListContent.phoneLabel}:</h4>
                    <div className={detailsBlockValueClass}>{getFormattedPhoneNumber(phoneNumber?.toString())}</div>
                </div>
            )}
        </>
    ) : (
        <>
            {phoneNumber && (
                <div className={detailsBlockClass}>
                    <h4>{storeHoursListContent.phoneLabel}:</h4>
                    <div className={detailsBlockValueClass}>{getFormattedPhoneNumber(phoneNumber?.toString())}</div>
                </div>
            )}
            <div className={`${storeHoursList}__additional`}>
                {getAEMAdditionalCopy(storeHoursListContent, storeDetailObj) || (
                    <span className={additionalCopyClass}>{additionalCopyAPI}</span>
                )}
            </div>
        </>
    )
}

/**
 * Send Email To EmailId
 * @param {props} emailId
 * @return {any} send email to emailId
 */
const sendMail = (emailId: string): any => window.open(`mailto:${emailId}`)

/**
 * Redirect to url flyerCTAURL
 * @param {props} flyerCTAURL
 * @param {string} storeId
 */
const redirectToFlyerCTA = (flyerCTAURL: string, storeId?: number | string) => {
    window.location.href = flyerCTAURL + `?${flyerStoreId}=${String(storeId)}`
}

/**
 * Generate StoreHoursList ComponenT To Be Used In Store Details
 * @param {props} props
 * @return {JSX.Element} returns StoreHoursList component
 */
const StoreHoursList: React.FC<StoreHoursListProps> = ({ ...props }): JSX.Element => {
    const { commonContentAvailable } = useSelector(commonContentSelector)
    const {
        storeService,
        storeDetails,
        storeHoursListContent,
        enableProvideFeedbackEnhancement,
        enableStoreHoursUnavailableMessage,
        storeHourMissingMsg,
        storeHourWeekdayMissingMsg,
        separator,
    } = props
    const isStoreHoursUnavailableMessageEnabled = !!enableStoreHoursUnavailableMessage
    const { holidayMsg, flyerCTALabel, feedbackCTALabel, flyerCTAURL, enableStoreFlyer } = storeHoursListContent
    const [address, setAddress] = useState({} as StoreAddress)
    const storeHoursMissingDays =
        storeDetails &&
        storeDetails?.openingHours.weekDayOpeningList.filter(
            weekday => weekday.closingTime === null || weekday.openingTime === null,
        )

    /**
     * Function to get storeClosed or storeHoursUnavailableMessage To Be Used In Table Row
     * @return {{JSX.Element}}
     */
    const getCloseOrStoreHoursMissingMsg = (): JSX.Element => {
        return <span>{isStoreHoursUnavailableMessageEnabled ? storeHourWeekdayMissingMsg : closedLabeled}</span>
    }

    /**
     * construct store time or unavailable message or closed message based on conditions
     * @param {boolean} isClosedAndHoursAvailable
     * @param {boolean} isCurrentday
     * @param {string} displayTime
     * @param {WeekDayOpeningList} weekDayList
     * @param {boolean} isStoreHoursMissing
     * @return {{JSX.Element}}
     */
    const constructStoreTiming = (
        isClosedAndHoursAvailable: boolean,
        isCurrentday: boolean,
        displayTime: string,
        weekDayList: WeekDayOpeningList,
        isStoreHoursMissing: boolean,
    ): JSX.Element => {
        if (weekDayList.closed && isStoreHoursMissing) {
            return getCloseOrStoreHoursMissingMsg()
        } else if (isClosedAndHoursAvailable || isCurrentday) {
            return (
                <>
                    <span className={`${storeHoursList}__color`}>{displayTime}</span>
                    <span>{getRemainingTime(weekDayList)}</span>
                </>
            )
        } else {
            return <span>{displayTime}</span>
        }
    }

    /**
     * Generate StoreTiming To Be Used In Table Row
     * @param {props} weekDayList
     * @return {{JSX.Element}} For Table Row
     */
    const createStoreTiming = (weekDayList: WeekDayOpeningList): JSX.Element => {
        const { closingTime, openingTime } = weekDayList
        const { formattedHour: closingFormattedHour } = closingTime || {}
        const { formattedHour: openingFormattedHour } = openingTime || {}
        const displayTime = `${openingFormattedHour}${String(separator)}${closingFormattedHour}`
        const isStoreHoursMissing = weekDayList.openingTime === null || weekDayList.closingTime === null
        const isClosedAndHoursAvailable = weekDayList.closed && weekDayList.closingTime && weekDayList.openingTime
        const isCurrentday = currentDay === weekDayList.weekDay.toLowerCase()
        return constructStoreTiming(
            isClosedAndHoursAvailable as boolean,
            isCurrentday,
            displayTime,
            weekDayList,
            isStoreHoursMissing,
        )
    }

    /**
     * Generate Record To Be Used In Table
     * @param {StoreWithAvailability | StoreServiceWithStoreTimezone} storeDtsList
     * @param {boolean} isStoreHoursParam
     * @return {Record<string, unknown>[]} returns Array Of Records
     */
    const createStoreTableData = (
        storeDtsList: StoreWithAvailability | StoreServiceWithStoreTimezone,
        isStoreHoursParam: boolean,
    ): Record<string, unknown>[] => {
        const tableDataArr: Record<string, unknown>[] = []
        let weekDayOpenLists: WeekDayOpeningList[]
        if (isStoreHoursParam) {
            weekDayOpenLists = (storeDtsList as StoreWithAvailability).openingHours?.weekDayOpeningList
        } else {
            weekDayOpenLists = (storeDtsList as StoreServiceWithStoreTimezone).weekDayOpeningList
        }
        if (isArrayNotEmpty(weekDayOpenLists)) {
            weekDayOpenLists.forEach((weekDayList: WeekDayOpeningList) => {
                let cellColor = ''
                if (currentDay.toLowerCase() === weekDayList.weekDay.toLowerCase()) {
                    cellColor = `class=${storeHoursList}__color`
                }
                const keySpan = `<span ${cellColor}>${getCapitalizeString(weekDayList.weekDay)}</span>`
                const weekDayListUpdate = checkNeedUpdateWeekdayHours(storeDtsList, weekDayList) as WeekDayOpeningList
                const tableRowData = {
                    [keySpan]: [createStoreTiming(weekDayListUpdate)],
                }
                tableDataArr.push(tableRowData)
            })
        }

        return tableDataArr
    }

    useEffect(() => {
        if (storeDetails && Object.keys(storeDetails).length > 0) {
            setAddress(storeDetails?.address)
        }
        closesInHours = commonContentAvailable?.storeLocator?.closesInLabel
        closedLabeled = commonContentAvailable?.storeLocator?.closedLabel
        closesAtLabel = commonContentAvailable?.storeLocator?.closesAtLabel
    }, [storeDetails, commonContentAvailable])
    let storeDetailObj: StoreWithAvailability | StoreServiceWithStoreTimezone
    let isStoreHours = false
    if (storeService) {
        storeDetailObj = storeService
        isStoreHours = false
    } else {
        storeDetailObj = storeDetails as StoreWithAvailability
        isStoreHours = true
    }
    const checkEmptyObj = Object.keys(storeDetailObj).length
    const { email } = address
    /**
     * function to render provide feedback CTA
     * @return {JSX.Element}
     */

    const renderProvideFeedbackCTA = (): JSX.Element => {
        return (
            <>
                {email && (
                    <Button
                        id="feedback-btn"
                        type="primary"
                        onClick={() => {
                            if (enableProvideFeedbackEnhancement === 'true') {
                                window.open(
                                    checkNestedProps(
                                        commonContentAvailable,
                                        'globalLinks',
                                        'contactUsPageLink',
                                    ) as string,
                                    '_blank',
                                    'noopener',
                                )
                            } else {
                                sendMail(email)
                            }
                        }}
                        linkSection="Store Details"
                        linkValue="Provide Feedback">
                        {feedbackCTALabel}
                    </Button>
                )}
            </>
        )
    }
    return (
        <>
            {checkEmptyObj && (
                <div className={`${storeHoursList}`}>
                    <h4>{holidayMsg}</h4>
                    {isStoreHoursUnavailableMessageEnabled && storeHoursMissingDays?.length === magicNumber.SEVEN ? (
                        <p className={`${storeHoursList}__unavailableMessage`}>{storeHourMissingMsg}</p>
                    ) : (
                        <Table
                            tableRichText={true}
                            tableType="stripped"
                            headerType="column"
                            tableData={createStoreTableData(storeDetailObj, isStoreHours)}
                        />
                    )}
                    <div className={`${storeHoursList}__details`}>
                        {storeDetailsHtml(storeDetailObj, storeHoursListContent, isStoreHours)}
                        {isStoreHours && enableStoreFlyer && (
                            <Button
                                id="flyer-btn"
                                type="secondary"
                                onClick={() => redirectToFlyerCTA(flyerCTAURL, storeDetails?.id)}>
                                {flyerCTALabel}
                            </Button>
                        )}
                        {renderProvideFeedbackCTA()}
                    </div>
                </div>
            )}
        </>
    )
}

StoreHoursList.propTypes = {
    storeDetails: PropTypes.any,
    storeService: PropTypes.any,
    storeHoursListContent: PropTypes.any,
}

export default StoreHoursList
