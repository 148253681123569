import {
    ProductResponseData,
    ProductSku,
    ProductSkusData,
    ProductSpecifications,
} from './../../redux/models/product.interface'
import { BreadcrumbLinkData } from '../../redux/models/productData.interface'
import { CategoryBreadCrumb } from '../../redux/models/category.interface'
import { MagicNumber } from '../../analytics/analytics.type'
import shopByVehicleAnalytics from '../../analytics/components/shopByVehicleAnalytics'
import { FitmentTypeEnum, VehicleFitType, productWheelTypes } from '../../globalConstants'
import { checkDataLength } from '../Accounts/Addresses/checkDataLength'
import { AutoAttributes, isArrayNotEmpty, Vehicle } from '@nl/lib'
import store from '../../store'
import { getCriticalFitmentProducts } from '../AutomotivePDP/AutomotiveBuyBox/AutomotiveBuyBox.helper'
import { setVehicleFitTypeAction } from '../../redux/actionCreators'
import { CriticalFitment } from '../../../../lib/src/components/ProductGridView/ProductGrid.types'
import getPageType from '../../utils/getPageType'
import { pageTypes, vehicleSuffix } from '../../config'
import { VehicleTypeIds, pdpURLStringCheck, radioIds, specValues } from './Vehicles.constant'
import { VisitedVehiclePLP } from './VehicleSelector.type'
import localStorageService from '../../utils/localStorageService'
import { StorageHelper } from '../../helpers'
import appCacheService from '../../utils/appCacheService'
import { TireType } from '../../redux/models/tireVehicle.interface'
import { DropdownType } from '../../redux/models/vehicleDropdowns.types'

/**
 * Function to return additional param string value
 * @param {DropdownType[]} dropdowns
 * @return {string} return additional param string value
 */
export const getAdditionalParamLabel = (dropdowns: DropdownType[]): string => {
    const additionalParamArr: string[] = []
    Object.values(dropdowns).forEach((dropdown: DropdownType) => {
        additionalParamArr.push(dropdown.label)
    })
    return additionalParamArr.join('-')
}

/**
 * Function to get additional vehicle form dropdown props
 * @param {Record<number, unknown>} addVehicleFormPropsParam
 * @param {Vehicle} defaultVehicle
 * @return {unknown[]} return additional vehicle form dropdown array
 */
export const getAdditionalProps = (addVehicleFormPropsParam: DropdownType[], defaultVehicle?: Vehicle): unknown[] =>
    addVehicleFormPropsParam.filter(
        drop => drop.id !== VehicleTypeIds.vehicleType && !defaultVehicle?.autoAttributes?.[drop.id],
    )

/**
 * Function to return boolean value when it is auto parts
 * @param {boolean | undefined } isFitmentRequired
 * @param {string} productWheelType
 * @return {boolean} return true/false on the basis isAutoPart
 */
export const isAutoPart = (isFitmentRequired: boolean | undefined, productWheelType: string | undefined): boolean => {
    return (
        !!isFitmentRequired &&
        productWheelType?.toUpperCase() !== productWheelTypes.Wheel &&
        productWheelType?.toUpperCase() !== productWheelTypes.Tire &&
        productWheelType?.toUpperCase() !== productWheelTypes.WinterTire
    )
}

/**
 *This function returns analytics on addVehicleForm
 * @param {Vehicle} vehicle
 * @param {boolean} fromBuyBox
 * @param {boolean} updateVehicleForm
 * @param {string} shopByVehicleDisplayed
 * @param {string} partialVehicleDisplayed
 * @param {string} buyBoxLocation
 * @param {string} banner
 */
export const addVehicleFormAnalytics = (
    vehicle: Vehicle,
    fromBuyBox: boolean,
    updateVehicleForm: boolean,
    shopByVehicleDisplayed: string,
    partialVehicleDisplayed: string,
    buyBoxLocation: string,
    banner: string,
): void => {
    const vehicleSelectorLocation = fromBuyBox ? buyBoxLocation : banner
    const actionName = updateVehicleForm ? partialVehicleDisplayed : shopByVehicleDisplayed
    const vehicleInfo = updateVehicleForm ? vehicle : {}
    shopByVehicleAnalytics.shopByAnalytics(vehicleInfo, 'true', undefined, vehicleSelectorLocation, actionName)
}

/**
 * check if PDP is automotive or not
 * @param { string } fitmentTypeCode
 * @param { string } productWheelType
 * @return { boolean }
 */
export const isAutomotivePDP = (fitmentTypeCode?: string, productWheelType?: string): boolean => {
    return Boolean((fitmentTypeCode && fitmentTypeCode !== FitmentTypeEnum.UNIVERSAL_FIT_01) || productWheelType)
}
/**
 * check if PDP is Auto part
 * @param { string } fitmentTypeCode
 * @param { string } productWheelType
 * @return { boolean }
 */
export const isAutoPartPDP = (fitmentTypeCode: string | undefined, productWheelType: string | undefined): boolean => {
    return (
        checkDataLength(fitmentTypeCode) &&
        fitmentTypeCode !== FitmentTypeEnum.UNIVERSAL_FIT_01 &&
        productWheelType?.toUpperCase() !== productWheelTypes.Wheel &&
        productWheelType?.toUpperCase() !== productWheelTypes.Tire &&
        productWheelType?.toUpperCase() !== productWheelTypes.WinterTire
    )
}

/**
 * This function returns if product is tire PDP or not
 * @return { boolean } returns true or false
 */

export const isTirePDP = (productWheelType?: string): boolean =>
    Boolean(
        productWheelType?.toUpperCase() === productWheelTypes.Tire ||
            productWheelType?.toUpperCase() === productWheelTypes.WinterTire,
    )

/**
 * function to return vehicle title string based on year, make and model
 * @param {Vehicle} selectedVehicle
 * @return {string | undefined}
 */
export const generateVehicleTitle = (selectedVehicle: Vehicle): string => {
    const { year, make, model } = selectedVehicle?.autoAttributes || {}
    return [year, make, model].filter(Boolean).map(String).join(' ')
}

export const isStandardTireProduct = (productWheelType?: string): boolean =>
    Boolean(productWheelType?.toUpperCase() === productWheelTypes.Tire)

/**
 * This function returns if product is wheel PDP or not
 * @return { boolean } returns true or false
 */

export const isWheelPDP = (productWheelType?: string): boolean =>
    Boolean(productWheelType?.toUpperCase() === productWheelTypes.Wheel)

/**
 * This function returns if page is wheel or tire PDP
 * @param {string} productWheelType
 * @return { boolean } returns true or false
 */
export const isWheelOrTirePDP = (productWheelType?: string): boolean => {
    return isWheelPDP(productWheelType) || isTirePDP(productWheelType)
}

/**
 * this function will check if list is not present OR shop without vehicle is selected
 * @param {Vehicle[]} list
 * @return { boolean } returns true or false
 */
export const isNoVehicleOrShopWithNoVehicle = (list: Vehicle[]): boolean => {
    return !list.length || (list.length === MagicNumber.ONE && Array.isArray(list[MagicNumber.ZERO]))
}

/**
 * hideVehicleBannerForTireWheel is added  to hide banner in tire and wheel pdp when vehicle is not present
 * no vehicle selected then click shop with no vehicle in autoparts, go to tire/wheel pdp---vehicle selector form should come not banner
 * @param {ProductResponseData} productData
 * @param {Vehicle[]} list
 * @return { boolean } returns true or false
 */
export const hideVehicleBannerForTireWheel = (productData: ProductResponseData, list: Vehicle[]): boolean => {
    return isWheelOrTirePDP(productData?.productWheelType) && isNoVehicleOrShopWithNoVehicle(list)
}

/**
 * function to return true if vehicle banner state needs to be updated
 * @param {boolean} isPDP
 * @param {Vehicle[]} list
 * @param {boolean} productData
 * @param {Vehicle} defaultVehicle
 * @return { boolean } returns true or false
 */
export const renderVehicleBannerState = (
    isPDP: boolean,
    list: Vehicle[],
    productData: ProductResponseData,
    defaultVehicle: Vehicle,
): boolean => {
    return (
        isPDP &&
        (hideVehicleBannerForTireWheel(productData, list) ||
            (list.length === MagicNumber.ONE &&
                !Array.isArray(list[0]) &&
                !Array.isArray(defaultVehicle) &&
                defaultVehicle &&
                Object.values(defaultVehicle?.autoAttributes)?.length <= MagicNumber.THREE))
    )
}

/**
 * Function to set the banner type based on response
 * @param {boolean} isAutomotivePDPOrPLP
 * @param {CriticalFitment} criticalFitmentData
 */
export const setBannerType = (isAutomotivePDPOrPLP: boolean, criticalFitmentData?: CriticalFitment[]): void => {
    if (isAutomotivePDPOrPLP && isArrayNotEmpty(criticalFitmentData)) {
        const fitmentProducts = getCriticalFitmentProducts(criticalFitmentData)
        if (!isArrayNotEmpty(fitmentProducts?.regularFitment) && !isArrayNotEmpty(fitmentProducts?.staggerdFitment)) {
            store.dispatch(setVehicleFitTypeAction(VehicleFitType.NotFit))
        } else if (isArrayNotEmpty(fitmentProducts?.regularFitment)) {
            store.dispatch(setVehicleFitTypeAction(VehicleFitType.Fit))
        } else if (isArrayNotEmpty(fitmentProducts?.staggerdFitment)) {
            if (fitmentProducts?.staggerdFitment.length === MagicNumber.ONE) {
                store.dispatch(setVehicleFitTypeAction(VehicleFitType.NotFit))
            } else if (fitmentProducts?.isStaggered) {
                store.dispatch(setVehicleFitTypeAction(VehicleFitType.Fit))
            }
        }
    } else {
        store.dispatch(setVehicleFitTypeAction(VehicleFitType.NotFit))
    }
}

/**
 * check if given page is automotive
 * @param {ProductResponseData} productData
 * @param {boolean} isFitmentRequired
 * @return {boolean} returns true if current page is automotive
 */
export const checkIsAutomotivePage = (productData: ProductResponseData, isFitmentRequired: boolean): boolean => {
    return (
        isAutomotivePDP(productData?.fitmentTypeCode, productData?.productWheelType) ||
        isFitmentRequired ||
        getPageType() === pageTypes.packageLanding
    )
}

/**
 * function to get categories list
 * @param {BreadcrumbLinkData[]} breadcrumbList
 * @param {boolean} isPDP
 * @return {string[]}
 */
export const getCategoriesList = (
    breadcrumbList: BreadcrumbLinkData[] | CategoryBreadCrumb[],
    isPDP: boolean,
): string[] => {
    const categoriesList =
        (breadcrumbList?.map((breadcrumb: BreadcrumbLinkData | CategoryBreadCrumb) => breadcrumb.label) as string[]) ||
        []
    isPDP && categoriesList.pop() // Remove Product Name from categories list
    return categoriesList
}

/**
 * function return RegExpMatchArray when url contains #store or #gs
 * @return {RegExpMatchArray | null  }
 * */

export const pdpURLDirectAccess = (): RegExpMatchArray | null => {
    return (
        RegExp(pdpURLStringCheck.store).exec(window.location.href) ||
        RegExp(pdpURLStringCheck.gs).exec(window.location.href)
    )
}

/**
 * function to remove hash value from url
 */
export const removeHashValueFromURL = (): void => {
    if (pdpURLDirectAccess()) {
        window.history.replaceState(undefined, document.title, window.location.href.split('#')[0])
    }
}

/**
 * function to remove vehicle parameters from url
 */
export const removeVehicleParamsFromURL = (): void => {
    window.history.pushState(undefined, document.title, `${window.location.href.split(vehicleSuffix)[0]}.html`)
}

/**
 * function to check if autopart withshopwithNoVehicle
 * @param { ProductResponseData } productData
 * @param {Vehicle[]} list
 * @return {boolean}
 */
export const checkIfAutoPartShopWithNoVehicle = (productData: ProductResponseData, list: Vehicle[]): boolean => {
    return (
        isAutoPartPDP(productData?.fitmentTypeCode, productData?.productWheelType) &&
        isArrayNotEmpty(list) &&
        Array.isArray(list[0])
    )
}

/**
 * function to return visitedPLPData object from localStorage
 * @return {VisitedVehiclePLP|undefined}
 */
export const getVisitedPLPData = (): VisitedVehiclePLP | undefined => {
    if (!!localStorageService.getItem(radioIds.visitedVehiclePLP)) {
        return JSON.parse(localStorageService.getItem(radioIds.visitedVehiclePLP)) as VisitedVehiclePLP
    }
}

/**
 * function to reset visitedVehiclePLP object in localStorage
 * @param {string} visitedValue
 */
export const updateVisitedVehiclePLP = (visitedValue: string): void => {
    const visitedPLP = { visited: visitedValue, timestamp: new Date() }
    StorageHelper.storageService.setVisitedPLP(radioIds.visitedVehiclePLP, visitedPLP).catch(err => console.error(err))
}

/**
 * function to getbreadcrumblist data
 * @param { BreadcrumbLinkData[] } breadcrumbList
 * @param { BreadcrumbLinkData[] } productBreadcrumbList
 * @param { BreadcrumbLinkData[] } breadcrumbAuthouredData
 * @return { BreadcrumbLinkData }
 */
export const getBreadcrumbData = (
    breadcrumbList: BreadcrumbLinkData[],
    productBreadcrumbList: BreadcrumbLinkData[],
    breadcrumbAuthouredData: BreadcrumbLinkData[],
): BreadcrumbLinkData[] => {
    if (isArrayNotEmpty(breadcrumbList)) {
        return breadcrumbList
    } else if (isArrayNotEmpty(productBreadcrumbList)) {
        return productBreadcrumbList
    } else {
        return breadcrumbAuthouredData
    }
}

/**
 * Returns 'true' if Vehicle banner is required to be rendered.
 *
 * @param { boolean } fitmentRequired
 * @param { string } pageType
 * @param { boolean } vehicleApiStatus
 * @param { ProductResponseData } productData
 * @return { boolean }
 */
export const isVehicleBannerRequired = (
    fitmentRequired: boolean,
    pageType: string,
    vehicleApiStatus: boolean,
    productData: ProductResponseData,
): boolean => {
    return (
        fitmentRequired ||
        (pageType === pageTypes.packageLanding && vehicleApiStatus) ||
        isAutomotivePDP(productData?.fitmentTypeCode, productData?.productWheelType) ||
        pageType === pageTypes.aoaPage
    )
}

/**
 * function to check if default Vehicle
 * @param { boolean } isDefaultVehicleSet
 * @param { boolean } isTires
 * @param { ProductResponseData } productData
 * @return {boolean}
 */
export const isTireOrDirectAccess = (
    isDefaultVehicleSet?: boolean,
    isTires?: boolean,
    productData?: ProductResponseData,
): boolean => {
    return (
        isAutomotivePDP(productData?.fitmentTypeCode, productData?.productWheelType) &&
        !!(isDefaultVehicleSet || isTires || pdpURLDirectAccess())
    )
}
/**
 * fuction to check spec value
 * @param { string } specValue
 * @return { boolean }
 */
export const checkRaoadRatedSpecValue = (specValue: string): boolean => {
    return specValue.toUpperCase() === specValues.YES_EN || specValue.toUpperCase() === specValues.YES_FR
}

/**
 * Function to return specifications for staggered vehicles
 * @param {ProductSkusData} productSkuData
 * @param {ProductSku[]} productDataSku
 * @return  {ProductSpecifications[]}
 */
const getSpecificationForStaggered = (
    productSkuData: ProductSkusData,
    productDataSku: ProductSku[],
): ProductSpecifications[] => {
    const specificationForStaggered: ProductSpecifications[] = []
    const skusArr: string[] = productSkuData?.skus?.map((skuData: ProductSku) => skuData.code)
    skusArr?.forEach(element => {
        const selectedSku = productDataSku?.find(item => item.code === element)
        selectedSku?.specifications && specificationForStaggered.push(...selectedSku?.specifications)
    })
    return Array.from(new Set(specificationForStaggered))
}

/**
 * Function returns product specifications
 * @param {boolean} isProductLevel
 * @param {ProductSpecifications[]} productDataSpecifications
 * @param {boolean} isStaggered
 * @param {ProductSku[]} productDataSku
 * @param {string} selectedProductCode
 * @param {ProductSkusData} productSkuData
 * @return {ProductSpecifications[] | undefined}
 */
export const getRoadRatedSpecifications = (
    isProductLevel: boolean,
    productDataSpecifications: ProductSpecifications[],
    isStaggered: boolean,
    productDataSku: ProductSku[],
    selectedProductCode: string,
    productSkuData: ProductSkusData,
): ProductSpecifications[] | undefined => {
    const selctedSKuSpecification = productDataSku?.find(item => item.code === selectedProductCode)
    let specifications: ProductSpecifications[] | undefined
    if (isProductLevel) {
        if (checkDataLength(productDataSpecifications)) {
            specifications = productDataSpecifications
        } else {
            specifications = []
        }
    } else {
        if (isStaggered) {
            if (checkDataLength(productDataSpecifications)) {
                specifications = getSpecificationForStaggered(productSkuData, productDataSku)
                    .slice()
                    .concat(productDataSpecifications)
            } else {
                specifications = getSpecificationForStaggered(productSkuData, productDataSku)
            }
        } else {
            if (checkDataLength(productDataSpecifications)) {
                specifications = selctedSKuSpecification?.specifications.slice().concat(productDataSpecifications)
            } else {
                specifications = selctedSKuSpecification?.specifications
            }
        }
    }
    return specifications
}

/**
 * Function to check whether the vehicle is selected.
 * @return {boolean}
 */
export const isVehicleSelected = (): boolean => {
    const vehicles = appCacheService.getVehicles()
    if (vehicles) {
        // If `Shop With No Vehicle` option is selected, then the first element of `vehicles` array will be an empty array
        return !Array.isArray(vehicles[0])
    }
    return false
}

/**
 * Function to check additional attributes of update vehicle.
 * @param {Array} list
 * @param {boolean} isTires
 * @return {boolean}
 */
export const isAdditionalAttributes = (list: Array<any>, isTires: boolean): boolean => {
    return !Array.isArray(list[0]) && !isTires
}

/**
 * Function to check if tireData exists. If tireData exists return it otherwise return empty.
 * @param {TireType} tiresData
 * @return {string}
 */
export const tireDetails = (tiresData: TireType | null) => {
    return tiresData
        ? `${tiresData?.selectedDiameterValue}" ${tiresData?.selectedSectionWidth}/${tiresData?.selectedAspectRatio}R${tiresData?.selectedDiameterValue}`
        : ''
}

/**
 * Function to get auto attributes
 * @param { AutoAttributes } autoAttributes
 * @return {object}
 */
export const getAutoAttributes = (autoAttributes: AutoAttributes) => {
    return {
        year: autoAttributes?.year || '',
        make: autoAttributes?.make || '',
        model: autoAttributes?.model || '',
    }
}

/**
 * Verify if user is shopping by tire size
 * @param { TireType } tireSizeData
 * @param { productWheelTypes } productWheelType
 * @return { boolean } returns true or false
 */
export const isShoppingByTireSize = (tireSizeData: TireType | null, productWheelType: string | undefined): boolean => {
    return !!(tireSizeData?.selectedSectionWidth && isTirePDP(productWheelType))
}
