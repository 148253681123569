export const sortFacetType = 'sort' // sortFacetType value for Facets
export const categoryType = 'breadcrumb' // categoryType value for Facets
export const maxFourFactes = 4 // maxFacets value for Facets
export const maxFiveFacets = 5
export const category = 'category'
export const premiumFilter = 'premium'
export const filterEnabled = 'enabled'
export const filterCleared = 'cleared'
export const facetsFont = '1rem Lato-bold, sans-serif'
export const hotSaleUrl = { en: '/promotions/hot-sale', fr: '/promotions/offres-speciales' }

// below constants are taken from _variables.scss
export const containerMaxWidthMd = 1480
export const containerMaxWidthLg = 1920
export const filterMargins = 64 // 12px x 2 - paddings, 20px - arrow, 8px - arrow margin, 12px - button right margin
export const labelsBounds = 40
export const windowPaddingBounds = 48
