import React, { useRef, useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { BalanceCheckerScreenPropsType, ScreenIDType } from './BalanceChecker.type'
import {
    ReactModal,
    Icon,
    useClickOutsideClose,
    balanceCheckerScreen,
    SuccessScreen,
    ErrorScreen,
    BalanceScreen,
    checkDataLength,
    getCardErrorCheck,
    replaceStrWithDynamicVal,
    getBalance,
    validateCardInput,
    errorCodes,
    extractKeyValues,
    getErrorCodeMessage,
} from '@nl/lib'
import { PREFIX } from '../../config'
import { commonContentAvailableSelector } from '../../redux/selectors/commonContent.selectors'
import { userProfileDataSelector } from '../../redux/selectors/userProfile.selectors'
import { fetchCTMoneyBalance, resetCTMoneyBalance } from '../../redux/actions/ctMoneyBalance.action'
import { ctMoneyBalanceDataSelector, ctMoneyBalanceErrorSelector } from '../../redux/selectors/ctMoneyBalance.selectors'
import { loyaltyCardMaxLength, loyaltyCardPrefix, loyaltyCardRegex } from '../LinkRewards/LinkRewards.constants'
import { showSpinner } from '../../redux/actions'

const BalanceCheckerModalScreen: React.FC<{
    screenId: string
    openPopup: boolean
    closeHandler: () => void
    data: BalanceCheckerScreenPropsType
}> = ({ ...props }): JSX.Element => {
    const { screenId, openPopup, closeHandler, data } = props
    const { balanceScreen, successScreen, errorScreen } = balanceCheckerScreen as Record<string, string>
    const initialRenderComponent = { screenID: screenId }
    const {
        balanceCheckerImage,
        balanceCheckerImageAltText,
        balanceCheckerTitle,
        balanceCheckerDescription,
        cardNumberLabel,
        cardImage,
        cardImageAltText,
        checkBalanceCtaLabel,
        checkBalanceCtaAllyLabel,
        cancelCtaLabel,
        cancelCtaAllyLabel,
        ctMoneyLabel,
        linkCardText,
        ctaLabelGuestUser,
        ctaUrlGuestUser,
        ctaLinkTargetGuestUser,
        ctaAllyLabelGuestUser,
        ctaUrlLoggedInUser,
        ctaLinkTargetLoggedInUser,
        ctaAllyLabelLoggedInUser,
        checkBalanceOnAnotherCardCtaLabel,
        checkBalanceOnAnotherCardCtaAllyLabel,
        multipleAttemptFailureTitle,
        multipleAttemptFailureDescription,
        tryAgainCtaLabel,
        tryAgainCtaAllyLabel,
        emptyCardErrorMsg,
        invalidCardErrorMsg,
        partialCardErrorMsg,
        cardLinkedErrorMsg,
        ineligibleForBalanceCheckTitle,
        ineligibleForBalanceCheckDescription,
        cardMergeProcessTitle,
        cardMergeProcessDescription,
    } = data

    const commonContentAvailable = useSelector(commonContentAvailableSelector)
    const userProfileData = useSelector(userProfileDataSelector)
    const ctMoneyBalanceData = useSelector(ctMoneyBalanceDataSelector)
    const ctMoneyErrorResponse = useSelector(ctMoneyBalanceErrorSelector)
    const balanceCheckerModalRef = useRef<HTMLDivElement>(null)
    const [errorMessage, setErrorMessage] = useState<string | undefined>('')
    const [selectedCardNumber, setSelectedCardNumber] = useState('')
    const [isValidationCheck, setIsValidationCheck] = useState(false)
    const [balanceCheckScreenType, setBalanceCheckScreenType] = useState(initialRenderComponent)
    const [errorScreenProps, setErrorScreenProps] = useState({})

    const isSignedInUser = Boolean(userProfileData && Object.keys(userProfileData).length)

    const [[a11yCloseIconLabel], [errorImage, errorImageAltText]] = extractKeyValues(commonContentAvailable, [
        {
            accessibility: ['a11yCloseIconLabel'],
        },
        {
            general: ['errorImage', 'errorImageAltText'],
        },
    ]) as string[][][]

    const dispatch = useDispatch()

    /**
     * Function to reset the input field and states associated with it
     * @return {void}
     */
    const resetInputFieldValues = useCallback(() => {
        setIsValidationCheck(false)
        setErrorMessage('')
        setBalanceCheckScreenType({ screenID: balanceScreen })
    }, [setErrorMessage, setIsValidationCheck, balanceScreen])

    /**
     * Try Again button handler to change modal to balance screen
     * @return {void}
     */
    const tryAgainHandler = useCallback(() => {
        dispatch(resetCTMoneyBalance())
        resetInputFieldValues()
    }, [dispatch, resetInputFieldValues])

    const errorBCProps = useCallback(
        (title: string, desc: string) => {
            return {
                errorImage,
                errorImgAltText: errorImageAltText,
                errorRichText: `${title ? title : ''}${desc}`,
                btnType: 'primary_red',
            }
        },
        [errorImage, errorImageAltText],
    )

    /**
     * Based on the error codes, display inline or modal screen errors
     * @return {void}
     */
    const setErrorRedirect = useCallback(
        (message: string) => {
            if (message === errorCodes.error00055 || message === errorCodes.error00030) {
                const newErrorProps = {
                    ...errorBCProps(multipleAttemptFailureTitle, multipleAttemptFailureDescription),
                    tryAgainCTALabelText: tryAgainCtaLabel,
                    tryAgainCTAAllyText: tryAgainCtaAllyLabel,
                    onClickTryAgain: tryAgainHandler,
                }
                setBalanceCheckScreenType({ screenID: errorScreen })
                setErrorScreenProps(newErrorProps)
            } else if (message === errorCodes.error00130) {
                setBalanceCheckScreenType({ screenID: errorScreen })
                setErrorScreenProps(errorBCProps(multipleAttemptFailureTitle, multipleAttemptFailureDescription))
            } else if (message === errorCodes.error00140) {
                setBalanceCheckScreenType({ screenID: errorScreen })
                setErrorScreenProps(errorBCProps(ineligibleForBalanceCheckTitle, ineligibleForBalanceCheckDescription))
            } else if (message === errorCodes.error00049) {
                setBalanceCheckScreenType({ screenID: errorScreen })
                setErrorScreenProps(errorBCProps(cardMergeProcessTitle, cardMergeProcessDescription))
            } else {
                setErrorMessage(message)
            }
        },
        [
            errorScreen,
            multipleAttemptFailureTitle,
            multipleAttemptFailureDescription,
            tryAgainCtaAllyLabel,
            tryAgainCtaLabel,
            tryAgainHandler,
            ineligibleForBalanceCheckTitle,
            ineligibleForBalanceCheckDescription,
            cardMergeProcessTitle,
            cardMergeProcessDescription,
            errorBCProps,
        ],
    )

    /**
     * useEffect to change modal to success screen or display an error message based on api response
     */
    useEffect(() => {
        if (checkDataLength(ctMoneyBalanceData)) {
            setBalanceCheckScreenType({ screenID: successScreen })
        } else if (checkDataLength(ctMoneyErrorResponse)) {
            const getMessage = getErrorCodeMessage(ctMoneyErrorResponse?.errCode, {
                errorMsg: invalidCardErrorMsg,
                redirectError: cardLinkedErrorMsg,
            })
            setErrorRedirect(getMessage)
        }
    }, [
        ctMoneyBalanceData,
        ctMoneyErrorResponse,
        successScreen,
        invalidCardErrorMsg,
        cardLinkedErrorMsg,
        setErrorRedirect,
    ])

    /**
     * Checking empty field and length validations in balance input field
     * @return {void}
     */
    const cardErrorCheck = useCallback(() => {
        const validateErrorMessage = validateCardInput(
            selectedCardNumber,
            partialCardErrorMsg,
            emptyCardErrorMsg,
            loyaltyCardMaxLength,
            loyaltyCardRegex,
        )
        setErrorMessage(validateErrorMessage)
    }, [partialCardErrorMsg, emptyCardErrorMsg, selectedCardNumber])

    useEffect(() => {
        cardErrorCheck()
    }, [cardErrorCheck])

    /**
     * Closing the modal should also reset the input field states and rootstate associated with balance checker
     * @return {void}
     */
    const closeModalHandler = useCallback(() => {
        dispatch(resetCTMoneyBalance())
        resetInputFieldValues()
        setSelectedCardNumber('')
        closeHandler()
    }, [dispatch, resetInputFieldValues, closeHandler])

    /**
     * Get Balance Handler to fetch ctMoneyBalance from api
     * @return {void}
     */
    const getCTMoneyBalanceHandler = () => {
        const cardNumber = (loyaltyCardPrefix + selectedCardNumber).replace(/\s/g, '')
        setIsValidationCheck(true)
        dispatch(resetCTMoneyBalance())
        if (selectedCardNumber && !getCardErrorCheck(selectedCardNumber, loyaltyCardMaxLength, loyaltyCardRegex)) {
            dispatch(showSpinner(true))
            dispatch(fetchCTMoneyBalance(cardNumber))
        } else if (!selectedCardNumber) {
            setErrorMessage(emptyCardErrorMsg)
        }
    }

    /**
     * Clicking link rewards button should redirect the user to a url based on auth status
     */
    const linkRewardsHandler = () => {
        window.location.href = isSignedInUser ? ctaUrlLoggedInUser : ctaUrlGuestUser
    }

    /**
     * Check Balance on another card handler switches modal to balance screen and resets input field
     */
    const checkBalanceOnAnotherCardHandler = () => {
        dispatch(resetCTMoneyBalance())
        resetInputFieldValues()
        setSelectedCardNumber('')
    }

    useClickOutsideClose(balanceCheckerModalRef, closeModalHandler, true, true)

    const balanceScreenProps = {
        balanceScreenImg: {
            image: balanceCheckerImage,
            imageAlt: balanceCheckerImageAltText,
        },
        balanceCheckerTitle,
        balanceCheckerDescription,
        cardNumberLabel,
        cardImage,
        cardImageAltText,
        ctaList: [
            {
                a11yLabel: checkBalanceCtaAllyLabel,
                label: checkBalanceCtaLabel,
                type: 'primary_red',
                btnFunc: getCTMoneyBalanceHandler,
            },
            {
                a11yLabel: cancelCtaAllyLabel,
                label: cancelCtaLabel,
                type: 'tertiary',
                btnFunc: closeModalHandler,
            },
        ],
        cardNumberError: errorMessage,
        selectedCardNumber,
        setSelectedCardNumber,
        isValidationCheck,
    }

    const successCTAList = {
        a11yLabel: isSignedInUser ? ctaAllyLabelLoggedInUser : ctaAllyLabelGuestUser,
        label: ctaLabelGuestUser,
        target: isSignedInUser ? ctaLinkTargetLoggedInUser : ctaLinkTargetGuestUser,
    }

    const successBCProps = {
        successLogo: {
            image: balanceCheckerImage,
            imageAlt: balanceCheckerImageAltText,
        },
        successMessageHeader: balanceCheckerTitle,
        labelValueArray: [
            {
                label: replaceStrWithDynamicVal(ctMoneyLabel, getBalance(ctMoneyBalanceData?.ctMoneyBalance)),
            },
        ],
        successScreenContent: linkCardText,
        ctaList: [
            {
                a11yLabel: successCTAList.a11yLabel,
                label: successCTAList.label,
                target: successCTAList.target,
                btnFunc: linkRewardsHandler,
                type: 'primary_red',
            },
            {
                a11yLabel: checkBalanceOnAnotherCardCtaAllyLabel,
                label: checkBalanceOnAnotherCardCtaLabel,
                btnFunc: checkBalanceOnAnotherCardHandler,
                type: 'tertiary',
            },
        ],
    }

    /**
     * Returns the JSX Element based on screen id
     *  @param {ScreenIDType} activeScreen
     *  @return {JSX-Element}
     */
    const activeScreenSelection = (activeScreen: ScreenIDType): JSX.Element => {
        switch (activeScreen.screenID) {
            case balanceScreen: {
                return <BalanceScreen data={balanceScreenProps} />
            }
            case successScreen: {
                return <SuccessScreen data={successBCProps} />
            }
            case errorScreen: {
                return <ErrorScreen data={errorScreenProps} />
            }
            default:
                return <></>
        }
    }
    return (
        <ReactModal closeHandler={closeModalHandler} isOpen={openPopup} ariaModal={true}>
            <div className={`${PREFIX}-balance-checker`} ref={balanceCheckerModalRef}>
                <div className={`${PREFIX}-confirmation-modal`}>
                    <div className={`${PREFIX}-confirmation-modal__close-container`}>
                        <button
                            className={`${PREFIX}-confirmation-modal__close-btn`}
                            aria-label={a11yCloseIconLabel}
                            onClick={closeModalHandler}>
                            <Icon type="ct-close" size="lg" />
                        </button>
                    </div>
                </div>
                <div className={`${PREFIX}-balance-checker-screen`}>
                    {activeScreenSelection(balanceCheckScreenType)}
                </div>
            </div>
        </ReactModal>
    )
}

BalanceCheckerModalScreen.propTypes = {
    data: PropTypes.shape({
        balanceCheckerImage: PropTypes.string.isRequired,
        balanceCheckerImageAltText: PropTypes.string.isRequired,
        balanceCheckerTitle: PropTypes.string.isRequired,
        balanceCheckerDescription: PropTypes.string.isRequired,
        cardNumberLabel: PropTypes.string.isRequired,
        cardImage: PropTypes.string.isRequired,
        cardImageAltText: PropTypes.string.isRequired,
        checkBalanceCtaLabel: PropTypes.string.isRequired,
        checkBalanceCtaAllyLabel: PropTypes.string.isRequired,
        cancelCtaLabel: PropTypes.string.isRequired,
        cancelCtaAllyLabel: PropTypes.string.isRequired,
        ctMoneyLabel: PropTypes.string.isRequired,
        linkCardText: PropTypes.string.isRequired,
        ctaLabelGuestUser: PropTypes.string.isRequired,
        ctaUrlGuestUser: PropTypes.string.isRequired,
        ctaLinkTargetGuestUser: PropTypes.string.isRequired,
        ctaAllyLabelGuestUser: PropTypes.string.isRequired,
        ctaUrlLoggedInUser: PropTypes.string.isRequired,
        ctaLinkTargetLoggedInUser: PropTypes.string.isRequired,
        ctaAllyLabelLoggedInUser: PropTypes.string.isRequired,
        checkBalanceOnAnotherCardCtaLabel: PropTypes.string.isRequired,
        checkBalanceOnAnotherCardCtaAllyLabel: PropTypes.string.isRequired,
        multipleAttemptFailureTitle: PropTypes.string.isRequired,
        multipleAttemptFailureDescription: PropTypes.string.isRequired,
        tryAgainCtaLabel: PropTypes.string.isRequired,
        tryAgainCtaAllyLabel: PropTypes.string.isRequired,
        emptyCardErrorMsg: PropTypes.string.isRequired,
        partialCardErrorMsg: PropTypes.string.isRequired,
        invalidCardErrorMsg: PropTypes.string.isRequired,
        cardLinkedErrorMsg: PropTypes.string,
        ineligibleForBalanceCheckTitle: PropTypes.string,
        ineligibleForBalanceCheckDescription: PropTypes.string,
        cardMergeProcessTitle: PropTypes.string,
        cardMergeProcessDescription: PropTypes.string,
    }).isRequired,
    screenId: PropTypes.string.isRequired,
    openPopup: PropTypes.bool.isRequired,
    closeHandler: PropTypes.func.isRequired,
}

export default BalanceCheckerModalScreen
