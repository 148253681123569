import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { PREFIX } from '../config'
import GridCard from '../ProductReusableCard'
import VehicleProductCard from '../VehicleProductCard'
import { ProductCardType, propTypes } from './ProductGrid.types'
import SkeletonComponent from '../Skeleton/SkeletonComponent'
import { replaceStrWithDynamicVal, addDynamicToken } from '../../utils/replaceStrWithDynamicVal'
import { isArrayNotEmpty } from '../../utils/isArrayNotEmpty'
import { getFormattedPriceValue } from '../ProductReusableCard/instanceCheckingUtil'
import { magicNumber } from '../../utils'
import { getAccessibilityId } from '../../utils/getAccessibilityId'
import { ProductSku } from '../VariantsWrapper/VariantsWrapper.type'
import { isAtleastOneParamValid } from '../../utils/validParams'

/**
 * ProductGridView component
 * @return {JSX.Element} returns GridCard and Grid view components
 */
const GridView: React.FC<propTypes> = ({
    path,
    productProps,
    ProductCardData,
    productCardClick,
    redirectAfterProductCardClick,
    viewType,
    badgePriority,
    a11yStrikeOutPrice,
    a11yStrikeOutPriceRange,
    inclCoreChargesLbl,
    plusCoreChargesLbl,
    coreChargesTooltipTitle,
    coreChargesTooltipParagraph,
    a11yTooltipIcon,
    language,
    a11yCloseIconLabel,
    isProductCardLoading,
    skeletonItems,
    rebateIcon,
    isFitmentRequired,
    setOfFourLabel,
    perTireLabel,
    vehicleFrontLabel,
    vehicleRearLabel,
    roadRatedLabel,
    vehicleFitType,
    eachLabel,
    nowFromLabel,
    saveFromLabel,
    wasFromLabel,
    fromLabel,
    isAutoPartPlp,
    vehicleList = null,
    productWheelType,
    promotionalPriceLabel,
    unitPriceLabel,
    clearancePriceLabel,
    isSRPPage,
    vehicleInformationPresent,
    tireInformationPresent,
    scrollToFooter,
    isWheelOrTirePDP,
    partNumberLabel,
    inStockLabel,
    inStockOnlineLabel,
    outOfStockLabel,
    inStorePurchaseLabel,
    inStockAisleLabel,
    checkAvailabilityLabel,
    checkOtherStoresLabel,
    exclude,
    thresholdValue,
    overridePriceHeight,
    a11yReviewRating,
    productCardSpecifications,
    isUrgentLowStockLabel,
    isWheelOrTireFunc,
    isAutomotiveProduct,
    imageDataComponentName,
    returnPolicy,
    attributePositionLabel,
    suggestedQuantityLabel,
    attributeUsageLabel,
    isLazyRequire,
    saleEndDaySoonMessage,
    isColourSwatchesActive,
    saleMessageRules,
    saleEndDisableShift,
    a11yVariantSelected,
    a11yVariantUnSelected,
    a11yClickToReadFootnote,
    pageType,
}) => {
    /**
     * renderProductCardSkeleton returns  react loading skeleton for ProductCart
     * @param { number } skeletonCount gives number of skeleton grid
     * @return {JSX.Element}
     */

    const renderProductCardSkeleton = (skeletonCount: number): JSX.Element => {
        return (
            <>
                {[...Array<unknown>(skeletonCount)].map((index: number) => (
                    <li className={`${PREFIX}-product__content`} key={index}>
                        <SkeletonComponent skeletonClass={`${PREFIX}-product-card__grid-card-skeleton`} />
                    </li>
                ))}
            </>
        )
    }

    /**
     * Function to check skus availability
     * @return {boolean}
     */
    const checkSkusAvailability = (): boolean => {
        return Boolean(ProductCardData?.filter(item => item.skus?.length > magicNumber.ZERO).length)
    }

    /**
     * generateAccessibilityId. When skuId and skus[] is empty then we are using pcode for accessibilityId - auto without vehicle info.
     * @param { ProducSkus[] } skus
     * @param { skuId } string
     * @param { code } string
     * @return {string}
     */

    const generateAccessibilityId = (skus: ProductSku[], skuId: string, code: string): string => {
        if (skus?.[0]?.code) return getAccessibilityId(pageType, skus?.[0].code)
        else if (skuId) return getAccessibilityId(pageType, skuId)
        else return getAccessibilityId(pageType, code)
    }

    return (
        <ul className={`${PREFIX}-product__${viewType}-view ${PREFIX}-product__${viewType}-items`}>
            <>
                {ProductCardData.map((value: ProductCardType, idx: number) => {
                    const singleSkuCurrentPrice = value.skus?.[magicNumber.ZERO]?.currentPrice
                    const singleSkuOriginalPrice = value.skus?.[magicNumber.ZERO]?.originalPrice
                    const isSingleSkuPricesAvailable = () =>
                        value.skus?.length === magicNumber.ONE &&
                        isAtleastOneParamValid(!!singleSkuCurrentPrice, !!singleSkuOriginalPrice)
                    const singleSkuPrices = isSingleSkuPricesAvailable()
                        ? {
                              currentPrice: singleSkuCurrentPrice,
                              originalPrice: singleSkuOriginalPrice,
                          }
                        : {}

                    const {
                        type,
                        title,
                        code,
                        brand,
                        options,
                        rating,
                        ratingsCount,
                        timeTestedPrice,
                        price,
                        priceShortMessage,
                        priceLongMessage,
                        longDescription,
                        packagePrice,
                        badges,
                        salePrice,
                        discount,
                        onlineOnly,
                        inStock,
                        coreCharge,
                        coreChargeIncluded,
                        images,
                        featureBullets,
                        skus,
                        originalPrice,
                        currentPrice,
                        displayWasLabel,
                        feeMessages,
                        priceMessage,
                        rebate,
                        categoryLevel,
                        roadRating,
                        specifications,
                        partNumber,
                        fitmentNotes,
                        fitmentTypeCode,
                        sellable,
                        orderable,
                        url,
                        isUrgentLowStock,
                        totalCurrentPrice,
                        skuId,
                    } = { ...value, ...singleSkuPrices } as unknown as ProductCardType
                    const feeMessage = isArrayNotEmpty(feeMessages) ? feeMessages?.[0] : {}
                    const isWheelOrTire = isWheelOrTireFunc && isWheelOrTireFunc(value?.productWheelType)
                    const isAutomotiveProductFlag =
                        isAutomotiveProduct && isAutomotiveProduct(fitmentTypeCode, value.productWheelType)

                    /** for tire and wheel fulfilment will be displayed from the sku level */

                    const fulfillment =
                        isWheelOrTire && vehicleInformationPresent
                            ? skus.length === magicNumber.TWO
                                ? null
                                : skus?.[0]?.fulfillment
                            : value?.fulfillment
                    return (
                        <Fragment key={`product-content-${idx}`}>
                            {
                                <li key={idx} className={`${PREFIX}-product__content`} data-testid="product-grids">
                                    {!isFitmentRequired ? (
                                        <GridCard
                                            product={value}
                                            productProps={{
                                                ...productProps,
                                                fulfillment,
                                                sellable,
                                                orderable,
                                            }}
                                            productCardClick={productCardClick}
                                            redirectAfterProductCardClick={redirectAfterProductCardClick}
                                            path={path}
                                            idx={idx}
                                            cardType={viewType}
                                            title={title}
                                            type={type}
                                            code={skuId}
                                            brand={brand}
                                            rating={rating}
                                            ratingsCount={ratingsCount}
                                            timeTestedPrice={timeTestedPrice}
                                            price={price}
                                            salePrice={salePrice}
                                            discount={discount}
                                            priceShortMessage={priceShortMessage}
                                            priceLongMessage={priceLongMessage}
                                            longDescription={longDescription}
                                            inStock={inStock}
                                            onlineOnly={onlineOnly}
                                            packagePrice={packagePrice}
                                            badgePriorities={badgePriority}
                                            badges={badges}
                                            a11yStrikeOutPrice={a11yStrikeOutPrice}
                                            a11yStrikeOutPriceRange={a11yStrikeOutPriceRange}
                                            plusCoreChargesLbl={plusCoreChargesLbl}
                                            inclCoreChargesLbl={inclCoreChargesLbl}
                                            coreCharge={coreCharge}
                                            coreChargeIncluded={coreChargeIncluded}
                                            coreChargesTooltipTitle={coreChargesTooltipTitle}
                                            coreChargesTooltipParagraph={coreChargesTooltipParagraph}
                                            a11yTooltipIcon={a11yTooltipIcon}
                                            images={images}
                                            featureBullets={featureBullets}
                                            feeTitle={
                                                feeMessage?.value
                                                    ? replaceStrWithDynamicVal(
                                                          addDynamicToken(feeMessage?.feeTitle, '$x'),
                                                          getFormattedPriceValue(language, feeMessage?.value),
                                                      )
                                                    : ''
                                            }
                                            feeDisclaimerTitle={feeMessage?.feeDisclaimerTitle}
                                            feeDisclaimerMessage={feeMessage?.feeDisclaimerMessage}
                                            options={options}
                                            skus={skus}
                                            originalPrice={originalPrice}
                                            currentPrice={currentPrice}
                                            displayWasLabel={displayWasLabel}
                                            language={language}
                                            a11yCloseIconLabel={a11yCloseIconLabel}
                                            priceMessage={priceMessage}
                                            rebateIcon={rebateIcon}
                                            rebate={rebate}
                                            scrollToFooter={scrollToFooter}
                                            nowFromLabel={nowFromLabel}
                                            saveFromLabel={saveFromLabel}
                                            wasFromLabel={wasFromLabel}
                                            fromLabel={fromLabel}
                                            hideDisclaimer={!isArrayNotEmpty(options)}
                                            feeDisclaimerType={feeMessage?.type}
                                            inStockLabel={inStockLabel}
                                            inStockOnlineLabel={inStockOnlineLabel}
                                            outOfStockLabel={outOfStockLabel}
                                            inStorePurchaseLabel={inStorePurchaseLabel}
                                            inStockAisleLabel={inStockAisleLabel}
                                            checkAvailabilityLabel={checkAvailabilityLabel}
                                            checkOtherStoresLabel={checkOtherStoresLabel}
                                            overridePriceHeight={overridePriceHeight}
                                            promotionalPriceLabel={promotionalPriceLabel}
                                            unitPriceLabel={unitPriceLabel}
                                            clearancePriceLabel={clearancePriceLabel}
                                            thresholdValue={thresholdValue}
                                            a11yReviewRating={a11yReviewRating}
                                            url={url}
                                            isUrgentLowStock={isUrgentLowStock}
                                            isUrgentLowStockLabel={isUrgentLowStockLabel}
                                            productWheelType={value.productWheelType}
                                            isWheelOrTirePDP={isWheelOrTire}
                                            eachLabel={eachLabel}
                                            imageDataComponentName={imageDataComponentName}
                                            returnPolicy={returnPolicy}
                                            isAutomotiveProduct={isAutomotiveProductFlag}
                                            isScrollingRequired={true}
                                            isLazyRequire={isLazyRequire}
                                            productDataId={code}
                                            saleEndDaySoonMessage={saleEndDaySoonMessage}
                                            isColourSwatchesActive={isColourSwatchesActive}
                                            saleMessagesRules={saleMessageRules}
                                            saleEndDisableShift={saleEndDisableShift}
                                            a11yVariantSelected={a11yVariantSelected}
                                            a11yVariantUnSelected={a11yVariantUnSelected}
                                            accessibilityId={generateAccessibilityId(skus, skuId, code)}
                                        />
                                    ) : (
                                        <VehicleProductCard
                                            product={value}
                                            productProps={{
                                                ...productProps,
                                                fulfillment,
                                                sellable,
                                                orderable,
                                            }}
                                            idx={idx}
                                            productCardClick={productCardClick}
                                            redirectAfterProductCardClick={redirectAfterProductCardClick}
                                            cardType={viewType}
                                            title={title}
                                            code={code}
                                            brand={brand}
                                            rating={rating}
                                            images={images}
                                            ratingsCount={ratingsCount}
                                            showRatingSection={true}
                                            skus={skus}
                                            currentPrice={currentPrice}
                                            language={language}
                                            feeTitle={feeMessage?.feeTitle}
                                            rebateIcon={rebateIcon}
                                            rebate={rebate}
                                            tireCategory={categoryLevel}
                                            isFitmentRequired={isFitmentRequired}
                                            setOfFourLabel={setOfFourLabel}
                                            perTireLabel={perTireLabel}
                                            vehicleFrontLabel={vehicleFrontLabel}
                                            vehicleRearLabel={vehicleRearLabel}
                                            roadRatedLabel={roadRatedLabel}
                                            vehicleFitType={vehicleFitType}
                                            eachLabel={eachLabel}
                                            roadRating={roadRating}
                                            specification={specifications}
                                            badgePriorities={badgePriority}
                                            badges={badges}
                                            originalPrice={originalPrice}
                                            displayWasLabel={displayWasLabel}
                                            discount={discount}
                                            isAutoPartPlp={isAutoPartPlp}
                                            priceMessage={priceMessage}
                                            feeDisclaimerTitle={feeMessage?.feeDisclaimerTitle}
                                            feeDisclaimerMessage={feeMessage?.feeDisclaimerMessage}
                                            a11yStrikeOutPrice={a11yStrikeOutPrice}
                                            a11yStrikeOutPriceRange={a11yStrikeOutPriceRange}
                                            a11yCloseIconLabel={a11yCloseIconLabel}
                                            a11yTooltipIcon={a11yTooltipIcon}
                                            fitmentTypeCode={fitmentTypeCode}
                                            vehicleList={vehicleList}
                                            options={options}
                                            partNumber={partNumber}
                                            fitmentNotes={fitmentNotes}
                                            productWheelType={productWheelType}
                                            feeValue={feeMessage?.value}
                                            nowFromLabel={nowFromLabel}
                                            saveFromLabel={saveFromLabel}
                                            wasFromLabel={wasFromLabel}
                                            fromLabel={fromLabel}
                                            promotionalPriceLabel={promotionalPriceLabel}
                                            unitPriceLabel={unitPriceLabel}
                                            clearancePriceLabel={clearancePriceLabel}
                                            feeDisclaimerType={feeMessage?.type}
                                            scrollToFooter={scrollToFooter}
                                            isSRPPage={isSRPPage}
                                            vehicleInformationPresent={vehicleInformationPresent}
                                            tireInformationPresent={tireInformationPresent}
                                            isWheelOrTirePDP={isWheelOrTirePDP}
                                            partNumberLabel={partNumberLabel}
                                            inStockLabel={inStockLabel}
                                            inStockOnlineLabel={inStockOnlineLabel}
                                            outOfStockLabel={outOfStockLabel}
                                            inStorePurchaseLabel={inStorePurchaseLabel}
                                            inStockAisleLabel={inStockAisleLabel}
                                            checkAvailabilityLabel={checkAvailabilityLabel}
                                            checkOtherStoresLabel={checkOtherStoresLabel}
                                            exclude={exclude}
                                            overridePriceHeight={overridePriceHeight}
                                            a11yReviewRating={a11yReviewRating}
                                            url={url}
                                            feeMessages={feeMessages}
                                            productCardSpecifications={productCardSpecifications}
                                            isUrgentLowStock={isUrgentLowStock}
                                            isUrgentLowStockLabel={isUrgentLowStockLabel}
                                            totalCurrentPrice={totalCurrentPrice}
                                            imageDataComponentName={imageDataComponentName}
                                            returnPolicy={returnPolicy}
                                            attributePositionLabel={attributePositionLabel}
                                            suggestedQuantityLabel={suggestedQuantityLabel}
                                            attributeUsageLabel={attributeUsageLabel}
                                            isScrollingRequired={true}
                                            isLazyRequire={isLazyRequire}
                                            productDataId={code}
                                            a11yClickToReadFootnote={a11yClickToReadFootnote}
                                            showAvailabilityMessage={checkSkusAvailability()}
                                            accessibilityId={generateAccessibilityId(skus, skuId, code)}
                                        />
                                    )}
                                </li>
                            }
                        </Fragment>
                    )
                })}
                {isProductCardLoading && renderProductCardSkeleton(skeletonItems)}
            </>
        </ul>
    )
}

GridView.propTypes = {
    path: PropTypes.string,
    productProps: PropTypes.any,
    ProductCardData: PropTypes.any,
    viewType: PropTypes.string,
    productCardClick: PropTypes.func,
    redirectAfterProductCardClick: PropTypes.func,
    badgePriority: PropTypes.any,
    a11yStrikeOutPrice: PropTypes.string,
    a11yStrikeOutPriceRange: PropTypes.string,
    plusCoreChargesLbl: PropTypes.string,
    inclCoreChargesLbl: PropTypes.string,
    coreChargesTooltipTitle: PropTypes.string,
    coreChargesTooltipParagraph: PropTypes.string,
    a11yTooltipIcon: PropTypes.string,
    language: PropTypes.string,
    a11yCloseIconLabel: PropTypes.string,
    isProductCardLoading: PropTypes.bool,
    skeletonItems: PropTypes.number,
    rebateIcon: PropTypes.string,
    isFitmentRequired: PropTypes.bool,
    setOfFourLabel: PropTypes.string,
    perTireLabel: PropTypes.string,
    vehicleFrontLabel: PropTypes.string,
    vehicleRearLabel: PropTypes.string,
    roadRatedLabel: PropTypes.string,
    vehicleFitType: PropTypes.any,
    eachLabel: PropTypes.string,
    isAutoPartPlp: PropTypes.bool,
    vehicleList: PropTypes.array,
    productWheelType: PropTypes.string,
    nowFromLabel: PropTypes.string,
    saveFromLabel: PropTypes.string,
    wasFromLabel: PropTypes.string,
    fromLabel: PropTypes.string,
    promotionalPriceLabel: PropTypes.string,
    unitPriceLabel: PropTypes.string,
    clearancePriceLabel: PropTypes.string,
    isSRPPage: PropTypes.bool,
    vehicleInformationPresent: PropTypes.bool,
    tireInformationPresent: PropTypes.bool,
    scrollToFooter: PropTypes.func,
    isWheelOrTirePDP: PropTypes.bool,
    inStockLabel: PropTypes.string,
    inStockOnlineLabel: PropTypes.string,
    outOfStockLabel: PropTypes.string,
    inStorePurchaseLabel: PropTypes.string,
    inStockAisleLabel: PropTypes.string,
    checkAvailabilityLabel: PropTypes.string,
    checkOtherStoresLabel: PropTypes.string,
    partNumberLabel: PropTypes.string,
    exclude: PropTypes.array,
    thresholdValue: PropTypes.number,
    overridePriceHeight: PropTypes.bool,
    a11yReviewRating: PropTypes.string,
    productCardSpecifications: PropTypes.string,
    isUrgentLowStockLabel: PropTypes.string,
    isWheelOrTireFunc: PropTypes.func,
    isAutomotiveProduct: PropTypes.func,
    imageDataComponentName: PropTypes.string,
    returnPolicy: PropTypes.func,
    attributePositionLabel: PropTypes.string,
    suggestedQuantityLabel: PropTypes.string,
    attributeUsageLabel: PropTypes.string,
    isLazyRequire: PropTypes.bool,
    a11yClickToReadFootnote: PropTypes.string,
    pageType: PropTypes.string,
}

/**
 * ProductGridView component
 * @param {ProductGridViewProps} props
 * @return {JSX.Element} returns GridView component
 */
const ProductGridView: React.FC<propTypes> = props => {
    return (
        <>
            <div className={`${PREFIX}-row ${PREFIX}-container`}>
                {/* for spacing purpose added style attribute */}

                <div className={`${PREFIX}-product`}>
                    <GridView {...props} />
                </div>
            </div>
        </>
    )
}
ProductGridView.propTypes = {
    path: PropTypes.string,
    productProps: PropTypes.any,
    ProductCardData: PropTypes.any,
    viewType: PropTypes.string,
    productCardClick: PropTypes.func,
    redirectAfterProductCardClick: PropTypes.func,
    badgePriority: PropTypes.any,
    a11yStrikeOutPrice: PropTypes.string,
    a11yStrikeOutPriceRange: PropTypes.string,
    plusCoreChargesLbl: PropTypes.string,
    inclCoreChargesLbl: PropTypes.string,
    coreCharge: PropTypes.any,
    coreChargeIncluded: PropTypes.bool,
    coreChargesTooltipTitle: PropTypes.string,
    coreChargesTooltipParagraph: PropTypes.string,
    a11yTooltipIcon: PropTypes.string,
    language: PropTypes.string,
    a11yCloseIconLabel: PropTypes.string,
    isProductCardLoading: PropTypes.bool,
    skeletonItems: PropTypes.number,
    rebateIcon: PropTypes.string,
    isFitmentRequired: PropTypes.bool,
    setOfFourLabel: PropTypes.string,
    perTireLabel: PropTypes.string,
    vehicleFrontLabel: PropTypes.string,
    vehicleRearLabel: PropTypes.string,
    roadRatedLabel: PropTypes.string,
    vehicleFitType: PropTypes.any,
    eachLabel: PropTypes.string,
    isAutoPartPlp: PropTypes.bool,
    fitmentTypeCode: PropTypes.any,
    vehicleList: PropTypes.array,
    productWheelType: PropTypes.string,
    nowFromLabel: PropTypes.string,
    saveFromLabel: PropTypes.string,
    wasFromLabel: PropTypes.string,
    fromLabel: PropTypes.string,
    promotionalPriceLabel: PropTypes.string,
    unitPriceLabel: PropTypes.string,
    clearancePriceLabel: PropTypes.string,
    isSRPPage: PropTypes.bool,
    vehicleInformationPresent: PropTypes.bool,
    tireInformationPresent: PropTypes.bool,
    scrollToFooter: PropTypes.func,
    partNumberLabel: PropTypes.string,
    exclude: PropTypes.array,
    thresholdValue: PropTypes.number,
    overridePriceHeight: PropTypes.bool,
    a11yReviewRating: PropTypes.string,
    productCardSpecifications: PropTypes.string,
    isUrgentLowStockLabel: PropTypes.string,
    imageDataComponentName: PropTypes.string,
    returnPolicy: PropTypes.func,
    isLazyRequire: PropTypes.bool,
    saleEndDaySoonMessage: PropTypes.string,
}
export default ProductGridView
