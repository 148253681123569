import { magicNumber } from '@nl/lib'
import { UserHelper } from '../../helpers/user.helper'
import { DropdownValueType } from '../models/tireVehicle.interface'
import { DropdownOptions, DropdownOptionsState, DropdownType } from '../models/vehicleDropdowns.types'
import { vehiclesDataInterface } from '../models/vehiclesData.interface'
import { calculateOptionState } from './calculateOptionState'

const DISABLE_STATES = [DropdownOptionsState.NOT_LOADED, DropdownOptionsState.PRESELECTED]

/**
 * Returns function for dropdown generation by id.
 * It uses parameters like a context data for returned function
 * @param {vehiclesDataInterface} state - state necessary like context for merging the new data with  the current ones
 * @param {Record<string, string>} knownMap - it is map of known (selected) values, the key is dropdown id
 * @param {Record<string, string[]>} suggestionsMap - it is a map of suggestion arrays, the key is dropdown id
 * @param {Record<string, string>} labels - dictionary with localized dropdown labels
 * @param {string[]} vehicleNumericalIds - contains the list of attribute id what need additional call for localization and suggested value is ID not a label
 * @param {string[]} suggestedAttributes - contains the list of attribute id what suggested by API to have in dropdowns
 * @return {Function} - return function what will be ready generate dropdown option by id
 */
export const generateDropDownById = (
    state: vehiclesDataInterface,
    knownMap: Record<string, string>,
    suggestionsMap: Record<string, string[]>,
    labels: Record<string, string>,
    vehicleNumericalIds: string[],
) => {
    // suggestions adapted for UserHelper
    const adaptedSuggestions = Object.entries(suggestionsMap).map(([id, values]) => ({ id, values }))

    return (id: string): DropdownType => {
        const label = labels?.[id] || id
        const existedDropdown = state.dropdowns?.find(drop => drop.id === id)
        const oldOptions = existedDropdown?.options
        const isIdsOptions = vehicleNumericalIds.includes(id)
        const valuesLikeIds = (suggestionsMap[id] || []).map(
            val =>
                ({
                    id: val,
                } as unknown as DropdownValueType),
        )

        let value = knownMap[id] || ''

        // getting from response or save from state if no new suggestions
        const newOptions = UserHelper.getVehicleDropdownOptions(
            adaptedSuggestions,
            id,
            isIdsOptions,
            valuesLikeIds,
        ) as unknown as DropdownOptions[]

        const isSuggestedAttribute = state.baseDropdownsKeys.indexOf(id) === magicNumber.MINUS_ONE
        const previousOptionsState = existedDropdown?.optionsState
        const optionsState: DropdownOptionsState = calculateOptionState(
            newOptions,
            oldOptions,
            isSuggestedAttribute,
            value,
            previousOptionsState,
        )

        if (newOptions?.length === 1) {
            const [singleOption] = newOptions
            // use  option id like value if option suggest by attribute id like engineConfigID, etc.
            value = isIdsOptions ? String(singleOption.id) : singleOption.label
        }

        // should be overridden in case lazy load for options
        const isDisabled = DISABLE_STATES.includes(optionsState)
        const options = newOptions || (oldOptions?.length ? oldOptions : defaultOptions(value, isIdsOptions))

        return {
            optionsState,
            label,
            id,
            isDisabled,
            value,
            options,
        } as DropdownType
    }
}

/**
 * Returns a default single option if value isn't empty.
 * @param {string} value -value form API
 * @param {boolean} isIdsOptions - flag if value is just id of localized values
 * @return {DropdownOptions[]}
 */
const defaultOptions = (value: string, isIdsOptions: boolean): DropdownOptions[] => {
    const defaultOption = {
        label: value,
        id: isIdsOptions ? value : 0,
        selected: true,
    } as unknown as DropdownOptions

    return value ? [defaultOption] : []
}
