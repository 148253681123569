import { AutoAttributes } from '@nl/lib/src/components/ProductGridView/ProductGrid.types'
import { AOAEtireOrderVehicleInfo, AOALocationInfoState } from '../redux/models/aoaBookingForm.interface'
import { RootState } from '../redux/reducers'
import getAOAWorkflowType, { AOAWorkflowType } from './aoaWorkflow.helper'

/**
 * getVehicleInfo will fetch the vehicle information from different sources of redux state based on AOA workflow
 * @param {RootState} state
 * @return { AOAEtireOrderVehicleInfo | AutoAttributes | undefined }
 */

export const getVehicleInfo = (state: RootState): AOAEtireOrderVehicleInfo | AutoAttributes | undefined => {
    switch (getAOAWorkflowType()) {
        case AOAWorkflowType.Regular:
        case AOAWorkflowType.ChangeOrCancel:
            return state.userProfile.vehicle.defaultVehicle?.autoAttributes
        case AOAWorkflowType.ETire:
            return state.aoaBookingFormData.eTireInfo?.vehicleInfo
    }
}

/**
 * getLocationInfo will fetch the storeName (Or) location from different sources of redux state based on AOA workflow
 * @param {RootState} state
 * @return {Partial<AOALocationInfoState> | undefined}
 */
export const getLocationInfo = (state: RootState): Partial<AOALocationInfoState> | undefined => {
    const aoaWorkflowType = getAOAWorkflowType()
    if (aoaWorkflowType === AOAWorkflowType.Regular) {
        return state.aoaBookingFormData.locationInfo as Partial<AOALocationInfoState>
    } else if (aoaWorkflowType === AOAWorkflowType.ETire) {
        return state.aoaBookingFormData.eTireInfo?.storeInfo as Partial<AOALocationInfoState>
    }
}
