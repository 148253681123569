import {
    AOAServiceCategory,
    AOASelectedService,
} from '../../components/AOABookingForm/AOAServiceView/AOAServiceView.type'
import { ILoading } from './loading.interface'

/**
 * Interface for AOABookingFormState
 * @interface
 */
export interface AOABookingFormState extends ILoading {
    contactInfo: AOAContactInfoState | null
    dateTimeInfo: AOADateTimeInfoState | null
    locationInfo: AOALocationInfoState | null
    bookingResponse: AOABookingResponseState | null
    serviceInfo: AOAServiceInfoState | null
    currentBookingFormStep: number
    isOpenedStoreLocatorModal: boolean
    isContactInfoEditMode: boolean
    eTireInfo: AOAEtireInfoState | null
    showSkeleton: boolean
    hasStartedAOAFlow: boolean
    error: AOAErrorState | null
}

/**
 * Interface for AOAContactInfoState
 * @interface
 */
export interface AOAContactInfoState {
    firstName: string | undefined
    lastName: string | undefined
    email: string | undefined
    mobilePhone: string | undefined
    streetAddress: string | undefined
    postalCode: string | undefined
    city: string | undefined
    province: string | undefined
    provinceCode: string | undefined
}

/**
 * Interface for AOADateTimeDropOffOptionState
 * @interface
 */
export interface AOADateTimeInfoState {
    selectedDate?: string
    selectedTime?: string
    selectedDropOffOption?: string
}

/**
 * Interface for AOALocationInfoState
 * @interface
 */
export interface AOALocationInfoState {
    storeId?: string | number
    baseStoreId?: string
    storeName?: string
    address: { line1?: string; town?: string; regionName?: string; postalCode?: string }
    phone?: string
}

/**
 * Interface for AOABookingResponse
 * @interface
 */
export interface AOABookingResponseState {
    bookingNumber: string | undefined
}

/**
 * Interface for AOAServiceResponse
 */
export interface AOAServiceResponseState {
    branchCode: string | undefined
    categoryList: Array<AOAServiceCategory>
}

/**
 * Interface for AOAServicesInfo
 * @interface
 */

export interface AOAServiceInfoState {
    serviceData?: AOAServiceResponseState
    selectedServices?: Array<AOASelectedService>
    previouslySelectedServices?: Array<AOASelectedService>
    additionalComments?: string
}

/**
 * Interface for AOAServicePayload
 * @interface
 */
export interface AOAServicePayload {
    store: string
    language: string
}

/**
 * Interface for AOAEtireOrderStoreInfo
 * @interface
 */
export interface AOAEtireOrderStoreInfo {
    storeName?: string
    storeId?: string
}

/**
 * Interface for AOAEtireOrderVehicleInfo
 * @interface
 */
export interface AOAEtireOrderVehicleInfo {
    year: string | null
    make: string | null
    model: string | null
}
/**
 * Interface for AOAEtireInfoState
 * @interface
 */

export interface AOAEtireInfoState {
    orderNumber: string
    storeInfo: AOAEtireOrderStoreInfo
    vehicleInfo: AOAEtireOrderVehicleInfo
    repairOrderNumber: string
}

export enum AOABookingResponseStatus {
    AddedToQueue = 'AddedToQueue',
    Error = 'Error',
}

export interface AOAErrorState {
    type: AOAErrorType
}

export enum AOAErrorType {
    PersistentToast = 'PersistentToast',
    TemporaryToast = 'TemporaryToast',
    MessageCard = 'MessageCard',
    NonBlockingMessageCard = 'NonBlockingMessageCard',
}
