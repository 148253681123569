/**
 * Interface for CardInputProps
 * @interface
 */
export enum CardType {
    loyalty = 'loyalty',
    master = 'master',
    linkmaster = 'master-link',
}
export interface CardInputProps {
    id: string
    label?: string
    error?: string
    cardType?: 'loyalty' | 'master' | string | CardType
    path?: string
    value?: string
    onChange?: (value) => void
    errorIcon?: string
    cardImage?: string
    cardImageAltText?: string
}
