// constant for mapControls
export const mapControls = {
    mapType: 'roadmap',
    mapZoomControl: true,
    mapTypeControl: false,
    mapFullscreenControl: false,
}

// constant for markerProps
export const markerProps = {
    initialMarkerIndex: 0,
    markerWidth: 36,
    markerHeight: 52,
    markerTypes: {
        defaultMarker:
            'data:image/svg+xml;base64,PHN2ZyBpZD0iYmVhZDhjNTQtMzUzOS00ZjMxLTk5YTYtODExMzhlMTE0YWZiIiBkYXRhLW5hbWU9IkxheWVyIDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDM2IDUyIj48cGF0aCBkPSJNMTgsMEExOC4wOSwxOC4wOSwwLDAsMCwwLDE4LjE4djBDMCwzMS44NSwxOCw1MiwxOCw1MlMzNiwzMS44NSwzNiwxOC4yQTE4LjA5LDE4LjA5LDAsMCwwLDE4LDBaIiBmaWxsPSIjMDA4MjQ4Ii8+PGNpcmNsZSBjeD0iMTgiIGN5PSIxOCIgcj0iMTQiIGZpbGw9IiNmZmYiLz48L3N2Zz4=',
        preferredSelectedMarker:
            'data:image/svg+xml;base64,PHN2ZyBpZD0iZmYwYTQ3ZmMtM2JiNC00N2YyLThiNzUtYzNkZjdjM2YyM2M3IiBkYXRhLW5hbWU9IkxheWVyIDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDM2IDUyIj48cGF0aCBkPSJNMTgsMEExOC4wOSwxOC4wOSwwLDAsMCwwLDE4LjE4djBDMCwzMS44NSwxOCw1MiwxOCw1MlMzNiwzMS44NSwzNiwxOC4yQTE4LjA5LDE4LjA5LDAsMCwwLDE4LDBaIiBmaWxsPSIjMDA4MjQ4Ii8+PHBhdGggZD0iTTgsOEgyOFYyOEg4WiIgZmlsbD0ibm9uZSIvPjxwYXRoIGQ9Ik04LDhIMjhWMjhIOFoiIGZpbGw9Im5vbmUiLz48cGF0aCBkPSJNMTgsMjIuMzlsMy40NiwyLjA5YS44My44MywwLDAsMCwxLjE0LS4yNy44Ni44NiwwLDAsMCwuMS0uNjNsLS45Mi0zLjkzTDI0Ljg0LDE3YS44My44MywwLDAsMCwuMDktMS4xNy44NS44NSwwLDAsMC0uNTYtLjI5bC00LS4zNC0xLjU3LTMuNzJhLjgzLjgzLDAsMCwwLTEuMS0uNDMuODguODgsMCwwLDAtLjQ0LjQzbC0xLjU3LDMuNzEtNCwuMzRhLjg0Ljg0LDAsMCwwLS43Ni45LjgxLjgxLDAsMCwwLC4yOS41NmwzLjA2LDIuNjUtLjkyLDMuOTNhLjgyLjgyLDAsMCwwLC42MiwxLC44My44MywwLDAsMCwuNjItLjA5WiIgZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+',
        preferredMarker:
            'data:image/svg+xml;base64,PHN2ZyBpZD0iYjQ3YzhkMDMtYWMyMy00MjU5LWEyZjgtM2Q1OGY3NWE0YTQ0IiBkYXRhLW5hbWU9IkxheWVyIDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDM2IDUyIj48cGF0aCBkPSJNMTgsMEExOC4wOSwxOC4wOSwwLDAsMCwwLDE4LjE4djBDMCwzMS44NSwxOCw1MiwxOCw1MlMzNiwzMS44NSwzNiwxOC4yQTE4LjA5LDE4LjA5LDAsMCwwLDE4LDBaIiBmaWxsPSIjMDA4MjQ4Ii8+PGNpcmNsZSBjeD0iMTgiIGN5PSIxOCIgcj0iMTQiIGZpbGw9IiNmZmYiLz48cGF0aCBkPSJNMTgsMjIuMzlsMy40NiwyLjA5YS44My44MywwLDAsMCwxLjE0LS4yNy44Ni44NiwwLDAsMCwuMS0uNjNsLS45Mi0zLjkzTDI0Ljg0LDE3YS44My44MywwLDAsMCwuMDktMS4xNy44NS44NSwwLDAsMC0uNTYtLjI5bC00LS4zNC0xLjU3LTMuNzJhLjgzLjgzLDAsMCwwLTEuMS0uNDMuODguODgsMCwwLDAtLjQ0LjQzbC0xLjU3LDMuNzEtNCwuMzRhLjg0Ljg0LDAsMCwwLS43Ni45LjgxLjgxLDAsMCwwLC4yOS41NmwzLjA2LDIuNjUtLjkyLDMuOTNhLjgyLjgyLDAsMCwwLC42MiwxLC44My44MywwLDAsMCwuNjItLjA5WiIgZmlsbD0iIzAwODI0OCIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+',
        numericMarker:
            'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAzNiA1MiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzYgNTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojMDA4MjQ4O30KCS5zdDF7ZmlsbDpub25lO30KCS5zdDJ7ZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7ZmlsbDojRkZGRkZGO30KCS5zdDN7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzYgMjgpIj4KCTxnIHRyYW5zZm9ybT0ibWF0cml4KDEsIDAsIDAsIDEsIC0zNiwgLTI4KSI+CgkJPHBhdGggY2xhc3M9InN0MCIgZD0iTTE4LDBDOC4wMSwwLjA1LTAuMDUsOC4xOSwwLDE4LjE4YzAsMC4wMSwwLDAuMDIsMCwwLjAyQzAsMzEuODUsMTgsNTIsMTgsNTJzMTgtMjAuMTUsMTgtMzMuOAoJCQlDMzYuMDYsOC4yMSwyOC4wMSwwLjA2LDE4LjAyLDBDMTguMDIsMCwxOC4wMSwwLDE4LDB6Ii8+Cgk8L2c+CjwvZz4KPC9zdmc+Cg==',
    },
    activeMarkerLabelColor: '#fff',
    markerLabelColor: '#008248',
    markerLabelFontSize: '16px',
    markerLabelFontWeight: '800',
    markerLabelXPos: 18,
    markerLabelYPos: 18,
    preferredMarkerVal: 'preferred',
    shape: {
        coords: ['1, 1, 52, 52'],
        type: 'rect',
    },
}

// constant for locationProps
export const locationProps = {
    lat: 0,
    lng: 0,
}

// constant for minimumStore
export const minimumStore = 1

// constant for custom locate me control button

export const locateMeControlStyles = {
    locationButtonstylebackgroundColor: '#fff',
    locationButtonstyleborder: 'none',
    locationButtonstyleoutline: 'none',
    locationButtonstylewidth: '40px',
    locationButtonstyleheight: '40px',
    locationButtonstyleborderRadius: '0px',
    locationButtonstyleboxShadow: '0 1px 4px rgba(0,0,0,0.3)',
    locationButtonstylecursor: 'pointer',
    locationButtonstylemarginRight: '10px',
    locationButtonstylepadding: '0px',
    locationButtonInnerstylemargin: '12px',
    locationButtonInnerstylewidth: '18px',
    locationButtonInnerstyleheight: '18px',
    locationButtonInnerstylebackgroundImage:
        'url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-1x.png)',
    locationButtonInnerstylebackgroundSize: '180px 18px',
    locationButtonInnerstylebackgroundPosition: '0px 0px',
    locationButtonInnerstylebackgroundRepeat: 'no-repeat',
    locationButtonInnerid: 'your_location_img',
}
