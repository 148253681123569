import { localization, libUtils } from '@nl/lib'
import { currencyShortForm, currencyFormat, multiLanguageLabel } from '../globalConstants/global.constant'

export const getFormattedPriceWithLocale = (
    price?: number | null,
    maximumFractionDigits?: number | undefined,
    minimumFractionDigits?: number | undefined,
): string => {
    const language = libUtils.getLanguage()
    return localization.formattedPrice(
        language,
        price ?? '',
        currencyShortForm,
        currencyFormat,
        maximumFractionDigits,
        minimumFractionDigits,
    )
}

/**
 * Method to extract currency values in number format from formatted locale values
 * @param {string} currency
 * @return {number}
 */
export const getNumberFromFormattedPrice = (currency: string): number => {
    if (multiLanguageLabel.frenchLangLabel.toLowerCase() === libUtils.getLanguage()) {
        currency = currency.replace(/,/g, '.')
    }
    return Number(currency.replace(/[^0-9.-]+/g, ''))
}

export default { getFormattedPriceWithLocale, getNumberFromFormattedPrice }
