// / <reference path="../externals.ts" />
import appCacheService from './appCacheService'

export const logOutUser = (callBackFunc: () => void, UID: string): void => {
    const logOutUserCallback = (): void => {
        appCacheService.userDetails.delete()
        callBackFunc()
    }

    const params = {
        callback: logOutUserCallback,
        apiKey: window.gigya.apiKey,
        UID: UID,
    }
    window.gigya.accounts.logout?.(params)
}
