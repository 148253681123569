import { PriceBenefitsType, TriangleSelectBenefitsType } from '../redux/models/product.interface'
import { CartOrderEntries } from '../redux/models/cart.interface'
import { getPTEBaseTotal } from '@nl/lib'
import { renderPTEAmount } from '../helpers/offers.helper'

/**
 * based on flag return discountable amount
 * @param {number} brandDiscount
 * @param {number} everyDayDiscount
 * @param {PriceBenefitsType} priceBenefits
 * @return {number | TriangleSelectBenefitsType}
 */
const getDiscountAmount = (
    brandDiscount: number,
    everyDayDiscount: number,
    priceBenefits: PriceBenefitsType,
): number | TriangleSelectBenefitsType => {
    const { price, tSelectBenefits } = priceBenefits

    return tSelectBenefits?.tsBrand
        ? {
              tsEverydayDiscount: getPTEBaseTotal(everyDayDiscount, Number(price?.value)),
              tsBrandDiscount: getPTEBaseTotal(brandDiscount, Number(price?.value)),
          }
        : getPTEBaseTotal(everyDayDiscount, Number(price?.value))
}

/**
 * determine the discount to be considered for select benefits calculation
 * @param {PriceBenefitsType} priceBenefits
 * @return {number}
 */

export const calculateSelectDiscounts = (priceBenefits: PriceBenefitsType): number => {
    const { price, tSelectBenefits } = priceBenefits
    const selectDiscount = tSelectBenefits?.tsBrand
        ? Number(tSelectBenefits.tsBrandDiscount + tSelectBenefits.tsEverydayDiscount)
        : Number(tSelectBenefits?.tsEverydayDiscount)
    return getPTEBaseTotal(selectDiscount, Number(price?.value))
}

/**
 * calculate triangleselect benefits for a particular order
 * @param {CartOrderEntries[]} orderEntries
 * @return {number}
 */

export const calculateSelectBenefitsForOrder = (orderEntries: CartOrderEntries[]): number => {
    const selectBenefits = orderEntries?.map((currentItem: CartOrderEntries) => {
        const priceBenefitsArray = {
            price: currentItem.totalPrice,
            tSelectBenefits: currentItem.triangleSelectBenefits,
        }
        return calculateSelectDiscounts(priceBenefitsArray)
    })
    return renderPTEAmount(selectBenefits)
}

/**
 * calculate select benefit for a single product
 * @param { PriceBenefitsType } productPrice
 * @param { boolean } isQuebecRegion
 * @return {number | TriangleSelectBenefitsType}
 */
export const calculateProductSelectDiscount = (
    productPrice: PriceBenefitsType,
    isQuebecRegion: boolean,
): number | TriangleSelectBenefitsType => {
    const { tSelectBenefits } = productPrice
    const everyDayDiscount = Number(tSelectBenefits?.tsEverydayDiscount)
    const brandDiscount = Number(tSelectBenefits?.tsBrandDiscount)

    if (isQuebecRegion) {
        return calculateSelectDiscounts(productPrice)
    } else {
        return getDiscountAmount(brandDiscount, everyDayDiscount, productPrice)
    }
}
