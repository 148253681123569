import { Dispatch } from 'redux'
import { setRedirect } from './sessionStorageSearchGA.action'
import { SEARCH_BAR_QUERY } from '../types/products/search.actionTypes.constant'
import { searchBarAction, getProductSearchAction, getProductSearchError } from '../actionCreators/search.actionCreators'
import urlRedirection from '../../utils/urlRedirection'
import { ProductDataType } from '../models/productData.interface'
import searchService from '../../services/searchService'
import { RootState } from '../reducers'
import { enableDestructOnUndefinedData } from '../../utils/PDP/enableDestructOnUndefinedData.utils'
import { AxiosError } from 'axios'
import { SearchErrorResponse } from '../models/search.interface'
import { IGlobalProps } from '../models/commonContent.interface'

export const setSearchKeyword =
    (searchPayload: string) =>
    (dispatch: Dispatch<{ type: typeof SEARCH_BAR_QUERY; payload: string }>): void => {
        dispatch(searchBarAction(searchPayload))
    }

export const getProductSearchData =
    (requestPayload: string, searchPagePath: string, query: string, selectedPreferredStoreId: string) =>
    (dispatch: Dispatch<any>, getState: () => RootState): Promise<void> => {
        const formattedPayload = encodeURIComponent(requestPayload)
        const updatedQuery = `${query}=${formattedPayload}`

        const { commonContentAvailable } = enableDestructOnUndefinedData(getState().commonContent) as IGlobalProps
        const { enableXRingFence } = enableDestructOnUndefinedData(commonContentAvailable?.featureFlag)

        return searchService
            .getSearchResults(updatedQuery, selectedPreferredStoreId, enableXRingFence)
            .then(data => {
                let { redirectUrl } = data.data as ProductDataType

                if (!redirectUrl) {
                    redirectUrl = urlRedirection(searchPagePath, query, formattedPayload) // Search Page
                } else {
                    dispatch(setRedirect(true))
                }
                // TODO: PDP through SKUCODE
                dispatch(getProductSearchAction(redirectUrl))
            })
            .catch((error: AxiosError<SearchErrorResponse>) => {
                const { data, status } = (error?.response || {}) as unknown as SearchErrorResponse
                dispatch(getProductSearchError({ data, status }))
            })
    }
