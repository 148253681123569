import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { SearchTitle, replaceStrWithDynamicVal, getParamValue, updateUrlHistory, queryParameters } from '@nl/lib'
import { SearchTitlePropTypes } from './SearchTitle.type'
import { fetchProductCardData } from '../../redux/actions/ProductData.action'
import { usePageAnalytics } from '../../analytics/hooks/usePageAnalytics'
import { analyticsInteraction } from '../../redux/actions/pageAnalytics.action'
import { analyticsAttributes } from '../../globalConstants/analyticsParams.constant'
import { Facet } from '../../redux/models/productData.interface'
import { FacetTypes } from '../StickyFilters/HorizontalPillBar/HorizontalPillBar.types'
import { selectedPreferredStoreIdSelector } from '../../redux/selectors/storeDetails.selectors'
import { isFitmentRequiredSelector } from '../../redux/selectors/categoryIdData.selectors'
import { productCardDataSelector } from '../../redux/selectors/productData.selectors'

/**
 * SearchTitleComponent
 * @param {SearchTitlePropTypes} props - searchTitle
 * @return {JSX.Element} returns SearchTitle
 */
const SearchTitleComponent: React.FC<SearchTitlePropTypes> = ({ ...props }) => {
    const { searchTitle, noResultTitle, suggestiveMessage, autoCorrectiveSearchMessage, inCategoryLabel } = props

    const dispatch = useDispatch()

    usePageAnalytics()

    const productCardData = useSelector(productCardDataSelector)
    const { resultCount, didYouMean = [], autoCorrectQuery, similarProducts, facets, pagination } = productCardData
    const selectedPreferredStoreId = useSelector(selectedPreferredStoreIdSelector)
    const urlSearchParams = new URLSearchParams(window.location.search.replace(/;/g, '&'))
    const params = Object.fromEntries(urlSearchParams?.entries())
    const [queryString, setQueryString] = useState(params.q ? params.q : '')
    const GET_ALL_PRODUCTS = '*'

    const breadcrumbFacets = facets?.find((facet: Facet) => facet.type === FacetTypes.BREADCRUMB)
    const currentCategory = breadcrumbFacets?.current
    const lastSelectedCategory = currentCategory?.label
        ? replaceStrWithDynamicVal(inCategoryLabel, currentCategory?.label)
        : ''
    const isFitmentRequired = useSelector(isFitmentRequiredSelector)
    const {
        event: { search },
        eventParameters: {
            action: { suggestedSearchTerm },
            category: { siteSearch },
        },
    } = analyticsAttributes

    const firstPageUrl = pagination?.pages?.[0]?.url
    const productToFetchCount = firstPageUrl
        ? Number(getParamValue(firstPageUrl, queryParameters.count, queryParameters.divider))
        : 0
    /*
     * suggestiveQueryClick function updates the url with the suggestive search text
     */
    const suggestiveQueryClickCallback = (suggestion: string): void => {
        updateUrlHistory(`?q=${suggestion}`)
        dispatch(
            fetchProductCardData(
                window.location.search,
                selectedPreferredStoreId,
                isFitmentRequired,
                productToFetchCount,
            ),
        )
        dispatch(analyticsInteraction(suggestion, '', search, suggestedSearchTerm, siteSearch))
    }

    useEffect(() => {
        // OCCP-18593: use the categoryMap provided from productCard API response to look up the category name if given a category code as query string
        //             note that if the user actually searched for the category code, the displayed query string would be the resolved category name because
        //             there is no indicator telling FED that the code was the search string. as far as FED is concerned if the search string can be resolved to a
        //             category name (i.e. it's a category code) then FED will resolve and display the category name
        const queryStringTemp = autoCorrectQuery || queryString
        const categoryMap = productCardData.metadata?.categoryMap
        if (categoryMap && queryStringTemp in categoryMap) {
            setQueryString(categoryMap[queryStringTemp])
        } else {
            if (queryStringTemp) {
                setQueryString(queryStringTemp)
            }
        }
    }, [productCardData, queryString, autoCorrectQuery])

    return (
        <>
            {(queryString || lastSelectedCategory) && queryString !== GET_ALL_PRODUCTS && (
                <SearchTitle
                    searchTitle={resultCount === 0 ? noResultTitle : searchTitle}
                    suggestiveMessage={autoCorrectQuery ? autoCorrectiveSearchMessage : suggestiveMessage}
                    searchString={queryString}
                    suggestiveQueryClickCallback={suggestiveQueryClickCallback}
                    didYouMean={didYouMean}
                    lastSelectedCategory={lastSelectedCategory}
                    similarProductsMessage={similarProducts?.htmlText ? similarProducts?.htmlText : ''}
                />
            )}
        </>
    )
}

SearchTitleComponent.propTypes = {
    searchTitle: PropTypes.string.isRequired,
    noResultTitle: PropTypes.string.isRequired,
    suggestiveMessage: PropTypes.string.isRequired,
    autoCorrectiveSearchMessage: PropTypes.string.isRequired,
    inCategoryLabel: PropTypes.string,
}

export default SearchTitleComponent
