import { isArrayNotEmpty } from '@nl/lib'
import analyticsLayer from '../../utils/analyticsService'
import { analyticsAttributes } from '../../globalConstants/analyticsParams.constant'
import {
    ServiceDTO,
    CartAnalytics,
    CartModificationsDTO,
    CartOrderEntries,
    eEntryType,
} from '../../redux/models/cart.interface'
import checkNestedProps from '../../utils/checkNestedProps'
import getPageType from '../../utils/getPageType'
import { getProvider } from '../providers/index'
import { ProductHelper } from '../helpers/product.helper'
import { getPdpType } from '../../components/BuyBox/BuyBox.helper'
import { getDaysDifference } from '../../utils/getDaysDifference.utils'
import { checkValueOrNull, changeNullToUndefined } from '../../utils/checkNotNullAndUndefined'

/**
 * this function gives value for guranteedFitment key
 * @param {boolean} fitted
 * @param {boolean} shopWithVehicle
 * @return {string} returns value for guranteedFitment key
 */
const isGuaranteedFitment = (fitted: boolean, shopWithVehicle: boolean): string => {
    return fitted ? `${String(shopWithVehicle)}` : 'not applicable'
}

/**
 * this function gives one entry of product key for analytics
 * @param {ServiceDTO | CartModificationsDTO} cartData
 * @param {string} list
 * @param {boolean} isMergeCart
 * @param {boolean} financingEligible
 * @param {boolean} financingShown
 * @param {boolean} isAutomotive
 * @param {boolean} shopWithVehicle
 * @param {boolean} isPackagedItem
 * @param {string} flyerDealId
 * @param {string} flyerPublicationId
 * @return {CartAnalytics} returns product list value
 */
const getProductList = (
    cartData: ServiceDTO | CartModificationsDTO | CartOrderEntries,
    list: string,
    isMergeCart: boolean,
    financingEligible?: boolean,
    financingShown?: boolean,
    isAutomotive?: boolean,
    shopWithVehicle?: boolean,
    isPackagedItem?: boolean,
    flyerDealId: string | null = '',
    flyerPublicationId: string | null = '',
): CartAnalytics => {
    const entry = (cartData as CartModificationsDTO)?.entry || cartData
    const quantityAdded = (cartData as CartModificationsDTO)?.quantityAdded
    const isService = entry?.entryType === eEntryType.SERVICE_STANDALONE
    const isFitted = (entry?.vehicleInformation && entry?.fitmentTypeCode) !== null
    const { variant1, variant2, variant3 } = ProductHelper.transFormVariantData(entry)

    const [level1, level2, level3, level4, level5] = entry?.merchCategories || []
    const feeMessage = isArrayNotEmpty(entry?.feeMessages) ? entry?.feeMessages?.[0] : {}
    const fee = ProductHelper.transformFee(feeMessage)
    const rebate = ProductHelper.transformRebate(entry?.rebate)
    return {
        availability: ProductHelper.transformAvailability(entry),
        corporateStatus: entry?.productStatus?.toLowerCase(),
        badge: entry?.badges,
        brand: entry?.brand?.label,
        category: entry?.merchCategories?.join('/'),
        level1,
        level2,
        level3,
        level4,
        level5,
        fitted: String(isFitted),
        package: String(entry?.isPackagedItem || !!isPackagedItem),
        fullfillmentDaysAway: getDaysDifference(entry?.fulfillment?.sourceTypes?.[0]?.etaEarliest),
        fee: changeNullToUndefined(fee, isMergeCart),
        financingEligible: String(financingEligible && financingShown),
        financingShown: String(financingShown),
        installationAvailable: ProductHelper.transformInstallation(entry),
        inventory: String(entry?.fulfillment?.stockItemAvailability?.storeQuantity),
        list,
        name: entry?.name,
        pcode: entry?.baseProduct,
        price: ProductHelper.transformPrice(entry),
        rebate: changeNullToUndefined(rebate, isMergeCart),
        quantity: quantityAdded || entry?.quantity,
        rating: String(entry?.Rating),
        numberOfReviews: String(entry?.numberOfReviews),
        shipFrom: entry?.shipFrom,
        sku: entry?.code,
        variant1,
        variant2,
        variant3,
        fulfillmentOptionAvailable: entry?.fulfillmentOptionAvailable,
        bulk: 'false',
        vehicle: {
            staggeredFitment: String(!!entry?.staggeredGroup),
            type: entry?.vehicleInformation?.type,
            make: entry?.vehicleInformation?.make,
            model: entry?.vehicleInformation?.model,
            year: entry?.vehicleInformation?.year,
            body: entry?.vehicleInformation?.body,
            option: entry?.vehicleInformation?.options,
        },
        guaranteedFitment: isGuaranteedFitment(isFitted, shopWithVehicle as boolean),
        pdpType: getPdpType(isAutomotive as boolean, isService),
        // if present the add them to the final object
        ...(flyerDealId && { flyerDealId }),
        ...(flyerPublicationId && { flyerPublicationId }),
    }
}
/**
 * Analytics block to be sent when service is added to cart successfully
 * @param {Object} cartData - the data needs to be sent to analytics layer
 * @param {string} cartGUID - cart id
 * @param {string} location - location
 */

const { eventParameters } = analyticsAttributes
export const addToCartAnalytics = (
    cartData:
        | ServiceDTO[]
        | CartModificationsDTO[]
        | ServiceDTO
        | CartModificationsDTO
        | CartOrderEntries
        | CartOrderEntries[],
    cartGUID: string | null,
    location = checkNestedProps(eventParameters, 'location', 'servicesLocation'),
    list = checkNestedProps(eventParameters, 'labels', 'serviceCartList') as string,
    financingEligible?: boolean,
    financingShown?: boolean,
    isAutomotive?: boolean,
    shopWithVehicle?: boolean,
    isPackagedItem?: boolean,
    shippingFee?: number,
): void => {
    const productList: CartAnalytics[] = []
    const urlParams = new URLSearchParams(window.location.search)
    const flyerDealId = urlParams.get('flyerDealId')
    const flyerPublicationId = urlParams.get('flyerPublicationId')
    const ifHasFlyerParams = !!flyerDealId && !!flyerPublicationId
    const listValue = ifHasFlyerParams ? checkNestedProps(analyticsAttributes, 'list', 'digitalFlyer') : list
    const mergeCartAnalytics = checkNestedProps(eventParameters, 'location', 'mergeCart')
    const isMergeCart = mergeCartAnalytics === list
    const shippingCost = checkValueOrNull(shippingFee?.toString())
    if (Array.isArray(cartData) || isArrayNotEmpty(cartData)) {
        const analyticsCartData = (isArrayNotEmpty(cartData) ? cartData : Object.keys(cartData)) as
            | ServiceDTO[]
            | CartModificationsDTO[]
            | CartOrderEntries[]

        analyticsCartData.forEach((singleCartData: ServiceDTO | CartModificationsDTO | CartOrderEntries) => {
            productList.push(
                getProductList(
                    singleCartData,
                    listValue as string,
                    isMergeCart,
                    financingEligible,
                    financingShown,
                    isAutomotive,
                    shopWithVehicle,
                    isPackagedItem,
                    flyerDealId,
                    flyerPublicationId,
                ),
            )
        })
    } else {
        productList.push(
            getProductList(
                cartData,
                listValue as string,
                isMergeCart,
                financingEligible,
                financingShown,
                isAutomotive,
                shopWithVehicle,
                isPackagedItem,
                flyerDealId,
                flyerPublicationId,
            ),
        )
    }
    analyticsLayer.pushData({
        event: analyticsAttributes.event.addToCart,
        cart: {
            id: cartGUID,
            shippingCost: changeNullToUndefined(shippingCost, isMergeCart),
        },
        eventParameters: {
            location: location,
        },

        product: productList,
    })
}

/**
 * Analytics block to be sent when warning modal appears
 * @param {string} modalTitle - modal title
 *
 */

export const addToCartAnalyticsError = (modalTitle: string, eventName?: string, actionName?: string): void => {
    const { event } = analyticsAttributes

    getProvider().push({
        event: eventName || event.warning,
        eventParameters: {
            action: actionName || (checkNestedProps(eventParameters, 'action', 'viewWarningMessage') as string),
            category: getPageType() || undefined,
            label: modalTitle,
            value: '',
        },
    })
}
