import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

import { BannerProps, TextContentProps, SessionStorageValueType } from './BannerContent.type'
import { Button, Icon, magicNumber, SkinnyBannerModal, getPageType } from '@nl/lib'
import { PREFIX, previousElementName } from '../../config'
import { fontColor } from '../../globalConstants'
import { learnmoreCtaId, ultraSkinnyBannerSessionStorage } from './BannerContentWrapper.constant'

const BannerContentWrapper: React.FC<BannerProps> = ({ ...props }) => {
    const { ultraSkinnyBanner } = props
    const {
        triangleEventStartDate,
        triangleEventEndDate,
        learnMoreCtaLabel,
        learnMoreCtaColour,
        usModalImgAltText,
        ctaLabel,
        ctaLink,
        ctaA11yLabel,
        tdBannerTextDesktop,
        tdBannerTextMobile,
        tdBannerDescription,
        edBannerTextDesktop,
        edBannerTextMobile,
        edBannerDescription,
        fontColourText,
        fontColourCta,
        usModalDesktopBgImg,
        usModalMobileBgImg,
        componentMode,
        bannerLogoImageDesktop,
        learnMoreCtaAccessibilityLabel,
        closeCtaAccessibilityLabel,
        triangleBannerSuppressPageViewsCount,
        ctaLinkTarget,
        bannerId,
        tdShowBannerOnPageType,
        edShowBannerOnPageType,
        learnMoreCtaTarget,
        learnMoreCtaUrl,
        legalCopyText,
    } = ultraSkinnyBanner

    const pageType = getPageType()
    const currentDate = new Date().getTime()
    const eventStartDate = new Date(triangleEventStartDate).getTime()
    const eventEndDate = new Date(triangleEventEndDate).getTime()
    const isEventDays = currentDate >= eventStartDate && currentDate <= eventEndDate
    const [isModalOpen, setModalOpen] = useState<boolean>(false)
    const componentClassName = `${PREFIX}-ultra-slim-banner`
    const closeCtaId = `${PREFIX}-${bannerId}`
    const ctaClass = fontColourCta?.toLowerCase() === fontColor.WHITE ? `${PREFIX}-button--white` : ''
    const learnTextClass = learnMoreCtaColour?.toLowerCase() === fontColor.WHITE ? `${PREFIX}-button--white` : ''
    const fontColorClass = fontColourText?.toLowerCase() === fontColor.WHITE ? `${componentClassName}__font--white` : ''
    const isBannerLogo = document.getElementsByClassName(`${componentClassName}__left-section--logo`)[0]
    const textContainerClass = isBannerLogo ? `${componentClassName}__text-container--absolute` : ''

    /**
     * function to extract text based on whether current date is within event dates or else data for tease days are displayed
     * @return {TextContentProps}
     */

    const extractTextContentBasedOnDay = (): TextContentProps => {
        if (isEventDays) {
            return {
                bannerTextDesktop: edBannerTextDesktop,
                bannerTextMobile: edBannerTextMobile,
                modalDescription: edBannerDescription ? edBannerDescription : '',
            }
        } else {
            return {
                bannerTextDesktop: tdBannerTextDesktop,
                bannerTextMobile: tdBannerTextMobile,
                modalDescription: tdBannerDescription ? tdBannerDescription : '',
            }
        }
    }

    /**
     * redirected to target link
     * @return {void}
     */
    const optionalCTAHandler = (): void => {
        setModalOpen(false)
        window.open(ctaLink, ctaLinkTarget)
    }

    /**
     * function to handle click handler for opening the modal
     * @return {void}
     */
    const openModalHandler = (): void => {
        const targetElem = document.querySelector(`.${componentClassName}__learnmore`)
        targetElem?.setAttribute(previousElementName, 'true')
        setModalOpen(true)
    }

    /** updating session storage on page refresh / each page view
     * @return {void}
     */
    const setSessionStorageValues = useCallback(() => {
        const ultraslimBannerSS: SessionStorageValueType[] =
            (JSON.parse(sessionStorage.getItem(ultraSkinnyBannerSessionStorage)) as SessionStorageValueType[]) || []
        /**
         * function to increment page count only if the banner is displayed in page  and is less than/ equal to suppresscount
         * @param {number} count
         * @return {number} count
         */
        const incrementPageViewCount = (count: number): number => {
            const allowedPageTypes = isEventDays ? edShowBannerOnPageType : tdShowBannerOnPageType
            if (count <= triangleBannerSuppressPageViewsCount && allowedPageTypes?.includes(pageType)) {
                count = count + (magicNumber.ONE as number)
            }
            return count
        }
        const initialValues = {
            pageVisitedCount: incrementPageViewCount(magicNumber.ZERO as number),
            bannerId: bannerId,
        }
        if (ultraslimBannerSS.length > magicNumber.ZERO) {
            let isBannerIdAvailable = false
            ultraslimBannerSS.forEach((item: SessionStorageValueType) => {
                if (item.bannerId === bannerId) {
                    const viewCount = item.pageVisitedCount
                    let updatedViewCount = 0
                    updatedViewCount = incrementPageViewCount(viewCount)
                    item.pageVisitedCount = updatedViewCount
                    isBannerIdAvailable = true
                }
            })
            // if bannerId is not already present in the sessionStorage variable, push as a new item
            if (!isBannerIdAvailable) ultraslimBannerSS.push(initialValues)
        } else {
            ultraslimBannerSS.push(initialValues)
        }
        sessionStorage.setItem(ultraSkinnyBannerSessionStorage, JSON.stringify(ultraslimBannerSS))
    }, [
        triangleBannerSuppressPageViewsCount,
        bannerId,
        edShowBannerOnPageType,
        tdShowBannerOnPageType,
        isEventDays,
        pageType,
    ])

    // useEffect to set the sessionstorage when page visted or loaded
    useEffect(() => {
        setSessionStorageValues()
    }, [setSessionStorageValues])

    return (
        <>
            <SkinnyBannerModal
                modalHeading={extractTextContentBasedOnDay().bannerTextMobile}
                modalDescription={extractTextContentBasedOnDay().modalDescription}
                modalSecondaryHeader={extractTextContentBasedOnDay().bannerTextDesktop}
                optionalCTALink={ctaLink}
                usModalDesktopBgImg={usModalDesktopBgImg}
                usModalMobileBgImg={usModalMobileBgImg}
                modalLogo={bannerLogoImageDesktop}
                isModalOpen={isModalOpen}
                optionalCTALabel={ctaLabel}
                setModalOpen={setModalOpen}
                closeCtaAccessibilityLabel={closeCtaAccessibilityLabel}
                usModalImgAltText={usModalImgAltText}
                ctaAccessibilityLabel={ctaA11yLabel}
                optionalCTAHandler={optionalCTAHandler}
                disclaimerMessage={legalCopyText}
            />

            <div className={`${componentClassName}__text-container ${textContainerClass}`}>
                <div
                    className={`${PREFIX}-xs-none ${PREFIX}-md-block ${fontColorClass}`}
                    dangerouslySetInnerHTML={{ __html: extractTextContentBasedOnDay().bannerTextDesktop }}></div>
                <div
                    className={`${PREFIX}-xs-block ${PREFIX}-md-none ${fontColorClass}`}
                    dangerouslySetInnerHTML={{ __html: extractTextContentBasedOnDay().bannerTextMobile }}></div>
                {componentMode.toLowerCase() === 'modal'.toLowerCase() ? (
                    <Button
                        id={learnmoreCtaId}
                        onClick={() => openModalHandler()}
                        ariaLabel={learnMoreCtaAccessibilityLabel}
                        modifierClass={`${componentClassName}__learnmore ${learnTextClass} `}
                        type="tertiary">
                        <span className={`${PREFIX}-md-inline-block`}>{learnMoreCtaLabel}</span>
                    </Button>
                ) : (
                    <a
                        target={learnMoreCtaTarget}
                        rel="noopener noreferrer"
                        href={learnMoreCtaUrl}
                        className={`${componentClassName}__learnmore ${learnTextClass}`}
                        aria-label={learnMoreCtaAccessibilityLabel}>
                        <span className={`${PREFIX}-md-inline-block`}>{learnMoreCtaLabel}</span>
                    </a>
                )}
            </div>

            <div className={`${componentClassName}__cta-container`}>
                <Button
                    id={closeCtaId}
                    ariaLabel={closeCtaAccessibilityLabel}
                    modifierClass={`${ctaClass}`}
                    type="tertiary">
                    <Icon type="ct-close" size="lg" />
                </Button>
            </div>
        </>
    )
}

BannerContentWrapper.propTypes = {
    ultraSkinnyBanner: PropTypes.shape({
        triangleEventStartDate: PropTypes.string.isRequired,
        triangleEventEndDate: PropTypes.string.isRequired,
        learnMoreCtaLabel: PropTypes.string,
        usModalImgAltText: PropTypes.string,
        ctaLabel: PropTypes.string,
        ctaLink: PropTypes.string,
        ctaA11yLabel: PropTypes.string,
        tdBannerTextDesktop: PropTypes.string.isRequired,
        tdBannerTextMobile: PropTypes.string.isRequired,
        tdBannerDescription: PropTypes.string,
        edBannerTextDesktop: PropTypes.string.isRequired,
        edBannerTextMobile: PropTypes.string.isRequired,
        edBannerDescription: PropTypes.string,
        fontColourText: PropTypes.string.isRequired,
        fontColourCta: PropTypes.string.isRequired,
        learnMoreCtaColour: PropTypes.string.isRequired,
        usModalDesktopBgImg: PropTypes.string,
        usModalMobileBgImg: PropTypes.string,
        componentMode: PropTypes.string.isRequired,
        bannerLogoImageDesktop: PropTypes.string,
        learnMoreCtaAccessibilityLabel: PropTypes.string,
        closeCtaAccessibilityLabel: PropTypes.string.isRequired,
        triangleBannerSuppressPageViewsCount: PropTypes.number.isRequired,
        ctaLinkTarget: PropTypes.string,
        bannerId: PropTypes.string.isRequired,
        tdShowBannerOnPageType: PropTypes.arrayOf(PropTypes.string).isRequired,
        edShowBannerOnPageType: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
}

export default BannerContentWrapper
