/* eslint-disable complexity */
import {
    ProductResponseData,
    ProductSkusData,
    SizeChartDataType,
    ProductState,
    BrandValues,
} from '../models/product.interface'
import { ActionType, getType } from 'typesafe-actions'

import { MagicNumber } from './../../analytics/analytics.type'
import {
    fetchProductDataSuccessAction,
    fetchProductDataErrorAction,
    fetchProductDataBySkuAction,
    setSelectedFirstVariantAction,
    setSelectedSecondVariantAction,
    setSelectedSizeProductCodeAction,
    setSelectedThirdVariantAction,
    setSelectedFirstVariantIdAction,
    setSelectedSecondVariantIdAction,
    setSelectedThirdVariantIdAction,
    setStickyBuyboxCTAClickedAction,
    toggleAddToCartCTAAction,
    setIfAllVariantsSelectedAction,
    setFBTProductCodeAction,
    setBuyBoxPropsAction,
    setIsOutOfStockAction,
    setQuantitySelectedAction,
    setSelectedImageUrlAction,
    resetProductSKUDataAction,
    setRearQuantitySelectedAction,
    setFrontQuantitySelectedAction,
    fetchFBTProductDataBySkuAction,
    setIsTireOrWheelShopWithNoVehicleAction,
    setIsSKUPresentInUrlAction,
    setIsOOSCurrentStoreAction,
    setIsPriceAvailabilityApiDoneAction,
    setIsProductDataAvailableAction,
    setCartFlyoutOpenAction,
    skuNotFoundAction,
    fetchVariantsProductDataBySkuAction,
    fetchVariantsProductDataBySkuErrorAction,
    setIsVehicleChangedAction,
    fetchSizeChartDataAction,
    setBrandListFetchSuccess,
} from '../actionCreators'
import * as actions from '../actionCreators'

type Action = ActionType<typeof actions>

const initialProductErrorResponse = {
    data: null,
    status: null,
}

export const initialState: ProductState = {
    productData: {} as ProductResponseData,
    productSkuData: {} as ProductSkusData,
    quantitySelected: 1,
    imageSelectedUrl: '',
    selectedFirstVariant: '',
    selectedSecondVariant: '',
    selectedProductCode: '',
    selectedThirdVariant: '',
    selectedStoreId: '363', // To be updated when store selector logic is integrated
    selectedFirstVariantId: '',
    selectedSecondVariantId: '',
    selectedThirdVariantId: '',
    isStickyBuyboxCTAClicked: {
        event: null,
        isClicked: false,
        clickedCTAName: '',
    },
    showAddToCartOnStickyBuyBox: false,
    isAllVariantSelected: false,
    fbtProductCode: [''],
    buyBoxPropsState: {},
    isOutOfStock: false,
    rearQuantitySelected: MagicNumber.TWO,
    frontQuantitySelected: MagicNumber.TWO,
    fbtProductSkuData: {} as ProductSkusData,
    variantsProductSkuData: {} as ProductSkusData,
    productErrorResponse: initialProductErrorResponse,
    isTireOrWheelShopWithNoVehicle: false,
    isProductDataAvailable: false,
    isPriceAvailabilityApiDone: false,
    isVehicleChanged: false,
    isOOSCurrentStore: null,
    isCartFlyoutOpen: false,
    skuNotFound: false,
    sizeChartData: {} as unknown as SizeChartDataType,
    brandList: [] as BrandValues[],
}

const actionTypeObj = {
    SET_SELECTED_FIRST_VARIANT: 'selectedFirstVariant',
    SET_SELECTED_SECOND_VARIANT: 'selectedSecondVariant',
    SET_SELECTED_THIRD_VARIANT: 'selectedThirdVariant',
}

export const productReducer = (state: ProductState = initialState, action: Action): ProductState => {
    if (action.type === getType(setBrandListFetchSuccess)) {
        return {
            ...state,
            brandList: action.payload,
        }
    }
    switch (action.type) {
        case getType(fetchProductDataSuccessAction): {
            return { ...state, productData: action.payload, productErrorResponse: initialProductErrorResponse }
        }
        case getType(setSelectedFirstVariantAction):
        case getType(setSelectedSecondVariantAction):
        case getType(setSelectedThirdVariantAction): {
            return { ...state, [actionTypeObj[action.type]]: action.payload }
        }
        case getType(fetchProductDataErrorAction): {
            return { ...state, productErrorResponse: action.payload }
        }
        case getType(fetchProductDataBySkuAction): {
            return { ...state, productSkuData: action.payload }
        }
        case getType(setSelectedSizeProductCodeAction): {
            return { ...state, selectedProductCode: action.payload }
        }
        case getType(setSelectedFirstVariantIdAction): {
            return {
                ...state,
                selectedFirstVariantId: action.payload,
            }
        }
        case getType(setSelectedSecondVariantIdAction): {
            return {
                ...state,
                selectedSecondVariantId: action.payload,
            }
        }
        case getType(setSelectedThirdVariantIdAction): {
            return {
                ...state,
                selectedThirdVariantId: action.payload,
            }
        }
        case getType(setStickyBuyboxCTAClickedAction): {
            return {
                ...state,
                isStickyBuyboxCTAClicked: action.payload,
            }
        }
        case getType(toggleAddToCartCTAAction): {
            return {
                ...state,
                showAddToCartOnStickyBuyBox: action.payload,
            }
        }
        case getType(setIfAllVariantsSelectedAction): {
            return { ...state, isAllVariantSelected: action.payload }
        }
        case getType(setFBTProductCodeAction): {
            return { ...state, fbtProductCode: action.payload }
        }
        case getType(setBuyBoxPropsAction): {
            return { ...state, buyBoxPropsState: action.payload }
        }
        case getType(setIsOutOfStockAction): {
            return { ...state, isOutOfStock: action.payload }
        }
        case getType(setIsOOSCurrentStoreAction): {
            return { ...state, isOOSCurrentStore: action.payload }
        }
        case getType(setQuantitySelectedAction): {
            return { ...state, quantitySelected: action.payload }
        }
        case getType(setSelectedImageUrlAction): {
            return { ...state, imageSelectedUrl: action.payload }
        }
        case getType(resetProductSKUDataAction): {
            return {
                ...state,
                productSkuData: {} as ProductSkusData,
            }
        }
        case getType(setFrontQuantitySelectedAction): {
            return {
                ...state,
                frontQuantitySelected: action.payload,
            }
        }
        case getType(setRearQuantitySelectedAction): {
            return { ...state, rearQuantitySelected: action.payload }
        }
        case getType(fetchFBTProductDataBySkuAction): {
            return { ...state, fbtProductSkuData: action.payload }
        }
        case getType(fetchVariantsProductDataBySkuAction): {
            return { ...state, variantsProductSkuData: action.payload }
        }
        case getType(fetchVariantsProductDataBySkuErrorAction): {
            return { ...state, productErrorResponse: action.payload }
        }
        case getType(setIsTireOrWheelShopWithNoVehicleAction): {
            return { ...state, isTireOrWheelShopWithNoVehicle: action.payload }
        }
        case getType(setIsSKUPresentInUrlAction): {
            return { ...state, isSKUPresentInUrl: action.payload }
        }
        case getType(setIsProductDataAvailableAction): {
            return { ...state, isProductDataAvailable: action.payload }
        }
        case getType(setIsPriceAvailabilityApiDoneAction): {
            return { ...state, isPriceAvailabilityApiDone: action.payload }
        }
        case getType(setCartFlyoutOpenAction): {
            return { ...state, isCartFlyoutOpen: action.payload }
        }
        case getType(setIsVehicleChangedAction): {
            return { ...state, isVehicleChanged: action.payload }
        }
        case getType(skuNotFoundAction): {
            return { ...state, skuNotFound: true }
        }
        case getType(fetchSizeChartDataAction): {
            return { ...state, sizeChartData: action.payload }
        }
        default:
            return state
    }
}
