export const categoryItems = {
    mobile: 4,
    tablet: {
        FOUR: 4,
        EIGHT: 8,
    },
    noOfCategories: {
        FIVE: 5,
        TEN: 10,
    },
}
