import { checkDataLength } from '../../Accounts/Addresses/checkDataLength'
import { userState } from '../../../config'
import { UserProfileData } from '../../../redux/models/user.profile.interface'

const userStateProfile = (userdata: UserProfileData | null): any => {
    return checkDataLength(userdata)
        ? userdata?.loyalty?.cardNumber
            ? userState.loyaltyCard
            : userState.noLoyaltyCard
        : userState.guest
}

export const isLoyaltyCard = (userdata: UserProfileData | null): boolean => {
    return userStateProfile(userdata) === userState.loyaltyCard
}
