import React from 'react'
import PropTypes from 'prop-types'
import StyleCodes from './StyleCodes'
import { PREFIX } from '../config'
import { ProductInfoProps } from './product.types'

/**
 * Product Information component
 * @param {ProductInfoProps} props - brand,title, sku, brandUrl, titleStyle, titleContainerStyle, hideDescription
 * @return {JSX.Element} returns Product Information component
 */
const ProductInformation: React.FC<ProductInfoProps> = ({
    brand,
    title,
    sku,
    isStyleCodeExist,
    titleStyle,
    titleContainerStyle,
    hideDescription,
    isSKUVisible,
    partNumber,
    partLabel,
    isSticky,
    accessibilityTitleId,
    ariaHidden,
    brandUrl,
}): JSX.Element => {
    /**
     * Product Title Clamp component
     * @return {JSX.Element} returns Product Title Clamp component
     */
    const productTitleClamp = (): JSX.Element => {
        return (
            <>
                {/* 
                product title clamp accessibility compliant implementation
                */}
                <div id={accessibilityTitleId} className={titleStyle}>
                    {title}
                </div>
            </>
        )
    }

    /**
     * Product Title To display component
     * @return {JSX.Element} returns Product Title To display component
     */
    const productTitleDisplay = (): JSX.Element => {
        if (titleStyle && titleContainerStyle) return productTitleClamp()
        if (isSticky) return <h3 className={`${PREFIX}-product-info__title`}>{title}</h3>
        return <h1 className={`${PREFIX}-product__title`}>{title}</h1>
    }

    /**
     * display partnumber along with sku
     * @return {JSX.Element}
     */
    const renderSKUAndPartNumber = (): JSX.Element => {
        return (
            !isSKUVisible &&
            !!sku && (
                <div className={`${PREFIX}-product__sku`} aria-hidden={ariaHidden ? ariaHidden : false}>
                    {isStyleCodeExist
                        ? Array.isArray(sku) &&
                          sku.map((styleCode, index) => (
                              <StyleCodes styleCode={styleCode} key={`style_code_key-${styleCode}`} index={index} />
                          ))
                        : `#${sku.toString()}`}
                    {partNumber && (
                        <>
                            <span className={`${PREFIX}-product__sku-seprator`}>|</span>
                            {partLabel} #{partNumber}
                        </>
                    )}
                </div>
            )
        )
    }
    return (
        <>
            {!hideDescription && (
                <div className={`${PREFIX}-product`}>
                    {/* 
                    brand is not to be displayed for instances 
                    productCard going forward BRWS2-352*/}
                    {!!brand &&
                        !titleStyle &&
                        (!!brandUrl ? (
                            <a className={`${PREFIX}-product__brandurl`} tabIndex={0} href={brandUrl}>
                                {brand}
                            </a>
                        ) : (
                            <p className={`${PREFIX}-product__brand`}>{brand}</p>
                        ))}
                    <div className={`${PREFIX}-product-title-sku`}>
                        {productTitleDisplay()}
                        {renderSKUAndPartNumber()}
                    </div>
                </div>
            )}
        </>
    )
}
ProductInformation.propTypes = {
    brand: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.exact({
            label: PropTypes.string,
            url: PropTypes.string,
        }),
    ]),
    brandUrl: PropTypes.string,
    title: PropTypes.string,
    sku: PropTypes.string,
    titleStyle: PropTypes.string,
    titleContainerStyle: PropTypes.string,
    hideDescription: PropTypes.bool,
    isSKUVisible: PropTypes.bool,
    partNumber: PropTypes.string,
    partLabel: PropTypes.string,
    isSticky: PropTypes.bool,
    accessibilityTitleId: PropTypes.string,
}
export default ProductInformation
