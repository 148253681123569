import { PREFIX } from '../../config'
import { preProcessSvgUseTag } from '../../utils/preProcessSvgUseTag'

/**
 * authoredTextBlock component
 * @param {string} className
 */
const authoredTextBlock = (className: string | Element): void => {
    preProcessSvgUseTag('js-icon', 'data-icon')

    // Reference to the element where the data prop is added.
    const parentElementRef = typeof className === 'string' ? document.getElementsByClassName(className)[0] : className

    const viewMoreButton = parentElementRef.querySelector('#view-more-button') as HTMLElement
    const viewLessButton = parentElementRef.querySelector('#view-less-button') as HTMLElement
    const disclaimer = parentElementRef.querySelector('#js-legal-disclaimer-block') as HTMLElement
    const container = parentElementRef.querySelector('#legal-container-block') as HTMLElement
    const originalData = disclaimer.innerHTML
    let modifiedData = ''

    const addEllipseClass = `${PREFIX}-authored-text-block--add-ellipses`

    /**
     * Get the line height of the paragraph.
     * P tag is accessed instead of class because the p tag is a rich text.
     */
    const paragraphLineHeight = window
        .getComputedStyle(container.querySelector('p') as Element)
        .getPropertyValue('line-height')
        .replace(/[^0-9]+/g, '')

    // calculate the height of the block.
    const numberOfLines = Number(parentElementRef.getAttribute('data-numberoflines'))
    const authoredBlockHeight = `${Number(paragraphLineHeight) * numberOfLines}px`

    if (numberOfLines > 0) {
        container.style.height = authoredBlockHeight
    } else {
        container.classList.remove(addEllipseClass) // handle when lines is zero.
        container.style.height = '100%'
        container.style.display = 'block'
        viewMoreButton.style.display = 'none'
        viewLessButton.style.display = 'none'
    }

    let showMore = false

    /**
     * view more button event listener
     */
    viewMoreButton.addEventListener('click', () => {
        hideOrShowContent()
    })

    /**
     * view less button event listener
     */
    viewLessButton.addEventListener('click', () => {
        hideOrShowContent()
    })

    /**
     * to hide Or show Content on the basis of showMore variable and return void
     */
    const hideOrShowContent = (): void => {
        showMore ? showLessButton() : showMoreButton()
        showMore = !showMore
    }

    /**
     * to display the modified content with ellipses and return void
     */
    const showLessButton = (): void => {
        viewLessButton.style.display = 'none'
        viewMoreButton.style.display = 'block'
        container.classList.add(addEllipseClass)
        disclaimer.innerHTML = modifiedData
        container.style.height = authoredBlockHeight
    }

    /**
     * to display the original content and return void
     */
    const showMoreButton = (): void => {
        viewLessButton.style.display = 'block'
        viewMoreButton.style.display = 'none'
        container.classList.remove(addEllipseClass)
        disclaimer.innerHTML = originalData
        container.style.height = '100%'
        container.style.display = 'block'
    }

    /**
     * to check the height of disclaimer and container and add ellipses and return modifiedData
     */
    const showOrHideEllipses = (): void => {
        if (disclaimer?.clientHeight > container?.clientHeight) {
            disclaimer.innerHTML = disclaimer.innerHTML.replace(/\s(\S)*$/g, '...')
        }
        modifiedData = disclaimer.innerHTML
    }

    showOrHideEllipses()
}

export { authoredTextBlock }
