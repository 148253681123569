import { useEffect } from 'react'
import { useIsMobile } from '../../../hooks'
import { constants } from './HeaderWrapper.constants'

export const useMobileScrollLock = (isOpen?: boolean): void => {
    const isMobile = useIsMobile()
    useEffect(() => {
        if (isMobile) {
            const body = document.body

            if (isOpen) {
                const scrollY = window.scrollY

                body.style.position = 'fixed'
                body.style.top = `-${scrollY}px`
            } else {
                const scrollY = body.style.top

                body.style.position = ''
                body.style.top = ''

                window.scrollTo(
                    constants.scrollX,
                    parseInt(scrollY || constants.scrollYFallback) * constants.negativeMultiplier,
                )
            }
        }
    }, [isOpen, isMobile])
}
