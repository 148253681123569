import React, { useEffect, useRef } from 'react'

import { PREFIX } from '../config'
import { AccountsDropdownPropType } from './AccountDropdown.type'
import { MenuAccessibility } from '../../utils/MenuAccessibility/MenuAccessibility'
import Button from '../Button'
import Icon from '../Icon'
import { maximumAccountManagementLinks } from '../../globalConstants/global.constant'
import { AccountOptions, MobileNavigationListItemType } from './AccountDropdown.type'
import SkeletonComponent from '../Skeleton/SkeletonComponent'
import { externalNavigation } from '../../utils/getExternalLinks'
import NotificationBadge from '../NotificationBadge'

/**
 * Accounts Drop Down
 *
 * @interface AccountsDropdownPropType
 * @return {JSX.Element}
 */
const AccountsDropdown: React.FC<AccountsDropdownPropType> = ({
    signOutButtonClick,
    isLoggedIn,
    menuEscapeHandler,
    orText,
    signInLabel,
    registerLink,
    registerLabel,
    accountOptions,
    signOutLabel,
    selectLastMenuItem,
    isAccountDashboard,
    accountsLabel,
    featureType,
    onSignInButtonClickHandler,
    isGigyaLoaded,
    isShowTriangle,
    isDashboard,
    isFlyout,
    mobileNavigation,
    unActivatedOffersCount,
    offersBadgeCountThreshold,
    weeklyOffersLink,
    a11yAlertBadgeValue,
    hideAddRewards,
    accountButton,
    addRewardsPageLink,
}: AccountsDropdownPropType): JSX.Element => {
    const className = `${PREFIX}-pencil-banner`
    const runOnce = useRef(0)
    const cleanUpRef = useRef<() => void>(null)

    /**
     * useEffect for adding event listeners and creating menuAccessibility
     */
    useEffect(() => {
        if (!isAccountDashboard && runOnce.current === 0) {
            runOnce.current++
            const menuDropDownParent = isShowTriangle ? 'accountsMenuDropDown' : 'accountMenuDropDown'
            const menuList = new MenuAccessibility(menuDropDownParent)
            menuList.init(selectLastMenuItem)
            document.addEventListener('keydown', menuEscapeHandler)
            cleanUpRef.current = () => {
                document.removeEventListener('keydown', menuEscapeHandler)
                menuList.destroy()
            }
            accountButton?.current?.focus()
        }
    }, [isAccountDashboard, selectLastMenuItem, menuEscapeHandler, isShowTriangle, accountButton])

    useEffect(() => {
        return () => {
            const cleanUp = cleanUpRef.current
            if (cleanUp !== null) {
                cleanUp()
            }
        }
    }, [])

    /**
     * renderHorizontalLine for account Dashboard
     * @return {JSX.Element | null}
     */
    const renderHorizontalLine = (): JSX.Element | null => (isAccountDashboard ? <hr /> : null)

    /**
     * accountOptionsAfterRemovingExtraRecords is for removing additional records from Account Management Links
     * @return {AccountOptions[]}
     */
    const accountOptionsAfterRemovingExtraRecords = (): AccountOptions[] => {
        const isAccountOptions = accountOptions ? accountOptions.length : 0
        return isAccountOptions > maximumAccountManagementLinks
            ? accountOptions.slice(0, -(accountOptions.length - maximumAccountManagementLinks))
            : accountOptions
    }

    /**
     * render skeleton or sign-in text
     * @return {JSX.Element}
     */
    const showSignIn = (): JSX.Element => {
        return (
            !isLoggedIn &&
            (isGigyaLoaded ? (
                <>
                    <div className={`${className}__authentication`}>
                        <button
                            role="menuitem"
                            className={`${className}__authentication--sign-in`}
                            onClick={onSignInButtonClickHandler}
                            data-link-value={signInLabel}
                            data-feature-type={featureType}>
                            {signInLabel}
                        </button>
                        <span className={`${className}__middle-text`}>{orText}</span>
                        <a
                            role="menuitem"
                            href={registerLink}
                            className={`${className}__authentication--register`}
                            data-link-value={registerLabel}
                            data-feature-type={featureType}>
                            {registerLabel}
                        </a>
                    </div>
                    {renderHorizontalLine()}
                </>
            ) : (
                <div className={`${className}__sigin-in-skeleton`}>
                    <SkeletonComponent />
                </div>
            ))
        )
    }

    /**
     * render Accounts Dropdown
     * @return {JSX.Element}
     */
    const showRewardsSection = (): JSX.Element => {
        return (
            !isShowTriangle && (
                <>
                    {showSignIn()}
                    {renderHorizontalLine()}
                </>
            )
        )
    }

    /**
     * render Accounts Dropdown
     * @param {boolean} isWeeklyOffer
     * @return {JSX.Element}
     */
    const renderBadge = (isWeeklyOffer: boolean): JSX.Element => {
        const showBadge =
            unActivatedOffersCount &&
            unActivatedOffersCount !== 0 &&
            isWeeklyOffer &&
            unActivatedOffersCount <= offersBadgeCountThreshold
        if (showBadge) {
            return (
                <NotificationBadge
                    value={unActivatedOffersCount}
                    a11yValue={a11yAlertBadgeValue}
                    notificationClass={`${PREFIX}-account-settings__warning`}
                    type="error"
                    size="sm"
                    radius="rounded-xl"
                />
            )
        }
    }

    /**
     * render Menu Items on Primary navigation mobile view
     * @param {MobileNavigationListItemType[]} items
     * @return {JSX.Element[]}
     */
    const renderMobileNavigation = (items: MobileNavigationListItemType[]): JSX.Element[] => {
        return items.map((item: MobileNavigationListItemType, i: number) => (
            <li
                key={i}
                className={`${className}__account-options-item${externalNavigation(item.linkUrl) ? '__external' : ''}`}>
                <a
                    className={`${PREFIX}-xs-inline-block`}
                    href={item.linkUrl}
                    title={item.linkLabel}
                    data-link-value={item.linkLabel}
                    target={`${externalNavigation(item.linkUrl) ? '_blank' : '_self'}`}>
                    {item.linkLabel}
                    {externalNavigation(item.linkUrl) && <Icon type="ct-north-east" size="lg" />}
                </a>
            </li>
        ))
    }

    /**
     * render Menu Items on Primary navigation mobile view with title
     * @param {string} title
     * @param {MobileNavigationListItemType[]} items
     * @return {JSX.Element}
     */
    const renderMobileNavigationItems = (): JSX.Element | null => {
        if (isFlyout && mobileNavigation) {
            return (
                <>
                    <h4 className={`${className}__header ${PREFIX}-body-lg`}>{mobileNavigation.categoryTitle}</h4>
                    <ul className={`${className}__account-options`}>
                        {renderMobileNavigation(mobileNavigation.categoryItems)}
                    </ul>
                </>
            )
        }
    }

    const accountsDropDownSection = `${className}__dropdown${isShowTriangle ? '--triangle' : '--dashboard'}`
    const linkActive = (showDashboad, link: AccountOptions) =>
        showDashboad && link.isActive ? `${className}__account-options-item--active` : ''

    // Simplified till MTE-178 will be redesigned
    // const accountOptionsData = accountOptionsAfterRemovingExtraRecords()
    const buttonType = isShowTriangle ? 'secondary_reverse' : 'secondary'

    return (
        <div id="accountsMenuDropDown" aria-labelledby="accountButton" className={accountsDropDownSection}>
            {showRewardsSection()}
            <div className={`${className}__container${isShowTriangle ? '--triangle' : ''}`}>
                {isFlyout && accountsLabel && (
                    <h4
                        className={`${
                            isShowTriangle ? `${className}__header` : `${className}__section-header`
                        } ${PREFIX}-body-lg`}>
                        {accountsLabel}
                    </h4>
                )}
                {Array.isArray(accountOptionsAfterRemovingExtraRecords()) && (
                    <ul className={`${className}__account-options`}>
                        {accountOptionsAfterRemovingExtraRecords().map(link => {
                            const isWeeklyOffer = Boolean(link.navigationRedirectionLink === weeklyOffersLink)
                            if (
                                hideAddRewards &&
                                !!addRewardsPageLink &&
                                addRewardsPageLink.includes(link.navigationRedirectionLink)
                            ) {
                                return <></>
                            } else {
                                return (
                                    <li
                                        key={link.navigationOptionsLabel}
                                        className={`${linkActive(
                                            isDashboard,
                                            link,
                                        )} ${className}__account-options-item${
                                            isShowTriangle && externalNavigation(link.navigationRedirectionLink)
                                                ? '__external'
                                                : ''
                                        }`}>
                                        <a
                                            aria-current={linkActive(isDashboard, link) ? 'page' : null}
                                            href={link.navigationRedirectionLink}
                                            target={link.navigationOptionsTarget}
                                            data-link-value={link.navigationOptionsLabel}
                                            data-feature-type={featureType}
                                            className={`${PREFIX}-xs-inline-block`}>
                                            {link.navigationOptionsLabel}
                                            {isShowTriangle && externalNavigation(link.navigationRedirectionLink) && (
                                                <Icon type="ct-north-east" size="lg" />
                                            )}
                                            {renderBadge(isWeeklyOffer)}
                                        </a>
                                    </li>
                                )
                            }
                        })}
                    </ul>
                )}
                {renderMobileNavigationItems()}
                {isLoggedIn && (
                    <div className={`${className}__sign-out`}>
                        <Button type={buttonType} size="mini" onClick={signOutButtonClick}>
                            {signOutLabel}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    )
}

export default AccountsDropdown
